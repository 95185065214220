import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ExclamationCircleIcon } from 'components/icons';
import Tabs, { TabVariants } from 'components/Tabs';
import { OwnerTypes } from 'entities/ownership';
import IndividualForm from './IndividualForm';
import Accordion from 'components/Accordion';

import EntityForm from './EntityForm';

interface OwnerProps {
  data: any;
  index: number;
  showErrors: boolean;
  permissions: any;
}

const Owner: React.FC<OwnerProps> = ({
  data,
  index,
  showErrors,
  permissions,
}) => {
  const { t } = useTranslation('ownership');

  const [currentTab, setCurrentTab] = useState(data.type);

  return (
    <Accordion
      title={
        <span className="flex items-center">
          <span className="font-semibold">
            {data.type === OwnerTypes.person
              ? data.givenName1 && (
                  <span className="mr-3">
                    {data.givenName1} {data.surnameFirst}
                  </span>
                )
              : data.businessLegalName && (
                  <span className="mr-3">{data.businessLegalName}</span>
                )}
          </span>
          <span className="text-xs text-gray-500 font-normal">
            {t('ownership:ownerNumber', { number: index + 1 })}
          </span>
          {showErrors && (!data.isActive || data.idfrontGuid === null) && (
            <ExclamationCircleIcon className="ml-2 text-yellow-500" />
          )}
        </span>
      }
      className="bg-gray-50 rounded-lg p-6 my-5"
      opened={!data.isActive}
    >
      <div className="pt-6">
        <Tabs
          tabs={[
            {
              name: 'ownership:individualTab',
              disabled: !permissions.edit,
            },
            {
              name: 'ownership:entityTab',
              disabled: !permissions.edit,
            },
          ]}
          currentTab={currentTab}
          onChange={setCurrentTab}
          className="bg-gray-100 rounded-10"
          tabClassName="w-full rounded-10 m-1 justify-center shadow-none border-b border-t border-transparent"
          variant={TabVariants.pill}
        >
          <IndividualForm isOpen={currentTab === 0} ownerData={data} />
          <EntityForm
            isOpen={currentTab === 1}
            ownerData={data}
            number={index + 1}
          />
        </Tabs>
      </div>
    </Accordion>
  );
};

export default Owner;
