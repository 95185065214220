import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { fetchBusinessesByPerson } from 'pages/login/thunks';
import BusinessList from 'components/BusinessList';
import { PersonSchema } from 'services/persons';
import Loading from 'components/Loading';
import { Logo } from 'components/Logos';
import { useAppDispatch } from 'hooks';

type BusinessListCardProps = {
  person: PersonSchema | null;
};

const BusinessListCard: React.FC<BusinessListCardProps> = ({ person }) => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(
      fetchBusinessesByPerson({
        person,
        navigate,
      }),
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-full max-w-[428px] dropdown-shadow-effect rounded-3xl bg-white z-10 mb-6">
      <div className="p-8 border-b border-gray-200">
        <div className="flex justify-center mb-6">
          <Logo className="max-h-[72px]" />
        </div>

        <>
          <h2 className="text-2xl font-bold text-center">
            {t('businessPersonTitle')}
          </h2>
          <p className="text-gray-600 text-center">
            {t('businessPersonSubTitle')}
          </p>
        </>
      </div>

      <div className="w-full">
        <BusinessList />
      </div>
    </div>
  );
};

export default BusinessListCard;
