import { createSlice } from '@reduxjs/toolkit';

import { ConfigurationState } from 'entities/configuration';
import { clearDashboard } from 'pages/dashboard/thunks';

import {
  fetchBPSTrack,
  addBPSTrack,
  updateBPSTrack,
  addBPSTrackPayment,
  updateBPSTrackPayment,
} from './thunks';

export const initialState: ConfigurationState = {
  defaultBankAccountId: 0,
  bpsTrack: null,
  payments: [],
  isLoading: false,
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setDefaultBankAccountId(state, action) {
      state.defaultBankAccountId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchBPSTrack.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBPSTrack.rejected, () => initialState);
    builder.addCase(fetchBPSTrack.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bpsTrack = action.payload?.bpsTrack || initialState.bpsTrack;
      state.payments = action.payload?.payments || initialState.payments;
      state.defaultBankAccountId = action.payload?.bpsTrack.bankAccountId || 0;
    });

    builder.addCase(addBPSTrack.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBPSTrack.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBPSTrack.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bpsTrack = action.payload;

      if (action.payload?.bankAccountId) {
        state.defaultBankAccountId = action.payload.bankAccountId;
      }
    });

    builder.addCase(addBPSTrackPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBPSTrackPayment.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBPSTrackPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payments = action.payload;
    });

    builder.addCase(updateBPSTrack.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBPSTrack.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateBPSTrack.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bpsTrack = action.payload;

      if (action.payload?.bankAccountId) {
        state.defaultBankAccountId = action.payload.bankAccountId;
      }
    });

    builder.addCase(updateBPSTrackPayment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBPSTrackPayment.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateBPSTrackPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.payments = action.payload;
    });
  },
});

export const { setDefaultBankAccountId } = configurationSlice.actions;

export default configurationSlice.reducer;
