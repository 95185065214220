import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBusinessesId } from 'utils/authService';
import { RootState } from 'state/store';
import {
  CrossBorderProviderXrefSchema,
  addBusinessCrossBorderProvider,
  deleteBusinessCrossBorderProvider,
} from 'services/businesses';

import { getBusinessProviders } from './selectors';

export const createFXProvider: any = createAsyncThunk(
  'fxProvider/createFXProvider',
  async (payload: Partial<CrossBorderProviderXrefSchema>, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();

      const id = await addBusinessCrossBorderProvider(businessesId, payload);

      const businessProviders = getBusinessProviders(
        thunkAPI.getState() as RootState,
      );

      return [
        ...businessProviders,
        {
          id,
          businessesId,
          ...payload,
        },
      ];
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteFXProvider: any = createAsyncThunk(
  'fxProvider/deleteFXProvider',
  async (id: number, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();

      await deleteBusinessCrossBorderProvider(businessesId, id);

      const businessProviders = getBusinessProviders(
        thunkAPI.getState() as RootState,
      );

      return businessProviders.filter((p) => p.id !== id);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
