import { useTranslation } from 'react-i18next';

import { CloseCircleIcon, CheckCircleIcon } from 'components/icons';
import { ProductTypeCodes } from 'entities/progress';
import { useAppSelector } from 'hooks';

import { getConnectedErpAccount } from './AccountingSoftware/selectors';
import { getConnectedBankAccount } from './BankAccounts/selectors';
import { getActiveProductsTypeCodes } from './Progress/selectors';

enum IconTypes {
  close = 'close',
  check = 'check',
}

type CardProps = {
  type: IconTypes;
  title: string;
  description: string;
};

const icons = {
  [IconTypes.close]: (
    <CloseCircleIcon className="text-gray-500 flex-shrink-0 w-[18px] h-[18px]" />
  ),
  [IconTypes.check]: (
    <CheckCircleIcon className="text-green-500 flex-shrink-0 w-[18px] h-[18px]" />
  ),
};

const Card: React.FC<CardProps> = ({ type, title, description }) => (
  <div className="flex items-start mb-3 last:mb-0 bg-gray-800 p-3 rounded-lg">
    {icons[type]}
    <div className="pl-2">
      <p className="text-sm font-medium text-gray-50 mb-0">{title}</p>
      <p className="text-sm text-gray-400 mb-0">{description}</p>
    </div>
  </div>
);

const IntegrationTabPopover = () => {
  const { t } = useTranslation('integration');

  const bankAccount = useAppSelector(getConnectedBankAccount);
  const erpAccount = useAppSelector(getConnectedErpAccount);
  const activeProductTypes = useAppSelector(getActiveProductsTypeCodes);
  const fxProvider = false; // TODO

  return (
    <div className="bg-gray-900 rounded-lg p-3 md:w-[352px]">
      <Card
        type={erpAccount ? IconTypes.check : IconTypes.close}
        title={t('integration:erp.titleEmpty')}
        description={t('integration:erp.popover')}
      />
      <Card
        type={bankAccount ? IconTypes.check : IconTypes.close}
        title={t('integration:bank.titleEmpty')}
        description={t('integration:bank.popover')}
      />
      {activeProductTypes.includes(ProductTypeCodes.crossBorderPayments) && (
        <Card
          type={fxProvider ? IconTypes.check : IconTypes.close}
          title={t('integration:fxProvider.title')}
          description={t('integration:fxProvider.description')}
        />
      )}
    </div>
  );
};

export default IntegrationTabPopover;
