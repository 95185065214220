import { useTranslation } from 'react-i18next';

import batchIcon from 'assets/icons/connectors/Batch.svg';
import { DataSourceWidgets } from 'entities/accounting';

import DataSourceWidgetModal from './DataSourceWidgetModal';

const BatchWidgetModal = () => {
  const { t } = useTranslation('integration');

  return (
    <DataSourceWidgetModal
      id={DataSourceWidgets.batch}
      title="integration:dataSource.batchModalTitle"
      icon={batchIcon}
      description={
        <div>
          <p>{t('integration:dataSource.batchModalDescription')}</p>
          <p className="my-2">
            {t('integration:dataSource.batchModalDescription2')}
          </p>
        </div>
      }
    />
  );
};
export default BatchWidgetModal;
