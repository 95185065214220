import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { getPersonsLables } from 'components/InvitePersons/selectors';
import { UserAddIcon } from 'components/icons/UserAddIcon';
import { hasPermission } from 'pages/dashboard/selectors';
import PopoverOnHover from 'components/PopoverOnHover';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import Persons from 'components/Persons';
import Button from 'components/Button';
import {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
} from './invitePersonsSlice';

const TabInvite = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const persons = useAppSelector(getPersonsLables);
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleInvite = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(true));
  };

  return (
    <HideComponent show={invitePermissions.view}>
      <div className="flex items-center divide-x divide-gray-300">
        <div className="flex items-center">
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <Button
              variant="link"
              paddingClass="px-3"
              className="ml-2.5 mr-1 flex-shrink-0"
              size="medium-large"
              onClick={handleInvite}
              disabled={!invitePermissions.edit}
            >
              {t('dashboard:invitePersons.buttons.invitePeople')}
              <UserAddIcon className="ml-2" />
            </Button>
          </PermissionSection>
          <PopoverOnHover info={t('dashboard:inviteInfo')} className="mr-4" />
        </div>

        <div className="pl-4">
          <Persons persons={persons} />
        </div>
      </div>
    </HideComponent>
  );
};

export default TabInvite;
