import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';
import Popover from 'components/Popover';
import {
  DotsVerticalIcon,
  DisconnectIcon,
  RefreshIcon,
  AdjustmentsSolidIcon,
  LinkSolidIcon,
} from 'components/icons';

import ConfirmDisconnect from './ConfirmDisconnect';

interface DropdownMenuProps {
  isActive: boolean;
  onRefresh: (() => void) | null;
  onUpdate: (() => void) | null;
  onDisconnect?: (() => void) | null;
  onConnect?: (() => void) | null;
  permission?: Permissions | undefined;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  isActive,
  onRefresh,
  onUpdate,
  onDisconnect,
  onConnect,
  permission,
}) => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const permissions = useAppSelector((state) =>
    hasPermission(state, permission),
  );
  const compliancePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.compliance),
  );

  const [opened, setOpened] = useState<boolean>(false);

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleConfirm = () => {
    if (onDisconnect) {
      onDisconnect();
    }

    handleCancel();
  };

  const handleDisconnect = () => {
    dispatch(
      openDialog({
        content: (
          <ConfirmDisconnect
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        ),
        maxWidth: '514px',
      }),
    );
  };

  const items = [
    {
      key: 'refresh',
      icon: <RefreshIcon className="mr-3" />,
      text: t('integration:refreshConnection'),
      onClick: onRefresh,
      needPermission: false,
      complianceOnly: false,
    },
    {
      key: 'view',
      icon: <AdjustmentsSolidIcon className="mr-3" />,
      text: t('integration:viewDetails'),
      onClick: onUpdate,
      needPermission: false,
      complianceOnly: false,
    },
    {
      key: 'connect',
      icon: <LinkSolidIcon className="mr-3" />,
      text: t('integration:connect'),
      onClick: onConnect,
      needPermission: true,
      complianceOnly: true,
    },
    {
      key: 'disconnect',
      icon: <DisconnectIcon className="mr-3" />,
      text: t('integration:disconnect'),
      onClick: onDisconnect ? handleDisconnect : null,
      needPermission: true,
      complianceOnly: false,
    },
  ];

  const handleClick = (item) => {
    item.onClick();
    setOpened(false);
  };

  return (
    <Popover
      opened={opened}
      button={
        <button
          onClick={() => setOpened(true)}
          className="rounded-full flex items-center p-[7px] cursor-pointer text-gray-400 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-500"
        >
          <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      }
      content={
        <div className="mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {items
            .filter(
              (item) =>
                item.onClick &&
                (!item.needPermission ||
                  compliancePermissions.view ||
                  (item.key === 'connect' && !isActive)),
            )
            .map((item, index) => (
              <PermissionSection
                permission={
                  item.complianceOnly
                    ? Permissions.compliance
                    : item.needPermission
                    ? permission
                    : undefined
                }
                key={index}
                showPopover
                edit
              >
                <button
                  className={`text-gray-500 hover:text-gray-600 block p-4 text-sm leading-5 hover:bg-gray-100 cursor-pointer w-full${
                    (item.complianceOnly && !compliancePermissions.edit) ||
                    (item.needPermission && !permissions.edit)
                      ? ' opacity-50'
                      : ''
                  }`}
                  onClick={() =>
                    (item.complianceOnly && !compliancePermissions.edit) ||
                    (item.needPermission && !permissions.edit)
                      ? null
                      : handleClick(item)
                  }
                  disabled={
                    (item.complianceOnly && !compliancePermissions.edit) ||
                    (item.needPermission && !permissions.edit)
                  }
                >
                  <div className="flex items-center">
                    <span>{item.icon ? item.icon : null}</span>
                    <span className="text-gray-900 min-w-[175px] text-left">
                      {item.text}
                    </span>
                  </div>
                </button>
              </PermissionSection>
            ))}
        </div>
      }
    />
  );
};

export default DropdownMenu;
