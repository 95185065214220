import React from 'react';

import LottieControl from './LottieLoader/LottieLoader';

type LoadingProps = {
  fullHeight?: boolean;
  transparent?: boolean;
  zIndex?: number;
  loading?: boolean;
};

const Loading: React.FC<LoadingProps> = ({
  fullHeight = true,
  transparent = false,
  zIndex = 40,
  loading,
}) => {
  if (loading === false) {
    return null;
  }

  return (
    <div
      className={`${
        fullHeight ? 'absolute' : 'relative'
      } inset-0 flex items-center w-full h-full text-center justify-items-center z-${zIndex}`}
    >
      <div
        className={`${
          transparent && 'opacity-50'
        } absolute w-full h-full bg-gray-100`}
      />
      <div className="mx-auto">
        <LottieControl />
      </div>
    </div>
  );
};

export default Loading;
