import api from 'api';

import { PermissionSchema } from './security';

export const USER_WITH_OID = 1;
export const USER_WITHOUT_OID = 0;

export interface PersonSchema {
  personsId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  givenName1: string;
  middleName: string;
  preferredName: string;
  emailAddress: string;
  prefix: string;
  givenName2: string;
  suffix: string;
  surnameFirst: string;
  surnameSecond: string;
  password: string;
  hubSpotContactId: string;
  hubSpotCreatedDateTime: string;
  personsGuid: string;
  oid: string;
  tokenOid: string;
  tenantId: number;
  globalUserId: number | null;
  erpuserReferenceId: number;
}

export interface PersonBusinessSchema {
  accountholder: number;
  businessesAdmin: boolean;
  businessesBusinessLegalName: string;
  businessesDUNSNumber: string;
  businessesId: number;
  businessesNoDUNSListed: boolean;
  businessesPersonsRole: string;
  businessesPersonsXREFId: number;
  businessesTenantId: number;
  businessesTenantName: string;
  businessesTenantUrlSub: string;
  emailAddress: string;
  erpClientID: number | null;
  hubCreateUser: boolean;
  isActive: boolean;
  participantID: number | null;
  personsEmailAddress: string;
  personsGivenName1: string;
  personsGivenName2: string;
  personsId: number;
}

export interface PersonDetailsSchema {
  id: number;
  prefix: string;
  preferredName: string;
  givenName1: string;
  middleName: string;
  givenName2: string;
  surnameFirst: string;
  surnameSecond: string;
  suffix: string;
  emailAddress: string;
  phone: {
    id: number | null;
    countryCallingCode: string | null;
    number: string | null;
    personsTelephoneNumbersXrefId: number | null;
  };
}

export interface InvitePersonSchema {
  tenantsId: number;
  businessesId: number;
  inviteRedirectUrl: string;
  invitedUserDisplayName: string;
  invitedUserEmailAddress: string;
  inviteSenderEmailAddress: string;
  invitationSubject: string;
  invitationMessage: string;
  sendInvitationMessage: boolean;
  personRoles: string[];
}

export interface VerifyCodeSchema {
  emailAddress: string;
  verificationCode: string;
}

export interface VerifyEmailSchema {
  emailAddress: string;
  sendCode: boolean;
  tenantId: number;
}

export interface VerifyComplianceSchema {
  businessesId: number;
  createdBy: string;
}

export interface PersonPermissionsPayload {
  personsId: number;
  businessesId: number;
  permissions: number[];
}

export const getPerson = async (id: string | number) => {
  const result: { person: PersonDetailsSchema } | null = await api.get(
    `Persons/${id}`,
  );
  return result?.person || null;
};

export const getPersons = (personId: number) => {
  return api.get(`Persons/GetPersons/${personId}`);
};

export const addPersons = (data: Partial<PersonSchema>) => {
  return api.post('Persons/AddPersons', data);
};

export const updatePersons = (data: Partial<PersonSchema>) => {
  return api.put('Persons/UpdatePersons', data);
};

export const searchPersons = (data: Partial<PersonSchema>) => {
  return api.post('Persons/SearchPersons', data);
};

export const getBusinessPermissions = async (
  personId: number,
  businessId: number,
) => {
  const result: PermissionSchema[] = await api.get(
    `Persons/${personId}/businesses/${businessId}/permissions`,
  );
  return result;
};

export const invite = (data: Partial<InvitePersonSchema>) => {
  return api.post('Persons/Invite', data);
};

export const invitePersonsCreate = (data: Partial<InvitePersonSchema>) => {
  return api.post('Persons/InvitePersonsCreate', data);
};

export const addPersonPermissions = (data: PersonPermissionsPayload) => {
  const { personsId, businessesId, ...payload } = data;

  return api.post(
    `Persons/${personsId}/Businesses/${businessesId}/Permissions`,
    payload,
  );
};

export const deletePersonPermissions = (data: PersonPermissionsPayload) => {
  const { personsId, businessesId, ...payload } = data;

  return api.post(
    `Persons/${personsId}/Businesses/${businessesId}/Permissions/Delete`,
    payload,
  );
};

export const verifyEmail = (data: VerifyEmailSchema) => {
  return api.post('Persons/VerifyEmail', data);
};

export const verifyUserEnteredEmail = (data: VerifyCodeSchema) => {
  return api.post('Persons/VerifyUserEnteredEmail', data);
};

export const verifyCompliancePerson = (data: VerifyComplianceSchema) => {
  return api.post('Persons/VerifyCompliancePerson', data);
};

// TODO pagination
export const getPersonBusinesses = async (
  id: string | number,
  query = '?page=1&size=9999',
) => {
  const result: PersonBusinessSchema[] = await api.get(
    `Persons/${id}/Businesses${query}`,
  );
  return Array.isArray(result) ? result : [];
};

export const getPersonById = async (
  personId: number,
): Promise<PersonSchema | null> => {
  try {
    const persons: any = await getPersons(personId);

    return persons && persons.length ? persons[0] : null;
  } catch (error) {
    return null;
  }
};

export default {
  getPerson,
  getPersons,
  addPersons,
  updatePersons,
  searchPersons,
  getBusinessPermissions,
  invite,
  invitePersonsCreate,
  addPersonPermissions,
  deletePersonPermissions,
  verifyEmail,
  verifyUserEnteredEmail,
  verifyCompliancePerson,
  getPersonById,
  getPersonBusinesses,
};
