import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { NumberInput, Input } from 'components/Inputs/Input';
import Button from 'components/Button';
import { getOwnerSchema } from '../utils';

interface OwnerModalFormProps {
  onClose: () => void;
  onSave: (value) => void;
  values?: {
    name: string;
    shareOfOwnership: string;
  };
}

const OwnerModalForm: React.FC<OwnerModalFormProps> = ({
  onClose,
  onSave,
  values,
}) => {
  const { t } = useTranslation('dashboard');

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      shareOfOwnership: '',
    },
    resolver: yupResolver(getOwnerSchema(t)),
  });

  useEffect(() => {
    if (values) {
      reset(values);
    }
  }, [reset, values]);

  return (
    <div className="px-6 pb-6 pt-4">
      <form action="" onSubmit={handleSubmit(onSave)}>
        <div className="mb-4">
          <Input
            label={t('dashboard:businessPlan.modal.name.label')}
            name="name"
            error={errors?.name}
            register={register}
          />
        </div>
        <div className="mb-9">
          <NumberInput
            label={t('dashboard:businessPlan.modal.shareOfOwnership.label')}
            name="shareOfOwnership"
            prefix="%"
            prefixInline
            inputClassName="pl-8"
            error={errors?.shareOfOwnership}
            control={control}
          />
        </div>
        <div className="flex">
          <Button
            onClick={onClose}
            className="w-full border-gray-300 border-[1px] bg-white mr-2 "
            variant="secondary"
          >
            {t('dashboard:businessPlan.buttons.cancel')}
          </Button>
          <Button className="w-full ml-2" type="submit">
            {t('dashboard:businessPlan.buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OwnerModalForm;
