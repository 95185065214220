import { parsePhoneNumber } from 'react-phone-number-input';
import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import { BankAccount, BankAccountStatusIds } from 'entities/bankAccounts';
import { buildFormData, getResponseErrorMessages } from 'utils/helpers';
import * as bankIntegratorsService from 'services/bankIntegrators';
import * as bankAccountsService from 'services/bankAccounts';
import { getBankAccountBlobFile } from 'services/businesses';
import { hasPermission } from 'pages/dashboard/selectors';
import { addEmailAddress } from 'services/emailAddresses';
import { getBusinessesId } from 'utils/authService';
import { getPerson } from 'pages/Profile/selectors';
import { Permissions } from 'entities/dashboard';
import { RootState } from 'state/store';
import {
  getVendorCredentialsByIntegrator,
  getVendorCredentialsByDirectoryId,
} from 'services/tgpa';
import {
  addFundingAccount,
  getFundingAccount,
  updateFundingAccount,
} from 'services/accounts';

import { setDefaultBankAccountId } from '../Configuration/configurationSlice';
import { addTelephoneNumbers } from 'services/telephoneNumbers';
import { updateBPSTrack } from '../Configuration/thunks';
import { getBPSTrack } from '../Configuration/selectors';
import {
  getState as getBankAccountState,
  getBankAccount,
  getBankAccountChecks,
  getBankAccountCheckRanges,
  getBankAccounts as getAccounts,
  getBankAccountTypes,
  getProfile,
} from './selectors';
import {
  mappedAccountTypes,
  mapCreateBankAccountFields,
  mapUpdateBankAccountFields,
  mapCheckRanges,
  mapChecks,
} from './utils';

export const fetchVendorCredentials: any = createAsyncThunk(
  'bankAccounts/fetchVendorCredentials',
  async (_: void, thunk) => {
    try {
      const { directoryId, integrator } = getBankAccountState(
        thunk.getState() as RootState,
      );

      const fields = await getVendorCredentialsByDirectoryId(directoryId);

      let vendorCredentials: any = null;

      if (integrator) {
        vendorCredentials = await bankIntegratorsService.getVendorCredentials(
          integrator.integratorsId,
          directoryId,
          getBusinessesId(),
        );
      }

      return { fields, vendorCredentials };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBankAccounts: any = createAsyncThunk(
  'bankAccounts/fetchBankAccounts',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewBankPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.bankIntegration,
      );

      if (!viewBankPermission) {
        return [];
      }

      const businessesId = getBusinessesId();

      const accounts = await bankAccountsService.searchBankAccounts({
        businessesId,
        isActive: true,
      });

      return accounts ?? [];
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBankIntegrators: any = createAsyncThunk(
  'bankAccounts/fetchBankIntegrators',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewBankPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.bankIntegration,
      );

      if (!viewBankPermission) {
        return [];
      }

      const bankIntegrators =
        await bankIntegratorsService.searchBanksIntegratorsByDetails({
          isActive: true,
        });

      return bankIntegrators;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchCheckRanges: any = createAsyncThunk(
  'bankAccounts/fetchCheckRanges',
  async (_: void, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { bankAccountsId } = getBankAccount(state) as BankAccount;
      const { emailAddress } = getPerson(state);

      const ranges = await bankAccountsService.searchBankAccountCheckRanges({
        bankAccountsId,
        isActive: true,
        gotBy: emailAddress,
      });

      const omitted = await bankAccountsService.searchBankAccountChecks({
        banks_AccountsId: bankAccountsId,
        isActive: true,
        gotBy: emailAddress,
      });

      return { ranges, omitted };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBankAccountData: any = createAsyncThunk(
  'bankAccounts/fetchBankAccountData',
  async (integratorId: number, thunkApi) => {
    try {
      const currentTypes = getBankAccountTypes(
        thunkApi.getState() as RootState,
      );

      let bankAccountTypes = currentTypes;

      if (!bankAccountTypes.length) {
        const typesResult = await bankAccountsService.searchBankAccountTypes();
        bankAccountTypes = mappedAccountTypes(typesResult);
      }

      let directoryId = 0;

      if (integratorId) {
        const integrators = await getVendorCredentialsByIntegrator(
          integratorId,
        );
        directoryId = integrators[0]?.id || 0;
      }

      return {
        bankAccountTypes,
        directoryId,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createBankAccount: any = createAsyncThunk(
  'bankAccounts/createBankAccount',
  async (data: any, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    try {
      const state = getState() as RootState;

      const bankAccounts = getBankAccountState(state);
      const { person, business } = getProfile(state);

      const fields = mapCreateBankAccountFields({
        data,
        person,
        business,
        bankAccounts,
      });

      const formData = buildFormData(fields);

      const bankAccountId = await bankAccountsService.addBankAccounts(formData);

      if (bankAccountId) {
        const accounts = await bankAccountsService.getBankAccounts(
          bankAccountId,
        );
        return accounts.length ? accounts[0] : null;
      }

      return null;
    } catch (e: any) {
      if (
        getResponseErrorMessages(e.response, true) ===
        'This account already exists and is active'
      ) {
        return rejectWithValue(getResponseErrorMessages(e.response));
      }

      return Promise.reject(e);
    }
  },
);

export const updateBankAccount: any = createAsyncThunk(
  'bankAccounts/updateBankAccount',
  async (data: any, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;

    try {
      const state = getState() as RootState;

      const bankAccounts = getBankAccountState(state);
      const { person } = getProfile(state);

      const { emailAddress } = person;

      const fields = mapUpdateBankAccountFields({
        bankAccounts,
        data,
        emailAddress,
      });

      if (!fields) {
        return null;
      }

      const formData = buildFormData(fields);

      const bankAccountId: any = await bankAccountsService.updateBankAccounts(
        formData,
      );

      if (bankAccountId) {
        const result: any = await bankAccountsService.getBankAccounts(
          bankAccountId,
        );

        if (result?.[0]) {
          const accounts = bankAccounts.bankAccounts
            .slice()
            .map((acc) =>
              acc.bankAccountsId === bankAccountId ? result[0] : acc,
            );

          return {
            bankAccounts: accounts,
            bankAccount: result[0],
          };
        }
      }

      return null;
    } catch (e: any) {
      if (
        getResponseErrorMessages(e.response, true) ===
        'This account already exists and is active'
      ) {
        return rejectWithValue(getResponseErrorMessages(e.response));
      }

      return Promise.reject(e);
    }
  },
);

export const saveVendorCredentials: any = createAsyncThunk(
  'bankAccounts/saveVendorCredentials',
  async (json: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { integrator, directoryId, bankAccount, vendorCredentials } =
        getBankAccountState(state);
      const { emailAddress } = getPerson(state);

      if (!integrator || !bankAccount) {
        return null;
      }

      const businessesId = getBusinessesId();

      if (vendorCredentials) {
        await bankIntegratorsService.updateVendorCredentials(
          integrator.integratorsId,
          directoryId,
          vendorCredentials.bankAccounts_JsonId,
          { json, businessesId, isActive: true, modifiedBy: emailAddress },
        );

        return vendorCredentials;
      } else {
        const result: any = await bankIntegratorsService.addVendorCredentials(
          integrator.integratorsId,
          directoryId,
          { json, businessesId },
        );

        if (result?.id) {
          await bankIntegratorsService.linkVendorCredentialsToBankAccount(
            integrator.integratorsId,
            directoryId,
            result.id,
            bankAccount.bankAccountsId,
          );

          return {
            bankAccounts_JsonId: result.id,
            vendorCredentials: JSON.parse(window.atob(json)),
            createdOn: new Date().toISOString(),
          };
        }
      }

      return null;
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
);

export const updateBankAccountRanges: any = createAsyncThunk(
  'bankAccounts/updateBankAccountRanges',
  async (
    {
      ranges,
      omitted,
    }: {
      ranges: bankAccountsService.BankAccountCheckRangeType[];
      omitted: bankAccountsService.BankAccountCheckType[];
    },
    thunkAPI,
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { bankAccountsId } = getBankAccount(state) as BankAccount;
      const { emailAddress } = getPerson(state);

      const checkRanges: bankAccountsService.BankAccountCheckRangeType[] =
        getBankAccountCheckRanges(state);
      const checkOmitted: bankAccountsService.BankAccountCheckType[] =
        getBankAccountChecks(state);

      const mappedRanges: bankAccountsService.BankAccountCheckRangeType[] =
        mapCheckRanges(ranges, checkRanges, { bankAccountsId, emailAddress });

      const mappedOmitted: bankAccountsService.BankAccountCheckType[] =
        mapChecks(omitted, checkOmitted, { bankAccountsId, emailAddress });

      if (mappedRanges.length || mappedOmitted.length) {
        await bankAccountsService.addUpdateBanksAccountsChecksAndRanges({
          checkRanges: mappedRanges,
          checkNumberOmits: mappedOmitted,
          modifiedBy: emailAddress,
          bankAccountsId,
        });
      }
    } catch (e: any) {
      const error = getResponseErrorMessages(e.response, true);

      if (error) {
        return thunkAPI.rejectWithValue(error);
      }

      return Promise.reject(e);
    }
  },
);

export const removeBankAccountFile: any = createAsyncThunk(
  'bankAccounts/removeBankAccountFile',
  async (_: void, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      const { bankAccount } = getBankAccountState(state);
      const { emailAddress } = getPerson(state);

      if (bankAccount) {
        await bankAccountsService.updateBankAccountsBlobReference({
          bankAccountsId: bankAccount.bankAccountsId,
          modifiedBy: emailAddress,
          isLogging: true,
        });
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const connectBankAccount: any = createAsyncThunk(
  'bankAccounts/connectBankAccount',
  async (account: BankAccount, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      const { emailAddress } = getPerson(state);

      const fields: Partial<BankAccount> = {
        bankAccountsId: account.bankAccountsId,
        bankAccountStatusId: BankAccountStatusIds.connected,
        businessesId: account.businessesId,
        modifiedBy: emailAddress,
      };

      const formData = buildFormData(fields);

      const bankAccountId: any = await bankAccountsService.updateBankAccounts(
        formData,
      );

      if (bankAccountId) {
        const bankAccounts = getAccounts(state);
        const accounts = bankAccounts.slice();

        return accounts.map((bankAccount: BankAccount) => {
          if (bankAccount.bankAccountsId === bankAccountId) {
            return {
              ...bankAccount,
              ...fields,
            };
          }

          return bankAccount;
        });
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const disconnectBankAccount: any = createAsyncThunk(
  'bankAccounts/disconnectBankAccount',
  async (bankAccountsId: number, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      const { bankAccounts } = getBankAccountState(state);
      const { emailAddress } = getPerson(state);
      const bpsTrack = getBPSTrack(state);

      const fields = {
        bankAccountsId,
        modifiedBy: emailAddress,
        isActive: false,
      };

      const bankAccountId: any =
        await bankAccountsService.updateIsActiveBankAccounts(fields);
      const accounts = bankAccounts.slice();

      if (bpsTrack?.bankAccountId === bankAccountId) {
        thunkApi.dispatch(setDefaultBankAccountId(0));
        thunkApi.dispatch(
          updateBPSTrack({
            ...bpsTrack,
            bankAccountId: 0,
            modifiedBy: emailAddress,
          }),
        );
      }

      return accounts.filter(
        (account) => account.bankAccountsId !== bankAccountId,
      );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadFile: any = createAsyncThunk(
  'businessInfo/downloadFile',
  async ({ blobId, bankAccountId, fileName }: any) => {
    try {
      const businessId: number = getBusinessesId();

      const file: any = await getBankAccountBlobFile(
        businessId,
        bankAccountId,
        blobId,
      );

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadGuide: any = createAsyncThunk(
  'businessInfo/downloadGuide',
  async ({ guid, filename }: any) => {
    try {
      const file: any = await bankIntegratorsService.getBankIntegratorsFile(
        guid,
      );

      fileDownload(file, filename);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createFundingAccount: any = createAsyncThunk(
  'bankAccounts/createFundingAccount',
  async (data: any, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();
      const person = getPerson(thunkAPI.getState() as RootState);

      const formData = buildFormData({
        businessesId,
        createdBy: person.emailAddress,
        isActive: true,
        banksIntegratorsId: 0,
        integratorDirectoryId: 0,
      });

      const bankAccountId = await bankAccountsService.addBankAccounts(formData);

      const bankAccounts = await bankAccountsService.getBankAccounts(
        bankAccountId,
      );

      if (!bankAccounts?.length) {
        return null;
      }

      const parsePhone = parsePhoneNumber(data.phone);

      const phoneNumberId = await addTelephoneNumbers({
        countryCallingCode: `+${parsePhone?.countryCallingCode || 1}-`,
        telephoneNumber: `${parsePhone?.nationalNumber || ''}`,
        createdBy: person.emailAddress,
      });

      const emailAddressId = await addEmailAddress({
        emailAddress: data.email,
        createdBy: person.emailAddress,
      });

      await addFundingAccount(bankAccounts[0].referenceId || '', {
        bank: {
          createdBy: person.emailAddress,
          telephoneNumbersId: phoneNumberId,
          emailAddressesId: emailAddressId,
          note: data.note,
        },
      });

      return bankAccounts[0];
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchFundingAccount: any = createAsyncThunk(
  'bankAccounts/fetchFundingAccount',
  async (referenceId: string) => {
    try {
      const result = await getFundingAccount(referenceId);
      return { ...result, referenceId };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveFundingAccount: any = createAsyncThunk(
  'bankAccounts/saveFundingAccount',
  async (data: any, thunkAPI) => {
    try {
      const person = getPerson(thunkAPI.getState() as RootState);

      const { referenceId, id, ...payload } = data;

      const parsePhone = parsePhoneNumber(payload.phone);

      const phoneNumberId = await addTelephoneNumbers({
        countryCallingCode: `+${parsePhone?.countryCallingCode || 1}-`,
        telephoneNumber: `${parsePhone?.nationalNumber || ''}`,
        createdBy: person.emailAddress,
      });

      const emailAddressId = await addEmailAddress({
        emailAddress: payload.email,
        createdBy: person.emailAddress,
      });

      const result = await updateFundingAccount(referenceId, id, {
        bank: {
          modifiedBy: person.emailAddress,
          telephoneNumbersId: phoneNumberId,
          emailAddressesId: emailAddressId,
          note: payload.note,
        },
      });

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
