import { createSlice } from '@reduxjs/toolkit';

import { fetchTenant } from './thunks';
import { parseJson } from 'utils/helpers';

export interface LoadingState {
  isLoading: boolean;
}

export interface ThemeState {
  domain: string;
  tenant: {
    tenantsId: number;
    name: string;
    supportEmailAddress: string;
    telephoneNumber: string;
    logoFileShareUri: string | null;
    logoSecondaryFileShareUri: string | null;
    faviconFileShareUri: string | null;
    tenantUri: string | null;
  };
  theme: {
    backgroundColor: {
      buton: {
        primary: {
          base: string;
          hover: string;
        };
        secondery: {
          base: string;
          hover: string;
        };
      };
      progressLine: {
        primary: {
          base: string;
        };
      };
      bage: {
        primary: {
          base: string;
        };
      };
    };
    textColor: {
      primary: {
        base: string;
        hover: string;
      };
      secondery: {
        base: string;
      };
    };
    borderColor: {
      primary: {
        base: string;
        focus: string;
      };
    };
  };
}

export interface ITheme extends ThemeState, LoadingState {}

export const initialState: ITheme = {
  isLoading: true,
  domain: '',
  tenant: {
    tenantsId: 0,
    name: '',
    supportEmailAddress: 'transcard@smart-enroll.com',
    telephoneNumber: '',
    logoFileShareUri: '',
    logoSecondaryFileShareUri: '',
    faviconFileShareUri: '',
    tenantUri: '',
  },
  theme: {
    backgroundColor: {
      buton: {
        primary: {
          base: '#2563eb',
          hover: '#3b82f6',
        },
        secondery: {
          base: '#2563eb',
          hover: '#3b82f6',
        },
      },
      bage: {
        primary: {
          base: '#dbeafe',
        },
      },
      progressLine: {
        primary: {
          base: '#2563eb',
        },
      },
    },
    textColor: {
      primary: {
        base: '#2563eb',
        hover: '#3b82f6',
      },
      secondery: {
        base: '#0369a1',
      },
    },
    borderColor: {
      primary: {
        base: '#2563eb',
        focus: '#2563eb',
      },
    },
  },
};

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setDomain(state, action) {
      state.domain = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTenant.fulfilled, (state, action) => {
      if (action.payload) {
        const parsedJson = parseJson(action.payload.json);

        if (parsedJson) {
          state.theme = {
            ...state.theme,
            ...parsedJson,
          };
        }

        state.tenant = action.payload;
      }

      state.isLoading = false;
    });
    builder.addCase(fetchTenant.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchTenant.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setDomain } = theme.actions;
export default theme.reducer;
