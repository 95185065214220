import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import * as businessFinService from 'services/businessFinancials';
import { DashboardState, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import { FinancialState } from 'entities/financial';
import { getBusinessesId } from 'utils/authService';
import { ProfileState } from 'entities/profile';
import { RootState } from 'state/store';

import { fetchBusinessMerchant } from '../MerchantServices/thunks';
import {
  mapCreateBusinessFinancialFields,
  mapUpdateBusinessFinancialFields,
  mappedBusinessFinancial,
  getParsedBusinessFinancialJsonData,
  getBlobFormData,
  calcFinancialPercentage,
  mapBlobReference,
} from './utils';
import {
  startLoading,
  stopLoading,
  setLoadingProgress,
} from './financialSlice';

export const checkFinancialProgress = createAsyncThunk(
  'financial/checkFinancialProgress',
  async (data: any) => {
    try {
      return calcFinancialPercentage(data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const calculateFinancialProgress = createAsyncThunk(
  'financial/calculateFinancialProgress',
  async (_: void, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, merchant, financial } = getState() as RootState;

      const { business } = profile;
      const { currentProcessor } = merchant;
      const { businessFinancial } = financial;

      if (businessFinancial?.businessesFinancialsId) {
        const businessFinData =
          await businessFinService.getAllDataBusinessesFinancialsByBusinessId(
            business.businessesId,
          );

        const mappedFinancial = businessFinData
          ? mappedBusinessFinancial(businessFinData)
          : null;

        const parsedData = getParsedBusinessFinancialJsonData(businessFinData);

        const payload = {
          currentProcessor: currentProcessor?.value ?? null,
          merchant: merchant.merchant,
          businessFinancial: mappedFinancial,
          ...parsedData,
        };

        dispatch(checkFinancialProgress(payload));
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchFinancialData = createAsyncThunk(
  'financial/fetchFinancialData',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewFinancialPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.financialInfo,
      );

      if (!viewFinancialPermission) {
        return null;
      }

      const businessesId = getBusinessesId();

      const businessFinancial =
        await businessFinService.getAllDataBusinessesFinancialsByBusinessId(
          businessesId,
        );

      const currentCurrency =
        businessFinancial?.currencyCodesAlphaCode || 'USD';

      const mappedFinancial = businessFinancial
        ? mappedBusinessFinancial(businessFinancial)
        : null;

      const parsedData = getParsedBusinessFinancialJsonData(businessFinancial);

      const result = await thunkAPI.dispatch(fetchBusinessMerchant());

      const response = {
        currentProcessor: result.payload?.processor_MerchantsId ?? null,
        merchant: result.payload,
        businessFinancial: mappedFinancial,
        currentCurrency,
        ...parsedData,
      };

      thunkAPI.dispatch(checkFinancialProgress(response));

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createBusinessFinancial = createAsyncThunk(
  'financial/createBusinessFinancial',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, merchant } = getState() as RootState;

      const { business, person } = profile;
      const { currentProcessor } = merchant;

      const fields = mapCreateBusinessFinancialFields({
        data,
        business,
        person,
      });

      const financialId =
        await businessFinService.insertAllDataBusinessesFinancials(fields);

      if (financialId) {
        const businessFinancial =
          await businessFinService.getAllDataBusinessesFinancialsByBusinessId(
            business.businessesId,
          );

        const mappedFinancial = businessFinancial
          ? mappedBusinessFinancial(businessFinancial)
          : null;

        const parsedData =
          getParsedBusinessFinancialJsonData(businessFinancial);

        const payload = {
          currentProcessor: currentProcessor?.value ?? null,
          merchant: merchant.merchant,
          businessFinancial: mappedFinancial,
          ...parsedData,
        };

        dispatch(checkFinancialProgress(payload));

        return payload;
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusinessFinancial = createAsyncThunk(
  'financial/updateBusinessFinancial',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, financial, merchant } = getState() as RootState;
      const { person, business } = profile;
      const { currentProcessor } = merchant;
      const { businessFinancial } = financial;

      if (!business?.businessesId) {
        return null;
      }

      if (!businessFinancial) {
        dispatch(createBusinessFinancial(data));
        return null;
      }

      const fields = mapUpdateBusinessFinancialFields({
        businessFinancial,
        data,
        person,
      });

      const financialId =
        await businessFinService.updateAllDataBusinessesFinancials(fields);

      if (financialId) {
        const updatedFinancial =
          await businessFinService.getAllDataBusinessesFinancialsByBusinessId(
            businessFinancial.businessesId,
          );

        const mappedFinancial = updatedFinancial
          ? mappedBusinessFinancial(updatedFinancial)
          : null;

        const parsedData = getParsedBusinessFinancialJsonData(updatedFinancial);

        const payload = {
          currentProcessor: currentProcessor?.value ?? null,
          merchant: merchant.merchant,
          businessFinancial: mappedFinancial,
          ...parsedData,
        };

        dispatch(checkFinancialProgress(payload));

        return payload;
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createBlobReference = createAsyncThunk(
  'financial/createBlobReference',
  async ({ files, type }: any, thunk) => {
    const { getState, dispatch } = thunk;

    try {
      const { profile, financial, dashboard } = getState() as {
        profile: ProfileState;
        financial: FinancialState;
        dashboard: DashboardState;
      };
      const { person, business } = profile;
      const { mappedBlobReferencesTypes } = dashboard.dropdowns;
      const blobReferencesTypesId = mappedBlobReferencesTypes[type] || 0;

      const blobReferences = financial.blobReferences.slice();

      dispatch(startLoading(type));

      const options = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);

          if (percent < 100) {
            dispatch(setLoadingProgress(percent));
          } else {
            dispatch(setLoadingProgress(99));
          }
        },
      };

      for (let i = 0; i < files.length; i++) {
        const formData = getBlobFormData({
          file: files[i],
          person,
          business,
          blobReferencesTypesId,
        });

        const blobId =
          await businessFinService.addBusinessesFinancialsBlobReferences(
            formData,
            options,
          );

        if (blobId) {
          dispatch(setLoadingProgress(100));

          setTimeout(() => {
            dispatch(stopLoading());
          }, 2000);

          const blobReference =
            await businessFinService.getBusinessesFinancialsBlobReferences(
              blobId,
            );

          if (blobReference) {
            blobReferences.push(mapBlobReference(blobReference));
          }
        } else {
          dispatch(stopLoading());
        }
      }

      return blobReferences;
    } catch (e) {
      dispatch(stopLoading());
      return Promise.reject(e);
    }
  },
);

export const deleteBlobReference = createAsyncThunk(
  'financial/deleteBlobReference',
  async (id: number, thunk) => {
    const { getState } = thunk;

    try {
      const { profile, financial } = getState() as {
        profile: ProfileState;
        financial: FinancialState;
      };
      const { person } = profile;

      const blobReferences = financial.blobReferences.slice();

      const blobId =
        await businessFinService.updateIsActiveBusinessesFinancialsBlobReferences(
          {
            businessesFinancialsBlobReferencesID: id,
            modifiedBy: person.emailAddress,
            isActive: false,
          },
        );

      if (blobId) {
        return blobReferences.filter(
          (blob) => blob.businessesFinancialsBlobReferencesId !== id,
        );
      }

      return blobReferences;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadBlobReference = createAsyncThunk(
  'businessInfo/downloadBlobReference',
  async ({ guid, fileName }: any) => {
    try {
      const file =
        await businessFinService.getBusinessesFinancialsBlobReferencesFile(
          guid,
        );

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
