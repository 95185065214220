import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import {
  MultiValue,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  components,
} from 'react-select';

import { AvatarIcon, CloseIcon, PlusIcon } from 'components/icons';

type Member = {
  avatar: string;
  label: string;
  value: string;
};

const NewOption = ({ email }: { email: string }) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="flex items-center text-sm">
      <PlusIcon className="text-gray-400" />
      <span className="ml-3 text-gray-700">
        {t('dashboard:invitePersons.inviteViaEmail', { email })}
      </span>
    </div>
  );
};

const formatOptionLabel = (option: any, { context }: { context: any }) => {
  return context === 'menu' && option.__isNew__ ? (
    <NewOption email={option.value} />
  ) : (
    <div className="flex items-center bg-gray-100 rounded-full pl-2 pr-3 py-1">
      {option.avatar ? (
        <img src={option.avatar} alt={option.label} />
      ) : (
        <AvatarIcon className="text-gray-300" />
      )}
      <span className="ml-3 text-gray-900">{option.value}</span>
    </div>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<Member>) => (
  <components.MultiValueRemove {...props}>
    <CloseIcon className="text-gray-400" />
  </components.MultiValueRemove>
);

const MultiValueLabel = (props: MultiValueGenericProps<Member>) => {
  const { data } = props;
  return (
    <div className="flex items-center text-sm">
      {data.avatar ? (
        <img src={data.avatar} alt={data.label} />
      ) : (
        <AvatarIcon className="text-gray-500" />
      )}
      <span className="font-semibold ml-3 mr-2 text-gray-900">
        {data.label}
      </span>
    </div>
  );
};

export default function Autocomplete(props: any) {
  const { onChange, hasError, disabled, ...otherProps } = props;

  const handleChange = (newValue: MultiValue<Member>) => {
    onChange(newValue);
  };

  return (
    <CreatableSelect
      {...otherProps}
      isDisabled={disabled}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        MultiValueLabel,
        MultiValueRemove,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          borderColor: state.isFocused ? '#6366f1' : '#d1d5db',
          borderRadius: '6px 0 0 6px',
          boxShadow: 'none',
          ':hover': {
            borderColor: state.isFocused ? '#6366f1' : '#d1d5db',
          },
          minHeight: 40,
        }),
        input: (base) => ({
          ...base,
          cursor: 'text',
          ' input:focus': {
            boxShadow: 'none',
          },
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: '0.875rem',
        }),
        singleValue: (base) => ({
          ...base,
          display: 'flex',
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: '#f9fafb',
          borderRadius: 20,
          padding: 6,
        }),
        multiValueRemove: (base) => ({
          ...base,
          ':hover': {},
        }),
        option: (base) => ({
          ...base,
          cursor: 'pointer',
          wordBreak: 'break-all',
        }),
        menu: (base) => ({
          ...base,
          width: 'auto',
          maxWidth: 'calc(100% - 12px)',
          marginTop: -5,
          marginLeft: 12,
        }),
      }}
    />
  );
}
