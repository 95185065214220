import { createSlice } from '@reduxjs/toolkit';

import { fetchCommonDropdowns } from 'pages/dashboard/thunks';
import { clearDashboard } from 'pages/dashboard/thunks';
import {
  DemographicType,
  GeographicType,
  IndustryType,
  MerchantCategoryCode,
} from 'entities/dashboard';

import {
  addDocument,
  findBusinessPlan,
  deleteBusinessPlan,
  createBusinessPlan,
  getBusinessPlanDocumentById,
  getFile,
  getBusinessPlanById,
  updateDocument,
  updateBusinessLogo,
  findBusinessLogo,
  deleteBusinessLogo,
  uploadLogo,
} from './thunks';

export interface BusinessPlan {
  step: number | null;
  stepsLength: number;
  isPlanAttached: boolean;
  progressPercent: number;
  isBusinessPlanLoading: boolean;
  files: any;
  isLoading: boolean;
  isDownalod: boolean;
  businessPlan: any;
  industryTypes: IndustryType[];
  geogrphicTypes: GeographicType[];
  demographicTypes: DemographicType[];
  merchantCategoryCodes: MerchantCategoryCode[];
  logoFiles: any;
  isLogoUpload: boolean;
  isDocumentLoading: boolean;
}

export const initialState: BusinessPlan = {
  step: null,
  stepsLength: 5,
  progressPercent: 0,
  files: [],
  logoFiles: [],
  isPlanAttached: true,
  isLoading: false,
  isLogoUpload: false,
  isBusinessPlanLoading: false,
  isDocumentLoading: false,
  isDownalod: false,
  industryTypes: [],
  geogrphicTypes: [],
  demographicTypes: [],
  merchantCategoryCodes: [],
  businessPlan: {
    sellProducts: false,
    sellServices: false,
    sellOther: false,
    industryTypesId: null,
    kindofProducts: '',
    likeYourProducts: '',
    instore: false,
    online: false,
    others: false,
    currencyCodesId: null,
    storeSales: '',
    onlieSales: '',
    adultBuy: false,
    teenBuy: false,
    childrenBuy: false,
    infantBuy: false,
    businessProducts: false,
    personalProducts: false,
    demographicTypesId: null,
    geogrphicTypesId: null,
    customerIndustryTypesId: null,
    purchaseReason: '',
    bigBusiness: false,
    mediumBusiness: false,
    smallBusiness: false,
    emailAds: false,
    onlineAds: false,
    socialMediaAds: false,
    printedAds: false,
    phoneCalls: false,
    othersAds: false,
    typeInstore: false,
    typeOnline: false,
    typeOthers: false,
    expensesJSON: [],
    marketingJSON: [],
    rolesJSON: [],
    ownershipsJSON: [],
    competitorsJSON: [],
  },
};

const businessPlanSlice = createSlice({
  name: 'businessPlan',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
    setAttachedPlanState(state) {
      state.isPlanAttached = !state.isPlanAttached;
    },
    setProgressPrecent(state, action) {
      state.progressPercent = action.payload;
    },
    cleanFiles(state) {
      state.files = [];
    },
    setBusinessPlan(state, action) {
      const { key, value } = action.payload;
      state.businessPlan[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(addDocument.fulfilled, (state, action) => {
      state.progressPercent = 0;
      state.files = action.payload;
    });
    builder.addCase(addDocument.rejected, (state) => {
      state.progressPercent = 0;
    });

    //uploadLogo
    builder.addCase(uploadLogo.fulfilled, (state, action) => {
      state.progressPercent = 0;
      state.logoFiles = action.payload;
    });
    builder.addCase(uploadLogo.rejected, (state) => {
      state.progressPercent = 0;
    });

    //updateDocument
    builder.addCase(updateDocument.fulfilled, (state, action) => {
      state.progressPercent = 0;
      state.files = action.payload;
    });
    builder.addCase(updateDocument.rejected, (state) => {
      state.progressPercent = 0;
    });

    //updateBusinessLogo
    builder.addCase(updateBusinessLogo.pending, (state) => {
      state.logoFiles = [];
    });
    builder.addCase(updateBusinessLogo.fulfilled, (state, action) => {
      state.progressPercent = 0;
      state.logoFiles = action.payload;
    });
    builder.addCase(updateBusinessLogo.rejected, (state) => {
      state.progressPercent = 0;
    });

    // findBusinessPlan
    builder.addCase(findBusinessPlan.fulfilled, (state, action) => {
      state.progressPercent = 0;
      state.files = action.payload;
      state.isLoading = false;
    });
    builder.addCase(findBusinessPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(findBusinessPlan.rejected, (state) => {
      state.isLoading = false;
    });

    // findBusinessLogo
    builder.addCase(findBusinessLogo.fulfilled, (state, action) => {
      state.progressPercent = 0;
      state.logoFiles = action.payload;
      state.isLogoUpload = false;
    });
    builder.addCase(findBusinessLogo.pending, (state) => {
      state.isLogoUpload = true;
    });
    builder.addCase(findBusinessLogo.rejected, (state) => {
      state.isLogoUpload = false;
    });
    //createBusinessPlan
    builder.addCase(createBusinessPlan.fulfilled, (state) => {
      state.isBusinessPlanLoading = false;
      state.step = 6;
    });
    builder.addCase(createBusinessPlan.pending, (state) => {
      state.isBusinessPlanLoading = true;
    });
    builder.addCase(createBusinessPlan.rejected, (state) => {
      state.isBusinessPlanLoading = false;
    });

    // deleteBusinessLogo
    builder.addCase(deleteBusinessLogo.fulfilled, (state, action) => {
      const id = action.payload;
      state.logoFiles = state.logoFiles.filter(
        ({ businessesPlanBlobReferencesId }) =>
          businessesPlanBlobReferencesId !== id,
      );
      state.isLogoUpload = false;
    });
    builder.addCase(deleteBusinessLogo.pending, (state) => {
      state.isLogoUpload = true;
    });
    builder.addCase(deleteBusinessLogo.rejected, (state) => {
      state.isLogoUpload = false;
    });

    //deleteBusinessPlan
    builder.addCase(deleteBusinessPlan.fulfilled, (state, action) => {
      const id = action.payload;
      state.files = state.files.filter(
        ({ businessesPlanBlobReferencesId }) =>
          businessesPlanBlobReferencesId !== id,
      );
      state.businessPlan = initialState.businessPlan;
      state.isLoading = false;
    });
    builder.addCase(deleteBusinessPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBusinessPlan.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchCommonDropdowns.fulfilled, (state, action) => {
      const {
        industryTypes,
        geogrphicTypes,
        demographicTypes,
        merchantCategoryCodes,
      } = action.payload;

      state.isLoading = false;
      state.industryTypes = industryTypes;
      state.geogrphicTypes = geogrphicTypes;
      state.demographicTypes = demographicTypes;
      state.merchantCategoryCodes = merchantCategoryCodes;
    });

    //getBusinessPlanDocumentById
    builder.addCase(getBusinessPlanDocumentById.fulfilled, (state) => {
      state.isDownalod = false;
    });
    builder.addCase(getBusinessPlanDocumentById.pending, (state) => {
      state.isDownalod = true;
    });
    builder.addCase(getBusinessPlanDocumentById.rejected, (state) => {
      state.isDownalod = false;
    });

    //getFile
    builder.addCase(getFile.fulfilled, (state) => {
      state.isDownalod = false;
    });
    builder.addCase(getFile.pending, (state) => {
      state.isDownalod = true;
    });
    builder.addCase(getFile.rejected, (state) => {
      state.isDownalod = false;
    });

    //getBusinessPlanById
    builder.addCase(getBusinessPlanById.fulfilled, (state, action) => {
      if (action.payload) {
        state.businessPlan = action.payload;
        state.step = 1;
        state.isDocumentLoading = false;
        return;
      }
      state.businessPlan = initialState.businessPlan;
      state.isDocumentLoading = false;
    });
    builder.addCase(getBusinessPlanById.pending, (state) => {
      state.isDocumentLoading = true;
    });
    builder.addCase(getBusinessPlanById.rejected, (state) => {
      state.isDocumentLoading = false;
    });
  },
});

export const {
  setStep,
  setAttachedPlanState,
  setProgressPrecent,
  cleanFiles,
  setBusinessPlan,
} = businessPlanSlice.actions;

export default businessPlanSlice.reducer;
