import { FieldValues, useForm } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useCurrentUser, useAppSelector } from 'hooks';
import { AddUserIcon } from 'components/icons/AddUserIcon';
import { hasPermission } from 'pages/dashboard/selectors';
import { getPerson } from 'pages/Profile/selectors';
import { getUserEmail } from 'utils/authService';
import { Permissions } from 'entities/dashboard';
import { DomainType } from 'utils/domainService';
import { isEmailValid } from 'utils/helpers';
import { getDomain } from 'theme/selectors';
import Modal from 'components/Modal';
import Tabs from 'components/Tabs';

import { fetchBusinessMembers, searchPersonByEmail } from '../thunks';
import { setErrors } from '../invitePersonsSlice';
import BusinessMembers from './BusinessMembers';
import { getModalActions } from './utils';
import InviteForm from './InviteForm';
import {
  getInvitePersonsData,
  getValidPermissions,
  getVisiblePersons,
  getShowMembers,
} from '../selectors';

type Member = {
  avatar: string;
  label: string;
  value: string;
};

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const { user } = useCurrentUser();

  const { personsId, givenName1, surnameFirst } = useAppSelector(getPerson);
  const showMembers: boolean = useAppSelector(getShowMembers);
  const { isLoading } = useAppSelector(getInvitePersonsData);
  const persons = useAppSelector(getVisiblePersons);
  const domain: string = useAppSelector(getDomain);
  const isPermissionsValid = useAppSelector(getValidPermissions);
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const [selected, setSelected] = useState<Member | null>(null);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const defaultValues = useMemo(
    () => ({
      message: `${t('invitePersons.defaultMessage', {
        name: `${givenName1} ${surnameFirst}`,
        brand: 'Transcard',
      })}${
        domain === DomainType.invoicecloud
          ? ''
          : t('invitePersons.defaultMessageInitiated', { brand: 'Transcard' })
      }.`,
    }),
    [givenName1, surnameFirst, domain, t],
  );

  const { register, getValues, reset } = useForm<FieldValues>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    setSelected(null);
  }, [reset, defaultValues, isOpen]);

  useEffect(() => {
    if (showMembers && personsId && isOpen) {
      dispatch(fetchBusinessMembers());
    }
  }, [showMembers, personsId, dispatch, isOpen]);

  const handleOnChange = (value: Member) => {
    setSelected(value);
  };

  const isFormValid = () => {
    if (!selected?.value || !isEmailValid(selected.value)) {
      dispatch(
        setErrors({
          InvitedUserEmailAddress: t('invitePersons.emailError'),
        }),
      );
      return false;
    }

    if (!isPermissionsValid) {
      dispatch(
        setErrors({
          permissionsRequired: t('invitePersons.permissionsError'),
        }),
      );
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (isFormValid() && invitePermissions.edit) {
      const { message } = getValues();

      dispatch(
        searchPersonByEmail({
          email: selected?.value,
          userEmail: user?.username || getUserEmail(),
          onClose,
          message,
        }),
      );
    }
  };

  const inviteTabs = [
    {
      name: t('invitePersons.inviteTab'),
      isAdmin: false,
    },
    {
      name: t('invitePersons.membersTab'),
      badge: persons.length ? `${persons.length}` : '',
      isAdmin: true,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      maxWidth="auto"
      title={t('invitePersons.title')}
      icon={<AddUserIcon className="w-6 h-6 text-gray-500" />}
      actions={getModalActions(
        currentTab,
        t,
        handleSubmit,
        isLoading,
        invitePermissions,
      )}
      closeTimeoutMS={0}
    >
      <Tabs
        tabs={inviteTabs.filter((tab) => !tab.isAdmin || showMembers)}
        currentTab={currentTab}
        onChange={(index) => setCurrentTab(index)}
        className="px-6 pt-2 border-b border-gray-200 space-x-8"
        tabClassName="border-b-2"
      >
        {currentTab === 0 && (
          <InviteForm
            selected={selected}
            onChange={handleOnChange}
            register={register}
          />
        )}
        {currentTab === 1 && <BusinessMembers />}
      </Tabs>
    </Modal>
  );
};

export default InviteModal;
