import { useTranslation } from 'react-i18next';

import { StatusIconTypes } from 'components/Tabs';
import { useAppSelector } from 'hooks';

import { getOnboardingTabs } from '../selectors';

const colors = {
  [StatusIconTypes.success]: 'text-green-500',
  [StatusIconTypes.progress]: 'text-blue-500',
  [StatusIconTypes.error]: 'text-yellow-500',
  [StatusIconTypes.empty]: 'text-gray-500',
};

const OnboardingTabPopover = ({ code }) => {
  const { t } = useTranslation('common');

  const onboardingTabs = useAppSelector(getOnboardingTabs);

  const currentTab = onboardingTabs.find((tab) => tab.key === code);

  if (!currentTab) {
    return null;
  }

  return (
    <div className="bg-gray-900 rounded-lg py-2 px-[10px]">
      <div className="text-sm font-medium text-gray-50 flex items-center">
        <div>
          {currentTab.progress?.enteredFields ===
          currentTab.progress?.totalFields
            ? `${t('completed')}!`
            : t('inProgress')}
        </div>
        <div className="ml-2 font-semibold">
          <span className={colors[currentTab.statusIcon]}>
            {currentTab.progress?.enteredFields || 0}
          </span>
          <span className="text-gray-300">
            &nbsp;/&nbsp;{currentTab.progress?.totalFields || 0}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OnboardingTabPopover;
