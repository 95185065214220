import { getApplicationStatuses } from 'pages/dashboard/selectors';
import { useAppSelector } from 'hooks';
import Badge from 'components/Badge';
import {
  ClipboardCopyIcon,
  ProgressIcon,
  CloseCircleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from 'components/icons';
import {
  ApplicationStatuses,
  ApplicationStatusColors,
} from 'entities/progress';

const styles = {
  [ApplicationStatuses.pendingEnrollment]: {
    icon: <ClipboardCopyIcon className="text-yellow-400 mr-1 w-full h-full" />,
    color: ApplicationStatusColors[ApplicationStatuses.pendingEnrollment],
    description: 'text-yellow-700',
  },
  [ApplicationStatuses.pendingApproval]: {
    icon: <ProgressIcon className="text-blue-400 mr-1 w-full h-full" />,
    color: ApplicationStatusColors[ApplicationStatuses.pendingApproval],
    description: 'text-blue-700',
  },
  [ApplicationStatuses.suspended]: {
    icon: <CloseCircleIcon className="text-red-400 mr-1 w-full h-full" />,
    color: ApplicationStatusColors[ApplicationStatuses.suspended],
    description: 'text-red-700',
  },
  [ApplicationStatuses.declined]: {
    icon: <ExclamationCircleIcon className="text-red-400 mr-1 w-full h-full" />,
    color: ApplicationStatusColors[ApplicationStatuses.declined],
    description: 'text-red-700',
  },
  [ApplicationStatuses.approved]: {
    icon: <CheckCircleIcon className="text-green-400 mr-1 w-full h-full" />,
    color: ApplicationStatusColors[ApplicationStatuses.approved],
    description: 'text-green-700',
  },
  [ApplicationStatuses.complianceApproved]: {
    icon: <CheckCircleIcon className="text-green-400 mr-1 w-full h-full" />,
    color: ApplicationStatusColors[ApplicationStatuses.approved],
    description: 'text-green-700',
  },
};

type ApplicationStatusProps = {
  status: string | null;
  withDescription?: boolean;
};

const ApplicationStatus: React.FC<ApplicationStatusProps> = ({
  status,
  withDescription,
}) => {
  const statuses = useAppSelector(getApplicationStatuses);

  if (!status) {
    return null;
  }

  return (
    <Badge
      size={withDescription ? 'large' : 'small'}
      color={styles[status]?.color || 'blue'}
      className={withDescription ? 'px-3 py-3 w-full' : 'pl-[3px] pr-2'}
      rounded={`rounded-${withDescription ? 'md' : 'sm'}`}
    >
      <div className={withDescription ? 'flex' : 'flex items-center'}>
        {withDescription ? (
          <div className="w-5 h-5 flex-shrink-0">
            {styles[status]?.icon || null}
          </div>
        ) : (
          <div className="w-[14px] h-[14px] flex-shrink-0">
            {styles[status]?.icon || null}
          </div>
        )}
        <div className={withDescription ? 'pl-2' : 'pl-[2px]'}>
          <div className={withDescription ? 'mb-2' : ''}>{status}</div>
          {withDescription && (
            <div
              className={`${styles[status]?.description} font-normal text-justify pr-3`}
            >
              {statuses.find((s) => s.typeCode === status)?.description}
            </div>
          )}
        </div>
      </div>
    </Badge>
  );
};

export default ApplicationStatus;
