import { createSlice } from '@reduxjs/toolkit';

export type DialogDataProps = {
  title: string;
  text: string;
  onClose: any;
  onCancel: any;
  onContinue: any;
  content: any;
  maxWidth: string;
  hideCross?: boolean;
  isOverlayDisabled?: boolean;
};

export interface ModalStateProps {
  isOpened: boolean;
  data: DialogDataProps;
}

export const initialState: ModalStateProps = {
  isOpened: false,
  data: {
    title: '',
    text: '',
    onClose: null,
    onCancel: null,
    onContinue: null,
    content: null,
    maxWidth: '50rem',
    hideCross: true,
    isOverlayDisabled: true,
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openDialog(state, action) {
      state.isOpened = true;
      state.data = {
        ...initialState.data,
        ...action.payload,
      };
    },
    closeDialog(state) {
      state.isOpened = false;
      state.data = initialState.data;
    },
  },
  extraReducers: () => {},
});

export const { openDialog, closeDialog } = modalSlice.actions;

export default modalSlice.reducer;
