import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { TFunction } from 'react-i18next';

import { isEmailValid, getPersonLetters } from 'utils/helpers';
import path from 'common/path';

export const emailValidation = (t: TFunction) => ({
  required: t('login:email.validation.required'),
  validate: (value: string) =>
    isEmailValid(value) || t('login:email.validation.valid'),
});

export const phoneValidation = (t: TFunction) => ({
  required: t('login:phone.validation.required'),
  validate: (value: string) =>
    isPossiblePhoneNumber(value || '') || t('login:phone.validation.valid'),
});

export const getUserInviteData = ({
  email,
  businessesId,
  message = '',
  tenant,
  resendInvite = false,
  invitedUserDisplayName = '',
}) => {
  const fields = {
    tenantsId: tenant.tenantsId,
    businessesId,
    inviteRedirectUrl: window.location.origin,
    invitedUserDisplayName,
    invitedUserEmailAddress: email,
    inviteSenderEmailAddress: tenant.supportEmailAddress,
    invitationMessage: message,
    sendInvitationMessage: false,
    resendInvite,
  };

  return fields;
};

export const getBusinessRedirectLink = (business) => {
  const {
    businessesBusinessLegalName,
    businessesDUNSNumber,
    businessesNoDUNSListed,
    businessesPersonsRole,
  } = business;

  if (
    businessesBusinessLegalName &&
    businessesPersonsRole &&
    ((businessesDUNSNumber || '').trim() || businessesNoDUNSListed)
  ) {
    return path.dashboard;
  }

  return path.profile;
};

export const getMappedBusinessesPersons = (businesses) => {
  const options: any = businesses.map(
    ({
      businessesId,
      businessesBusinessLegalName,
      businessLegalName,
      businessesDUNSNumber,
      dunsNumber,
      businessesNoDUNSListed,
      noDUNSListed,
      businessesPersonsRole,
      businessEmailAddress,
      emailAddress,
      accountholder,
      participantID,
      erpClientID,
      businessesTenantId,
      businessesTenantName,
      businessesTenantUrlSub,
    }) => ({
      name: businessesBusinessLegalName || businessLegalName || '',
      label: businessesBusinessLegalName || businessLegalName || '',
      value: businessesId,
      avatarName: getPersonLetters(
        businessesBusinessLegalName || businessLegalName || '',
      ),
      emailAddress: businessEmailAddress || emailAddress || '',
      businessesBusinessLegalName,
      businessesDUNSNumber: (businessesDUNSNumber || dunsNumber || '').trim(),
      businessesNoDUNSListed:
        businessesNoDUNSListed !== undefined
          ? businessesNoDUNSListed
          : noDUNSListed,
      businessesPersonsRole,
      accountholder,
      participantID,
      erpClientID,
      businessesTenantId,
      businessesTenantName,
      businessesTenantUrlSub,
    }),
  );

  options.sort((a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    return 0;
  });

  return options;
};
