import AuthorizedSignatory from 'components/AuthorizedSignatory';
import { useAppDispatch, useAppSelector } from 'hooks';

import { getFinancialSignatory, getAuthorizing } from '../selectors';
import { updateBusinessFinancial } from '../thunks';
import { setAuthorizing } from '../financialSlice';
import { Permissions } from 'entities/dashboard';

const FinancialSignatory = () => {
  const dispatch = useAppDispatch();

  const signatory = useAppSelector(getFinancialSignatory);
  const authorizing = useAppSelector(getAuthorizing);

  const handleAuthorize = async () => {
    dispatch(setAuthorizing(true));
    await dispatch(
      updateBusinessFinancial({ signatoryAttestationPersonsId: true }),
    );
    dispatch(setAuthorizing(false));
  };

  return (
    <div className="bg-gray-50 rounded-lg border p-6 mt-16">
      <AuthorizedSignatory
        loading={authorizing}
        signatory={signatory}
        onClick={handleAuthorize}
        permission={Permissions.financialInfo}
      />
    </div>
  );
};

export default FinancialSignatory;
