import { Navigate } from 'react-router-dom';
import { useCurrentUser } from 'hooks';

import { getOID } from 'utils/authService';
import path from 'common/path';

interface Props {
  component: React.ComponentType;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  const { isAuthenticated } = useCurrentUser();

  if (isAuthenticated || getOID()) {
    return <RouteComponent />;
  }

  return <Navigate to={path.login} />;
};

export default PrivateRoute;
