export const ClipboardCopyIcon = ({ className }: { className?: string }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.60156 1.40039C5.21496 1.40039 4.90156 1.71379 4.90156 2.10039C4.90156 2.48699 5.21496 2.80039 5.60156 2.80039H7.00156C7.38816 2.80039 7.70156 2.48699 7.70156 2.10039C7.70156 1.71379 7.38816 1.40039 7.00156 1.40039H5.60156Z"
      fill="currentColor"
    />
    <path
      d="M2.10156 3.50039C2.10156 2.72719 2.72836 2.10039 3.50156 2.10039C3.50156 3.26019 4.44176 4.20039 5.60156 4.20039H7.00156C8.16136 4.20039 9.10156 3.26019 9.10156 2.10039C9.87476 2.10039 10.5016 2.72719 10.5016 3.50039V7.70039H7.29151L8.19654 6.79537C8.4699 6.522 8.4699 6.07878 8.19654 5.80542C7.92317 5.53205 7.47995 5.53205 7.20659 5.80542L5.10659 7.90542C4.83322 8.17878 4.83322 8.622 5.10659 8.89537L7.20659 10.9954C7.47995 11.2687 7.92317 11.2687 8.19654 10.9954C8.4699 10.722 8.4699 10.2788 8.19654 10.0054L7.29151 9.10039H10.5016V11.2004C10.5016 11.9736 9.87476 12.6004 9.10156 12.6004H3.50156C2.72836 12.6004 2.10156 11.9736 2.10156 11.2004V3.50039Z"
      fill="currentColor"
    />
    <path
      d="M10.5016 7.70039H11.9016C12.2882 7.70039 12.6016 8.01379 12.6016 8.40039C12.6016 8.78699 12.2882 9.10039 11.9016 9.10039H10.5016V7.70039Z"
      fill="currentColor"
    />
  </svg>
);
