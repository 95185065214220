import { BusinessOwnerBlobReferenceSchema } from 'services/businessOwners';

export enum OwnerTypes {
  'person' = 0,
  'business' = 1,
}

export interface OwnerType {
  type: number;
  isActive: boolean;
  ownershipPercentage?: number;
  businessesOwnersPersonsId?: number;
  businessesOwnersBusinessesId?: number;
}

export interface OwnershipState {
  owners: OwnerType[];
  blobReferences: BusinessOwnerBlobReferenceSchema[];
  isLoading: boolean;
  loadingProgress: number;
}
