import fullLogo from 'assets/images/logos/smart-suite-full.svg';
import hexLogo from 'assets/images/logos/smart-suite-hex.svg';

interface SmartSuiteLogoProps {
  className?: string;
  full?: boolean;
}

const SmartSuiteLogo: React.FC<SmartSuiteLogoProps> = ({
  className,
  full = false,
}) => {
  return (
    <img
      src={full ? fullLogo : hexLogo}
      className={className}
      alt="SMART Suite"
    />
  );
};

export default SmartSuiteLogo;
