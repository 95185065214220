import { createAsyncThunk } from '@reduxjs/toolkit';

import { hasPermission } from 'pages/dashboard/selectors';
import { getBusinessesId } from 'utils/authService';
import { getPerson } from 'pages/Profile/selectors';
import { Permissions } from 'entities/dashboard';
import { RootState } from 'state/store';
import {
  BusinessBPSTrackSchema,
  BusinessBPSTrackPaymentSchema,
  addBusinessesBPSTracks,
  addBusinessesBPSTracksPayments,
  addBusinessesBPSTracksPaymentXrefs,
  updateBusinessesBPSTracks,
  updateBusinessesBPSTracksPayments,
  getAllBusinessesBpsTrackDetails,
} from 'services/businesses';

import { getBPSTrack, getBPSTrackPayments } from './selectors';
import { getMappedCreateBPSTrack } from './utils';

export const fetchBPSTrack: any = createAsyncThunk(
  'configuration/fetchBPSTrack',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewConfigPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.configuration,
      );

      if (!viewConfigPermission) {
        return null;
      }

      const businessesId: number = getBusinessesId();

      const data: any = await getAllBusinessesBpsTrackDetails(businessesId);

      if (data) {
        const { businessesBpsTracksPaymentControllerIds, ...other } = data;

        return {
          bpsTrack: other,
          payments: businessesBpsTracksPaymentControllerIds,
        };
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const addBPSTrack: any = createAsyncThunk(
  'configuration/addBPSTrack',
  async (data: Partial<BusinessBPSTrackSchema>, thunkAPI) => {
    try {
      const businessId = getBusinessesId();

      const { emailAddress } = getPerson(thunkAPI.getState() as RootState);

      const fields = getMappedCreateBPSTrack({
        ...data,
        createdBy: emailAddress,
        businessesId: businessId,
      });

      const businessesBpstracksId = await addBusinessesBPSTracks(fields);

      return { businessesBpstracksId, ...fields };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBPSTrack: any = createAsyncThunk(
  'configuration/updateBPSTrack',
  async (data: Partial<BusinessBPSTrackSchema>, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { emailAddress } = getPerson(state);
      const bpsTrack = getBPSTrack(state);

      await updateBusinessesBPSTracks({
        ...data,
        modifiedBy: emailAddress,
      });

      return {
        ...bpsTrack,
        ...data,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveBPSTrack: any = createAsyncThunk(
  'configuration/saveBPSTrack',
  async (data: Partial<BusinessBPSTrackSchema>, thunkAPI) => {
    try {
      let bpsTrack: BusinessBPSTrackSchema | null = getBPSTrack(
        thunkAPI.getState() as RootState,
      );

      if (!bpsTrack) {
        const result = await thunkAPI.dispatch(fetchBPSTrack());

        if (result.payload) {
          bpsTrack = result.payload;
        }
      }

      if (bpsTrack) {
        const { bankAccountId, businessesBpstracksId } = bpsTrack;

        thunkAPI.dispatch(
          updateBPSTrack({
            bankAccountId,
            businessesBpstracksId,
            ...data,
          }),
        );
      } else {
        thunkAPI.dispatch(addBPSTrack(data));
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const addBPSTrackPayment: any = createAsyncThunk(
  'configuration/addBPSTrackPayment',
  async (data: Partial<BusinessBPSTrackPaymentSchema>, thunkAPI) => {
    try {
      let bpsTrack: BusinessBPSTrackSchema | null = getBPSTrack(
        thunkAPI.getState() as RootState,
      );

      const payments: BusinessBPSTrackPaymentSchema[] = getBPSTrackPayments(
        thunkAPI.getState() as RootState,
      );

      const { emailAddress } = getPerson(thunkAPI.getState() as RootState);

      if (!bpsTrack) {
        const { payload } = await thunkAPI.dispatch(addBPSTrack({}));
        bpsTrack = payload;
      }

      const fields = {
        ...data,
        createdBy: emailAddress,
        businessesBPSTracksId: bpsTrack?.businessesBpstracksId,
        paymentControllerId: '0',
      };

      const businessesBpsTracksPaymentControllerIdsId: any =
        await addBusinessesBPSTracksPayments(fields);

      await addBusinessesBPSTracksPaymentXrefs({
        ...fields,
        businessesBpstracksPaymentControllerIdsId:
          businessesBpsTracksPaymentControllerIdsId,
      });

      return [
        ...payments,
        { businessesBpsTracksPaymentControllerIdsId, ...fields },
      ];
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBPSTrackPayment: any = createAsyncThunk(
  'configuration/updateBPSTrackPayment',
  async (data: Partial<BusinessBPSTrackPaymentSchema>, thunkAPI) => {
    try {
      const payments: BusinessBPSTrackPaymentSchema[] = getBPSTrackPayments(
        thunkAPI.getState() as RootState,
      );

      const { emailAddress } = getPerson(thunkAPI.getState() as RootState);

      await updateBusinessesBPSTracksPayments({
        ...data,
        modifiedBy: emailAddress,
      });

      return payments.map((p) =>
        p.businessesBpsTracksPaymentControllerIdsId ===
        data.businessesBpsTracksPaymentControllerIdsId
          ? { ...p, ...data }
          : p,
      );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveBPSTrackPayment: any = createAsyncThunk(
  'configuration/saveBPSTrackPayment',
  async (data: Partial<BusinessBPSTrackPaymentSchema>, thunkAPI) => {
    try {
      const payments: BusinessBPSTrackPaymentSchema[] = getBPSTrackPayments(
        thunkAPI.getState() as RootState,
      );

      const payment = payments.find(
        (p) => p.paymentMethod === data.paymentMethod,
      );

      if (payment) {
        await thunkAPI.dispatch(
          updateBPSTrackPayment({
            ...data,
            businessesBpsTracksPaymentControllerIdsId:
              payment.businessesBpsTracksPaymentControllerIdsId,
          }),
        );
      } else {
        await thunkAPI.dispatch(addBPSTrackPayment(data));
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
