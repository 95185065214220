import { useTranslation, Trans } from 'react-i18next';

import linkIcon from 'assets/icons/connectors/Link.svg';
import { DataSourceWidgets } from 'entities/accounting';
import { isUATDomain } from 'utils/domainService';
import Link from 'components/Link';

import DataSourceWidgetModal from './DataSourceWidgetModal';

const APIWidgetModal = () => {
  const { t } = useTranslation('integration');

  return (
    <DataSourceWidgetModal
      id={DataSourceWidgets.api}
      title="integration:dataSource.apiModalTitle"
      icon={linkIcon}
      description={
        <div>
          <p>{t('integration:dataSource.apiModalDescription')}</p>
          <p className="my-2">
            <Trans i18nKey="integration:dataSource.apiModalDescription2">
              text
              <Link
                href={
                  isUATDomain()
                    ? 'https://developer-sandbox.transcard.com/'
                    : 'https://www.transcard.com/smart-suite-api'
                }
                target="_blank"
                rel="noreferrer"
              >
                link
              </Link>
              dot
            </Trans>
          </p>
        </div>
      }
    />
  );
};
export default APIWidgetModal;
