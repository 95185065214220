import { ProductTypeCodes } from 'entities/progress';
import api from 'api';

export interface BusinessProductXrefSchema {
  approvedBy: string | null;
  approvedOn: string | null;
  businessesId: number;
  businessesProductsXrefId: number;
  createdBy: string | null;
  createdOn: string | null;
  isActive: boolean;
  isApproved: boolean;
  modifiedBy: string | null;
  modifiedOn: string | null;
  productStatusesDescription: string | null;
  productStatusesId: number | null;
  productsDescription: string | null;
  productsId: number;
  productsIsActive: boolean;
  productsTypeCode: ProductTypeCodes;
}

export const searchBusinessesProductsXrefs = async (
  payload: Partial<BusinessProductXrefSchema>,
) => {
  const results: BusinessProductXrefSchema[] = await api.post(
    'BusinessesProductsXrefs/SearchBusinessesProductsXrefs',
    payload,
  );

  return results;
};

export const updateBusinessesProductsXrefs = async (
  payload: Partial<BusinessProductXrefSchema>,
) => {
  const id: number = await api.put(
    'BusinessesProductsXrefs/UpdateBusinessesProductsXrefs',
    payload,
  );

  return id;
};
