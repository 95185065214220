export const Others = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      className={className}
    >
      <path
        d="M2.33325 0C1.22868 0 0.333252 0.89543 0.333252 2V4C0.333252 5.10457 1.22868 6 2.33325 6H4.33325C5.43782 6 6.33325 5.10457 6.33325 4V2C6.33325 0.895431 5.43782 0 4.33325 0H2.33325Z"
        fill="#6B7280"
      />
      <path
        d="M2.33325 8C1.22868 8 0.333252 8.89543 0.333252 10V12C0.333252 13.1046 1.22868 14 2.33325 14H4.33325C5.43782 14 6.33325 13.1046 6.33325 12V10C6.33325 8.89543 5.43782 8 4.33325 8H2.33325Z"
        fill="#6B7280"
      />
      <path
        d="M8.33325 2C8.33325 0.89543 9.22868 0 10.3333 0H12.3333C13.4378 0 14.3333 0.895431 14.3333 2V4C14.3333 5.10457 13.4378 6 12.3333 6H10.3333C9.22868 6 8.33325 5.10457 8.33325 4V2Z"
        fill="#6B7280"
      />
      <path
        d="M11.3333 8C11.8855 8 12.3333 8.44771 12.3333 9V10H13.3333C13.8855 10 14.3333 10.4477 14.3333 11C14.3333 11.5523 13.8855 12 13.3333 12H12.3333V13C12.3333 13.5523 11.8855 14 11.3333 14C10.781 14 10.3333 13.5523 10.3333 13V12H9.33325C8.78097 12 8.33325 11.5523 8.33325 11C8.33325 10.4477 8.78097 10 9.33325 10H10.3333V9C10.3333 8.44771 10.781 8 11.3333 8Z"
        fill="#6B7280"
      />
    </svg>
  );
};
