import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getIsLoading, getShowBusinessList } from 'pages/login/selectors';
import { useAppSelector, useCurrentUser, useAppDispatch } from 'hooks';
import { setShowBusinessList, signOut } from 'pages/login/loginSlice';
import personsServices, { PersonSchema } from 'services/persons';
import Background from 'pages/login/components/Background';
import { getUserEmail, logOut } from 'utils/authService';
import PoweredBy from 'components/layouts/PoweredBy';
import HideComponent from 'components/HideComponent';
import { LogoWithText } from 'components/Logos';
import { getTenant } from 'theme/selectors';
import Loading from 'components/Loading';
import path from 'common/path';

import BusinessListCard from './components/BusinessListCard';
import PlatformCard from './components/PlatformCard';

const Businesses = () => {
  const { t } = useTranslation('business-list');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user, logoutRedirect } = useCurrentUser();

  const [person, setPerson] = useState<PersonSchema | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const showBusinessList = useAppSelector(getShowBusinessList);
  const isLoading = useAppSelector(getIsLoading);
  const tenant = useAppSelector(getTenant);

  const handleBusinessList = async () => {
    await dispatch(setShowBusinessList(true));
  };

  const signOutAndRedirect = async () => {
    await dispatch(signOut());
    logoutRedirect();
    logOut();
    navigate(path.login);
  };

  useEffect(() => {
    const fetchPersonByEmail = async () => {
      const email = user?.username || getUserEmail();

      if (!email) {
        signOutAndRedirect();
        return;
      }

      try {
        const persons: any = await personsServices.searchPersons({
          emailAddress: email,
          isActive: true,
        });

        if (!persons || persons.length === 0) {
          navigate(path.profile);
          return null;
        }

        setPerson(persons[0]);

        if (!persons[0].globalUserId) {
          await handleBusinessList();
        }

        setLoading(false);
      } catch (error) {
        signOutAndRedirect();
      }
    };

    fetchPersonByEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="h-screen overflow-y-auto flex">
      <Loading loading={isLoading || loading} />
      <Background />
      <div className="absolute top-0 left-0">
        <LogoWithText className="mt-12 ml-16 max-h-[72px]" checkDefaultTenant />
      </div>
      <div className="flex-1">
        <div className="flex flex-col min-h-screen items-center justify-center pt-16 pb-4 px-4">
          <HideComponent hide={showBusinessList}>
            <div className="w-full">
              <HideComponent show={person?.givenName1 !== ''}>
                <p className="text-3xl font-extrabold text-center">
                  {t('platform.title', { name: person?.givenName1 })}
                </p>
              </HideComponent>
              <p className="text-lg text-gray-600 mb-8 text-center max-w-[658px] leading-6 mx-auto">
                {t('platform.description')}
              </p>
              <div className="flex items-center justify-center gap-8 mb-8">
                <PlatformCard
                  platform="Enroll"
                  handleLink={handleBusinessList}
                />
                <PlatformCard
                  platform="Hub"
                  handleLink={() => {
                    if (tenant.tenantUri) {
                      window.location.href = tenant.tenantUri.replace(
                        'smart-enroll',
                        'smart-hub',
                      );
                    }
                  }}
                />
              </div>
            </div>
          </HideComponent>

          <HideComponent show={showBusinessList}>
            <BusinessListCard person={person} />
          </HideComponent>

          <PoweredBy />
        </div>
      </div>
    </div>
  );
};

export default Businesses;
