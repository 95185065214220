import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { AngleLeftIcon, UserAddIcon } from 'components/icons';
import { hasPermission } from 'pages/dashboard/selectors';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';
import { useAppSelector } from 'hooks';

import AccountStepper from './AccountStepper';

const Confirm = ({ setStep, provider }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      disabled={!provider?.auths?.length}
      className="w-48 float-right"
      onClick={() => {
        setStep(2);
      }}
    >
      {t('next')}
    </Button>
  );
};

const ProviderListButtons = ({
  setStep,
  handleSubmitForm,
  onSubmit,
  updateIsLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <Button
        type="button"
        variant="secondary-outline"
        className="w-48"
        onClick={() => setStep(1)}
      >
        <AngleLeftIcon className="mr-3" />
        {t('back')}
      </Button>
      <Button
        type="button"
        className="min-w-[192px]"
        onClick={handleSubmitForm(onSubmit)}
        loading={updateIsLoading}
      >
        {t('connect')}
      </Button>
    </div>
  );
};

const InfoButtons = ({ setStep, closeModal, showSteps }) => {
  const { t } = useTranslation();

  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  return (
    <div
      className={`flex items-center justify-${showSteps ? 'between' : 'end'}`}
    >
      {showSteps && (
        <Button
          type="button"
          variant="secondary-outline"
          className="w-48"
          onClick={() => setStep(2)}
        >
          <AngleLeftIcon className="mr-3" />
          {t('back')}
        </Button>
      )}
      <PermissionSection
        permission={Permissions.erpIntegration}
        showPopover
        edit
      >
        <Button
          type="button"
          className="w-48"
          onClick={closeModal}
          disabled={!erpPermissions.edit}
        >
          {t('save')}
        </Button>
      </PermissionSection>
    </div>
  );
};

const Buttons = ({ step, ...rest }) => {
  const buttons = {
    1: Confirm,
    2: ProviderListButtons,
    3: InfoButtons,
  };

  const ModalButtons = buttons[step];

  if (!buttons[step]) {
    return null;
  }

  return <ModalButtons {...rest} step={step} />;
};

export const getModalActions = (props: any) => <Buttons {...props} />;

export const getModalTitle = ({
  hide,
  t,
  handleInvite,
  step,
  showSteps,
  name,
  invitePermissions,
}) => {
  if (hide) {
    return '';
  }

  // TODO check title logic after backend will send statusId
  return (
    <div className="flex flex-1 items-center justify-between">
      <span>
        {!name || step < 3 || showSteps
          ? t('dashboard:accounting.providers.title')
          : name}
      </span>
      <div className="flex items-center">
        <HideComponent show={invitePermissions.view}>
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <Button
              variant="link"
              paddingClass="px-4"
              className="ml-3 mr-[14px]"
              size="medium"
              onClick={handleInvite}
              disabled={!invitePermissions.edit}
            >
              {t('dashboard:invite')}
              <UserAddIcon className="ml-2" />
            </Button>
          </PermissionSection>
        </HideComponent>
        {(showSteps || step < 3) && <AccountStepper />}
      </div>
    </div>
  );
};
