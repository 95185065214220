import { useState, useRef, DragEvent, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Permissions } from 'entities/dashboard';
import { AddImageIcon } from 'components/icons';
import { validateFiles } from 'utils/files';
import { AcceptFormat } from 'common/files';
import { getTheme } from 'theme/selectors';
import Loading from 'components/Loading';
import { useAppSelector } from 'hooks';
import Badge from 'components/Badge';
import classNames from 'classnames';

import Progress from './Progress';
import PermissionSection from 'components/permission/PermissionSection';

interface UploadFilesProps {
  optional?: boolean;
  title?: string;
  text?: string;
  className?: string;
  multiple?: boolean;
  onUpload: (files: FileList) => void;
  handleProgress?: (value: number) => void;
  progressPercent?: number;
  uploadFiles: any;
  children: (value: any) => ReactNode;
  isLoading?: any;
  acceptFormat?: string[];
  fileUploadText?: string;
  validations?: any;
  button?: ReactNode;
  disabled?: boolean;
  permission?: Permissions;
}

const maxAllowedSize = 8;
const accept = [
  AcceptFormat.png,
  AcceptFormat.jpeg,
  AcceptFormat.jpg,
  AcceptFormat.svg,
  AcceptFormat.pdf,
  AcceptFormat.msword,
  AcceptFormat.document,
];

const UploadFile: React.FC<UploadFilesProps> = ({
  optional = false,
  title = '',
  text,
  fileUploadText,
  className = '',
  multiple = false,
  onUpload,
  handleProgress,
  progressPercent,
  uploadFiles,
  children,
  isLoading,
  acceptFormat,
  validations,
  button,
  disabled,
  permission,
}) => {
  const { textColor } = useAppSelector(getTheme);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [errors, setErrors] = useState<Array<string>>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof progressPercent === 'number') {
      setLoading(progressPercent);
    }
  }, [progressPercent]);

  useEffect(() => {
    setUploadedFiles(uploadFiles);
  }, [uploadFiles]);

  const handleSelect = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleUpload = async (files: FileList) => {
    if (files.length) {
      if (!multiple) {
        setUploadedFiles([]);
      }

      const validationErrors = await validateFiles(files, {
        maxSize: maxAllowedSize,
        types: acceptFormat || accept,
        ...validations,
      });

      setErrors(validationErrors);

      if (validationErrors.length === 0) {
        if (handleProgress) {
          handleProgress(1);
        }

        onUpload(files);
      }
    }
  };

  const handleDrag = (e: DragEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLButtonElement>) => {
    handleDrag(e);
    handleUpload(e.dataTransfer.files);
  };

  const handleFilesChange = ({
    currentTarget,
  }: {
    currentTarget: HTMLInputElement;
  }) => {
    if (currentTarget && currentTarget.files) {
      handleUpload(currentTarget.files);
    }
  };

  if (isLoading) {
    return <Loading fullHeight={false} zIndex={10} transparent />;
  }

  return (
    <div className={className}>
      <Progress loading={loading} />

      {(multiple || uploadedFiles.length === 0) && loading === 0 && (
        <div className="flex items-center justify-between">
          {title && (
            <div className="mr-5">
              <p className="text-sm leading-5 font-medium text-gray-700 mb-1">
                {title}
              </p>
              <p className="text-xs leading-4 text-gray-500">
                {text || t('common:uploadFileText')}
              </p>
            </div>
          )}

          <div className={classNames('flex-shrink-0', { 'w-full': !title })}>
            <PermissionSection permission={permission} showPopover edit>
              <button
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                type="button"
                className={classNames({
                  'flex w-full items-center justify-center border-2 border-gray-300 border-dashed text-left rounded-lg px-6 py-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500':
                    !button,
                  'pointer-events-none opacity-50': disabled,
                  'hover:bg-gray-100': !disabled,
                })}
                onClick={handleSelect}
              >
                {button || (
                  <>
                    <AddImageIcon className="text-gray-400" />
                    <span className="block ml-4">
                      <span className="text-sm leading-5 font-medium text-gray-600 block mb-1">
                        <span
                          style={{
                            color: textColor.primary.base,
                          }}
                          className="mr-1"
                        >
                          {t('common:fileUploadFile')}
                        </span>
                        {t('common:fileUploadDrag')}
                      </span>
                      <span className="text-xs leading-4 text-gray-500 block">
                        {fileUploadText || t('common:fileUploadType')}
                      </span>
                    </span>
                  </>
                )}
              </button>
            </PermissionSection>

            {optional && (
              <div className="flex justify-center mt-2">
                <Badge color="gray" fontWeight="text-gray-500">
                  {t('common:labelOptionalAttachment')}
                </Badge>
              </div>
            )}
          </div>
        </div>
      )}

      <input
        ref={inputRef}
        type="file"
        className="hidden"
        accept={acceptFormat ? acceptFormat.join(', ') : accept.join(', ')}
        onChange={handleFilesChange}
        multiple={multiple}
      />

      {errors.length > 0 && (
        <div className="mr-2">
          {errors.map((error) => (
            <p key={error} className="text-sm leading-4 mt-2 text-red-600">
              {t(`common:validations.file.${error}`, {
                size: `${maxAllowedSize}mb`,
                width: `${
                  validations?.imageSize
                    ? validations.imageSize.split('x')[0]
                    : 0
                }`,
                height: `${
                  validations?.imageSize
                    ? validations.imageSize.split('x')[1]
                    : 0
                }`,
              })}
            </p>
          ))}
        </div>
      )}

      {children({ handleSelect, inputRef })}
    </div>
  );
};

export default UploadFile;
