import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import { Permissions } from 'entities/dashboard';
import { Select } from 'components/Inputs';
import { useAppSelector } from 'hooks';

import {
  getMappedSubscriptionsHowOften,
  getSubscriptionsHowOftenId,
} from '../selectors';

const SubscriptionsHowOften = ({ setValue, showErrors }) => {
  const { t } = useTranslation('financial');

  const subscriptions = useAppSelector(getMappedSubscriptionsHowOften);
  const subscriptionsHowOftenId = useAppSelector(getSubscriptionsHowOftenId);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const [hasValue, setHasValue] = useState(false);

  const subscriptionsHowOften = subscriptions.find(
    (item) => item.value === subscriptionsHowOftenId,
  );

  return (
    <Select
      name="subscriptionsHowOftenId"
      label={t('financial:fields.subscriptionPeriod.label')}
      placeholder={t('financial:fields.subscriptionPeriod.placeholder')}
      options={subscriptions}
      value={subscriptionsHowOften}
      setValue={setValue}
      onChange={() => setHasValue(true)}
      disabled={!financialPermissions.edit}
      error={
        showErrors &&
        !subscriptionsHowOften &&
        !hasValue && {
          message: t('financial:fields.subscriptionPeriod.validation.required'),
        }
      }
    />
  );
};

export default SubscriptionsHowOften;
