import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { BusinessIcon, PersonIcon } from 'components/icons';
import { Select, Input } from 'components/Inputs';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BusinessPlan } from 'common/businessPlan';

import HorizontalPipe from './HorizontalPipe';
import CheckboxWithIcon from './CheckboxWithIcon';
import { setBusinessPlan } from '../businessPlanSlice';
import {
  getBusinessPlanItems,
  getSelectedValue,
  getDropdowns,
} from '../selectors';

enum OptionKeys {
  IndustryTypesId = 'industryTypesId',
  industryTypes = 'industryTypes',
  TypeCode = 'typeCode',
  DemographicTypesId = 'demographicTypesId',
  demographicTypes = 'demographicTypes',
  geogrphicTypes = 'geogrphicTypes',
  merchantCategoryCodes = 'merchantCategoryCodes',
  GeogrphicTypesId = 'geogrphicTypesId',
  customerIndustryTypesId = 'customerIndustryTypesId',
}

interface AboutCustomersProps {
  step: number;
}

const AboutCustomers: React.FC<AboutCustomersProps> = ({ step }) => {
  const { t } = useTranslation('dashboard');

  const dispatch = useAppDispatch();
  const {
    adultBuy,
    teenBuy,
    childrenBuy,
    infantBuy,
    businessProducts,
    personalProducts,
    purchaseReason,
    demographicTypesId,
    geogrphicTypesId,
    customerIndustryTypesId,
  } = useAppSelector(getBusinessPlanItems);
  const { industryTypes, demographicTypes, geogrphicTypes } =
    useAppSelector(getDropdowns);
  const selectedIndustry = useAppSelector(
    getSelectedValue(
      customerIndustryTypesId,
      OptionKeys.industryTypes,
      OptionKeys.IndustryTypesId,
    ),
  );
  const demographicType = useAppSelector(
    getSelectedValue(
      demographicTypesId,
      OptionKeys.demographicTypes,
      OptionKeys.DemographicTypesId,
    ),
  );

  const geogrphicType = useAppSelector(
    getSelectedValue(
      geogrphicTypesId,
      OptionKeys.geogrphicTypes,
      OptionKeys.GeogrphicTypesId,
    ),
  );

  const handleState = (key: string, value: boolean) => {
    dispatch(setBusinessPlan({ key, value }));
  };

  const handleChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value ?? e;
    dispatch(setBusinessPlan({ key, value }));
  };

  const demographicValue = demographicType
    ? {
        name: demographicType[OptionKeys.TypeCode],
        value: demographicType[OptionKeys.DemographicTypesId],
      }
    : null;

  const geogrphicValue = geogrphicType
    ? {
        name: geogrphicType[OptionKeys.TypeCode],
        value: geogrphicType[OptionKeys.GeogrphicTypesId],
      }
    : null;

  const selectedValue = selectedIndustry
    ? {
        name: selectedIndustry[OptionKeys.TypeCode],
        value: selectedIndustry[OptionKeys.IndustryTypesId],
      }
    : null;

  if (step !== 3) {
    return null;
  }

  return (
    <div className="w-full">
      <h1 className="text-2xl leading-8 font-bold">
        {t('dashboard:businessPlan.modal.aboutCustomer')}
      </h1>
      <HorizontalPipe />
      <div>
        <div>
          <label className="text-sm leading-5 font-medium mb-[10px]">
            {t('dashboard:businessPlan.modal.whoWillBuying')}
          </label>
          <label className="text-sm leading-5 font-normal text-zinc-400">
            {t('dashboard:businessPlan.modal.seeAll')}
          </label>
        </div>

        <div className="mt-[10px] flex gap-x-6 flex-wrap gap-y-6">
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.adultBuy, !adultBuy)}
            checked={adultBuy}
            label={t('dashboard:businessPlan.modal.adults')}
          />
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.teenBuy, !teenBuy)}
            checked={teenBuy}
            label={t('dashboard:businessPlan.modal.teens')}
          />
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.childrenBuy, !childrenBuy)}
            checked={childrenBuy}
            label={t('dashboard:businessPlan.modal.children')}
          />
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.infantBuy, !infantBuy)}
            checked={infantBuy}
            label={t('dashboard:businessPlan.modal.infants')}
          />
        </div>
        <div className="mt-9">
          <div className="mb-[10px]">
            <label className="text-sm leading-5 font-medium mb-[10px]">
              {t('dashboard:businessPlan.modal.whoWillBuying')}
            </label>
            <label className="text-sm leading-5 font-normal text-zinc-400">
              {t('dashboard:businessPlan.modal.seeAll')}
            </label>
          </div>
          <div className="flex items-center flex-wrap gap-x-12">
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.businessProducts, !businessProducts)
                }
                checked={businessProducts}
                label={t('dashboard:businessPlan.modal.business')}
                icon={<BusinessIcon />}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.personalProducts, !personalProducts)
                }
                checked={personalProducts}
                icon={<PersonIcon />}
                label={t('dashboard:businessPlan.modal.personal')}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center mt-9 flex-wrap gap-x-12">
          <div className="flex flex-1 w-full">
            <Select
              className="w-full"
              label={t('dashboard:businessPlan.modal.keyDemographic')}
              name="productIndustry"
              optionalLabel={OptionKeys.TypeCode}
              optionValue={OptionKeys.DemographicTypesId}
              onChange={(option: any) =>
                handleChange(BusinessPlan.demographicTypesId, option.value)
              }
              options={demographicTypes}
              value={demographicValue}
              placeholder={t('dashboard:businessPlan.modal.selectDemographicn')}
            />
          </div>
          <div className="flex flex-1 w-full">
            <Select
              className="w-full"
              optionalLabel={OptionKeys.TypeCode}
              optionValue={OptionKeys.GeogrphicTypesId}
              onChange={(option: any) =>
                handleChange(BusinessPlan.geogrphicTypesId, option.value)
              }
              label={t('dashboard:businessPlan.modal.geographic')}
              name="productIndustry"
              value={geogrphicValue}
              options={geogrphicTypes}
              placeholder={t('dashboard:businessPlan.modal.selectLocation')}
            />
          </div>
          <div className="flex flex-1 w-full">
            <Select
              className="w-full"
              label={t('dashboard:businessPlan.modal.wahtIndustry')}
              name="productIndustry"
              optionalLabel={OptionKeys.TypeCode}
              optionValue={OptionKeys.IndustryTypesId}
              onChange={(option: any) =>
                handleChange(BusinessPlan.customerIndustryTypesId, option.value)
              }
              value={selectedValue}
              options={industryTypes}
              placeholder={t('dashboard:businessPlan.modal.selectIndustry')}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-x-9 mt-9 gap-y-4">
          <div className="flex flex-1 w-full flex-col">
            <Input
              className="w-full"
              name="prduct"
              type="textarea"
              onChange={(event: any) =>
                handleChange(BusinessPlan.purchaseReason, event)
              }
              value={purchaseReason || ''}
              label={t('dashboard:businessPlan.modal.purchaseReason')}
            />
            <span className="text-sm leading-5 font-normal text-gray-500">
              {t('dashboard:businessPlan.modal.kindOfProductsDescripton')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCustomers;
