import { useTranslation } from 'react-i18next';

import { ExclamationIcon } from 'components/icons/ExclamationIcon';
import Button from 'components/Button';

interface ConfirmDisconnectProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDisconnect: React.FC<ConfirmDisconnectProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="p-6 text-center">
      <ExclamationIcon className="text-red-500 w-10 h-10 mt-8 mb-6 mx-auto" />
      <div className="text-lg font-semibold text-gray-900 mb-2">
        {t('dashboard:connectors.disconnectTitle')}
      </div>
      <div className="text-sm text-gray-500 mb-6">
        {t('dashboard:connectors.disconnectText')}
      </div>
      <Button variant="red" className="w-full mb-4" onClick={onConfirm}>
        {t('dashboard:connectors.disconnect')}
      </Button>
      <Button variant="secondary-outline" className="w-full" onClick={onCancel}>
        {t('common:cancel')}
      </Button>
    </div>
  );
};

export default ConfirmDisconnect;
