import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { BankAccountStatusIds } from 'entities/bankAccounts';
import { useAppSelector, useAppDispatch } from 'hooks';
import { BankAccount } from 'entities/bankAccounts';
import { Permissions } from 'entities/dashboard';

import { getHasBankAccountRelation } from '../AccountingSoftware/selectors';
import BankAccountStatus from '../Connectors/BankAccountStatus';
import DropdownMenu from '../Connectors/DropdownMenu';
import { viewBankAccount } from './bankAccountsSlice';
import { getBankAccounts } from './selectors';
import {
  fetchBankAccounts,
  disconnectBankAccount,
  connectBankAccount,
  fetchFundingAccount,
} from './thunks';

const BankAccountList = () => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const bankAccounts: BankAccount[] = useAppSelector(getBankAccounts);
  const bankNameRequired: boolean = useAppSelector(getHasBankAccountRelation);

  const handleRefresh = () => {
    dispatch(fetchBankAccounts());
  };

  const handleUpdate = (account: BankAccount) => {
    if (account.accountName) {
      dispatch(viewBankAccount(account.bankAccountsId));
    } else {
      dispatch(fetchFundingAccount(account.referenceId));
    }
  };

  const handleDisconnect = (account: BankAccount) => {
    dispatch(disconnectBankAccount(account.bankAccountsId));
  };

  const handleConnect = (account: BankAccount) => {
    dispatch(connectBankAccount(account));
  };

  return (
    <div className="table w-full mt-6">
      <div className="table-header-group">
        <div className="table-row uppercase font-medium text-xs text-gray-500">
          <div className="table-cell px-4 py-3">
            {t('integration:bank.account')}
          </div>
          <div className="table-cell px-4 py-3">
            <div className="flex item-center">{t('integration:bank.bank')}</div>
          </div>
          <div className="table-cell px-4 py-3">
            {t('integration:bank.routingNumber')}
          </div>
          <div className="table-cell px-4 py-3">
            {t('integration:bank.accountNumber')}
          </div>
          <div className="table-cell px-4 py-3">
            {t('integration:bank.status')}
          </div>
          <div className="table-cell w-8 px-4 py-3"></div>
        </div>
      </div>
      <div className="table-row-group">
        {bankAccounts.map((account: BankAccount) => (
          <div
            key={account.bankAccountsId}
            className={classNames('table-row', {
              'bg-yellow-50':
                account.accountName &&
                (account.bankAccountStatusId ===
                  BankAccountStatusIds.actionRequired ||
                  (bankNameRequired && !account.erpBankAccountName)),
            })}
          >
            <div className="table-cell p-4 border-t border-gray-200 text-sm font-medium">
              {account.userCalledName ||
                account.accountName ||
                t('bank.fundingAccount')}
            </div>
            <div className="table-cell p-4 border-t border-gray-200 text-secondary">
              {account.accountName ? account.bankName : '\u2014'}
            </div>
            <div className="table-cell p-4 border-t border-gray-200 text-secondary">
              {account.routingNumber
                ? `******${account.routingNumber.slice(
                    account.routingNumber.length - 4,
                    account.routingNumber.length,
                  )}`
                : '\u2014'}
            </div>
            <div className="table-cell p-4 border-t border-gray-200 text-secondary">
              {account.accountNumberLast4
                ? `******${account.accountNumberLast4}`
                : '\u2014'}
            </div>
            <div className="table-cell px-4 border-t border-gray-200">
              <BankAccountStatus
                statusId={account.bankAccountStatusId}
                status={
                  account.bankAccountStatus ||
                  t('integration:bank.inProgress') ||
                  ''
                }
              />
            </div>
            <div className="table-cell border-t border-gray-200">
              <DropdownMenu
                permission={Permissions.bankIntegration}
                onRefresh={handleRefresh}
                onUpdate={() => handleUpdate(account)}
                onDisconnect={() => handleDisconnect(account)}
                onConnect={() => handleConnect(account)}
                isActive={account.isActive}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BankAccountList;
