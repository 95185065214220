import classNames from 'classnames';

import { EyeIcon, EyeOffIcon } from 'components/icons';

type ShowButtonProps = {
  error: any;
  showValue: boolean;
  toggleShowValue: () => void;
};

const ShowButton: React.FC<ShowButtonProps> = ({
  error,
  showValue,
  toggleShowValue,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'absolute inset-y-0 right-0 flex items-center text-gray-400 cursor-pointer',
        error ? 'pr-9' : 'pr-3',
      )}
      onClick={toggleShowValue}
    >
      {showValue ? <EyeOffIcon /> : <EyeIcon />}
    </button>
  );
};

export default ShowButton;
