import { useTranslation } from 'react-i18next';

import { CloseCircleIcon, CheckCircleIcon } from 'components/icons';
import { BusinessBPSTrackSchema } from 'services/businesses';
import { useAppSelector } from 'hooks';

import { getBPSTrack } from './Configuration/selectors';

enum IconTypes {
  close = 'close',
  check = 'check',
}

const icons = {
  [IconTypes.close]: (
    <CloseCircleIcon className="text-gray-500 flex-shrink-0 w-[18px] h-[18px]" />
  ),
  [IconTypes.check]: (
    <CheckCircleIcon className="text-green-500 flex-shrink-0 w-[18px] h-[18px]" />
  ),
};

const ConfigurationTabPopover = () => {
  const { t } = useTranslation('configuration');

  const bpsTrack: BusinessBPSTrackSchema | null = useAppSelector(getBPSTrack);

  return (
    <div className="bg-gray-900 rounded-lg p-3 md:w-[352px]">
      <div className="flex items-start bg-gray-800 p-3 rounded-lg">
        {icons[bpsTrack?.bankAccountId ? IconTypes.check : IconTypes.close]}
        <div className="pl-2">
          <p className="text-sm font-medium text-gray-50 mb-0">
            {t('configuration:smart.title')}
          </p>
          <p className="text-sm text-gray-400 mb-0">
            {t('configuration:smart.description')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationTabPopover;
