import { createSlice } from '@reduxjs/toolkit';

import { fetchProfileByEmail } from 'pages/Profile/thunks';
import { PermissionSchema } from 'services/security';

import {
  searchPersonByEmail,
  fetchBusinessMembers,
  searchBusinessesPersons,
  fetchPermissions,
  fetchMemberPermissions,
  updateBusinessMember,
  deleteBusinessPerson,
  updateMemberPermissions,
} from './thunks';

export enum PermissionType {
  view = 'view',
  edit = 'edit',
}

export interface Person {
  businessesAdmin: boolean;
  businessesPersonsXREFId: number;
  businessesPersonsRole: string;
  hubCreateUser: boolean;
  personsId: number;
  personsGivenName1: string;
  personsSurnameFirst: string;
  personsSurnameSecond: string;
  personsEmailAddress: string;
  permissionType: PermissionType | null;
  hasOnlyReadonlyRights: boolean;
}

interface Errors {
  [key: string]: string[];
}

export interface InvitePersonsProps {
  isOpen: boolean;
  isLoading: boolean;
  wasInvited: boolean;
  isInviteSent: boolean;
  persons: Person[];
  errors: Errors;
  permissionType: PermissionType | null;
  permissions: {
    view: PermissionSchema[];
    edit: PermissionSchema[];
  };
  personPermissions: PermissionSchema[];
  grandAccess: boolean;
  showMembers: boolean;
}

export const initialState: InvitePersonsProps = {
  isOpen: false,
  isLoading: false,
  wasInvited: false,
  isInviteSent: false,
  persons: [],
  errors: {},
  permissionType: null,
  permissions: {
    view: [],
    edit: [],
  },
  personPermissions: [],
  grandAccess: false,
  showMembers: false,
};

const invitePersonsSlice = createSlice({
  name: 'invitePersons',
  initialState,
  reducers: {
    setInvitedAndWasInviteState(state) {
      state.isInviteSent = false;
      state.wasInvited = false;
    },
    setInviteIsOpen(state, action) {
      state.isOpen = action.payload;

      if (!action.payload) {
        state.errors = {};
        state.permissionType = null;
        state.personPermissions = [];
        state.grandAccess = false;
      }
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    setPermissions(state, action) {
      state.personPermissions = action.payload;
    },
    setPermissionType(state, action) {
      state.permissionType = action.payload;
    },
    setGrandAccess(state, action) {
      state.grandAccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchPersonByEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchPersonByEmail.rejected, (state, action) => {
      state.isLoading = false;

      if (action?.payload) {
        state.errors = action.payload;

        if (
          action.payload.find(
            (e: string) =>
              e === 'User or Person already exists' ||
              e === 'User has already been invited',
          )
        ) {
          state.wasInvited = true;
        }
      }
    });
    builder.addCase(fetchProfileByEmail.fulfilled, (state, action) => {
      state.showMembers = action.payload.showMembers || false;
    });
    builder.addCase(searchPersonByEmail.fulfilled, (state) => {
      state.isLoading = false;
      state.isInviteSent = true;
    });
    builder.addCase(fetchBusinessMembers.fulfilled, (state, action) => {
      state.persons = action.payload;
    });
    builder.addCase(searchBusinessesPersons.fulfilled, (state, action) => {
      state.persons = action.payload;
    });
    builder.addCase(updateBusinessMember.fulfilled, (state, action) => {
      state.persons = action.payload;
    });
    builder.addCase(deleteBusinessPerson.fulfilled, (state, action) => {
      state.persons = action.payload;
    });
    builder.addCase(updateMemberPermissions.fulfilled, (state, action) => {
      state.persons = action.payload;
    });
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.permissions = {
        view: action.payload.filter(
          (p: PermissionSchema) =>
            !p.isHidden &&
            p.permissions_Code
              .toLowerCase()
              .endsWith(`_${PermissionType.view}`),
        ),
        edit: action.payload.filter(
          (p: PermissionSchema) =>
            !p.isHidden &&
            p.permissions_Code
              .toLowerCase()
              .endsWith(`_${PermissionType.edit}`),
        ),
      };
      state.personPermissions = action.payload.filter(
        (p: PermissionSchema) => !p.isHidden,
      );
    });
    builder.addCase(fetchMemberPermissions.pending, (state) => {
      state.personPermissions = [];
    });
    builder.addCase(fetchMemberPermissions.fulfilled, (state, action) => {
      state.personPermissions = action.payload.filter(
        (p: PermissionSchema) => !p.isHidden,
      );
    });
  },
});

export const {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
  setErrors,
  setPermissions,
  setPermissionType,
  setGrandAccess,
} = invitePersonsSlice.actions;

export default invitePersonsSlice.reducer;
