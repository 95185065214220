import api from 'api';

export interface CrossBorderProviderSchema {
  id: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string | null;
  modifiedOn: string | null;
  businessesId: number;
  countryCallingCode: string;
  isActive: boolean;
  emailAddressesId: number | null;
  emailAddress: string;
  logoId: number;
  name: string;
  telephoneNumber: string;
  telephoneNumbersId: number | null;
  url: string | null;
  applicationUrl: string | null;
}

export const searchCrossBorderProviders = async (
  payload: Partial<CrossBorderProviderSchema>,
) => {
  const results: CrossBorderProviderSchema[] | null = await api.post(
    'CrossBorder/Providers/Search',
    payload,
  );

  return results || [];
};

export const fetchFXProviderLogo = async (id: number) => {
  try {
    const logo: any = await api.get(`CrossBorder/Providers/${id}/logo`, {});

    return logo;
  } catch (e) {
    return null;
  }
};

export default {
  searchCrossBorderProviders,
};
