export const GreenFlagIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    className={className}
  >
    <path
      d="M1 43V33.6667M1 33.6667V5.66667C1 3.08934 3.08934 1 5.66667 1H20.8333L23.1667 3.33333H43L36 17.3333L43 31.3333H23.1667L20.8333 29H5.66667C3.08934 29 1 31.0893 1 33.6667ZM22 2.16667V15"
      stroke="#10B981"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
