type ErrorMessageProps = {
  error: any;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  if (!error || !error.message) {
    return null;
  }

  return <p className="text-sm leading-4 mt-2 text-red-600">{error.message}</p>;
};

export default ErrorMessage;
