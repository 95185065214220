import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ChevronDownIcon } from 'components/icons';
import { Permissions } from 'entities/dashboard';
import Popover from 'components/Popover';

import {
  FXProviderModalTypes,
  setDetailsModalOpened,
  setProvider,
} from '../fxProviderSlice';

const ProviderDropDown = ({ provider }) => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const fxPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.fxProvider),
  );

  const handleClick = (type: FXProviderModalTypes) => {
    dispatch(setProvider(provider));
    dispatch(setDetailsModalOpened(type));
    setIsOpened(false);
  };

  return (
    <Popover
      opened={isOpened}
      placement="bottom-end"
      button={
        <Button
          variant={ButtonVariant.secondaryOutline}
          size={ButtonSize.medium}
          heightClass="h-8"
          paddingClass="px-2.5"
          className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-500"
          onClick={() => setIsOpened(true)}
        >
          <span className="font-semibold">{t('fxProvider.beginSetup')}</span>
          <ChevronDownIcon className="text-gray-500 ml-1" />
        </Button>
      }
      content={
        <div className="border rounded-lg shadow-sm mt-1 bg-white min-w-[230px]">
          <PermissionSection
            permission={Permissions.fxProvider}
            edit
            showPopover
          >
            <button
              disabled={!fxPermissions.edit}
              className={
                'text-gray-700 hover:text-gray-900 block px-4 py-3 text-sm leading-5 hover:bg-gray-100 cursor-pointer w-full text-left'
              }
              onClick={() => handleClick(FXProviderModalTypes.existed)}
            >
              {t('fxProvider.newProviderButton')}
            </button>
          </PermissionSection>
          <PermissionSection
            permission={Permissions.fxProvider}
            edit
            showPopover
          >
            <button
              disabled={!fxPermissions.edit}
              className={
                'text-gray-700 hover:text-gray-900 block px-4 py-3 text-sm leading-5 hover:bg-gray-100 cursor-pointer w-full text-left'
              }
              onClick={() => handleClick(FXProviderModalTypes.new)}
            >
              {t('fxProvider.existedProviderButton')}
            </button>
          </PermissionSection>
        </div>
      }
    />
  );
};

export default ProviderDropDown;
