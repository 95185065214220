import { useTranslation, Trans } from 'react-i18next';
import fileDownload from 'js-file-download';
import classNames from 'classnames';
import { useState } from 'react';

import { getIsNMIMerchant } from 'pages/dashboard/components/MerchantServices/selectors';
import { getBusinessSignatory, getPersonId } from 'pages/Profile/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateBusiness } from 'pages/Profile/thunks';
import { Checkbox } from 'components/Inputs';
import Loading from 'components/Loading';
import Link from 'components/Link';
import api from 'api';

import { setIsNMIAccepted } from '../submitDashboardSlice';
import { getIsNMIAccepted } from '../selectors';

const NMIAgreement = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const signatory = useAppSelector(getBusinessSignatory);
  const isNMIMerchant = useAppSelector(getIsNMIMerchant);
  const personId = useAppSelector(getPersonId);
  const isNMIAccepted = useAppSelector(getIsNMIAccepted);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

  const handleChange = async (value: boolean) => {
    dispatch(setIsNMIAccepted(value));

    if (isUpdating || !value) return;

    if (!isTermsAccepted) {
      setIsTermsAccepted(true);

      setIsUpdating(true);

      await dispatch(
        updateBusiness({
          nmiTermsAndConditionsPersonsId: personId,
        }),
      );

      setIsUpdating(false);
    }
  };

  const downloadFile = async () => {
    const file: Blob = await api.get(
      `${window.location.origin}/assets/files/gateway_merchant_service_agreement.pdf`,
      { responseType: 'blob' },
    );

    fileDownload(file, `${t('submitModal.NMIFileName')}.pdf`);
  };

  if (!isNMIMerchant) {
    return null;
  }

  return (
    <div
      className={classNames('mt-9 bg-gray-50 p-6 rounded-md relative', {
        'opacity-50 pointer-events-none': !signatory || isUpdating,
      })}
    >
      <p className="text-gray-700 text-xs font-medium mb-5">
        {t('dashboard:submitModal.NMITitle')}
      </p>
      <div className="flex justify-start">
        <Loading loading={isUpdating} transparent />
        <label htmlFor="nmi-checkbox" className="flex items-center">
          <Checkbox
            id="nmi-checkbox"
            onChange={handleChange}
            checked={isNMIAccepted}
          />
          <span className="ml-4 text-xs font-medium text-gray-700">
            <Trans ns="dashboard" i18nKey="submitModal.NMILabel">
              text
              <Link onClick={downloadFile}>link</Link>
            </Trans>
          </span>
        </label>
      </div>
    </div>
  );
};

export default NMIAgreement;
