import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import * as businessPlanService from 'services/businessPlans';
import { hasPermission } from 'pages/dashboard/selectors';
import { getPerson } from 'pages/Profile/selectors';
import { getBusinessesId } from 'utils/authService';
import { Permissions } from 'entities/dashboard';
import { getUploadOptions } from 'utils/files';
import { buildFormData } from 'utils/helpers';
import { RootState } from 'state/store';

import { getBusinessPlan, getFiles, getlogoFiles } from './selectors';
import { setProgressPrecent } from './businessPlanSlice';

export const checkBusinessPlanProgress = createAsyncThunk(
  'financial/checkBusinessPlanProgress',
  async (blobFiles: any) => {
    try {
      const isActivePlan = blobFiles.filter(({ isActive }) => isActive).length;
      return isActivePlan
        ? { progress: 100, totalFields: 1, enteredFields: 1 }
        : { progress: 0, totalFields: 1, enteredFields: 0 };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const uploadLogo = createAsyncThunk(
  'businessPlan/uploadLogo',
  async (files: File, thunkApi) => {
    const { getState, dispatch } = thunkApi;

    try {
      const businessesId = getBusinessesId();

      const { emailAddress } = getPerson(getState() as RootState);

      const { name, size } = files[0];

      const fields = {
        businessesId,
        blobReferencesTypesId: 25,
        createdBy: emailAddress,
        blobFileSize: size,
        blobFileName: name,
        isActive: true,
      };

      const formData = buildFormData({
        ...fields,
        file: files[0],
      });

      const options = getUploadOptions((value) =>
        dispatch(setProgressPrecent(value)),
      );

      const result = await businessPlanService.uploadBusinessPlan(
        formData,
        options,
      );

      if (result) {
        dispatch(setProgressPrecent(100));

        return [
          {
            ...fields,
            businessesPlanBlobReferencesId:
              result.businessesPlanBlobReferencesId,
            blobGuid: result.blobGuid,
          },
        ];
      }

      return [];
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const addDocument = createAsyncThunk(
  'businessPlan/addDocument',
  async (files: File, thunkApi) => {
    const { getState, dispatch } = thunkApi;

    try {
      const businessesId = getBusinessesId();

      const { emailAddress } = getPerson(getState() as RootState);

      const { name, size } = files[0];

      const fields = {
        businessesId,
        createdBy: emailAddress,
        isActive: true,
        blobReferencesTypesId: 1,
        blobFileSize: size,
        blobFileName: name,
      };

      const formData = buildFormData({
        ...fields,
        file: files[0],
      });

      const options = getUploadOptions((value) =>
        dispatch(setProgressPrecent(value)),
      );

      const result = await businessPlanService.uploadBusinessPlan(
        formData,
        options,
      );

      if (result) {
        dispatch(setProgressPrecent(100));

        const blobReferences = [
          {
            ...fields,
            businessesPlanBlobReferencesId:
              result.businessesPlanBlobReferencesId,
            blobGuid: result.blobGuid,
          },
        ];

        dispatch(checkBusinessPlanProgress(blobReferences));

        return blobReferences;
      }

      return [];
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const updateBusinessLogo = createAsyncThunk(
  'businessPlan/updateBusinessLogo',
  async (
    { files, businessesPlanBlobReferencesId, blobGuid }: any,
    thunkApi,
  ) => {
    const { getState, dispatch } = thunkApi;

    try {
      const businessesId = getBusinessesId();

      const { emailAddress } = getPerson(getState() as RootState);

      const { name, size } = files[0];

      const fields = {
        businessesId,
        businessesPlanBlobReferencesId,
        blobGuid: blobGuid,
        isActive: true,
        blobReferencesTypesId: 25,
        modifiedBy: emailAddress,
        blobFileSize: size,
        blobFileName: name,
      };

      const formData = buildFormData({
        ...fields,
        file: files[0],
      });

      const options = getUploadOptions((value) =>
        dispatch(setProgressPrecent(value)),
      );

      const result = await businessPlanService.updateBusinessPlanBlob(
        formData,
        options,
      );

      if (result) {
        dispatch(setProgressPrecent(100));

        return [
          {
            ...fields,
            blobGuid: result.blobGuid,
          },
        ];
      }

      return [];
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const updateDocument = createAsyncThunk(
  'businessPlan/updateBusinessPlanBlob',
  async (
    { files, businessesPlanBlobReferencesId, blobGuid }: any,
    thunkApi,
  ) => {
    const { getState, dispatch } = thunkApi;

    try {
      const businessesId = getBusinessesId();

      const { emailAddress } = getPerson(getState() as RootState);

      const { name, size } = files[0];

      const fields = {
        businessesId,
        businessesPlanBlobReferencesId,
        blobGuid: blobGuid,
        isActive: true,
        blobReferencesTypesId: 1,
        modifiedBy: emailAddress,
        blobFileSize: size,
        blobFileName: name,
      };

      const formData = buildFormData({
        ...fields,
        file: files[0],
      });

      const options = getUploadOptions((value) =>
        dispatch(setProgressPrecent(value)),
      );

      const result = await businessPlanService.updateBusinessPlanBlob(
        formData,
        options,
      );

      if (result) {
        dispatch(setProgressPrecent(100));

        return [
          {
            ...fields,
            blobGuid: result.blobGuid,
          },
        ];
      }

      return [];
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const findBusinessPlan = createAsyncThunk(
  'businessPlan/findBusinessPlan',
  async (_: void, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const { view: viewFinancialPermission } = hasPermission(
        thunkApi.getState() as RootState,
        Permissions.financialInfo,
      );

      if (!viewFinancialPermission) {
        return [];
      }

      const businessesId = getBusinessesId();

      const data = {
        blobReferencesTypesId: 1,
        businessesId,
      };

      const blobFiles = await businessPlanService.searchBusinessPlan(data);

      dispatch(checkBusinessPlanProgress(blobFiles));

      return blobFiles;
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const findBusinessLogo = createAsyncThunk(
  'businessPlan/findBusinessLogo',
  async (_: void, thunkApi) => {
    const { dispatch } = thunkApi;

    try {
      const businessesId = getBusinessesId();

      const data = {
        blobReferencesTypesId: 25, // todo replace value
        businessesId,
      };

      const blobFiles = await businessPlanService.searchBusinessPlan(data);

      return blobFiles;
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const getFile = createAsyncThunk(
  'businessPlan/getFile',
  async ({ blobGUID, blobFileName }: any) => {
    try {
      const blobFile = await businessPlanService.getFile(blobGUID);

      fileDownload(blobFile, blobFileName || 'plan.pdf');

      return blobFile;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteBusinessPlan = createAsyncThunk(
  'businessPlan/deleteBusinessPlan',
  async (businessesPlanBlobReferencesId: string, thunkApi) => {
    const { emailAddress } = getPerson(thunkApi.getState() as RootState);

    try {
      const data = {
        isActive: false,
        businessesPlanBlobReferencesID: businessesPlanBlobReferencesId,
        modifiedBy: emailAddress,
      };

      await businessPlanService.updateIsActivePlan(data);

      thunkApi.dispatch(checkBusinessPlanProgress([]));

      return businessesPlanBlobReferencesId;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteBusinessLogo = createAsyncThunk(
  'businessPlan/deleteBusinessLogo',
  async (businessesPlanBlobReferencesId: string, thunkApi) => {
    const { emailAddress } = getPerson(thunkApi.getState() as RootState);

    try {
      const data = {
        isActive: false,
        businessesPlanBlobReferencesID: businessesPlanBlobReferencesId,
        modifiedBy: emailAddress,
      };

      await businessPlanService.updateIsActivePlan(data);

      return businessesPlanBlobReferencesId;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createBusinessPlan = createAsyncThunk(
  'businessPlan/createBusinessPlan',
  async (_: void, thunkApi) => {
    const { getState, dispatch } = thunkApi;
    const businessesId = getBusinessesId();

    const state = getState() as RootState;

    const { emailAddress } = getPerson(state);
    const businessPlan = getBusinessPlan(state);
    const currentFiles = getFiles(state);
    const currentLogoFiles = getlogoFiles(state);

    const id = currentFiles?.[0]?.businessesPlanBlobReferencesId;

    const {
      rolesJSON,
      expensesJSON,
      marketingJSON,
      ownershipsJSON,
      competitorsJSON,
      storeSales,
      onlieSales,
      ...rest
    } = businessPlan;

    try {
      const data = {
        isActive: true,
        businessesId,
        storeSales: storeSales || 0,
        onlieSales: onlieSales || 0,
        ...{
          rolesJSON: JSON.stringify(rolesJSON),
          expensesJSON: JSON.stringify(expensesJSON),
          marketingJSON: JSON.stringify(marketingJSON),
          ownershipsJSON: JSON.stringify(ownershipsJSON),
          competitorsJSON: JSON.stringify(competitorsJSON),
          ...rest,
        },
      };

      const businessesPlanBlobReferencesJSON: any = [];

      if (currentFiles?.[0]) {
        const {
          businessesPlanBlobReferencesId,
          blobReferencesTypesId,
          blobGuid,
          blobFileSize,
          blobFileName,
          blobFileExtension,
        } = currentFiles[0];

        businessesPlanBlobReferencesJSON.push({
          BusinessesPlanBlobReferencesId: businessesPlanBlobReferencesId,
          BlobReferencesTypesId: blobReferencesTypesId,
          BlobGUID: blobGuid,
          BlobFileSize: blobFileSize,
          BlobFileExtension: blobFileExtension,
          BlobFileName: blobFileName,
        });
      }

      if (currentLogoFiles?.[0]) {
        const {
          businessesPlanBlobReferencesId,
          blobReferencesTypesId,
          blobGuid,
          blobFileSize,
          blobFileName,
          blobFileExtension,
        } = currentLogoFiles[0];

        businessesPlanBlobReferencesJSON.push({
          BusinessesPlanBlobReferencesId: businessesPlanBlobReferencesId,
          BlobReferencesTypesId: blobReferencesTypesId,
          BlobGUID: blobGuid,
          BlobFileSize: blobFileSize,
          BlobFileExtension: blobFileExtension,
          BlobFileName: blobFileName,
        });
      }

      data.businessesPlanBlobReferencesJSON =
        businessesPlanBlobReferencesJSON.length
          ? JSON.stringify(businessesPlanBlobReferencesJSON)
          : null;

      const createOrUpdate = async () => {
        if (rest?.businessesPlansId && id) {
          const updateResult = await businessPlanService.updateBusinessPlan({
            ...data,
            modifiedBy: emailAddress,
          });

          return updateResult;
        }

        const result = await businessPlanService.addBusinessPlan({
          ...data,
          createdBy: emailAddress,
        });

        return result;
      };

      const result = await createOrUpdate();

      dispatch(findBusinessPlan());

      return result;
    } catch (e) {
      dispatch(setProgressPrecent(0));
      return Promise.reject(e);
    }
  },
);

export const getBusinessPlanDocumentById = createAsyncThunk(
  'businessPlan/getBusinessPlanDocumentById',
  async () => {
    const businessesId = getBusinessesId();

    try {
      const blobFiles = await businessPlanService.getBusinessPlanDocumentById(
        businessesId,
      );

      fileDownload(blobFiles, 'businessPlan.pdf');

      return blobFiles;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const getBusinessPlanById = createAsyncThunk(
  'businessPlan/getBusinessPlanById',
  async () => {
    const businessesId = getBusinessesId();

    try {
      const businessPlan = await businessPlanService.getBusinessPlanById(
        businessesId,
      );

      if (businessPlan) {
        const {
          expensesJSON,
          marketingJSON,
          rolesJSON,
          ownershipsJSON,
          competitorsJSON,
          ...rest
        } = businessPlan;
        return {
          expensesJSON: JSON.parse(expensesJSON),
          marketingJSON: JSON.parse(marketingJSON),
          rolesJSON: JSON.parse(rolesJSON),
          ownershipsJSON: JSON.parse(ownershipsJSON),
          competitorsJSON: JSON.parse(competitorsJSON),
          ...rest,
        };
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
