import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { getBusinessPersons, getLoginData } from 'pages/login/selectors';
import { useAppDispatch, useAppSelector, useCurrentUser } from 'hooks';
import { setBusinessesId, logOut, getOID } from 'utils/authService';
import { getBusinessRedirectLink } from 'pages/login/utils';
import { setOnboarding } from 'pages/Profile/profileSlice';
import { PlusIcon, SearchIcon } from 'components/icons';
import { clearDashboard } from 'pages/dashboard/thunks';
import HideComponent from 'components/HideComponent';
import { getSubDomain } from 'utils/domainService';
import { signOut } from 'pages/login/loginSlice';
import { Input } from 'components/Inputs';
import Button from 'components/Button';
import Person from 'components/Person';
import { config } from 'config';
import path from 'common/path';

type BusinessListProps = {
  refreshPage?: boolean;
  selected?: number;
};

const BusinessList: React.FC<BusinessListProps> = ({
  refreshPage,
  selected,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logoutRedirect, isAuthenticated } = useCurrentUser();

  const businesses = useAppSelector(getBusinessPersons);
  const { email } = useAppSelector(getLoginData);

  const [searchValue, setSearchValue] = useState('');

  const options = useMemo(() => {
    const searchString = searchValue.toString().toLowerCase();

    const result = businesses.filter(
      (option) =>
        !searchValue ||
        (option.name || '').toLowerCase().includes(searchString) ||
        (option.emailAddress || '').toLowerCase().includes(searchString) ||
        option.value.toString().includes(searchString) ||
        (option.businessesTenantId || '').toString() === searchString ||
        (option.businessesTenantName || '').toLowerCase() === searchString,
    );

    return selected
      ? result.filter((option) => option.value !== selected)
      : result;
  }, [selected, businesses, searchValue]);

  const handleClick = (business: any) => {
    if (
      business.businessesTenantUrlSub &&
      getSubDomain() !== business.businessesTenantUrlSub
    ) {
      window.location.href = `${config.PROTOCOL}${business.businessesTenantUrlSub}/login`;
      return;
    }

    setBusinessesId(business.value);

    const link = getBusinessRedirectLink(business);

    if (refreshPage && link === path.dashboard) {
      navigate(0);
    } else {
      const params: any = { pathname: link };

      if (!isAuthenticated && !getOID() && email) {
        params.search = `?email=${email}`;
      }

      dispatch(clearDashboard());
      navigate(params);
    }
  };

  const handleNew = async () => {
    await dispatch(setOnboarding(true));
    await dispatch(clearDashboard());
    navigate(path.profile);
  };

  const handleSignOut = async () => {
    await dispatch(signOut());
    logoutRedirect();
    logOut();
  };

  const handleSearch = (data) => {
    setSearchValue(data.currentTarget.value);
  };

  return (
    <div>
      <HideComponent show={!!searchValue || options.length > 3}>
        <div className={classNames('border-b border-gray-200 p-4')}>
          <Input
            name="search"
            value={searchValue}
            onChange={handleSearch}
            iconStart={<SearchIcon className="text-gray-400" />}
            placeholder={t('common:fields.search.placeholder')}
          />
        </div>
      </HideComponent>

      <div className="max-h-[20vh] 2xl:max-h-[334px] overflow-y-auto custom-scrollbar border-b border-gray-200">
        {options.map((option, index) => (
          <button
            type="button"
            className="w-full p-4 flex items-center border-t first:border-t-0 border-gray-200 hover:bg-gray-50 overflow-hidden"
            key={option.value}
            onClick={() => handleClick(option)}
          >
            <div className="pr-3">
              <Person
                name={option.avatarName.toUpperCase()}
                index={index}
                size="large"
                disabled={
                  !option.accountholder ||
                  !option.participantID ||
                  !option.erpClientID
                }
              />
            </div>

            <div
              className="pl-3 text-left text-gray-700"
              style={{ maxWidth: 'calc(100% - 40px)' }}
            >
              <p className="text-sm font-medium truncate">{option.name}</p>
              <div className="flex items-center text-xs">
                <span className="bg-gray-100 py-px px-1 rounded-sm flex-shrink-0">
                  {`ID: ${option.value}`}
                </span>
                {option.emailAddress && (
                  <>
                    <div className="bg-gray-500 w-1 h-1 rounded-full mx-2.5 flex-shrink-0" />
                    <span className="text-gray-500 truncate">
                      {option.emailAddress}
                    </span>
                  </>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>

      <div className="flex justify-center">
        <Button
          type="button"
          className="my-4 w-full"
          variant="link"
          heightClass="h-[48px]"
          onClick={handleNew}
        >
          <PlusIcon className="mr-3" />
          {t('common:onboardBusiness')}
        </Button>
      </div>

      <div className="flex justify-center border-t border-gray-200 py-4 px-7">
        <Button
          variant="secondary-outline"
          size="medium"
          className="w-full"
          onClick={handleSignOut}
        >
          {t('common:signOut')}
        </Button>
      </div>
    </div>
  );
};

export default BusinessList;
