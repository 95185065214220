import { Trans, useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { notify } from 'components/notification/notificationSlice';
import { ConnectorTypes, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import Button from 'components/Button';
import Link from 'components/Link';
import {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
} from 'components/InvitePersons/invitePersonsSlice';
import {
  UserAddIcon,
  PlusIcon,
  CommunicationIcon,
  FinanceIcon,
} from 'components/icons';

import { setIsBankAccountOpen } from '../BankAccounts/bankAccountsSlice';
import {
  setShowWidgets,
  setIsAccountingOpen,
} from '../AccountingSoftware/accountingSlice';

interface ConnectorWrapperProps {
  type: ConnectorTypes;
  isEmpty: boolean;
  children: React.ReactElement;
}

const icons = {
  [ConnectorTypes.erp]: (
    <CommunicationIcon className="text-gray-400 h-12 w-12 mx-auto" />
  ),
  [ConnectorTypes.bank]: (
    <FinanceIcon className="text-gray-400 h-12 w-12 mx-auto" />
  ),
};

const actions = {
  [ConnectorTypes.erp]: setIsAccountingOpen,
  [ConnectorTypes.bank]: setIsBankAccountOpen,
};

const permissions = {
  [ConnectorTypes.erp]: Permissions.erpIntegration,
  [ConnectorTypes.bank]: Permissions.bankIntegration,
};

const PermissionLink = ({ children, onClick, permission, disabled }) => (
  <PermissionSection permission={permission} showPopover edit>
    <Link onClick={onClick} disabled={disabled} className="mx-1">
      {children}
    </Link>
  </PermissionSection>
);

const ConnectorWrapper: React.FC<ConnectorWrapperProps> = ({
  type,
  isEmpty,
  children,
}) => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const connectorPermissions = useAppSelector((state) =>
    hasPermission(state, permissions[type]),
  );
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleInvite = () => {
    if (invitePermissions.edit) {
      dispatch(setInvitedAndWasInviteState());
      dispatch(setInviteIsOpen(true));
    }
  };

  const handleSwitchConnection = () => {
    if (connectorPermissions.edit) {
      dispatch(setShowWidgets(true));
    }
  };

  const handleAdd = () => {
    if (connectorPermissions.edit) {
      dispatch(actions[type](true));
    } else {
      dispatch(
        notify({
          message: t('common:permissions.editPermissionRequired', {
            section: t(`common:permissions.${permissions[type]}`),
          }),
          translate: false,
          variant: 'error',
          duration: 5000,
        }),
      );
    }
  };

  return (
    <div className="w-full mb-6 bg-white rounded-lg p-6 border shadow">
      <div
        className={connectorPermissions.view ? 'border-b border-gray-200' : ''}
      >
        <div className="flex items-center justify-between mb-4">
          <h3 className="heading-2xl font-semibold">
            {t(`integration:${type}.${isEmpty ? 'titleEmpty' : 'title'}`)}
          </h3>
          <div className="flex items-center">
            <HideComponent
              show={invitePermissions.view && connectorPermissions.view}
            >
              <PermissionSection
                permission={Permissions.invite}
                showPopover
                edit
              >
                <Button
                  variant="link"
                  size="medium"
                  paddingClass="px-3"
                  onClick={handleInvite}
                  disabled={!invitePermissions.edit}
                >
                  {t('integration:inviteHelp')}
                  <UserAddIcon className="ml-2" />
                </Button>
              </PermissionSection>
            </HideComponent>
            <HideComponent
              show={
                (isEmpty && type === ConnectorTypes.erp) ||
                (type === ConnectorTypes.bank && connectorPermissions.view)
              }
            >
              <PermissionSection
                permission={permissions[type]}
                showPopover
                edit
              >
                <Button
                  variant={isEmpty ? 'primary' : 'mslLink'}
                  size="medium"
                  onClick={handleAdd}
                  className="ml-6"
                  disabled={!connectorPermissions.edit}
                >
                  <PlusIcon className="mr-2" />
                  {t(`common:${isEmpty ? 'connect' : 'add'}`)}
                </Button>
              </PermissionSection>
            </HideComponent>
          </div>
        </div>

        <HideComponent show={isEmpty && type === ConnectorTypes.bank}>
          <PermissionSection
            permission={Permissions.bankIntegration}
            showMessage
          >
            <p className="text-secondary mb-4">
              {t(`integration:${type}.description`)}
            </p>
          </PermissionSection>
        </HideComponent>

        <HideComponent show={isEmpty && type === ConnectorTypes.erp}>
          <p className="text-secondary mb-4">
            {t(`integration:${type}.description`)}
          </p>
        </HideComponent>

        <HideComponent show={type === ConnectorTypes.erp}>
          <div className="flex mb-4">
            <div className="flex items-center text-xs p-2 bg-gray-50 rounded-md justify-start">
              <p className="text-gray-600 mr-1">
                {t('integration:erp.selectedPreference')}
              </p>
              <PermissionSection
                permission={Permissions.erpIntegration}
                showPopover
                edit
              >
                <Link onClick={handleSwitchConnection}>
                  {t('integration:erp.switchConnection')}
                </Link>
              </PermissionSection>
            </div>
          </div>
        </HideComponent>
      </div>

      <HideComponent show={connectorPermissions.view}>
        {isEmpty ? (
          <div className="p-6 bg-gray-50 border-2 border-dashed border-gray-300 mt-6 rounded-lg text-center">
            {icons[type]}
            <h5 className="mt-2 mb-1 text-lg font-medium">
              {t(`integration:${type}.emptyListTitle`)}
            </h5>
            <div className="text-secondary flex items-center justify-center">
              <Trans i18nKey={`integration:${type}.emptyListDescription`}>
                <PermissionLink
                  permission={permissions[type]}
                  onClick={handleAdd}
                  disabled={!connectorPermissions.edit}
                >
                  link
                </PermissionLink>
                text
                <PermissionLink
                  permission={Permissions.invite}
                  onClick={handleInvite}
                  disabled={!invitePermissions.edit}
                >
                  link
                </PermissionLink>
                text
              </Trans>
            </div>
          </div>
        ) : (
          children
        )}
      </HideComponent>
    </div>
  );
};

export default ConnectorWrapper;
