import { useState, useEffect, ReactElement } from 'react';
import classNames from 'classnames';

import PermissionSection from 'components/permission/PermissionSection';
import RadioButtons from 'components/RadioButtons';
import { Permissions } from 'entities/dashboard';

const options = [
  {
    value: 1,
    name: 'Yes',
  },
  {
    value: 0,
    name: 'No',
  },
];

interface YesNoAnswerProps {
  text: string | ReactElement;
  value?: boolean | null;
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
  permission?: Permissions;
}

const YesNoAnswer: React.FC<YesNoAnswerProps> = ({
  text,
  className,
  value,
  onChange,
  disabled = false,
  permission,
}) => {
  const [checked, setChecked] = useState(options[1]);

  useEffect(() => {
    if (value === null) {
      setChecked({
        value: -1,
        name: '',
      });
    } else {
      setChecked(options[value ? 0 : 1]);
    }
  }, [value, setChecked]);

  const handleChange = (option) => {
    setChecked(option);

    if (onChange) {
      onChange(Boolean(option.value));
    }
  };

  return (
    <div
      className={classNames(
        className,
        'flex items-start justify-between rounded-lg mt-6 px-4 py-3',
      )}
    >
      <div className="text-sm leading-5 font-medium text-gray-900">{text}</div>

      <PermissionSection permission={permission} showPopover edit>
        <RadioButtons
          options={options}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
      </PermissionSection>
    </div>
  );
};

export default YesNoAnswer;
