import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from 'components/Inputs/Input';
import Button from 'components/Button';
import { getCompetitorSchema } from '../utils';

interface CompetitorsModalFormProps {
  onClose: () => void;
  onSave: (value) => void;
  values?: {
    competitor: string;
  };
}

const CompetitorsModalForm: React.FC<CompetitorsModalFormProps> = ({
  onClose,
  onSave,
  values,
}) => {
  const { t } = useTranslation('dashboard');

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      competitor: '',
    },
    resolver: yupResolver(getCompetitorSchema(t)),
  });

  useEffect(() => {
    if (values) {
      reset(values);
    }
  }, [reset, values]);

  return (
    <div className="px-6 pb-6 pt-4">
      <form action="" onSubmit={handleSubmit(onSave)}>
        <div className="mb-4">
          <Input
            label={t('dashboard:businessPlan.modal.competitor.label')}
            name="competitor"
            error={errors?.competitor}
            register={register}
          />
        </div>
        <div className="flex">
          <Button
            onClick={onClose}
            className="w-full border-gray-300 border-[1px] bg-white mr-2 "
            variant="secondary"
          >
            {t('dashboard:businessPlan.buttons.cancel')}
          </Button>
          <Button className="w-full ml-2" type="submit">
            {t('dashboard:businessPlan.buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CompetitorsModalForm;
