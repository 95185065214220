import api from 'api';

export const BUSINESS_ADDRESS_TYPE_CODE = 'Mailing';

export interface AddressSchema {
  addressesId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateOrProvince: string;
  countryIsocode: string;
  postalCode: string;
  tokenOid: string;
}

export interface BusinessAddressXrefSchema {
  businessesAddressesXrefid: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  businessesId: number;
  addressesId: number;
  addressesTypesTypeCode: string;
  tokenOid: string;
}

export const addAddresses = (data: Partial<AddressSchema>) => {
  return api.post('Addresses/AddAddresses', data);
};

export const updateAddresses = (data: Partial<AddressSchema>) => {
  return api.put('Addresses/UpdateAddresses', data);
};

export const addBusinessesAddressesXrefs = (
  data: Partial<BusinessAddressXrefSchema>,
) => {
  return api.post('BusinessesAddressesXrefs/AddBusinessesAddressesXrefs', data);
};

export default {
  addAddresses,
  updateAddresses,
  addBusinessesAddressesXrefs,
};
