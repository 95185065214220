import * as yup from 'yup';

export const getOwnerSchema = (t) =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .trim()
        .required(t('dashboard:businessPlan.modal.name.validation.required')),
      shareOfOwnership: yup
        .number()
        .min(
          0.01,
          t('dashboard:businessPlan.modal.shareOfOwnership.validation.min', {
            value: 0,
          }),
        )
        .max(
          100,
          t('dashboard:businessPlan.modal.shareOfOwnership.validation.max'),
        )
        .transform((value, originValue) =>
          originValue === '' || originValue === '-' ? 0 : value,
        ),
    })
    .required();

export const getRoleSchema = (t) =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .trim()
        .required(t('dashboard:businessPlan.modal.name.validation.required')),
      role: yup
        .string()
        .trim()
        .required(t('dashboard:businessPlan.modal.role.validation.required')),
    })
    .required();

export const getCompetitorSchema = (t) =>
  yup
    .object()
    .shape({
      competitor: yup
        .string()
        .trim()
        .required(
          t('dashboard:businessPlan.modal.competitor.validation.required'),
        ),
    })
    .required();

export const getTypeSchema = (t) =>
  yup
    .object()
    .shape({
      type: yup
        .string()
        .trim()
        .required(t('dashboard:businessPlan.modal.type.validation.required')),
    })
    .required();
