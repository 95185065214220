export const Services = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      className={className}
    >
      <path
        d="M8.66663 3C8.66663 4.65685 7.32348 6 5.66663 6C4.00977 6 2.66663 4.65685 2.66663 3C2.66663 1.34315 4.00977 0 5.66663 0C7.32348 0 8.66663 1.34315 8.66663 3Z"
        fill="#6B7280"
      />
      <path
        d="M16.6666 3C16.6666 4.65685 15.3235 6 13.6666 6C12.0098 6 10.6666 4.65685 10.6666 3C10.6666 1.34315 12.0098 0 13.6666 0C15.3235 0 16.6666 1.34315 16.6666 3Z"
        fill="#6B7280"
      />
      <path
        d="M12.5958 14C12.6425 13.6734 12.6666 13.3395 12.6666 13C12.6666 11.3648 12.106 9.86059 11.1664 8.66907C11.9019 8.24355 12.7558 8 13.6666 8C16.4281 8 18.6666 10.2386 18.6666 13V14H12.5958Z"
        fill="#6B7280"
      />
      <path
        d="M5.66663 8C8.42805 8 10.6666 10.2386 10.6666 13V14H0.666626V13C0.666626 10.2386 2.9052 8 5.66663 8Z"
        fill="#6B7280"
      />
    </svg>
  );
};
