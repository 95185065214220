import api from 'api';

export interface BankAccountSchema {
  bankAccountsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  businessesId: number;
  banksIntegratorsId: number;
  accountName: string;
  routingNumber: string;
  accountNumber: string;
  bankAccountTypesId: number;
  userCalledName: string | null;
  accountNumberLast4: string;
  tokenOid: string;
  referenceId: string | null;
}

export const getBankAccounts = async (id: number) => {
  const result: BankAccountSchema[] = await api.get(
    `/BankAccounts/GetBankAccounts/${id}`,
  );
  return result;
};

export const addBankAccounts = async (payload: FormData) => {
  const result: number = await api.post(
    '/BankAccounts/AddBankAccounts',
    payload,
  );
  return result;
};

export const updateBankAccounts = async (payload: FormData) => {
  const result: number = await api.put(
    '/BankAccounts/UpdateBankAccounts',
    payload,
  );
  return result;
};

export const updateIsActiveBankAccounts = (
  payload: Partial<BankAccountSchema>,
) => {
  return api.put('/BankAccounts/UpdateIsActiveBankAccounts', payload);
};

export const updateBankAccountsBlobReference = (payload) => {
  return api.put('/BankAccounts/UpdateBankAccountsBlobReference', payload);
};

export const searchBankAccounts = async (
  payload: Partial<BankAccountSchema>,
) => {
  const result: BankAccountSchema[] = await api.post(
    '/BankAccounts/SearchBankAccounts',
    payload,
  );
  return result;
};

export interface BankAccountCheckRangeSchema {
  banksAccountsChecksRangesId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  startRange: string;
  endRange: string;
  bankAccountsId: number;
  isLogging: boolean;
  tokenOid: string;
  gotBy: string;
}

export type BankAccountCheckRangeType = Partial<BankAccountCheckRangeSchema>;

export const searchBankAccountCheckRanges = (
  payload: BankAccountCheckRangeType,
) => {
  return api.post(
    '/BanksAccountsChecksRanges/SearchBanksAccountsChecksRanges',
    payload,
  );
};

export interface BankAccountCheckSchema {
  banks_AccountsId: number;
  banksAccountsId: number;
  banks_Accounts_ChecksId: number;
  banksAccountsChecksId: number;
  banks_Accounts_Checks_StatusesId: number;
  tgpA_Banks_Accounts_ChecksId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  checkNumber: string;
  isLogging: boolean;
  tokenOid: string;
  gotBy: string;
}

export type BankAccountCheckType = Partial<BankAccountCheckSchema>;

export const searchBankAccountChecks = (payload: BankAccountCheckType) => {
  return api.post('/BanksAccountsChecks/SearchBanksAccountsChecks', payload);
};

export type BulkEditCheckRangesType = {
  modifiedBy: string;
  checkRanges: BankAccountCheckRangeType[];
  checkNumberOmits: BankAccountCheckRangeType[];
  bankAccountsId: number;
};

export const addUpdateBanksAccountsChecksAndRanges = (
  payload: BulkEditCheckRangesType,
) => {
  return api.post(
    '/BanksAccountsChecksRanges/AddUpdateBanksAccountsChecksAndRanges',
    payload,
  );
};

export interface BankAccountTypeSchema {
  id: number;
  name: string;
}

export const searchBankAccountTypes = async () => {
  const response: BankAccountTypeSchema[] = await api.get(
    '/BankAccountTypes/SearchBankAccountTypes',
  );
  return response;
};
