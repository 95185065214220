import api from 'api';

export interface MCTrackCreationWrapperSchema {
  businessesId: number;
  createdBy: string;
}

export const mcTrackBPSCreationWrapper = (
  data: MCTrackCreationWrapperSchema,
) => {
  return api.post('MCTrackBPS/McTrackBpsCreationWrapper', data);
};

export default {
  mcTrackBPSCreationWrapper,
};
