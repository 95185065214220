import { useState, FC, ReactNode } from 'react';
import { Popover } from '@headlessui/react';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import { InfoCircleIcon } from 'components/icons/InfoCircleIcon';

type PopoverProps = {
  info: string | ReactNode;
  button?: ReactNode;
  placement?: Placement;
  className?: string;
  infoClassName?: string;
  wrapperClassName?: string;
  onClick?: () => void;
};

const PopoverOnHover: FC<PopoverProps> = ({
  info,
  button,
  className,
  infoClassName,
  wrapperClassName,
  placement = 'bottom-start',
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 5] },
      },
    ],
  });

  return (
    <Popover className={wrapperClassName}>
      <Popover.Button
        ref={setReferenceElement}
        className={classNames(className, 'flex')}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={onClick}
        as="div"
      >
        {button || <InfoCircleIcon className="w-4.5 h-4.5 text-gray-500" />}
      </Popover.Button>

      {open && (
        <Popover.Panel
          ref={setPopperElement}
          className={
            infoClassName ||
            'z-10 w-64 text-xs font-medium text-gray-700 bg-white px-2 py-1 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md'
          }
          style={styles.popper}
          {...attributes.popper}
          static
        >
          {info}
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default PopoverOnHover;
