import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import ExternalLink from 'components/links/ExternalLink';
import { Select, DatePicker } from 'components/Inputs';
import { ChevronRightIcon } from 'components/icons';
import RadioButtons from 'components/RadioButtons';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';
import Button from 'components/Button';

import { getMappedPciCompliants, getPciCompliantId } from '../selectors';

const options = [
  {
    value: 1,
    name: 'Yes',
  },
  {
    value: 0,
    name: 'No',
  },
];

const PciCompliant = ({ setValue, control, showErrors, watch }) => {
  const { t } = useTranslation('financial');

  const [option, setOption] = useState(options[1]);

  const pciCompliants = useAppSelector(getMappedPciCompliants);
  const compliantId = useAppSelector(getPciCompliantId);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const value = compliantId
    ? pciCompliants.find((compliant) => compliant.value === compliantId)
    : null;

  useEffect(() => {
    if (value) {
      setOption(options[0]);
    }
  }, [value]);

  const handleChange = (opt) => {
    setOption(opt);
    setValue('isPciCompliant', Boolean(opt.value));
    setValue('pcicompliantId', 0);
  };

  const [pcicompliantId, pcidate] = watch(['pcicompliantId', 'pcidate']);

  return (
    <div className="rounded-lg px-4 py-3 mt-5 bg-gray-50">
      <div className="flex items-center justify-between">
        <p className="text-sm leading-5 font-medium">
          {t('financial:pciCompliant')}
        </p>

        <PermissionSection
          permission={Permissions.financialInfo}
          showPopover
          edit
        >
          <RadioButtons
            options={options}
            checked={option}
            onChange={handleChange}
            disabled={!financialPermissions.edit}
          />
        </PermissionSection>
      </div>
      <div className="py-6">
        {option.value ? (
          <Select
            name="pcicompliantId"
            options={pciCompliants}
            value={value}
            setValue={setValue}
            disabled={!financialPermissions.edit}
            error={
              showErrors &&
              !pcicompliantId && {
                message: t('financial:pciCompliantRequired'),
              }
            }
          />
        ) : (
          <div className="flex items-end justify-between">
            <ExternalLink
              href="https://www.pcisecuritystandards.org/pci_security/completing_self_assessment"
              site="PCI Security Standards"
            >
              <Button variant="link" size="medium-large">
                {t('financial:pciCertify')}
                <ChevronRightIcon className="ml-2" />
              </Button>
            </ExternalLink>
            <DatePicker
              label={t('financial:fields.pciDate.label')}
              name="pcidate"
              className="w-1/2"
              control={control}
              minDate={new Date()}
              disabled={!financialPermissions.edit}
              error={
                showErrors &&
                !pcidate && {
                  message: t('financial:fields.pciDate.validation.required'),
                }
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PciCompliant;
