import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';

import { ERPAcountStatuses, ERPProviders } from 'entities/accounting';
import Alert, { AlertTypes } from 'components/Alert';

import { QBOButton } from './QBOButton';

interface ConnectedAccountFooterProps {
  account: any;
  onUpdate: () => void;
  hasPermission: boolean;
}

const QBOAlerts: React.FC<ConnectedAccountFooterProps> = ({
  account,
  onUpdate,
  hasPermission,
}) => {
  const { t } = useTranslation('dashboard');

  const { erpaccountStatus, refreshTokenNextExpiryDate } = account;

  const daysLeft = refreshTokenNextExpiryDate
    ? moment(refreshTokenNextExpiryDate).diff(moment(), 'days', true)
    : 0;

  return (
    <div className="relative">
      {erpaccountStatus !== ERPAcountStatuses.connected && daysLeft < 0 && (
        <Alert
          description={t('dashboard:accounting.info.alertExpired', {
            name: ERPProviders.quickbooks,
          })}
          type={AlertTypes.error}
          icon={AlertTypes.warning}
          button={
            hasPermission ? (
              <QBOButton onClick={onUpdate} className="mt-2" small />
            ) : undefined
          }
        />
      )}

      {erpaccountStatus === ERPAcountStatuses.connected &&
        daysLeft > 0 &&
        daysLeft < 7 && (
          <Alert
            description={
              <Trans
                i18nKey="dashboard:accounting.info.alertExpireDays"
                values={{
                  name: ERPProviders.quickbooks,
                  days: daysLeft > 2 ? 7 : 2,
                }}
              >
                text<b>days</b>text
              </Trans>
            }
            type={daysLeft > 2 ? AlertTypes.warning : AlertTypes.error}
            icon={AlertTypes.warning}
            button={
              hasPermission ? (
                <QBOButton onClick={onUpdate} className="mt-2" small />
              ) : undefined
            }
          />
        )}
    </div>
  );
};

export default QBOAlerts;
