import { useEffect } from 'react';

import { ProcessorOption, prysymProcessor } from 'entities/merchantServices';
import { useAppSelector, useAppDispatch } from 'hooks';
import { ProductTypeCodes } from 'entities/progress';
import Loading from 'components/Loading';

import ProcessorConfiguration from './components/ProcessorConfiguration';
import { getActiveProductsTypeCodes } from '../Progress/selectors';
import PrysymConfiguration from './components/PrysymConfiguration';
import { calculateFinancialProgress } from '../Financial/thunks';
import { getCurrentProcessor, getIsLoading } from './selectors';
import SelectProcessor from './components/SelectProcessor';

const MerchantServices = ({ children }) => {
  const dispatch = useAppDispatch();

  const activeProducts = useAppSelector(getActiveProductsTypeCodes);
  const currentProcessor: ProcessorOption | null =
    useAppSelector(getCurrentProcessor);
  const isLoading: boolean = useAppSelector(getIsLoading);

  useEffect(() => {
    dispatch(calculateFinancialProgress());
  }, [currentProcessor, dispatch]);

  if (!activeProducts.includes(ProductTypeCodes.services)) {
    return null;
  }

  return (
    <div className="border-t border-gray-200">
      {!currentProcessor ? (
        <div className="relative">
          <Loading loading={isLoading} />
          <SelectProcessor />
        </div>
      ) : currentProcessor.value === prysymProcessor.value ? (
        <PrysymConfiguration>{children}</PrysymConfiguration>
      ) : (
        <ProcessorConfiguration />
      )}
    </div>
  );
};

export default MerchantServices;
