export const ProgressIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.5001 17.9838C10.2245 18.0008 10 17.7753 10 17.4992V16.4992C10 16.2231 10.2246 16.0014 10.4998 15.9787C13.5797 15.7247 16 13.1446 16 9.9992C16 6.85381 13.5797 4.27373 10.4998 4.01972C10.2246 3.99702 10 3.77534 10 3.4992V2.4992C10 2.22306 10.2245 1.99757 10.5001 2.01457C14.6854 2.27272 18 5.74888 18 9.9992C18 14.2495 14.6854 17.7257 10.5001 17.9838Z"
      fill="currentColor"
    />
    <path
      d="M7.91902 15.6285C8.20937 15.7359 8.36766 16.0635 8.24062 16.3458L7.83476 17.2477C7.73138 17.4774 7.47258 17.596 7.23617 17.509C6.88248 17.3787 6.54054 17.2243 6.21235 17.0475C5.99065 16.9281 5.90788 16.6559 6.01121 16.4263L6.41707 15.5244C6.54412 15.2421 6.89435 15.1433 7.16717 15.2897C7.40838 15.4191 7.65947 15.5325 7.91902 15.6285Z"
      fill="currentColor"
    />
    <path
      d="M6.21236 2.9509C5.99066 3.07029 5.90789 3.34249 6.01122 3.57211L6.41708 4.47401C6.54413 4.75635 6.89436 4.85511 7.16718 4.70873C7.40839 4.5793 7.65948 4.46588 7.91903 4.3699C8.20938 4.26253 8.36767 3.93494 8.24063 3.65264L7.83477 2.75073C7.73139 2.52101 7.47259 2.40244 7.23618 2.48948C6.8825 2.61968 6.54055 2.77417 6.21236 2.9509Z"
      fill="currentColor"
    />
    <path
      d="M2.01932 9.43864C2.00685 9.61878 2.00035 9.80057 2 9.9838V10.0146C2.00035 10.1979 2.00685 10.3797 2.01932 10.5598C2.03674 10.8114 2.25119 10.9992 2.5034 10.9992H3.49207C3.80126 10.9992 4.035 10.7211 4.01401 10.4126C4.00471 10.276 3.99999 10.1382 3.99999 9.99922C3.99999 9.86027 4.00471 9.72242 4.014 9.58583C4.03499 9.27735 3.80126 8.99923 3.49207 8.99923H2.5034C2.25118 8.99923 2.03674 9.18702 2.01932 9.43864Z"
      fill="currentColor"
    />
    <path
      d="M3.61457 14.8194C3.76648 15.0203 4.04823 15.0605 4.25942 14.9233L5.08848 14.3844C5.34797 14.2157 5.39212 13.8547 5.20582 13.6076C5.04043 13.3882 4.88975 13.157 4.75525 12.9157C4.60467 12.6455 4.257 12.5395 3.99764 12.708L3.16851 13.247C2.95712 13.3844 2.87961 13.6586 3.00206 13.879C3.18449 14.2073 3.38935 14.5215 3.61457 14.8194Z"
      fill="currentColor"
    />
    <path
      d="M3.99765 7.29036C4.257 7.45894 4.60469 7.35293 4.75526 7.08272C4.88977 6.84136 5.04045 6.61025 5.20584 6.39085C5.39214 6.14372 5.34799 5.78271 5.08851 5.61404L4.25944 5.07514C4.04825 4.93787 3.76651 4.97813 3.6146 5.17905C3.38937 5.47695 3.18451 5.7911 3.00208 6.11944C2.87963 6.33982 2.95714 6.61402 3.16853 6.75142L3.99765 7.29036Z"
      fill="currentColor"
    />
  </svg>
);
