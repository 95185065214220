import { createSlice } from '@reduxjs/toolkit';

import { OwnerType, OwnershipState } from 'entities/ownership';
import { clearDashboard } from 'pages/dashboard/thunks';

import {
  fetchOwners,
  createOwnerPerson,
  createOwnerBusiness,
  deleteOwnerBusiness,
  deleteOwnerPerson,
  updateOwnerPerson,
  updateOwnerBusiness,
  deleteOwnerBlobReference,
  createOwnerBlobReference,
} from './thunks';

export const emptyOwner: OwnerType = {
  type: 0,
  isActive: false,
};

export const initialState: OwnershipState = {
  owners: [],
  blobReferences: [],
  isLoading: false,
  loadingProgress: 0,
};

const ownershipSlice = createSlice({
  name: 'ownership',
  initialState,
  reducers: {
    addOwner(state) {
      state.owners = [...state.owners, emptyOwner];
    },
    setLoadingProgress(state, action) {
      state.loadingProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchOwners.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOwners.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOwners.fulfilled, (state, action) => {
      state.blobReferences = action.payload.blobReferences;
      state.owners = action.payload.owners.length
        ? action.payload.owners
        : [emptyOwner];
      state.isLoading = false;
    });
    builder.addCase(createOwnerPerson.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(createOwnerBusiness.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(deleteOwnerBusiness.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(deleteOwnerPerson.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(updateOwnerPerson.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(updateOwnerBusiness.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(deleteOwnerBlobReference.fulfilled, (state, action) => {
      state.blobReferences = action.payload;
    });
    builder.addCase(createOwnerBlobReference.fulfilled, (state, action) => {
      state.blobReferences = action.payload;
    });
  },
});

export const { addOwner, setLoadingProgress } = ownershipSlice.actions;

export default ownershipSlice.reducer;
