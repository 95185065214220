import { config } from 'config';

interface BaseUrlConfig {
  protocol?: boolean;
  hostUrl?: boolean;
  apiRoot?: boolean;
  apiVersion?: boolean;
}

export const getBaseUrl = (newConfig?: BaseUrlConfig) => {
  const baseUrlConfig: BaseUrlConfig = {
    protocol: true,
    hostUrl: true,
    apiRoot: true,
    apiVersion: true,
    ...newConfig,
  };
  const protocol = baseUrlConfig.protocol ? config.PROTOCOL : '';
  const hostUrl = baseUrlConfig.hostUrl ? config.HOST_URL : '';
  const apiRoot = baseUrlConfig.apiRoot ? `/${config.API_ROOT}` : '';

  return `${protocol}${hostUrl}${apiRoot}`;
};

export const getUrlParams = () => {
  const query = window.location.search.substring(1);
  if (!query) {
    return null;
  }
  const arr = query.split('&');
  const params = {};
  for (let i = 0; i < arr.length; i++) {
    const pair = arr[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};
