import api from 'api';

export interface TGPACreationWrapperSchema {
  businessesId: number;
  modifiedBy: string;
  complianceReference: string;
}

export const tgpaCreationWrapper = async (data: TGPACreationWrapperSchema) => {
  return api.put('TGPAApi/TGPACreationWrapper', data);
};

export const getVendorCredentialsByIntegrator = (id: number) => {
  return api.get(`/TGPAApi/GetVendorCredentialsByIntegrator/${id}`);
};

export const getVendorCredentialsByDirectoryId = (id: number) => {
  return api.get(`/TGPAApi/GetVendorCredentialsByDirectoryID/${id}`);
};

export default {
  tgpaCreationWrapper,
  getVendorCredentialsByIntegrator,
  getVendorCredentialsByDirectoryId,
};
