import { getDomain, getTenant } from 'theme/selectors';
import { DomainType } from 'utils/domainService';
import { useAppSelector } from 'hooks';

import SmartSuiteLogo from './SmartSuiteLogo';

interface LogoProps {
  className?: string;
  checkDefaultTenant?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  className,
  checkDefaultTenant = false,
}) => {
  const { logoSecondaryFileShareUri, logoFileShareUri } =
    useAppSelector(getTenant);
  const domain = useAppSelector(getDomain);

  const logo = logoFileShareUri || logoSecondaryFileShareUri || '';

  if (checkDefaultTenant && domain === DomainType.smartHub) {
    return <SmartSuiteLogo className="-mb-5 -mt-4" />;
  }

  if (!logo) {
    return null;
  }

  return <img src={logo} className={className} alt="Logo" />;
};

export default Logo;
