import { useTranslation } from 'react-i18next';

import creditCard from 'assets/icons/creditCard.svg';

const ServicesPopover = () => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="bg-gray-900 rounded-lg p-4 w-[300px] mt-1 shadow-2xl">
      <div className="flex items-center mb-2.5">
        <img src={creditCard} alt="Credit card" />
        <span
          className="inline-flex items-center font-medium ml-2.5 rounded-full text-xs py-0.5 px-2.5"
          style={{ color: '#f1bb49', backgroundColor: 'rgba(241,187,73,0.2)' }}
        >
          {t('progress.services.badge')}
        </span>
      </div>
      <p className="text-gray-50 font-bold mb-1.5">
        {t('progress.services.title')}
      </p>
      <p className="text-gray-300 text-xs max-w-[200px]">
        {t('progress.services.text')}
      </p>
    </div>
  );
};

export default ServicesPopover;
