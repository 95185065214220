import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import PermissionSection from 'components/permission/PermissionSection';
import { Checkbox, Input, NumberInput } from 'components/Inputs';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ShowErrorsProps } from 'entities/progress';
import { Permissions } from 'entities/dashboard';
import YesNoAnswer from 'components/YesNoAnswer';

import OnboardingTabHeader from '../OnboardingTabHeader';
import { getShowErrors } from '../Progress/selectors';
import { getCrossBorder } from './selectors';
import { saveCrossBorder } from './thunks';

const Transactional = () => {
  const { t } = useTranslation('transactional');
  const dispatch = useAppDispatch();

  const showErrors: ShowErrorsProps = useAppSelector(getShowErrors);
  const crossBorder = useAppSelector(getCrossBorder);
  const transactionalPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.transactional),
  );
  const currencies: any = [];

  const { register, control, watch, setValue, getValues, reset } = useForm<any>(
    {
      defaultValues: {
        transactionEstimate12Month: '',
        transactionAverage: '',
        transactionHigh: '',
        transactionPurpose: '',
        regulatedActivities: '',
        // currency: [],
      },
    },
  );

  const [
    transactionEstimate12Month,
    transactionAverage,
    transactionHigh,
    transactionPurpose,
    regulatedActivities,
    currency,
  ] = watch([
    'transactionEstimate12Month',
    'transactionAverage',
    'transactionHigh',
    'transactionPurpose',
    'regulatedActivities',
    'currency',
  ]);

  const showError = useMemo(
    () => showErrors.transactional && transactionalPermissions.edit,
    [showErrors.transactional, transactionalPermissions.edit],
  );

  useEffect(() => {
    return () => {
      if (transactionalPermissions.edit) {
        const data = getValues();

        dispatch(saveCrossBorder(data));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      transactionEstimate12Month: crossBorder?.transactionEstimate12Month ?? '',
      transactionAverage: crossBorder?.transactionAverage ?? '',
      transactionHigh: crossBorder?.transactionHigh ?? '',
      transactionPurpose: crossBorder?.transactionPurpose ?? '',
      regulatedActivities: crossBorder?.regulatedActivities ?? '',
    });
  }, [crossBorder, reset]);

  const handleActivities = (value: boolean) => {
    setValue('regulatedActivities', value);
  };

  return (
    <div className="p-6">
      <PermissionSection permission={Permissions.transactional} showMessage>
        <>
          <div className="mb-6">
            <OnboardingTabHeader
              title={t('title')}
              subTitle={t('crossBorderDescription')}
            />
          </div>

          <form>
            <div className="grid grid-cols-2 gap-9 mt-4">
              <PermissionSection
                edit
                showPopover
                permission={Permissions.transactional}
                fullWidth
              >
                <NumberInput
                  label={t('numberOfTransactions.label')}
                  placeholder={t('numberOfTransactions.placeholder')}
                  name="transactionEstimate12Month"
                  control={control}
                  className="mb-5 w-full"
                  allowNegative={false}
                  type="int"
                  error={showError && !transactionEstimate12Month}
                  readOnly={!transactionalPermissions.edit}
                />
              </PermissionSection>
              <PermissionSection
                edit
                showPopover
                permission={Permissions.transactional}
                fullWidth
              >
                <NumberInput
                  label={t('transactionValue.label')}
                  placeholder={t('transactionValue.placeholder')}
                  name="transactionAverage"
                  control={control}
                  className="mb-5 w-full"
                  inputClassName="pl-10"
                  allowNegative={false}
                  prefix="$"
                  prefixInline
                  error={showError && !transactionAverage}
                  readOnly={!transactionalPermissions.edit}
                />
              </PermissionSection>
            </div>
            <div className="grid grid-cols-2 gap-9">
              <PermissionSection
                edit
                showPopover
                permission={Permissions.transactional}
                fullWidth
              >
                <NumberInput
                  label={t('highestValue.label')}
                  placeholder={t('highestValue.placeholder')}
                  name="transactionHigh"
                  control={control}
                  className="w-full mb-4"
                  inputClassName="pl-10"
                  allowNegative={false}
                  prefix="$"
                  prefixInline
                  error={showError && !transactionHigh}
                  readOnly={!transactionalPermissions.edit}
                />
              </PermissionSection>
              <div className="w-full"></div>
            </div>
            <PermissionSection
              edit
              showPopover
              permission={Permissions.transactional}
              fullWidth
            >
              <Input
                label={t('purpose.label')}
                name="transactionPurpose"
                register={register}
                type="textarea"
                className="w-full"
                error={showError && !transactionPurpose.trim()}
                readOnly={!transactionalPermissions.edit}
              />
            </PermissionSection>
            <p className="text-secondary mt-2 mb-8">{t('purpose.info')}</p>
            <YesNoAnswer
              permission={Permissions.transactional}
              className="rounded-lg bg-gray-50 mb-6"
              disabled={!transactionalPermissions.edit}
              onChange={handleActivities}
              value={regulatedActivities || false}
              text={
                <div>
                  <p className="mb-3">{t('activities.title')}</p>
                  <ul className="font-normal text-gray-600 list-disc pl-5">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <li key={index}>{t(`activities.activity-${index}`)}</li>
                    ))}
                  </ul>
                </div>
              }
            />

            <p className="pt-12 mb-1 text-lg font-medium text-gray-900 hidden">
              {t('currencies.title')}
            </p>
            <p className="text-secondary pb-5 mb-4 border-b border-gray-200 hidden">
              {t('currencies.info')}
            </p>
            <div className="grid grid-cols-2 gap-x-9 hidden">
              {currencies.map((curr) => (
                <div
                  key={curr.id}
                  className="h-10 flex items-center mt-2 justify-between"
                >
                  <PermissionSection
                    permission={Permissions.transactional}
                    showPopover
                    edit
                  >
                    <label
                      className="flex items-center text-sm cursor-pointer pr-3"
                      htmlFor={curr.code}
                    >
                      <Checkbox
                        id={curr.code}
                        className="mr-3"
                        register={register}
                        name={`currency.${curr.code}`}
                        setValue={setValue}
                        disabled={!transactionalPermissions.edit}
                      />
                      <span className="mx-1.5 font-medium">{curr.name}</span>
                      <span className="text-gray-500">{curr.code}</span>
                    </label>
                  </PermissionSection>
                  {currency[curr.code] && (
                    <PermissionSection
                      permission={Permissions.transactional}
                      showPopover
                      edit
                    >
                      <NumberInput
                        placeholder={t('currencies.input.placeholder')}
                        control={control}
                        name={`percentage.${curr.code}`}
                        allowNegative={false}
                        type="int"
                        suffix="%"
                        className="max-w-[128px] mr-10"
                        readOnly={!transactionalPermissions.edit}
                      />
                    </PermissionSection>
                  )}
                </div>
              ))}
            </div>
          </form>
        </>
      </PermissionSection>
    </div>
  );
};

export default Transactional;
