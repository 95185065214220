import { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { hasPermission } from 'pages/dashboard/selectors';
import Alert, { AlertTypes } from 'components/Alert';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

import { getCreditCardNetworks, getCardsAccepted } from '../selectors';
import CardsAcceptedButton from './CardsAcceptedButton';

export enum CardLogos {
  'Visa' = 'visa_icon.png',
  'Mastercard' = 'mastercard_icon.png',
  'AMEX' = 'amex_icon.png',
  'Discover' = 'discover_icon.png',
}

const FinancialCardsAccepted = ({ register, setValue, showErrors }) => {
  const { t } = useTranslation('financial');

  const creditCardNetworks = useAppSelector(getCreditCardNetworks);
  const cardsAccepted = useAppSelector(getCardsAccepted);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  useEffect(() => {
    if (creditCardNetworks.length) {
      creditCardNetworks.map((cardNetwork, index) => {
        const cardAccepted = cardsAccepted.find(
          (card) =>
            card.creditCardNetworksId === cardNetwork.creditCardNetworksId,
        );

        if (cardAccepted) {
          setValue(
            `businessesFinancialsCardsAccepted.${index}.businessesFinancialsCardsAcceptedId`,
            cardAccepted.businessesFinancialsCardsAcceptedId,
          );
        }

        setValue(
          `businessesFinancialsCardsAccepted.${index}.creditCardNetworksId`,
          cardNetwork.creditCardNetworksId,
        );
      });
    }
  }, [cardsAccepted, creditCardNetworks, setValue]);

  return (
    <div>
      <p className="text-secondary">{t('financial:specifyPayments')}</p>
      {showErrors &&
        !cardsAccepted.some(
          ({ last24Months, last12Months }) => last24Months || last12Months,
        ) && (
          <Alert
            type={AlertTypes.warning}
            title={t('financial:specifyPaymentsRequired')}
            className="mt-2"
            withBorder
          />
        )}
      <div className="mt-6">
        <p className="text-secondary mb-1">{t('financial:last12Months')}</p>
        <div className="flex flex-wrap items-center -mx-2">
          {creditCardNetworks.map((cardNetwork, index) => (
            <Fragment key={cardNetwork.creditCardNetworksId}>
              <input
                type="hidden"
                {...register(
                  `businessesFinancialsCardsAccepted.${index}.creditCardNetworksId`,
                  { valueAsNumber: true },
                )}
              />
              {cardsAccepted.length > 0 && (
                <input
                  type="hidden"
                  {...register(
                    `businessesFinancialsCardsAccepted.${index}.businessesFinancialsCardsAcceptedId`,
                    {
                      valueAsNumber: true,
                    },
                  )}
                />
              )}
              <CardsAcceptedButton
                key={cardNetwork.creditCardNetworksId}
                disabled={!financialPermissions.edit}
                cardNetwork={cardNetwork}
                type="last12Months"
                register={register}
                setValue={setValue}
                index={index}
              />
            </Fragment>
          ))}
        </div>
      </div>

      <div className="mt-6 pb-6 border-b">
        <p className="text-secondary mb-1">{t('financial:last24Months')}</p>
        <div className="flex flex-wrap items-center -mx-2">
          {creditCardNetworks.map((cardNetwork, index) => (
            <CardsAcceptedButton
              key={cardNetwork.creditCardNetworksId}
              disabled={!financialPermissions.edit}
              cardNetwork={cardNetwork}
              type="last24Months"
              register={register}
              setValue={setValue}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinancialCardsAccepted;
