import { useTranslation } from 'react-i18next';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { ExclamationIcon } from 'components/icons';

type WarningDialogProps = {
  title?: string;
  text?: string;
  onContinue?: () => void;
  onCancel?: () => void;
  buttonConfirmText?: string;
};

const WarningDialog: React.FC<WarningDialogProps> = ({
  title,
  text,
  onContinue,
  onCancel,
  buttonConfirmText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-6">
      <ExclamationIcon className="w-11 h-11 text-yellow-600 mx-auto mt-8" />
      <div className="text-center my-6">
        {title && <p className="text-lg font-semibold mb-2">{t(title)}</p>}
        {text && <p className="text-secondary">{t(text)}</p>}
      </div>

      {onContinue && (
        <Button
          size={ButtonSize.mediumLarge}
          className="w-full mb-4"
          onClick={onContinue}
        >
          {buttonConfirmText || t('common:continue')}
        </Button>
      )}

      {onCancel && (
        <Button
          size={ButtonSize.mediumLarge}
          variant={ButtonVariant.secondaryOutline}
          className="w-full"
          onClick={onCancel}
        >
          {t('common:cancel')}
        </Button>
      )}
    </div>
  );
};

export default WarningDialog;
