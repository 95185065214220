export enum CountryISOCodes {
  Canada = '124',
  USA = '840',
  Mexico = '484',
  Brazil = '076',
  India = '356',
  Australia = '036',
  Germany = '276',
  Nigeria = '566',
  Malaysia = '458',
  Venezuela = '862',
  Austria = '040',
  SouthSudan = '728',
  Sudan = '729',
  Micronesia = '583',
  Myanmar = '104',
  Palau = '585',
}

export enum CountriesWithStates {
  USA = CountryISOCodes.USA,
  Mexico = CountryISOCodes.Mexico,
  Brazil = CountryISOCodes.Brazil,
  India = CountryISOCodes.India,
  Australia = CountryISOCodes.Australia,
  Germany = CountryISOCodes.Germany,
  Nigeria = CountryISOCodes.Nigeria,
  Malaysia = CountryISOCodes.Malaysia,
  Venezuela = CountryISOCodes.Venezuela,
  Austria = CountryISOCodes.Austria,
  SouthSudan = CountryISOCodes.SouthSudan,
  Sudan = CountryISOCodes.Sudan,
  Micronesia = CountryISOCodes.Micronesia,
  Myanmar = CountryISOCodes.Myanmar,
  Palau = CountryISOCodes.Palau,
}
