import { useTranslation } from 'react-i18next';
import React from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import { ApplicationStatuses } from 'entities/progress';
import { CloseCircleIcon } from 'components/icons';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';
import { useAppSelector } from 'hooks';

import { getLoadingButton } from '../selectors';

type ModalActionsProps = {
  handleSuspend: () => void;
  handleApprove: () => void;
  handleDecline: () => void;
  handleActivate: () => void;
  status: string | null;
};

const ModalActions: React.FC<ModalActionsProps> = ({
  handleSuspend,
  handleApprove,
  handleDecline,
  handleActivate,
  status,
}) => {
  const { t } = useTranslation('dashboard');

  const loading: string = useAppSelector(getLoadingButton);
  const compliancePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.compliance),
  );

  return (
    <div className="flex items-center justify-between">
      <PermissionSection permission={Permissions.compliance} showPopover edit>
        <Button
          onClick={handleSuspend}
          loading={loading === 'suspend'}
          disabled={
            status === ApplicationStatuses.suspended ||
            status === ApplicationStatuses.declined ||
            !compliancePermissions.edit ||
            loading
          }
          variant="link-red"
        >
          <CloseCircleIcon className="mr-3" />
          {t('dashboard:compliance.suspend')}
        </Button>
      </PermissionSection>

      <div className="flex items-center">
        <PermissionSection permission={Permissions.compliance} showPopover edit>
          <Button
            onClick={handleDecline}
            variant="secondary-outline"
            loading={loading === 'decline'}
            disabled={
              status === ApplicationStatuses.suspended ||
              status === ApplicationStatuses.declined ||
              !compliancePermissions.edit ||
              loading
            }
            className="mr-6"
          >
            {t('dashboard:compliance.decline')}
          </Button>
        </PermissionSection>
        {status === ApplicationStatuses.suspended ||
        status === ApplicationStatuses.declined ? (
          <PermissionSection
            permission={Permissions.compliance}
            showPopover
            edit
          >
            <Button
              onClick={handleActivate}
              loading={loading === 'approve'}
              disabled={!compliancePermissions.edit || loading}
            >
              {t('dashboard:compliance.reActivate')}
            </Button>
          </PermissionSection>
        ) : (
          <PermissionSection
            permission={Permissions.compliance}
            showPopover
            edit
          >
            <Button
              onClick={handleApprove}
              loading={loading === 'approve'}
              disabled={
                status === ApplicationStatuses.approved ||
                status === ApplicationStatuses.complianceApproved ||
                !compliancePermissions.edit ||
                loading
              }
            >
              {t('dashboard:compliance.approve')}
            </Button>
          </PermissionSection>
        )}
      </div>
    </div>
  );
};

export default ModalActions;
