import { AxiosRequestConfig } from 'axios';

import api from 'api';

export interface BusinessPlanBlobSchema {
  businessesPlanBlobReferencesId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  businessesId: number;
  blobReferencesTypesId: number;
  blobGuid: string;
  blobFileSize: string;
  blobFileExtension: string | null;
  blobFileName: string;
  typeCode: string;
  description: string;
}

export interface BusinessPlanSchema {
  businessesPlansId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  businessesId: number;
  businessLegalName: string;
  doingBusinessAs: string;
  sellProducts: boolean;
  sellServices: boolean;
  sellOther: boolean;
  industryTypesId: number;
  industryTypesTypeCode: string;
  industryTypesDescription: string;
  kindofProducts: string;
  likeYourProducts: string;
  instore: boolean;
  online: boolean;
  others: boolean;
  currencyCodesId: number;
  currencyName: string;
  currencyShortName: string;
  alphaCode: string;
  numericCode: number;
  numericCodeConverted: string;
  exponent: number;
  currencySymbol: string;
  storeSales: number;
  onlieSales: number;
  adultBuy: boolean;
  teenBuy: boolean;
  childrenBuy: boolean;
  infantBuy: boolean;
  businessProducts: boolean;
  personalProducts: boolean;
  demographicTypesId: number;
  demographicTypesTypeCode: string;
  demographicTypesDescription: string;
  geogrphicTypesId: number;
  geogrphicTypesTypeCode: string;
  geogrphicTypesDescription: string;
  customerIndustryTypesId: number;
  customerIndustryTypeCode: string;
  customerIndustryTypeDescription: string;
  purchaseReason: string;
  bigBusiness: boolean;
  mediumBusiness: boolean;
  smallBusiness: boolean;
  emailAds: boolean;
  onlineAds: boolean;
  socialMediaAds: boolean;
  printedAds: boolean;
  phoneCalls: boolean;
  othersAds: boolean;
  typeInstore: boolean;
  typeOnline: boolean;
  typeOthers: boolean;
  expensesJSON: string;
  marketingJSON: string;
  rolesJSON: string;
  ownershipsJSON: string;
  competitorsJSON: string;
  businessesPlanBlobReferencesJSON: string;
}

export const uploadBusinessPlan = async (
  fileData: FormData,
  options = {} as AxiosRequestConfig,
) => {
  const result: BusinessPlanBlobSchema = await api.post(
    'BusinessesPlanBlobReferences/AddBusinessesPlanBlobReferences',
    fileData,
    options,
  );

  return result;
};

export const searchBusinessPlan = async (
  payload: Partial<BusinessPlanBlobSchema>,
) => {
  const result: BusinessPlanBlobSchema[] = await api.post(
    'BusinessesPlanBlobReferences/SearchBusinessesPlanBlobReferences',
    payload,
  );

  return result;
};

export const updateBusinessPlanBlob = async (
  payload: FormData,
  options = {} as AxiosRequestConfig,
) => {
  const result: Partial<BusinessPlanBlobSchema> = await api.put(
    'BusinessesPlanBlobReferences/UpdateBusinessesPlanBlobReferences',
    payload,
    options,
  );

  return result;
};

export const updateIsActivePlan = async (
  payload: Partial<BusinessPlanBlobSchema>,
) => {
  const id: number = await api.put(
    'BusinessesPlanBlobReferences/UpdateIsActiveBusinessesPlanBlobReferences',
    payload,
  );

  return id;
};

export const getFile = async (blobGUID: string) => {
  const result: Blob = await api.get(
    `BusinessesPlanBlobReferences/GetBusinessesPlanBlobReferencesFile/${blobGUID}`,
    {
      responseType: 'blob',
    },
  );

  return result;
};

export const addBusinessPlan = async (payload: Partial<BusinessPlanSchema>) => {
  const id: number = await api.post(
    'BusinessesPlans/AddBusinessesPlans',
    payload,
  );

  return id;
};

export const updateBusinessPlan = async (
  payload: Partial<BusinessPlanSchema>,
) => {
  const result: BusinessPlanSchema = await api.put(
    'BusinessesPlans/UpdateBusinessesPlans',
    payload,
  );

  return result;
};

export const getBusinessPlanById = async (id: number) => {
  const result: BusinessPlanSchema[] = await api.get(
    `BusinessesPlans/GetBusinessesPlansByBusinessesId/${id}`,
  );

  return result.length ? result[0] : null;
};

export const getBusinessPlanDocumentById = async (id: number) => {
  const result: Blob = await api.get(
    `BusinessesPlans/GetBusinessesPlansDocument/${id}`,
    {
      responseType: 'blob',
    },
  );

  return result;
};
