import { MultiValue, MultiValueRemoveProps, components } from 'react-select';
import { KeyboardEventHandler, useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

import { CloseIcon } from 'components/icons/CloseIcon';

const MultiValueRemove = (props: MultiValueRemoveProps<any>) => (
  <components.MultiValueRemove {...props}>
    {props.selectProps.isDisabled ? (
      <></>
    ) : (
      <CloseIcon className="w-[14px] h-[14px] text-gray-400" />
    )}
  </components.MultiValueRemove>
);

const customComponents = {
  DropdownIndicator: null,
  MultiValueRemove,
};

interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  placeholder?: string;
  validation?: (val: string) => boolean;
  value?: any;
  setValue?: any;
  name?: string;
  isDisabled?: boolean;
  createOption?: (value: string) => any;
}

const create = (label: string) => ({
  label,
  value: label,
});

const styles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled ? 'rgb(249,250,251)' : '',
  }),
  input: (base) => ({
    ...base,
    ' input:focus': {
      boxShadow: 'none',
    },
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '4px 8px',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'rgb(243,244,246)',
    margin: 4,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {
      backgroundColor: 'transparent',
    },
  }),
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  placeholder,
  validation,
  setValue,
  value,
  name,
  isDisabled,
  createOption,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [values, setValues] = useState<readonly Option[]>([]);

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [value]);

  useEffect(() => {
    if (setValue) {
      setValue(name, values, { shouldValidate: true });
    }
  }, [values, name, setValue]);

  const addNewValue = (newValue: string) => {
    if (!values.find((v) => v.value === newValue)) {
      setValues((prev) => [
        ...prev,
        createOption ? createOption(newValue) : create(newValue),
      ]);
    }
  };

  const handleChange = (newValues: MultiValue<Option>) => {
    setValues(newValues);
  };

  const handleInput = (newValue: string) => {
    if (validation && validation(newValue)) {
      addNewValue(newValue);
      setInputValue('');
    } else {
      setInputValue(newValue);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
      case ' ':
        event.preventDefault();

        if (!validation || validation(inputValue)) {
          addNewValue(inputValue);
        }

        setInputValue('');
    }
  };

  return (
    <CreatableSelect
      onChange={handleChange}
      onInputChange={handleInput}
      components={customComponents}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      inputValue={inputValue}
      menuIsOpen={false}
      styles={styles}
      value={values}
      isDisabled={isDisabled}
      isClearable
      isMulti
    />
  );
};

export default MultiSelect;
