import { BuildingIcon } from 'components/icons/BuildingIcon';
import classNames from 'classnames';

type BusinessCardProps = {
  business: any;
  onSelect: (id: number) => void;
  selected: boolean;
};

const BusinessCard: React.FC<BusinessCardProps> = ({
  business,
  onSelect,
  selected,
}) => (
  <button
    className={classNames(
      'rounded-lg shadow-sm mb-4 px-6 py-4 border cursor-pointer w-full text-left',
      {
        'ring-2 ring-blue-600 border-transparent': selected,
        'border-gray-300': !selected,
      },
    )}
    onClick={() => onSelect(business.displaySequence)}
  >
    <h6 className="font-medium text-gray-900 mb-2">
      {business.organization.primaryName}
    </h6>
    <div className="flex items-center">
      <BuildingIcon />
      <p className="text-sm text-gray-500 ml-1">
        {business.organization.primaryAddress.streetAddress.line1},{' '}
        {business.organization.primaryAddress.addressLocality.name}
        {business.organization.primaryAddress.addressRegion.name}{' '}
        {business.organization.primaryAddress.postalCode}
      </p>
    </div>
  </button>
);

export default BusinessCard;
