import { useEffect, useState } from 'react';

import { fetchFXProviderLogo } from 'services/crossborder';
import { useAppDispatch, useAppSelector } from 'hooks';
import Loading from 'components/Loading';

import { addFXProviderLogo } from '../fxProviderSlice';
import { getFXProviderLogos } from '../selectors';

const FXProviderLogo = ({ id, alt, className }) => {
  const dispatch = useAppDispatch();

  const FXProvidersLogos = useAppSelector(getFXProviderLogos);

  const [loading, setLoading] = useState<boolean>(true);
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    const fetchProviderLogo = async (providerId: number) => {
      const providerLogo = await fetchFXProviderLogo(providerId);

      if (providerLogo) {
        setImage(providerLogo);

        dispatch(
          addFXProviderLogo({
            id,
            logo: providerLogo,
          }),
        );
      }

      setLoading(false);
    };

    const logoDownloaded = FXProvidersLogos.find((logo) => logo.id === id);

    if (logoDownloaded) {
      setImage(logoDownloaded.logo);
      setLoading(false);
    } else {
      fetchProviderLogo(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={`relative w-12 ${className}`}>
        <Loading />
      </div>
    );
  }

  if (!image) {
    return <span>{alt || ''}</span>;
  }

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: image }} />
  );
};
export default FXProviderLogo;
