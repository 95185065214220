import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import LayoutSignUp from 'components/layouts/LayoutSignUp';
import PoweredBy from 'components/layouts/PoweredBy';
import { enumHasValue } from 'utils/helpers';
import Tabs from 'components/Tabs';
import { Languages } from 'config';
import path from 'common/path';

import TranscardSmartSuite from './components/TranscardSmartSuite';
import CanadaSupplement from './components/CanadaSupplement';
import UKSupplement from './components/UKSupplement';

enum TandCCountries {
  US = 'US',
  UK = 'UK',
  CA = 'CA',
}

const TermsOfUse = () => {
  const { t } = useTranslation('terms-of-use');
  const { lang, country } = useParams();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<number>(0);

  const tabs = [
    {
      name: t('tab-main'),
      key: TandCCountries.US,
      component: <TranscardSmartSuite />,
    },
    {
      name: t('tab-uk'),
      key: TandCCountries.UK,
      component: <UKSupplement />,
      hidden: true,
    },
    {
      name: t('tab-ca'),
      key: TandCCountries.CA,
      component: <CanadaSupplement />,
    },
  ];

  useEffect(() => {
    if (country && enumHasValue(TandCCountries, country.toUpperCase())) {
      const selectTabIndex = tabs.findIndex(
        (tab) => tab.key === (country.toUpperCase() || TandCCountries.US),
      );

      if (selectTabIndex !== currentTab) {
        setCurrentTab(selectTabIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (lang && !enumHasValue(Languages, lang.toLowerCase())) {
      navigate(`${path.terms}/${Languages.EN.toUpperCase()}`);
    }
  }, [lang, navigate]);

  const handleTabChange = (value: number) => {
    if (!country) {
      setCurrentTab(value);
    } else {
      navigate(`${path.terms}/${tabs[value].key}/${lang?.toUpperCase()}`);
    }
  };

  return (
    <LayoutSignUp fullWidth>
      <div className="max-w-[1096px] m-auto">
        <p>{t('title')}</p>
        <Tabs
          tabs={tabs}
          currentTab={currentTab}
          onChange={handleTabChange}
          className="px-0 sm:px-6 pt-0 sm:pt-4 border-b border-gray-200 space-x-2 sm:space-x-8"
          tabClassName="border-b-2"
        >
          <div className="pt-6">{tabs[currentTab]?.component || null}</div>
        </Tabs>
        <PoweredBy />
      </div>
    </LayoutSignUp>
  );
};

export default TermsOfUse;
