import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ProductIcon,
  Services,
  Others,
  AddUserCircleIcon,
  AddUserGray,
} from 'components/icons';
import Modal from 'components/Modal';
import { Select, Input } from 'components/Inputs';
import { BusinessPlan } from 'common/businessPlan';
import { useAppDispatch, useAppSelector } from 'hooks';

import HorizontalPipe from './HorizontalPipe';
import CheckboxWithIcon from './CheckboxWithIcon';
import { Tablet, TabletText, TabletSubText, TabletLink } from './Tablet';
import OwnerModalForm from './OwnerModalForm';
import { setBusinessPlan } from '../businessPlanSlice';
import {
  getBusinessPlanItems,
  getDropdowns,
  getSelectedValue,
} from '../selectors';
import OtherRoleForm from './OtherRoleForm';

enum OptionKeys {
  IndustryTypesId = 'industryTypesId',
  industryTypes = 'industryTypes',
  TypeCode = 'typeCode',
}

interface AboutYourBusinessProps {
  step: number;
}

const AboutYourBusiness: React.FC<AboutYourBusinessProps> = ({ step }) => {
  const dispatch = useAppDispatch();
  const {
    sellProducts,
    sellServices,
    sellOther,
    kindofProducts,
    industryTypesId,
    rolesJSON,
    ownershipsJSON,
    likeYourProducts,
  } = useAppSelector(getBusinessPlanItems);
  const { industryTypes } = useAppSelector(getDropdowns);
  const selectedIndustry = useAppSelector(
    getSelectedValue(
      industryTypesId,
      OptionKeys.industryTypes,
      OptionKeys.IndustryTypesId,
    ),
  );
  const [isOpenOwenerModal, setOwnerModalState] = useState(false);
  const [isOpenRoleModal, setRoleModalState] = useState(false);

  const [updateValues, setValues] = useState<any>();
  const [updatedIndex, setIndex] = useState<null | number>(null);

  const { t } = useTranslation('dashboard');

  const handleState = (key: string, value: boolean) => {
    dispatch(setBusinessPlan({ key, value }));
  };

  const handleChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value ?? e;
    dispatch(setBusinessPlan({ key, value }));
  };

  const handleOwnerModal = () => {
    if (isOpenOwenerModal) {
      setValues(null);
      setIndex(null);
    }

    setOwnerModalState(!isOpenOwenerModal);
  };

  const handleEditOwner = (value, index) => {
    setValues(value);
    setIndex(index);
    handleOwnerModal();
  };

  const dispatchNewOwner = (list) => {
    dispatch(
      setBusinessPlan({
        key: BusinessPlan.ownershipsJSON,
        value: list,
      }),
    );
  };

  const handleOwnerDelete = (deleteByIndex) => {
    const filter = ownershipsJSON.filter(
      (item, index) => index !== deleteByIndex,
    );
    dispatchNewOwner(filter);
  };

  const addNewOwner = (value) => {
    handleOwnerModal();

    if (typeof updatedIndex === 'number') {
      const newArr = ownershipsJSON.map((item, index) => {
        if (index !== updatedIndex) {
          return item;
        }
      });

      newArr[updatedIndex] = value;
      dispatchNewOwner(newArr);

      setIndex(null);
      setValues(null);
      return;
    }

    dispatchNewOwner([...ownershipsJSON, value]);
  };

  const handleRoleModal = () => {
    if (isOpenRoleModal) {
      setValues(null);
      setIndex(null);
    }

    setRoleModalState(!isOpenRoleModal);
  };

  const handleEditRole = (value, index) => {
    setValues(value);
    setIndex(index);
    handleRoleModal();
  };

  const dispatchNewRole = (list) => {
    dispatch(
      setBusinessPlan({
        key: BusinessPlan.rolesJSON,
        value: list,
      }),
    );
  };

  const handleRoleDelete = (deleteByIndex) => {
    const filter = rolesJSON.filter((item, index) => index !== deleteByIndex);
    dispatchNewRole(filter);
  };

  const addNewRole = (value) => {
    handleRoleModal();

    if (typeof updatedIndex === 'number') {
      const newArr = rolesJSON.map((item, index) => {
        if (index !== updatedIndex) {
          return item;
        }
      });

      newArr[updatedIndex] = value;
      dispatchNewRole(newArr);

      setIndex(null);
      setValues(null);
      return;
    }

    dispatchNewRole([...rolesJSON, value]);
  };

  const selectedValue = selectedIndustry
    ? {
        name: selectedIndustry[OptionKeys.TypeCode],
        value: selectedIndustry[OptionKeys.IndustryTypesId],
      }
    : null;

  if (step !== 1) {
    return null;
  }
  return (
    <div className="w-full">
      <h1 className="text-2xl leading-8 font-bold">
        {t('dashboard:businessPlan.modal.aboutBusiness')}
      </h1>
      <HorizontalPipe />
      <div className="">
        <label className="text-sm leading-5 font-medium mb-[10px]">
          {t('dashboard:businessPlan.modal.wahtDoYouSell')}
        </label>
        <div className="mt-[10px] flex gap-x-6 flex-wrap gap-y-6">
          <CheckboxWithIcon
            onChange={() =>
              handleState(BusinessPlan.sellProducts, !sellProducts)
            }
            checked={sellProducts}
            label={t('dashboard:businessPlan.modal.products')}
            icon={<ProductIcon />}
          />
          <CheckboxWithIcon
            onChange={() =>
              handleState(BusinessPlan.sellServices, !sellServices)
            }
            checked={sellServices}
            label={t('dashboard:businessPlan.modal.services')}
            icon={<Services />}
          />
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.sellOther, !sellOther)}
            checked={sellOther}
            label={t('dashboard:businessPlan.modal.others')}
            icon={<Others />}
          />
        </div>
        <div className="flex items-center mt-9 flex-wrap gap-x-12">
          <label className="text-sm leading-5 font-medium mb-[10px]">
            {t('dashboard:businessPlan.modal.selectProduct')}
          </label>
          <div className="flex flex-1 w-full">
            <Select
              className="w-full"
              name="productIndustry"
              optionalLabel={OptionKeys.TypeCode}
              optionValue={OptionKeys.IndustryTypesId}
              value={selectedValue}
              onChange={(option: any) =>
                handleChange(BusinessPlan.industryTypesId, option.value)
              }
              options={industryTypes}
              placeholder={t(
                'dashboard:businessPlan.modal.selectProductPlaceholder',
              )}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-x-9 mt-9 gap-y-4">
          <div className="flex flex-1 w-full flex-col">
            <Input
              className="w-full"
              onChange={(event: any) =>
                handleChange(BusinessPlan.kindofProducts, event)
              }
              name="prduct"
              type="textarea"
              value={kindofProducts || ''}
              label={t('dashboard:businessPlan.modal.kindOfProducts')}
            />
            <span className="text-sm leading-5 font-normal text-gray-500">
              {t('dashboard:businessPlan.modal.kindOfProductsDescripton')}
            </span>
          </div>
          <div className="flex flex-1 w-full flex-col">
            <Input
              className="w-full"
              name="prduct"
              type="textarea"
              onChange={(event: any) =>
                handleChange(BusinessPlan.likeYourProducts, event)
              }
              value={likeYourProducts || ''}
              label={t('dashboard:businessPlan.modal.productsLike')}
            />
            <span className="text-sm leading-5 font-normal text-gray-500">
              {t('dashboard:businessPlan.modal.kindOfProductsDescripton')}
            </span>
          </div>
        </div>
        <div className="mt-9 flex flex-col">
          <label className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard:businessPlan.modal.keyRoles')}
          </label>
          <span className="text-sm leading-5 font-normal mt-1 text-gray-500">
            {t('dashboard:businessPlan.modal.keyRolesDescription')}
          </span>
        </div>
        <HorizontalPipe />
        <div className="flex flex-col">
          <label className="text-sm leading-5 font-medium mb-[10px]">
            {t('dashboard:businessPlan.modal.currentStructure')}
          </label>
          <div className="flex gap-x-4 flex-wrap gap-y-4">
            {ownershipsJSON.map(({ name, shareOfOwnership }, index) => (
              <div key={index}>
                <Tablet
                  withDefaultIcon
                  iconTextLabel={name?.[0]?.toUpperCase()}
                  onEdit={() =>
                    handleEditOwner({ name, shareOfOwnership }, index)
                  }
                  onDelete={() => handleOwnerDelete(index)}
                >
                  <TabletText>{name}</TabletText>
                  <TabletSubText>{`${shareOfOwnership}%`}</TabletSubText>
                </Tablet>
              </div>
            ))}

            <TabletLink onClick={handleOwnerModal} icon={<AddUserCircleIcon />}>
              {t('dashboard:businessPlan.buttons.addAnotherOwner')}
            </TabletLink>
          </div>
        </div>
        <div className="flex flex-col mt-5">
          <label className="text-sm leading-5 font-medium mb-[10px]">
            {t('dashboard:businessPlan.modal.otherKeyRoles')}
          </label>
          <div className="flex gap-x-4 flex-wrap gap-y-4">
            {rolesJSON.map(({ name, role }, index) => (
              <div key={index}>
                <Tablet
                  withDefaultIcon
                  iconTextLabel={name?.[0]?.toUpperCase()}
                  onEdit={() => handleEditRole({ name, role }, index)}
                  onDelete={() => handleRoleDelete(index)}
                >
                  <TabletText>{name}</TabletText>
                  <TabletSubText>{role}</TabletSubText>
                </Tablet>
              </div>
            ))}
            <TabletLink onClick={handleRoleModal} icon={<AddUserCircleIcon />}>
              {t('dashboard:businessPlan.buttons.addAnotherRole')}
            </TabletLink>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenOwenerModal}
        closeModal={handleOwnerModal}
        maxWidth="50rem"
        title={t(
          `dashboard:businessPlan.buttons.${
            updatedIndex !== null ? 'edit' : 'add'
          }AnotherOwner`,
        )}
        icon={<AddUserGray />}
        content={{
          maxWidth: '449px',
        }}
        closeTimeoutMS={0}
      >
        <OwnerModalForm
          onClose={handleOwnerModal}
          onSave={addNewOwner}
          values={updateValues}
        />
      </Modal>
      <Modal
        isOpen={isOpenRoleModal}
        closeModal={handleRoleModal}
        maxWidth="50rem"
        title={t(
          `dashboard:businessPlan.buttons.${
            updatedIndex !== null ? 'edit' : 'add'
          }AnotherRole`,
        )}
        icon={<AddUserGray />}
        content={{
          maxWidth: '449px',
        }}
        closeTimeoutMS={0}
      >
        <OtherRoleForm
          onClose={handleRoleModal}
          onSave={addNewRole}
          values={updateValues}
        />
      </Modal>
    </div>
  );
};

export default AboutYourBusiness;
