import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { BankAccount as BankAccountProps } from 'entities/bankAccounts';
import { BankAccountStatusIds } from 'entities/bankAccounts';
import bankImage from 'assets/images/bank-account.png';
import BankAccountStatus from './BankAccountStatus';

interface BankAccountInterface {
  account: BankAccountProps;
  erpBankNameRequired: boolean;
  onClick: () => void;
}

const BankAccount: React.FC<BankAccountInterface> = ({
  account,
  erpBankNameRequired,
  onClick,
}) => {
  const { t } = useTranslation('dashboard');
  const status =
    account.bankAccountStatus || t('dashboard:connectors.inProgress') || '';

  return (
    <button
      className={classNames(
        'flex items-center py-2 px-3 hover:bg-gray-100 rounded-md w-full text-left mb-1 border',
        account.bankAccountStatusId === BankAccountStatusIds.actionRequired ||
          (erpBankNameRequired && !account.erpBankAccountName)
          ? 'border-yellow-400 bg-yellow-50'
          : 'border-transparent',
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        <img src={bankImage} alt="Bank Account" className="h-8 w-8" />

        <div className="ml-3 text-sm">
          <div className="flex items-center">
            <p className="font-medium text-gray-900 mr-2">
              {account.userCalledName || account.accountName || ''}
            </p>
            <BankAccountStatus
              status={status}
              statusId={account.bankAccountStatusId}
            />
          </div>
          <p className="text-secondary">
            {account.accountNumberLast4
              ? t('dashboard:connectors.bank.info', {
                  name: account.bankName,
                  number: account.accountNumberLast4,
                })
              : account.bankName}
          </p>
        </div>
      </div>
    </button>
  );
};

export default BankAccount;
