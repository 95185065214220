import { createSlice } from '@reduxjs/toolkit';

import { clearDashboard } from 'pages/dashboard/thunks';
import { CrossBorderSchema } from 'services/businesses';

import { saveCrossBorder, fetchCrossBorder } from './thunks';

type TransactionalStateType = {
  crossBorder: Partial<CrossBorderSchema> | null;
};

export const initialState: TransactionalStateType = {
  crossBorder: null,
};

const transactionalSlice = createSlice({
  name: 'transactional',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(saveCrossBorder.fulfilled, (state, action) => {
      state.crossBorder = action.payload;
    });
    builder.addCase(fetchCrossBorder.fulfilled, (state, action) => {
      state.crossBorder = action.payload.crossBorder;
    });
  },
});

export const {} = transactionalSlice.actions;

export default transactionalSlice.reducer;
