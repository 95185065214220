import * as yup from 'yup';

import { BusinessBPSTrackSchema } from 'services/businesses';

export const getMappedCreateBPSTrack = (
  data: Partial<BusinessBPSTrackSchema>,
) => ({
  createdBy: data.createdBy,
  isActive: true,
  businessesId: data.businessesId,
  buyerEnabled: false,
  supplierEnabled: false,
  bankAccountId: data.bankAccountId || 0,
  supplierRate: data.supplierRate || null,
  supplierMinTenor: data.supplierMinTenor || null,
  supplierMaxTenor: data.supplierMaxTenor || null,
});

export const getPaymentPreferencesSchema = (t) =>
  yup.object().shape({
    supplierRate: yup
      .number()
      .typeError(t('configuration:preferences.rate.validation.required'))
      .min(0.01, t('configuration:preferences.rate.validation.min'))
      .max(100, t('configuration:preferences.rate.validation.max'))
      .required(t('configuration:preferences.rate.validation.required')),
    supplierMinTenor: yup
      .number()
      .typeError(t('configuration:preferences.ranges.min.validation.required'))
      .min(5, t('configuration:preferences.ranges.min.validation.min'))
      .required(t('configuration:preferences.ranges.min.validation.required')),
    supplierMaxTenor: yup
      .number()
      .typeError(t('configuration:preferences.ranges.max.validation.required'))
      .required(t('configuration:preferences.ranges.max.validation.required'))
      .when('supplierMinTenor', (supplierMinTenor, schema) =>
        supplierMinTenor
          ? schema.min(
              supplierMinTenor,
              t('configuration:preferences.ranges.max.validation.min'),
            )
          : schema,
      ),
  });
