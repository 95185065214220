import axios from 'axios';

import { getBaseUrl } from 'utils/url';
import headers from 'common/headers';

export interface APIResponseSchema {
  errorCode: number | null;
  errorFound: boolean;
  errorMessage: string | null;
}

export interface APIResponseIdSchema extends APIResponseSchema {
  id: number;
}

export interface APIResponseItemSchema<Type> extends APIResponseSchema {
  result: Type | null;
}

export interface APIResponseArraySchema<Type> extends APIResponseSchema {
  result: Type[] | null;
}

const api = axios.create({
  headers,
  baseURL: getBaseUrl(),
});

export default api;
