import { Switch } from '@headlessui/react';
import classNames from 'classnames';

interface SwitchProps {
  isOn: boolean;
  disabled?: boolean;
  handleToggle: () => void;
}

const SwitchButton: React.FC<SwitchProps> = ({
  isOn,
  disabled,
  handleToggle,
}) => {
  return (
    <Switch
      checked={isOn}
      disabled={disabled}
      onChange={handleToggle}
      className={classNames(
        { 'cursor-default opacity-50': disabled },
        isOn ? 'bg-blue-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          isOn ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </Switch>
  );
};

export default SwitchButton;
