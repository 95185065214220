import { createAsyncThunk } from '@reduxjs/toolkit';

import { submitBusinessApplication } from 'services/businesses';
import { fetchProfileByEmail } from 'pages/Profile/thunks';
import { getProfile } from 'pages/Profile/selectors';
import { getTenant } from 'theme/selectors';
import { RootState } from 'state/store';

export const submitApplication: any = createAsyncThunk(
  'submitDashboard/submitApplication',
  async (_: void, thunks) => {
    try {
      const { getState, dispatch } = thunks;

      const state = getState() as RootState;

      const { business, person } = getProfile(state);
      const tenant = getTenant(state);

      const businessPersonsXrefs: any = await submitBusinessApplication({
        businessesId: business.businessesId,
        modifiedBy: person.emailAddress,
        fromEmailId: tenant.supportEmailAddress,
        tenantsId: tenant.tenantsId,
      });

      await dispatch(fetchProfileByEmail(person.emailAddress));

      return businessPersonsXrefs;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
