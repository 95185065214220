import Person from './Person';
import Badge from 'components/Badge';

const Persons = ({ persons }) => {
  if (!persons?.length) {
    return null;
  }

  if (persons.length > 5) {
    const name = persons[0];

    return (
      <div className="flex relative mr-3">
        <Person name={name} index={0} />
        <Badge
          color="blue"
          rounded="rounded-full"
          className="absolute flex items-center justify-center w-[15px] h-[20px] top-[-8px] right-[-18px]"
        >
          {persons.length}
        </Badge>
      </div>
    );
  }

  return (
    <div className="flex flex-row-reverse mr-3">
      {persons.map((value, index) => (
        <div key={index}>
          <Person name={value} index={index} />
        </div>
      ))}
    </div>
  );
};

export default Persons;
