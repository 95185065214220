import { ErpAccountSchema, ERPConnectorSchema } from 'services/erp';

export interface Auth {
  authId: number;
  authType: number;
  connectorId: number;
  createdDate: string;
  derrivedFromTemplate: boolean;
  isHidden: boolean;
  isRequired: boolean;
  isPrivate: boolean;
  key: string;
  keyType: number;
  password: boolean;
  remove: boolean;
  updatedDate: string;
  value: string;
}

export interface Provider {
  name: string;
  auths: Auth[];
  logoFileShareUri: string;
  guideFileShareUri: string;
  guideText: string;
  helpText: string;
  connectorId: number;
}

export interface ERPConnector extends ERPConnectorSchema {
  logoFileShareUri: string;
  guideFileShareUri: string;
  guideText: string;
  helpText: string;
}

export interface ErpAccount extends ErpAccountSchema {
  authTokensId: number;
  authTokenIsActive: boolean;
  isTokenChanged: boolean;
  accessToken: string | null;
  accessTokenExpiry: number | null;
  refreshToken: string | null;
  refreshTokenExpiry: number | null;
  nextRenewalDate: string | null;
  nextExpiryDate: string | null;
  erpsentDate: string | null;
  erpresponseDate: string | null;
  erperrorSent: boolean;
  erpresponseRecipient: string | null;
  refreshTokenNextExpiryDate: string | null;
  connectorName: string;
  erpaccountStatus: string;
}

export enum ERPProviders {
  intacct = 'Intacct',
  quickbooks = 'QuickBooks',
  acumatica = 'Acumatica',
}

export enum FilterType {
  erp = 'ERP',
}

export const ProvidersWithExpireDate = ['QuickBooks'];

export enum AccountingFieldNames {
  user = 'User ID',
  company = 'Company',
  password = 'Password',
}

export enum ERPAcountStatuses {
  credentials = 'Pending Credentials',
  verification = 'Pending Verification',
  connected = 'Connected',
  action = 'Action Required',
}

export enum DataSourceWidgets {
  api = 1,
  erp = 2,
  batch = 3,
}
