import { Trans, useTranslation } from 'react-i18next';

import { Languages } from 'config';
import Link from 'components/Link';
import path from 'common/path';

import LastUpdated from './LastUpdated';
import Typography from './Typography';
import SubTitle from './SubTitle';
import Title from './Title';

const CanadaSupplement = () => {
  const { t } = useTranslation('terms-of-use');

  return (
    <div>
      <LastUpdated />
      <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-3 sm:mb-6">
        {t('canada.supplement')}
      </h2>
      <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-6 sm:mb-12">
        <Trans
          ns="terms-of-use"
          i18nKey="canada.title"
          components={{ 1: <span className="text-blue-600" /> }}
        />
      </h2>
      <div className="mb-12">
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.text"
            components={{ 1: <b /> }}
          />
        </Typography>

        <Title>{t('canada.intro-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.intro-p-1"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.intro-p-2"
            components={{
              1: (
                <Link
                  href="https://fintrac-canafe.canada.ca/contact-contactez/1-eng"
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>{t('canada.intro-p-3')}</Typography>
        <Typography last>{t('canada.intro-p-4')}</Typography>

        <Title>{t('canada.service-t')}</Title>
        <SubTitle>{t('canada.service-a-t')}</SubTitle>
        <Typography>{t('canada.service-a-p')}</Typography>
        <SubTitle>{t('canada.service-b-t')}</SubTitle>
        <SubTitle>{t('canada.service-b-t-1')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-1"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <SubTitle>{t('canada.service-b-t-2')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-2"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-3"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-4"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-5"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-6"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <div className="pl-6">
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-6-a"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-6-b"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-6-c"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-6-d"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-6-e"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-6-f"
              components={{ 1: <b /> }}
            />
          </Typography>
        </div>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-7"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-8"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-9"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-10"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-11"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <div className="pl-6">
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-11-a"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-11-b"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-11-c"
              components={{ 1: <b /> }}
            />
            <span className="block pl-6">
              <Trans
                ns="terms-of-use"
                i18nKey="canada.service-b-p-11-c-1"
                components={{ 1: <b /> }}
              />
              <br />
              <Trans
                ns="terms-of-use"
                i18nKey="canada.service-b-p-11-c-2"
                components={{ 1: <b /> }}
              />
              <br />
              <Trans
                ns="terms-of-use"
                i18nKey="canada.service-b-p-11-c-3"
                components={{ 1: <b /> }}
              />
              <br />
              <Trans
                ns="terms-of-use"
                i18nKey="canada.service-b-p-11-c-4"
                components={{ 1: <b /> }}
              />
              <br />
              <Trans
                ns="terms-of-use"
                i18nKey="canada.service-b-p-11-c-5"
                components={{ 1: <b /> }}
              />
              <br />
              <Trans
                ns="terms-of-use"
                i18nKey="canada.service-b-p-11-c-6"
                components={{ 1: <b /> }}
              />
            </span>
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-11-d"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-11-e"
              components={{ 1: <b /> }}
            />
            <br />
            <Trans
              ns="terms-of-use"
              i18nKey="canada.service-b-p-11-f"
              components={{ 1: <b /> }}
            />
          </Typography>
        </div>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-12"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-13"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-14"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-15"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-16"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-17"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href="mailto:support@smart-enroll.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-18"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-19"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-20"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-21"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-22"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-23"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-24"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>

        <SubTitle>{t('canada.service-b-t-3')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-25"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href="https://www.international.gc.ca/world-monde/international_relations-relations_internationales/sanctions/consolidated-consolide.aspx?lang=eng"
                  className="font-medium"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-b-p-26"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>

        <SubTitle>{t('canada.service-c-t')}</SubTitle>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-c-p"
            components={{
              1: (
                <Link
                  href="mailto:support@smart-enroll.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>

        <SubTitle>{t('canada.service-d-t')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-d-p-1"
            components={{
              1: (
                <Link
                  href="https://pricing.smart-hub.com/"
                  className="font-medium"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-d-p-2"
            components={{ 1: <b /> }}
          />
        </Typography>

        <SubTitle>{t('canada.service-e-t')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-1"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href={path.privacy}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-2"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href="mailto:privacy@transcard.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-3"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-4"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-5"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-6"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href="mailto:support@smart-enroll.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-7"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-8"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href="mailto:support@smart-enroll.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-9"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-e-p-10"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>

        <SubTitle>{t('canada.service-f-t')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-f-p-1"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-f-p-2"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>{t('canada.service-f-p-3')}</Typography>
        <Typography last>{t('canada.service-f-p-4')}</Typography>

        <SubTitle>{t('canada.service-g-t')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-1"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>{t('canada.service-g-p-2')}</Typography>
        <Typography last>{t('canada.service-g-p-3')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-4"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-5"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href="mailto:support@smart-enroll.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-6"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-7"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-8"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-9"
            components={{ 1: <b className="mr-4" /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="canada.service-g-p-10"
            components={{
              1: <b className="mr-4" />,
              2: (
                <Link
                  href={`${path.terms}/${Languages.FR.toUpperCase()}`}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
              3: <i />,
            }}
          />
        </Typography>
      </div>
    </div>
  );
};

export default CanadaSupplement;
