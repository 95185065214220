import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { UseFormRegister, FieldValues, RegisterOptions } from 'react-hook-form';

import PermissionSection from 'components/permission/PermissionSection';
import { InfoOutlinedIcon } from 'components/icons/InfoOutlinedIcon';
import PopoverOnHover from 'components/PopoverOnHover';
import { Permissions } from 'entities/dashboard';

interface Option {
  value: string | number;
  name: string;
  disabled?: boolean;
  content?: ReactNode;
}

interface RadioGroupProps {
  name: string;
  options: Array<Option>;
  className?: string;
  optionClassName?: string;
  optionsClassName?: string;
  label?: string;
  info?: string;
  optional?: boolean;
  register?: UseFormRegister<FieldValues>;
  watch?: any;
  validation?: RegisterOptions;
  error?: any;
  checked?: string | number;
  disabled?: boolean;
  vertical?: boolean;
  permission?: Permissions | undefined;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  name,
  label,
  className,
  optionClassName,
  optionsClassName,
  info,
  optional = false,
  register,
  watch,
  validation = {},
  error,
  checked = '',
  disabled = false,
  vertical = false,
  permission,
}) => {
  const { t } = useTranslation();

  const value: string | number = watch ? watch(name) : null;

  return (
    <div className={className}>
      {label && (
        <div className="flex justify-between items-center mb-1">
          <label className="text-sm leading-5 font-medium text-gray-700">
            {label}
          </label>
          {info && (
            <PopoverOnHover
              info={info}
              button={
                <InfoOutlinedIcon className="text-blue-600 hover:text-blue-700" />
              }
            />
          )}
          {optional && (
            <span className="text-sm leading-5 font-medium text-gray-500">
              {t('common:labelOptional')}
            </span>
          )}
        </div>
      )}

      <div
        className={classNames(
          !vertical && 'space-y-4 sm:flex sm:items-center sm:space-y-0',
          optionsClassName,
        )}
      >
        {options.map((option, index) => (
          <div key={index} className={optionClassName}>
            <PermissionSection
              permission={disabled ? permission : undefined}
              showPopover
              edit
            >
              <label
                htmlFor={name + '' + option.value}
                className={classNames(
                  'flex items-center',
                  disabled ? 'pointer-events-none' : 'cursor-pointer',
                )}
              >
                <input
                  id={name + '' + option.value}
                  name={name}
                  type="radio"
                  defaultChecked={option.value === checked}
                  value={option.value}
                  className={classNames(
                    'focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300',
                    disabled ? 'bg-gray-100' : 'cursor-pointer',
                  )}
                  {...(register && register(name, validation))}
                />
                <span
                  className={classNames(
                    'pl-3 block text-sm font-medium text-gray-700',
                  )}
                >
                  {option.name}
                </span>
              </label>
            </PermissionSection>
            {String(value) === String(option.value) && (option.content || null)}
          </div>
        ))}
      </div>

      {error && (
        <p className="text-sm leading-4 mt-2 text-red-600">{error.message}</p>
      )}
    </div>
  );
};

export default RadioGroup;
