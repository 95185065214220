import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.businessPlan;
export const getDashboardDropdowns = (state: RootState) =>
  state.dashboard.dropdowns;

export const getStep = createSelector([getRoot], ({ step }) => step);

export const getStepsLength = createSelector(
  [getRoot],
  ({ stepsLength }) => stepsLength,
);

export const getPlanAttachedState = createSelector(
  [getRoot],
  ({ isPlanAttached }) => isPlanAttached,
);

export const getprogressPercent = createSelector(
  [getRoot],
  ({ progressPercent }) => progressPercent,
);

export const getFiles = createSelector([getRoot], ({ files }) =>
  files.filter(({ isActive }) => isActive),
);

export const getlogoFiles = createSelector([getRoot], ({ logoFiles }) =>
  logoFiles.filter(({ isActive }) => isActive),
);

export const getBusinessPlan = createSelector(
  [getRoot],
  ({ businessPlan }) => businessPlan,
);

export const getBusinessPlanItems: any = createSelector(
  [getBusinessPlan],
  (businessPlanItems) => businessPlanItems,
);

export const getBusinessPlanSelectedCurrency: any = createSelector(
  [getBusinessPlan, getDashboardDropdowns],
  ({ currencyCodesId }, { inputCurrencies }) =>
    inputCurrencies.find((cur) => cur.currencyCodeID === currencyCodesId)
      ?.alphaCode || 'USD',
);

export const getIsLoading: any = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getIsLogoUpload: any = createSelector(
  [getRoot],
  ({ isLogoUpload }) => isLogoUpload,
);

export const getIsDownalod: any = createSelector(
  [getRoot],
  ({ isDownalod }) => isDownalod,
);

export const getIsDocumentLoading: any = createSelector(
  [getRoot],
  ({ isDocumentLoading }) => isDocumentLoading,
);

export const getIsBusinessPlanLoading: any = createSelector(
  [getRoot],
  ({ isBusinessPlanLoading }) => isBusinessPlanLoading,
);

export const getDropdowns: any = createSelector(
  [getRoot],
  ({
    industryTypes,
    geogrphicTypes,
    demographicTypes,
    merchantCategoryCodes,
  }) => ({
    industryTypes,
    geogrphicTypes,
    demographicTypes,
    merchantCategoryCodes,
  }),
);

export const getSelectedValue = (id, listKey, valueKey) => {
  return createSelector([getDropdowns], (list) =>
    list[listKey].find((items) => items[valueKey] === id),
  );
};
