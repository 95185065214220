import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductTypeCodes, ProgressState } from 'entities/progress';
import { getBusinessesId } from 'utils/authService';
import { getEnumKeyByValue } from 'utils/helpers';
import { ProfileState } from 'entities/profile';
import {
  searchBusinessesProductsXrefs,
  updateBusinessesProductsXrefs,
} from 'services/businessProducts';

import { updateProductById } from './utils';

export const fetchBusinessProducts = createAsyncThunk(
  'progress/searchBusinessesProducts',
  async () => {
    try {
      const businessesId: number = getBusinessesId();
      const data = await searchBusinessesProductsXrefs({ businessesId });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusinessProduct = createAsyncThunk(
  'progress/updateBusinessesProduct',
  async (fields: any, thunkApi) => {
    try {
      const { getState } = thunkApi;

      const { profile, progress } = getState() as {
        profile: ProfileState;
        progress: ProgressState;
      };
      const { person } = profile;
      const { businessProducts } = progress;

      const updateFields = {
        ...fields,
        modifiedBy: person.emailAddress,
      };

      await updateBusinessesProductsXrefs(updateFields);
      const products = updateProductById(businessProducts, updateFields);

      const newProductType = fields.isActive
        ? getEnumKeyByValue(ProductTypeCodes, fields.productsTypeCode)
        : '';

      return {
        products,
        newProductType,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
