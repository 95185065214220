import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ExclamationIcon, CloseIcon } from 'components/icons';
import Button, { ButtonVariant } from 'components/Button';
import HideComponent from 'components/HideComponent';

type DangerDialogProps = {
  title?: string;
  text?: string;
  buttonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const DangerDialog: React.FC<DangerDialogProps> = ({
  title,
  text,
  buttonText,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = () => {
    setIsLoading(true);

    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <div className="pt-14 px-6 pb-6">
      <Button
        onClick={onCancel}
        className="absolute top-4 right-4"
        paddingClass="p-2"
        variant={ButtonVariant.link}
        heightClass="h-10"
      >
        <CloseIcon className="w-6 h-6 text-gray-400" />
      </Button>

      <div className="flex justify-center mb-6">
        <ExclamationIcon className="text-red-500 w-11 h-11" />
      </div>

      <HideComponent show={title !== undefined}>
        <p className="text-lg font-semibold text-gray-900 mb-2 text-center">
          {title}
        </p>
      </HideComponent>

      <HideComponent show={text !== undefined}>
        <p className="text-secondary mb-6 text-center">{text}</p>
      </HideComponent>

      <HideComponent show={onConfirm !== undefined}>
        <Button
          className="w-full mb-4"
          variant={ButtonVariant.red}
          heightClass="h-12"
          onClick={handleConfirm}
          loading={isLoading}
        >
          {buttonText || t('delete')}
        </Button>
      </HideComponent>

      <HideComponent show={onCancel !== undefined}>
        <Button
          className="w-full"
          variant={ButtonVariant.secondaryOutline}
          heightClass="h-12"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
      </HideComponent>
    </div>
  );
};

export default DangerDialog;
