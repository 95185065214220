import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';

import { closeNotification } from './notificationSlice';
import { getNotification } from './selectors';

const Notification = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [timer, setTimer] = useState<any>(null);

  const { message, variant, translate, duration } =
    useAppSelector(getNotification);

  const handleClose = () => {
    dispatch(closeNotification());
  };

  useEffect(() => {
    if (duration && message) {
      if (timer) {
        clearTimeout(timer);
      }

      setTimer(setTimeout(handleClose, duration));
    } else {
      clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, duration]);

  if (!message) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 w-full p-6" style={{ zIndex: 999 }}>
      <div className="flex justify-center">
        <Alert
          onClose={handleClose}
          isShow={message !== ''}
          title={translate ? t(message) : message}
          type={AlertTypes[variant]}
          className="shadow-md max-w-xl"
          withClose
        />
      </div>
    </div>
  );
};

export default Notification;
