import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { equals } from 'ramda';

import { getActiveProductsTypeCodes } from 'pages/dashboard/components/Progress/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import Button, { ButtonSize } from 'components/Button';
import Label from 'components/Inputs/components/Label';
import { ProductTypeCodes } from 'entities/progress';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import { NumberInput } from 'components/Inputs';
import Loading from 'components/Loading';

import { getEarlyPaymentPreferences, getIsLoading } from './selectors';
import { getPaymentPreferencesSchema } from './utils';
import { saveBPSTrack } from './thunks';

const EarlyPaymentPreferences = () => {
  const { t } = useTranslation('configuration');
  const dispatch = useAppDispatch();

  const earlyPaymentPreferences = useAppSelector(getEarlyPaymentPreferences);
  const isLoading: boolean = useAppSelector(getIsLoading);
  const activeProductTypes = useAppSelector(getActiveProductsTypeCodes);
  const configPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.configuration),
  );

  const [defaultValues, setDefaultValues] = useState<any>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: earlyPaymentPreferences,
    resolver: yupResolver(getPaymentPreferencesSchema(t)),
  });

  useEffect(() => {
    if (!equals(earlyPaymentPreferences, defaultValues)) {
      reset(earlyPaymentPreferences);
      setDefaultValues(earlyPaymentPreferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earlyPaymentPreferences, reset]);

  const handleFormSubmit = (data: any) => {
    dispatch(saveBPSTrack(data));
  };

  const handleSave = () => {
    handleSubmit(handleFormSubmit)();
  };

  const validateRate = () => {
    const formValues = getValues();

    if (formValues.supplierRate && +formValues.supplierRate > 100) {
      setError('supplierRate', {
        message: t('configuration:preferences.rate.validation.max'),
      });
    } else {
      clearErrors('supplierRate');
    }
  };

  const validateTerms = () => {
    const formValues = getValues();

    if (
      formValues.supplierMinTenor &&
      formValues.supplierMaxTenor &&
      +formValues.supplierMaxTenor < +formValues.supplierMinTenor
    ) {
      setError('supplierMaxTenor', {
        message: t('configuration:preferences.ranges.max.validation.min'),
      });
    } else {
      clearErrors('supplierMaxTenor');
    }
  };

  const values = watch();

  if (!activeProductTypes.includes(ProductTypeCodes.scf)) {
    return null;
  }

  return (
    <div className="bg-white border shadow rounded-10 p-6 relative mt-6">
      {isLoading && <Loading transparent />}
      <div className="flex items-start justify-between pb-6 mb-6 border-b border-gray-200">
        <div>
          <h6 className="font-semibold mb-0.5">
            {t('configuration:preferences.title')}
          </h6>
          <div className="text-secondary flex items-center text-sm">
            {t('configuration:preferences.description')}
          </div>
        </div>
        <HideComponent show={configPermissions.view}>
          <PermissionSection
            permission={Permissions.configuration}
            showPopover
            edit
          >
            <Button
              onClick={handleSave}
              heightClass="h-9"
              size={ButtonSize.medium}
              disabled={
                equals(defaultValues, values) || !configPermissions.edit
              }
              className="ml-3"
            >
              {t('common:save')}
            </Button>
          </PermissionSection>
        </HideComponent>
      </div>

      <PermissionSection permission={Permissions.configuration} showMessage>
        <>
          <div className="pb-6 mb-6 border-b border-gray-200">
            <p className="font-medium mb-0.5">
              {t('configuration:preferences.rate.title')}
            </p>
            <p className="text-secondary mb-2 text-sm">
              {t('configuration:preferences.rate.description')}
            </p>
            <NumberInput
              className="max-w-[430px]"
              placeholder={t('configuration:preferences.rate.placeholder')}
              inputClassName="pl-20"
              name="supplierRate"
              prefix={t('configuration:preferences.rate.prefix')}
              prefixInline
              allowNegative={false}
              control={control}
              error={errors?.supplierRate}
              onBlur={validateRate}
              readOnly={!configPermissions.edit}
            />
          </div>
          <div>
            <p className="font-medium mb-0.5">
              {t('configuration:preferences.ranges.title')}
            </p>
            <p className="text-secondary mb-4 text-sm">
              {t('configuration:preferences.ranges.description')}
            </p>
            <div className="flex -mx-4.5">
              <div className="w-1/2 mx-4.5">
                <div className="flex items-center justify-between">
                  <Label>
                    {t('configuration:preferences.ranges.min.label')}
                  </Label>
                  <p className="text-secondary">
                    {t('configuration:preferences.ranges.info')}
                  </p>
                </div>
                <NumberInput
                  placeholder={t(
                    'configuration:preferences.ranges.min.placeholder',
                  )}
                  name="supplierMinTenor"
                  type="int"
                  allowNegative={false}
                  control={control}
                  error={errors?.supplierMinTenor}
                  onBlur={validateTerms}
                  maxLength={9}
                  readOnly={!configPermissions.edit}
                />
              </div>
              <div className="w-1/2 mx-4.5">
                <div className="flex items-center justify-between">
                  <Label>
                    {t('configuration:preferences.ranges.max.label')}
                  </Label>
                  <p className="text-secondary">
                    {t('configuration:preferences.ranges.info')}
                  </p>
                </div>
                <NumberInput
                  placeholder={t(
                    'configuration:preferences.ranges.max.placeholder',
                  )}
                  name="supplierMaxTenor"
                  type="int"
                  allowNegative={false}
                  control={control}
                  error={errors?.supplierMaxTenor}
                  onBlur={validateTerms}
                  maxLength={9}
                  readOnly={!configPermissions.edit}
                />
              </div>
            </div>
          </div>
        </>
      </PermissionSection>
    </div>
  );
};

export default EarlyPaymentPreferences;
