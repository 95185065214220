import { useEffect } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';
import Loading from 'components/Loading';

import { getShowErrors } from '../../Progress/selectors';
import { getIsLoading, getFields } from '../selectors';
import { fetchProcessorFields } from '../thunks';
import ProcessorField from './ProcessorField';

const ProcessorFields = ({
  platform,
  control,
  register,
  watch,
  setValue,
  onEdit,
}) => {
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector(getIsLoading);
  const fields: any = useAppSelector(getFields);
  const showErrors: any = useAppSelector(getShowErrors);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  useEffect(() => {
    dispatch(fetchProcessorFields());
  }, [dispatch]);

  return (
    <div className="relative grid grid-cols-2 gap-x-8 mt-6">
      {isLoading && <Loading transparent />}
      {Object.entries(fields)
        .filter((field: any) => field[1].required)
        .map((field: any) => (
          <div key={field[0]} className="mb-5">
            <ProcessorField
              data={field}
              control={control}
              register={register}
              watch={watch}
              setValue={setValue}
              disabled={!financialPermissions.edit}
              permission={Permissions.financialInfo}
              platform={platform}
              onEdit={onEdit}
              showErrors={showErrors}
            />
          </div>
        ))}
    </div>
  );
};

export default ProcessorFields;
