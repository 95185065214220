import api from 'api';

export interface PermissionSchema {
  name: string;
  description: string;
  isHidden: boolean;
  permissions_Code: string;
  security_PermissionsId: number;
}

export const getPermissions = async () => {
  const result: PermissionSchema[] = await api.get('Security/Permissions');
  return result;
};

export default {
  getPermissions,
};
