import { useTranslation } from 'react-i18next';

import bankImage from 'assets/images/bank-account.png';
import { BankAccount } from 'entities/bankAccounts';

import BankAccountStatus from '../../Connectors/BankAccountStatus';

const BankAccountOption = ({ option }: { option: BankAccount }) => {
  const { t } = useTranslation('integration');

  return (
    <div className="flex items-center">
      <img src={bankImage} alt="Bank Account" className="h-8 w-8" />
      <div className="ml-3">
        <div className="flex items-center mb-0.5">
          <h6 className="text-sm font-medium mr-2">{option.accountName}</h6>
          <BankAccountStatus
            statusId={option.bankAccountStatusId}
            status={option.bankAccountStatus}
          />
        </div>
        <p className="text-secondary">
          {option.bankName
            ? t('integration:bank.optionInfo', {
                name: option.bankName,
                number: option.accountNumberLast4,
              })
            : t('integration:bank.optionInfoNumber', {
                number: option.accountNumberLast4,
              })}
        </p>
      </div>
    </div>
  );
};

export default BankAccountOption;
