import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import Button, { ButtonSize } from 'components/Button';
import { ERPProviders } from 'entities/accounting';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

type QBOButtonProps = {
  onClick: () => void;
  className?: string;
  small?: boolean;
};

export const QBOButton: React.FC<QBOButtonProps> = ({
  className,
  onClick,
  small = false,
}) => {
  const { t } = useTranslation();

  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  return (
    <PermissionSection permission={Permissions.erpIntegration} showPopover edit>
      <Button
        type="button"
        onClick={onClick}
        className={className}
        disabled={!erpPermissions.edit}
        size={small ? ButtonSize.small : ButtonSize.large}
        styles={{
          backgroundColor: '#2CA01C',
          color: '#fff',
          ':hover': {
            backgroundColor: '#108000',
          },
        }}
      >
        {t('dashboard:accounting.providers.connectToERP', {
          name: ERPProviders.quickbooks,
        })}
      </Button>
    </PermissionSection>
  );
};
