import classNames from 'classnames';

import { PlusCircleIcon, CloseIcon, InfoCircleIcon } from 'components/icons';
import PermissionSection from 'components/permission/PermissionSection';
import { BusinessProductXrefSchema } from 'services/businessProducts';
import { ProductTypeCodes } from 'entities/progress';
import { getEnumKeyByValue } from 'utils/helpers';
import { Permissions } from 'entities/dashboard';
import Popover from 'components/Popover';

import { getProductPopover } from '../utils';

type ProgressProductProps = {
  item: BusinessProductXrefSchema;
  onClick: (key: BusinessProductXrefSchema) => void;
  inline: boolean;
  permission: boolean;
};

const ProgressProduct: React.FC<ProgressProductProps> = ({
  item,
  onClick,
  inline,
  permission,
}) => {
  const popoverContent = getProductPopover(
    getEnumKeyByValue(ProductTypeCodes, item.productsTypeCode),
  );

  return (
    <div className={classNames(inline ? 'mr-2' : 'w-full', 'mt-3')}>
      <PermissionSection
        permission={item.isActive ? undefined : Permissions.products}
        showPopover={!item.isActive}
        fullWidth
        edit
      >
        <div
          aria-hidden="true"
          className={classNames(
            'border border-gray-200 text-gray-400 px-[10px] py-2 flex items-center justify-between w-full bg-white rounded-md',
            {
              'hover:text-blue-600 hover:bg-gray-50 cursor-pointer':
                !item.isActive,
              'cursor-default': item.isActive,
              'opacity-50 cursor-not-allowed': !item.isActive && !permission,
            },
          )}
          onClick={() => (item.isActive || !permission ? null : onClick(item))}
        >
          <div className="flex items-center">
            <div className="py-px flex-shrink-0">
              {item.isActive ? (
                <img
                  alt={item.productsDescription || ''}
                  src={`/assets/images/product-${getEnumKeyByValue(
                    ProductTypeCodes,
                    item.productsTypeCode,
                  )}.svg`}
                  width="32"
                  height="32"
                />
              ) : (
                <PlusCircleIcon />
              )}
            </div>

            <span
              className={classNames(
                'text-sm leading-5 font-medium mx-2',
                item.isActive ? 'text-gray-800' : 'text-blue-600',
              )}
            >
              {item.productsDescription}
            </span>
          </div>

          <div className="flex items-center">
            {popoverContent && (
              <Popover
                content={popoverContent}
                button={
                  <button className="flex self-center cursor-pointer text-gray-400 flex-shrink-0 p-0.5 rounded-md hover:bg-gray-100 hover:text-gray-500">
                    <InfoCircleIcon className="h-[18px] w-[18px] text-gray-500" />
                  </button>
                }
                stopPropagation
              />
            )}

            {item.isActive &&
              item.productsTypeCode !== ProductTypeCodes.payments && (
                <PermissionSection
                  permission={Permissions.products}
                  showPopover
                  edit
                >
                  <button
                    className="ml-1 cursor-pointer text-gray-400 flex-shrink-0 p-0.5 rounded-md hover:bg-gray-100 hover:text-gray-500"
                    onClick={() => (permission ? onClick(item) : null)}
                    disabled={!permission}
                  >
                    <CloseIcon className="h-4 w-4" />
                  </button>
                </PermissionSection>
              )}
          </div>
        </div>
      </PermissionSection>
    </div>
  );
};

export default ProgressProduct;
