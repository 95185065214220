export const SearchDuotoneIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 20 20"
    width="20"
    height="20"
    fill="currentColor"
  >
    <path
      opacity="0.3"
      d="M11.9101 13.9226C11.5847 13.5972 11.5847 13.0695 11.9101 12.7441C12.2355 12.4186 12.7632 12.4186 13.0886 12.7441L16.4219 16.0774C16.7474 16.4028 16.7474 16.9305 16.4219 17.2559C16.0965 17.5814 15.5689 17.5814 15.2434 17.2559L11.9101 13.9226Z"
      fill="currentColor"
    />
    <path
      d="M9.16732 13.334C11.4685 13.334 13.334 11.4685 13.334 9.16732C13.334 6.86613 11.4685 5.00065 9.16732 5.00065C6.86613 5.00065 5.00065 6.86613 5.00065 9.16732C5.00065 11.4685 6.86613 13.334 9.16732 13.334ZM9.16732 15.0007C5.94566 15.0007 3.33398 12.389 3.33398 9.16732C3.33398 5.94566 5.94566 3.33398 9.16732 3.33398C12.389 3.33398 15.0007 5.94566 15.0007 9.16732C15.0007 12.389 12.389 15.0007 9.16732 15.0007Z"
      fill="currentColor"
    />
  </svg>
);
