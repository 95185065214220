import Radium from 'radium';

export const SendIcon = Radium(
  ({ className, styles }: { className?: string; styles?: any }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6665 10.9739L17.039 10.0014L6.6665 9.02901V4.8113C6.6665 4.75235 6.67901 4.69407 6.7032 4.64032C6.79764 4.43047 7.0443 4.3369 7.25415 4.43133L18.7878 9.62147C18.8806 9.66326 18.955 9.7376 18.9968 9.83045C19.0912 10.0403 18.9976 10.287 18.7878 10.3814L7.25415 15.5715C7.2004 15.5957 7.14212 15.6082 7.08317 15.6082C6.85305 15.6082 6.6665 15.4217 6.6665 15.1916V10.9739Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 13.3333H4.16666C4.6269 13.3333 4.99999 13.7064 4.99999 14.1667C4.99999 14.6269 4.6269 15 4.16666 15H3.33333C2.87309 15 2.5 14.6269 2.5 14.1667C2.5 13.7064 2.87309 13.3333 3.33333 13.3333ZM0.833332 9.16667H4.16666C4.6269 9.16667 4.99999 9.53976 4.99999 10C4.99999 10.4602 4.6269 10.8333 4.16666 10.8333H0.833332C0.373095 10.8333 0 10.4602 0 10C0 9.53976 0.373095 9.16667 0.833332 9.16667ZM3.33333 5H4.16666C4.6269 5 4.99999 5.3731 4.99999 5.83333C4.99999 6.29357 4.6269 6.66667 4.16666 6.66667H3.33333C2.87309 6.66667 2.5 6.29357 2.5 5.83333C2.5 5.3731 2.87309 5 3.33333 5Z"
        fill="#2563EB"
      />
    </svg>
  ),
);
