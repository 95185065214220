import { AxiosRequestConfig } from 'axios';

import api from 'api';

export interface BusinessFinancialSchema {
  acceptOrdersInAdvance: boolean;
  acceptedCardPaymentslast24Months: boolean;
  acceptedTandC: string | null;
  acceptedTandCdate: string | null;
  averageTicketTransaction: number;
  breachDescription: string | null;
  businessSalesVolumeTurnover: number;
  businessStatusesId: number;
  businessesFinancialsBlobReferencesJson: string | null;
  businessesFinancialsCardsAcceptedJson: string | null;
  businessesFinancialsDelayedSalesJson: string | null;
  businessesFinancialsGeographicSalesJson: string | null;
  businessesFinancialsId: number;
  businessesFinancialsYearlyGrowthJson: string | null;
  businessesId: number;
  chargebackHistoryPercentage: number;
  complianceReference: string | null;
  complianceStatusId: number | null;
  complianceUpdateDate: string | null;
  complianceUpdatedBy: string | null;
  createdBy: string | null;
  createdBySp: string | null;
  createdOn: string | null;
  currencyCodesAlphaCode: string | null;
  currencyCodesCurrencyName: string | null;
  currencyCodesCurrencyShortName: string | null;
  currencyCodesCurrencySymbol: string | null;
  currencyCodesExponent: number | null;
  currencyCodesId: number | null;
  currencyCodesNumericCode: number | null;
  currencyCodesNumericCodeConverted: string | null;
  currentPaymentProcessingServiceProviders: boolean;
  customerTermsConditionsPosted: boolean;
  dataBreach: boolean;
  dateOfIncorporation: string | null;
  dateofGoLive: string | null;
  deliveryPeriodFromPurchase: number;
  financialsStatusID: number | null;
  highestTicketTransaction: number;
  industry: string | null;
  isActive: boolean;
  merchantCategoryCodesCategory: string | null;
  merchantCategoryCodesCode: string | null;
  merchantCategoryCodesDescription: string | null;
  merchantCategoryCodesId: number | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  modifiedOn: string | null;
  numberOfEmployes: number | null;
  operatingStatusDescription: string | null;
  operatingStatusId: number | null;
  operatingStatusTypeCode: string | null;
  parentBusinessId: number | null;
  parentCompany: string | null;
  pciCompliantDescription: string | null;
  pciCompliantTypeCode: string | null;
  pcicompliantId: number | null;
  pcidate: string | null;
  percentageGrowth: number | null;
  purchaseProcess: string | null;
  refundHistoryPercentage: number | null;
  regulatedService: boolean;
  serviceProviders: string | null;
  shortBusinessProductLineDescription: string | null;
  signatoryAttestationPersonsId: number | null;
  sourceOfFunds: string | null;
  subscriptionBasedPayments: boolean;
  subscriptionsHowOftenDescription: string | null;
  subscriptionsHowOftenId: number | null;
  subscriptionsHowOftenTypeCode: string | null;
  violationDescription: string | null;
  violationNotice: boolean;
  voidedCheckForAbadda: boolean;
  volumeLast12Months: number | null;
}

export const getAllDataBusinessesFinancialsByBusinessId = async (
  id: number,
) => {
  const result: BusinessFinancialSchema[] = await api.get(
    `BusinessesFinancials/GetAllDataBusinessesFinancialsByBusinessId/${id}`,
  );

  return result.length ? result[0] : null;
};

export const insertAllDataBusinessesFinancials = async (
  payload: Partial<BusinessFinancialSchema>,
) => {
  const id: number = await api.post(
    'BusinessesFinancials/InsertAllDataBusinessesFinancials',
    payload,
  );

  return id;
};

export const updateAllDataBusinessesFinancials = async (
  payload: Partial<BusinessFinancialSchema>,
) => {
  const id: number = await api.put(
    'BusinessesFinancials/UpdateAllDataBusinessesFinancials',
    payload,
  );

  return id;
};

export interface BusinessesFinancialsBlobReferenceSchema {
  businessesFinancialsBlobReferencesId: number;
  isActive: boolean;
  businessesId: number;
  blobReferencesTypesId: number;
  blobGuid: string;
  blobFileSize: string;
  blobFileExtension: string;
  blobFileName: string;
  typeCode: string;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
  id: number;
  blobGUID: string;
}

export const getBusinessesFinancialsBlobReferences = async (id: number) => {
  const results: BusinessesFinancialsBlobReferenceSchema[] = await api.get(
    `BusinessesFinancialsBlobReferences/GetBusinessesFinancialsBlobReferences/${id}`,
  );

  return results.length ? results[0] : null;
};

export const getBusinessesFinancialsBlobReferencesFile = async (
  guid: string,
) => {
  const result: Blob = await api.get(
    `BusinessesFinancialsBlobReferences/GetBusinessesFinancialsBlobReferencesFile/${guid}`,
    {
      responseType: 'blob',
    },
  );

  return result;
};

export const addBusinessesFinancialsBlobReferences = async (
  data: FormData,
  options = {} as AxiosRequestConfig,
) => {
  const id: number = await api.post(
    'BusinessesFinancialsBlobReferences/AddBusinessesFinancialsBlobReferences',
    data,
    options,
  );

  return id;
};

export interface UpdateIsActiveBusinessesFinancialsBlobReferencePayload {
  businessesFinancialsBlobReferencesID: number;
  modifiedBy: string;
  isActive: boolean;
}

export const updateIsActiveBusinessesFinancialsBlobReferences = async (
  payload: UpdateIsActiveBusinessesFinancialsBlobReferencePayload,
) => {
  const id: number = await api.put(
    'BusinessesFinancialsBlobReferences/UpdateIsActiveBusinessesFinancialsBlobReferences',
    payload,
  );

  return id;
};

export const searchBlobReferences = async (
  payload: Partial<BusinessesFinancialsBlobReferenceSchema>,
) => {
  const results: BusinessesFinancialsBlobReferenceSchema[] = await api.post(
    'BusinessesFinancialsBlobReferences/SearchBusinessesFinancialsBlobReferences',
    payload,
  );

  return results;
};
