import { useTranslation } from 'react-i18next';

import { InfoCircleSolidIcon } from 'components/icons';
import PopoverOnHover from 'components/PopoverOnHover';
import { useAppDispatch } from 'hooks';
import Link from 'components/Link';

import {
  setIsBankAccountOpen,
  setIsFundingAccountOpen,
  setBackToBankAccount,
} from '../bankAccountsSlice';

const FundingPopoverLink = ({ bankAccountId }: { bankAccountId?: number }) => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setIsBankAccountOpen(false));
    dispatch(setIsFundingAccountOpen(true));

    if (bankAccountId) {
      dispatch(setBackToBankAccount(bankAccountId));
    }
  };

  return (
    <PopoverOnHover
      button={
        <Link className="text-sm font-semibold" onClick={handleClick}>
          {t('funding.setUpLink')}
        </Link>
      }
      infoClassName="z-10 p-4 bg-gray-900 shadow-lg rounded-lg text-gray-300 text-sm max-w-[376px]"
      info={
        <div className="flex">
          <div>
            <InfoCircleSolidIcon className="text-blue-500" />
          </div>
          <div className="pl-2">
            <p className="text-gray-50 font-medium mb-1">
              {t('funding.helpTitle')}
            </p>
            <p>{t('funding.helpDescription')}</p>
          </div>
        </div>
      }
    />
  );
};

export default FundingPopoverLink;
