import api, { APIResponseIdSchema } from 'api';

export interface AccountsSchema {
  id: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  isActive: boolean;
  referenceId: string;
  accounts_TypesId: number;
  businessesId: number;
}

export interface FundingAccountSchema {
  countryCallingCode: string;
  emailAddress: string;
  id: number;
  note: string | null;
  requestSentOn: string | null;
  telephoneNumber: string;
  referenceId: string;
}

export interface AddFundingAccountPayload {
  bank: {
    createdBy: string;
    emailAddressesId: number;
    telephoneNumbersId: number;
    note: string;
  };
}

export interface UpdateFundingAccountPayload {
  bank: {
    modifiedBy: string;
    emailAddressesId: number;
    telephoneNumbersId: number;
    note: string;
  };
}

export const addFundingAccount = async (
  referenceId: string,
  data: AddFundingAccountPayload,
) => {
  const response: APIResponseIdSchema = await api.post(
    `Accounts/${referenceId}/Funding`,
    data,
  );
  return response.id;
};

export const updateFundingAccount = async (
  referenceId: string,
  id: number,
  data: any,
) => {
  const response: APIResponseIdSchema = await api.put(
    `Accounts/${referenceId}/Funding/${id}`,
    data,
  );
  return response.id;
};

export const getFundingAccount = async (referenceId: string) => {
  const response: FundingAccountSchema = await api.get(
    `Accounts/${referenceId}/Funding`,
  );
  return response;
};
