import Readium from 'radium';

export const FinanceIcon = Readium(
  ({ className, styles }: { className?: string; styles?: any }) => (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30 29.5859V28.0859C30 27.1859 29.4 26.5859 28.5 26.5859H7.5C6.6 26.5859 6 27.1859 6 28.0859V29.5859H4.5C3.6 29.5859 3 30.1859 3 31.0859V32.5859H33V31.0859C33 30.1859 32.4 29.5859 31.5 29.5859H30Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M33 10.0875V11.5875C33 12.4875 32.4 13.0875 31.5 13.0875H27C27.9 13.0875 28.5 13.6875 28.5 14.5875C28.5 15.4875 27.9 16.0875 27 16.0875V23.5875C27.9 23.5875 28.5 24.1875 28.5 25.0875V26.5875H22.5V25.0875C22.5 24.1875 23.1 23.5875 24 23.5875V16.0875C23.1 16.0875 22.5 15.4875 22.5 14.5875C22.5 13.6875 23.1 13.0875 24 13.0875H19.5C20.4 13.0875 21 13.6875 21 14.5875C21 15.4875 20.4 16.0875 19.5 16.0875V23.5875C20.4 23.5875 21 24.1875 21 25.0875V26.5875H15V25.0875C15 24.1875 15.6 23.5875 16.5 23.5875V16.0875C15.6 16.0875 15 15.4875 15 14.5875C15 13.6875 15.6 13.0875 16.5 13.0875H12C12.9 13.0875 13.5 13.6875 13.5 14.5875C13.5 15.4875 12.9 16.0875 12 16.0875V23.5875C12.9 23.5875 13.5 24.1875 13.5 25.0875V26.5875H7.5V25.0875C7.5 24.1875 8.1 23.5875 9 23.5875V16.0875C8.1 16.0875 7.5 15.4875 7.5 14.5875C7.5 13.6875 8.1 13.0875 9 13.0875H4.5C3.6 13.0875 3 12.4875 3 11.5875V10.0875L16.5 3.3375C17.4 2.8875 18.6 2.8875 19.65 3.3375L33 10.0875ZM18 5.5875C16.8 5.5875 15.75 6.6375 15.75 7.8375C15.75 9.0375 16.8 10.0875 18 10.0875C19.2 10.0875 20.25 9.0375 20.25 7.8375C20.25 6.6375 19.2 5.5875 18 5.5875Z"
        fill="currentColor"
      />
    </svg>
  ),
);
