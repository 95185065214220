import PaymentsPopover from './components/PaymentsPopover';
import ServicesPopover from './components/ServicesPopover';
import SCFPopover from './components/SCFPopover';

const popoverContents = {
  payments: <PaymentsPopover />,
  services: <ServicesPopover />,
  scf: <SCFPopover />,
};

export const updateProductById = (prods, fields) => {
  const products = prods.slice();

  const productIndex = products.findIndex(
    (product) =>
      product.businessesProductsXrefId === fields.businessesProductsXrefId,
  );

  products[productIndex] = {
    ...products[productIndex],
    ...fields,
  };

  return products;
};

export const getProductPopover = (type) => {
  return popoverContents[type];
};
