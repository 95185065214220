import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { hasPermission } from 'pages/dashboard/selectors';
import { getBusiness } from 'pages/Profile/selectors';
import AccountingStatus from './AccountingStatus';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

interface AccountProps {
  image?: ReactNode;
  email?: string;
  status?: string;
  actions?: ReactNode;
  onUpdate?: () => void;
}

const Account: FC<AccountProps> = ({
  image,
  email,
  status,
  actions,
  onUpdate,
}) => {
  const { businessLegalName } = useAppSelector(getBusiness);
  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  const handleOnClick = () => {
    if (onUpdate && erpPermissions.edit) {
      onUpdate();
    }
  };

  return (
    <div
      className={classNames(
        'flex justify-between items-center mb-4 p-4 rounded-10 w-full',
        onUpdate && 'cursor-pointer',
        actions ? 'bg-gray-50' : 'bg-gray-100',
      )}
      onClick={handleOnClick}
      aria-hidden="true"
    >
      <div className="flex items-center">
        {image}

        <div className="ml-4 text-left">
          <div className="flex items-start">
            <p className="leading-6 font-semibold text-gray-900 mb-0.5 flex mr-2">
              {businessLegalName}
            </p>
            {status && <AccountingStatus status={status} />}
          </div>
          <p className="text-secondary">{email}</p>
        </div>
      </div>
      {actions}
    </div>
  );
};

export default Account;
