import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import { BlobFileTypes, DashboardState, Permissions } from 'entities/dashboard';
import * as businessOwnerPersonService from 'services/businessOwners';
import { hasPermission } from 'pages/dashboard/selectors';
import { OwnershipState } from 'entities/ownership';
import { getBusinessesId } from 'utils/authService';
import { ProfileState } from 'entities/profile';
import { getUploadOptions } from 'utils/files';
import { buildFormData } from 'utils/helpers';
import { RootState } from 'state/store';

import { setLoadingProgress } from './ownershipSlice';
import {
  getMappedCreateOwnerPersonFields,
  getMappedUpdateOwnerPersonFields,
  getMappedCreateOwnerBusinessFields,
  getMappedUpdateOwnerBusinessFields,
  getMappedOwners,
  getMappedOwner,
  getMappedCreateBlobReferenceFields,
  calcOwnershipPercentage,
} from './utils';

export const checkOwnershipProgress = createAsyncThunk(
  'financial/checkOwnershipProgress',
  async (data: any, thunk) => {
    try {
      const { getState } = thunk;
      const { profile } = getState() as { profile: ProfileState };
      const { singleOwner25Percent } = profile.business;

      return calcOwnershipPercentage(data, singleOwner25Percent);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchOwners = createAsyncThunk(
  'ownership/fetchOwners',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewOwnershipPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.ownership,
      );

      if (!viewOwnershipPermission) {
        return {
          owners: [],
          blobReferences: [],
        };
      }

      const { getState, dispatch } = thunkAPI;
      const { dashboard } = getState() as { dashboard: DashboardState };

      const businessesId = getBusinessesId();

      const ownersPersons =
        await businessOwnerPersonService.searchBusinessesOwnersPersons({
          isActive: true,
          businessesId,
        });

      const ownersBusinesses =
        await businessOwnerPersonService.searchBusinessesOwnersBusinesses({
          isActive: true,
          businessesId,
        });

      const blobReferences =
        await businessOwnerPersonService.searchBusinessesOwnersBlobReferences({
          isActive: true,
          businessesId,
          blobReferencesTypesId:
            dashboard.dropdowns.mappedBlobReferencesTypes[
              BlobFileTypes.organization
            ],
        });

      const ownersData = getMappedOwners([
        ...ownersPersons,
        ...ownersBusinesses,
      ]);

      dispatch(
        checkOwnershipProgress({
          owners: ownersData,
          hasFile: blobReferences.length,
        }),
      );

      return {
        owners: ownersData,
        blobReferences: blobReferences,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createOwnerPerson = createAsyncThunk(
  'ownership/createOwnerPerson',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedCreateOwnerPersonFields({
        data,
        person,
        business,
      });

      const formData = buildFormData(fields);

      const personId =
        await businessOwnerPersonService.addBusinessesOwnersPersons(formData);

      if (personId) {
        const newPerson =
          await businessOwnerPersonService.getBusinessesOwnersPersons(personId);

        if (newPerson) {
          const newOwner = getMappedOwner(newPerson);

          owners.push(newOwner);

          dispatch(
            checkOwnershipProgress({
              owners: owners,
              hasFile: ownership.blobReferences.length,
            }),
          );
        }

        return owners.filter((o) => o.isActive);
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createOwnerBusiness = createAsyncThunk(
  'ownership/createOwnerBusiness',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedCreateOwnerBusinessFields({
        data,
        person,
        business,
      });

      const businessId =
        await businessOwnerPersonService.addBusinessesOwnersBusinesses(fields);

      if (businessId) {
        const newBusiness =
          await businessOwnerPersonService.getBusinessesOwnersBusinesses(
            businessId,
          );

        if (newBusiness) {
          const newOwner = getMappedOwner(newBusiness);

          owners.push(newOwner);

          dispatch(
            checkOwnershipProgress({
              owners: owners,
              hasFile: ownership.blobReferences.length,
            }),
          );
        }

        return owners.filter((o) => o.isActive);
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateOwnerPerson = createAsyncThunk(
  'ownership/updateOwnerPerson',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedUpdateOwnerPersonFields({
        data,
        person,
        business,
      });

      if (fields.frontBlobFile === null && fields.idfrontGuid) {
        await businessOwnerPersonService.updateBusinessesOwnersPersonsBlobReference(
          {
            businessesOwnersPersonsId: fields.businessesOwnersPersonsId,
            modifiedBy: fields.modifiedBy,
            idFrontGuid: fields.idfrontGuid,
          },
        );
        fields.idfrontGuid = null;
      }

      const formData = buildFormData(fields);

      const ownerId =
        await businessOwnerPersonService.updateBusinessesOwnersPersons(
          formData,
        );

      if (ownerId) {
        const updatedPerson =
          await businessOwnerPersonService.getBusinessesOwnersPersons(ownerId);

        if (updatedPerson) {
          const index = owners.findIndex(
            (owner: any) =>
              owner.businessesOwnersPersonsId ===
              data.businessesOwnersPersonsId,
          );

          owners[index] = getMappedOwner(updatedPerson);

          dispatch(
            checkOwnershipProgress({
              owners: owners,
              hasFile: ownership.blobReferences.length,
            }),
          );
        }
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateOwnerBusiness = createAsyncThunk(
  'ownership/updateOwnerBusiness',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedUpdateOwnerBusinessFields({
        data,
        person,
        business,
      });

      const ownerId =
        await businessOwnerPersonService.updateBusinessesOwnersBusinesses(
          fields,
        );

      if (ownerId) {
        const updatedBusiness =
          await businessOwnerPersonService.getBusinessesOwnersBusinesses(
            ownerId,
          );

        if (updatedBusiness) {
          const index = owners.findIndex(
            (owner: any) =>
              owner.businessesOwnersBusinessesId ===
              data.businessesOwnersBusinessesId,
          );

          owners[index] = getMappedOwner(updatedBusiness);

          dispatch(
            checkOwnershipProgress({
              owners: owners,
              hasFile: ownership.blobReferences.length,
            }),
          );
        }
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteOwnerBusiness = createAsyncThunk(
  'ownership/deleteOwnerBusiness',
  async (id: number, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person } = profile;
      const owners = ownership.owners.slice();

      await businessOwnerPersonService.updateIsActiveBusinessesOwnersBusinesses(
        {
          isActive: false,
          modifiedBy: person.emailAddress,
          businessesOwnersBusinessesId: id,
        },
      );

      const result = owners.filter(
        (owner: any) => owner.businessesOwnersBusinessesId !== id,
      );

      dispatch(
        checkOwnershipProgress({
          owners: result,
          hasFile: ownership.blobReferences.length,
        }),
      );

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteOwnerPerson = createAsyncThunk(
  'ownership/deleteOwnerPerson',
  async (id: number, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person } = profile;
      const owners = ownership.owners.slice();

      await businessOwnerPersonService.updateIsActiveBusinessesOwnersPersons({
        isActive: false,
        modifiedBy: person.emailAddress,
        businessesOwnersPersonsId: id,
      });

      const result = owners.filter(
        (owner: any) => owner.businessesOwnersPersonsId !== id,
      );

      dispatch(
        checkOwnershipProgress({
          owners: result,
          hasFile: ownership.blobReferences.length,
        }),
      );

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteOwnerBlobReference = createAsyncThunk(
  'ownership/deleteOwnerBlobReference',
  async (id: number, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person } = profile;
      const blobReferences = ownership.blobReferences.slice();

      await businessOwnerPersonService.updateIsActiveBusinessesOwnersBlobReference(
        {
          businessesOwnersBlobReferencesID: id,
          isActive: false,
          modifiedBy: person.emailAddress,
        },
      );

      const references = blobReferences.filter(
        (blob) => blob.businessesOwnersBlobReferencesId !== id,
      );

      dispatch(
        checkOwnershipProgress({
          owners: ownership.owners.slice(),
          hasFile: references.length,
        }),
      );

      return references;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadOwnerBlobReference = createAsyncThunk(
  'businessInfo/downloadOwnerBlobReference',
  async ({ guid, fileName }: any) => {
    try {
      const file =
        await businessOwnerPersonService.getBusinessesOwnersBlobReferenceFile(
          guid,
        );

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadPersonFile = createAsyncThunk(
  'businessInfo/downloadPersonFile',
  async ({ guid, fileName }: any) => {
    try {
      const file =
        await businessOwnerPersonService.getBusinessesOwnersPersonsFile(guid);

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createOwnerBlobReference = createAsyncThunk(
  'ownership/createOwnerBlobReference',
  async ({ files, typeCode }: any, thunk) => {
    const { getState, dispatch } = thunk;
    try {
      const { profile, ownership, dashboard } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
        dashboard: DashboardState;
      };
      const { person, business } = profile;
      const { mappedBlobReferencesTypes } = dashboard.dropdowns;

      const blobReferences = ownership.blobReferences.slice();

      const fields = getMappedCreateBlobReferenceFields({
        file: files[0],
        person,
        business,
        blobReferencesTypesId: mappedBlobReferencesTypes[typeCode],
      });

      dispatch(setLoadingProgress(1));

      const options = getUploadOptions((value) =>
        dispatch(setLoadingProgress(value)),
      );

      const blobId =
        await businessOwnerPersonService.addBusinessesOwnersBlobReference(
          fields,
          options,
        );

      if (blobId) {
        dispatch(setLoadingProgress(100));
        setTimeout(() => {
          dispatch(setLoadingProgress(0));
        }, 2000);

        const blob =
          await businessOwnerPersonService.getBusinessesOwnersBlobReference(
            blobId,
          );

        if (blob) {
          blobReferences.push(blob);
        }

        dispatch(
          checkOwnershipProgress({
            owners: ownership.owners.slice(),
            hasFile: blobReferences.length,
          }),
        );
      }

      return blobReferences;
    } catch (e) {
      dispatch(setLoadingProgress(0));
      return Promise.reject(e);
    }
  },
);
