import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { DoneCircle } from 'components/icons/duotone';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

import RequestProviderModal from './RequestProviderModal';

const RequestProviderButton = () => {
  const { t } = useTranslation('integration');

  const [opened, setOpened] = useState<boolean>(false);
  const [providerRequested, setProviderRequested] = useState<boolean>(false);

  const fxPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.fxProvider),
  );

  const handleClick = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const handleSuccess = () => {
    setProviderRequested(true);
    handleClose();
  };

  return null;

  return (
    <div className="flex items-center justify-center px-12 py-3">
      {providerRequested ? (
        <div className="text-center">
          <DoneCircle className="text-blue-600 mx-auto" />
          <p className="mt-3 text-secondary">
            {t('fxProvider.request.submitted')}
          </p>
        </div>
      ) : (
        <PermissionSection permission={Permissions.fxProvider} edit showPopover>
          <Button
            variant={ButtonVariant.link}
            size={ButtonSize.medium}
            paddingClass="px-3"
            heightClass="h-8"
            onClick={handleClick}
            disabled={!fxPermissions.edit}
          >
            <span className="font-semibold">
              {t('fxProvider.providerLink')}
            </span>
          </Button>
        </PermissionSection>
      )}

      {opened && (
        <RequestProviderModal onClose={handleClose} onSuccess={handleSuccess} />
      )}
    </div>
  );
};

export default RequestProviderButton;
