import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { components, MultiValueRemoveProps } from 'react-select';

import Button from 'components/Button';
import { CloseIcon } from 'components/icons/CloseIcon';

type ValueProps = {
  expense: string;
};

interface ExpensesModalFormProps {
  onClose: () => void;
  onSave: (value: string[]) => void;
  values: ValueProps[];
}

const MultiValueRemove = (props: MultiValueRemoveProps<any>) => (
  <components.MultiValueRemove {...props}>
    <CloseIcon className="w-4 h-4 text-gray-400 mt-[3px] ml-1" />
  </components.MultiValueRemove>
);

const ExpensesModalForm: React.FC<ExpensesModalFormProps> = ({
  onClose,
  onSave,
  values,
}) => {
  const { t } = useTranslation('dashboard');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (values.length) {
      setTags(values.map((val) => val.expense));
    }
  }, [values]);

  const handleChange = (items) => {
    if (items.length <= 5) {
      setTags(items.map((item) => item.label));
    }
  };

  const handleSubmit = () => {
    onSave(tags);
  };

  return (
    <div className="px-6 pb-6 pt-4">
      <form action="" onSubmit={handleSubmit}>
        <div className="mb-4">
          <CreatableSelect
            value={tags.map((tag) => ({
              label: tag,
              value: tag,
            }))}
            onChange={handleChange}
            components={{ MultiValueRemove }}
            placeholder={t('dashboard:businessPlan.buttons.addAnotherExpense')}
            styles={{
              indicatorsContainer: () => ({
                display: 'none',
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#f9fafb',
              }),
              multiValueRemove: (base) => ({
                ...base,
                ':hover': {
                  backgroundColor: 'transparent',
                },
              }),
            }}
            isMulti
          />
        </div>
        <div className="flex">
          <Button
            onClick={onClose}
            size="medium"
            className="w-full border-gray-300 border-[1px] bg-white mr-2 "
            variant="secondary"
          >
            {t('dashboard:businessPlan.buttons.cancel')}
          </Button>
          <Button size="medium" className="w-full ml-2" type="submit">
            {t('dashboard:businessPlan.buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ExpensesModalForm;
