import { createAsyncThunk } from '@reduxjs/toolkit';

import personsServices, { USER_WITHOUT_OID } from 'services/persons';
import { notify } from 'components/notification/notificationSlice';
import { getSubDomain } from 'utils/domainService';
import { LoginFormValues } from 'entities/login';
import { getTenant } from 'theme/selectors';
import { RootState } from 'state/store';
import { config } from 'config';
import path from 'common/path';
import {
  setBusinessesId,
  setOID,
  setUserEmail,
  logOut,
} from 'utils/authService';

import { getLoginData } from './selectors';

export const fetchBusinessesByPerson = createAsyncThunk(
  'login/fetchBusinessesByEmail',
  async ({ person, navigate }: any) => {
    try {
      const businesses = await personsServices.getPersonBusinesses(
        person.personsId,
      );

      if (businesses.length === 0) {
        navigate(path.profile);
        return null;
      }

      if (businesses.length === 1) {
        if (
          businesses[0].businessesTenantUrlSub &&
          getSubDomain() !== businesses[0].businessesTenantUrlSub
        ) {
          logOut();
          window.location.href = `${config.PROTOCOL}${businesses[0].businessesTenantUrlSub}/login`;
          return null;
        }

        setBusinessesId(businesses[0].businessesId);
        navigate(path.dashboard);
        return null;
      }

      return businesses;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const verifyPerson: any = createAsyncThunk(
  'login/verifyPerson',
  async (data: LoginFormValues, thunkAPI) => {
    try {
      let result: any = null;

      if (data.email) {
        const tenant = getTenant(thunkAPI.getState() as RootState);

        result = await personsServices.verifyEmail({
          emailAddress: data.email,
          sendCode: data.sendCode || false,
          tenantId: tenant.tenantsId,
        });

        if (result === USER_WITHOUT_OID && data.sendCode) {
          return 'sendCode';
        }
      }

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const resendCode: any = createAsyncThunk(
  'login/resendCode',
  async (_: void, thunkApi) => {
    try {
      const data = getLoginData(thunkApi.getState() as RootState);

      await thunkApi.dispatch(verifyPerson(data));

      thunkApi.dispatch(
        notify({
          message: 'login:code.resendCodeSuccess',
          variant: 'success',
          duration: 7000,
        }),
      );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const verifyCode: any = createAsyncThunk(
  'login/verifyCode',
  async ({ verificationCode, navigate }: any, thunkApi) => {
    try {
      const { email } = getLoginData(thunkApi.getState() as RootState);

      const result: any = await personsServices.verifyUserEnteredEmail({
        emailAddress: email || '',
        verificationCode,
      });

      // account has been locked due to multiple unsuccessful login attempts
      if (result === 0) {
        thunkApi.dispatch(
          notify({
            variant: 'error',
            message: 'login:emailLockedAlert',
          }),
        );
        return false;
      }

      if (result === true || result?.verificationResult) {
        if (result.personsGUID) {
          setOID(result.personsGUID);
          setUserEmail(email || '');
        }

        // code is valid
        navigate(path.businesses);
      } else {
        // code is invalid
        thunkApi.dispatch(
          notify({
            variant: 'error',
            message: 'login:code.validation.valid',
            duration: 7000,
          }),
        );
      }

      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
