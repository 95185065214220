import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddUserGray,
  PlusIcon,
  HomeIcon,
  CartIcon,
  Others,
} from 'components/icons';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BusinessPlan } from 'common/businessPlan';

import HorizontalPipe from './HorizontalPipe';
import CheckboxWithIcon from './CheckboxWithIcon';
import { Tablet, TabletText } from './Tablet';
import { getBusinessPlanItems } from '../selectors';
import { setBusinessPlan } from '../businessPlanSlice';
import CompetitorsModalForm from './CompetitorsModalForm';

interface AboutCompetitorsProps {
  step: number;
}

const AboutCompetitors: React.FC<AboutCompetitorsProps> = ({ step }) => {
  const dispatch = useAppDispatch();
  const {
    bigBusiness,
    mediumBusiness,
    smallBusiness,
    typeInstore,
    typeOnline,
    typeOthers,
    competitorsJSON,
  } = useAppSelector(getBusinessPlanItems);
  const [isOpenOwenerModal, setOwnerModalState] = useState(false);

  const [updateValues, setValues] = useState<any>(null);
  const [updatedIndex, setIndex] = useState<null | number>(null);

  const { t } = useTranslation('dashboard');

  const handleState = (key: string, value: boolean) => {
    dispatch(setBusinessPlan({ key, value }));
  };

  const handleOwnerModal = () => {
    if (isOpenOwenerModal) {
      setValues(null);
      setIndex(null);
    }

    setOwnerModalState(!isOpenOwenerModal);
  };

  const handleEditOwner = (value, index) => {
    setValues(value);
    setIndex(index);
    handleOwnerModal();
  };

  const dispatchNewOwner = (list) => {
    dispatch(
      setBusinessPlan({
        key: BusinessPlan.competitorsJSON,
        value: list,
      }),
    );
  };

  const handleOwnerDelete = (deleteByIndex) => {
    const filter = competitorsJSON.filter(
      (item, index) => index !== deleteByIndex,
    );
    dispatchNewOwner(filter);
  };

  const addNewOwner = (value) => {
    handleOwnerModal();

    if (typeof updatedIndex === 'number') {
      const newArr = competitorsJSON.map((item, index) => {
        if (index !== updatedIndex) {
          return item;
        }
      });

      newArr[updatedIndex] = value;
      dispatchNewOwner(newArr);

      setIndex(null);
      setValues(null);
      return;
    }

    dispatchNewOwner([...competitorsJSON, value]);
  };

  if (step !== 4) {
    return null;
  }

  return (
    <div className="w-full">
      <h1 className="text-2xl leading-8 font-bold">
        {t('dashboard:businessPlan.modal.aboutCompetitors')}
      </h1>
      <HorizontalPipe />
      <div>
        <div>
          <label className="text-sm leading-5 font-medium mb-[10px]">
            {t('dashboard:businessPlan.modal.typeOfCompetitors')}
          </label>
          <label className="text-sm leading-5 font-normal text-zinc-400">
            {t('dashboard:businessPlan.modal.seeAll')}
          </label>
        </div>

        <div className="mt-[10px] flex gap-x-6 flex-wrap gap-y-6">
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.bigBusiness, !bigBusiness)}
            checked={bigBusiness}
            label={t('dashboard:businessPlan.modal.bigBusiness')}
          />
          <CheckboxWithIcon
            onChange={() =>
              handleState(BusinessPlan.mediumBusiness, !mediumBusiness)
            }
            checked={mediumBusiness}
            label={t('dashboard:businessPlan.modal.mediumBusiness')}
          />
          <CheckboxWithIcon
            onChange={() =>
              handleState(BusinessPlan.smallBusiness, !smallBusiness)
            }
            checked={smallBusiness}
            label={t('dashboard:businessPlan.modal.smallBusiness')}
          />
        </div>
        <div className="mt-9">
          <div className="mb-[10px]">
            <label className="text-sm leading-5 font-medium mb-[10px]">
              {t('dashboard:businessPlan.modal.whatTypeOfBusiness')}
            </label>
            <label className="text-sm leading-5 font-normal text-zinc-400">
              {t('dashboard:businessPlan.modal.seeAll')}
            </label>
          </div>
          <div className="flex items-center flex-wrap gap-x-12">
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.typeInstore, !typeInstore)
                }
                checked={typeInstore}
                label={t('dashboard:businessPlan.modal.inStore')}
                icon={<HomeIcon />}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.typeOnline, !typeOnline)
                }
                checked={typeOnline}
                label={t('dashboard:businessPlan.modal.online')}
                icon={<CartIcon />}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.typeOthers, !typeOthers)
                }
                checked={typeOthers}
                icon={<Others />}
                label={t('dashboard:businessPlan.modal.others')}
              />
            </div>
          </div>
        </div>

        <div className="mt-9 flex flex-col">
          <label className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard:businessPlan.modal.keyCompetitors')}
          </label>
          <span className="text-sm leading-5 font-normal mt-1 text-gray-500">
            {t('dashboard:businessPlan.modal.keyRolesDescription')}
          </span>
        </div>
        <HorizontalPipe />
        <div className="flex flex-col">
          <div className="mb-[10px]">
            <label className="text-sm leading-5 font-medium mb-[10px]">
              {t('dashboard:businessPlan.modal.whoCompetotors')}
            </label>
            <label className="text-sm leading-5 font-normal text-zinc-400">
              {t('dashboard:businessPlan.modal.addCompetitors')}
            </label>
          </div>

          <div className="flex gap-x-4 flex-wrap gap-y-4">
            {competitorsJSON.map(({ competitor }, index) => (
              <div key={index}>
                <Tablet
                  onEdit={() => handleEditOwner({ competitor }, index)}
                  onDelete={() => handleOwnerDelete(index)}
                >
                  <TabletText>{competitor}</TabletText>
                </Tablet>
              </div>
            ))}
            <Button variant="link" size="medium" onClick={handleOwnerModal}>
              <PlusIcon className="mr-2" />
              {t('dashboard:businessPlan.buttons.addAnotherCompetitor')}
            </Button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenOwenerModal}
        closeModal={handleOwnerModal}
        maxWidth="50rem"
        title={t(
          `dashboard:businessPlan.buttons.${
            updatedIndex !== null ? 'edit' : 'add'
          }AnotherCompetitor`,
        )}
        icon={<AddUserGray className="w-6 h-6 text-gray-500" />}
        content={{
          maxWidth: '449px',
        }}
        closeTimeoutMS={0}
      >
        <CompetitorsModalForm
          values={updateValues}
          onClose={handleOwnerModal}
          onSave={addNewOwner}
        />
      </Modal>
    </div>
  );
};

export default AboutCompetitors;
