import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';
import { CheckCircleIcon } from 'components/icons';

interface BulletProps {
  step: number;
  currentStep: number;
  check: boolean;
}

const Bullet: React.FC<BulletProps> = ({ step, currentStep, check }) => {
  const { backgroundColor } = useAppSelector(getTheme);

  if (check && currentStep > step) {
    return (
      <div
        style={{
          color:
            currentStep >= step ? backgroundColor.buton.secondery.base : '',
        }}
        className="mr-2.5 last:mr-0"
      >
        <CheckCircleIcon className="" />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor:
          currentStep >= step ? backgroundColor.buton.secondery.base : '',
      }}
      className={classNames('w-2.5 h-2.5 rounded-full mr-4 last:mr-0 ml-1', {
        'ring-5 ring-blue-100': currentStep === step,
        'bg-gray-200': currentStep < step,
      })}
    />
  );
};

interface StepsProps {
  step: number;
  stepsLength: number;
  showLabel?: boolean;
  check?: boolean;
}

const Steps: React.FC<StepsProps> = ({
  step = 1,
  stepsLength = 0,
  showLabel = true,
  check = true,
}) => {
  const { t } = useTranslation();
  const steps = Array.from(Array(stepsLength).keys());

  return (
    <div className="flex items-center">
      {showLabel && (
        <p className="text-sm leading-4 font-medium text-gray-900 mr-7">
          {t('common:steps', { currentStep: step, stepsLength })}
        </p>
      )}
      {steps.map((value) => (
        <Bullet
          step={value + 1}
          currentStep={step}
          key={value + 1}
          check={check}
        />
      ))}
    </div>
  );
};

export default Steps;
