import { createAsyncThunk } from '@reduxjs/toolkit';

import { getSubDomain, setFavicon } from 'utils/domainService';
import { searchTenants, TenantSchema } from 'services/tenants';

import { getTenantFromStorage, setTenantToStorage } from './utils';

export const fetchTenant: any = createAsyncThunk(
  'theme/fetchTenant',
  async () => {
    try {
      const subDomain = getSubDomain();

      let tenant: TenantSchema | null = getTenantFromStorage(subDomain);

      if (!tenant) {
        const tenants = await searchTenants({
          urlsub: subDomain,
          isActive: true,
        });

        tenant = Array.isArray(tenants) && tenants.length ? tenants[0] : null;

        if (tenant) {
          setTenantToStorage(tenant);
        }
      }

      if (tenant?.faviconFileShareUri) {
        setFavicon(tenant.faviconFileShareUri);
      }

      return tenant;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
