import { createSlice } from '@reduxjs/toolkit';

type NotifyVariantTypes = 'success' | 'warning' | 'error' | 'info';

export enum NotifyVariants {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export interface NotificationState {
  message: string;
  variant: NotifyVariantTypes;
  translate: boolean;
  duration: number;
}

export const initialState: NotificationState = {
  message: '',
  variant: 'success',
  translate: true,
  duration: 0,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notify(state, action) {
      return {
        ...initialState,
        ...action.payload,
      };
    },
    closeNotification() {
      return initialState;
    },
  },
});

export const { notify, closeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
