import api from 'api';

export interface CommonDropdownsSchema {
  applicationStatus: string;
  bankAccountStatuses: string;
  blobReferencesTypesJson: string;
  businessIdTypes: string;
  businessStatusesJson: string;
  businessesDocumentsTypes: string;
  businessesFinancialsGeographicSalesTypesJson: string;
  businessesLegalEntityFormTypesJson: string;
  countriesJson: string;
  creditCardNetworksJson: string;
  currencyCodesJson: string;
  demographicTypesJson: string;
  erpAccountStatuses: string;
  geogrphicTypesJson: string;
  industryTypesJson: string;
  merchantCategoryCodesJson: string;
  operatingStatusJson: string;
  pciCompliantJson: string;
  personsDocumentsTypesJson: string;
  productStatusesJson: string;
  productsJson: string;
  salesDelayedPaymentPeriodsJson: string;
  subscriptionsHowOftenJson: string;
}

export const getCommonDropdowns = async () => {
  const results: CommonDropdownsSchema[] = await api.get('Common/GetDropdowns');

  return results.length ? results[0] : null;
};
