import { AxiosRequestConfig } from 'axios';

import api from 'api';

export interface BusinessDocumentSchema {
  businessesDocumentsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  businessesId: number;
  businessesDocumentsTypesId: number;
  idnumber: string;
  description: string | null;
  effectiveDate: string | null;
  expirationDate: string | null;
  issuedBy: string | null;
  documentsBlobId: string | null;
  blobFileSize: string | null;
  blobFileExtension: string | null;
  blobFileName: string | null;
  typecode: string | null;
}

export const searchBusinessesDocuments = async (
  payload: Partial<BusinessDocumentSchema>,
) => {
  const result = await api.post(
    'BusinessesDocuments/SearchBusinessesDocuments',
    payload,
  );
  return result;
};

export const updateBusinessesDocuments = async (payload: FormData) => {
  const docId = await api.put(
    'BusinessesDocuments/UpdateBusinessesDocuments',
    payload,
  );
  return docId;
};

export const deleteBusinessesDocuments = async (
  id: number,
  modifiedBy: string,
) => {
  const docId = await api.delete(
    `BusinessesDocuments/DeleteBusinessesDocuments/${id}/${modifiedBy}`,
  );
  return docId;
};

export const getBusinessesDocuments = async (id: number) => {
  const documents: BusinessDocumentSchema[] = await api.get(
    `BusinessesDocuments/GetBusinessesDocuments/${id}`,
  );
  return documents.length ? documents[0] : null;
};

export const getBusinessesDocumentsFile = async (guid: string) => {
  const file: Blob = await api.get(
    `BusinessesDocuments/GetBusinessesDocumentsFile/${guid}`,
    {
      responseType: 'blob',
    },
  );
  return file;
};

export const addBusinessesDocuments = async (
  payload: FormData,
  options = {} as AxiosRequestConfig,
) => {
  const docId: number = await api.post(
    'BusinessesDocuments/AddBusinessesDocuments',
    payload,
    options,
  );

  return docId;
};
