import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import PopoverOnHover from 'components/PopoverOnHover';
import { useAppSelector, useAppDispatch } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';
import { getPerson } from 'pages/Profile/selectors';
import { Permissions } from 'entities/dashboard';
import { UserAddIcon } from 'components/icons';
import Persons from 'components/Persons';
import Button from 'components/Button';

import { getInvitePersonsData, getPersonsLables } from './selectors';
import InviteModal from './components/InviteModal';
import { searchBusinessesPersons } from './thunks';
import {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
} from './invitePersonsSlice';

const InvitePersons = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const { wasInvited, isInviteSent, isOpen } =
    useAppSelector(getInvitePersonsData);
  const persons = useAppSelector(getPersonsLables);
  const { personsId } = useAppSelector(getPerson);

  useEffect(() => {
    if (personsId) {
      dispatch(searchBusinessesPersons(null));
    }
  }, [personsId, dispatch]);

  const handleModal = () => {
    dispatch(setInviteIsOpen(!isOpen));
  };

  const openModal = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(!isOpen));
  };

  const handleClose = () => {
    dispatch(setInvitedAndWasInviteState());
  };

  const alertTitle = isInviteSent
    ? t('dashboard:iniviteSent')
    : t('dashboard:alreadyInvited');

  return (
    <PermissionSection permission={Permissions.invite}>
      <>
        <InviteModal isOpen={isOpen} onClose={handleModal} />
        <div className="flex items-center divide-x divide-gray-300">
          <div className="flex items-center">
            <Button
              type="button"
              variant="link"
              className="flex-shrink-0 mr-2"
              paddingClass="px-3"
              size="medium-large"
              onClick={openModal}
            >
              {t('dashboard:invitePersons.buttons.invitePeople')}
              <UserAddIcon className="ml-3" />
            </Button>
            <PopoverOnHover info={t('dashboard:inviteInfo')} className="mr-4" />
          </div>

          <div className="pl-4">
            <Persons persons={persons} />
          </div>
        </div>
        <div
          className="absolute left-[50%] top-[50px] w-[418px] z-50"
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          <Alert
            onClose={handleClose}
            isShow={wasInvited || isInviteSent}
            className="w-[418px]"
            title={alertTitle}
            type={isInviteSent ? AlertTypes.info : AlertTypes.warning}
            withClose
          />
        </div>
      </>
    </PermissionSection>
  );
};

export default InvitePersons;
