import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { CheckCircleIcon, CheckIcon } from 'components/icons';
import LayoutSignUp from 'components/layouts/LayoutSignUp';
import PoweredBy from 'components/layouts/PoweredBy';
import { enumHasValue } from 'utils/helpers';
import { Languages } from 'config';
import Link from 'components/Link';
import path from 'common/path';

const Title = ({ children, mb = 6 }) => (
  <h4 className={`heading-xl sm:heading-3xl mb-4 sm:mb-${mb}`}>{children}</h4>
);

const SubTitle = ({ children }) => (
  <h5 className="text-18 sm:text-[24px] mb-4 sm:mb-6 text-gray-900 font-bold">
    {children}
  </h5>
);

const Typography = ({ children, last = false }) => (
  <p
    className={`${
      last ? 'mb-6 sm:mb-12' : 'mb-3 sm:mb-6'
    } text-gray-700 text-sm sm:text-base`}
  >
    {children}
  </p>
);

const ListItem = ({ children }) => (
  <div className="flex items-start py-3 sm:py-6">
    <CheckIcon className="text-green-500 flex-shrink-0 w-6 h-6 mt-[2px]" />
    <div className="ml-3 text-gray-500 text-sm sm:text-base">{children}</div>
  </div>
);

const List = ({ children }) => (
  <div className="divide-y divide-gray-200 sm:-my-6">{children}</div>
);

const ListWrapper = ({ title, text, children }) => (
  <div className="flex flex-wrap -mx-6 my-8 sm:my-24">
    <div className="max-w-[424px] mx-6">
      {title && <Title mb={4}>{title}</Title>}
      <p className="text-[14px] sm:text-[18px] text-gray-500 whitespace-pre-wrap">
        {text}
      </p>
    </div>
    <div className="flex-1 mx-6">{children}</div>
  </div>
);

const PrivacyPolicy = () => {
  const { t } = useTranslation('privacy-policy');
  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && !enumHasValue(Languages, lang.toLowerCase())) {
      navigate(`${path.privacy}/${Languages.EN.toUpperCase()}`);
    }
  }, [lang, navigate]);

  return (
    <LayoutSignUp fullWidth>
      <div className="max-w-[1096px] m-auto">
        <div className="flex mt-3 mb-3 sm:mb-5">
          <div className="flex items-center rounded-full bg-gray-50 text-xs sm:text-sm p-[5px]">
            <CheckCircleIcon className="mr-2 sm:mr-[14px] text-green-500" />
            <span className="text-gray-500">{t('lastUpdated')}</span>&nbsp;
            <span className="font-medium text-gray-900">{t('date')}</span>
          </div>
        </div>

        <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-6 sm:mb-12">
          <span className="text-blue-600">Transcard</span> {t('title')}
        </h2>

        <div className="mb-6 sm:mb-12">
          <Title>{t('overview')}</Title>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="overview-p-1"
              components={{ 1: <span className="font-semibold" /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="overview-p-2"
              components={{
                1: (
                  <Link
                    href="https://www.transcard.com/"
                    target="_blank"
                    className="font-medium"
                  />
                ),
                2: (
                  <Link
                    href="https://smart-enroll.com/"
                    target="_blank"
                    className="font-medium"
                  />
                ),
                3: (
                  <Link
                    href="https://smart-hub.com/"
                    target="_blank"
                    className="font-medium"
                  />
                ),
                4: <span className="font-semibold" />,
                5: (
                  <Link
                    href={path.terms}
                    target="_blank"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>
          <Typography>{t('overview-p-3')}</Typography>
          <Typography last>
            <Trans
              ns="privacy-policy"
              i18nKey="overview-p-4"
              components={{ 1: <span className="font-semibold" /> }}
            />
          </Typography>

          <Title>{t('collection')}</Title>
          <Typography last>{t('collection-p-1')}</Typography>

          <ListWrapper title={t('collection-t-1')} text={t('collection-p-2')}>
            <List>
              <ListItem>{t('collection-l-1')}</ListItem>
              <ListItem>{t('collection-l-2')}</ListItem>
              <ListItem>{t('collection-l-3')}</ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="collection-l-4"
                  components={{
                    1: (
                      <Link
                        href="https://smart-hub.com/"
                        target="_blank"
                        className="font-medium"
                      />
                    ),
                  }}
                />
              </ListItem>
              <ListItem>{t('collection-l-5')}</ListItem>
              <ListItem>{t('collection-l-6')}</ListItem>
              <ListItem>{t('collection-l-7')}</ListItem>
            </List>
          </ListWrapper>

          <Typography last>{t('collection-p-3')}</Typography>

          <ListWrapper title={t('collection-t-2')} text={t('collection-p-4')}>
            <List>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="collection-l-8"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="collection-l-9"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>{t('collection-l-10')}</ListItem>
              <ListItem>{t('collection-l-11')}</ListItem>
            </List>
          </ListWrapper>
          <Typography last>{t('collection-p-5')}</Typography>

          <ListWrapper title={t('collection-t-3')} text={t('collection-p-6')}>
            <List>
              <ListItem>{t('collection-l-12')}</ListItem>
              <ListItem>{t('collection-l-13')}</ListItem>
              <ListItem>{t('collection-l-14')}</ListItem>
              <ListItem>{t('collection-l-15')}</ListItem>
              <ListItem>{t('collection-l-16')}</ListItem>
            </List>
          </ListWrapper>
          <Typography last>{t('collection-p-7')}</Typography>

          <ListWrapper title={t('use-data-t')} text={t('use-data-p-1')}>
            <List>
              <ListItem>{t('use-data-l-1')}</ListItem>
              <ListItem>{t('use-data-l-2')}</ListItem>
            </List>
          </ListWrapper>

          <ListWrapper title="" text={t('use-data-p-2')}>
            <List>
              <ListItem>{t('use-data-l-3')}</ListItem>
              <ListItem>{t('use-data-l-4')}</ListItem>
              <ListItem>{t('use-data-l-5')}</ListItem>
              <ListItem>{t('use-data-l-6')}</ListItem>
              <ListItem>{t('use-data-l-7')}</ListItem>
              <ListItem>{t('use-data-l-8')}</ListItem>
            </List>
          </ListWrapper>

          <ListWrapper title="" text={t('use-data-p-3')}>
            <List>
              <ListItem>{t('use-data-l-9')}</ListItem>
              <ListItem>{t('use-data-l-10')}</ListItem>
              <ListItem>{t('use-data-l-11')}</ListItem>
            </List>
          </ListWrapper>

          <Typography>{t('use-data-p-4')}</Typography>
          <Typography>{t('use-data-p-5')}</Typography>
          <Typography last>{t('use-data-p-6')}</Typography>

          <ListWrapper title={t('disclosure-t')} text={t('disclosure-p-1')}>
            <List>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-1"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-2"
                  components={{ 1: <span className="font-semibold" /> }}
                />
                <ul className="list-disc pl-8 sm:pl-12">
                  <li className="mt-9 mb-4 pl-2">{t('disclosure-l-2-1')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-2')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-3')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-4')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-5')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-6')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-7')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-2-8')}</li>
                  <li className="pl-2">{t('disclosure-l-2-9')}</li>
                </ul>
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-3"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-4"
                  components={{
                    1: <span className="font-semibold" />,
                    2: (
                      <Link
                        href={path.terms}
                        target="_blank"
                        className="font-medium"
                      />
                    ),
                  }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-5"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-6"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-7"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-8"
                  components={{ 1: <span className="font-semibold" /> }}
                />
                <ul className="list-disc pl-8 sm:pl-12">
                  <li className="mt-9 mb-4 pl-2">{t('disclosure-l-8-1')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-8-2')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-8-3')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-8-4')}</li>
                  <li className="mb-4 pl-2">{t('disclosure-l-8-5')}</li>
                </ul>
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="disclosure-l-9"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
            </List>
          </ListWrapper>

          <Title>{t('cookies')}</Title>
          <SubTitle>{t('cookies-t-1')}</SubTitle>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="cookies-p-1"
              components={{
                1: (
                  <Link
                    href="https://allaboutcookies.org/"
                    target="_blank"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <SubTitle>{t('cookies-t-2')}</SubTitle>
          <Typography>{t('cookies-p-2')}</Typography>
          <Typography>{t('cookies-p-3')}</Typography>

          <SubTitle>{t('cookies-t-3')}</SubTitle>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="cookies-p-4"
              components={{ 1: <span className="font-semibold" /> }}
            />
          </Typography>
          <Typography>{t('cookies-p-5')}</Typography>

          <SubTitle>{t('cookies-t-4')}</SubTitle>
          <Typography>{t('cookies-p-6')}</Typography>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="cookies-p-7"
              components={{
                1: (
                  <Link
                    href="https://www.google.com/policies/privacy/partners"
                    target="_blank"
                    className="font-medium"
                  />
                ),
                2: (
                  <Link
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <SubTitle>{t('cookies-t-5')}</SubTitle>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="cookies-p-8"
              components={{
                1: <span className="font-semibold" />,
                2: (
                  <Link
                    href="https://www.aboutads.info"
                    target="_blank"
                    className="font-medium"
                  />
                ),
                3: (
                  <Link
                    href="https://www.aboutads.info/choices"
                    target="_blank"
                    className="font-medium"
                  />
                ),
                4: (
                  <Link
                    href="https://www.networkadvertising.org/choices/"
                    target="_blank"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="cookies-p-9"
              components={{
                1: (
                  <Link
                    href="https://www.aboutads.info/appchoices"
                    target="_blank"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>
          <Typography>{t('cookies-p-10')}</Typography>
          <Typography last>
            <Trans
              ns="privacy-policy"
              i18nKey="cookies-p-11"
              components={{ 1: <span className="font-semibold" /> }}
            />
          </Typography>

          <Title>{t('store')}</Title>
          <Typography>{t('store-p-1')}</Typography>
          <Typography last>
            <Trans
              ns="privacy-policy"
              i18nKey="store-p-2"
              components={{
                1: <span className="font-semibold" />,
                2: (
                  <Link
                    href="mailto:privacy@Transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <ListWrapper title={t('store-t-1')} text={t('store-p-3')}>
            <List>
              <ListItem>{t('store-l-1')}</ListItem>
              <ListItem>{t('store-l-2')}</ListItem>
              <ListItem>{t('store-l-3')}</ListItem>
              <ListItem>{t('store-l-4')}</ListItem>
              <ListItem>{t('store-l-5')}</ListItem>
            </List>
          </ListWrapper>
          <Typography last>{t('store-p-4')}</Typography>

          <ListWrapper title={t('rights')} text={t('rights-p-1')}>
            <List>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-1"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-2"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-3"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-4"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-5"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-6"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-7"
                  components={{ 1: <span className="font-semibold" /> }}
                />
              </ListItem>
              <ListItem>
                <Trans
                  ns="privacy-policy"
                  i18nKey="rights-l-8"
                  components={{
                    1: <span className="font-semibold" />,
                    2: (
                      <Link
                        href="mailto:privacy@Transcard.com"
                        className="font-medium"
                      />
                    ),
                  }}
                />
              </ListItem>
            </List>
          </ListWrapper>
          <Typography>{t('rights-p-2')}</Typography>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="rights-p-3"
              components={{
                1: (
                  <Link
                    href="mailto:privacy@Transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>
          <Typography last>{t('rights-p-4')}</Typography>

          <Title>{t('third-party-t')}</Title>
          <Typography last>{t('third-party-p')}</Typography>

          <Title>{t('security-t')}</Title>
          <Typography last>{t('security-p')}</Typography>

          <Title>{t('children-t')}</Title>
          <Typography last>{t('children-p')}</Typography>

          <Title>{t('changes-t')}</Title>
          <Typography last>{t('changes-p')}</Typography>

          <Title>{t('complaints-t')}</Title>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="complaints-p-1"
              components={{
                1: (
                  <Link
                    href="mailto:privacy@Transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>
          <Typography last>{t('complaints-p-2')}</Typography>

          <Title>{t('contact')}</Title>
          <Typography last>
            <Trans
              ns="privacy-policy"
              i18nKey="contact-p-1"
              components={{
                1: (
                  <Link
                    href="mailto:privacy@Transcard.com"
                    className="font-medium"
                  />
                ),
                2: (
                  <Link
                    href="mailto:Alwyn.ChoudryThomas@transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <Title>{t('contact-t-1')}</Title>
          <Title>{t('contact-usa')}</Title>
          <SubTitle>{t('contact-california')}</SubTitle>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="contact-california-p"
              components={{
                1: (
                  <Link
                    href="mailto:privacy@Transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>
          <SubTitle>{t('contact-nevada')}</SubTitle>
          <Typography>
            <Trans
              ns="privacy-policy"
              i18nKey="contact-nevada-p"
              components={{
                1: (
                  <Link
                    href="mailto:privacy@Transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <SubTitle>{t('contact-canada')}</SubTitle>
          <Typography last>{t('contact-canada-p')}</Typography>
        </div>
        <PoweredBy />
      </div>
    </LayoutSignUp>
  );
};

export default PrivacyPolicy;
