import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks';
import Autocomplete from 'components/Autocomplete';
import { Permissions } from 'entities/dashboard';
import {
  getMappedCurrencyCodes,
  hasPermission,
} from 'pages/dashboard/selectors';

import { setCurrentCurrency } from '../financialSlice';
import { getCurrentCurrency } from '../selectors';

const CurrencyCode = ({ setValue }) => {
  const { t } = useTranslation('financial');
  const dispatch = useAppDispatch();

  const currencyCodes = useAppSelector(getMappedCurrencyCodes);
  const currentCurrency = useAppSelector(getCurrentCurrency);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const currencyCode = currencyCodes.find(
    (code: any) => code.alphaCode === currentCurrency,
  );

  const handleChange = ({ alphaCode }) => {
    dispatch(setCurrentCurrency(alphaCode));
  };

  return (
    <Autocomplete
      name="currencyCodesId"
      label={t('financial:fields.currency.label')}
      onChange={handleChange}
      value={currencyCode}
      options={currencyCodes}
      className="w-full mx-4.5"
      setValue={setValue}
      isSearchable
      disabled={!financialPermissions.edit}
    />
  );
};

export default CurrencyCode;
