import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import OnboardingTabHeader from 'pages/dashboard/components/OnboardingTabHeader';
import { getShowErrors } from 'pages/dashboard/components/Progress/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import { getSingleOwner25Percent } from 'pages/Profile/selectors';
import { BlobFileTypes, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import { UploadFile, File } from 'components/uploadFile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateBusiness } from 'pages/Profile/thunks';
import { AddUserCircleIcon } from 'components/icons';
import Alert, { AlertTypes } from 'components/Alert';
import YesNoAnswer from 'components/YesNoAnswer';
import { getTheme } from 'theme/selectors';
import Loading from 'components/Loading';

import { addOwner } from './ownershipSlice';
import Owner from './components/Owner';
import {
  getOwners,
  getIsLoading,
  getBlobReferences,
  getLoadingProgress,
  getTotalShare,
} from './selectors';
import {
  createOwnerBlobReference,
  deleteOwnerBlobReference,
  downloadOwnerBlobReference,
  checkOwnershipProgress,
} from './thunks';

const Ownership = () => {
  const { t } = useTranslation('ownership');
  const dispatch = useAppDispatch();

  const { textColor } = useAppSelector(getTheme);
  const singleOwner25Percent = useAppSelector(getSingleOwner25Percent);
  const owners = useAppSelector(getOwners);
  const totalShare = useAppSelector(getTotalShare);
  const isLoading = useAppSelector(getIsLoading);
  const loadingProgress = useAppSelector(getLoadingProgress);
  const blobReferences = useAppSelector(getBlobReferences);
  const showErrors: any = useAppSelector(getShowErrors);
  const ownershipPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.ownership),
  );

  const [showOwners, setShowOwners] = useState<boolean>(singleOwner25Percent);

  const showError = useMemo(
    () => showErrors.owner && ownershipPermissions.edit,
    [showErrors.owner, ownershipPermissions.edit],
  );

  const handleAddOwner = () => {
    dispatch(addOwner());
  };

  const handleAnswer = useCallback(
    async (value) => {
      setShowOwners(value);

      await dispatch(updateBusiness({ singleOwner25Percent: value }));

      dispatch(
        checkOwnershipProgress({
          owners: [],
          hasFile: blobReferences.length,
        }),
      );
    },
    [dispatch, blobReferences],
  );

  useEffect(() => {
    if (singleOwner25Percent === null) {
      setShowOwners(true);
      handleAnswer(true);
    }
  }, [singleOwner25Percent, handleAnswer]);

  const handleDelete = async (id: number) => {
    await dispatch(deleteOwnerBlobReference(id));
  };

  const handleDownload = async (guid: string, fileName: string) => {
    await dispatch(downloadOwnerBlobReference({ guid, fileName }));
  };

  const handleUpload = async (files: FileList) => {
    if (blobReferences.length) {
      await handleDelete(blobReferences[0].businessesOwnersBlobReferencesId);
    }

    dispatch(
      createOwnerBlobReference({
        files,
        typeCode: BlobFileTypes.organization,
      }),
    );
  };

  return (
    <div className="p-6 relative">
      {isLoading && <Loading zIndex={20} />}

      <PermissionSection permission={Permissions.ownership} showMessage>
        <>
          <div className="mb-5">
            <OnboardingTabHeader
              title={t('ownership:title')}
              subTitle={t('ownership:subTitle')}
            />
          </div>

          <YesNoAnswer
            text={t('ownership:ownersQuestion')}
            value={showOwners}
            className="bg-gray-50 mb-5"
            onChange={handleAnswer}
            disabled={
              (singleOwner25Percent &&
                owners.filter((owner) => owner.isActive).length > 0) ||
              !ownershipPermissions.edit
            }
            permission={Permissions.ownership}
          />

          {showOwners && (
            <>
              {showError &&
                owners.filter((owner) => (owner.ownershipPercentage || 0) >= 20)
                  .length === 0 && (
                  <Alert
                    type={AlertTypes.warning}
                    title={t('ownership:ownerRequiredAlertTitle')}
                    withBorder
                  />
                )}

              {owners.map((owner, index) => (
                <Owner
                  key={`${index}-${
                    owner.businessesOwnersPersonsId ||
                    owner.businessesOwnersBusinessesId ||
                    ''
                  }`}
                  data={owner}
                  index={index}
                  showErrors={showError}
                  permissions={ownershipPermissions}
                />
              ))}
              <PermissionSection
                permission={Permissions.ownership}
                showPopover
                edit
              >
                <div className="flex mt-5">
                  <button
                    style={{
                      color: textColor.primary.base,
                    }}
                    className={classNames(
                      'rounded-full flex items-center border border-gray-300 py-2 pl-2 pr-4 font-medium cursor-pointer hover:bg-gray-50',
                      (totalShare >= 100 || !ownershipPermissions.edit) &&
                        'opacity-50 cursor-not-allowed',
                    )}
                    onClick={handleAddOwner}
                    disabled={totalShare === 100 || !ownershipPermissions.edit}
                  >
                    <AddUserCircleIcon className="text-gray-500 mr-3" />
                    {t('ownership:addOwner')}
                  </button>
                </div>
              </PermissionSection>
            </>
          )}

          <div className="border-b mt-12">
            <p className="heading-lg mb-1">{t('ownership:docTitle')}</p>
            <p className="text-secondary mb-5">{t('ownership:docSubTitle')}</p>
          </div>

          <UploadFile
            title={t('ownership:docOrganizationTitle')}
            text={t('ownership:docOrganizationSubTitle')}
            className={classNames(
              'mt-5',
              showError &&
                blobReferences.length === 0 &&
                'border border-red-600 p-2 rounded-md',
            )}
            onUpload={handleUpload}
            progressPercent={loadingProgress}
            uploadFiles={blobReferences}
            disabled={!ownershipPermissions.edit}
            permission={Permissions.ownership}
          >
            {({ handleSelect }) =>
              blobReferences.map((file) => (
                <div key={file.id}>
                  <File
                    file={file}
                    onDelete={() => handleDelete(file.id)}
                    onDownload={(name) => handleDownload(file.blobGuid, name)}
                    handleSelect={handleSelect}
                    readOnly={!ownershipPermissions.edit}
                    permission={Permissions.ownership}
                  />
                </div>
              ))
            }
          </UploadFile>
          {showError && blobReferences.length === 0 && (
            <ErrorMessage
              error={{
                message: t('ownership:docOrganizationRequired'),
              }}
            />
          )}
        </>
      </PermissionSection>
    </div>
  );
};

export default Ownership;
