import { ReactNode } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/Button';
import { CloseIcon } from 'components/icons/CloseIcon';

const variantStyles = {
  default: {
    width: '80%',
    maxWidth: '50rem',
    height: 'auto',
    maxHeight: 'calc(100vh - 120px)',
  },
  screen: {
    width: 'calc(100vw - 100px)',
    maxWidth: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 100px)',
  },
};

type ModalProps = {
  isOpen: boolean;
  isOverlayDisabled?: boolean;
  closeModal: () => any;
  hideCross?: boolean;
  overflow?: string;
  maxWidth?: string;
  width?: string;
  maxHeight?: string;
  height?: string;
  children: ReactNode;
  title?: string | ReactNode;
  content?: any;
  icon?: ReactNode;
  actions?: ReactNode;
  closeTimeoutMS?: number;
  variant?: 'default' | 'screen';
  zIndex?: number;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  isOverlayDisabled = false,
  closeModal,
  hideCross = false,
  overflow,
  children,
  maxWidth,
  width,
  maxHeight,
  height,
  title,
  icon,
  content = {},
  actions,
  closeTimeoutMS = 200,
  variant = 'default',
  zIndex = 30,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={isOverlayDisabled ? () => {} : closeModal}
      closeTimeoutMS={closeTimeoutMS}
      style={{
        overlay: {
          backgroundColor: 'rgba(17, 24, 39, 0.9)',
          zIndex: zIndex,
        },
        content: {
          border: '0',
          borderRadius: '8px',
          boxShadow:
            '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
          bottom: 'auto',
          left: '50%',
          position: 'fixed',
          padding: 0,
          right: 'auto',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          width: width || variantStyles[variant].width,
          maxWidth: maxWidth || variantStyles[variant].maxWidth,
          height: height || variantStyles[variant].height,
          maxHeight: maxHeight || variantStyles[variant].maxHeight,
          overflow: overflow || 'hidden',
          display: 'flex',
          flexDirection: 'column',
          ...content,
        },
      }}
      contentLabel={typeof title === 'string' ? title : 'Modal content'}
    >
      {!hideCross && (
        <Button
          onClick={closeModal}
          className="absolute top-0 right-0 mt-5 mr-6"
          paddingClass="p-0"
          variant="link"
          heightClass="h-6"
        >
          <CloseIcon className="w-6 h-6 text-gray-400" />
        </Button>
      )}

      {title && (
        <div className="pl-6 pr-15 py-[11px] flex items-center justify-between border-b min-h-[65px]">
          <div className="flex items-center w-full">
            {icon}
            <h6 className={`heading-lg${icon ? ' ml-2.5' : ''} w-full`}>
              {title}
            </h6>
          </div>
        </div>
      )}

      <div className={`flex-1 overflow-${overflow || 'auto'}`}>{children}</div>

      {actions && <div className="px-6 py-5 border-t">{actions}</div>}
    </ReactModal>
  );
};

export default Modal;
