import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import Autocomplete from 'components/Autocomplete';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

import {
  getMappedMerchantCategoryCodes,
  getMerchantCategoryCodesId,
} from '../selectors';

const MerchantCategoryCode = ({ setValue }) => {
  const { t } = useTranslation('financial');

  const [option, setOption] = useState<any>(null);

  const merchantCategoryCodes = useAppSelector(getMappedMerchantCategoryCodes);
  const merchantCategoryCodesId = useAppSelector(getMerchantCategoryCodesId);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  useEffect(() => {
    const merchantCategoryCode = merchantCategoryCodes.find(
      (code: any) => code.value === merchantCategoryCodesId,
    );

    setOption(merchantCategoryCode || null);
  }, [merchantCategoryCodes, merchantCategoryCodesId]);

  const handleChange = (value) => {
    setOption(value);
  };

  return (
    <Autocomplete
      name="merchantCategoryCodesId"
      label={t('financial:fields.merchantCategory.label')}
      placeholder={t('financial:fields.merchantCategory.placeholder')}
      value={option}
      options={merchantCategoryCodes}
      onChange={handleChange}
      className="mt-5 w-full mx-4.5"
      disabled={!financialPermissions.edit}
      setValue={setValue}
      isSearchable
      optional
    />
  );
};

export default MerchantCategoryCode;
