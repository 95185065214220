import { LoaderIcon } from 'components/icons/LoaderIcon';

export default function ModalLoading() {
  return (
    <div className="p-12">
      <LoaderIcon className="mx-auto w-8 h-8" />
      <p className="heading-xl mb-2 mt-10 text-center">Please wait</p>
      <p className="text-secondary text-center">This may take a while</p>
    </div>
  );
}
