import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';

import { getBusinessSignatory } from 'pages/Profile/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { InfoCircleSolidIcon } from 'components/icons';
import { addTandCaccept, addTandCview } from 'pages/Profile/thunks';
import { Checkbox } from 'components/Inputs';
import Link from 'components/Link';
import path from 'common/path';

import { setTermsCheckbox, setPrivacyCheckbox } from '../submitDashboardSlice';
import { getCheckboxes } from '../selectors';

enum LinkTypes {
  terms = 'terms',
  privacy = 'privacy',
}

const TermsAgreement = () => {
  const { t, i18n } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const signatory = useAppSelector(getBusinessSignatory);
  const { termsCheckbox, privacyCheckbox } = useAppSelector(getCheckboxes);

  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState<boolean>(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

  const links = {
    terms: `${path.terms}/${i18n.language.toUpperCase()}`,
    privacy: `${path.privacy}/${i18n.language.toUpperCase()}`,
  };

  const handleReview = (type: LinkTypes) => {
    if (type === LinkTypes.terms && !termsCheckbox.show) {
      dispatch(setTermsCheckbox({ show: true }));
      dispatch(addTandCview(`submit${links.terms}`));
    }

    if (type === LinkTypes.privacy && !privacyCheckbox.show) {
      dispatch(setPrivacyCheckbox({ show: true }));
      dispatch(addTandCview(`submit${links.privacy}`));
    }
  };

  const handleTerms = (value: boolean) => {
    dispatch(setTermsCheckbox({ checked: value }));

    if (value && !isTermsAccepted) {
      dispatch(addTandCaccept(`submit${links.terms}`));
      setIsTermsAccepted(true);
    }
  };

  const handlePrivacy = (value: boolean) => {
    dispatch(setPrivacyCheckbox({ checked: value }));

    if (value && !isPrivacyAccepted) {
      dispatch(addTandCaccept(`submit${links.privacy}`));
      setIsPrivacyAccepted(true);
    }
  };

  return (
    <div className="mt-12">
      <div className="flex items-center pb-3">
        <InfoCircleSolidIcon className="mr-1 mb-0.5 text-gray-400 w-4 h-4" />
        <p className="text-xs text-gray-500">{t('submitModal.termsInfo')}</p>
      </div>
      <div className="w-full sm:w-1/2 max-w-[650px]">
        <label
          className={classNames(
            termsCheckbox.show && 'cursor-pointer',
            !signatory && 'opacity-50 pointer-events-none',
          )}
        >
          <div className="flex items-center justify-between p-[14px] border border-gray-200 rounded-t-lg relative">
            <p className="text-xs font-medium text-gray-700 pr-9">
              <Trans ns="dashboard" i18nKey="submitModal.termsOfUse">
                text
                <Link
                  href={links.terms}
                  target="_blank"
                  className="font-medium"
                  rel="noreferrer"
                  onClick={() => handleReview(LinkTypes.terms)}
                >
                  link
                </Link>
                text
              </Trans>
            </p>
            {termsCheckbox.show ? (
              <Checkbox
                onChange={handleTerms}
                checked={termsCheckbox.checked}
              />
            ) : (
              <Link
                href={links.terms}
                target="_blank"
                className="font-semibold text-xs"
                rel="noreferrer"
                onClick={() => handleReview(LinkTypes.terms)}
              >
                {t('submitModal.review')}
              </Link>
            )}
          </div>
        </label>
        <label
          className={classNames(
            privacyCheckbox.show && 'cursor-pointer',
            !signatory && 'opacity-50 pointer-events-none',
          )}
        >
          <div className="flex items-center justify-between p-[14px] border border-gray-200 border-t-0 rounded-b-lg relative">
            <p className="text-xs font-medium text-gray-700 pr-9">
              <Trans ns="dashboard" i18nKey="submitModal.privacyPolicy">
                text
                <Link
                  href={links.privacy}
                  target="_blank"
                  className="font-medium"
                  rel="noreferrer"
                  onClick={() => handleReview(LinkTypes.privacy)}
                >
                  link
                </Link>
                text
              </Trans>
            </p>
            {privacyCheckbox.show ? (
              <Checkbox
                onChange={handlePrivacy}
                checked={privacyCheckbox.checked}
              />
            ) : (
              <Link
                href={links.privacy}
                target="_blank"
                className="font-semibold text-xs"
                rel="noreferrer"
                onClick={() => handleReview(LinkTypes.privacy)}
              >
                {t('submitModal.review')}
              </Link>
            )}
          </div>
        </label>
      </div>
    </div>
  );
};

export default TermsAgreement;
