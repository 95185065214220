import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { InputProps } from 'entities/inputs';
import { isEmailValid } from 'utils/helpers';

import { Input } from './Input';

interface EmailInputProps extends Omit<InputProps, 'onChange'> {
  setValue?: any;
}

const EmailInput: React.FC<EmailInputProps> = ({
  name,
  label,
  info,
  placeholder,
  register,
  setValue,
  className,
  error,
  optional,
  iconStart,
  iconEnd,
  validation,
  readOnly,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [emailError, setEmailError] = useState<boolean>(false);

  const handleChange = (event) => {
    const val = event.target?.value || '';

    if (val && !isEmailValid(val)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (setValue) {
      setValue(name, val);
    }
  };

  return (
    <Input
      name={name}
      register={register}
      onChange={(val) => handleChange(val)}
      label={label}
      className={className}
      placeholder={placeholder}
      readOnly={readOnly}
      error={
        error ||
        (emailError && { message: t('common:fields.email.validation.valid') })
      }
      {...otherProps}
    />
  );
};

export default EmailInput;
