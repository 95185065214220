import { useTranslation } from 'react-i18next';

import { ExclamationIcon } from 'components/icons';
import Button from 'components/Button';
import Modal from 'components/Modal';

const ErrorModal = ({ serverError, setServerError }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setServerError(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal
      isOpen={serverError}
      closeModal={handleClose}
      maxWidth="522px"
      zIndex={99}
    >
      <div className="pt-20 px-6 pb-6 text-center">
        <ExclamationIcon className="mb-1.5 h-9 w-9 text-yellow-500 mx-auto" />
        <p className="font-bold mb-3">{t('common:errorModal.title')}</p>
        <p className="text-secondary mb-8">{t('common:errorModal.subTitle')}</p>
        <Button className="w-full" onClick={handleRefresh}>
          {t('common:errorModal.button')}
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
