import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { closeDialog } from 'components/dialog/modalSlice';
import { DataSourceWidgets } from 'entities/accounting';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';

import { updateSourceTypes } from '../AccountingSoftware/thunks';
import { getSourceType } from '../AccountingSoftware/selectors';
import {
  setShowWidgets,
  setSourceType,
} from '../AccountingSoftware/accountingSlice';

type DataSourceWidgetModalProps = {
  id: number;
  icon: string;
  title: string;
  description: string | React.ReactElement;
};

const DataSourceWidgetModal: React.FC<DataSourceWidgetModalProps> = ({
  icon,
  title,
  description,
  id,
}) => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataSource: number = useAppSelector(getSourceType);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleSelect = async () => {
    setIsLoading(true);

    const result = await dispatch(updateSourceTypes([id]));

    if (!result.error) {
      dispatch(setSourceType(id));
      if (id === DataSourceWidgets.erp) {
        dispatch(setShowWidgets(false));
      }

      handleClose();
    }

    setIsLoading(false);
  };

  return (
    <div className="p-6 pt-14">
      <img src={icon} alt={t(title)} className="w-8 h-8" />
      <p className="heading-2xl font-medium my-2">{t(title)}</p>
      <div className="text-secondary pb-6">{description}</div>
      <HideComponent hide={id === dataSource}>
        <div className="flex item-center pt-4">
          <Button
            size={ButtonSize.medium}
            className="mr-4"
            onClick={handleSelect}
            loading={isLoading}
          >
            {t('common:continue')}
          </Button>
          <Button
            variant={ButtonVariant.secondaryOutline}
            size={ButtonSize.medium}
            onClick={handleClose}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </HideComponent>
    </div>
  );
};

export default DataSourceWidgetModal;
