import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.notification;

export const getNotification = createSelector(
  [getRoot],
  ({ message, variant, translate, duration }) => ({
    message,
    variant,
    translate,
    duration,
  }),
);
