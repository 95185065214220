import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CloseIcon, PlusIcon } from 'components/icons';
import PopoverOnHover from 'components/PopoverOnHover';
import { PermissionSchema } from 'services/security';
import { Permissions } from 'entities/dashboard';
import Modal from 'components/Modal';
import {
  NotifyVariants,
  notify,
} from 'components/notification/notificationSlice';

import { getPermissions, getPersonPermissions, getErrors } from '../selectors';
import { getPermissionCodeWithoutType } from './utils';
import {
  setPermissionType,
  setPermissions,
  setErrors,
  Person,
  PermissionType,
} from '../invitePersonsSlice';
import { updateMemberPermissions } from '../thunks';

type PermissionModalProps = {
  onClose: () => void;
  type: PermissionType | null;
  person: Person | null;
};

const warningMessages = {
  [Permissions.onboardingBusiness.toLocaleLowerCase()]:
    'invitePersons.permission.view.removeBusinessPermission',
  [Permissions.financialInfo.toLocaleLowerCase()]:
    'invitePersons.permission.view.addFinancialPermission',
  [Permissions.ownership.toLocaleLowerCase()]:
    'invitePersons.permission.view.addOwnershipPermission',
  [Permissions.transactional.toLocaleLowerCase()]:
    'invitePersons.permission.view.addTransactionalPermission',
};

const PermissionModal: React.FC<PermissionModalProps> = ({
  onClose,
  type,
  person,
}) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const personPermissions = useAppSelector(getPersonPermissions);
  const permissions = useAppSelector(getPermissions);
  const errors = useAppSelector(getErrors);

  const [selected, setSelected] =
    useState<PermissionSchema[]>(personPermissions);

  useEffect(() => {
    setSelected(personPermissions);
  }, [personPermissions]);

  const handleClick = (permission: PermissionSchema) => {
    let result = [...selected];
    const code = getPermissionCodeWithoutType(permission);

    if (
      selected.find(
        (p: PermissionSchema) =>
          p.permissions_Code === permission.permissions_Code,
      )
    ) {
      result = result.filter(
        (p: PermissionSchema) =>
          p.permissions_Code !== permission.permissions_Code,
      );

      if (code === Permissions.onboardingBusiness.toLocaleLowerCase()) {
        result = result.filter(
          (p: PermissionSchema) =>
            p.permissions_Code.toLowerCase() !==
              `${Permissions.financialInfo.toLocaleLowerCase()}_${type}` &&
            p.permissions_Code.toLowerCase() !==
              `${Permissions.ownership.toLocaleLowerCase()}_${type}` &&
            p.permissions_Code.toLowerCase() !==
              `${Permissions.transactional.toLocaleLowerCase()}_${type}`,
        );
      }
    } else {
      if (
        (code === Permissions.financialInfo.toLocaleLowerCase() ||
          code === Permissions.ownership.toLocaleLowerCase() ||
          code === Permissions.transactional.toLocaleLowerCase()) &&
        !selected.find(
          (p: PermissionSchema) =>
            p.permissions_Code.toLowerCase().includes(`_${type}`) &&
            getPermissionCodeWithoutType(p) ===
              Permissions.onboardingBusiness.toLocaleLowerCase(),
        )
      ) {
        return;
      }

      result.push(permission);
    }

    setSelected(result);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    if (person) {
      if (
        !selected.filter((p) =>
          p.permissions_Code.toLowerCase().endsWith(`_${type}`),
        ).length
      ) {
        dispatch(
          notify({
            variant: NotifyVariants.error,
            message: t('invitePersons.permissionsError'),
            duration: 3000,
          }),
        );

        return;
      }

      dispatch(
        updateMemberPermissions({
          personsId: person.personsId,
          personPermissions: selected,
          permissionType: type,
        }),
      );
    } else {
      dispatch(setPermissionType(type));
      dispatch(setPermissions(selected));

      const { permissionsRequired, ...other } = errors;

      dispatch(setErrors(other));
    }

    onClose();
  };

  const getWarningMessage = (permission: PermissionSchema) => {
    let message = '';

    const isSelected =
      selected.findIndex(
        (p: PermissionSchema) =>
          p.permissions_Code === permission.permissions_Code,
      ) >= 0;
    const code = getPermissionCodeWithoutType(permission);
    const selectedByType = selected.filter((s) =>
      s.permissions_Code.toLowerCase().includes(`_${type}`),
    );

    if (
      isSelected &&
      code === Permissions.onboardingBusiness.toLocaleLowerCase() &&
      selectedByType.findIndex(
        (p: PermissionSchema) =>
          getPermissionCodeWithoutType(p) ===
            Permissions.financialInfo.toLocaleLowerCase() ||
          getPermissionCodeWithoutType(p) ===
            Permissions.ownership.toLocaleLowerCase() ||
          getPermissionCodeWithoutType(p) ===
            Permissions.transactional.toLocaleLowerCase(),
      ) >= 0
    ) {
      message = t(warningMessages[code]);
    }

    if (!isSelected) {
      if (
        (code === Permissions.financialInfo.toLocaleLowerCase() ||
          code === Permissions.ownership.toLocaleLowerCase() ||
          code === Permissions.transactional.toLocaleLowerCase()) &&
        selectedByType.findIndex(
          (p: PermissionSchema) =>
            getPermissionCodeWithoutType(p) ===
            Permissions.onboardingBusiness.toLocaleLowerCase(),
        ) < 0
      ) {
        message = t(warningMessages[code]);
      }
    }

    return message ? <div>{message}</div> : null;
  };

  return (
    <Modal
      isOpen={true}
      closeModal={handleCancel}
      maxWidth="680px"
      title={
        <div className="py-1">
          <p className="text-xl font-medium mb-1">
            {t(`invitePersons.permission.${type}.title`)}
          </p>
          <p className="text-sm text-gray-500">
            {t(`invitePersons.permission.${type}.description`)}
          </p>
        </div>
      }
      actions={
        <div className="flex items-center justify-end">
          <Button
            variant={ButtonVariant.secondaryOutline}
            size={ButtonSize.medium}
            onClick={handleCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button
            className="ml-6"
            size={ButtonSize.medium}
            onClick={handleConfirm}
          >
            {t('common:confirm')}
          </Button>
        </div>
      }
      closeTimeoutMS={0}
    >
      <div className="p-6 flex flex-wrap">
        {permissions[type || PermissionType.view].map(
          (permission: PermissionSchema) => (
            <PopoverOnHover
              key={permission.security_PermissionsId}
              button={
                <span
                  aria-hidden
                  onClick={() => handleClick(permission)}
                  className={`flex items-center pl-[5px] pr-[10px] py-[2px]${
                    selected.find(
                      (p: PermissionSchema) =>
                        p.permissions_Code === permission.permissions_Code,
                    )
                      ? ' bg-blue-100 text-blue-800'
                      : ' bg-gray-100 text-gray-800'
                  } rounded-xl text-sm font-medium mr-[10px] mb-[10px] flex-shrink-0 cursor-pointer`}
                >
                  {selected.find(
                    (p: PermissionSchema) =>
                      p.permissions_Code === permission.permissions_Code,
                  ) ? (
                    <CloseIcon className="text-blue-600 mr-[2px] w-[14px] h-[14px]" />
                  ) : (
                    <PlusIcon className="text-gray-400 mr-[2px] w-[14px] h-[14px]" />
                  )}
                  <span>{permission.name}</span>
                </span>
              }
              info={
                <div>
                  <span className="font-bold">{permission.description}</span>
                  {getWarningMessage(permission)}
                </div>
              }
              infoClassName="z-10 text-sm font-medium text-gray-700 bg-white px-2 py-1 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md max-w-[95%]"
            />
          ),
        )}
      </div>
    </Modal>
  );
};

export default PermissionModal;
