import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Languages, DEFAULT_LANGUAGE } from 'config';
import { enumHasValue } from 'utils/helpers';

const LanguageSwitcher: React.FC = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const url = window.location.href;
    const languageCode = url.split('/').pop();

    const language =
      languageCode && enumHasValue(Languages, languageCode.toLowerCase())
        ? languageCode.toLowerCase()
        : DEFAULT_LANGUAGE;

    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  return <div>{children}</div>;
};

export default LanguageSwitcher;
