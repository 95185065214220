import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import { getPersonInfo } from 'pages/Profile/selectors';
import PopoverOnHover from 'components/PopoverOnHover';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';
import Person from 'components/Person';
import Badge from 'components/Badge';
import {
  setInviteIsOpen,
  setInvitedAndWasInviteState,
} from 'components/InvitePersons/invitePersonsSlice';
import {
  ShieldCheckIcon,
  AddUserIcon,
  CheckCircleIcon,
} from 'components/icons';

import VirtualDDACheckbox from './VirtualDDACheckbox';

type SignatoryProps = {
  signatory: any;
  loading: boolean;
  onClick: () => void;
  permission?: Permissions | undefined;
};

const AuthorizedSignatory: React.FC<SignatoryProps> = ({
  signatory,
  loading,
  onClick,
  permission,
}) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const person = useAppSelector(getPersonInfo);
  const permissions = useAppSelector((state) =>
    hasPermission(state, permission),
  );

  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    role: '',
  });

  const handleInvite = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(true));
  };

  useEffect(() => {
    setData({
      firstname: signatory ? signatory.personsGivenName1 : person.givenName1,
      lastname: signatory ? signatory.personsSurnameFirst : person.surnameFirst,
      role: signatory
        ? signatory.businessesPersonsRole
        : person.businessesPersonsRole,
    });
  }, [signatory, person]);

  return (
    <div>
      <p className="heading-lg mb-1">{t('dashboard:signatory.title')}</p>
      <p className="text-sm text-gray-400">
        {t('dashboard:signatory.subTitle')}
      </p>
      <VirtualDDACheckbox />
      <div className="mt-6 pt-6 border-t flex">
        <div className="flex items-center">
          <div className="mr-3">
            <Person name={data.firstname?.[0] || ''} index={1} size="large" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{`${data.firstname} ${data.lastname}`}</p>
            <p className="text-sm text-gray-500">{data.role}</p>
          </div>
          <div className="ml-6 flex items-center">
            {signatory ? (
              <Badge color="lightGreen" size="large">
                <CheckCircleIcon className="mr-3 text-green-500" />
                {t('dashboard:signatory.badge')}
              </Badge>
            ) : (
              <>
                <PermissionSection permission={permission} showPopover edit>
                  <Button
                    size="medium"
                    onClick={onClick}
                    loading={loading}
                    disabled={permission && !permissions.edit}
                  >
                    <ShieldCheckIcon className="mr-3" />
                    {t('dashboard:signatory.btnAuthorize')}
                  </Button>
                </PermissionSection>
                <Button
                  size="medium"
                  className="ml-6 mr-1"
                  variant="link"
                  paddingClass="px-3"
                  onClick={handleInvite}
                >
                  {t('dashboard:signatory.btnSend')}
                  <AddUserIcon className="ml-2" />
                </Button>
                <PopoverOnHover info={t('dashboard:inviteInfo')} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizedSignatory;
