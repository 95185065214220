const Typography = ({ children, last = false }) => (
  <p
    className={`${
      last ? 'mb-8 sm:mb-12' : 'mb-4 sm:mb-6'
    } text-gray-700 text-sm sm:text-base`}
  >
    {children}
  </p>
);

export default Typography;
