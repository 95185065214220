import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import OnboardingTabHeader from 'pages/dashboard/components/OnboardingTabHeader';
import { getShowErrors } from 'pages/dashboard/components/Progress/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import { NumberInput, Input, DatePicker } from 'components/Inputs';
import { BlobFileTypes, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import { UploadFile, File } from 'components/uploadFile';
import { useAppDispatch, useAppSelector } from 'hooks';
import YesNoCollapse from 'components/YesNoCollapse';
import Loading from 'components/Loading';

import FinancialCardsAccepted from './components/FinancialCardsAccepted';
import SubscriptionsHowOften from './components/SubscriptionsHowOften';
import MerchantCategoryCode from './components/MerchantCategoryCode';
import MerchantServices from '../MerchantServices/MerchantServices';
import FinancialSignatory from './components/FinancialSignatory';
import OperatingStatus from './components/OperatingStatus';
import GeographicSales from './components/GeographicSales';
import PciCompliant from './components/PciCompliant';
import CurrencyCode from './components/CurrencyCode';
import DelayedSales from './components/DelayedSales';
import YearlyGrowth from './components/YearlyGrowth';
import { setFormUpdate } from './financialSlice';
import { documents } from './utils';
import {
  updateBusinessFinancial,
  createBlobReference,
  deleteBlobReference,
  downloadBlobReference,
} from './thunks';
import {
  getBusinessFinancialInfo,
  getIsFormUpdate,
  getIsLoading,
  getCurrentCurrency,
  getBlobReferences,
  getFileLoading,
} from './selectors';

const Financial = () => {
  const { t } = useTranslation('financial');
  const dispatch = useAppDispatch();
  const isFormUpdate = useAppSelector(getIsFormUpdate);
  const isLoading = useAppSelector(getIsLoading);
  const businessFinancialInfo = useAppSelector(getBusinessFinancialInfo);
  const currentCurrency = useAppSelector(getCurrentCurrency);
  const blobReferences = useAppSelector(getBlobReferences);
  const fileLoading = useAppSelector(getFileLoading);
  const showErrors: any = useAppSelector(getShowErrors);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const showError = useMemo(
    () => showErrors.financial && financialPermissions.edit,
    [showErrors.financial, financialPermissions.edit],
  );

  const licenseDocs = blobReferences.filter(
    (blob) => blob.typeCode === BlobFileTypes.license,
  );

  const statementDocs = blobReferences.filter(
    (blob) => blob.typeCode === BlobFileTypes.bankAccountStatement6,
  );

  const termsDocs = blobReferences.filter(
    (blob) => blob.typeCode === BlobFileTypes.termsAndConditions,
  );

  const { register, getValues, setValue, reset, watch, control } =
    useForm<FieldValues>({
      defaultValues: businessFinancialInfo,
    });

  useEffect(() => {
    if (!isFormUpdate) {
      reset(businessFinancialInfo);
      dispatch(setFormUpdate());
    }
  }, [businessFinancialInfo, isFormUpdate, reset, dispatch]);

  useEffect(() => {
    return () => {
      if (financialPermissions.edit) {
        const data = getValues();
        dispatch(updateBusinessFinancial(data));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswer = (name: string, value: boolean) => {
    setValue(name, value);
  };

  const handleDelete = async (id: number) => {
    await dispatch(deleteBlobReference(id));
  };

  const handleUpload = async (
    files: FileList,
    type: BlobFileTypes,
    remove = false,
  ) => {
    if (remove) {
      const file = blobReferences.find((blob) => blob.typeCode === type);

      if (file) {
        await handleDelete(file.id);
      }
    }

    dispatch(createBlobReference({ files, type }));
  };

  const handleDownload = (guid: string, fileName: string) => {
    dispatch(downloadBlobReference({ guid, fileName }));
  };

  const [
    parentCompany,
    dateOfIncorporation,
    shortBusinessProductLineDescription,
    sourceOfFunds,
    industry,
    numberOfEmployes,
    businessSalesVolumeTurnover,
    breachDescription,
    violationDescription,
    serviceProviders,
    chargebackHistoryPercentage,
    refundHistoryPercentage,
    averageTicketTransaction,
    highestTicketTransaction,
    deliveryPeriodFromPurchase,
    volumeLast12Months,
    purchaseProcess,
  ] = watch([
    'parentCompany',
    'dateOfIncorporation',
    'shortBusinessProductLineDescription',
    'sourceOfFunds',
    'industry',
    'numberOfEmployes',
    'businessSalesVolumeTurnover',
    'breachDescription',
    'violationDescription',
    'serviceProviders',
    'chargebackHistoryPercentage',
    'refundHistoryPercentage',
    'averageTicketTransaction',
    'highestTicketTransaction',
    'deliveryPeriodFromPurchase',
    'volumeLast12Months',
    'purchaseProcess',
  ]);

  if (isLoading) {
    return (
      <div className="p-6 relative h-60">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="p-6">
        <PermissionSection permission={Permissions.financialInfo} showMessage>
          <div>
            <div className="mb-5">
              <OnboardingTabHeader
                title={t('financial:title')}
                subTitle={t('financial:subTitle')}
              />
            </div>

            <div className="flex -mx-4.5">
              <Input
                label={t('financial:fields.company.label')}
                name="parentCompany"
                className="w-full mx-4.5"
                register={register}
                error={
                  showError &&
                  !parentCompany && {
                    message: t('financial:fields.company.validation.required'),
                  }
                }
                readOnly={!financialPermissions.edit}
              />
              <CurrencyCode setValue={setValue} />
            </div>

            <div className="flex -mx-4.5">
              <DatePicker
                label={t('financial:fields.incorporationDate.label')}
                name="dateOfIncorporation"
                className="mt-5 w-full mx-4.5"
                control={control}
                maxDate={new Date()}
                disabled={!financialPermissions.edit}
                error={
                  showError &&
                  !dateOfIncorporation && {
                    message: t(
                      'financial:fields.incorporationDate.validation.required',
                    ),
                  }
                }
              />
              <MerchantCategoryCode setValue={setValue} />
            </div>

            <div className="flex items-start -mx-4.5">
              <div className="mt-5 w-full mx-4.5">
                <Input
                  label={t('financial:fields.description.label')}
                  name="shortBusinessProductLineDescription"
                  type="textarea"
                  register={register}
                  rows={3}
                  readOnly={!financialPermissions.edit}
                  error={
                    showError &&
                    !shortBusinessProductLineDescription && {
                      message: t(
                        'financial:fields.description.validation.required',
                      ),
                    }
                  }
                />
                <p className="text-secondary mt-2">
                  {t('financial:fields.description.info')}
                </p>
              </div>

              <div className="mt-5 w-full mx-4.5">
                <Input
                  label={t('financial:fields.funds.label')}
                  name="sourceOfFunds"
                  type="textarea"
                  register={register}
                  rows={3}
                  readOnly={!financialPermissions.edit}
                  error={
                    showError &&
                    !sourceOfFunds && {
                      message: t('financial:fields.funds.validation.required'),
                    }
                  }
                />
                <p className="text-secondary mt-2">
                  {t('financial:fields.funds.info')}
                </p>
              </div>
            </div>

            <div className="flex items-start -mx-4.5">
              <Input
                label={t('financial:fields.industry.label')}
                name="industry"
                register={register}
                className="mt-5 w-full mx-4.5"
                readOnly={!financialPermissions.edit}
                error={
                  showError &&
                  !industry && {
                    message: t('financial:fields.industry.validation.required'),
                  }
                }
              />
              <NumberInput
                label={t('financial:fields.employees.label')}
                name="numberOfEmployes"
                className="mt-5 w-full mx-4.5"
                control={control}
                allowNegative={false}
                type="int"
                readOnly={!financialPermissions.edit}
                error={
                  showError &&
                  !numberOfEmployes && {
                    message: t(
                      'financial:fields.employees.validation.required',
                    ),
                  }
                }
              />
            </div>

            <div className="flex items-start -mx-4.5">
              <NumberInput
                label={t('financial:fields.sales.label')}
                placeholder={t('financial:fields.sales.placeholder')}
                name="businessSalesVolumeTurnover"
                className="mt-5 w-full mx-4.5"
                control={control}
                suffix={currentCurrency}
                suffixInline
                allowNegative={false}
                readOnly={!financialPermissions.edit}
                error={
                  showError &&
                  !businessSalesVolumeTurnover && {
                    message: t('financial:fields.sales.validation.required'),
                  }
                }
              />
              <OperatingStatus
                setValue={setValue}
                control={control}
                showError={showError}
              />
            </div>
          </div>
        </PermissionSection>
      </div>

      <PermissionSection permission={Permissions.financialInfo}>
        <MerchantServices>
          <div>
            <YesNoCollapse
              title={t('financial:regularActivity')}
              value={businessFinancialInfo.regulatedService}
              onChange={(val) => handleAnswer('regulatedService', Boolean(val))}
              className="bg-gray-50"
              disabled={!financialPermissions.edit}
              permission={Permissions.financialInfo}
            >
              <div className="pt-6">
                <UploadFile
                  title={t('financial:businessLicenseTitle')}
                  text={t('financial:businessLicenseText')}
                  onUpload={(files) =>
                    handleUpload(files, BlobFileTypes.license)
                  }
                  uploadFiles={licenseDocs}
                  disabled={!financialPermissions.edit}
                  permission={Permissions.financialInfo}
                  className={
                    showError && licenseDocs.length === 0
                      ? 'border p-1 border-red-600 rounded-md'
                      : ''
                  }
                  progressPercent={
                    fileLoading.type === BlobFileTypes.license
                      ? fileLoading.progress
                      : 0
                  }
                  multiple
                >
                  {() =>
                    licenseDocs.map((file) => (
                      <div key={file.id}>
                        <File
                          file={file}
                          onDelete={handleDelete}
                          readOnly={!financialPermissions.edit}
                          permission={Permissions.financialInfo}
                          onDownload={(name) =>
                            handleDownload(file.blobGUID, name)
                          }
                          hideDate
                          hideUploadButton
                          size="small"
                        />
                      </div>
                    ))
                  }
                </UploadFile>
                {showError && licenseDocs.length === 0 && (
                  <ErrorMessage
                    error={{ message: t('financial:fileRequired') }}
                  />
                )}
              </div>
            </YesNoCollapse>

            <GeographicSales
              register={register}
              setValue={setValue}
              showErrors={showError}
            />

            <YesNoCollapse
              title={t('financial:acceptedPayments')}
              value={businessFinancialInfo.acceptedCardPaymentslast24Months}
              disabled={!financialPermissions.edit}
              permission={Permissions.financialInfo}
              onChange={(val) =>
                handleAnswer('acceptedCardPaymentslast24Months', Boolean(val))
              }
              className="bg-gray-50"
            >
              <div className="pt-6">
                <FinancialCardsAccepted
                  register={register}
                  setValue={setValue}
                  showErrors={showError}
                />

                <YesNoCollapse
                  title={t('financial:cardholderDataLoss')}
                  value={businessFinancialInfo.dataBreach}
                  onChange={(val) => handleAnswer('dataBreach', Boolean(val))}
                  className="bg-white"
                  disabled={!financialPermissions.edit}
                  permission={Permissions.financialInfo}
                >
                  <div className="pt-6">
                    <Input
                      label={t('financial:fields.breachDescription.label')}
                      name="breachDescription"
                      register={register}
                      type="textarea"
                      readOnly={!financialPermissions.edit}
                      error={
                        showError &&
                        !breachDescription && {
                          message: t(
                            'financial:fields.breachDescription.validation.required',
                          ),
                        }
                      }
                    />
                  </div>
                </YesNoCollapse>

                <YesNoCollapse
                  title={t('financial:violationNotice')}
                  className="bg-white"
                  value={businessFinancialInfo.violationNotice}
                  disabled={!financialPermissions.edit}
                  permission={Permissions.financialInfo}
                  onChange={(val) =>
                    handleAnswer('violationNotice', Boolean(val))
                  }
                >
                  <div className="pt-6">
                    <Input
                      label={t('financial:fields.violationDescription.label')}
                      name="violationDescription"
                      register={register}
                      type="textarea"
                      readOnly={!financialPermissions.edit}
                      error={
                        showError &&
                        !violationDescription && {
                          message: t(
                            'financial:fields.violationDescription.validation.required',
                          ),
                        }
                      }
                    />
                  </div>
                </YesNoCollapse>

                <YesNoCollapse
                  title={t('financial:serviceProviders')}
                  className="bg-white"
                  disabled={!financialPermissions.edit}
                  permission={Permissions.financialInfo}
                  value={
                    businessFinancialInfo.currentPaymentProcessingServiceProviders
                  }
                  onChange={(val) =>
                    handleAnswer(
                      'currentPaymentProcessingServiceProviders',
                      Boolean(val),
                    )
                  }
                >
                  <div className="pt-6">
                    <Input
                      label={t('financial:fields.serviceProviders.label')}
                      name="serviceProviders"
                      register={register}
                      type="textarea"
                      readOnly={!financialPermissions.edit}
                      error={
                        showError &&
                        !serviceProviders && {
                          message: t(
                            'financial:fields.serviceProviders.validation.required',
                          ),
                        }
                      }
                    />
                  </div>
                </YesNoCollapse>

                <div className="mt-6 pb-4 border-b border-gray-200">
                  <UploadFile
                    title={t('financial:transactionFileTitle')}
                    text={t('financial:transactionFileText')}
                    disabled={!financialPermissions.edit}
                    permission={Permissions.financialInfo}
                    onUpload={(files) =>
                      handleUpload(files, BlobFileTypes.bankAccountStatement6)
                    }
                    uploadFiles={statementDocs}
                    progressPercent={
                      fileLoading.type === BlobFileTypes.bankAccountStatement6
                        ? fileLoading.progress
                        : 0
                    }
                    className={
                      showError && statementDocs.length === 0
                        ? ' border p-1 border-red-600 rounded-md'
                        : ''
                    }
                    multiple
                  >
                    {({ handleSelect }) =>
                      statementDocs.map((file) => (
                        <div key={file.id}>
                          <File
                            file={file}
                            onDelete={() => handleDelete(file.id)}
                            readOnly={!financialPermissions.edit}
                            permission={Permissions.financialInfo}
                            onDownload={(name) =>
                              handleDownload(file.blobGUID, name)
                            }
                            handleSelect={handleSelect}
                            hideUploadButton
                            hideDate
                            size="small"
                          />
                        </div>
                      ))
                    }
                  </UploadFile>
                  {showError && statementDocs.length === 0 && (
                    <ErrorMessage
                      error={{ message: t('financial:fileRequired') }}
                    />
                  )}
                </div>

                <div className="bg-white mt-6 px-4 py-3 rounded-lg">
                  <p className="text-gray-700 text-sm font-medium leading-4 mb-6">
                    {t('financial:6MonthsTransactions')}
                  </p>
                  <div className="flex items-start -mx-4.5">
                    <NumberInput
                      label={t('financial:fields.chargeHistory.label')}
                      placeholder={t(
                        'financial:fields.chargeHistory.placeholder',
                      )}
                      readOnly={!financialPermissions.edit}
                      name="chargebackHistoryPercentage"
                      prefix="%"
                      prefixInline
                      className="mx-4.5 w-full mt-6"
                      inputClassName="pl-8"
                      control={control}
                      allowNegative={false}
                      error={
                        showError &&
                        !chargebackHistoryPercentage && {
                          message: t(
                            'financial:fields.chargeHistory.validation.required',
                          ),
                        }
                      }
                    />
                    <NumberInput
                      label={t('financial:fields.refundHistory.label')}
                      placeholder={t(
                        'financial:fields.refundHistory.placeholder',
                      )}
                      readOnly={!financialPermissions.edit}
                      name="refundHistoryPercentage"
                      prefix="%"
                      prefixInline
                      className="mx-4.5 w-full mt-6"
                      inputClassName="pl-8"
                      control={control}
                      allowNegative={false}
                      error={
                        showError &&
                        !refundHistoryPercentage && {
                          message: t(
                            'financial:fields.refundHistory.validation.required',
                          ),
                        }
                      }
                    />
                  </div>
                  <div className="flex items-start -mx-4.5">
                    <NumberInput
                      label={t('financial:fields.averageTicket.label')}
                      placeholder={t(
                        'financial:fields.averageTicket.placeholder',
                      )}
                      readOnly={!financialPermissions.edit}
                      name="averageTicketTransaction"
                      className="mx-4.5 mt-6 w-full"
                      control={control}
                      suffix={currentCurrency}
                      suffixInline
                      allowNegative={false}
                      error={
                        showError &&
                        !averageTicketTransaction && {
                          message: t(
                            'financial:fields.averageTicket.validation.required',
                          ),
                        }
                      }
                    />
                    <NumberInput
                      label={t('financial:fields.highestTicket.label')}
                      placeholder={t(
                        'financial:fields.highestTicket.placeholder',
                      )}
                      readOnly={!financialPermissions.edit}
                      name="highestTicketTransaction"
                      className="mx-4.5 mt-6 w-full"
                      control={control}
                      suffix={currentCurrency}
                      suffixInline
                      allowNegative={false}
                      error={
                        showError &&
                        !highestTicketTransaction && {
                          message: t(
                            'financial:fields.highestTicket.validation.required',
                          ),
                        }
                      }
                    />
                  </div>
                  <div className="w-1/2">
                    <NumberInput
                      label={t('financial:fields.deliveryPeriod.label')}
                      readOnly={!financialPermissions.edit}
                      name="deliveryPeriodFromPurchase"
                      className="mr-4.5 mt-6"
                      control={control}
                      allowNegative={false}
                      error={
                        showError &&
                        !deliveryPeriodFromPurchase && {
                          message: t(
                            'financial:fields.deliveryPeriod.validation.required',
                          ),
                        }
                      }
                    />
                  </div>
                </div>
              </div>
            </YesNoCollapse>

            <PciCompliant
              setValue={setValue}
              control={control}
              showErrors={showError}
              watch={watch}
            />

            <div className="mt-6 pt-12 pb-6 border-b">
              <p className="heading-lg mb-1">{t('financial:merchantTitle')}</p>
              <p className="text-secondary">{t('financial:merchantText')}</p>
            </div>

            <NumberInput
              label={t('financial:fields.12MonthsRequest.label')}
              name="volumeLast12Months"
              className="mt-6"
              control={control}
              suffix={currentCurrency}
              suffixInline
              allowNegative={false}
              readOnly={!financialPermissions.edit}
              error={
                showError &&
                !volumeLast12Months && {
                  message: t(
                    'financial:fields.12MonthsRequest.validation.required',
                  ),
                }
              }
            />

            <YearlyGrowth
              register={register}
              setValue={setValue}
              control={control}
              showErrors={showError}
            />

            <YesNoCollapse
              title={t('financial:advanceOrders')}
              value={businessFinancialInfo.acceptOrdersInAdvance}
              disabled={!financialPermissions.edit}
              permission={Permissions.financialInfo}
              onChange={(val) =>
                handleAnswer('acceptOrdersInAdvance', Boolean(val))
              }
              className="bg-gray-50"
            >
              <DelayedSales
                register={register}
                setValue={setValue}
                showErrors={showError}
              />
            </YesNoCollapse>

            <Input
              type="textarea"
              name="purchaseProcess"
              label={t('financial:fields.purchaseProcess.label')}
              readOnly={!financialPermissions.edit}
              className="mt-6"
              rows={4}
              register={register}
              error={
                showError &&
                !purchaseProcess && {
                  message: t(
                    'financial:fields.purchaseProcess.validation.required',
                  ),
                }
              }
            />
            <p className="text-secondary mt-2">
              {t('financial:fields.purchaseProcess.description')}
            </p>

            <YesNoCollapse
              title={t('financial:subscriptionPayments')}
              value={businessFinancialInfo.subscriptionBasedPayments}
              disabled={!financialPermissions.edit}
              permission={Permissions.financialInfo}
              onChange={(val) =>
                handleAnswer('subscriptionBasedPayments', Boolean(val))
              }
              className="bg-gray-50"
            >
              <div className="pt-6">
                <SubscriptionsHowOften
                  setValue={setValue}
                  showErrors={showError}
                />
              </div>
            </YesNoCollapse>

            <div className="py-1">
              <YesNoCollapse
                title={t('financial:websitePolicies')}
                value={businessFinancialInfo.customerTermsConditionsPosted}
                disabled={!financialPermissions.edit}
                permission={Permissions.financialInfo}
                onChange={(val) =>
                  handleAnswer('customerTermsConditionsPosted', Boolean(val))
                }
                className="bg-gray-50"
              >
                <div className="pt-6">
                  <UploadFile
                    title={t('financial:policiesFilesTitle')}
                    text={t('financial:policiesFilesText')}
                    disabled={!financialPermissions.edit}
                    permission={Permissions.financialInfo}
                    onUpload={(files) =>
                      handleUpload(files, BlobFileTypes.termsAndConditions)
                    }
                    uploadFiles={termsDocs}
                    progressPercent={
                      fileLoading.type === BlobFileTypes.termsAndConditions
                        ? fileLoading.progress
                        : 0
                    }
                    className={
                      showError && termsDocs.length === 0
                        ? 'border p-1 border-red-600 rounded-md'
                        : ''
                    }
                    multiple
                  >
                    {() =>
                      termsDocs.map((file) => (
                        <div key={file.id}>
                          <File
                            file={file}
                            readOnly={!financialPermissions.edit}
                            permission={Permissions.financialInfo}
                            onDelete={() => handleDelete(file.id)}
                            onDownload={(name) =>
                              handleDownload(file.blobGUID, name)
                            }
                            hideUploadButton
                            isMultiple
                          />
                        </div>
                      ))
                    }
                  </UploadFile>
                  {showError && termsDocs.length === 0 && (
                    <ErrorMessage
                      error={{ message: t('financial:fileRequired') }}
                    />
                  )}
                </div>
              </YesNoCollapse>
            </div>

            <div className="pt-12 mt-5 divide-y">
              <div className="pb-5">
                <p className="heading-lg mb-1">
                  {t('financial:proofingDocumentsTitle')}
                </p>
                <p className="text-secondary">
                  {t('financial:proofingDocumentsText')}
                </p>
              </div>
              {documents.map((document) => {
                const docs = blobReferences.filter(
                  (blob) => blob.typeCode === document.type,
                );
                return (
                  <div key={document.id}>
                    <UploadFile
                      uploadFiles={docs}
                      onUpload={(files) =>
                        handleUpload(files, document.type, true)
                      }
                      progressPercent={
                        fileLoading.type === document.type
                          ? fileLoading.progress
                          : 0
                      }
                      title={t(document.title)}
                      text={t(document.text)}
                      optional={document.optional}
                      disabled={!financialPermissions.edit}
                      permission={Permissions.financialInfo}
                      className={
                        showError && !document.optional && docs.length === 0
                          ? 'p-2 mb-4 border border-red-600 rounded-md'
                          : 'pt-4 mb-4'
                      }
                    >
                      {({ handleSelect }) =>
                        docs.map((file) => (
                          <div key={file.id}>
                            <File
                              file={file}
                              onDelete={() => handleDelete(file.id)}
                              onDownload={(name) =>
                                handleDownload(file.blobGUID, name)
                              }
                              handleSelect={handleSelect}
                              readOnly={!financialPermissions.edit}
                              permission={Permissions.financialInfo}
                            />
                          </div>
                        ))
                      }
                    </UploadFile>
                    {showError && !document.optional && docs.length === 0 && (
                      <ErrorMessage
                        error={{ message: t('financial:fileRequired') }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            <FinancialSignatory />
          </div>
        </MerchantServices>
      </PermissionSection>
    </div>
  );
};

export default Financial;
