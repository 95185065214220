import { AxiosRequestConfig } from 'axios';

import api from 'api';

export interface BusinessOwnerPersonSchema {
  addressAlpha2Code: string | null;
  addressCountry: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  businessesId: number;
  businessesOwnersPersonsId: number;
  businessesOwnersStatusesDescription: string;
  businessesOwnersStatusesId: number;
  city: string | null;
  countryCallingCode: string | null;
  countryIsocode: string | null;
  createdBy: string | null;
  createdBySp: string | null;
  createdOn: string | null;
  dateOfBirth: string | null;
  emailAddress: string | null;
  givenName1: string | null;
  givenName2: string | null;
  idAlpha2Code: string | null;
  idCountry: string | null;
  idbackBlobFileExtension: string | null;
  idbackBlobFileName: string | null;
  idbackBlobFileSize: number | null;
  idbackGuid: string | null;
  idcountryIsocode: string | null;
  idexpirationDate: string | null;
  idfrontBlobFileExtension: string | null;
  idfrontBlobFileName: string | null;
  idfrontBlobFileSize: number | null;
  idfrontGuid: string | null;
  idnumber: string | null;
  isActive: boolean;
  middleName: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  modifiedOn: string | null;
  nationalityCountryISOCode: string | null;
  ownershipPercentage: number | null;
  personDocumentsTypesId: number | null;
  personDocumentsTypesIdDescription: string | null;
  postalCode: string | null;
  preferredName: string | null;
  prefix: string | null;
  proofOfAddressBlobFileExtension: string | null;
  proofOfAddressBlobFileName: string | null;
  proofOfAddressBlobFileSize: number | null;
  proofOfAddressGuid: string | null;
  stateOrProvince: string | null;
  statusCode: string | null;
  suffix: string | null;
  surnameFirst: string | null;
  surnameSecond: string | null;
  telephoneNumber: string | null;
}

export const getBusinessesOwnersPersons = async (id: number) => {
  const results: BusinessOwnerPersonSchema[] = await api.get(
    `BusinessesOwnersPersons/GetBusinessesOwnersPersons/${id}`,
  );

  return results.length ? results[0] : null;
};

export const addBusinessesOwnersPersons = async (payload: FormData) => {
  const id: number = await api.post(
    'BusinessesOwnersPersons/AddBusinessesOwnersPersons',
    payload,
  );

  return id;
};

export const updateBusinessesOwnersPersons = async (payload: FormData) => {
  const id: number = await api.put(
    'BusinessesOwnersPersons/UpdateBusinessesOwnersPersons',
    payload,
  );

  return id;
};

export const searchBusinessesOwnersPersons = async (
  payload: Partial<BusinessOwnerPersonSchema>,
) => {
  const results: BusinessOwnerPersonSchema[] = await api.post(
    'BusinessesOwnersPersons/SearchBusinessesOwnersPersons',
    payload,
  );

  return results;
};

export const updateIsActiveBusinessesOwnersPersons = async (
  payload: Partial<BusinessOwnerPersonSchema>,
) => {
  const id: number = await api.put(
    'BusinessesOwnersPersons/UpdateIsActiveBusinessesOwnersPersons',
    payload,
  );

  return id;
};

export interface UpdateBusinessOwnerPersonBlobReferencePayload {
  businessesOwnersPersonsId: number;
  modifiedBy: string;
  idFrontGuid: string;
}

export const updateBusinessesOwnersPersonsBlobReference = async (
  payload: UpdateBusinessOwnerPersonBlobReferencePayload,
) => {
  const id: number = await api.put(
    'BusinessesOwnersPersons/UpdateBusinessesOwnersPersonsBlobReference',
    payload,
  );

  return id;
};

export const getBusinessesOwnersPersonsFile = async (id: string) => {
  const result: Blob = await api.get(
    `BusinessesOwnersPersons/GetBusinessesOwnersPersonsFile/${id}`,
    {
      responseType: 'blob',
    },
  );

  return result;
};

export interface BusinessOwnerBusinessSchema {
  addressAlpha2Code: string | null;
  addressCountry: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  businessContactName: string | null;
  businessContactNumber: string | null;
  businessLegalName: string | null;
  businessesId: number;
  businessesLegalEntityFormTypesId: number | null;
  businessesOwnersBusinessesId: number;
  businessesOwnersStatusesId: number;
  city: string | null;
  countryCallingCode: string | null;
  countryIsocode: string | null;
  countryofIncorporationAlpha2Code: string | null;
  countryofIncorporationCountry: string | null;
  countryofIncorporationIsocode: string | null;
  createdBy: string | null;
  createdBySp: string | null;
  createdOn: string | null;
  doingBusinessAs: string | null;
  dunsnumber: string | null;
  emailAddress: string | null;
  isActive: boolean;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  modifiedOn: string | null;
  noDunslisted: boolean;
  ownershipPercentage: number | null;
  postalCode: string | null;
  referenceNumber: string | null;
  stateOrProvince: string | null;
}

export const getBusinessesOwnersBusinesses = async (id: number) => {
  const results: BusinessOwnerBusinessSchema[] = await api.get(
    `BusinessesOwnersBusinesses/GetBusinessesOwnersBusinesses/${id}`,
  );

  return results.length ? results[0] : null;
};

export const addBusinessesOwnersBusinesses = async (
  payload: Partial<BusinessOwnerBusinessSchema>,
) => {
  const id: number = await api.post(
    'BusinessesOwnersBusinesses/AddBusinessesOwnersBusinesses',
    payload,
  );

  return id;
};

export const updateIsActiveBusinessesOwnersBusinesses = async (
  payload: Partial<BusinessOwnerBusinessSchema>,
) => {
  const id: number = await api.put(
    'BusinessesOwnersBusinesses/UpdateIsActiveBusinessesOwnersBusinesses',
    payload,
  );

  return id;
};

export const updateBusinessesOwnersBusinesses = async (
  payload: Partial<BusinessOwnerBusinessSchema>,
) => {
  const id: number = await api.put(
    'BusinessesOwnersBusinesses/UpdateBusinessesOwnersBusinesses',
    payload,
  );

  return id;
};

export const searchBusinessesOwnersBusinesses = async (
  payload: Partial<BusinessOwnerBusinessSchema>,
) => {
  const results: BusinessOwnerBusinessSchema[] = await api.post(
    'BusinessesOwnersBusinesses/SearchBusinessesOwnersBusinesses',
    payload,
  );

  return results;
};

export interface BusinessOwnerBlobReferenceSchema {
  blobFileExtension: string | null;
  blobFileName: string | null;
  blobFileSize: number | null;
  blobGuid: string | null;
  blobReferencesTypesId: number;
  businessesId: number;
  businessesOwnersBlobReferencesId: number;
  createdBy: string | null;
  createdBySp: string | null;
  createdOn: string | null;
  description: string | null;
  isActive: boolean;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  modifiedOn: string | null;
  typeCode: string | null;
}

export const addBusinessesOwnersBlobReference = async (
  payload: FormData,
  options = {} as AxiosRequestConfig,
) => {
  const id: number = await api.post(
    'BusinessesOwnersBlobReferences/AddBusinessesOwnersBlobReference',
    payload,
    options,
  );

  return id;
};

export const getBusinessesOwnersBlobReference = async (id: number) => {
  const results: BusinessOwnerBlobReferenceSchema[] = await api.get(
    `BusinessesOwnersBlobReferences/GetBusinessesOwnersBlobReference/${id}`,
  );

  return results.length ? results[0] : null;
};

export const getBusinessesOwnersBlobReferenceFile = async (id: number) => {
  const result: Blob = await api.get(
    `BusinessesOwnersBlobReferences/GetBusinessesOwnersBlobReferenceFile/${id}`,
    {
      responseType: 'blob',
    },
  );

  return result;
};

export const searchBusinessesOwnersBlobReferences = async (
  payload: Partial<BusinessOwnerBlobReferenceSchema>,
) => {
  const results: BusinessOwnerBlobReferenceSchema[] = await api.post(
    'BusinessesOwnersBlobReferences/SearchBusinessesOwnersBlobReference',
    payload,
  );

  return results;
};

export interface UpdateIsActiveBusinessOwnerBlobReferencePayload {
  businessesOwnersBlobReferencesID: number;
  isActive: boolean;
  modifiedBy: string;
}

export const updateIsActiveBusinessesOwnersBlobReference = async (
  payload: UpdateIsActiveBusinessOwnerBlobReferencePayload,
) => {
  const id: number = await api.put(
    'BusinessesOwnersBlobReferences/UpdateIsActiveBusinessesOwnersBlobReference',
    payload,
  );

  return id;
};
