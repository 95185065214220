import { useEffect, useState } from 'react';

import { addERPLogo } from 'pages/dashboard/components/AccountingSoftware/accountingSlice';
import { getERPLogos } from 'pages/dashboard/components/AccountingSoftware/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchERPLogoByURI } from 'services/erp';
import { blobToBase64 } from 'utils/helpers';
import Loading from 'components/Loading';

const ERPConnectorLogo = ({ link, alt, className }) => {
  const dispatch = useAppDispatch();

  const ERPLogos = useAppSelector(getERPLogos);

  const [loading, setLoading] = useState<boolean>(true);
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    const fetchProviderLogo = async (logoUri: string) => {
      const providerLogoSrc = await fetchERPLogoByURI(logoUri);

      if (providerLogoSrc) {
        blobToBase64(providerLogoSrc, function (base64String) {
          const base64Src = `data:image/png;base64,${base64String}`;
          setImageSrc(base64Src);

          dispatch(
            addERPLogo({
              link,
              src: base64Src,
            }),
          );
        });
      }

      setLoading(false);
    };

    const logoDownloaded = ERPLogos.find((logo) => logo.link === link);

    if (logoDownloaded) {
      setImageSrc(logoDownloaded.src);
      setLoading(false);
    } else {
      fetchProviderLogo(link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={`relative ${className}`}>
        <Loading />
      </div>
    );
  }

  if (!imageSrc) {
    return <span>{alt || ''}</span>;
  }

  return <img src={imageSrc} alt={alt || 'Logo'} className={className} />;
};
export default ERPConnectorLogo;
