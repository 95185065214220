import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { CheckIcon, ChevronDownIcon, PencilIcon } from 'components/icons';
import PermissionSection from 'components/permission/PermissionSection';
import { openDialog, closeDialog } from 'components/dialog/modalSlice';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import Button, { ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { Permissions } from 'entities/dashboard';
import { getTenant } from 'theme/selectors';
import Popover from 'components/Popover';

import { getPermissionType, getPermissions } from '../selectors';
import { PermissionType, Person } from '../invitePersonsSlice';
import PermissionModal from './PermissionModal';
import { formatPhoneNumber } from 'utils/phone';
import {
  deleteBusinessPerson,
  fetchMemberPermissions,
  fetchPermissions,
} from '../thunks';

type SelectPermissionsProps = {
  person?: Person;
};

const SelectPermissions: React.FC<SelectPermissionsProps> = ({
  person = null,
}) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getPermissions);
  const type = useAppSelector((state) => getPermissionType(state, person));
  const { supportEmailAddress, telephoneNumber } = useAppSelector(getTenant);
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const [selectedType, setSelectedType] = useState<PermissionType | null>(type);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);

  const isViewPermissions =
    (!person && type === PermissionType.view) || person?.hasOnlyReadonlyRights;
  const isEditPermissions =
    (!person && type === PermissionType.edit) ||
    person?.hasOnlyReadonlyRights === false;

  useEffect(() => {
    if (!person && !permissions.view.length) {
      dispatch(fetchPermissions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (value: PermissionType) => {
    if (person) {
      dispatch(fetchMemberPermissions(person.personsId));
    }

    setSelectedType(value);
    setOpened(false);
    setIsModalOpen(true);
  };

  const cancelRemove = () => dispatch(closeDialog());

  const handleRemove = async () => {
    await dispatch(deleteBusinessPerson(person));
    cancelRemove();
  };

  const confirmRemove = () => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: cancelRemove,
        content: (
          <DangerDialog
            title={t('invitePersons.member.removeUserTitle')}
            text={t('invitePersons.member.removeUserDescription', {
              phone: formatPhoneNumber(telephoneNumber),
              email: supportEmailAddress,
            })}
            buttonText={t('common:remove')}
            onConfirm={handleRemove}
            onCancel={cancelRemove}
          />
        ),
      }),
    );
  };

  return (
    <div>
      <Popover
        opened={opened}
        button={
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <Button
              className={person ? '!rounded-l-md !border-l min-w-[120px]' : ''}
              variant={ButtonVariant.addOn}
              onClick={() => setOpened(true)}
              disabled={person?.businessesAdmin || !invitePermissions.edit}
            >
              <span className="font-semibold text-gray-900 text-sm w-full text-left">
                {type
                  ? t(`invitePersons.${type}`)
                  : person
                  ? t(
                      `invitePersons.${
                        person.hasOnlyReadonlyRights ? 'view' : 'edit'
                      }`,
                    )
                  : t('invitePersons.select')}
              </span>
              <ChevronDownIcon
                className="ml-1 text-gray-400"
                aria-hidden="true"
              />
            </Button>
          </PermissionSection>
        }
        content={
          <div className="mt-[5px] w-[216px] max-h-[420px] overflow-auto custom-scrollbar py-1 rounded-md shadow-lg bg-white divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <button
              className={classNames(
                isViewPermissions && 'bg-gray-100',
                'w-full flex flex-col text-left p-4 cursor-pointer hover:bg-gray-100',
              )}
              onClick={() => handleSelect(PermissionType.view)}
            >
              <div className="flex items-center w-full">
                <p
                  className={isViewPermissions ? 'font-medium' : 'font-normal'}
                >
                  {t('invitePersons.view')}
                </p>
                {isViewPermissions && (
                  <div className="ml-2 flex items-center flex-1 justify-between">
                    <PencilIcon aria-hidden="true" className="text-blue-500" />
                    <CheckIcon aria-hidden="true" className="text-green-500" />
                  </div>
                )}
              </div>
            </button>
            <button
              className={classNames(
                isEditPermissions && 'bg-gray-100',
                'w-full flex flex-col text-left p-4 cursor-pointer hover:bg-gray-100',
              )}
              onClick={() => handleSelect(PermissionType.edit)}
            >
              <div className="flex items-center w-full">
                <p
                  className={isEditPermissions ? 'font-medium' : 'font-normal'}
                >
                  {t('invitePersons.edit')}
                </p>
                {isEditPermissions && (
                  <div className="ml-2 flex items-center flex-1 justify-between">
                    <PencilIcon aria-hidden="true" className="text-blue-500" />
                    <CheckIcon aria-hidden="true" className="text-green-500" />
                  </div>
                )}
              </div>
            </button>
            {person && (
              <button
                className="w-full flex flex-col text-left p-4 cursor-pointer hover:bg-gray-100 text-red-500"
                onClick={confirmRemove}
              >
                {t('invitePersons.member.removeUser')}
              </button>
            )}
          </div>
        }
        placement="bottom-end"
      />

      {isModalOpen && (
        <PermissionModal
          onClose={() => setIsModalOpen(false)}
          type={selectedType}
          person={person}
        />
      )}
    </div>
  );
};

export default SelectPermissions;
