import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import accountingLogo from 'assets/images/accounting-software.png';
import ERPConnectorLogo from 'components/Logos/ERPConnectorLogo';
import ContactSupport from 'components/ContactSupport';
import Alert, { AlertTypes } from 'components/Alert';
import { ERPConnector } from 'entities/accounting';
import Popover from 'components/Popover';
import Loading from 'components/Loading';
import { useAppSelector } from 'hooks';
import Link from 'components/Link';

import { getErpAccounts, getProviderList } from '../selectors';

interface ProvidersListProps {
  onChange: (value: ERPConnector) => void;
  isLoading?: boolean;
  selectedErpConnectorId: any;
}

const ProvidersList: React.FC<ProvidersListProps> = ({
  onChange,
  isLoading,
  selectedErpConnectorId,
}) => {
  const { t } = useTranslation('dashboard');
  const erpAccounts = useAppSelector(getErpAccounts);
  const options: ERPConnector[] = useAppSelector(getProviderList);

  const [selected, setSelected] = useState(selectedErpConnectorId);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (selectedErpConnectorId && !id) {
      setSelected(selectedErpConnectorId);
    }
  }, [selectedErpConnectorId, options, id]);

  const handleChange = (provider) => {
    const selectedAccount: any = erpAccounts.find(
      (item: any) => item?.connectorId === provider.connectorId,
    );

    setSelected(provider.connectorId);
    setId(provider.connectorId);
    onChange({ ...provider, jsone: selectedAccount?.jsone });
  };

  return (
    <div className="p-6 flex-1 overflow-auto relative">
      <img
        alt="Accounting Software"
        src={accountingLogo}
        className="mx-auto mb-3 w-11 h-11"
      />
      <h6 className="heading-lg text-center mb-3">
        {t('dashboard:accounting.providers.title')}
      </h6>

      <div className="flex justify-center">
        <div className="bg-gray-50 rounded-md p-4 text-xs leading-4 font-medium text-gray-600 mb-6">
          {t('dashboard:accounting.providers.subTitle')}
        </div>
      </div>

      <div className="flex items-center justify-between mb-6">
        <p className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-500">
          {t('dashboard:accounting.providers.selectTitle')}
        </p>
      </div>

      <div className="grid items-center -mx-2 mb-6 grid-cols-2">
        {isLoading ? (
          <Loading transparent />
        ) : (
          options
            .filter((connector) => connector.isActive)
            .map(({ name, connectorId, logoFileShareUri, ...rest }) => (
              <button
                className={classNames(
                  'py-4 px-6 shadow-sm border rounded-lg m-2 cursor-pointer',
                  {
                    'border-blue-600': selected === connectorId,
                  },
                )}
                onClick={() =>
                  handleChange({ name, connectorId, logoFileShareUri, ...rest })
                }
                key={name}
              >
                {logoFileShareUri ? (
                  <ERPConnectorLogo
                    link={logoFileShareUri}
                    alt={name}
                    className="h-12 m-auto"
                  />
                ) : (
                  <div className="h-12 flex items-center justify-center">
                    {name}
                  </div>
                )}
              </button>
            ))
        )}
      </div>

      <div className="flex mx-auto self-start max-w-[448px]">
        <Alert
          type={AlertTypes.info}
          title={t('dashboard:accounting.providers.notListed')}
          description={
            <Popover
              button={
                <Link className="text-sm">{t('common:helpTranscard')} →</Link>
              }
              content={<ContactSupport />}
              overlay={true}
            />
          }
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ProvidersList;
