import { createSlice } from '@reduxjs/toolkit';

import { searchPersonByEmail } from 'components/InvitePersons/thunks';
import { authorizeSignatory } from 'pages/Profile/thunks';

interface SubmitDashboardProps {
  isModalOpened: boolean;
  isDDAChecked: boolean;
  termsCheckbox: {
    show: boolean;
    checked: boolean;
  };
  privacyCheckbox: {
    show: boolean;
    checked: boolean;
  };
  authorizing: boolean;
  isNMIAccepted: boolean;
}

export const initialState: SubmitDashboardProps = {
  isModalOpened: false,
  isDDAChecked: false,
  termsCheckbox: {
    show: false,
    checked: false,
  },
  privacyCheckbox: {
    show: false,
    checked: false,
  },
  authorizing: false,
  isNMIAccepted: false,
};

const submitDashboardSlice = createSlice({
  name: 'submitDashboard',
  initialState,
  reducers: {
    setIsDDAChecked(state, action) {
      state.isDDAChecked = action.payload;
    },
    setTermsCheckbox(state, action) {
      state.termsCheckbox = {
        ...state.termsCheckbox,
        ...action.payload,
      };
    },
    setPrivacyCheckbox(state, action) {
      state.privacyCheckbox = {
        ...state.privacyCheckbox,
        ...action.payload,
      };
    },
    setIsModalOpened(state, action) {
      if (action.payload) {
        state.isModalOpened = action.payload;
      } else {
        Object.assign(state, initialState);
      }
    },
    setIsNMIAccepted(state, action) {
      state.isNMIAccepted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authorizeSignatory.pending, (state) => {
      state.authorizing = true;
    });
    builder.addCase(authorizeSignatory.rejected, (state) => {
      state.authorizing = false;
    });
    builder.addCase(authorizeSignatory.fulfilled, (state) => {
      state.authorizing = false;
    });
    builder.addCase(searchPersonByEmail.fulfilled, (state) => {
      state.isModalOpened = false;
    });
  },
});

export const {
  setIsDDAChecked,
  setTermsCheckbox,
  setPrivacyCheckbox,
  setIsModalOpened,
  setIsNMIAccepted,
} = submitDashboardSlice.actions;

export default submitDashboardSlice.reducer;
