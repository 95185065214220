import { useTranslation } from 'react-i18next';

import accountReceivable from 'assets/icons/accountReceivable.svg';
import accountPayable from 'assets/icons/accountPayable.svg';

import PopoverCard from './PopoverCard';

const PaymentsPopover = () => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="bg-gray-900 rounded-lg px-4 py-2 w-[300px] mt-1 shadow-2xl">
      <PopoverCard
        badge={t('progress.payments.apBadge')}
        title={t('progress.payments.apTitle')}
        text={t('progress.payments.apText')}
        icon={accountPayable}
      />
      <PopoverCard
        badge={t('progress.payments.arBadge')}
        title={t('progress.payments.arTitle')}
        text={t('progress.payments.arText')}
        icon={accountReceivable}
        rgbColor="12,180,207"
      />
    </div>
  );
};

export default PaymentsPopover;
