export const PaymentProcessorIcon = ({ className }: { className?: string }) => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.3"
      d="M33.5 10.2861H6.5V15.8576H33.5V10.2861Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.15 27H18.4754C18.0895 21.1365 13.2112 16.5 7.25 16.5C6.99798 16.5 6.74789 16.5083 6.5 16.5246V15.8572H33.5V25.6071C33.5 26.4429 32.96 27 32.15 27ZM33.5 10.2858V8.89286C33.5 8.05714 32.96 7.5 32.15 7.5H7.85C7.04 7.5 6.5 8.05714 6.5 8.89286V10.2858H33.5ZM22.4 22.125C22.4 23.2393 23.345 24.2143 24.425 24.2143H28.775C29.855 24.2143 30.8 23.2393 30.8 22.125C30.8 21.0107 29.855 20.0357 28.775 20.0357H24.425C23.345 20.0357 22.4 21.0107 22.4 22.125Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M6.3125 31.125C5.95312 31.125 5.59375 30.9812 5.30625 30.6937L2.43125 27.8187C1.85625 27.2438 1.85625 26.3812 2.43125 25.8062C3.00625 25.2312 3.94063 25.2312 4.44375 25.8062L6.3125 27.675L11.0563 22.9312C11.6313 22.3562 12.4937 22.3562 13.0688 22.9312C13.6438 23.5063 13.6438 24.3688 13.0688 24.9438L7.31875 30.6937C7.03125 30.9812 6.67188 31.125 6.3125 31.125Z"
      fill="currentColor"
    />
  </svg>
);
