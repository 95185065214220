import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMemo } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import WarningDialog from 'components/dialog/components/WarningDialog';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { DataSourceWidgets } from 'entities/accounting';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import Badge from 'components/Badge';
import Link from 'components/Link';
import {
  DialogDataProps,
  closeDialog,
  openDialog,
} from 'components/dialog/modalSlice';

import { setShowWidgets } from '../AccountingSoftware/accountingSlice';
import { getSourceType } from '../AccountingSoftware/selectors';

export type WidgetType = {
  id: number;
  title: string;
  description: string | React.ReactElement;
  icon: string;
  modal: React.ReactElement;
  active: boolean;
};

const DataSourceWidget = ({ data }: { data: WidgetType }) => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const dataSource: number = useAppSelector(getSourceType);
  const sourcePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  const isSelected = useMemo(
    () => dataSource === data.id,
    [dataSource, data.id],
  );

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const showModal = (options: Partial<DialogDataProps>) => {
    dispatch(openDialog(options));
  };

  const openWidgetModal = () => {
    showModal({
      content: data.modal,
      hideCross: false,
      maxWidth: '680px',
    });
  };

  const handleModal = (checkDataSource = true) => {
    if (checkDataSource && dataSource) {
      showModal({
        content: (
          <WarningDialog
            title="integration:discardedModal.title"
            text="integration:discardedModal.text"
            onCancel={handleClose}
            onContinue={openWidgetModal}
          />
        ),
        hideCross: false,
        maxWidth: '512px',
      });
    } else {
      openWidgetModal();
    }
  };

  const handleClick = () => {
    if (dataSource === DataSourceWidgets.erp) {
      dispatch(setShowWidgets(false));
    } else {
      handleModal(false);
    }
  };

  return (
    <div
      className={classNames(
        'p-6 rounded-lg shadow-sm border flex flex-col',
        !data.active && 'bg-gray-100 opacity-50',
        isSelected && 'outline outline-2 outline-blue-600',
      )}
    >
      <div className="flex items-center justify-between">
        <img src={data.icon} alt={t(data.title)} className="w-8 h-8" />
        <HideComponent show={isSelected}>
          <Badge rounded="rounded-sm">{t('dataSource.selectedBadge')}</Badge>
        </HideComponent>
      </div>

      <div className="my-4 flex-1">
        <p className="font-medium mb-1">{t(data.title)}</p>
        <p className="text-secondary">
          {typeof data.description === 'string'
            ? t(data.description)
            : data.description}
        </p>
      </div>

      <HideComponent show={!isSelected}>
        <PermissionSection
          permission={Permissions.erpIntegration}
          showPopover
          edit
        >
          <Button
            variant={ButtonVariant.secondaryOutline}
            size={ButtonSize.medium}
            className="self-start !opacity-100 disabled:border-opacity-40 disabled:text-opacity-40"
            disabled={!data.active || !sourcePermissions.edit}
            onClick={handleModal}
          >
            {t('common:select')}
          </Button>
        </PermissionSection>
      </HideComponent>
      <HideComponent show={isSelected && dataSource == DataSourceWidgets.erp}>
        <Link className="text-sm self-start pt-2" onClick={handleClick}>
          {t(
            `dataSource.${
              dataSource === DataSourceWidgets.erp
                ? 'viewAccounts'
                : 'learnMore'
            }`,
          )}
        </Link>
      </HideComponent>
    </div>
  );
};

export default DataSourceWidget;
