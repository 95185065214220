import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { ITheme } from './themeSlice';

export const getRoot = (state: RootState): ITheme => state.theme;

export const getTheme = createSelector([getRoot], ({ theme }) => theme);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getDomain = createSelector([getRoot], ({ domain }) => domain);
export const getTenant = createSelector([getRoot], ({ tenant }) => tenant);
