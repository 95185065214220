import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getPerson } from 'pages/Profile/selectors';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';

import ComplianceModal from './components/ComplianceModal';
import { setIsModalOpened } from './complianceSlice';

const Compliance = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const person = useAppSelector(getPerson);

  const handleOpen = () => {
    dispatch(setIsModalOpened(true));
  };

  if (!person || !person.emailAddress) {
    return null;
  }

  return (
    <PermissionSection permission={Permissions.compliance}>
      <>
        <ComplianceModal />
        <Button
          onClick={handleOpen}
          type="button"
          className="flex-shrink-0 ml-2"
          variant="blue-outline"
          heightClass="h-10.5"
          paddingClass="px-5"
        >
          {t('dashboard:compliance.button')}
        </Button>
      </>
    </PermissionSection>
  );
};

export default Compliance;
