import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FileIcon, ChevronRightIcon } from 'components/icons';

interface GetStartedProps {
  step: number;
  getStarted: (nextStep: number) => void;
}

const GetStarted: React.FC<GetStartedProps> = ({ step, getStarted }) => {
  const { t } = useTranslation('dashboard');

  if (step !== 0) {
    return null;
  }

  return (
    <div className="flex justify-center flex-col items-center flex-1 h-full">
      <div className="mt-5 mb-5">
        <FileIcon className="w-12 h-12 text-blue-600" />
      </div>
      <div>
        <span className="text-2xl leading-8 font-bold">
          {t('dashboard:businessPlan.modal.planCreation')}
        </span>
        <span className="text-2xl leading-8 font-normal">
          {t('dashboard:businessPlan.modal.stepByStep')}
        </span>
      </div>
      <div className="text-sm leading-5 font-normal max-w-[680px] mt-5 text-center">
        <span>{t('dashboard:businessPlan.modal.subTitle1')}</span>
      </div>
      <div className="text-sm leading-5 font-normal max-w-[680px] mt-5 p-4 flex flex-col rounded-xl bg-gray-50">
        <span>{t('dashboard:businessPlan.modal.subTitle2')}</span>
      </div>
      <div className="mt-12 w-full flex justify-center">
        <Button
          heightClass="h-20"
          className="rounded-[10px] w-full max-w-[445px]"
          onClick={() => getStarted(1)}
        >
          <p className="w-full text-left text-lg leading-7 font-semibold">
            {t('dashboard:businessPlan.buttons.getStarted')}
          </p>
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default GetStarted;
