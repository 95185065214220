import * as yup from 'yup';

import { BankNames } from 'entities/bankAccounts';
import { trimStr, isSubset } from 'utils/helpers';
import {
  BankAccountCheckRangeType,
  BankAccountCheckType,
  BankAccountTypeSchema,
} from 'services/bankAccounts';

export const getBankName = (name: string) => {
  return BankNames[name.replace(/\s/g, '')] || '';
};

export const mappedSearchAccounts = () => {
  return {
    isActive: true,
  };
};

export const mappedAccountTypes = (accountTypes: BankAccountTypeSchema[]) => {
  return accountTypes.map(({ id, name }) => ({
    name,
    value: id,
  }));
};

export const mapAdditionalFields = ({ fields, additionalFields, jsonData }) => {
  const data = fields.slice().map((field) => ({
    id: field.id,
    name: field.name,
    modifiedOn:
      trimStr(additionalFields[field.id]) ===
      jsonData[`additionalFields.${field.id}`]
        ? null
        : new Date().toISOString(),
    value: trimStr(additionalFields[field.id]),
  }));

  return data;
};

type MapCheckRangesParamsType = {
  bankAccountsId: number;
  emailAddress: string;
};

export const mapCheckRanges = (
  ranges: BankAccountCheckRangeType[],
  existedRanges: BankAccountCheckRangeType[],
  params: MapCheckRangesParamsType,
) => {
  const result: BankAccountCheckRangeType[] = ranges
    .filter(
      (range: BankAccountCheckRangeType) => range.startRange && range.endRange,
    )
    .map((range: BankAccountCheckRangeType) => {
      const rangeData: BankAccountCheckRangeType = {
        ...range,
        bankAccountsId: params.bankAccountsId,
      };

      if (!rangeData.createdBy) {
        rangeData.createdBy = params.emailAddress;
      } else {
        rangeData.modifiedBy = params.emailAddress;
      }

      return rangeData;
    });

  existedRanges.map((checkRange: BankAccountCheckRangeType) => {
    if (
      !result.find(
        (range: BankAccountCheckRangeType) =>
          range.banksAccountsChecksRangesId ===
          checkRange.banksAccountsChecksRangesId,
      )
    ) {
      result.unshift({
        ...checkRange,
        isActive: false,
        modifiedBy: params.emailAddress,
      });
    }

    return checkRange;
  });

  return result;
};

export const mapChecks = (
  omitted: BankAccountCheckType[],
  existedOmitted: BankAccountCheckType[],
  params: MapCheckRangesParamsType,
) => {
  const result: BankAccountCheckType[] = omitted.map(
    (omit: BankAccountCheckType) => {
      const omitData: BankAccountCheckType = {
        ...omit,
        banksAccountsId: params.bankAccountsId,
        banksAccountsChecksId: omit.banks_Accounts_ChecksId,
      };

      if (!omitData.createdBy) {
        omitData.createdBy = params.emailAddress;
      } else {
        omitData.modifiedBy = params.emailAddress;
      }

      return omitData;
    },
  );

  existedOmitted.map((checkOmit: BankAccountCheckType) => {
    if (
      !result.find(
        (omit: BankAccountCheckType) =>
          omit.banks_Accounts_ChecksId === checkOmit.banks_Accounts_ChecksId,
      )
    ) {
      result.unshift({
        ...checkOmit,
        banksAccountsChecksId: checkOmit.banks_Accounts_ChecksId,
        banksAccountsId: params.bankAccountsId,
        isActive: false,
        modifiedBy: params.emailAddress,
      });
    }

    return checkOmit;
  });

  return result;
};

export const mapCreateBankAccountFields = ({
  data,
  person,
  business,
  bankAccounts,
}) => {
  return {
    ...data,
    accountNumberLast4: data.accountNumber.slice(-4),
    businessesId: business.businessesId,
    banksIntegratorsId: bankAccounts.integrator?.banksIntegratorsId || 0,
    integratorDirectoryId: bankAccounts.directoryId,
    createdBy: person.emailAddress,
    userCalledName: '',
    isActive: true,
  };
};

export const isBankAccountEmpty = ({ bankAccount, integrator }) => {
  if (bankAccount?.banksIntegratorsId === 0) {
    return false;
  }

  const banksIntegratorsId = bankAccount?.banksIntegratorsId || 0;

  return (
    !bankAccount || (integrator?.banksIntegratorsId || 0) !== banksIntegratorsId
  );
};

export const mapUpdateBankAccountFields = ({
  data,
  emailAddress,
  bankAccounts,
}) => {
  const { bankAccount, integrator, directoryId } = bankAccounts;

  if (isSubset(data, bankAccount)) {
    return null;
  }

  const empty = isBankAccountEmpty({ bankAccount, integrator });
  const accountNumber = data.accountNumber ?? bankAccount.accountNumber;

  const { addressLine1, city, postalCode, stateOrProvince, countryISOCode } =
    bankAccount;

  return {
    addressLine1,
    city,
    postalCode,
    stateOrProvince,
    countryISOCode,
    isActive: true,
    ...data,
    bankAccountsId: bankAccount.bankAccountsId,
    businessesId: bankAccount.businessesId,
    treasuryBlobFileExtension: empty
      ? ''
      : data.treasuryBlobFileExtension || bankAccount.treasuryBlobFileExtension,
    treasuryBlobFileName: empty
      ? ''
      : data.treasuryBlobFileName || bankAccount.treasuryBlobFileName,
    treasuryBlobFileSize: empty
      ? ''
      : data.treasuryBlobFileSize || bankAccount.treasuryBlobFileSize,
    treasuryBlobdocumentId: empty
      ? ''
      : data.treasuryBlobdocumentId || bankAccount.treasuryBlobdocumentId,
    accountNumberLast4: accountNumber ? accountNumber.slice(-4) : '',
    modifiedBy: emailAddress,
    banksIntegratorsId: integrator.banksIntegratorsId,
    integratorDirectoryId: directoryId,
  };
};

export const getBankAccountConnectionSchema = (t) =>
  yup
    .object()
    .shape({
      connectionName: yup
        .string()
        .trim()
        .required(
          t('common:validations.required', {
            name: t('bank:connectionName'),
          }),
        ),
    })
    .required();

export const getBankAccountInfoSchema = (t) =>
  yup
    .object()
    .shape({
      accountName: yup
        .string()
        .trim()
        .required(t('bank:fields.bankName.validation.required')),
      countryISOCode: yup
        .string()
        .trim()
        .required(t('common:fields.country.validation.required')),
      addressLine1: yup
        .string()
        .trim()
        .required(t('common:fields.address.validation.required')),
      city: yup
        .string()
        .trim()
        .required(t('common:fields.city.validation.required')),
      postalCode: yup
        .string()
        .trim()
        .required(t('common:fields.postal.validation.required')),
      stateOrProvince: yup
        .string()
        .trim()
        .required(t('common:fields.province.validation.required')),
      bankAccountTypesId: yup
        .number()
        .nullable()
        .min(1, t('bank:fields.accountType.validation.required'))
        .required(t('bank:fields.accountType.validation.required')),
      routingNumber: yup
        .string()
        .trim()
        .required(t('bank:fields.routingNumber.validation.required')),
      accountNumber: yup
        .string()
        .trim()
        .required(t('bank:fields.accountNumber.validation.required')),
    })
    .required();
