export enum ComplianceStatuses {
  pending = 1,
  approved = 2,
  declined = 3,
}

export type BusinessRequest = {
  businessesRequestsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  businessesId: number;
  isHidden: boolean;
  isReplyAllowed: boolean;
  isEmail: boolean;
  subject: string;
  messageText: string;
  followUpOn: string;
  replyDueOn: string;
  threadMessageId: string;
  replyMessageId: string;
  priority: number;
  tokenOid: string;
  isLogging: boolean;
};

export const COMPLIANCE_ROLE = 'compliance';
export const IMPLEMENTATION_ROLE = 'implementations';
export const ADMIN_ROLE = 'admin';
export const TC_ADMIN_ROLE = 'transcardadmin';
export const VIEWER_ROLE = 'viewer';

export const TC_ROLES = [
  COMPLIANCE_ROLE,
  IMPLEMENTATION_ROLE,
  ADMIN_ROLE,
  TC_ADMIN_ROLE,
  VIEWER_ROLE,
];
