import { parsePhoneNumber } from 'react-phone-number-input';

export const getCountryCallingCodeAndNationalNumber = (phone) => {
  const parsedPhone = parsePhoneNumber(phone);

  return {
    countryCallingCode: `+${parsedPhone?.countryCallingCode}-`,
    nationalNumber: parsedPhone?.nationalNumber,
  };
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let result = phoneNumber.replace(/\D/g, '');

  if (result.length === 10) {
    result = `${result.substring(0, 3)}-${result.substring(
      3,
      6,
    )}-${result.substring(6, 10)}`;
  } else if (result.length === 11 && result.startsWith('1')) {
    result = `+${result.substring(0, 1)}-${result.substring(
      1,
      4,
    )}-${result.substring(4, 7)}-${result.substring(7, 11)}`;
  }

  return result;
};
