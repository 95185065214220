import { useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';

interface TabletLinkProps {
  children: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const TabletLink: React.FC<TabletLinkProps> = ({ children, icon, onClick }) => {
  const { textColor } = useAppSelector(getTheme);
  const text =
    children?.length >= 18 ? `${children?.slice(0, 18)}...` : children;

  return (
    <button
      onClick={onClick}
      className="flex min-w-[186px] flex-row justify-between rounded-full items-center max-w-[360px] py-2 pr-4 pl-[10px] border-[1px] border-gray-300 hover:bg-gray-50"
    >
      {icon}
      <span
        style={{ color: textColor.primary.base }}
        className="text-base leading-6 font-medium ml-3"
      >
        {text}
      </span>
    </button>
  );
};

export default TabletLink;
