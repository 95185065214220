import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { hasPermission } from 'pages/dashboard/selectors';
import { CommunicationIcon } from 'components/icons';
import { Permissions } from 'entities/dashboard';
import Modal from 'components/Modal';
import { AccountForm, ProvidersList } from './components';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  fetchAllConnectors,
  addErpAccount,
  updateErpAccount,
  fetchErpAccounts,
} from './thunks';
import {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
} from 'components/InvitePersons/invitePersonsSlice';
import AccountInfo from './components/AccountInfo';
import { getModalActions, getModalTitle } from './components/Buttons';
import { FilterType } from 'entities/accounting';
import {
  getProvider,
  getErpAccount,
  getSteps,
  getIsLoading,
  getUpdateIsLoading,
  getShowSteps,
  getIsAccountingOpen,
} from './selectors';
import {
  setProvider as setCurrentProvider,
  setStep,
  resetAccountingModal,
} from './accountingSlice';
import { getBusiness } from 'pages/Profile/selectors';

const AccountingModal = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const isOpen: boolean = useAppSelector(getIsAccountingOpen);
  const currentProvider = useAppSelector(getProvider);
  const updateIsLoading = useAppSelector(getUpdateIsLoading);
  const isLoading = useAppSelector(getIsLoading);
  const step = useAppSelector(getSteps);
  const account = useAppSelector(getErpAccount);
  const showSteps = useAppSelector(getShowSteps);
  const { businessLegalName } = useAppSelector(getBusiness);
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const {
    register,
    reset,
    handleSubmit: handleSubmitForm,
    formState: { errors },
  } = useForm<FieldValues>({ defaultValues: {} });

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAllConnectors(FilterType.erp));
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    const { jsone } = account;
    if (isOpen && !updateIsLoading) {
      const jsonData = jsone ? JSON.parse(jsone) : jsone;
      const formData = jsonData?.reduce(
        (acc, { key, value }) => ({ ...acc, [key]: value }),
        {},
      );
      reset(formData ?? {});
    }
  }, [account, reset, isOpen, updateIsLoading]);

  const handleStep = (nextStep) => dispatch(setStep(nextStep));

  const handleSubmitAccountsForm = async (data) => {
    const { erpaccountsId, createdBy } = account;
    let result = { error: true };

    if (erpaccountsId) {
      result = await dispatch(
        updateErpAccount({
          formData: data,
          erpaccountsId: erpaccountsId,
          createdBy,
        }),
      );
    } else {
      result = await dispatch(addErpAccount(data));
    }

    if (!result.error) {
      handleStep(3);
    }
  };

  const handleProvider = (value) => {
    dispatch(setCurrentProvider(value));
  };

  const closeModal = () => {
    dispatch(resetAccountingModal());
    dispatch(fetchErpAccounts());
  };

  const handleInvite = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(true));
  };

  const { erpaccountsId } = account;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="50rem"
      height={step === 3 ? '100%' : 'auto'}
      maxHeight={step === 3 ? '650px' : ''}
      title={getModalTitle({
        hide: false,
        t,
        handleInvite,
        step,
        showSteps,
        name: businessLegalName,
        invitePermissions,
      })}
      actions={getModalActions({
        erpaccountsId,
        handleSubmitForm,
        onSubmit: handleSubmitAccountsForm,
        step,
        setStep: handleStep,
        provider: currentProvider,
        updateIsLoading,
        closeModal,
        showSteps,
      })}
      icon={<CommunicationIcon className="w-6 h-6 text-gray-500" />}
      zIndex={25}
    >
      {step === 1 && (
        <ProvidersList
          selectedErpConnectorId={currentProvider.connectorId}
          isLoading={isLoading}
          onChange={handleProvider}
        />
      )}
      {step === 2 && (
        <AccountForm
          provider={currentProvider}
          register={register}
          account={account}
          errors={errors}
        />
      )}
      {step === 3 && (
        <AccountInfo
          account={account}
          provider={currentProvider}
          onClose={closeModal}
          showSteps={showSteps}
        />
      )}
    </Modal>
  );
};

export default AccountingModal;
