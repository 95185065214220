import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { MailIcon, PhoneIcon } from 'components/icons';
import { Input, PhoneInput } from 'components/Inputs';
import { Permissions } from 'entities/dashboard';

import { getShowErrors } from '../../Progress/selectors';
import { updateFormValues } from '../merchantSlice';

const ContactInfo = ({ register, control, watch }) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );
  const showErrors: any = useAppSelector(getShowErrors);

  const contact = watch('contact');

  useEffect(() => {
    dispatch(
      updateFormValues({
        contact: {
          firstName: contact.firstName,
          lastName: contact.lastName,
          phone: contact.phone,
          email: contact.email,
        },
      }),
    );
  }, [
    contact.firstName,
    contact.lastName,
    contact.phone,
    contact.email,
    dispatch,
  ]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-6 mb-5">
        <Input
          label={t('common:fields.firstname.label')}
          name="contact.firstName"
          register={register}
          className="w-full"
          readOnly={!financialPermissions.edit}
          error={
            showErrors.financial &&
            !contact.firstName && {
              message: t('common:validations.required', {
                name: t('common:fields.firstname.label'),
              }),
            }
          }
        />
        <Input
          label={t('common:fields.lastname.label')}
          name="contact.lastName"
          register={register}
          className="w-full"
          readOnly={!financialPermissions.edit}
          error={
            showErrors.financial &&
            !contact.lastName && {
              message: t('common:validations.required', {
                name: t('common:fields.lastname.label'),
              }),
            }
          }
        />
      </div>
      <PhoneInput
        label={t('merchantServices.configuration.contact.phone.label')}
        placeholder={t(
          'merchantServices.configuration.contact.phone.placeholder',
        )}
        name="contact.phone"
        className="w-full mb-5"
        control={control}
        iconStart={<PhoneIcon className="text-gray-400" />}
        readOnly={!financialPermissions.edit}
        error={
          showErrors.financial &&
          !contact.phone && {
            message: t('common:validations.required', {
              name: t('merchantServices.configuration.contact.phone.label'),
            }),
          }
        }
      />
      <Input
        register={register}
        label={t('merchantServices.configuration.contact.email.label')}
        placeholder={t(
          'merchantServices.configuration.contact.email.placeholder',
        )}
        name="contact.email"
        className="w-full"
        iconStart={<MailIcon className="text-gray-400" />}
        readOnly={!financialPermissions.edit}
        error={
          showErrors.financial &&
          !contact.email && {
            message: t('common:validations.required', {
              name: t('merchantServices.configuration.contact.email.label'),
            }),
          }
        }
      />
    </div>
  );
};

export default ContactInfo;
