import InputMask from 'react-input-mask-format';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import ErrorIcon from 'components/Inputs/components/ErrorIcon';
import Label from 'components/Inputs/components/Label';

const SocialNumberInput = ({ control, error, disabled }) => {
  const { t } = useTranslation('ownership');

  return (
    <div className="w-full my-6">
      <Label>{t('fields.idnumber.label')}</Label>

      <div className="relative flex">
        <Controller
          name="idnumber"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <InputMask
                mask="999-99-9999"
                maskPlaceholder={null}
                value={value}
                placeholder={t('fields.idnumber.placeholder')}
                onChange={onChange}
                disabled={disabled}
                className={classNames(
                  'w-full shadow-sm border focus-visible:outline-none border-gray-300 py-2 px-3 placeholder-gray-400 rounded-md',
                  { 'bg-gray-100': disabled },
                  error
                    ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900'
                    : 'border-gray-300 focus:ring-0 focus:border-indigo-500',
                )}
              />
            );
          }}
        />

        {error && <ErrorIcon />}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export default SocialNumberInput;
