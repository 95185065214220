import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import accountIcon from 'assets/images/accounting-software.png';
import bankIcon from 'assets/images/bank-integrator.png';

const data = {
  account: {
    icon: accountIcon,
    text: 'connectors.successAccountText',
  },
  bank: {
    icon: bankIcon,
    text: 'connectors.successBankText',
  },
};

export default function SuccessfulConnection({
  type,
  onClick,
}: {
  type: 'account' | 'bank';
  onClick: () => void;
}) {
  const { t } = useTranslation('dashboard');

  return (
    <div className="px-6 pb-6 pt-14">
      <img
        alt="Accounting Software"
        src={data[type].icon}
        className="mx-auto mb-6"
      />
      <p className="heading-xl mb-2 text-center">
        {t('connectors.successTitle')}
      </p>
      <p className="text-secondary mb-6 text-center">{t(data[type].text)}</p>
      <Button type="button" className="w-full" onClick={onClick}>
        {t('common:done')}
      </Button>
    </div>
  );
}
