import { useTranslation } from 'react-i18next';

import { DataSourceWidgets, ERPConnector } from 'entities/accounting';
import ERPConnectorLogo from 'components/Logos/ERPConnectorLogo';
import ERPIcon from 'assets/icons/connectors/ERP.svg';
import { useAppSelector } from 'hooks';

import { getProviderList } from '../AccountingSoftware/selectors';
import DataSourceWidgetModal from './DataSourceWidgetModal';

const ERPWidgetModal = () => {
  const { t } = useTranslation('integration');

  const providers: ERPConnector[] = useAppSelector(getProviderList);

  return (
    <DataSourceWidgetModal
      id={DataSourceWidgets.erp}
      title="integration:dataSource.erpModalTitle"
      description={
        <div>
          <p>{t('integration:dataSource.erpModalDescription')}</p>
          <p className="my-2">
            {t('integration:dataSource.erpModalDescription2')}
          </p>
          {providers
            .filter((provider) => provider.isActive)
            .map((provider) => (
              <div
                className="flex items-center mb-2"
                key={provider.connectorId}
              >
                <span className="w-[5px] h-[5px] block rounded-full bg-blue-600 mr-2"></span>
                <div className="h-6 w-[100px]">
                  <ERPConnectorLogo
                    link={provider.logoFileShareUri}
                    alt={provider.name}
                    className="h-full w-auto"
                  />
                </div>
                <p className="text-xs text-gray-700 ml-1">{provider.name}</p>
              </div>
            ))}
        </div>
      }
      icon={ERPIcon}
    />
  );
};
export default ERPWidgetModal;
