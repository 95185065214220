import { get, rm } from 'lockr';

import { notify } from 'components/notification/notificationSlice';

export const showResponseError = (
  message: string | null,
  dispatch = null as any,
) => {
  const timeout = get('serverTimeout');

  if (timeout) {
    rm('serverTimeout');
    dispatch(
      notify({
        message: '504 Gateway Time-out',
        variant: 'error',
        translate: false,
      }),
    );
    return;
  }

  if (!message) {
    return;
  }

  if (dispatch) {
    dispatch(
      notify({
        message,
        variant: 'error',
        translate: false,
      }),
    );
  }
};
