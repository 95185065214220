import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';
import { formatPhoneNumber } from 'utils/phone';
import Modal from 'components/Modal';
import Link from 'components/Link';
import {
  ArrowLeftIcon,
  MailIcon,
  PhoneIcon,
  StarsIcon,
} from 'components/icons';

import { getDetailsModalOpened, getSelectedProvider } from '../selectors';
import { createFXProvider, deleteFXProvider } from '../thunks';
import FXProviderLogo from './FXProviderLogo';
import {
  FXProviderModalTypes,
  setDetailsModalOpened,
  setProvider,
} from '../fxProviderSlice';

const ProviderDetailsModal = () => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const detailsModal = useAppSelector(getDetailsModalOpened);
  const provider = useAppSelector(getSelectedProvider);
  const fxPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.fxProvider),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    dispatch(setDetailsModalOpened(''));
    dispatch(setProvider(null));
  };

  const handleCancel = async () => {
    setIsLoading(true);

    await dispatch(deleteFXProvider(provider?.businessCrossBorderProviderId));

    handleClose();
    setIsLoading(false);
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    await dispatch(
      createFXProvider({
        crossBorder_ProvidersId: provider?.id,
      }),
    );

    handleClose();
    setIsLoading(false);
  };

  if (!provider) {
    return null;
  }

  return (
    <Modal
      isOpen={detailsModal !== ''}
      closeModal={handleClose}
      maxWidth="744px"
      isOverlayDisabled
      closeTimeoutMS={0}
    >
      <>
        <div className="px-6 pb-8 pt-14">
          <FXProviderLogo
            alt={provider.name}
            id={provider.id}
            className="h-6"
          />
          <p className="text-2xl font-medium mt-6 mb-1">
            {t(`fxProvider.details.title.${detailsModal}`, {
              provider: provider.name,
            })}
          </p>
          <p className="text-secondary pb-4 mb-4 border-b border-gray-200 text-base">
            {t(`fxProvider.details.subTitle.${detailsModal}`, {
              provider: provider.name,
            })}
          </p>

          {detailsModal === FXProviderModalTypes.existed ? (
            [1, 2, 3].map((index) => (
              <div className="flex items-start mb-3" key={index}>
                <div className="bg-gray-400 rounded-full h-4 w-4 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <span className="text-xs font-medium text-white">
                    {index}
                  </span>
                </div>
                <p className="text-sm font-medium pl-2.5">
                  <Trans
                    i18nKey={`integration:fxProvider.details.step-${index}`}
                    values={{ provider: provider.name }}
                  >
                    text
                    <Link
                      href={provider.applicationUrl || null}
                      target="_blank"
                    >
                      link
                    </Link>
                    text
                  </Trans>
                </p>
              </div>
            ))
          ) : (
            <div className="pb-1">
              {[1, 2, 3].map((index) => (
                <p className="text-sm text-gray-700 mb-5" key={index}>
                  {t(`fxProvider.details.description-${index}`, {
                    provider: provider.name,
                  })}
                </p>
              ))}

              <div className="flex items-center justify-between p-5 bg-blue-50 rounded-lg relative overflow-hidden">
                <StarsIcon className="opacity-20 absolute w-[136px] h-[136px] text-blue-200 left-[-24px] z-0" />
                <StarsIcon className="opacity-20 absolute w-[136px] h-[136px] text-blue-200 top-0 right-0 z-0" />
                <div className="flex items-center z-10">
                  <StarsIcon className="text-blue-600 flex-shrink-0" />
                  <span className="ml-3 text-sm font-semibold">
                    {t('fxProvider.details.getStarted')}
                  </span>
                </div>
                <Link
                  href={provider.applicationUrl || null}
                  target="_blank"
                  className="z-10 flex items-center text-sm px-2 py-1"
                >
                  <span className="mr-1 font-semibold">
                    {t('fxProvider.details.setUp', { provider: provider.name })}
                  </span>
                  <ArrowLeftIcon className="h-4.5 w-4.5 rotate-180" />
                </Link>
              </div>
            </div>
          )}

          <div className="mt-10 mb-6">
            <p className="font-medium pb-3 mb-6 border-b border-gray-200">
              {t('fxProvider.details.contact')}
            </p>
            <div className="flex items-center">
              <div className="flex items-center">
                <PhoneIcon className="text-gray-500" />
                <span className="ml-2 text-sm text-gray-900">
                  {formatPhoneNumber(
                    `${provider.countryCallingCode}${provider.telephoneNumber}`,
                  )}
                </span>
              </div>
              <div className="w-px h-5 mx-6 bg-gray-200" />
              <div className="flex items-center">
                <MailIcon className="text-gray-500" />
                <span className="ml-2 text-sm text-gray-900">
                  {provider.emailAddress}
                </span>
              </div>
            </div>
          </div>

          <div className="p-3 bg-gray-50 text-xs text-gray-600">
            {t('fxProvider.details.footer', { provider: provider.name })}
          </div>
        </div>

        <div className="px-6 py-5 flex items-center justify-end gap-4 border-t border-gray-200">
          {provider.crossBorder_ProvidersId ? (
            <PermissionSection
              permission={Permissions.fxProvider}
              edit
              showPopover
            >
              <Button
                variant={ButtonVariant.secondaryOutline}
                disabled={!fxPermissions.edit}
                size={ButtonSize.medium}
                heightClass="h-8"
                paddingClass="px-2.5"
                onClick={handleCancel}
                loading={isLoading}
              >
                <span className="font-semibold text-red-600">
                  {t('fxProvider.details.cancel')}
                </span>
              </Button>
            </PermissionSection>
          ) : (
            <>
              <Button
                variant={ButtonVariant.secondaryOutline}
                size={ButtonSize.medium}
                heightClass="h-8"
                paddingClass="px-2.5"
                onClick={handleClose}
              >
                <span className="font-semibold">{t('common:cancel')}</span>
              </Button>
              <PermissionSection
                permission={Permissions.fxProvider}
                edit
                showPopover
              >
                <Button
                  size={ButtonSize.medium}
                  disabled={!fxPermissions.edit}
                  heightClass="h-8"
                  paddingClass="px-2.5"
                  onClick={handleConfirm}
                  loading={isLoading}
                >
                  <span className="font-semibold">{t('common:confirm')}</span>
                </Button>
              </PermissionSection>
            </>
          )}
        </div>
      </>
    </Modal>
  );
};

export default ProviderDetailsModal;
