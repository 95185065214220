export enum DomainType {
  paya = 'paya',
  freightx = 'freightx',
  invoicecloud = 'invoicecloud',
  smartHub = 'smartHub',
}

const defaultSubDomain = 'uat.smart-enroll.com';

export const getSubDomain = () => {
  if (['localhost', '127.0.0.1', ''].includes(window.location.hostname)) {
    return defaultSubDomain;
  }

  return window.location.host;
};

export const domain = () => {
  const domainKeys: string[] = Object.keys(DomainType);
  const subDomain: string = getSubDomain();

  return (
    domainKeys.find((key) => subDomain.includes(key)) ?? DomainType.smartHub
  );
};

export const setFavicon = (href: string) => {
  const favicon: HTMLLinkElement | null =
    document.querySelector('link[rel~="icon"]');

  if (favicon) {
    favicon.href = href;
  }
};

export const isUATDomain = () => {
  return getSubDomain().indexOf('uat') === 0;
};
