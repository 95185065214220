import { Trans, useTranslation } from 'react-i18next';

import Link from 'components/Link';
import path from 'common/path';

import LastUpdated from './LastUpdated';
import Typography from './Typography';
import SubTitle from './SubTitle';
import Title from './Title';

const TranscardSmartSuite = () => {
  const { t } = useTranslation('terms-of-use');

  return (
    <div>
      <LastUpdated />
      <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-6 sm:mb-12">
        <Trans
          ns="terms-of-use"
          i18nKey="main.title"
          components={{ 1: <span className="text-blue-600" /> }}
        />
      </h2>
      <div className="mb-12">
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.terms-p-1"
            components={{
              1: <b />,
              2: (
                <Link
                  href="mailto:sales@transcard.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.terms-p-2"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.terms-p-3"
            components={{
              1: <b />,
              2: (
                <Link
                  href={path.privacy}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>{t('main.terms-p-4')}</Typography>
        <Typography last>{t('main.terms-p-5')}</Typography>

        <Title>{t('main.service')}</Title>
        <SubTitle>{t('main.service-a-t')}</SubTitle>
        <Typography>{t('main.service-a-p')}</Typography>
        <SubTitle>{t('main.service-b-t')}</SubTitle>
        <Typography>{t('main.service-b-p-1')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.service-b-p-2"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>{t('main.service-b-p-3')}</Typography>
        <SubTitle>{t('main.service-c-t')}</SubTitle>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.service-c-p-1"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>{t('main.service-c-p-2')}</Typography>
        <Typography>{t('main.service-c-p-3')}</Typography>
        <SubTitle>{t('main.service-d-t')}</SubTitle>
        <Typography>{t('main.service-d-p-1')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.service-d-p-2"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>{t('main.service-d-p-3')}</Typography>
        <SubTitle>{t('main.service-e-t')}</SubTitle>
        <Typography>{t('main.service-e-p-1')}</Typography>
        <Typography>{t('main.service-e-p-2')}</Typography>
        <Typography>{t('main.service-e-p-3')}</Typography>
        <SubTitle>{t('main.service-f-t')}</SubTitle>
        <Typography>{t('main.service-f-p')}</Typography>
        <SubTitle>{t('main.service-g-t')}</SubTitle>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.service-g-p"
            components={{
              1: (
                <Link
                  href="https://pricing.smart-hub.com"
                  target="_blank"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>

        <Title>{t('main.user-t')}</Title>
        <Typography>{t('main.user-p-1')}</Typography>
        <Typography>{t('main.user-p-2')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.user-p-3"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography last>{t('main.user-p-4')}</Typography>
        <Title>{t('main.rights-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rights-p-1"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rights-p-2"
            components={{ 1: <b /> }}
          />
        </Typography>

        <Title>{t('main.privacy-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.privacy-p-1"
            components={{
              1: (
                <Link
                  href={path.privacy}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
              2: (
                <Link
                  href="https://www.transcard.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>{t('main.privacy-p-2')}</Typography>
        <Typography last>{t('main.privacy-p-3')}</Typography>

        <Title>{t('main.security-t')}</Title>
        <Typography last>{t('main.security-p')}</Typography>

        <Title>{t('main.copyright-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-p-1"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-a"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-b"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-c"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-d"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-e"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-f"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>{t('main.copyright-p-2')}</Typography>
        <Typography>{t('main.copyright-p-3')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.copyright-p-4"
            components={{
              1: (
                <Link
                  href="mailto:support@smart-hub.com"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>{t('main.copyright-p-5')}</Typography>
        <Typography>{t('main.copyright-p-6')}</Typography>
        <Typography>{t('main.copyright-p-7')}</Typography>

        <Title>{t('main.links-t')}</Title>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.links-p"
            components={{
              1: (
                <Link
                  href={path.privacy}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>

        <Title>{t('main.bank-t')}</Title>
        <Typography>{t('main.bank-p-1')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-a"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-b"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-c"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-d"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-e"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-f"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-g"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-h"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-i"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-j"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-k"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-l"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-m"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-n"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-o"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-p"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.bank-q"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>

        <Title>{t('main.indemnity-t')}</Title>
        <Typography last>{t('main.indemnity-p')}</Typography>

        <Title>{t('main.warranty-t')}</Title>
        <Typography>{t('main.warranty-p-1')}</Typography>
        <Typography>{t('main.warranty-p-2')}</Typography>
        <Typography last>{t('main.warranty-p-3')}</Typography>

        <Title>{t('main.liability-t')}</Title>
        <Typography>{t('main.liability-p-1')}</Typography>
        <Typography>{t('main.liability-p-2')}</Typography>
        <Typography>{t('main.liability-p-3')}</Typography>
        <Typography last>{t('main.liability-p-4')}</Typography>

        <Title>{t('main.law-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.law-a"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.law-b"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.law-c"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>

        <Title>{t('main.app-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.app-a"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.app-b"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>

        <Title>{t('main.rewards-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-p"
            components={{
              1: <b />,
              2: (
                <Link
                  href="https://www.dosh.com/Transcard-rewards-powered-by-dosh-terms"
                  target="_blank"
                  className="font-medium"
                />
              ),
            }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-a-1"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>{t('main.rewards-a-2')}</Typography>
        <Typography>{t('main.rewards-a-3')}</Typography>
        <Typography>{t('main.rewards-a-4')}</Typography>
        <Typography>{t('main.rewards-a-5')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-b-1"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>{t('main.rewards-b-2')}</Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-b-3"
            components={{ 1: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-c"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-d"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.rewards-e-1"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>{t('main.rewards-e-2')}</Typography>
        <Typography>{t('main.rewards-e-3')}</Typography>
        <Typography>{t('main.rewards-e-4')}</Typography>
        <Typography>{t('main.rewards-e-5')}</Typography>
        <Typography>{t('main.rewards-e-6')}</Typography>
        <Typography last>{t('main.rewards-e-7')}</Typography>

        <Title>{t('main.general-t')}</Title>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-a"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-b"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-c"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-d"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-e"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-f"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-g"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-h"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-i"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-j"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-k"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-l"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-m"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-1"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-2"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-3"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-4"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-5"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-6"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-7"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-8"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-9"
              components={{ 1: <b /> }}
            />
          </Typography>
          <Typography>
            <Trans
              ns="terms-of-use"
              i18nKey="main.general-m-10"
              components={{ 1: <b /> }}
            />
          </Typography>
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-n"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-o"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-p"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-q"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
        <Typography last>
          <Trans
            ns="terms-of-use"
            i18nKey="main.general-r"
            components={{ 1: <b className="mr-4" />, 2: <b /> }}
          />
        </Typography>
      </div>
    </div>
  );
};

export default TranscardSmartSuite;
