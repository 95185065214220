export const AngleLeftIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.0896 5.59056C13.415 5.26512 13.415 4.73748 13.0896 4.41205C12.7641 4.08661 12.2365 4.08661 11.9111 4.41205L6.91107 9.41205C6.59559 9.72753 6.58455 10.2355 6.88603 10.5644L11.4694 15.5644C11.7804 15.9037 12.3075 15.9266 12.6468 15.6156C12.986 15.3046 13.0089 14.7775 12.698 14.4382L8.65377 10.0264L13.0896 5.59056Z"
      fill="#6B7280"
    />
  </svg>
);
