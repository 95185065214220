interface TabletTextProps {
  children: string;
}

const TabletText: React.FC<TabletTextProps> = ({ children }) => {
  const text =
    children?.length >= 17 ? `${children?.slice(0, 17)}...` : children;
  return <span className="text-base leading-6 font-medium">{text}</span>;
};

export default TabletText;
