import { CrossBorderSchema } from 'services/businesses';
import { calcProcentege } from 'pages/dashboard/utils';

export const calcTransactionalPercentage = (
  data: Partial<CrossBorderSchema> | null,
) => {
  const {
    transactionEstimate12Month,
    transactionAverage,
    transactionHigh,
    transactionPurpose,
    regulatedActivities,
  } = data || {};

  return calcProcentege({
    transactionEstimate12Month,
    transactionAverage,
    transactionHigh,
    transactionPurpose,
    regulatedActivities: regulatedActivities !== null,
  });
};
