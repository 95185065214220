import { useTranslation } from 'react-i18next';

import ProgressProducts from 'pages/dashboard/components/Progress/components/ProgressProducts';
import CompleteSmarter from 'pages/dashboard/components/CompleteSmarter';
import { ProductTypeCodes } from 'entities/progress';

const Connectors = () => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="p-6">
      <div>
        <p className="heading-2xl mb-9 font-bold">
          {t('dashboard:submitModal.connectorsTitle')}
        </p>
        <p className="heading-lg mb-1">
          {t('dashboard:submitModal.connectorsSubTitle')}
        </p>
        <p className="text-secondary pb-5 border-b">
          {t('dashboard:submitModal.connectorsText')}
        </p>

        <div className="flex flex-wrap pb-15">
          <ProgressProducts filter={[ProductTypeCodes.services]} inline />
        </div>
      </div>

      <CompleteSmarter
        className="border border-yellow-500 ring-2 ring-orange-100"
        showModalTitle
      />
    </div>
  );
};

export default Connectors;
