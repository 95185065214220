import { useMsal, useIsAuthenticated } from '@azure/msal-react';

const useCurrentUser = () => {
  const { accounts, inProgress, instance } = useMsal();

  return {
    user: accounts[0],
    loading: inProgress !== 'none',
    isAuthenticated: useIsAuthenticated(),
    logoutRedirect: () =>
      instance.logoutRedirect({
        onRedirectNavigate: () => false,
      }),
    logoutWithoutRedirect: () => instance.logout(),
  };
};

export default useCurrentUser;
