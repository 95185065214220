import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import { InfoCircleSolidIcon, PictureIcon, TrashIcon } from 'components/icons';
import { hasPermission } from 'pages/dashboard/selectors';
import PopoverOnHover from 'components/PopoverOnHover';
import Button, { ButtonSize } from 'components/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getBusiness } from 'pages/Profile/selectors';
import { updateBusiness } from 'pages/Profile/thunks';
import { UploadFile } from 'components/uploadFile';
import { Permissions } from 'entities/dashboard';
import { AcceptFormat } from 'common/files';
import Loading from 'components/Loading';

enum LoadingTypes {
  faviconFileShareURI = 'faviconFile',
  logoFileShareURI = 'logoFile',
}

const CompanyImages = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const { logoFileShareURI, faviconFileShareURI } = useAppSelector(getBusiness);
  const onboardingPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.onboardingBusiness),
  );

  const [loading, setLoading] = useState({
    faviconFile: false,
    logoFile: false,
  });

  const handleUpload = async (type: string, files: FileList) => {
    if (files.length) {
      setLoading({ ...loading, [type]: true });

      await dispatch(
        updateBusiness({
          [type]: files[0],
        }),
      );

      setLoading({ ...loading, [type]: false });
    }
  };

  const closeConfirmationDialog = () => {
    dispatch(closeDialog());
  };

  const handleDelete = async (type, inputRef) => {
    inputRef.current.value = '';
    setLoading({ ...loading, [LoadingTypes[type]]: true });

    await dispatch(
      updateBusiness({
        [type]: '',
      }),
    );

    setLoading({ ...loading, [LoadingTypes[type]]: false });
  };

  const confirmDelete = async (type: string, inputRef) => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: closeConfirmationDialog,
        onCancel: closeConfirmationDialog,
        content: (
          <DangerDialog
            title={t('common:confirmationText')}
            buttonText={t('common:remove')}
            onCancel={closeConfirmationDialog}
            onConfirm={() => {
              handleDelete(type, inputRef);
              closeConfirmationDialog();
            }}
          />
        ),
      }),
    );
  };

  return (
    <div className="border-b border-gray-200 py-5">
      <div className="flex -mx-8">
        <div className="w-full mx-8 flex flex-col">
          <div className="flex items-center justify-between mb-1">
            <span className="text-gray-700 font-medium text-sm flex items-center">
              {t('dashboard:businessInfo.companyIcon')}
              <PopoverOnHover
                button={
                  <InfoCircleSolidIcon className="w-5 h-5 text-gray-400" />
                }
                info={t('dashboard:businessInfo.companyIconInfo')}
                className="ml-1"
              />
            </span>
            <span className="text-secondary font-medium">
              {t('common:labelOptional')}
            </span>
          </div>
          <p className="text-secondary text-xs">
            {t('dashboard:businessInfo.companyIconText')}
          </p>
          <div className="flex flex-wrap items-center">
            {faviconFileShareURI ? (
              <div className="flex flex-shrink-0 items-center justify-center rounded-full w-12 h-12 mr-5 mt-4 relative">
                {loading.faviconFile && <Loading transparent />}
                <img
                  alt="Icon"
                  src={faviconFileShareURI}
                  className="max-h-full"
                />
              </div>
            ) : (
              <div className="mt-4 flex flex-shrink-0 items-center justify-center rounded-full bg-gray-50 border-gray-300 border-dashed border w-12 h-12 mr-5 relative">
                {loading.faviconFile && <Loading transparent />}
                <PictureIcon className="text-gray-500" />
              </div>
            )}

            <PermissionSection
              permission={Permissions.onboardingBusiness}
              showPopover
              edit
            >
              <div className="flex items-center mt-4">
                <UploadFile
                  uploadFiles={[]}
                  onUpload={(files) => handleUpload('faviconFile', files)}
                  button={
                    <span className="ml-2 block text-sm leading-4 font-medium border text-gray-700 self-center py-2 px-3 rounded-md shadow-sm">
                      {t(`common:${faviconFileShareURI ? 'change' : 'upload'}`)}
                    </span>
                  }
                  className="flex items-center flex-row-reverse"
                  acceptFormat={[
                    AcceptFormat.jpeg,
                    AcceptFormat.jpg,
                    AcceptFormat.png,
                    AcceptFormat.svg,
                  ]}
                  validations={{
                    imageSize: '16x16',
                  }}
                  disabled={!onboardingPermissions.edit}
                >
                  {({ inputRef }) =>
                    faviconFileShareURI ? (
                      <Button
                        variant="link-red"
                        className="border border-red-400 h-[34px] ml-5"
                        paddingClass="px-3"
                        onClick={() =>
                          confirmDelete('faviconFileShareURI', inputRef)
                        }
                        disabled={!onboardingPermissions.edit}
                        size={ButtonSize.small}
                      >
                        <TrashIcon className="mr-2 text-red-400 w-4 h-4" />
                        <span className="text-red-500 text-sm">
                          {t('common:remove')}
                        </span>
                      </Button>
                    ) : null
                  }
                </UploadFile>
              </div>
            </PermissionSection>
          </div>
        </div>
        <div className="w-full mx-8 flex flex-col">
          <div className="flex justify-between mb-1">
            <span className="text-gray-700 font-medium text-sm flex items-center">
              {t('dashboard:businessInfo.companyLogo')}
              <PopoverOnHover
                button={
                  <InfoCircleSolidIcon className="w-5 h-5 text-gray-400" />
                }
                info={t('dashboard:businessInfo.companyLogoInfo')}
                className="ml-1"
              />
            </span>
            <span className="text-secondary font-medium">
              {t('common:labelOptional')}
            </span>
          </div>
          <p className="text-secondary text-xs">
            {t('dashboard:businessInfo.companyLogoText')}
          </p>
          <div className="flex flex-wrap items-center">
            {logoFileShareURI ? (
              <div className="flex flex-shrink-0 items-center justify-center w-full max-w-[195px] h-12 mr-5 mt-4 relative">
                {loading.logoFile && <Loading transparent />}
                <img alt="Logo" src={logoFileShareURI} className="max-h-full" />
              </div>
            ) : (
              <div className="mt-4 flex items-center justify-center rounded-[10px] bg-gray-50 border-gray-300 border-dashed border pt-[7px] pb-[11px] w-full max-w-[195px] mr-5 relative">
                {loading.logoFile && <Loading transparent />}
                <PictureIcon className="text-gray-500" />
              </div>
            )}

            <PermissionSection
              permission={Permissions.onboardingBusiness}
              showPopover
              edit
            >
              <div className="flex items-center mt-4">
                <UploadFile
                  uploadFiles={[]}
                  onUpload={(files) => handleUpload('logoFile', files)}
                  button={
                    <span className="ml-2 block text-sm leading-4 font-medium border text-gray-700 self-center py-2 px-3 rounded-md shadow-sm">
                      {t(`common:${logoFileShareURI ? 'change' : 'upload'}`)}
                    </span>
                  }
                  disabled={!onboardingPermissions.edit}
                  className="flex items-center flex-row-reverse"
                  acceptFormat={[
                    AcceptFormat.jpeg,
                    AcceptFormat.jpg,
                    AcceptFormat.png,
                    AcceptFormat.svg,
                  ]}
                  validations={{
                    imageSize: 'x512',
                  }}
                >
                  {({ inputRef }) =>
                    logoFileShareURI ? (
                      <Button
                        variant="link-red"
                        className="border border-red-400 h-[34px] ml-5"
                        paddingClass="px-3"
                        onClick={() =>
                          confirmDelete('logoFileShareURI', inputRef)
                        }
                        disabled={!onboardingPermissions.edit}
                        size={ButtonSize.small}
                      >
                        <TrashIcon className="mr-2 text-red-400 w-4 h-4" />
                        <span className="text-red-500 text-sm">
                          {t('common:remove')}
                        </span>
                      </Button>
                    ) : null
                  }
                </UploadFile>
              </div>
            </PermissionSection>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyImages;
