import React from 'react';

import { getTheme } from 'theme/selectors';
import { useAppSelector } from 'hooks';

type ProgressLineProps = {
  progress: number;
  height: number;
  rounded?: 'full' | 'none';
};

const ProgressLine: React.FC<ProgressLineProps> = ({
  progress,
  height,
  rounded = 'full',
}) => {
  const { backgroundColor } = useAppSelector(getTheme);

  return (
    <div className={`bg-blue-100 rounded-${rounded} overflow-hidden w-full`}>
      <div
        className={`rounded-${rounded} transition-width duration-700 ease-linear`}
        style={{
          width: `${progress}%`,
          height: `${height}px`,
          backgroundColor: backgroundColor.progressLine.primary.base,
        }}
      />
    </div>
  );
};

export default ProgressLine;
