import { FormEvent, useState, ReactNode } from 'react';
import PNInput from 'react-phone-number-input/input';
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import classNames from 'classnames';
import { Controller, Control } from 'react-hook-form';
import { CountryCode } from 'libphonenumber-js/types';
import { useTranslation } from 'react-i18next';

import { ExclamationCircleIcon, InfoCircleSolidIcon } from 'components/icons';
import PopoverOnHover from 'components/PopoverOnHover';
import Countries from './Countries';
import { useAppSelector } from 'hooks/redux';
import { getPhoneCountries } from 'pages/login/selectors';
import { InputProps } from 'entities/inputs';
import { getTheme } from 'theme/selectors';
import ErrorMessage from './components/ErrorMessage';

export enum CountryCallingCodes {
  US = '1',
}

interface PhoneInputProps extends Omit<InputProps, 'onChange'> {
  control?: Control;
  country?: CountryCode;
  iconEnd?: ReactNode;
  iconStart?: ReactNode;
  disabled?: boolean;
  validation?: any;
  selectCountry?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  control,
  label,
  info,
  className,
  error,
  optional,
  country = 'US',
  iconEnd,
  iconStart,
  disabled,
  validation = {},
  selectCountry = false,
  ...otherProps
}) => {
  const { borderColor } = useAppSelector(getTheme);

  const { t } = useTranslation();
  const [code, setCode] = useState<CountryCode>(country);
  const [codeError, setCodeError] = useState<boolean>(false);
  const countries = useAppSelector(getPhoneCountries);

  const handleChangeCountry = (e: FormEvent<HTMLSelectElement>) => {
    setCode(e.currentTarget.value as CountryCode);
  };

  const handleChange = (value, callback) => {
    const val = value || '';
    const parsedPhone = parsePhoneNumber(val);

    const isWrongCode =
      parsedPhone &&
      CountryCallingCodes[code] !== parsedPhone.countryCallingCode;
    const isValidPhone = !val || (val && isPossiblePhoneNumber(val));

    setCodeError(isWrongCode || !isValidPhone);

    callback(val);
  };

  return (
    <div className={className}>
      {label && (
        <div className="flex justify-between items-center mb-1">
          <label className="text-sm leading-5 font-medium text-gray-700">
            {label}
          </label>
          {info && (
            <PopoverOnHover
              info={info}
              button={<InfoCircleSolidIcon className="text-gray-400" />}
            />
          )}
          {optional && (
            <span className="text-sm leading-5 font-medium text-gray-500">
              {t('common:labelOptional')}
            </span>
          )}
        </div>
      )}

      <div className="relative bg-gray-100">
        {iconStart && (
          <div className="absolute pl-3 inset-y-0 left-0 flex items-center pointer-events-none">
            {iconStart}
          </div>
        )}
        {selectCountry && (
          <Countries
            handleChangeCountry={handleChangeCountry}
            code={code}
            countries={countries}
            disabled={disabled}
          />
        )}
        {iconStart && (
          <div
            className={classNames(
              'absolute inset-y-0 left-0 flex items-center pointer-events-none pl-3',
            )}
          >
            {iconStart}
          </div>
        )}
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field: { value, onChange } }) => (
            <PNInput
              value={value}
              onChange={(val) => handleChange(val, onChange)}
              defaultCountry={code}
              className={classNames(
                'rounded-md w-full shadow-sm border border-gray-300 py-[7px] placeholder-gray-400',
                {
                  'pr-10': iconEnd,
                  'pl-10': iconStart,
                  'bg-gray-100 focus:border-gray-300 cursor-default':
                    disabled || otherProps.readOnly,
                  'pl-16': selectCountry,
                },
                error || codeError
                  ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 placeholder-red-300'
                  : `pr-3 border-gray-300 focus:ring-0 ${borderColor.primary.focus}`,
              )}
              placeholder={countries.find((c) => c.code === code)?.placeholder}
              disabled={disabled}
              style={{
                ':focus': {
                  borderColor:
                    error || codeError ? '' : borderColor.primary.focus,
                },
              }}
              {...otherProps}
            />
          )}
        />
        {(error || codeError) && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="text-red-500" />
          </div>
        )}

        {iconEnd && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pointer-events-none',
              error || codeError ? 'pr-9' : 'pr-3',
            )}
          >
            {iconEnd}
          </div>
        )}
      </div>

      {(error || codeError) && (
        <ErrorMessage
          error={
            codeError ? { message: t('common:validations.phoneCode') } : error
          }
        />
      )}
    </div>
  );
};

export default PhoneInput;
