import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { updateBusiness, sendSmartDisburse } from 'pages/Profile/thunks';
import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import WarningDialog from 'components/dialog/components/WarningDialog';
import { getBusiness, getPerson } from 'pages/Profile/selectors';
import { DoubleCheckIcon, PencilIcon } from 'components/icons';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ComplianceStatuses } from 'common/compliance';
import Autocomplete from 'components/Autocomplete';
import { Permissions } from 'entities/dashboard';
import { getProduct } from 'services/tpas';
import { Input } from 'components/Inputs';
import Button from 'components/Button';
import Steps from 'components/Steps';

const ProductSelect = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const { personsId } = useAppSelector(getPerson);
  const business = useAppSelector(getBusiness);
  const { smartDisburseProductsId, productValidatorPersonId } =
    useAppSelector(getBusiness);
  const implementationsPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.implementations),
  );

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [showConfirm, setShowConfirm] = useState(true);
  const [confirmed, setConfirmed] = useState(0);
  const [editable, setEditable] = useState(true);
  const [confirmedByMe, setConfirmedByMe] = useState(false);

  const fetchProduct = async (productId) => {
    try {
      const response: any = await getProduct(productId);

      return response.productId
        ? [
            {
              name: `${response.productName} - ${response.productId}`,
              value: response.productId,
            },
          ]
        : [];
    } catch (e) {
      return [];
    }
  };

  const debouncedLoadOptions = debounce(async (value, callback) => {
    const prod = await fetchProduct(value);
    callback(prod);
  }, 500);

  const loadOptions = (value, callback) => {
    if (value) {
      debouncedLoadOptions(value, callback);
    } else {
      callback([]);
    }
  };

  useEffect(() => {
    const setProduct = async (productId) => {
      const prod = await fetchProduct(productId);

      if (prod.length) {
        setSelected(prod[0]);
      } else {
        setSelected({
          name: productId,
          value: productId,
        });
      }
    };

    const totalConfirmed =
      (productValidatorPersonId ? 1 : 0) + (smartDisburseProductsId ? 1 : 0);

    setConfirmed(totalConfirmed);

    if (smartDisburseProductsId) {
      setProduct(smartDisburseProductsId);
    }

    if (totalConfirmed === 2) {
      setShowConfirm(false);
      setEditable(false);
    }
  }, [smartDisburseProductsId, productValidatorPersonId]);

  const handleChange = (option) => {
    setSelected(option);

    if (+option.value !== +smartDisburseProductsId) {
      setConfirmed(0);
      setShowConfirm(true);
      return;
    }

    setConfirmed(productValidatorPersonId ? 2 : 1);
    setShowConfirm(false);
  };

  const handleConfirm = async () => {
    setLoading(true);

    if (selected && confirmed === 0) {
      const result = await dispatch(
        updateBusiness({
          smartDisburseProductsId: +selected.value,
          productValidatorPersonId: 0,
        }),
      );

      if (!result.error) {
        setConfirmedByMe(true);
      }
    }

    if (confirmed === 1) {
      const result = await dispatch(
        updateBusiness({
          productValidatorPersonId: personsId,
        }),
      );

      if (!result.error) {
        setConfirmedByMe(true);

        if (business.complianceStatusID === ComplianceStatuses.approved) {
          dispatch(sendSmartDisburse());
        }
      }
    }

    setLoading(false);
  };

  const handleCloseDialog = () => dispatch(closeDialog());

  const handleEdit = () => {
    setEditable(true);
    setConfirmedByMe(false);
    handleCloseDialog();
  };

  const confirmEdit = () => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: handleCloseDialog,
        onCancel: handleCloseDialog,
        hideCross: false,
        content: (
          <WarningDialog
            title={t('selectProductId.title')}
            text={t('selectProductId.text')}
            buttonConfirmText={t('common:edit')}
            onContinue={handleEdit}
            onCancel={handleCloseDialog}
          />
        ),
      }),
    );
  };

  return (
    <PermissionSection permission={Permissions.implementations}>
      <div className="border-t border-gray-200 pt-4 mt-3">
        <PermissionSection
          permission={Permissions.implementations}
          showPopover
          fullWidth
          edit
        >
          {editable ? (
            <Autocomplete
              label={t('common:fields.productId.label')}
              placeholder={t('common:fields.productId.placeholder')}
              loadOptions={loadOptions}
              onChange={handleChange}
              value={selected}
              disabled={!implementationsPermissions.edit}
              className="w-full"
              async
            />
          ) : (
            <Input
              name="productId"
              value={`${selected?.name || ''}`}
              label={t('common:fields.productId.label')}
              suffixClass="pl-0 pr-0"
              suffix={
                implementationsPermissions.edit ? (
                  <button
                    className="flex items-center pl-3 pr-4 text-gray-700 font-medium"
                    onClick={confirmEdit}
                  >
                    <PencilIcon className="mr-2 text-gray-400" />
                    {t('common:edit')}
                  </button>
                ) : null
              }
              readOnly
            />
          )}
        </PermissionSection>

        {selected && (
          <div className="mt-3 flex items-center justify-between">
            {showConfirm && !confirmedByMe ? (
              <PermissionSection
                permission={Permissions.implementations}
                showPopover
                edit
              >
                <Button
                  size="medium"
                  paddingClass="px-4"
                  onClick={handleConfirm}
                  loading={loading}
                  disabled={!implementationsPermissions.edit}
                >
                  {t('common:confirm')}
                </Button>
              </PermissionSection>
            ) : (
              <div className="flex items-center bg-blue-50 text-blue-600 pl-[15px] pr-[17px] py-[9px] rounded-[6px] text-sm font-medium">
                <DoubleCheckIcon className="mr-2" />
                {t('common:confirmed', { value: confirmed, total: 2 })}
              </div>
            )}
            <Steps
              step={confirmed + 1}
              stepsLength={2}
              showLabel={false}
              check
            />
          </div>
        )}
      </div>
    </PermissionSection>
  );
};

export default ProductSelect;
