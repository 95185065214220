import { useEffect } from 'react';

import { setOnboardingTab } from 'pages/dashboard/dashboardSlice';
import { OnboardingTabCodes } from 'entities/dashboard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { OnboardingTab } from 'pages/dashboard/utils';
import Tabs from 'components/Tabs';
import {
  clearNewProductType,
  setShowErrors,
} from 'pages/dashboard/components/Progress/progressSlice';
import {
  getActiveProductsTypeCodes,
  getNewProductType,
} from 'pages/dashboard/components/Progress/selectors';
import {
  getOnboardingTab,
  getOnboardingTabCode,
  getOnboardingTabs,
} from 'pages/dashboard/selectors';

export default function OnboardingTabs() {
  const dispatch = useAppDispatch();

  const currentTab: number = useAppSelector(getOnboardingTab);
  const currentTabCode: string = useAppSelector(getOnboardingTabCode);
  const newProductType: string = useAppSelector(getNewProductType);
  const activeProductTypes = useAppSelector(getActiveProductsTypeCodes);
  const tabList: OnboardingTab[] = useAppSelector(getOnboardingTabs);

  const activeTabs = tabList.filter(
    (tab: OnboardingTab) =>
      !tab.product || activeProductTypes.includes(tab.product),
  );

  const handleChange = (value: number) => {
    dispatch(setOnboardingTab({ index: value, key: activeTabs[value].key }));
    dispatch(setShowErrors({ [activeTabs[currentTab]?.key || '']: true }));
  };

  useEffect(() => {
    const currentProduct = activeTabs[currentTab]?.product;

    if (currentProduct && !activeProductTypes.includes(currentProduct)) {
      const tabIndex = activeTabs.findIndex(
        (tab) => tab.key === OnboardingTabCodes.businessInfo,
      );

      dispatch(
        setOnboardingTab({ index: tabIndex, key: activeTabs[tabIndex].key }),
      );
    }
  }, [activeTabs, activeProductTypes, currentTab, dispatch]);

  useEffect(() => {
    if (currentTabCode !== activeTabs[currentTab]?.key) {
      const tabIndex = activeTabs.findIndex(
        (tab) => tab.key === currentTabCode,
      );

      if (tabIndex >= 0) {
        handleChange(tabIndex);
      } else if (currentTab > 0) {
        for (let i = 1; i++; i <= activeTabs.length) {
          if (activeTabs[currentTab - i]) {
            handleChange(currentTab - i);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabCode, activeTabs]);

  useEffect(() => {
    if (newProductType) {
      const tabIndex = activeTabs.findIndex(
        (tab: OnboardingTab) =>
          tab[newProductType] && tab.progress && tab.progress.progress !== 100,
      );

      if (tabIndex >= 0) {
        dispatch(
          setOnboardingTab({ index: tabIndex, key: activeTabs[tabIndex].key }),
        );
      }

      dispatch(clearNewProductType());
    }
  }, [newProductType, dispatch, activeTabs]);

  return (
    <div id="onboarding-tabs" className="bg-white shadow rounded-lg">
      <Tabs
        tabs={activeTabs}
        currentTab={currentTab}
        onChange={handleChange}
        className="px-6 pt-4 border-b border-gray-200 space-x-6"
        tabClassName="border-b-2"
        footerNavigation
      >
        {activeTabs[currentTab]?.component || null}
      </Tabs>
    </div>
  );
}
