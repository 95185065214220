import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { useAppSelector, useAppDispatch } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';
import HideComponent from 'components/HideComponent';
import { LoginFormValues } from 'entities/login';
import { Input } from 'components/Inputs';
import { getUrlParams } from 'utils/url';
import path from 'common/path';

import { getLoginData } from 'pages/login/selectors';
import { emailValidation } from 'pages/login/utils';
import { verifyPerson } from 'pages/login/thunks';
import { setData } from 'pages/login/loginSlice';

const EnrollForm = () => {
  const { t } = useTranslation(['enroll', 'login']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const urlParams: any = getUrlParams();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const data = useAppSelector(getLoginData);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: urlParams?.email || data.email,
      phone: urlParams?.email || data.phone,
    },
  });

  const email = watch('email');

  useEffect(() => {
    if (showAlert) {
      setShowAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleLogin = async () => {
    dispatch(
      setData({
        email,
      }),
    );
    navigate(path.login);
  };

  const handleFormSubmit = async (values: LoginFormValues) => {
    const loginData: LoginFormValues = {
      email: values.email,
      sendCode: false,
    };

    dispatch(setData(loginData));

    const result = await dispatch(verifyPerson(loginData));

    if (!Array.isArray(result.payload) || result.payload.length !== 0) {
      setShowAlert(true);
      return;
    }

    navigate({ pathname: path.profile, search: `?email=${email}` });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="py-8 px-4 md:px-8">
        <Input
          name="email"
          placeholder={t('form.email.placeholder')}
          label={t('form.email.label')}
          register={register}
          error={errors?.email}
          validation={emailValidation(t)}
        />

        <HideComponent show={showAlert}>
          <Alert
            type={AlertTypes.warning}
            title={t('form.emailExistsAlert')}
            className="mt-4"
          />
        </HideComponent>

        <Button
          loading={isSubmitting}
          type="submit"
          className="my-6 w-full"
          heightClass="h-11"
          size={ButtonSize.mediumLarge}
        >
          {t('form.enroll')}
        </Button>

        <div className="flex justify-center items-center">
          <p className="text-secondary font-medium px-2.5">
            {t('form.existed')}
          </p>
          <Button
            onClick={handleLogin}
            variant={ButtonVariant.link}
            size={ButtonSize.medium}
            heightClass="h-6"
            paddingClass="px-2.5"
          >
            {t('form.signIn')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EnrollForm;
