import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { CloseIcon, CheckCircleIcon } from 'components/icons';
import Button, { ButtonVariant } from 'components/Button';
import HideComponent from 'components/HideComponent';

type InfoDialogProps = {
  title?: string;
  text?: string;
  buttonText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
};

const InfoDialog: React.FC<InfoDialogProps> = ({
  title,
  text,
  buttonText,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = () => {
    setIsLoading(true);

    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <div>
      <Button
        onClick={onClose}
        className="absolute top-4 right-4"
        paddingClass="p-2"
        variant={ButtonVariant.link}
        heightClass="h-10"
      >
        <CloseIcon className="w-6 h-6 text-gray-400" />
      </Button>
      <div className="mt-14 px-6 pb-8">
        <div className="flex justify-center mb-6">
          <CheckCircleIcon className="text-blue-600 w-11 h-11" />
        </div>

        <HideComponent show={title !== undefined}>
          <p className="text-2xl font-bold text-gray-900 mb-6 text-center">
            {title}
          </p>
        </HideComponent>

        <HideComponent show={text !== undefined}>
          <p className="text-secondary text-center">{text}</p>
        </HideComponent>
      </div>

      <HideComponent show={onConfirm !== undefined}>
        <div className="px-6 py-5 border-t border-gray-200">
          <Button
            className="w-full"
            onClick={handleConfirm}
            loading={isLoading}
            heightClass="h-11"
          >
            {buttonText || t('gotIt')}
          </Button>
        </div>
      </HideComponent>
    </div>
  );
};

export default InfoDialog;
