import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import PopoverOnHover from 'components/PopoverOnHover';
import { InfoCircleSolidIcon } from 'components/icons';
import { addTandCaccept } from 'pages/Profile/thunks';
import { CountryISOCodes } from 'common/countries';
import { Checkbox } from 'components/Inputs';
import {
  getBusinessesAddress,
  getBusinessSignatory,
} from 'pages/Profile/selectors';

import { setIsDDAChecked } from '../submitDashboardSlice';
import { getIsDDAChecked } from '../selectors';

const VirtualDDACheckbox = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const { countryIsocode } = useAppSelector(getBusinessesAddress);
  const signatory = useAppSelector(getBusinessSignatory);
  const isDDAChecked = useAppSelector(getIsDDAChecked);

  const [isAccepted, setIsAccepted] = useState<boolean>();

  const handleCheckbox = (value: boolean) => {
    dispatch(setIsDDAChecked(value));

    if (value && !isAccepted) {
      dispatch(addTandCaccept('submit/tc/virtual_dda'));
      setIsAccepted(true);
    }
  };

  if (countryIsocode !== CountryISOCodes.Canada) {
    return null;
  }

  return (
    <div className="mt-6">
      <div className="w-full sm:w-1/2 max-w-[650px]">
        <label
          htmlFor="virtualDDA"
          className={classNames(
            'cursor-pointer',
            !signatory && 'opacity-50 pointer-events-none',
          )}
        >
          <div className="flex items-center justify-between p-[14px] border border-gray-200 rounded-lg relative">
            <div className="flex items-center">
              <p className="text-xs font-medium text-gray-700 pr-1">
                {t('submitModal.virtualDDA')}
              </p>

              <PopoverOnHover
                info={<div>{t('submitModal.virtualDDAInfo')}</div>}
                button={
                  <button className="flex self-center cursor-pointer text-gray-400 flex-shrink-0 p-0.5 rounded-md hover:bg-gray-100 hover:text-gray-500">
                    <InfoCircleSolidIcon className="text-gray-400 hover:text-gray-900" />
                  </button>
                }
                infoClassName="z-10 w-[345px] text-sm text-gray-100 bg-gray-900 p-3 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md"
                placement="top"
              />
            </div>

            <Checkbox
              id="virtualDDA"
              className="ml-2"
              onChange={handleCheckbox}
              checked={isDDAChecked}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export default VirtualDDACheckbox;
