import { FormEvent } from 'react';
import { CountryCode } from 'libphonenumber-js/types';

import { CountriesList } from 'entities/login';

interface CountriesProps {
  code: CountryCode;
  handleChangeCountry: (e: FormEvent<HTMLSelectElement>) => void;
  countries: CountriesList[];
  disabled?: boolean;
}

const Countries: React.FC<CountriesProps> = ({
  code,
  handleChangeCountry,
  countries,
  disabled,
}) => {
  return (
    <div className="absolute inset-y-0 left-0 flex items-center">
      <select
        name="country"
        autoComplete="country"
        className="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 text-sm leading-5 rounded-md focus:ring-0 focus:border-indigo-500"
        value={code}
        onChange={handleChangeCountry}
        disabled={disabled}
      >
        {countries.map((item) => (
          <option value={item.code} key={item.id}>
            {item.code}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Countries;
