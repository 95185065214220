import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import moment from 'moment';

import ConfirmDisconnect from 'pages/dashboard/components/Connectors/ConfirmDisconnect';
import { CalendarIcon, DisconnectIcon, PencilIcon } from 'components/icons';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import Account from 'pages/dashboard/components/Connectors/Account';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import Alert, { AlertTypes } from 'components/Alert';
import { ERPProviders } from 'entities/accounting';
import imageLabel from 'assets/images/hakuna.png';
import { Permissions } from 'entities/dashboard';
import Loading from 'components/Loading';
import Button from 'components/Button';
import {
  ERPAcountStatuses,
  Provider,
  ProvidersWithExpireDate,
} from 'entities/accounting';

import { openPopup, getPopupSize } from '../utils';
import { setStep } from '../accountingSlice';
import { QBOButton } from './QBOButton';
import QBOAlerts from './QBOAlerts';
import {
  disconnectErpAccount,
  erpAuth,
  erpValidate,
  fetchErpAccount,
} from '../thunks';
import PermissionSection from 'components/permission/PermissionSection';

interface AccountInfoProps {
  provider: Provider;
  account: any;
  onClose: () => void;
  showSteps: boolean;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  account,
  provider,
  onClose,
  showSteps,
}) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [editMode] = useState<boolean>(showSteps);

  const erpHasToken = ProvidersWithExpireDate.includes(provider.name);

  const {
    erpaccountsId,
    refreshTokenNextExpiryDate,
    erpaccountStatus,
    modifiedBy,
    createdBy,
  } = account;

  const isTokenExpired = useMemo(() => {
    const daysLeft = refreshTokenNextExpiryDate
      ? moment(refreshTokenNextExpiryDate).diff(moment(), 'days', true)
      : 0;

    return daysLeft < 0;
  }, [refreshTokenNextExpiryDate]);

  const isConnected = useMemo(
    () => erpaccountStatus === ERPAcountStatuses.connected,
    [erpaccountStatus],
  );

  const isQBO = useMemo(
    () => provider.name === ERPProviders.quickbooks,
    [provider.name],
  );

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const disconnectAccount = async () => {
    setIsLoading(true);
    handleCancel();
    await dispatch(
      disconnectErpAccount({ modifiedBy, erpaccountsId, onSuccess: onClose }),
    );
    setIsLoading(false);
  };

  const validateErp = async () => {
    setIsLoading(true);

    const result = await dispatch(erpValidate());

    if (result.error) {
      setError(t('dashboard:accounting.info.alertError'));
    } else if (!result.payload[0]?.isSuccessful) {
      setError(
        result.payload[0]?.error || t('dashboard:accounting.info.alertError'),
      );
    }

    setIsLoading(false);
  };

  const handleConnect = async () => {
    setError('');

    if (erpHasToken) {
      const options = getPopupSize();
      const popup: any = window.open('', '_blank', options);
      popup.document.write('Loading...');

      setIsLoading(true);
      const response = await dispatch(erpAuth(provider.name));
      setIsLoading(false);

      if (response.payload) {
        openPopup(
          response.payload,
          () => {
            dispatch(fetchErpAccount(erpaccountsId));
            validateErp();
          },
          popup,
        );

        return;
      } else {
        popup?.close();
      }
    }

    validateErp();
  };

  const handleDisconnect = () => {
    dispatch(
      openDialog({
        content: (
          <ConfirmDisconnect
            onConfirm={disconnectAccount}
            onCancel={handleCancel}
          />
        ),
        maxWidth: '514px',
      }),
    );
  };

  const handleEdit = () => {
    dispatch(setStep(1));
  };

  return (
    <div className="p-6 flex-1 overflow-auto">
      <Loading transparent fullHeight loading={isLoading || !provider.name} />
      <Account
        image={<img src={imageLabel} alt="label" className="w-8 h-8" />}
        email={createdBy}
        status={erpaccountStatus}
        actions={
          <div className="flex items-center">
            <HideComponent show={!showSteps}>
              <PermissionSection
                permission={Permissions.erpIntegration}
                showPopover
                edit
              >
                <Button
                  variant="secondary-outline"
                  size="medium"
                  className="ml-4"
                  onClick={handleEdit}
                  disabled={!erpPermissions.edit}
                >
                  <PencilIcon className="mr-2 text-gray-500" />
                  {t('common:edit')}
                </Button>
              </PermissionSection>
            </HideComponent>
            <HideComponent show={isConnected || isTokenExpired}>
              <PermissionSection
                permission={Permissions.erpIntegration}
                showPopover
                edit
              >
                <Button
                  variant="link-red"
                  size="medium"
                  className="ml-4"
                  onClick={handleDisconnect}
                  disabled={!erpPermissions.edit}
                >
                  <DisconnectIcon className="mr-2" />
                  {t('common:disconnect')}
                </Button>
              </PermissionSection>
            </HideComponent>
          </div>
        }
      />

      {!isConnected &&
        (!refreshTokenNextExpiryDate ||
          (isQBO &&
            moment(refreshTokenNextExpiryDate).diff(moment(), 'days', true) >=
              7)) && (
          <>
            <Alert
              title={t('dashboard:accounting.info.alertMissingTitle')}
              description={t('dashboard:accounting.info.alertMissingText', {
                name: provider.name || '',
                rule: t(
                  `dashboard:accounting.info.${
                    erpHasToken ? 'alertMissingToken' : 'alertMissingValidation'
                  }`,
                ),
              })}
              type={editMode ? AlertTypes.info : AlertTypes.error}
              icon={editMode ? AlertTypes.info : AlertTypes.warning}
              className="mt-6"
              buttonText={
                erpPermissions.edit && !isQBO && !editMode
                  ? t('dashboard:accounting.info.validateConnection')
                  : ''
              }
              buttonClick={erpPermissions.edit ? handleConnect : undefined}
              button={
                erpPermissions.edit && isQBO ? (
                  <QBOButton onClick={handleConnect} className="mt-2" small />
                ) : null
              }
            />
            {editMode && (
              <div className="flex justify-center mt-6">
                {isQBO ? (
                  <QBOButton onClick={handleConnect} className="mx-auto" />
                ) : (
                  <PermissionSection
                    permission={Permissions.erpIntegration}
                    showPopover
                    edit
                  >
                    <Button
                      className="mx-auto"
                      onClick={handleConnect}
                      disabled={!erpPermissions.edit}
                    >
                      {t('dashboard:accounting.info.validateConnection')}
                    </Button>
                  </PermissionSection>
                )}
              </div>
            )}
          </>
        )}

      {error && (
        <div className="flex justify-center mt-[10px]">
          <Alert
            title={error}
            type={AlertTypes.error}
            withClose
            isShow={!!error}
            onClose={() => setError('')}
          />
        </div>
      )}

      {isQBO && (
        <QBOAlerts
          account={account}
          onUpdate={handleConnect}
          hasPermission={erpPermissions.edit}
        />
      )}

      <div className="mt-6 pt-6 border-t border-gray-200 flex">
        <div className="w-1/2">
          <p className="text-sm font-medium text-gray-500 mb-1">
            {t('dashboard:accounting.info.erpSoftware')}
          </p>
          <p className="text-sm">{provider.name}</p>
        </div>
        <div className="w-1/2">
          <p className="text-sm font-medium text-gray-500 mb-1">
            {t('dashboard:accounting.info.connectionValidity')}
          </p>
          <p className="text-xs flex items-center">
            {isConnected && provider.name && !isQBO && (
              <PermissionSection
                permission={Permissions.erpIntegration}
                showPopover
                edit
              >
                <Button
                  variant="mslLink"
                  size="small"
                  className="mr-4"
                  onClick={handleConnect}
                  disabled={!erpPermissions.edit}
                >
                  {t('dashboard:accounting.info.validateConnection')}
                </Button>
              </PermissionSection>
            )}
            {((!refreshTokenNextExpiryDate && !isConnected) || erpHasToken) && (
              <span className="pr-2 py-[2px] pl-[3px] flex items-center bg-gray-100 rounded">
                <CalendarIcon className="w-[14px] h-[14px] text-gray-400" />
                <span className="ml-[2px] font-medium">
                  {isConnected && refreshTokenNextExpiryDate
                    ? t('dashboard:accounting.info.expiresOn', {
                        date: moment(refreshTokenNextExpiryDate).format(
                          'MMM DD, yyyy',
                        ),
                      })
                    : '--/--/----'}
                </span>
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
