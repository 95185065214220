import { ReactNode, LinkHTMLAttributes, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Radium from 'radium';

import { getTheme } from 'theme/selectors';
import { openDialog } from 'components/dialog/modalSlice';
import { useAppDispatch, useAppSelector } from 'hooks';

interface LinkProps extends LinkHTMLAttributes<HTMLLinkElement> {
  href: string;
  children: ReactNode | string;
  className?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  site?: string;
  styles?: any;
}

const ExternalLink: React.FC<LinkProps> = ({
  href,
  children,
  className,
  target = '_blank',
  site = '',
  styles,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { textColor } = useAppSelector(getTheme);

  const defaultStyles = {
    color: textColor.primary.base,
    ':hover': {
      color: textColor.primary.hover,
    },
  };

  const handleContinue = () => {
    window.open(href, target)?.focus();
  };

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      openDialog({
        title: t('externalLinkTitle'),
        text: t('externalLinkText', { site }),
        onContinue: handleContinue,
        onCancel: () => {},
        maxWidth: '400px',
      }),
    );
  };

  return (
    <a
      href={href}
      className={className}
      target={target}
      rel="noopener noreferrer"
      onClick={handleClick}
      style={styles ?? defaultStyles}
    >
      {children}
    </a>
  );
};

export default Radium(ExternalLink);
