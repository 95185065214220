export const AngleDoubleRightIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.2441 5.59056C9.91864 5.26512 9.91864 4.73748 10.2441 4.41205C10.5695 4.08661 11.0972 4.08661 11.4226 4.41205L16.4226 9.41205C16.7381 9.72753 16.7491 10.2355 16.4476 10.5644L11.8643 15.5644C11.5533 15.9037 11.0262 15.9266 10.6869 15.6156C10.3476 15.3046 10.3247 14.7775 10.6357 14.4382L14.6799 10.0264L10.2441 5.59056Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M4.41042 5.59056C4.08498 5.26512 4.08498 4.73748 4.41042 4.41205C4.73586 4.08661 5.26349 4.08661 5.58893 4.41205L10.5889 9.41205C10.9044 9.72753 10.9154 10.2355 10.614 10.5644L6.03064 15.5644C5.71964 15.9037 5.1925 15.9266 4.85324 15.6156C4.51397 15.3046 4.49105 14.7775 4.80205 14.4382L8.84623 10.0264L4.41042 5.59056Z"
      fill="currentColor"
    />
  </svg>
);
