import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';

const IconUnchecked = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="4"
      fill="white"
      stroke="currentColor"
    />
  </svg>
);

const IconChecked = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="4"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M12.2074 4.79279C12.3949 4.98031 12.5002 5.23462 12.5002 5.49979C12.5002 5.76495 12.3949 6.01926 12.2074 6.20679L7.20741 11.2068C7.01988 11.3943 6.76557 11.4996 6.50041 11.4996C6.23524 11.4996 5.98094 11.3943 5.79341 11.2068L3.79341 9.20679C3.61125 9.01818 3.51045 8.76558 3.51273 8.50339C3.51501 8.24119 3.62018 7.99038 3.80559 7.80497C3.991 7.61956 4.24181 7.51439 4.50401 7.51211C4.7662 7.50983 5.0188 7.61063 5.20741 7.79279L6.50041 9.08579L10.7934 4.79279C10.9809 4.60532 11.2352 4.5 11.5004 4.5C11.7656 4.5 12.0199 4.60532 12.2074 4.79279Z"
      fill="white"
    />
  </svg>
);

interface CheckboxProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  onChange?: (val: boolean) => void;
  color?: string;
  register?: UseFormRegister<FieldValues>;
  setValue?: any;
  validation?: any;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  disabled = false,
  checked = false,
  className,
  onChange,
  register,
  setValue,
  validation = {},
}) => {
  const { textColor } = useAppSelector(getTheme);

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    if (setValue) {
      setValue(name, isChecked);
    }
  }, [isChecked, setValue, name]);

  const handleChange = () => {
    if (!disabled) {
      setIsChecked(!isChecked);

      if (onChange) {
        onChange(!isChecked);
      }
    }
  };

  const handleDefault = () => {};

  return (
    <span
      className={classNames('relative inline-block w-4 h-4', className, {
        'opacity-50': disabled,
      })}
      onClick={handleChange}
      aria-hidden
    >
      {isChecked ? (
        <div
          style={{ color: textColor.primary.base }}
          className={classNames(disabled ? '' : 'cursor-pointer')}
        >
          <IconChecked />
        </div>
      ) : (
        <div
          className={classNames(
            'text-gray-300',
            disabled ? '' : 'cursor-pointer',
          )}
        >
          <IconUnchecked />
        </div>
      )}
      <input
        {...(id && { id })}
        checked={isChecked}
        type="checkbox"
        {...(register && name && register(name, validation))}
        className={`absolute top-0 w-full h-full opacity-0 ${
          disabled ? '' : 'cursor-pointer'
        }`}
        onChange={handleDefault}
      />
    </span>
  );
};
