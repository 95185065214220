import RadioButtons from 'components/RadioButtons';

const ShowYear = ({ year, options, startYear, showSelect, onChange }) => {
  return showSelect ? (
    <RadioButtons
      options={options}
      checked={startYear}
      onChange={onChange}
      className="ml-4"
      size="small"
    />
  ) : (
    <span className="ml-3 text-xs font-normal text-gray-500">{year}</span>
  );
};

export default ShowYear;
