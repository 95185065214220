import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import { NumberInput, Input } from 'components/Inputs';
import Alert, { AlertTypes } from 'components/Alert';
import { Permissions } from 'entities/dashboard';
import Accordion from 'components/Accordion';
import { useAppSelector } from 'hooks';
import { round } from 'utils/helpers';

import { getCurrentCurrency, getYearlyGrowth } from '../selectors';
import ShowYear from './ShowYear';

type YearOption = {
  name: string;
  value: string;
};

const currentYear = new Date().getFullYear();
const yearOptions: YearOption[] = [
  {
    name: currentYear.toString(),
    value: currentYear.toString(),
  },
  {
    name: (currentYear + 1).toString(),
    value: (currentYear + 1).toString(),
  },
];

const YearlyGrowth = ({ register, setValue, control, showErrors }) => {
  const { t } = useTranslation('financial');

  const currentCurrency = useAppSelector(getCurrentCurrency);
  const yearlyGrowths = useAppSelector(getYearlyGrowth);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const [startYear, setStartYear] = useState<YearOption>(yearOptions[0]);

  useEffect(() => {
    if (yearlyGrowths.length) {
      let year = 0;

      yearlyGrowths.map((yearlyGrowth, index) => {
        if (!year || yearlyGrowth.year < year) {
          year = yearlyGrowth.year;
        }

        setValue(
          `businessesFinancialsYearlyGrowth.${index}.businessesFinancialsYearlyGrowthId`,
          yearlyGrowth.businessesFinancialsYearlyGrowthId,
        );
        setValue(
          `businessesFinancialsYearlyGrowth.${index}.year`,
          yearlyGrowth.year,
        );
        setValue(
          `businessesFinancialsYearlyGrowth.${index}.salesVolume`,
          round(yearlyGrowth.salesVolume),
        );

        setTimeout(() => {
          setValue(
            `businessesFinancialsYearlyGrowth.${index}.salesCount`,
            yearlyGrowth.salesCount,
          );
        }, 500);

        setValue(
          `businessesFinancialsYearlyGrowth.${index}.transactionAverage`,
          round(yearlyGrowth.transactionAverage),
        );
        setValue(
          `businessesFinancialsYearlyGrowth.${index}.transactionHighest`,
          round(yearlyGrowth.transactionHighest),
        );
        setValue(
          `businessesFinancialsYearlyGrowth.${index}.refundPercentage`,
          yearlyGrowth.refundPercentage,
        );
        setValue(
          `businessesFinancialsYearlyGrowth.${index}.chargebackPercentage`,
          yearlyGrowth.chargebackPercentage,
        );
      });

      setStartYear(
        yearOptions.find((option) => option.value === year.toString()) ||
          yearOptions[0],
      );
    }
  }, [setValue, yearlyGrowths]);

  useEffect(() => {
    [0, 1, 2].map((index) => {
      setValue(
        `businessesFinancialsYearlyGrowth.${index}.year`,
        +startYear.value + index,
      );
    });
  }, [startYear, setValue]);

  const handleYearChange = (value) => {
    setStartYear(value);
  };

  return (
    <Accordion
      title={t('financial:transactionEstimation')}
      className="bg-gray-50 rounded-lg p-6 mt-6"
      opened
    >
      <NumberInput
        label={t('financial:fields.expectedGrowth.label')}
        readOnly={!financialPermissions.edit}
        name="percentageGrowth"
        className="mt-5"
        prefix="%"
        prefixInline
        placeholder="0.00"
        inputClassName="pl-8"
        control={control}
        allowNegative={false}
      />

      {showErrors &&
        yearlyGrowths.find(
          ({
            chargebackPercentage,
            refundPercentage,
            salesCount,
            salesVolume,
            transactionAverage,
            transactionHighest,
          }) =>
            !chargebackPercentage ||
            !refundPercentage ||
            !salesCount ||
            !salesVolume ||
            !transactionAverage ||
            !transactionHighest,
        ) && (
          <Alert
            type={AlertTypes.warning}
            title={t('financial:transactionEstimationRequired')}
            className="mt-4"
            withBorder
          />
        )}

      {[+startYear.value, +startYear.value + 1, +startYear.value + 2].map(
        (year, index) => (
          <Accordion
            key={index}
            title={
              <span className="flex items-center">
                <span
                  className={`${index === 0 ? 'text-sm' : ''} flex-shrink-0`}
                >
                  {t('financial:year', {
                    index: index + 1,
                  })}
                </span>
                <ShowYear
                  showSelect={financialPermissions.edit && index === 0}
                  year={year}
                  options={yearOptions}
                  onChange={handleYearChange}
                  startYear={startYear}
                />
              </span>
            }
            className="bg-gray-50 rounded-lg p-4 mt-6 border border-gray-300"
            opened={year === +startYear.value}
          >
            <input
              type="hidden"
              {...register(`businessesFinancialsYearlyGrowth.${index}.year`, {
                valueAsNumber: true,
              })}
            />
            <div className="flex items-start -mx-4.5">
              {yearlyGrowths.length > 0 && (
                <input
                  type="hidden"
                  {...register(
                    `businessesFinancialsYearlyGrowth.${index}.businessesFinancialsYearlyGrowthId`,
                    {
                      valueAsNumber: true,
                    },
                  )}
                />
              )}
              <Input
                label={t('financial:fields.volume.label')}
                placeholder={t('financial:fields.volume.placeholder')}
                name={`businessesFinancialsYearlyGrowth.${index}.salesVolume`}
                readOnly={!financialPermissions.edit}
                className="mt-6 w-full mx-4.5"
                register={register}
                suffix={currentCurrency}
                suffixInline
                type="number"
              />
              <NumberInput
                label={t('financial:fields.count.label')}
                placeholder={t('financial:fields.count.placeholder')}
                name={`businessesFinancialsYearlyGrowth.${index}.salesCount`}
                readOnly={!financialPermissions.edit}
                className="mt-6 w-full mx-4.5"
                allowNegative={false}
                control={control}
                type="int"
              />
            </div>
            <div className="flex items-start -mx-4.5">
              <Input
                label={t('financial:fields.avarageTransaction.label')}
                placeholder={t(
                  'financial:fields.avarageTransaction.placeholder',
                )}
                name={`businessesFinancialsYearlyGrowth.${index}.transactionAverage`}
                readOnly={!financialPermissions.edit}
                className="mt-6 w-full mx-4.5"
                register={register}
                suffix={currentCurrency}
                suffixInline
                type="number"
              />
              <Input
                label={t('financial:fields.highestTransaction.label')}
                placeholder={t(
                  'financial:fields.highestTransaction.placeholder',
                )}
                name={`businessesFinancialsYearlyGrowth.${index}.transactionHighest`}
                readOnly={!financialPermissions.edit}
                className="mt-6 w-full mx-4.5"
                register={register}
                suffix={currentCurrency}
                suffixInline
                type="number"
              />
            </div>
            <div className="flex items-start -mx-4.5">
              <Input
                label={t('financial:fields.refund.label')}
                placeholder={t('financial:fields.refund.placeholder')}
                name={`businessesFinancialsYearlyGrowth.${index}.refundPercentage`}
                readOnly={!financialPermissions.edit}
                className="mt-6 w-full mx-4.5"
                inputClassName="pl-8"
                prefix="%"
                prefixInline
                register={register}
                type="number"
              />
              <Input
                label={t('financial:fields.chargeback.label')}
                placeholder={t('financial:fields.chargeback.placeholder')}
                name={`businessesFinancialsYearlyGrowth.${index}.chargebackPercentage`}
                readOnly={!financialPermissions.edit}
                className="mt-6 w-full mx-4.5"
                inputClassName="pl-8"
                prefix="%"
                prefixInline
                register={register}
                type="number"
              />
            </div>
          </Accordion>
        ),
      )}
    </Accordion>
  );
};

export default YearlyGrowth;
