import { FieldError, Controller, Control } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

import { ExclamationCircleIcon, CalendarIcon } from 'components/icons';

import ErrorMessage from './components/ErrorMessage';
import Label from './components/Label';

interface DatePickerProps {
  name: string;
  control?: Control;
  label?: string;
  value?: string;
  info?: string;
  description?: string;
  className?: string;
  disabled?: boolean;
  error?: FieldError | undefined | any;
  optional?: boolean;
  validation?: any;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
}

const Picker: React.FC<DatePickerProps> = ({
  name,
  control,
  label,
  info,
  description,
  className,
  disabled,
  error,
  optional,
  validation = {},
  minDate = new Date('1900-01-07'),
  maxDate,
  dateFormat = 'MM-dd-yyyy',
}) => {
  return (
    <div className={className}>
      {label && (
        <Label info={info} description={description} optional={optional}>
          {label}
        </Label>
      )}

      <div className="relative flex">
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              selected={value}
              onChange={onChange}
              className={classNames(
                'w-full shadow-sm border py-2 px-3 placeholder-gray-400 rounded-md pr-10',
                { 'bg-gray-100': disabled },
                error
                  ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900'
                  : 'border-gray-300 focus:ring-0 focus:border-indigo-500',
              )}
              placeholderText="mm-dd-yyyy"
              dropdownMode="select"
              dateFormat={dateFormat}
              disabled={disabled}
              minDate={minDate}
              maxDate={maxDate}
              showMonthDropdown
              showYearDropdown
            />
          )}
        />

        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="text-red-500" />
          </div>
        )}

        <div
          className={classNames(
            'absolute inset-y-0 right-0 flex items-center pointer-events-none',
            error ? 'pr-9' : 'pr-3',
          )}
        >
          <CalendarIcon className="text-gray-400" />
        </div>
      </div>

      <ErrorMessage error={error} />
    </div>
  );
};

export default Picker;
