import api, { APIResponseIdSchema } from 'api';

export interface EmailAddressSchema {
  emailAddressesId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  emailAddress: string;
}

export const addEmailAddress = async (data: Partial<EmailAddressSchema>) => {
  const response: APIResponseIdSchema = await api.post('EmailAddresses', data);
  return response.id;
};
