import { useState, ReactNode, useEffect, useRef, TransitionEvent } from 'react';
import classNames from 'classnames';

const Collapse = ({
  opened = false,
  children,
  className,
  header,
}: {
  opened: boolean;
  children: ReactNode;
  className?: string;
  header?: ReactNode;
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [height, setHeight] = useState(opened ? 'auto' : '0');
  const [shouldOpenOnNextCycle, setShouldOpenOnNextCycle] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const getRefHeight = () => {
    if (contentRef && contentRef.current) {
      return contentRef.current.scrollHeight > 0
        ? contentRef.current.scrollHeight.toString()
        : 'auto';
    }

    return 'auto';
  };

  useEffect(() => {
    if ((isDirty || opened) && contentRef && contentRef.current) {
      setIsDirty(true);

      if (opened) {
        setHeight(getRefHeight());
      } else {
        setShouldOpenOnNextCycle(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  useEffect(() => {
    if (shouldOpenOnNextCycle) {
      setHeight(getRefHeight());
      setShouldOpenOnNextCycle(false);
    } else {
      setTimeout(() => {
        if (height !== 'auto') {
          setHeight('0');
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenOnNextCycle]);

  function handleTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
    // Switch to height auto to make the container responsive
    if (e.target === contentRef.current && opened) {
      setHeight('auto');
    }
  }

  return (
    <div
      className={classNames(
        { 'overflow-hidden': height !== 'auto' },
        className,
      )}
    >
      {header}
      <div
        ref={contentRef}
        style={{ height: height === 'auto' ? 'auto' : `${height}px` }}
        className={classNames('transition-height duration-300 ease-in-out', {
          'overflow-hidden': height === '0',
        })}
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
