export const AddUserCircleIcon = ({ className }: { className?: string }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.3"
      d="M24.9998 16.6667H23.3332C22.8729 16.6667 22.4998 16.2936 22.4998 15.8333C22.4998 15.3731 22.8729 15 23.3332 15H24.9998V13.3333C24.9998 12.8731 25.3729 12.5 25.8332 12.5C26.2934 12.5 26.6665 12.8731 26.6665 13.3333V15H28.3332C28.7934 15 29.1665 15.3731 29.1665 15.8333C29.1665 16.2936 28.7934 16.6667 28.3332 16.6667H26.6665V18.3333C26.6665 18.7936 26.2934 19.1667 25.8332 19.1667C25.3729 19.1667 24.9998 18.7936 24.9998 18.3333V16.6667ZM17.4998 19.1667C15.6589 19.1667 14.1665 17.6743 14.1665 15.8333C14.1665 13.9924 15.6589 12.5 17.4998 12.5C19.3408 12.5 20.8332 13.9924 20.8332 15.8333C20.8332 17.6743 19.3408 19.1667 17.4998 19.1667Z"
      fill="currentColor"
    />
    <path
      d="M10.0005 26.8314C10.3235 22.8541 13.5516 20.832 17.4861 20.832C21.476 20.832 24.7541 22.743 24.9983 26.832C25.008 26.9949 24.9983 27.4987 24.3722 27.4987C21.2843 27.4987 16.6956 27.4987 10.6063 27.4987C10.3973 27.4987 9.98295 27.048 10.0005 26.8314Z"
      fill="currentColor"
    />
    <rect
      x="1"
      y="1"
      width="38"
      height="38"
      rx="19"
      stroke="#D1D5DB"
      strokeWidth="2"
      strokeDasharray="4 4"
    />
  </svg>
);
