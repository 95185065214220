import { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';

const Badge = ({
  children,
  className,
  size = 'small',
  color = 'blue',
  rounded = 'rounded-full',
  withDot = false,
  fontWeight,
}: {
  children: ReactNode;
  className?: string;
  size?: 'small' | 'large';
  color?: 'blue' | 'gray' | 'green' | 'lightGreen' | 'red' | 'yellow';
  rounded?: 'rounded-full' | 'rounded-lg' | 'rounded-md' | 'rounded-sm';
  withDot?: boolean;
  fontWeight?: string;
}) => {
  const { backgroundColor, textColor } = useAppSelector(getTheme);

  const classes = useMemo(
    () => ({
      weight: {
        blue: 'text-blue-800',
        gray: 'text-gray-800',
        green: 'text-green-800',
        lightGreen: 'text-green-800',
        red: 'text-red-800',
        yellow: 'text-yellow-800',
      },
      bg: {
        blue: 'bg-blue-100',
        gray: 'bg-gray-100',
        green: 'bg-green-100',
        lightGreen: 'bg-green-50',
        red: 'bg-red-100',
        yellow: 'bg-yellow-100',
      },
      dot: {
        blue: 'bg-blue-400',
        gray: 'bg-gray-400',
        green: 'bg-green-400',
        lightGreen: 'bg-green-400',
        red: 'bg-red-400',
        yellow: 'bg-yellow-400',
      },
    }),
    [],
  );

  const styles = {
    weight: {
      blue: { color: textColor.primary.base },
    },
    bg: {
      blue: { backgroundColor: backgroundColor.bage.primary.base },
    },
    dot: {
      blue: { backgroundColor: backgroundColor.buton.secondery.base },
    },
  };

  return (
    <span
      style={{
        backgroundColor: styles.bg[color]
          ? styles.bg[color].backgroundColor
          : null,
        color: styles.weight[color] ? styles.weight[color].color : null,
      }}
      className={classNames(
        'inline-flex items-center font-medium flex-shrink-0',
        rounded,
        fontWeight || classes.weight[color],
        classes.bg[color],
        {
          'text-xs px-2.5 py-0.5': size === 'small',
          'text-sm p-1.5': size === 'large',
        },
        className,
      )}
    >
      {withDot && (
        <span
          style={styles.dot[color] ?? {}}
          className={classNames(
            'w-1.5 h-1.5 rounded-full mr-1.5',
            classes.dot[color],
          )}
        ></span>
      )}
      {children}
    </span>
  );
};

export default Badge;
