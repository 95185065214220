import { ReactNode } from 'react';
import Header from './Header';
import DialogModal from 'components/dialog/DialogModal';

const LayoutApp = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col h-screen min-h-screen">
      <Header logged />

      <div className="flex-1 overflow-y-auto">
        <div className="px-3 sm:px-12 pb-15 flex flex-wrap sm:flex-nowrap bg-gray-50 pt-6">
          {children}
        </div>
      </div>

      <DialogModal />
    </div>
  );
};

export default LayoutApp;
