export const AddUserIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.3"
      d="M14.9998 6.66667H13.3332C12.8729 6.66667 12.4998 6.29357 12.4998 5.83333C12.4998 5.3731 12.8729 5 13.3332 5H14.9998V3.33333C14.9998 2.8731 15.3729 2.5 15.8332 2.5C16.2934 2.5 16.6665 2.8731 16.6665 3.33333V5H18.3331C18.7934 5 19.1665 5.3731 19.1665 5.83333C19.1665 6.29357 18.7934 6.66667 18.3331 6.66667H16.6665V8.33333C16.6665 8.79357 16.2934 9.16667 15.8332 9.16667C15.3729 9.16667 14.9998 8.79357 14.9998 8.33333V6.66667ZM7.49983 9.16667C5.65889 9.16667 4.1665 7.67428 4.1665 5.83333C4.1665 3.99238 5.65889 2.5 7.49983 2.5C9.34078 2.5 10.8332 3.99238 10.8332 5.83333C10.8332 7.67428 9.34078 9.16667 7.49983 9.16667Z"
      fill="currentColor"
    />
    <path
      d="M0.000543069 16.8314C0.323548 12.8541 3.55159 10.832 7.48611 10.832C11.476 10.832 14.754 12.743 14.9982 16.832C15.008 16.9949 14.9982 17.4987 14.3722 17.4987C11.2843 17.4987 6.69559 17.4987 0.606251 17.4987C0.39726 17.4987 -0.0170514 17.048 0.000543069 16.8314Z"
      fill="currentColor"
    />
  </svg>
);
