import { useTranslation, Trans } from 'react-i18next';
import { components } from 'react-select';
import debounce from 'lodash.debounce';
import { useState } from 'react';

import { ProcessorOption, prysymProcessor } from 'entities/merchantServices';
import PermissionSection from 'components/permission/PermissionSection';
import { ProcessorSchema, searchProcessorsList } from 'services/prysym';
import Button, { ButtonVariant, ButtonSize } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import Autocomplete from 'components/Autocomplete';
import { isUATDomain } from 'utils/domainService';
import { Permissions } from 'entities/dashboard';
import Badge from 'components/Badge';
import {
  PaymentProcessorIcon,
  ChevronRightIcon,
  SelectorIcon,
  CheckIcon,
} from 'components/icons';

import { addBusinessMerchant } from '../thunks';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SelectorIcon />
  </components.DropdownIndicator>
);

const SelectProcessor = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const [selectedProcessor, setSelectedProcessor] =
    useState<ProcessorOption | null>(null);
  const [isLoading, setIsLoading] = useState<string>('');

  const handleChange = (processor: ProcessorOption) => {
    setSelectedProcessor(processor);
  };

  const fetchPaymentProcessors = async (search: string) => {
    try {
      const response: any = await searchProcessorsList({
        offset: 0,
        limit: 100,
        filters: search ? { name: search } : {},
      });

      return response.data.map((item: ProcessorSchema) => ({
        name: item.name,
        value: item.id,
      }));
    } catch (e) {
      return [];
    }
  };

  const debouncedLoadOptions = debounce(async (value: string, callback) => {
    const prod = await fetchPaymentProcessors(value);
    callback(prod);
  }, 500);

  const loadOptions = (value: string, callback) => {
    if (value) {
      debouncedLoadOptions(value, callback);
    } else {
      callback([]);
    }
  };

  const handleProcessor = async (processor: ProcessorOption) => {
    setIsLoading(processor.value);
    await dispatch(addBusinessMerchant(processor));
    setIsLoading('');
  };

  return (
    <div className="p-6">
      <div className="flex items-center mb-2">
        <PaymentProcessorIcon className="text-blue-600" />
        <h6 className="text-lg font-medium ml-2">
          {t('merchantServices.select.title')}
        </h6>
      </div>
      <p className="text-secondary mb-9">
        {t('merchantServices.select.description')}
      </p>

      <div className="flex">
        <div className="bg-gray-50 rounded-xl shadow-sm p-6 w-1/2 relative mr-3">
          <Badge className="absolute !text-sm top-[-12px]">
            {t('merchantServices.select.recommended.badge')}
          </Badge>
          <h6 className="text-lg font-medium mb-2.5">
            {t('merchantServices.select.recommended.title')}
          </h6>
          <p className="text-secondary">
            <Trans i18nKey="dashboard:merchantServices.select.recommended.description">
              Text<span className="font-medium text-gray-700">name</span>text
            </Trans>
          </p>

          <p className="text-secondary flex items-center my-2.5">
            <CheckIcon className="text-green-500 mr-2.5" />
            <span>{t('merchantServices.select.recommended.option1')}</span>
          </p>
          <p className="text-secondary flex items-center mb-2.5">
            <CheckIcon className="text-green-500 mr-2.5" />
            <span>{t('merchantServices.select.recommended.option2')}</span>
          </p>
          <p className="text-secondary flex items-center">
            <CheckIcon className="text-green-500 mr-2.5" />
            <span>{t('merchantServices.select.recommended.option3')}</span>
          </p>

          <PermissionSection
            permission={Permissions.financialInfo}
            showPopover
            edit
          >
            <Button
              className="w-full mt-6"
              onClick={() => handleProcessor(prysymProcessor)}
              loading={isLoading === prysymProcessor.value}
              disabled={!financialPermissions.edit}
            >
              {t('merchantServices.select.recommended.button')}
              <ChevronRightIcon className="ml-3" />
            </Button>
          </PermissionSection>
        </div>

        <div className="p-6 w-1/2 ml-3">
          <h6 className="text-lg font-medium mb-2.5">
            {t('merchantServices.select.custom.title')}
          </h6>
          <p className="text-secondary mb-2.5">
            {t('merchantServices.select.custom.description')}
          </p>
          <div className="flex items-center mt-6">
            <Autocomplete
              placeholder={t('merchantServices.select.custom.placeholder')}
              value={selectedProcessor}
              onChange={handleChange}
              loadOptions={loadOptions}
              customStyles={{
                valueContainer: { padding: '10px 16px' },
                indicatorsContainer: { paddingRight: 8 },
              }}
              components={selectedProcessor ? { DropdownIndicator } : {}}
              disabled={!financialPermissions.edit || !isUATDomain()}
              className="flex-1"
              async
            />
            {selectedProcessor && (
              <Button
                className="ml-2.5"
                heightClass="h-13"
                variant={ButtonVariant.mslLink}
                size={ButtonSize.large}
                onClick={() => handleProcessor(selectedProcessor)}
                loading={isLoading === selectedProcessor.value}
              >
                <ChevronRightIcon />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectProcessor;
