import { useTranslation } from 'react-i18next';

import { getIsNMIMerchant } from 'pages/dashboard/components/MerchantServices/selectors';
import { ChevronRightIcon, AngleDoubleRightIcon } from 'components/icons';
import { getBusinessSignatory } from 'pages/Profile/selectors';
import Button from 'components/Button';
import { useAppSelector } from 'hooks';

import { getIsNMIAgreed, getIsConfirmEnabled } from '../selectors';

type ModalActionsProps = {
  step: number;
  handleSubmit: () => void;
  handleBack: () => void;
  handleNext: () => void;
  isLoading: boolean;
};

const ModalActions: React.FC<ModalActionsProps> = ({
  step,
  handleSubmit,
  handleBack,
  handleNext,
  isLoading,
}) => {
  const { t } = useTranslation();

  const signatory = useAppSelector(getBusinessSignatory);
  const isNMIAgreed = useAppSelector(getIsNMIAgreed);
  const isNMIMerchant = useAppSelector(getIsNMIMerchant);
  const isConfirmEnabled = useAppSelector(getIsConfirmEnabled);

  if (step === 1) {
    return (
      <Button className="w-48 float-right" onClick={handleNext}>
        {t('common:next')}
        <AngleDoubleRightIcon className="ml-3" />
      </Button>
    );
  }

  if (step === 2) {
    return (
      <>
        <Button
          onClick={handleBack}
          variant="secondary-outline"
          className="w-48"
        >
          <ChevronRightIcon className="mr-3 rotate-180" />
          {t('common:back')}
        </Button>
        <Button className="w-48 float-right" onClick={handleNext}>
          {t('common:next')}
          <AngleDoubleRightIcon className="ml-3" />
        </Button>
      </>
    );
  }

  if (step === 3) {
    return (
      <>
        <Button
          onClick={handleBack}
          variant="secondary-outline"
          className="w-48"
        >
          <ChevronRightIcon className="mr-3 rotate-180" />
          {t('common:back')}
        </Button>
        <Button
          className="w-48 float-right"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={
            !signatory || !isConfirmEnabled || (isNMIMerchant && !isNMIAgreed)
          }
        >
          {t('common:confirm')}
        </Button>
      </>
    );
  }

  if (step === 4) {
    return (
      <Button onClick={handleBack} variant="secondary-outline" className="w-48">
        <ChevronRightIcon className="mr-3 rotate-180" />
        {t('common:back')}
      </Button>
    );
  }

  return null;
};

export default ModalActions;
