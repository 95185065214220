import { createSlice } from '@reduxjs/toolkit';

import { ProfileState } from 'entities/profile';
import { onboardingSteps } from './utils';
import {
  fetchOrCreatePerson,
  fetchProfileByEmail,
  fetchCountries,
  searchCandidatesByAddress,
  updateBusinessInfo,
  createBusinessInfo,
  updateBusiness,
  updatePersonInfo,
  createPersonTelephone,
  updatePersonTelephone,
  authorizeSignatory,
  sendSmartDisburse,
} from './thunks';

export const initialState: ProfileState = {
  isAdmin: false,
  termsCheckbox: {
    show: false,
    checked: false,
    id: 0,
    version: '',
  },
  privacyCheckbox: {
    show: false,
    checked: false,
    id: 0,
    version: '',
  },
  person: {},
  personAddress: {},
  personTelephoneNumber: {},
  business: {},
  businessAdmin: {},
  businessAddress: {},
  businessTelephoneNumber: {},
  businessTelephoneNumberXref: {},
  businessPerson: {},
  candidates: {
    data: [],
    selected: 0,
    isLoading: true,
  },
  countries: [],
  step: onboardingSteps.business,
  isLoading: true,
  isFormUpdate: false,
  existingEmails: [],
  redirectToDashboard: true, // redirect from 3d step
  onboarding: false, // onboard a new business
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setPersonId(state, action) {
      state.person.personsId = action.payload;
    },
    setPerson(state, action) {
      const person = {
        ...state.person,
        ...action.payload,
      };

      state.person = person;
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setBusinessAddress(state, action) {
      state.businessAddress = action.payload;
    },
    addExistingEmail(state, action) {
      state.existingEmails.push(action.payload);
    },
    clearExistingEmail(state) {
      state.existingEmails = [];
    },
    setTermsCheckbox(state, action) {
      state.termsCheckbox = {
        ...state.termsCheckbox,
        ...action.payload,
      };
    },
    setPrivacyCheckbox(state, action) {
      state.privacyCheckbox = {
        ...state.privacyCheckbox,
        ...action.payload,
      };
    },
    setBusiness(state, action) {
      const business = {
        ...state.business,
        ...action.payload,
      };

      state.business = business;
    },
    setCandidates(state, action) {
      state.candidates.data = action.payload;
    },
    setSelectedCandidate(state, action) {
      state.candidates.selected = action.payload;
    },
    setFormUpdateState(state) {
      state.isFormUpdate = !state.isFormUpdate;
    },
    setStep(state, action) {
      state.step = action.payload;
      state.candidates.isLoading = true;
    },
    setRedirectToDashboard(state, action) {
      state.redirectToDashboard = action.payload;
    },
    setBusinessInfo(state, action) {
      state.business = {
        ...state.business,
        ...action.payload.business,
      };
      state.businessAddress = action.payload.businessAddress;
      state.businessTelephoneNumber = action.payload.businessTelephoneNumber;
      state.businessTelephoneNumberXref =
        action.payload.businessTelephoneNumberXref;
    },
    setOnboarding(state, action) {
      state.onboarding = action.payload;
      state.redirectToDashboard = false;
    },
    setApplicationStatus(state, action) {
      state.business = {
        ...state.business,
        ...action.payload,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    resetState(state, action) {
      return {
        ...initialState,
        redirectToDashboard: false,
        user: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(authorizeSignatory.fulfilled, (state, action) => {
      if (action.payload.businessId) {
        const business: any = {
          ...state.business,
          signatoryAttestationPersonsID:
            action.payload.signatoryAttestationPersonsID,
        };

        state.business = business;
      }
    });

    builder.addCase(createBusinessInfo.fulfilled, (state, action) => {
      const { business, businessAddress, businessPerson, person } =
        action.payload;

      state.business = business;
      state.businessAddress = businessAddress;
      state.businessPerson = businessPerson || {};

      if (person) {
        state.person = person;
      }

      if (action.payload.termsCheckboxId) {
        state.termsCheckbox.id = action.payload.termsCheckboxId;
      }

      if (action.payload.privacyCheckboxId) {
        state.privacyCheckbox.id = action.payload.privacyCheckboxId;
      }

      state.step = onboardingSteps.dnb;
    });

    builder.addCase(updateBusinessInfo.fulfilled, (state, action) => {
      if (action.payload) {
        const { business, businessAddress, person } = action.payload;

        if (business) {
          state.business = business;
          state.businessAddress = businessAddress;

          if (business.noDunslisted || !business.dunsnumber) {
            state.candidates.selected = 0;
          }
        }

        if (person) {
          state.person = person;
        }
      }

      state.step = onboardingSteps.dnb;
    });

    builder.addCase(updatePersonInfo.fulfilled, (state, action) => {
      state.person = action.payload.person;
      state.businessPerson = action.payload.businessPerson;
    });

    builder.addCase(createPersonTelephone.fulfilled, (state, action) => {
      state.personTelephoneNumber = action.payload.telephoneNumber;
    });

    builder.addCase(searchCandidatesByAddress.pending, (state) => {
      state.candidates.isLoading = true;
    });
    builder.addCase(searchCandidatesByAddress.rejected, (state) => {
      state.candidates.isLoading = false;
      state.candidates.data = [];
      state.candidates.selected = 0;
    });
    builder.addCase(searchCandidatesByAddress.fulfilled, (state, action) => {
      state.candidates.isLoading = false;

      if (action.payload?.candidatesMatchedQuantity) {
        state.candidates.data = action.payload.matchCandidates;
      } else {
        state.candidates.data = [];
        state.candidates.selected = 0;
      }
    });

    builder.addCase(sendSmartDisburse.fulfilled, (state) => {
      state.business.productIdSentToErp = new Date().toLocaleString();
    });
    builder.addCase(updatePersonTelephone.fulfilled, (state, action) => {
      state.personTelephoneNumber = action.payload;
    });

    builder.addCase(updateBusiness.fulfilled, (state, action) => {
      state.business = {
        ...state.business,
        ...action.payload,
      };

      if (action.payload.noDunslisted) {
        state.candidates.selected = 0;
      }
    });

    builder.addCase(fetchOrCreatePerson.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrCreatePerson.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrCreatePerson.fulfilled, (state, action) => {
      const {
        personId,
        business,
        businessAdmin,
        businessAddress,
        businessTelephoneNumber,
        businessTelephoneNumberXref,
        person,
        personAddress,
        personTelephoneNumber,
        businessPerson,
        checkStep,
      } = action.payload;

      state.isLoading = false;
      state.isFormUpdate = true;

      if (person) {
        state.person = person;
      }

      if (personAddress) {
        state.personAddress = personAddress;
      }

      if (personTelephoneNumber) {
        state.personTelephoneNumber = personTelephoneNumber;
      }

      if (personId) {
        return;
      }

      if (business) {
        state.business = business;
        state.businessAdmin = businessAdmin;
        state.businessAddress = businessAddress;
        state.businessTelephoneNumber = businessTelephoneNumber;
        state.businessTelephoneNumberXref = businessTelephoneNumberXref;
        state.businessPerson = businessPerson;

        if (checkStep && business.businessesId && businessAddress.addressesId) {
          if (!business.dunsnumber && !business.noDunslisted) {
            state.step = onboardingSteps.dnb;
          } else {
            state.step = onboardingSteps.person;
          }
        } else {
          state.redirectToDashboard = false;
        }
      } else {
        state.business = {};
        state.businessAddress = {};
        state.businessTelephoneNumber = {};
        state.businessTelephoneNumberXref = {};
        state.businessPerson = {};
        state.redirectToDashboard = false;
      }
    });

    builder.addCase(fetchProfileByEmail.fulfilled, (state, action) => {
      if (action.payload) {
        const {
          person,
          business,
          personAddress,
          businessPerson,
          businessAddress,
          personTelephoneNumber,
          businessTelephoneNumber,
          businessTelephoneNumberXref,
        } = action.payload;

        state.isFormUpdate = true;

        state.person = person;
        state.personAddress = personAddress;
        state.personTelephoneNumber = personTelephoneNumber;

        if (business) {
          state.business = business;
          state.businessPerson = businessPerson;
          state.businessAddress = businessAddress;
          state.businessTelephoneNumber = businessTelephoneNumber;
          state.businessTelephoneNumberXref = businessTelephoneNumberXref;
        }
      }
    });
  },
});

export const {
  setFormUpdateState,
  setPersonId,
  setPerson,
  setIsAdmin,
  setBusiness,
  setTermsCheckbox,
  setPrivacyCheckbox,
  setStep,
  setBusinessAddress,
  setCandidates,
  setSelectedCandidate,
  setBusinessInfo,
  setRedirectToDashboard,
  addExistingEmail,
  clearExistingEmail,
  setOnboarding,
  setApplicationStatus,
  setIsLoading,
  resetState,
} = profileSlice.actions;

export default profileSlice.reducer;
