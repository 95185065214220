import api from 'api';

export interface CandidateSchema {
  name: string;
  address: string;
  countryISOAlpha2code: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
}

export const searchCandidates = (data: Partial<CandidateSchema>) => {
  return api.post('DnBApi/SearchCandidates', data);
};

export default {
  searchCandidates,
};
