import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserInviteData } from 'pages/login/utils';
import { getBusinessesId } from 'utils/authService';
import businessesService from 'services/businesses';
import countriesServices from 'services/countries';
import { addSmartDisburse } from 'services/erp';
import { searchCandidates } from 'services/dnb';
import personsServices from 'services/persons';
import { getTenant } from 'theme/selectors';
import tandcService from 'services/tandc';
import { RootState } from 'state/store';
import {
  BusinessPersonProps,
  PersonalInfoProps,
  PersonProps,
  UserProps,
} from 'entities/profile';
import telephoneService, {
  PERSON_PHONE_TYPE_CODE,
} from 'services/telephoneNumbers';

import { getBusiness, getPerson, getProfile } from './selectors';
import {
  searchOrCreatePerson,
  createBusinessProfile,
  updateBusinesses,
  updateBusinessProfile,
  getBusinessById,
} from './api';
import {
  getMappedCountries,
  getProfileData,
  mappedCreateTelephoneNumber,
  isBusinessUpdated,
} from './utils';

export const fetchCountries: any = createAsyncThunk(
  'profile/fetchCountries',
  async () => {
    try {
      const data = await countriesServices.searchCountries({ isActive: true });
      return getMappedCountries(data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchPersonPermissions: any = createAsyncThunk(
  'profile/fetchPersonPermissions',
  async (_: void, thunkAPI) => {
    try {
      const { personsId } = getPerson(thunkAPI.getState() as RootState);

      const businessId: number = getBusinessesId();

      const permissions = await personsServices.getBusinessPermissions(
        personsId,
        businessId,
      );

      return permissions;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBusiness: any = createAsyncThunk(
  'profile/fetchBusiness',
  async (businessId: number) => {
    try {
      const business = await getBusinessById(businessId);

      return business;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const sendInvitation: any = createAsyncThunk(
  'profile/sendInvitation',
  async (message: string, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      const { businessesId } = getBusiness(state);
      const { emailAddress } = getPerson(state);
      const tenant = getTenant(state);

      const inviteData = getUserInviteData({
        businessesId,
        email: emailAddress,
        resendInvite: true,
        message,
        tenant,
      });

      await personsServices.invitePersonsCreate(inviteData);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchProfileByEmail: any = createAsyncThunk(
  'profile/fetchProfileByEmail',
  async (email: string) => {
    try {
      const person = await personsServices.getPerson(email);

      if (person) {
        const businessId = getBusinessesId();
        const business = await businessesService.getBusinessDetailsById(
          businessId,
        );

        const profileData = getProfileData(person, business);

        return profileData;
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createPersonTelephone: any = createAsyncThunk(
  'profile/createPersonTelephone',
  async (data: any, thunkApi) => {
    try {
      const { emailAddress, personsId } = getPerson(
        thunkApi.getState() as RootState,
      );

      const fields = mappedCreateTelephoneNumber(data, emailAddress);

      const telephoneNumbersId = await telephoneService.addTelephoneNumbers(
        fields,
      );

      let personsTelephoneNumbersXrefid: any = 0;

      if (personsId) {
        const phoneNumberXrefs: any =
          await telephoneService.searchPersonsTelephoneNumbersXrefs({
            personsId: personsId,
            isActive: true,
            telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
          });

        const phoneNumberXref =
          phoneNumberXrefs && phoneNumberXrefs.length
            ? phoneNumberXrefs[0]
            : null;

        if (phoneNumberXref) {
          personsTelephoneNumbersXrefid =
            await telephoneService.updatePersonsTelephoneNumbersXrefs({
              persons__TelephoneNumbers_XrefId:
                phoneNumberXref.persons__TelephoneNumbers_XrefId,
              telephoneNumbersId,
              isActive: true,
              modifiedBy: data.emailAddress || emailAddress,
            });
        } else {
          personsTelephoneNumbersXrefid =
            await telephoneService.addPersonsTelephoneNumbersXrefs({
              createdBy: data.emailAddress || emailAddress,
              telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
              personsId: data.personsId || personsId,
              telephoneNumbersId,
              isActive: true,
            });
        }
      } else {
        personsTelephoneNumbersXrefid =
          await telephoneService.addPersonsTelephoneNumbersXrefs({
            createdBy: data.emailAddress || emailAddress,
            telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
            personsId: data.personsId || personsId,
            telephoneNumbersId,
            isActive: true,
          });
      }

      return {
        telephoneNumber: {
          telephoneNumbersId,
          ...fields,
        },
        telephoneNumberXref: {
          personsTelephoneNumbersXrefid,
        },
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updatePersonTelephone: any = createAsyncThunk(
  'profile/updatePersonTelephone',
  async (data: any, thunkApi) => {
    try {
      const { person, personTelephoneNumber } = getProfile(
        thunkApi.getState() as RootState,
      );

      const fields = mappedCreateTelephoneNumber(data, person.emailAddress);

      if (
        fields.countryCallingCode !==
          personTelephoneNumber.countryCallingCode ||
        fields.telephoneNumber !== personTelephoneNumber.telephoneNumber
      ) {
        const telephoneNumbersId = await telephoneService.addTelephoneNumbers(
          fields,
        );

        const phoneNumberXrefs: any =
          await telephoneService.searchPersonsTelephoneNumbersXrefs({
            personsId: person.personsId,
            isActive: true,
            telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
          });

        const phoneNumberXref =
          phoneNumberXrefs && phoneNumberXrefs.length
            ? phoneNumberXrefs[0]
            : null;

        if (phoneNumberXref) {
          await telephoneService.updatePersonsTelephoneNumbersXrefs({
            persons__TelephoneNumbers_XrefId:
              phoneNumberXref.persons__TelephoneNumbers_XrefId,
            telephoneNumbersId,
            isActive: true,
            modifiedBy: data.emailAddress || person.emailAddress,
          });
        } else {
          await telephoneService.addPersonsTelephoneNumbersXrefs({
            createdBy: data.emailAddress || person.emailAddress,
            telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
            personsId: data.personsId || person.personsId,
            telephoneNumbersId,
            isActive: true,
          });
        }
      }

      return {
        ...personTelephoneNumber,
        ...fields,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updatePersonInfo: any = createAsyncThunk(
  'profile/updatePersonInfo',
  async (data: PersonalInfoProps, thunkApi) => {
    try {
      const { person, businessPerson, personTelephoneNumber } = getProfile(
        thunkApi.getState() as RootState,
      );

      const result = { person, businessPerson };

      if (
        person.givenName1 !== data.givenName1 ||
        person.surnameFirst !== data.surnameFirst
      ) {
        const personFields = {
          ...person,
          modifiedBy: person.emailAddress,
          givenName1: data.givenName1,
          surnameFirst: data.surnameFirst,
        };

        await personsServices.updatePersons({
          ...person,
          modifiedBy: person.emailAddress,
          givenName1: data.givenName1,
          surnameFirst: data.surnameFirst,
        });

        result.person = personFields as PersonProps;
      }

      if (
        businessPerson.businessesPersonsXrefid &&
        businessPerson.businessesPersonsRole !== data.businessesPersonsRole
      ) {
        const businessesId = getBusinessesId();

        const businessPersonFields = {
          ...businessPerson,
          businessesId,
          businessesPersonsXrefid: businessPerson.businessesPersonsXrefid,
          modifiedBy: person.emailAddress,
          businessesPersonsRole: data.businessesPersonsRole,
        };

        await businessesService.updateBusinessesPersonsXrefs(
          businessPersonFields,
        );

        result.businessPerson = businessPersonFields as BusinessPersonProps;
      }

      if (data.phone) {
        if (personTelephoneNumber.telephoneNumber) {
          await thunkApi.dispatch(updatePersonTelephone(data));
        } else {
          await thunkApi.dispatch(createPersonTelephone({ phone: data.phone }));
        }
      }

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const searchCandidatesByAddress: any = createAsyncThunk(
  'profile/searchCandidates',
  async (address: any) => {
    try {
      const result: any = await searchCandidates(address);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const sendSmartDisburse: any = createAsyncThunk(
  'profile/sendSmartDisburse',
  async (_: void, thunkApi) => {
    try {
      const { person, business } = getProfile(thunkApi.getState() as RootState);

      await addSmartDisburse({
        businessesId: business.businessesId,
        createdBy: person.emailAddress,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusiness: any = createAsyncThunk(
  'profile/updateBusiness',
  async (data: any, thunkApi) => {
    try {
      const { person, business } = getProfile(thunkApi.getState() as RootState);

      const fields = {
        ...data,
        businessesId: business.businessesId,
        modifiedBy: person.emailAddress,
      };

      await updateBusinesses(fields);

      if (fields.logoFile || fields.faviconFile) {
        const updatedBusiness = await getBusinessById(business.businessesId);
        return updatedBusiness || fields;
      }

      return fields;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchOrCreatePerson: any = createAsyncThunk(
  'profile/fetchOrCreatePerson',
  async (user: Partial<UserProps>, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      const tenant = getTenant(state);

      const data: any = await searchOrCreatePerson(user, tenant.tenantsId);

      return {
        ...data,
        checkStep: user.checkStep,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createBusinessInfo: any = createAsyncThunk(
  'profile/createBusinessInfo',
  async (data: any, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      const tenant = getTenant(state);
      const profile = getProfile(state);

      const result = await createBusinessProfile({
        data,
        tenant,
        profile,
      });

      // if there is a phone ID and personWasCreated is true
      // it means that business was create for another person
      // and we should create phone record instead of update
      if (
        (profile.personTelephoneNumber?.telephoneNumbersId ||
          data.telephoneNumbersId) &&
        !result.personWasCreated
      ) {
        await thunkApi.dispatch(updatePersonTelephone(data));
      } else {
        await thunkApi.dispatch(
          createPersonTelephone({
            phone: data.phone,
            emailAddress: data.emailAddress,
            personsId: result.person?.personsId || profile.person.personsId,
          }),
        );
      }

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusinessInfo: any = createAsyncThunk(
  'profile/updateBusinessInfo',
  async (data: any, thunkApi) => {
    try {
      const profile = getProfile(thunkApi.getState() as RootState);

      let result: any = {};

      if (isBusinessUpdated(data, profile)) {
        result = await updateBusinessProfile(data, profile);
      }

      if (
        data.emailAddress !== profile.person?.emailAddress &&
        profile.person?.personsId &&
        !data.isAuthenticated
      ) {
        const personFields = {
          ...profile.person,
          modifiedBy: data.emailAddress,
          emailAddress: data.emailAddress,
        };

        await personsServices.updatePersons(personFields);

        result.person = personFields;
      }

      if (
        profile.personTelephoneNumber?.telephoneNumbersId ||
        data.telephoneNumbersId
      ) {
        if (
          !profile.businessAdmin.emailAddress ||
          profile.businessAdmin.emailAddress === profile.person?.emailAddress
        ) {
          await thunkApi.dispatch(updatePersonTelephone(data));
        }
      } else {
        await thunkApi.dispatch(
          createPersonTelephone({
            phone: data.phone,
            emailAddress: data.emailAddress,
            personsId: profile.person.personsId,
          }),
        );
      }

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveBusiness: any = createAsyncThunk(
  'profile/saveBusiness',
  async (data: any, thunkApi) => {
    try {
      const business = getBusiness(thunkApi.getState() as RootState);

      if (business?.businessesId) {
        await thunkApi.dispatch(updateBusinessInfo(data));
      } else {
        await thunkApi.dispatch(createBusinessInfo(data));
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const authorizeSignatory: any = createAsyncThunk(
  'submitDashboard/authorizeSignatory',
  async (_: void, thunkApi) => {
    try {
      const { person, business } = getProfile(thunkApi.getState() as RootState);

      const businessesId = business.businessesId;

      const businessId = await updateBusinesses({
        businessesId,
        signatoryAttestationPersonsID: person.personsId,
        modifiedBy: person.emailAddress,
      });

      return { businessId, signatoryAttestationPersonsID: person.personsId };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const addTandCaccept: any = createAsyncThunk(
  'profile/addTandCacceptLogs',
  async (tandCversion: string, thunkApi) => {
    try {
      const { personsId, emailAddress } = getPerson(
        thunkApi.getState() as RootState,
      );

      if (personsId) {
        const businessesId = getBusinessesId();

        const id = await tandcService.addTandCacceptLogs({
          businessesId,
          personsId,
          tandCversion,
          createdBy: emailAddress,
        });

        return id;
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const addTandCview: any = createAsyncThunk(
  'profile/addTandCviewLogs',
  async (tandCversion: string, thunkApi) => {
    try {
      const { personsId, emailAddress } = getPerson(
        thunkApi.getState() as RootState,
      );

      if (personsId) {
        const businessesId = getBusinessesId();

        const id = await tandcService.addTandCviewLogs({
          businessesId,
          personsId,
          tandCversion,
          createdBy: emailAddress,
        });

        return id;
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
