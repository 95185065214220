import { createSelector } from '@reduxjs/toolkit';

import { getBusinessesId } from 'utils/authService';
import { CountryISOCodes } from 'common/countries';
import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.profile;
export const getInvitePersons = (state: RootState) => state.invitePersons;

export const getProfile = createSelector([getRoot], (profile) => profile);

export const getBusiness = createSelector(
  [getRoot],
  ({ business }) => business,
);

export const getIsAdmin = createSelector([getRoot], ({ isAdmin }) => isAdmin);

export const getCheckboxes = createSelector(
  [getRoot],
  ({ termsCheckbox, privacyCheckbox }) => ({ termsCheckbox, privacyCheckbox }),
);

export const getExistingEmails = createSelector(
  [getRoot],
  ({ existingEmails }) => existingEmails,
);

export const getSingleOwner25Percent = createSelector(
  [getRoot],
  ({ business }) => business.singleOwner25Percent,
);

export const getBusinessSignatory = createSelector(
  [getRoot, getInvitePersons],
  ({ business }, { persons }) =>
    persons.find(
      (person) => person.personsId === business.signatoryAttestationPersonsID,
    ),
);

export const getCountries = createSelector(
  [getRoot],
  ({ countries }) => countries,
);

export const getPerson = createSelector([getRoot], ({ person }) => person);

export const getPersonId = createSelector(
  [getRoot],
  ({ person }) => person.personsId,
);

export const getOnboarding = createSelector(
  [getRoot],
  ({ onboarding }) => onboarding,
);

export const getCandidates = createSelector(
  [getRoot],
  ({ candidates }) => candidates,
);

export const getBusinessesAdmin = createSelector(
  [getRoot],
  ({ businessAdmin }) => businessAdmin,
);

export const getBusinessesAddress = createSelector(
  [getRoot],
  ({ businessAddress }) => businessAddress,
);

export const getSelectedCountry = createSelector(
  [getRoot],
  ({ countries, businessAddress }) => {
    const countryISOCode =
      businessAddress.countryIsocode || CountryISOCodes.USA;

    return countries.find((country) => country.value === countryISOCode);
  },
);

export const getBusinessInfo = createSelector(
  [getRoot],
  ({
    business,
    businessAdmin,
    businessAddress,
    personTelephoneNumber,
    person,
  }) => {
    const {
      city,
      countryIsocode,
      postalCode,
      stateOrProvince,
      addressLine1,
      alpha2Code,
    } = businessAddress;

    const businessId: string = getBusinessesId();

    const isAutoAdmin = person?.businessesCreateForOtherPersonInTenant || false;
    const showAdminFields = businessId || !isAutoAdmin;

    return {
      businessLegalName: business.businessLegalName || '',
      city: city || '',
      addressLine1: addressLine1 || '',
      countryIsocode: countryIsocode || '',
      alpha2Code: alpha2Code || '',
      postalCode: postalCode || '',
      stateOrProvince: stateOrProvince || '',
      emailAddress: showAdminFields
        ? businessAdmin.emailAddress || person.emailAddress || ''
        : '',
      phone: showAdminFields
        ? `${
            businessAdmin.countryCallingCode ||
            personTelephoneNumber.countryCallingCode ||
            ''
          }${
            businessAdmin.telephoneNumber ||
            personTelephoneNumber.telephoneNumber ||
            ''
          }`
        : '',
    };
  },
);

export const getIsAutoAdmin = createSelector(
  [getRoot],
  ({ person }) => person?.businessesCreateForOtherPersonInTenant || false,
);

export const getPersonInfo = createSelector(
  [getRoot],
  ({ person, personTelephoneNumber, businessPerson }) => {
    const { givenName1, surnameFirst, emailAddress } = person;
    const { countryCallingCode, telephoneNumber } = personTelephoneNumber;
    const { businessesPersonsRole } = businessPerson;

    return {
      givenName1: givenName1 || '',
      surnameFirst: surnameFirst || '',
      emailAddress: emailAddress || '',
      businessesPersonsRole: businessesPersonsRole || '',
      phone: `${countryCallingCode || ''}${telephoneNumber || ''}`,
    };
  },
);

export const getIsLoadingAddresses = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getIsLoadinCandidates = createSelector(
  [getRoot],
  ({ candidates }) => candidates.isLoading,
);

export const getIsFormUpdate = createSelector(
  [getRoot],
  ({ isFormUpdate }) => isFormUpdate,
);

export const getRedirectValue = createSelector(
  [getRoot],
  ({ person, personTelephoneNumber, redirectToDashboard, businessPerson }) =>
    person.givenName1 &&
    person.surnameFirst &&
    personTelephoneNumber.telephoneNumber &&
    businessPerson.businessesPersonsRole &&
    redirectToDashboard,
);

export const getCurrentStep = createSelector([getRoot], ({ step }) => step);
