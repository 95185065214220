import { ReactNode } from 'react';
import classNames from 'classnames';
import Radium from 'radium';

import { LoadingSwitch } from './LoadingButton';
import { useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';

export enum ButtonHeight {
  small = 'h-[30px]',
  medium = 'h-9.5',
  'medium-large' = 'h-10.5',
  large = 'h-[50px]',
  'add-on' = 'h-10',
}

export enum ButtonPadding {
  small = 'px-[10px]',
  medium = 'px-4',
  'medium-large' = 'px-4',
  large = 'px-6',
  'add-on' = 'px-3',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  mediumLarge = 'medium-large',
  large = 'large',
  auto = 'auto',
}

export enum ButtonVariant {
  primary = 'primary',
  link = 'link',
  linkSecondary = 'link-secondary',
  linkRed = 'link-red',
  secondary = 'secondary',
  secondaryOutline = 'secondary-outline',
  text = 'text',
  blueOutline = 'blue-outline',
  yellowOutline = 'yellow-outline',
  warning = 'warning',
  success = 'success',
  redPale = 'red-pale',
  mslLink = 'mslLink',
  red = 'red',
  successOutline = 'success-outline',
  addOn = 'add-on',
}

type ButtonVariantType =
  | 'primary'
  | 'link'
  | 'link-secondary'
  | 'link-red'
  | 'secondary'
  | 'secondary-outline'
  | 'success-outline'
  | 'text'
  | 'blue-outline'
  | 'yellow-outline'
  | 'warning'
  | 'success'
  | 'red-pale'
  | 'mslLink'
  | 'red'
  | 'add-on';

interface ButtonProps {
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'medium-large' | 'large' | 'auto';
  children: ReactNode;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonVariantType;
  widthClass?: string;
  paddingClass?: string;
  heightClass?: string;
  className?: string;
  styles?: any;
  prefix?: ReactNode;
  ref?: any;
  rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' | 'none';
  form?: string;
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  size = 'large',
  children,
  widthClass = '',
  paddingClass = '',
  heightClass,
  variant = 'primary',
  loading = false,
  disabled = false,
  className,
  onClick = () => {},
  prefix,
  ref,
  rounded = 'md',
  styles: buttonStyles,
  form,
}) => {
  const { backgroundColor, textColor, borderColor } = useAppSelector(getTheme);
  const isAddOn = variant === ButtonVariant.addOn;

  const height =
    heightClass || ButtonHeight[isAddOn ? 'add-on' : size] || 'h-[50px]';
  const padding =
    paddingClass || ButtonPadding[isAddOn ? 'add-on' : size] || 'px-6';

  const styles = {
    [ButtonVariant.primary]: {
      backgroundColor: backgroundColor.buton.primary.base,
      ':hover': {
        backgroundColor: backgroundColor.buton.primary.hover,
      },
    },
    [ButtonVariant.link]: {
      color: textColor.primary.base,
    },
    [ButtonVariant.blueOutline]: {
      color: textColor.primary.base,
      borderColor: borderColor.primary.base,
      ':hover': {
        backgroundColor: backgroundColor.buton.secondery.hover,
        color: '#ffffff',
      },
    },
  };

  const primary = 'text-white';
  // const link = 'hover:bg-blue-50';
  const blueOutline = 'border';
  const addOn =
    'border border-gray-300 rounded-l-none border-l-0 bg-gray-50 text-gray-500 font-normal';

  return (
    <button
      ref={ref}
      type={type}
      style={buttonStyles ?? styles[variant] ?? {}}
      form={form}
      disabled={disabled || loading}
      className={classNames(
        widthClass,
        className,
        padding,
        height,
        `rounded-${rounded}`,
        'font-medium transition-all duration-200 rounded-md focus:outline-none focus:shadow-outline disabled:cursor-not-allowed disabled:opacity-40 inline-flex items-center justify-center',
        {
          [primary]: variant === ButtonVariant.primary,
          'bg-red-100 text-red-500 focus:shadow-outline-red':
            variant === 'red-pale',
          'text-gray-700': variant === ButtonVariant.text,
          'leading-5 bg-blue-50 text-blue-600 hover:bg-blue-100':
            variant === ButtonVariant.mslLink,
          // [link]: variant === ButtonVariant.link,
          'text-gray-500 hover:bg-gray-50':
            variant === ButtonVariant.linkSecondary,
          'text-red-600 hover:text-red-700': variant === ButtonVariant.linkRed,
          'text-green-500 border border-green-500 hover:bg-green-500 hover:text-white':
            variant === ButtonVariant.successOutline,
          [blueOutline]: variant === ButtonVariant.blueOutline,
          'text-yellow-500 border border-yellow-500 hover:bg-yellow-500 hover:text-white':
            variant === ButtonVariant.yellowOutline,
          'text-white bg-yellow-600 hover:bg-yellow-500':
            variant === ButtonVariant.warning,
          'text-white bg-green-600 hover:bg-green-500':
            variant === ButtonVariant.success,
          'bg-gray-200 text-gray-600': variant === ButtonVariant.secondary,
          'border border-gray-300 bg-white hover:bg-gray-50 text-gray-700':
            variant === ButtonVariant.secondaryOutline,
          'bg-red-600 text-white': variant === ButtonVariant.red,
          [addOn]: isAddOn,
        },
      )}
      onClick={disabled || loading ? () => {} : onClick}
    >
      <span
        className={classNames('text-center w-full', {
          'text-xs': size === 'small',
          'text-sm leading-4': size === 'medium' || size === 'medium-large',
          'text-base leading-6': size === 'large',
        })}
      >
        <LoadingSwitch
          loading={loading}
          animationProps={{
            width: 16,
            height: 16,
            className: 'mx-auto',
          }}
        >
          <div
            className={classNames({
              'flex justify-center': prefix,
            })}
          >
            {prefix && <div className={'mr-2'}>{prefix}</div>}
            <div className="flex items-center justify-center">{children}</div>
          </div>
        </LoadingSwitch>
      </span>
    </button>
  );
};

export default Radium(Button);
