const colors = [
  'bg-teal-600',
  'bg-orange-500',
  'bg-pink-600',
  'bg-blue-600',
  'bg-yellow-500',
  'bg-green-600',
  'bg-red-500',
  'bg-fuchsia-600',
  'bg-lime-500',
  'bg-sky-500',
];

interface PersonProps {
  name: string;
  index: number;
  disabled?: boolean;
  size?: 'normal' | 'large' | 'huge';
}

enum SizeClasses {
  normal = 'h-8 w-8',
  large = 'h-10 w-10',
  huge = 'h-16 w-16',
}

enum TextClasses {
  normal = 'text-sm',
  large = 'text-base',
  huge = 'text-2xl',
}

const Person: React.FC<PersonProps> = ({
  name,
  index,
  disabled = false,
  size = 'normal',
}) => {
  const bg = disabled
    ? 'bg-gray-500'
    : colors[index.toString().split('').pop() || 0];

  return (
    <div
      className={`${
        SizeClasses[size]
      } rounded-full ${bg} flex items-center justify-center $z-${
        index * 10
      } -mr-3 border-2 rounded-full border-white`}
    >
      <span
        className={`${TextClasses[size]} leading-none font-normal text-white`}
      >
        {name}
      </span>
    </div>
  );
};

export default Person;
