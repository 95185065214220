import { useTranslation } from 'react-i18next';

import accountReceivable from 'assets/icons/accountReceivable.svg';
import git3 from 'assets/icons/git3.svg';

import PopoverCard from './PopoverCard';

const SCFPopover = () => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="bg-gray-900 rounded-lg px-4 py-2 w-[300px] mt-1 shadow-2xl">
      <PopoverCard
        badge={t('progress.payments.apBadge')}
        title={t('progress.scf.apTitle')}
        text={t('progress.scf.apText')}
        icon={git3}
        rgbColor="16,185,129"
        iconClass="absolute"
      />
      <PopoverCard
        badge={t('progress.payments.arBadge')}
        title={t('progress.scf.arTitle')}
        text={t('progress.scf.arText')}
        icon={accountReceivable}
        rgbColor="12,180,207"
      />
    </div>
  );
};

export default SCFPopover;
