import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';
import { Provider } from 'entities/accounting';
import { Input } from 'components/Inputs';
import Loading from 'components/Loading';
import Link from 'components/Link';

import { getIsCreatedByCurrentPerson } from '../selectors';
import { downloadGuide } from '../thunks';
import { ERPGuideFiles } from '../utils';
import ERPConnectorLogo from 'components/Logos/ERPConnectorLogo';

interface AccountFormProps {
  provider: Provider;
  account: any;
  register: any;
  errors: any;
}

const AccountForm: React.FC<AccountFormProps> = ({
  provider,
  account,
  register,
  errors,
}) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const isCreatedByCurrentPerson = useAppSelector(getIsCreatedByCurrentPerson);

  const { name, auths, logoFileShareUri, guideFileShareUri } = provider;
  const values = account.jsone ? JSON.parse(account.jsone) : [];

  const handleGuide = async () => {
    setLoading(true);
    const filename = `${t('dashboard:accounting.form.filename', { name })}.pdf`;
    await dispatch(downloadGuide({ guideFileShareUri, filename }));
    setLoading(false);
  };

  const handleUpdateGuide = async (erpFile: any) => {
    setLoading(true);
    const filename = `${erpFile.name}.pdf`;
    await dispatch(
      downloadGuide({
        guideFileShareUri: `${window.location.origin}/assets/files/${erpFile.file}`,
        filename,
      }),
    );
    setLoading(false);
  };

  return (
    <div className="p-6 flex-1 overflow-auto">
      {loading && <Loading transparent />}

      <Alert
        title={t('dashboard:accounting.form.alert')}
        type={AlertTypes.info}
        className="mb-6"
        withClose
      />

      {logoFileShareUri && (
        <div className="mt-6">
          <ERPConnectorLogo
            link={logoFileShareUri}
            alt={name}
            className="mx-auto h-9"
          />
        </div>
      )}

      <h2 className="heading-2xl mb-3 mt-2 text-center font-bold">
        {t('dashboard:accounting.form.title')}
      </h2>

      {guideFileShareUri && (
        <div className="flex justify-center mb-6">
          <div className="bg-gray-50 rounded-md px-4 py-2 text-sm">
            {t('dashboard:accounting.form.help')}
            <Link onClick={handleGuide} className="ml-1">
              {t('dashboard:accounting.form.helpLink')}
            </Link>
            {ERPGuideFiles[name] && (
              <>
                <span className="mx-1">{t('common:and')}</span>
                <Link onClick={() => handleUpdateGuide(ERPGuideFiles[name])}>
                  {ERPGuideFiles[name].text}
                </Link>
              </>
            )}
          </div>
        </div>
      )}

      <form className="max-w-md mx-auto px-4.5">
        {auths.map(
          (field) =>
            !field.derrivedFromTemplate &&
            !field.isHidden && (
              <div className="mb-6" key={field.authId}>
                <Input
                  name={field.key}
                  label={field.key}
                  error={errors?.[field.key]}
                  register={register}
                  type="password"
                  hideValue={
                    !isCreatedByCurrentPerson &&
                    values.find((val) => val.authId === field.authId)?.value
                  }
                  validation={
                    field.isRequired
                      ? {
                          required: t('common:validations.required', {
                            name: field.key,
                          }),
                        }
                      : {}
                  }
                />
              </div>
            ),
        )}
      </form>
    </div>
  );
};

export default AccountForm;
