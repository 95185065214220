import { getOnboardingTabCode } from 'pages/dashboard/selectors';
import { OnboardingTabCodes } from 'entities/dashboard';
import { useAppSelector } from 'hooks';
import Steps from 'components/Steps';

import { getBankAccountStep } from '../selectors';

const BankingModalStepper = () => {
  const step: number = useAppSelector(getBankAccountStep);
  const onboardingTabCode: OnboardingTabCodes =
    useAppSelector(getOnboardingTabCode);

  if (onboardingTabCode === OnboardingTabCodes.configuration) {
    return null;
  }

  return (
    <div className="pr-6">
      <Steps step={step - 1} stepsLength={2} />
    </div>
  );
};

export default BankingModalStepper;
