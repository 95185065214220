import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import { ProductTypeCodes } from 'entities/progress';
import { Permissions } from 'entities/dashboard';
import { formatPhoneNumber } from 'utils/phone';
import {
  setInviteIsOpen,
  setInvitedAndWasInviteState,
} from 'components/InvitePersons/invitePersonsSlice';
import {
  InfoCircleSolidIcon,
  MailIcon,
  PhoneIcon,
  UserAddIcon,
} from 'components/icons';

import RequestProviderButton from './components/RequestProviderButton';
import ProviderDetailsModal from './components/ProviderDetailsModal';
import { getActiveProductsTypeCodes } from '../Progress/selectors';
import ProviderDropDown from './components/ProviderDropDown';
import ProviderStatus from './components/ProviderStatus';
import FXProviderLogo from './components/FXProviderLogo';
import { getProviders } from './selectors';

const FXProvider = () => {
  const { t } = useTranslation('integration');

  const dispatch = useAppDispatch();

  const providers = useAppSelector(getProviders);
  const activeProductTypes = useAppSelector(getActiveProductsTypeCodes);
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleInvite = () => {
    if (invitePermissions.edit) {
      dispatch(setInvitedAndWasInviteState());
      dispatch(setInviteIsOpen(true));
    }
  };

  if (!activeProductTypes.includes(ProductTypeCodes.crossBorderPayments)) {
    return null;
  }

  return (
    <div className="p-6 rounded-lg border shadow">
      <div className="flex items-center justify-between mb-2">
        <p className="text-2xl font-semibold">{t('fxProvider.title')}</p>
        <HideComponent show={invitePermissions.view}>
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <Button
              variant={ButtonVariant.link}
              size={ButtonSize.medium}
              paddingClass="px-3"
              heightClass="h-8"
              onClick={handleInvite}
              disabled={!invitePermissions.edit}
            >
              {t('inviteHelp')}
              <UserAddIcon className="ml-2" />
            </Button>
          </PermissionSection>
        </HideComponent>
      </div>

      <PermissionSection permission={Permissions.fxProvider} showMessage>
        <>
          <p className="text-secondary mb-2">{t('fxProvider.description')}</p>
          <div className="flex items-start p-4 mb-6">
            <InfoCircleSolidIcon className="text-gray-400 flex-shrink-0" />
            <div className="ml-3 text-sm text-gray-700">
              <p className="mb-5">{t('fxProvider.info-1')}</p>
              <p className="mb-5">{t('fxProvider.info-2')}</p>
              <p>{t('fxProvider.info-3')}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            {providers.map((provider) => (
              <div
                className="border rounded-lg shadow-sm p-6"
                key={provider.id}
              >
                <div className="mb-6 flex items-center justify-between">
                  <div className="flex items-center pr-4">
                    <FXProviderLogo
                      id={provider.id}
                      alt={provider.name}
                      className="h-6"
                    />
                    <div className="h-10 w-px bg-gray-200 mx-3" />
                    <span className="text-sm font-medium">{provider.name}</span>
                  </div>
                  {provider.status ? (
                    <ProviderStatus
                      status={provider.status}
                      provider={provider}
                    />
                  ) : (
                    <ProviderDropDown provider={provider} />
                  )}
                </div>
                <div className="flex item-center mb-2">
                  <PhoneIcon className="text-gray-500" />
                  <span className="ml-2 text-sm text-gray-700">
                    {formatPhoneNumber(
                      `${provider.countryCallingCode}${provider.telephoneNumber}`,
                    )}
                  </span>
                </div>
                <div className="flex item-center">
                  <MailIcon className="text-gray-500" />
                  <span className="ml-2 text-sm text-gray-700">
                    {provider.emailAddress}
                  </span>
                </div>
              </div>
            ))}
            <RequestProviderButton />
          </div>
        </>
      </PermissionSection>

      <ProviderDetailsModal />
    </div>
  );
};

export default FXProvider;
