import bgCube from 'assets/images/bg-cube.png';

const Background = () => {
  return (
    <>
      <img
        src={bgCube}
        alt="Bg cube"
        className="pointer-events-none absolute z-0"
        style={{
          height: '66%',
          minHeight: '668px',
          left: '-10px',
          bottom: '-10px',
        }}
      />
      <img
        src={bgCube}
        alt="Bg cube"
        className="pointer-events-none absolute z-0"
        style={{
          height: '66%',
          minHeight: '668px',
          top: '-80px',
          right: '-70px',
        }}
      />
    </>
  );
};

export default Background;
