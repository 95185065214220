import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Badge from 'components/Badge';
import classNames from 'classnames';

interface BadgeConnectorProps {
  image: ReactNode;
  text: string;
  connected: boolean;
  started: boolean;
  fullWidth?: boolean;
}

const BadgeConnector: React.FC<BadgeConnectorProps> = ({
  image,
  text,
  connected,
  started,
  fullWidth,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <div
      className={classNames(
        fullWidth && 'flex-1 mx-[15px]',
        'inline-flex items-center justify-between p-2 bg-gray-50 rounded-10',
      )}
    >
      <div className="flex items-center">
        {image}
        <h6 className="text-sm leading-5 font-semibold text-gray-900 ml-2 mr-1">
          {text}
        </h6>
      </div>

      {connected ? (
        <Badge color="green" rounded="rounded-sm" withDot>
          {t('dashboard:connectors.connected')}
        </Badge>
      ) : started ? (
        <Badge color="blue" rounded="rounded-sm" withDot>
          {t('dashboard:connectors.inProgress')}
        </Badge>
      ) : (
        <Badge color="gray" rounded="rounded-sm" withDot>
          {t('dashboard:connectors.notStarted')}
        </Badge>
      )}
    </div>
  );
};

export default BadgeConnector;
