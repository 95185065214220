export const AddRequestIcon = ({ className }: { className?: string }) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 28.5C24.4183 28.5 28 24.9183 28 20.5C28 16.0817 24.4183 12.5 20 12.5C15.5817 12.5 12 16.0817 12 20.5C12 24.9183 15.5817 28.5 20 28.5ZM21 17.5C21 16.9477 20.5523 16.5 20 16.5C19.4477 16.5 19 16.9477 19 17.5V19.5H17C16.4477 19.5 16 19.9477 16 20.5C16 21.0523 16.4477 21.5 17 21.5H19V23.5C19 24.0523 19.4477 24.5 20 24.5C20.5523 24.5 21 24.0523 21 23.5V21.5H23C23.5523 21.5 24 21.0523 24 20.5C24 19.9477 23.5523 19.5 23 19.5H21V17.5Z"
      fill="currentColor"
    />
    <rect
      x="1"
      y="1.5"
      width="38"
      height="38"
      rx="19"
      stroke="currentColor"
      strokeWidth="2"
      strokeDasharray="4 4"
      opacity="0.4"
    />
  </svg>
);
