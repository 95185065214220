import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';

import bankIcon from 'assets/images/bank-integrator.png';
import { useAppSelector, useAppDispatch } from 'hooks';
import { Input } from 'components/Inputs';
import Button from 'components/Button';
import { getUserCalledName } from '../selectors';
import { updateBankAccount } from '../thunks';
import { setIsSuccess } from '../bankAccountsSlice';
import { AngleRightIcon } from 'components/icons';

const BankAccountName = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();
  const userCalledName = useAppSelector(getUserCalledName);

  const { register, handleSubmit } = useForm<FieldValues>({
    defaultValues: { userCalledName },
  });

  const onSubmit = (data) => {
    dispatch(updateBankAccount(data));
  };

  const handleSkip = () => {
    dispatch(setIsSuccess(true));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-6 pb-6 pt-14 border-b border-gray-200">
        <img
          alt="Banking Integrator"
          src={bankIcon}
          className="mx-auto mb-10"
        />
        <p className="heading-xl mb-2 text-center">{t('bank:bankNameTitle')}</p>
        <p className="text-secondary mb-8 text-center">
          {t('bank:bankNameText')}
        </p>
        <Input
          name="userCalledName"
          label={t('bank:fields.userCalledName.label')}
          register={register}
          optional
        />
      </div>

      <div className="flex items-center justify-between gap-6 px-6 py-5">
        <Button
          type="button"
          className="w-1/2"
          onClick={handleSkip}
          variant="secondary-outline"
          heightClass="h-11"
        >
          {t('common:skip')}
        </Button>
        <Button type="submit" className="w-1/2" heightClass="h-11">
          {t('common:next')}
          <AngleRightIcon className="ml-2" />
        </Button>
      </div>
    </form>
  );
};

export default BankAccountName;
