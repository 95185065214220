import { createSlice } from '@reduxjs/toolkit';

import { BusinessMerchantSchema } from 'services/businesses';
import { ProcessorOption } from 'entities/merchantServices';
import { clearDashboard } from 'pages/dashboard/thunks';

import { setFormValues, defaulFormtValues } from './utils';
import {
  fetchProcessorFields,
  fetchBusinessMerchant,
  changeBusinessMerchant,
  addBusinessMerchant,
  saveBusinessMerchant,
} from './thunks';

interface MerchantServicesStateProps {
  currentProcessor: ProcessorOption | null;
  isLoading: boolean;
  updateForm: boolean;
  fields: any;
  merchant: BusinessMerchantSchema | null;
  formValues: typeof defaulFormtValues;
}

export const initialState: MerchantServicesStateProps = {
  currentProcessor: null,
  isLoading: false,
  updateForm: false,
  fields: [],
  merchant: null,
  formValues: defaulFormtValues,
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setUpdateForm(state, action) {
      state.updateForm = action.payload;
    },
    updateFormValues(state, action) {
      state.formValues = {
        ...state.formValues,
        ...action.payload,
        fields: {
          ...state.formValues.fields,
          ...action.payload.fields,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchProcessorFields.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProcessorFields.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchProcessorFields.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fields = action.payload;
      state.updateForm = true;
    });
    builder.addCase(fetchBusinessMerchant.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBusinessMerchant.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchBusinessMerchant.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload) {
        state.merchant = action.payload;
        state.currentProcessor = {
          value: action.payload.processor_MerchantsId,
          name: action.payload.processor_Name,
        };
        state.formValues = setFormValues(action.payload);
      }

      state.updateForm = true;
    });
    builder.addCase(changeBusinessMerchant.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeBusinessMerchant.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeBusinessMerchant.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.merchant = null;
        state.currentProcessor = null;
        state.fields = [];
      }
    });
    builder.addCase(addBusinessMerchant.fulfilled, (state, action) => {
      if (action.payload) {
        state.merchant = action.payload;
        state.formValues = setFormValues(action.payload);
        state.currentProcessor = {
          value: action.payload.processor_MerchantsId,
          name: action.payload.processor_Name,
        };
        state.updateForm = true;
      } else {
        state.merchant = null;
        state.currentProcessor = null;
        state.fields = [];
      }
    });
    builder.addCase(saveBusinessMerchant.fulfilled, (state, action) => {
      if (action.payload) {
        state.merchant = {
          ...state.merchant,
          ...action.payload,
        };
        state.updateForm = true;
      }
    });
  },
});

export const { setUpdateForm, updateFormValues } = merchantSlice.actions;

export default merchantSlice.reducer;
