import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import Alert, { AlertTypes } from 'components/Alert';
import { Permissions } from 'entities/dashboard';
import { Input } from 'components/Inputs';
import { useAppSelector } from 'hooks';

import { getGeographicSaleTypes, getGeographicSales } from '../selectors';

const GeographicSales = ({ register, setValue, showErrors }) => {
  const { t } = useTranslation('financial');

  const types = useAppSelector(getGeographicSaleTypes);
  const geographicSales = useAppSelector(getGeographicSales);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  useEffect(() => {
    if (types.length) {
      types.map((type, index) => {
        const geographicSale = geographicSales.find(
          (sale) =>
            sale.businessesFinancialsGeographicSalesTypesId ===
            type.businessesFinancialsGeographicSalesTypesId,
        );

        if (geographicSale) {
          setValue(
            `businessesFinancialsGeographicSales.${index}.businessesFinancialsGeographicSalesId`,
            geographicSale.businessesFinancialsGeographicSalesId,
          );
          setValue(
            `businessesFinancialsGeographicSales.${index}.percentageEstimate`,
            geographicSale.percentageEstimate,
          );
        }

        setValue(
          `businessesFinancialsGeographicSales.${index}.businessesFinancialsGeographicSalesTypesId`,
          type.businessesFinancialsGeographicSalesTypesId,
        );
      });
    }
  }, [geographicSales, types, setValue]);

  return (
    <div className="mb-6">
      <p className="text-sm leading-5 font-medium text-gray-700 mt-6 mb-2.5">
        {t('financial:activityDistributed')}
      </p>
      {showErrors &&
        financialPermissions.edit &&
        !geographicSales.some(
          ({ percentageEstimate }) => Number(percentageEstimate) > 0,
        ) && (
          <Alert
            type={AlertTypes.warning}
            title={t('financial:activityDistributedRequired')}
            className="my-2"
            withBorder
          />
        )}
      <div className="grid grid-cols-4 gap-4">
        {types.map((type, index) => (
          <div
            className="w-full"
            key={type.businessesFinancialsGeographicSalesTypesId}
          >
            {geographicSales.length > 0 && (
              <input
                type="hidden"
                {...register(
                  `businessesFinancialsGeographicSales.${index}.businessesFinancialsGeographicSalesId`,
                  { valueAsNumber: true },
                )}
              />
            )}
            <input
              type="hidden"
              {...register(
                `businessesFinancialsGeographicSales.${index}.businessesFinancialsGeographicSalesTypesId`,
                { valueAsNumber: true },
              )}
            />
            <Input
              name={`businessesFinancialsGeographicSales.${index}.percentageEstimate`}
              label={type.description}
              placeholder="0"
              suffix="%"
              suffixInline
              iconStart={
                <img
                  src={type.logoReference}
                  alt={type.typeCode}
                  className="h-[15px]"
                />
              }
              readOnly={!financialPermissions.edit}
              inputClassName="pl-10"
              register={register}
              type="number"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeographicSales;
