import { useTranslation } from 'react-i18next';
import { ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import Button, { ButtonVariant } from 'components/Button';
import PopoverOnHover from 'components/PopoverOnHover';
import { getTheme } from 'theme/selectors';
import { useAppSelector } from 'hooks';
import Badge from 'components/Badge';
import {
  AngleRightIcon,
  AngleLeftIcon,
  ProgressIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from 'components/icons';

export enum TabVariants {
  underline = 'underline',
  button = 'button',
  pill = 'pill',
}

export enum StatusIconTypes {
  success = 'success',
  progress = 'progress',
  error = 'error',
  empty = 'empty',
}

export interface TabProps {
  name: string;
  icon?: ReactNode;
  statusIcon?: StatusIconTypes;
  statusIconPopover?: ReactNode;
  badge?: string;
  disabled?: boolean;
  popover?: ReactNode;
  key?: string;
  hidden?: boolean;
}

export const StatusIcons = {
  [StatusIconTypes.progress]: (
    <ProgressIcon className="ml-2 text-blue-500 w-[18px] h-[18px]" />
  ),
  [StatusIconTypes.error]: (
    <ExclamationCircleIcon className="ml-2 text-yellow-500 w-[18px] h-[18px]" />
  ),
  [StatusIconTypes.success]: (
    <CheckCircleIcon className="ml-2 text-green-500 w-[18px] h-[18px]" />
  ),
  [StatusIconTypes.empty]: null,
};

interface TabsInterface {
  tabs: TabProps[];
  currentTab: number;
  onChange: (id: number) => void;
  className?: string;
  tabClassName?: string;
  footerNavigation?: boolean;
  variant?: TabVariants;
  children?: ReactNode;
}

interface TabInterface {
  tab: TabProps;
  index: number;
  currentTab: number;
  isLastTab: boolean;
  onChange: (id: number) => void;
  tabClassName: string;
  variant: TabVariants;
}

const Tab: React.FC<TabInterface> = ({
  tab,
  index,
  currentTab,
  isLastTab,
  onChange,
  tabClassName,
  variant,
}) => {
  const { t } = useTranslation();

  const { textColor, borderColor } = useAppSelector(getTheme);

  const classes = useMemo(
    () => ({
      normal: {
        [TabVariants.underline]:
          'py-[9px] border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
        [TabVariants.pill]: `py-[9px] text-gray-500 ${
          tab.disabled
            ? 'cursor-default'
            : 'hover:bg-gray-50 hover:text-gray-700'
        }`,
        [TabVariants.button]:
          'py-4 text-gray-500 hover:text-gray-700 focus:z-10 hover:bg-gray-50 relative overflow-hiden border-b-2 border-transparent',
      },
      active: {
        [TabVariants.underline]: 'py-[9px]',
        [TabVariants.pill]: 'py-[9px] bg-white text-gray-900',
        [TabVariants.button]:
          'py-4 text-gray-900 focus:z-10 hover:bg-gray-50 relative overflow-hiden border-b-2',
      },
      button: {
        [StatusIconTypes.progress]: 'border-blue-600',
        [StatusIconTypes.error]: 'border-yellow-600 text-yellow-600',
        [StatusIconTypes.success]: 'border-blue-600',
      },
    }),
    [tab.disabled],
  );

  const styles = {
    active: {
      [TabVariants.underline]: {
        borderColor: borderColor.primary.base,
        color: textColor.primary.base,
      },
    },
  };

  return (
    <div
      style={index === currentTab ? styles.active[variant] : {}}
      className={classNames(
        tabClassName,
        index === currentTab
          ? classes.active[variant]
          : classes.normal[variant],
        variant === TabVariants.button && index === 0 && 'rounded-l-lg',
        variant === TabVariants.button && isLastTab && 'rounded-r-lg',
        variant === TabVariants.button &&
          index === currentTab &&
          classes.button[tab.statusIcon || ''],
        tab.hidden && 'hidden',
        'px-1 group inline-flex items-center font-medium text-xs sm:text-sm cursor-pointer overflow-hidden',
      )}
      aria-current={index === currentTab ? 'page' : undefined}
      aria-hidden="true"
      onClick={() => !tab.disabled && onChange(index)}
    >
      {tab.icon && (
        <div
          className={classNames(
            '-ml-0.5 mr-2 h-5 w-5',
            index === currentTab
              ? textColor.primary.base
              : 'text-gray-400 group-hover:text-gray-500',
          )}
          aria-hidden="true"
        >
          {tab.icon}
        </div>
      )}

      {variant === TabVariants.button && (
        <div className="flex justify-center items-center font-medium flex-shrink-0 mr-2 bg-gray-100 text-gray-800 rounded-full text-xs w-5 h-5">
          {index + 1}
        </div>
      )}

      <span>{t(tab.name)}</span>

      {tab.statusIcon && (
        <>
          {tab.statusIconPopover ? (
            <PopoverOnHover
              key={index}
              infoClassName="z-40"
              button={StatusIcons[tab.statusIcon]}
              info={tab.statusIconPopover}
              onClick={() => !tab.disabled && onChange(index)}
            />
          ) : (
            StatusIcons[tab.statusIcon]
          )}
        </>
      )}

      {tab.badge && (
        <Badge
          color={index === currentTab ? 'blue' : 'gray'}
          rounded="rounded-lg"
          className="ml-2"
        >
          {tab.badge}
        </Badge>
      )}
    </div>
  );
};

const Tabs: React.FC<TabsInterface> = ({
  tabs,
  currentTab,
  onChange,
  className = '',
  tabClassName = '',
  variant = TabVariants.underline,
  footerNavigation,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={classNames('-mb-px flex', className)} aria-label="Tabs">
        {tabs.map((tab, index) =>
          tab.popover ? (
            <PopoverOnHover
              key={index}
              className="w-full"
              wrapperClassName="w-full ml-[1px] first:ml-0"
              infoClassName="z-40"
              button={
                <Tab
                  tab={tab}
                  index={index}
                  currentTab={currentTab}
                  isLastTab={index === tabs.length - 1}
                  tabClassName={tabClassName}
                  variant={variant}
                  onChange={onChange}
                />
              }
              info={tab.popover}
            />
          ) : (
            <Tab
              key={index}
              tab={tab}
              index={index}
              currentTab={currentTab}
              isLastTab={index === tabs.length - 1}
              tabClassName={tabClassName}
              variant={variant}
              onChange={onChange}
            />
          ),
        )}
      </div>

      {children}

      {footerNavigation && (
        <div className="px-6 py-2.5 border-t border-gray-200 flex items-center justify-between">
          <div>
            {currentTab - 1 >= 0 && (
              <Button
                variant={ButtonVariant.linkSecondary}
                onClick={() => onChange(currentTab - 1)}
              >
                <AngleLeftIcon className="mr-1.5" />
                {t(tabs[currentTab - 1]?.name)}
              </Button>
            )}
          </div>

          <div>
            {tabs.length - 1 >= currentTab + 1 && (
              <Button
                variant={ButtonVariant.linkSecondary}
                onClick={() => onChange(currentTab + 1)}
              >
                {t(tabs[currentTab + 1]?.name)}
                <AngleRightIcon className="ml-1.5" />
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tabs;
