import { useTranslation } from 'react-i18next';

interface IconProps {
  icon?: React.ReactNode;
  withDefaultIcon: boolean;
  iconTextLabel?: string;
}
const Icon: React.FC<IconProps> = ({
  icon,
  withDefaultIcon,
  iconTextLabel,
}) => {
  if (icon && !withDefaultIcon) {
    return <>{icon}</>;
  }
  if (!icon && !withDefaultIcon) {
    return null;
  }
  return (
    <div className="h-10 w-10 rounded-full bg-orange-500 flex items-center justify-center">
      <span className="text-base leading-none font-normal text-white">
        {iconTextLabel}
      </span>
    </div>
  );
};

interface TabletProps {
  icon?: React.ReactNode;
  withDefaultIcon?: boolean;
  iconTextLabel?: string;
  onDelete?: () => void;
  onEdit?: () => void;
}

const Tablet: React.FC<TabletProps> = ({
  icon,
  withDefaultIcon = false,
  iconTextLabel,
  children,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation('dashboard');
  return (
    <div className="flex min-w-[186px] flex-row justify-between rounded-full items-center p-2 border-[1px] border-gray-300">
      <div className="flex">
        <Icon
          icon={icon}
          withDefaultIcon={withDefaultIcon}
          iconTextLabel={iconTextLabel}
        />
        <div className="flex flex-col mx-2 justify-center">{children}</div>
      </div>
      <div className="flex">
        <button
          onClick={onEdit}
          className="text-sm px-[10px] py-[1px] border-[1px] border-gray-300 leading-5 rounded-full flex justify-center items-center font-medium text-gray-700 hover:bg-gray-100"
        >
          {t('dashboard:businessPlan.buttons.edit')}
        </button>
        <button
          onClick={onDelete}
          className="text-sm px-[10px] ml-2 py-[1px] border-[1px] border-red-300 leading-5 rounded-full flex justify-center items-center font-medium text-red-700 hover:bg-red-100"
        >
          {t('dashboard:businessPlan.buttons.delete')}
        </button>
      </div>
    </div>
  );
};

export default Tablet;
