import { Trans, useTranslation } from 'react-i18next';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { Logo } from 'components/Logos';
import Modal from 'components/Modal';

const AccessModal = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation('dashboard');

  return (
    <Modal
      isOpen={true}
      closeModal={onCancel}
      maxWidth="680px"
      actions={
        <div className="flex items-center justify-end">
          <Button
            variant={ButtonVariant.secondaryOutline}
            size={ButtonSize.medium}
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button className="ml-6" size={ButtonSize.medium} onClick={onConfirm}>
            {t('common:confirm')}
          </Button>
        </div>
      }
      closeTimeoutMS={0}
    >
      <div className="px-6 pb-6 pt-14">
        <div className="flex items-center mb-6">
          <Logo />
          <p className="ml-3 text-2xl font-medium">
            <Trans i18nKey="dashboard:invitePersons.accessModal.title">
              text<b>bold</b>text
            </Trans>
          </p>
        </div>
        <p className="text-secondary">
          {t('invitePersons.accessModal.description')}
        </p>
      </div>
    </Modal>
  );
};

export default AccessModal;
