import React, { useEffect, useState, useMemo, ReactNode } from 'react';
import classNames from 'classnames';

import { getTheme } from 'theme/selectors';
import { useAppSelector } from 'hooks';
import {
  CheckCircleIcon,
  ExclamationIcon,
  CloseCircleIcon,
  InfoCircleSolidIcon,
  CloseIcon,
} from 'components/icons';

import Button from './Button';

export enum AlertTypes {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

interface AlertProps {
  type: AlertTypes;
  title?: ReactNode | 'string';
  className?: string;
  description?: ReactNode | 'string';
  withBorder?: boolean;
  withClose?: boolean;
  isShow?: boolean;
  paddingClass?: string;
  icon?: AlertTypes;
  button?: ReactNode;
  buttonText?: string;
  buttonClick?: () => void;
  linkText?: string;
  linkClick?: () => void;
  onClose?: () => void;
}

enum ButtonVariants {
  success = 'success',
  warning = 'warning',
  error = 'red',
  info = 'primary',
}

const Alert: React.FC<AlertProps> = ({
  type,
  title,
  className,
  description,
  paddingClass,
  withBorder = false,
  withClose = false,
  onClose,
  isShow = true,
  icon,
  button = null,
  buttonText,
  buttonClick,
  linkText,
  linkClick,
}) => {
  const { backgroundColor, textColor } = useAppSelector(getTheme);
  const [hide, setHide] = useState(false);

  const icons = {
    success: <CheckCircleIcon className="text-green-500" />,
    warning: <ExclamationIcon />,
    error: <CloseCircleIcon />,
    info: <InfoCircleSolidIcon className={textColor.primary.base} />,
  };

  const classes = useMemo(
    () => ({
      success: {
        icon: 'text-green-400',
        text: 'text-green-800',
        bg: 'bg-green-50',
        border: 'border-green-400',
        close: 'text-green-500',
      },
      warning: {
        icon: 'text-yellow-400',
        text: 'text-yellow-800',
        bg: 'bg-yellow-50',
        border: 'border-yellow-400',
        close: 'text-yellow-500',
      },
      error: {
        icon: 'text-red-400',
        text: 'text-red-800',
        bg: 'bg-red-50',
        border: 'border-red-400',
        close: 'text-red-500',
      },
      info: {
        icon: 'text-blue-400',
        text: 'text-blue-800',
        bg: '',
        border: 'border-blue-400',
        close: '',
      },
    }),
    [],
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setHide(true);
  };

  useEffect(() => {
    setHide(!isShow);
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  const stylesContainer = {
    info: {
      backgroundColor: backgroundColor.bage.primary.base,
      color: textColor.primary.base,
    },
  };

  const stylesCloseIcon = {
    info: {
      color: textColor.primary.base,
    },
  };

  return (
    <div
      style={stylesContainer[type] ?? {}}
      className={classNames(
        'flex items-start',
        paddingClass || 'p-4',
        className,
        classes[type].text,
        classes[type].bg,
        withBorder
          ? `border-l-4 rounded-r-md ${classes[type].border}`
          : 'rounded-md',
        hide && 'hidden',
      )}
    >
      <span className={classes[type].icon}>{icons[icon || type]}</span>
      <div className="pl-3 w-full">
        <div className="w-full flex justify-between">
          {title && (
            <div
              className={classNames(
                'text-sm leading-5 font-medium w-full break-word',
                withClose && 'pr-3',
              )}
            >
              {title}
            </div>
          )}

          {withClose && (
            <button className="flex cursor-pointer" onClick={handleClose}>
              <CloseIcon styles={stylesCloseIcon[type] ?? {}} />
            </button>
          )}
        </div>

        {description && (
          <div
            className={classNames(
              'text-sm',
              title && 'mt-2',
              (buttonText || linkText) && 'mb-[10px]',
            )}
          >
            {description}
          </div>
        )}

        <div className="flex items-center">
          {button}
          {buttonText && (
            <Button
              variant={ButtonVariants[type]}
              onClick={buttonClick}
              heightClass="h-[28px]"
              paddingClass="px-2"
              size="medium"
            >
              {buttonText}
            </Button>
          )}
          {linkText && (
            <Button
              variant="link"
              size="medium"
              onClick={linkClick}
              paddingClass="px-2"
              heightClass="h-[28px]"
              className="ml-2"
            >
              <span className={classes[type].text}>{linkText}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
