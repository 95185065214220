import Steps from 'components/Steps';
import { useAppSelector } from 'hooks';
import { getSteps } from '../selectors';

const AccountStepper = () => {
  const step: number = useAppSelector(getSteps);

  return (
    <div className="flex justify-center border-l border-gray-200 px-6 py-1">
      <Steps step={step} stepsLength={3} />
    </div>
  );
};

export default AccountStepper;
