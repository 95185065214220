import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import { setSessionExpired, signOut } from 'pages/login/loginSlice';
import { useCurrentUser, useAppDispatch } from 'hooks';
import { logOut, getOID } from 'utils/authService';
import path from 'common/path';

function IdleWrapper({ children }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { logoutRedirect, isAuthenticated } = useCurrentUser();

  const handleOnIdle = async () => {
    if (isAuthenticated || getOID()) {
      await dispatch(signOut());
      logoutRedirect();
      logOut();
      dispatch(setSessionExpired(true));
      navigate(path.login);
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
  });

  return <>{children}</>;
}

export default IdleWrapper;
