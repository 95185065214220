export const DisconnectIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.0005 10.0005L15.1218 10.8791L13.7076 9.46492L14.5862 8.58624C15.462 7.71043 15.462 6.29047 14.5862 5.41467C13.7104 4.53886 12.2905 4.53886 11.4147 5.41467L10.536 6.29335L9.12177 4.87913L10.0005 4.00045C11.6573 2.3436 14.3436 2.3436 16.0005 4.00045C17.6573 5.65731 17.6573 8.3436 16.0005 10.0005Z"
      fill="currentColor"
    />
    <path
      d="M5.41467 11.4147L6.29335 10.536L4.87913 9.12177L4.00045 10.0005C2.3436 11.6573 2.3436 14.3436 4.00045 16.0005C5.65731 17.6573 8.3436 17.6573 10.0005 16.0005L10.8791 15.1218L9.46492 13.7076L8.58624 14.5862C7.71043 15.462 6.29047 15.462 5.41467 14.5862C4.53886 13.7104 4.53886 12.2905 5.41467 11.4147Z"
      fill="currentColor"
    />
    <path
      d="M5.70756 4.29332C5.31704 3.9028 4.68388 3.9028 4.29335 4.29332C3.90283 4.68384 3.90283 5.31701 4.29335 5.70753L14.2934 15.7075C14.6839 16.0981 15.317 16.0981 15.7076 15.7075C16.0981 15.317 16.0981 14.6838 15.7076 14.2933L5.70756 4.29332Z"
      fill="currentColor"
    />
  </svg>
);
