import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getIsLoading } from 'theme/selectors';
import { domain } from 'utils/domainService';
import { setDomain } from 'theme/themeSlice';
import { fetchTenant } from 'theme/thunks';
import Loading from 'components/Loading';

enum ThemeType {
  paya = 'theme-paya',
  freightx = 'theme-freightx',
  invoicecloud = 'theme-ic',
}

const ThemeProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector(getIsLoading);

  const currentDomain: string = useMemo(() => domain(), []);

  useEffect(() => {
    dispatch(fetchTenant());
    dispatch(setDomain(currentDomain));
    document.documentElement.classList.add(ThemeType[currentDomain] ?? 'smart');
  }, [dispatch, currentDomain]);

  if (isLoading) {
    return <Loading />;
  }

  return <div>{children}</div>;
};

export default ThemeProvider;
