import { useTranslation } from 'react-i18next';

import { ExclamationOutlinedIcon } from 'components/icons';
import { useAppSelector, useAppDispatch } from 'hooks';
import Button from 'components/Button';
import Modal from 'components/Modal';

import { setSessionExpired } from '../loginSlice';
import { getSessionExpired } from '../selectors';

const SessionExpired = () => {
  const { t } = useTranslation('login');
  const dispatch = useAppDispatch();
  const sessionExpired = useAppSelector(getSessionExpired);

  const handleClose = () => {
    dispatch(setSessionExpired(false));
  };

  if (!sessionExpired) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      closeModal={handleClose}
      closeTimeoutMS={0}
      maxWidth="514px"
    >
      <div className="p-12">
        <div className="flex justify-center">
          <div className="mt-4 mb-8 rounded-full bg-yellow-50 p-3">
            <ExclamationOutlinedIcon className="text-yellow-500" />
          </div>
        </div>
        <p className="heading-xl mb-2 text-center">
          {t('login:sessionExpiredTitle')}
        </p>
        <p className="text-secondary mb-6 text-center">
          {t('login:sessionExpiredText')}
        </p>
        <Button onClick={handleClose} className="w-full bg-blue-600">
          {t('common:okay')}
        </Button>
      </div>
    </Modal>
  );
};

export default SessionExpired;
