import Select, { components as selectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import colors from 'tailwindcss/colors';
import classNames from 'classnames';
import { useEffect } from 'react';

import { CheckIcon, SearchIcon } from 'components/icons';
import Label from 'components/Inputs/components/Label';
import { getTheme } from 'theme/selectors';
import { useAppSelector } from 'hooks';

import ErrorMessage from './Inputs/components/ErrorMessage';

const DropdownIndicator = (props) => (
  <selectComponents.DropdownIndicator {...props}>
    <SearchIcon />
  </selectComponents.DropdownIndicator>
);

const defaultFormatOption = (option: any, { context, selectValue }: any) => {
  const isSelected = selectValue.find(
    (selected) => selected.value === option.value,
  );

  return (
    <div
      className={classNames(
        'flex items-center relative',
        context === 'menu' && isSelected && 'pr-6',
      )}
    >
      <span>{option.name}</span>
      {context === 'menu' && isSelected && (
        <span className="absolute inset-y-0 right-0 flex items-center">
          <CheckIcon className="selected-icon" aria-hidden="true" />
        </span>
      )}
    </div>
  );
};

const Autocomplete: React.FC<any> = ({
  name,
  value,
  options,
  onChange,
  label,
  className,
  info,
  description,
  optional,
  error,
  setValue,
  formatOptionLabel,
  async,
  loadOptions,
  placeholder = 'Select...',
  disabled,
  customStyles = {},
  components = {},
  cacheOptions = false,
  ...otherProps
}) => {
  const { textColor } = useAppSelector(getTheme);

  useEffect(() => {
    if (value && setValue) {
      setValue(name, value.value || '', { shouldValidate: true });
    }
  }, [value, name, setValue]);

  const handleSelect = (newValue: any) => {
    if (setValue) {
      setValue(name, newValue.value, { shouldValidate: true });
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      borderColor: error
        ? colors.red[300]
        : state.isFocused
        ? colors.indigo[500]
        : colors.gray[300],
      borderRadius: 6,
      boxShadow: 'none',
      ':hover': {
        borderColor: error
          ? colors.red[500]
          : state.isFocused
          ? textColor.primary.base
          : textColor.primary.base,
      },
      minHeight: 40,
      backgroundColor: state.isDisabled ? colors.gray[100] : 'transparent',
    }),
    input: (base) => ({
      ...base,
      cursor: 'text',
      ' input:focus': {
        boxShadow: 'none',
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '0.875rem',
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.gray[700],
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: colors.gray[50],
      borderRadius: 20,
      padding: 6,
    }),
    multiValueRemove: (base) => ({
      ...base,
      ':hover': {},
    }),
    option: (base, { isFocused, isSelected, isDisabled }) => ({
      ...base,
      cursor: 'pointer',
      wordBreak: 'break-all',
      backgroundColor: isFocused ? colors.gray[100] : 'transparent',
      color: isSelected ? colors.gray[900] : colors.gray[700],
      opacity: isDisabled ? 0.5 : 1,
      fontWeight: isSelected ? 600 : 400,
      ':active': {
        backgroundColor: colors.gray[100],
      },
      '.selected-icon': {
        color: colors.green[500],
      },
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
      marginTop: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (base) => ({
      ...base,
      ...(customStyles.valueContainer || {}),
    }),
    indicatorsContainer: (base) => ({
      ...base,
      ...(customStyles.indicatorsContainer || {}),
    }),
  };

  return (
    <div className={className}>
      {label && (
        <Label info={info} description={description} optional={optional}>
          {label}
        </Label>
      )}

      {async ? (
        <AsyncSelect
          value={value}
          onChange={handleSelect}
          loadOptions={loadOptions}
          formatOptionLabel={formatOptionLabel || defaultFormatOption}
          components={{ DropdownIndicator, ...components }}
          styles={styles}
          placeholder={placeholder}
          isDisabled={disabled}
          defaultOptions
          cacheOptions={cacheOptions}
        />
      ) : (
        <Select
          value={value}
          options={options}
          onChange={handleSelect}
          formatOptionLabel={formatOptionLabel || defaultFormatOption}
          components={{ DropdownIndicator, ...components }}
          placeholder={placeholder}
          isDisabled={disabled}
          {...otherProps}
          styles={styles}
        />
      )}

      <ErrorMessage error={error} />
    </div>
  );
};

export default Autocomplete;
