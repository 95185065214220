import { useState, useEffect, ReactNode, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';

type PopoverProps = {
  opened?: boolean;
  button: ReactNode;
  content: ReactNode;
  placement?: Placement;
  overlay?: boolean;
  withModal?: boolean;
  stopPropagation?: boolean;
};

const addEvent = (func) => {
  document.addEventListener('mousedown', func);
};

const removeEvent = (func) => {
  document.removeEventListener('mousedown', func);
};

const CustomPopover: React.FC<PopoverProps> = ({
  opened = false,
  button,
  content,
  placement = 'bottom-start',
  overlay = false,
  withModal = false,
  stopPropagation = false,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [open, setOpen] = useState<boolean>(opened);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
    },
  );
  const popovers =
    document.getElementById('popovers') || document.createElement('div');
  const modals = Array.from(
    document.getElementsByClassName('ReactModalPortal'),
  );

  const getOpenedModal = useCallback(
    (event) => {
      let modal: any = null;

      if (withModal) {
        modals.forEach((element) => {
          if (element.firstChild) {
            modal = element;
          }
        });
      }

      return !modal || !modal.contains(event.target);
    },
    [withModal, modals],
  );

  const handleOpen = (event) => {
    setOpen(!open);

    if (update) {
      update();
    }

    if (stopPropagation) {
      event.stopPropagation();
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !popperElement?.contains(event.target) &&
        !referenceElement?.contains(event.target) &&
        getOpenedModal(event)
      ) {
        setOpen(false);
      }
    },
    [popperElement, referenceElement, getOpenedModal],
  );

  useEffect(() => {
    return () => removeEvent(handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  useEffect(() => {
    if (!document.getElementById('popovers')) {
      popovers.setAttribute('id', 'popovers');
      document.getElementById('root')?.after(popovers);
    }
  }, [popovers]);

  useEffect(() => {
    if (open) {
      addEvent(handleClickOutside);
    } else {
      removeEvent(handleClickOutside);
    }
  }, [open, handleClickOutside]);

  return (
    <Popover>
      <>
        <div ref={setReferenceElement} onClick={handleOpen} aria-hidden="true">
          {button}
        </div>

        {ReactDOM.createPortal(
          <div className={open ? '' : 'hidden'}>
            <div
              className={classNames(
                open && overlay ? 'fixed inset-0 opacity-30' : 'opacity-0',
                'bg-black z-40',
              )}
              onClick={handleOpen}
              aria-hidden="true"
            />
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-50"
              static
            >
              {content}
            </Popover.Panel>
          </div>,
          popovers,
        )}
      </>
    </Popover>
  );
};

export default CustomPopover;
