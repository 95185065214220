export const AddUserGray = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_7181_10348)">
      <path
        opacity="0.3"
        d="M15.5001 6.66667H13.8334C13.3732 6.66667 13.0001 6.29357 13.0001 5.83333C13.0001 5.3731 13.3732 5 13.8334 5H15.5001V3.33333C15.5001 2.8731 15.8732 2.5 16.3334 2.5C16.7937 2.5 17.1667 2.8731 17.1667 3.33333V5H18.8334C19.2937 5 19.6667 5.3731 19.6667 5.83333C19.6667 6.29357 19.2937 6.66667 18.8334 6.66667H17.1667V8.33333C17.1667 8.79357 16.7937 9.16667 16.3334 9.16667C15.8732 9.16667 15.5001 8.79357 15.5001 8.33333V6.66667ZM8.00008 9.16667C6.15913 9.16667 4.66675 7.67428 4.66675 5.83333C4.66675 3.99238 6.15913 2.5 8.00008 2.5C9.84103 2.5 11.3334 3.99238 11.3334 5.83333C11.3334 7.67428 9.84103 9.16667 8.00008 9.16667Z"
        fill="#6B7280"
      />
      <path
        d="M0.500543 16.8333C0.823549 12.8561 4.05159 10.834 7.98612 10.834C11.976 10.834 15.2541 12.745 15.4983 16.834C15.508 16.9969 15.4983 17.5007 14.8722 17.5007C11.7843 17.5007 7.1956 17.5007 1.10625 17.5007C0.89726 17.5007 0.482949 17.05 0.500543 16.8333Z"
        fill="#6B7280"
      />
    </g>
    <defs>
      <clipPath id="clip0_7181_10348">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
