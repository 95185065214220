export enum BusinessPlanModalSteps {
  aboutCompetitors = 'aboutCompetitors',
  aboutMarketing = 'aboutMarketing',
  aboutRevenueAndExpenses = 'aboutRevenueAndExpenses',
  aboutBusiness = 'aboutBusiness',
  aboutCustomer = 'aboutCustomer',
}

export enum StartAndEndSteps {
  started = 'started',
  success = 'success',
}

export enum BusinessPlan {
  sellProducts = 'sellProducts',
  sellServices = 'sellServices',
  sellOther = 'sellOther',
  industryTypesId = 'industryTypesId',
  kindofProducts = 'kindofProducts',
  likeYourProducts = 'likeYourProducts',
  instore = 'instore',
  online = 'online',
  others = 'others',
  currencyCodesId = 'currencyCodesId',
  storeSales = 'storeSales',
  onlieSales = 'onlieSales',
  adultBuy = 'adultBuy',
  teenBuy = 'teenBuy',
  childrenBuy = 'childrenBuy',
  infantBuy = 'infantBuy',
  businessProducts = 'businessProducts',
  personalProducts = 'personalProducts',
  demographicTypesId = 'demographicTypesId',
  geogrphicTypesId = 'geogrphicTypesId',
  customerIndustryTypesId = 'customerIndustryTypesId',
  purchaseReason = 'purchaseReason',
  bigBusiness = 'bigBusiness',
  mediumBusiness = 'mediumBusiness',
  smallBusiness = 'smallBusiness',
  emailAds = 'emailAds',
  onlineAds = 'onlineAds',
  socialMediaAds = 'socialMediaAds',
  printedAds = 'printedAds',
  phoneCalls = 'phoneCalls',
  othersAds = 'othersAds',
  typeInstore = 'typeInstore',
  typeOnline = 'typeOnline',
  typeOthers = 'typeOthers',
  'expensesJSON' = 'expensesJSON',
  'marketingJSON' = 'marketingJSON',
  'rolesJSON' = 'rolesJSON',
  'ownershipsJSON' = 'ownershipsJSON',
  'competitorsJSON' = 'competitorsJSON',
}
