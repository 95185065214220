import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { useAppDispatch } from 'hooks';
import { CheckCircleIcon, ChevronRightIcon } from 'components/icons';
import { setIsModalOpened } from '../submitDashboardSlice';

const InformationReceived = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setIsModalOpened(false));
  };

  return (
    <div className="p-6 h-full flex items-center">
      <div className="w-full">
        <p className="heading-2xl mb-6 flex items-center justify-center w-full">
          <CheckCircleIcon className="mr-4 text-green-500" />
          {t('dashboard:submitModal.receivedTitle')}
        </p>
        <p className="leading-6 font-medium text-gray-500 mb-15 max-w-2xl text-center mx-auto">
          {t('dashboard:submitModal.receivedInfo')}
        </p>
        {/* <div className="text-center mb-4">
          <a target="_blank" href="https://paya.smart-hub.com" rel="noreferrer">
            <Button
              size="large"
              rounded="xl"
              paddingClass="p-6"
              className="w-full max-w-md"
              heightClass="h-auto"
            >
              <div className="w-full flex items-center justify-between">
                {t('dashboard:submitModal.goToPortal')}
                <ChevronRightIcon />
              </div>
            </Button>
          </a>
        </div> */}
        <div className="text-center">
          <Button
            variant="link-secondary"
            size="medium-large"
            paddingClass="px-3"
            onClick={handleClose}
          >
            <ChevronRightIcon className="mr-1.5 rotate-180" />
            {t('dashboard:submitModal.goToOnboarding')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InformationReceived;
