import { createSlice } from '@reduxjs/toolkit';

import { CrossBorderProviderXrefSchema } from 'services/businesses';
import { CrossBorderProviderSchema } from 'services/crossborder';
import { clearDashboard } from 'pages/dashboard/thunks';

import { deleteFXProvider, createFXProvider } from './thunks';
import { fetchCrossBorder } from '../Transactional/thunks';

export enum FXProviderModalTypes {
  new = 'new',
  existed = 'existed',
}

type InitialStateType = {
  detailsModalOpened: FXProviderModalTypes | '';
  provider: any;
  providers: CrossBorderProviderSchema[];
  businessProviders: CrossBorderProviderXrefSchema[];
  logos: any;
};

export const initialState: InitialStateType = {
  detailsModalOpened: '',
  provider: null,
  providers: [],
  businessProviders: [],
  logos: [],
};

const fxProviderSlice = createSlice({
  name: 'fxProvider',
  initialState,
  reducers: {
    setDetailsModalOpened(state, action) {
      state.detailsModalOpened = action.payload;
    },
    setProvider(state, action) {
      state.provider = action.payload;
    },
    addFXProviderLogo(state, action) {
      state.logos = [...state.logos, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchCrossBorder.fulfilled, (state, action) => {
      state.providers = action.payload.crossBorderProviders;
      state.businessProviders =
        action.payload.businessCrossBorderProviders.filter((p) => p.isActive);
    });
    builder.addCase(createFXProvider.fulfilled, (state, action) => {
      state.businessProviders = action.payload;
    });
    builder.addCase(deleteFXProvider.fulfilled, (state, action) => {
      state.businessProviders = action.payload;
    });
  },
});

export const { setDetailsModalOpened, setProvider, addFXProviderLogo } =
  fxProviderSlice.actions;

export default fxProviderSlice.reducer;
