import { CountryBusinessDocumentTypeXrefSchema } from 'services/countries';
import { BusinessDocumentSchema } from 'services/businessDocuments';
import { ERPPreferencesDataSchema } from 'services/erp';

export interface ApplicantProps {
  applicantName: string;
  applicantEmail: string;
  applicantPhone: string;
  applicantRole: string;
}

export interface BusinessDocumentType extends BusinessDocumentSchema {
  id: number;
}

export interface BusinessInfoState {
  documents: BusinessDocumentSchema[];
  applicant: ApplicantProps;
  erpPreferences: ERPPreferencesDataSchema | null;
  isFormUpdate: boolean;
  showDoingBusinessAs: boolean;
  loadingProgress: number;
  idTypes: CountryBusinessDocumentTypeXrefSchema[];
  idTypesCountryId: number;
}

export enum BusinessDocumentTypes {
  federalTaxId = 1,
  dbaStatement = 2,
  centralIndexKey = 3,
}
