import Lottie from 'lottie-react';
import * as animationData from './loader.json';

const LottieControl = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    style: {
      height: 50,
      width: 50,
    },
  };

  return <Lottie {...defaultOptions} />;
};

export default LottieControl;
