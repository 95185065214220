import { useTranslation } from 'react-i18next';

import SubmitDashboard from 'components/submitDashboard/SubmitDashboard';
import InvitePersons from 'components/InvitePersons/InvitePersons';
import BusinessSelector from 'components/BusinessSelector';
import Compliance from 'components/compliance/Compliance';
import ContactSupport from 'components/ContactSupport';
import { LogoWithText } from 'components/Logos';
import Popover from 'components/Popover';
import Button from 'components/Button';

const Header = ({ logged = false }: { logged?: boolean }) => {
  const { t } = useTranslation('common');

  return (
    <div
      className="flex flex-wrap justify-center md:justify-between items-center px-4 sm:px-15 py-[13px] sm:py-6 bg-white w-full"
      style={{
        boxShadow: logged ? 'inset 0px -1px 3px 0px rgb(0 0 0 / 20%)' : 'none',
      }}
    >
      <LogoWithText className="hidden sm:block max-h-[44px]" />

      {logged ? (
        <div className="flex flex-wrap items-center">
          <InvitePersons />
          <BusinessSelector />
          <SubmitDashboard />
          <Compliance />
        </div>
      ) : (
        <div className="flex items-center sm:ml-6">
          <div className="text-right mr-6">
            <p className="text-sm leading-5 font-semibold text-gray-700">
              {t('whereStart')}
            </p>
            <p className="text-xs leading-4 text-gray-500">
              {t('helpSpecialist')}
            </p>
          </div>

          <Popover
            button={
              <Button
                type="button"
                className="flex-shrink-0 whitespace-nowrap"
                heightClass="h-9.5 sm:h-[50px]"
              >
                {t('contactUs')}
              </Button>
            }
            content={<ContactSupport />}
            overlay={true}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
