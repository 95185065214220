import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';
import { sort } from 'ramda';
import moment from 'moment';
import * as yup from 'yup';

import PermissionSection from 'components/permission/PermissionSection';
import { AddRequestIcon } from 'components/icons/AddRequestIcon';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BusinessRequest } from 'common/compliance';
import { Permissions } from 'entities/dashboard';
import { Input } from 'components/Inputs';
import Button from 'components/Button';
import Person from 'components/Person';
import Badge from 'components/Badge';

import { getRequests } from '../selectors';
import { sendMessage } from '../thunks';

export const getFormSchema = (t) =>
  yup.object().shape({
    message: yup
      .string()
      .trim()
      .required(t('dashboard:compliance.messageRequired')),
  });

const RequestInfoTab = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const requests = useAppSelector(getRequests);
  const compliancePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.compliance),
  );

  const [showForm, setShowForm] = useState<boolean>(requests.length === 0);
  const [showMessages, setShowMessages] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: { message: '' },
    resolver: yupResolver(getFormSchema(t)),
  });

  const handleCancel = () => {
    setValue('message', '');
  };

  const openMessage = (id: number) => {
    if (showMessages.includes(id)) {
      setShowMessages(showMessages.filter((messageId) => messageId !== id));
    } else {
      setShowMessages([...showMessages, id]);
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const result = await dispatch(sendMessage(data.message));
    setIsLoading(false);

    if (!result.error) {
      handleCancel();
    }
  };

  const sortedRequests = sort(
    (a: BusinessRequest, b: BusinessRequest) =>
      Date.parse(b.createdOn) - Date.parse(a.createdOn),
    requests,
  );

  return (
    <div className="h-full p-6">
      {showForm ? (
        <div>
          <p className="heading-lg mb-4">
            {t('dashboard:compliance.requestTab')}
          </p>
          <p className="text-secondary mb-1">
            {t('dashboard:compliance.requestTabMessage')}
          </p>
          <form id="request-info-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="message"
              type="textarea"
              register={register}
              rows={4}
              error={errors.message}
              optional
              readOnly={!compliancePermissions.edit}
            />
            <div className="flex items-center justify-end mt-4">
              <PermissionSection
                permission={Permissions.compliance}
                showPopover
                edit
              >
                <Button
                  variant="link-secondary"
                  size="medium"
                  className="mr-2"
                  disabled={!compliancePermissions.edit}
                  onClick={handleCancel}
                >
                  {t('common:cancel')}
                </Button>
              </PermissionSection>
              <PermissionSection
                permission={Permissions.compliance}
                showPopover
                edit
              >
                <Button
                  variant="secondary-outline"
                  size="medium"
                  type="submit"
                  disabled={!compliancePermissions.edit}
                  loading={isLoading}
                >
                  {t('dashboard:compliance.sendRequest')}
                </Button>
              </PermissionSection>
            </div>
          </form>
        </div>
      ) : (
        <PermissionSection permission={Permissions.compliance} showPopover edit>
          <button
            className={classNames(
              'rounded-full flex items-center border text-gray-400 border-gray-300 py-2 pl-2 pr-4 text-sm font-medium',
              {
                'opacity-50': !compliancePermissions.edit,
                'cursor-pointer hover:bg-gray-50 hover:text-blue-600':
                  compliancePermissions.edit,
              },
            )}
            onClick={() =>
              compliancePermissions.edit ? setShowForm(true) : null
            }
          >
            <AddRequestIcon className="mr-3" />
            <span className="text-blue-600">
              {t('dashboard:compliance.requestTab')}
            </span>
          </button>
        </PermissionSection>
      )}

      {requests.length > 0 && (
        <p className="heading-lg mb-4 flex items-center mt-9">
          {t('dashboard:compliance.prevRequests')}
          <Badge color="gray" className="ml-2">
            {requests.length}
          </Badge>
        </p>
      )}
      {sortedRequests.map((request: BusinessRequest) => {
        const nameParts = request.subject.split(' ');
        const initials = (nameParts[0]?.[0] || '') + (nameParts[1]?.[0] || '');

        return (
          <div
            className="flex items-start w-full p-3 bg-gray-50 mb-4 rounded-[8px]"
            key={request.businessesRequestsId}
            onClick={() => openMessage(request.businessesRequestsId)}
            aria-hidden="true"
          >
            <div className="w-10 mr-3 flex-shrink-0">
              <Person name={initials} index={1} size="large" disabled />
            </div>
            <div className="w-full overflow-hidden">
              <div className="flex items-center w-full justify-between">
                <p className="text-sm font-semibold text-gray-700">
                  {request.subject}
                </p>
                <p className="text-secondary">
                  {moment(request.createdOn).format(
                    'ddd, MMM DD YYYY hh:mm:ss A',
                  )}{' '}
                  (ET)
                </p>
              </div>
              {showMessages.includes(request.businessesRequestsId) ? (
                <p className="text-sm mt-2">{request.messageText}</p>
              ) : (
                <p className="text-sm mt-2 truncate">{request.messageText}</p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RequestInfoTab;
