const PopoverCard = ({
  badge,
  title,
  text,
  icon,
  rgbColor = '59,130,246',
  iconClass = '',
}) => (
  <div className="py-2">
    <div
      className="p-4 relative rounded-md"
      style={{ backgroundColor: 'rgba(255,255,255,0.08)' }}
    >
      <img
        src={icon}
        alt="Account payable"
        className={`${iconClass || 'absolute top-[15px] left-[-16px]'}`}
      />
      <div className="flex items-center mb-2.5">
        <div className="w-9 h-9" />
        <span
          className="inline-flex items-center font-medium ml-2.5 rounded-full text-xs py-0.5 px-2.5"
          style={{
            color: `rgb(${rgbColor})`,
            backgroundColor: `rgba(${rgbColor},0.2)`,
          }}
        >
          {badge}
        </span>
      </div>
      <p className="text-gray-50 font-bold mb-1.5">{title}</p>
      <p className="text-gray-300 text-xs max-w-[225px]">{text}</p>
    </div>
  </div>
);

export default PopoverCard;
