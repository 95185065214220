import { ERPProviders } from 'entities/accounting';
import { ERPConnectorSchema, ErpIntegratorSchema } from 'services/erp';
import { trimStr } from 'utils/helpers';

export const ERPGuideFiles = {
  [ERPProviders.quickbooks]: {
    file: 'qbo_update_integration_guide.pdf',
    text: 'Token / Bank Account update guide',
    name: 'QuickBooks Integration Guide - Token Connections and Bank Account changes',
  },
  [ERPProviders.intacct]: {
    file: 'sage_update_integration_guide.pdf',
    text: 'Reconciliation Guide',
    name: 'Sage Intacct Reconciliation Guide v1.1',
  },
};

export const getMappedConnectors = (
  connectors: ERPConnectorSchema[],
  integrators: ErpIntegratorSchema[],
) =>
  connectors.map((connector) => {
    const integrator = integrators.find(
      (integ) => integ.erpdbIntegratorsId === connector.connectorId,
    );

    return {
      ...connector,
      logoFileShareUri: integrator?.logoFileShareUri ?? '',
      guideFileShareUri: integrator?.guideFileShareUri ?? '',
      guideText: integrator?.guideText ?? '',
      helpText: integrator?.helpText ?? '',
    };
  });

export const mapedSaveAccountsData = ({
  businessesId,
  connectorId,
  createdBy,
  modifiedBy,
  jsonData,
  erpaccountsId,
  erpaccountStatusesId,
}: any) => {
  const data: any = {
    erpaccountsId: erpaccountsId ?? null,
    createdBy,
    businessesId,
    connectorId,
    jsone: jsonData,
    isActive: true,
  };

  if (modifiedBy) {
    data.modifiedBy = modifiedBy;
  }

  if (erpaccountStatusesId) {
    data.erpaccountStatusesId = erpaccountStatusesId;
  }

  return data;
};

export const getPopupSize = () => {
  const width = 800;
  const height = 650;
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;

  return `top=${top},left=${left},width=${width},height=${height}`;
};

export const openPopup = (url, callback, popup = null) => {
  const options = getPopupSize();

  const win = popup || window.open('', '_blank', options);

  if (win) {
    win.location.href = url;

    const pollOAuth = window.setInterval(function () {
      if (win.window === null) {
        window.clearInterval(pollOAuth);
      }

      try {
        if (win.document.URL.indexOf('success') != -1) {
          window.clearInterval(pollOAuth);
          win.close();

          if (callback) {
            callback();
          }
        }
      } catch (e) {}
    }, 100);
  }
};

export const getJsonUpdated = (formData, json) => {
  const array = json ? JSON.parse(json) : [];

  let result = false;

  for (const key in formData) {
    const oldField = array.find((field) => field.key === key);

    if (!oldField || oldField.value !== trimStr(formData[key])) {
      result = true;
      break;
    }
  }

  return result;
};

export const mapedJsonData = (formData, auths) => {
  const keys = Object.keys(formData);

  const mapedData = keys.map((key, index) => ({
    ...auths[index],
    value: trimStr(formData[key]),
  }));

  return mapedData;
};
