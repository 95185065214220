import {
  BusinessBPSTrackSchema,
  BusinessBPSTrackPaymentSchema,
} from 'services/businesses';

export const PAYMENT_TYPES = [
  {
    id: 1,
    title: 'ACH',
    info: '',
    isActive: false,
  },
  {
    id: 2,
    title: 'RTP',
    info: '',
    isActive: false,
  },
  {
    id: 3,
    title: 'TRF',
    info: '',
    isActive: true,
  },
];

export interface ConfigurationState {
  defaultBankAccountId: number;
  bpsTrack: BusinessBPSTrackSchema | null;
  payments: BusinessBPSTrackPaymentSchema[];
  isLoading: boolean;
}
