import { ExclamationCircleIcon } from 'components/icons';

const ErrorIcon = () => {
  return (
    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <ExclamationCircleIcon className="text-red-500" />
    </div>
  );
};

export default ErrorIcon;
