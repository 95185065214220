import classNames from 'classnames';

import { ExclamationIcon, CheckCircleIcon } from 'components/icons';
import { ERPAcountStatuses } from 'entities/accounting';

interface AccountingStatusProps {
  status: string;
}

const styles = {
  bg: {
    1: 'bg-red-100',
    2: 'bg-green-100',
  },
  text: {
    1: 'text-red-800',
    2: 'text-green-800',
  },
  icon: {
    1: 'text-red-400',
    2: 'text-green-400',
  },
};

const statusIcons = {
  1: <ExclamationIcon className="w-[14px] h-[14px]" />,
  2: <CheckCircleIcon className="w-[14px] h-[14px]" />,
};

const AccountingStatus: React.FC<AccountingStatusProps> = ({ status }) => {
  const badgeType = status === ERPAcountStatuses.connected ? 2 : 1;

  return (
    <div className="flex">
      <div
        className={classNames(
          'rounded text-xs font-medium py-[2px] pl-[3px] pr-2 flex items-center flex-shrink-0 self-end',
          styles.bg[badgeType],
          styles.text[badgeType],
        )}
      >
        {statusIcons[badgeType] && (
          <span className={styles.icon[badgeType]}>
            {statusIcons[badgeType]}
          </span>
        )}
        <span className="ml-[2px]">{status}</span>
      </div>
    </div>
  );
};

export default AccountingStatus;
