import { createSelector } from '@reduxjs/toolkit';

import { PermissionSchema } from 'services/security';
import { getPersonLetters } from 'utils/helpers';
import { TC_ROLES } from 'common/compliance';
import { RootState } from 'state/store';

import { PermissionType, Person } from './invitePersonsSlice';

export const getRoot = (state: RootState) => state.invitePersons;

export const getProfile = (state: RootState) => state.profile;

export const getInvitePersonsData = createSelector(
  [getRoot],
  (invitePersons) => invitePersons,
);

export const getErrors = createSelector([getRoot], ({ errors }) => errors);

export const getShowMembers = createSelector(
  [getRoot],
  ({ showMembers }) => showMembers,
);

export const getPersons = createSelector([getRoot], ({ persons }) =>
  persons.map((item) => ({
    ...item,
    personsEmailAddress: item.personsEmailAddress.toLowerCase(),
    avatarLetters: getPersonLetters(
      `${item.personsGivenName1} ${item.personsSurnameFirst}`,
    ).toUpperCase(),
  })),
);

export const getVisiblePersons = createSelector(
  [getRoot, getProfile],
  ({ persons }, { person }) =>
    persons
      .filter(
        (item) =>
          item.businessesAdmin ||
          (!TC_ROLES.includes(
            (item.businessesPersonsRole || '').toLowerCase(),
          ) &&
            item.personsId !== person.personsId),
      )
      .map((item) => ({
        ...item,
        personsEmailAddress: item.personsEmailAddress.toLowerCase(),
        avatarLetters: getPersonLetters(
          `${item.personsGivenName1} ${item.personsSurnameFirst}`,
        ).toUpperCase(),
      })),
);

export const getPermissionType = createSelector(
  [getRoot, (_, person: Person | null) => person],
  ({ permissionType }, person) =>
    person ? person.permissionType : permissionType,
);

export const getPermissions = createSelector(
  [getRoot],
  ({ permissions }) => permissions,
);

export const getPersonPermissions = createSelector(
  [getRoot],
  ({ personPermissions }) => personPermissions,
);

export const getValidPermissions = createSelector(
  [getRoot],
  ({ personPermissions, permissionType }) => {
    if (!permissionType) {
      return false;
    }

    if (
      permissionType === PermissionType.view &&
      !personPermissions.find((p: PermissionSchema) =>
        p.permissions_Code.toLowerCase().endsWith(`_${PermissionType.view}`),
      )
    ) {
      return false;
    }

    if (
      permissionType === PermissionType.edit &&
      !personPermissions.find((p: PermissionSchema) =>
        p.permissions_Code.toLowerCase().endsWith(`_${PermissionType.edit}`),
      )
    ) {
      return false;
    }

    return true;
  },
);

export const getGrandAccess = createSelector(
  [getRoot],
  ({ grandAccess }) => grandAccess,
);

export const getPersonsLables = createSelector(
  [getRoot, getProfile],
  ({ persons }, { person }) => {
    return persons
      ?.filter(
        (item) =>
          item.businessesAdmin ||
          (!TC_ROLES.includes(
            (item.businessesPersonsRole || '').toLowerCase(),
          ) &&
            item.personsId !== person.personsId),
      )
      .map(
        ({ personsGivenName1, personsSurnameFirst, personsEmailAddress }) => {
          const firstLetter =
            personsGivenName1?.[0]?.toUpperCase() ||
            personsEmailAddress[0].toUpperCase();
          const secondLetter =
            personsSurnameFirst?.[0]?.toUpperCase() ||
            personsEmailAddress[1].toUpperCase();

          return `${firstLetter}${secondLetter}`;
        },
      );
  },
);
