import { get, set, rm } from 'lockr';

import { TenantSchema } from 'services/tenants';

const configIsValid = (date: string) =>
  new Date().getDate() === new Date(parseInt(date, 10)).getDate();

export function performUpdate() {
  set('themeExpired', new Date().getTime().toString());
}

export const getTenantFromStorage = (subDomain: string) => {
  const themeConfigs = get('themeConfigs');

  if (themeConfigs?.[subDomain] && configIsValid(themeConfigs.createdOn)) {
    return themeConfigs[subDomain];
  }

  rm('themeConfigs');

  return null;
};

export const setTenantToStorage = (tenant: TenantSchema) => {
  set('themeConfigs', {
    [tenant.urlsub]: tenant,
    createdOn: new Date().getTime().toString(),
  });
};
