import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';

import { ERPAcountStatuses, ERPProviders } from 'entities/accounting';
import { CalendarIcon } from 'components/icons/CalendarIcon';
import { hasPermission } from 'pages/dashboard/selectors';
import Alert, { AlertTypes } from 'components/Alert';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';
import Button from 'components/Button';

interface ConnectedAccountFooterProps {
  account: any;
  onUpdate: (account: any) => void;
}

const ConnectedAccountFooter: React.FC<ConnectedAccountFooterProps> = ({
  account,
  onUpdate,
}) => {
  const { t } = useTranslation('dashboard');

  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  const { erpaccountStatus, refreshTokenNextExpiryDate, connectorName } =
    account;

  const daysLeft = refreshTokenNextExpiryDate
    ? moment(refreshTokenNextExpiryDate).diff(moment(), 'days', true)
    : 0;

  const handleClick = () => {
    onUpdate(account);
  };

  return (
    <div>
      {erpaccountStatus !== ERPAcountStatuses.connected && (
        <Alert
          description={t(
            `dashboard:accounting.info.${
              daysLeft < 0 ? 'alertExpired' : 'alertNotValidated'
            }`,
            { name: connectorName },
          )}
          type={AlertTypes.error}
          icon={AlertTypes.warning}
          buttonText={
            erpPermissions.edit
              ? t('dashboard:accounting.info.validateConnection')
              : ''
          }
          buttonClick={erpPermissions.edit ? handleClick : undefined}
          // linkText={t('common:guide')}
          // linkClick={() => {}}
        />
      )}

      {erpaccountStatus === ERPAcountStatuses.connected &&
        (daysLeft > 0 || refreshTokenNextExpiryDate === null) && (
          <>
            {refreshTokenNextExpiryDate && daysLeft < 7 ? (
              <Alert
                description={
                  <Trans
                    i18nKey="dashboard:accounting.info.alertExpireDays"
                    values={{
                      name: connectorName,
                      days: daysLeft > 2 ? 7 : 2,
                    }}
                  >
                    text<b>days</b>text
                  </Trans>
                }
                type={daysLeft > 2 ? AlertTypes.warning : AlertTypes.error}
                icon={AlertTypes.warning}
                buttonText={
                  erpPermissions.edit
                    ? t('dashboard:accounting.info.validateConnection')
                    : ''
                }
                buttonClick={erpPermissions.edit ? handleClick : undefined}
                // linkText={t('common:guide')}
                // linkClick={() => {}}
              />
            ) : (
              <p className="text-xs flex items-center">
                {erpPermissions.edit &&
                  connectorName !== ERPProviders.quickbooks && (
                    <Button
                      variant="mslLink"
                      size="small"
                      className="mr-4"
                      onClick={handleClick}
                    >
                      {t('dashboard:accounting.info.validateConnection')}
                    </Button>
                  )}
                {refreshTokenNextExpiryDate && (
                  <span className="pr-2 py-[2px] pl-[3px] flex items-center bg-gray-100 rounded">
                    <CalendarIcon className="w-[14px] h-[14px] text-gray-400" />
                    <span className="ml-[2px] font-medium">
                      {t('dashboard:accounting.info.expiresOn', {
                        date: moment(refreshTokenNextExpiryDate).format(
                          'MMM DD, yyyy',
                        ),
                      })}
                    </span>
                  </span>
                )}
              </p>
            )}
          </>
        )}
    </div>
  );
};

export default ConnectedAccountFooter;
