import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';
import { PlusIcon } from 'components/icons';

import {
  setIsBankAccountOpen,
  setStep,
} from '../../BankAccounts/bankAccountsSlice';

const AddBankAccount = () => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );

  const handleAddBankAccount = () => {
    dispatch(setStep(2));
    dispatch(setIsBankAccountOpen(true));
  };

  return (
    <PermissionSection
      permission={Permissions.bankIntegration}
      showPopover
      fullWidth
      edit
    >
      <div className="border-t border-gray-200 px-3 py-[6px] w-full">
        <Button
          variant={ButtonVariant.link}
          size={ButtonSize.medium}
          onClick={handleAddBankAccount}
          className="w-full"
          disabled={!bankPermissions.edit}
        >
          <PlusIcon className="mr-2 text-blue-500" />
          {t('configuration:smart.bankAccount.add')}
        </Button>
      </div>
    </PermissionSection>
  );
};

export default AddBankAccount;
