export const FlagIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 3H5V19.5C5 20.3284 4.32843 21 3.5 21C2.67157 21 2 20.3284 2 19.5V4.5C2 3.67157 2.67157 3 3.5 3Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3L19.7548 3.00004C20.3071 3.00004 20.7548 3.44776 20.7548 4.00004C20.7548 4.24708 20.6633 4.48538 20.4981 4.669L17.5001 8.00005L20.4981 11.3311C20.8675 11.7416 20.8342 12.3739 20.4237 12.7433C20.2401 12.9086 20.0018 13 19.7548 13H7V3Z"
      fill="currentColor"
    />
  </svg>
);
