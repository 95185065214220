export const CurrencyDollarSolidIcon = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2C11 1.44772 10.5523 1 10 1C9.44772 1 9 1.44772 9 2V3.07498C6.65837 3.43371 5 5.03858 5 7C5 8.96142 6.65837 10.5663 9 10.925V14.9249C8.28531 14.8122 7.77164 14.5905 7.42495 14.3873C7.17592 14.2413 6.7919 13.8893 6.7919 13.8893C6.45935 13.4578 5.84082 13.3714 5.40239 13.6982C4.95958 14.0283 4.86892 14.6558 5.19897 15.0986C5.19897 15.0986 5.43621 15.3705 5.5354 15.4636C5.73383 15.6498 6.02284 15.8837 6.41356 16.1127C7.02986 16.474 7.8817 16.8126 9 16.9433V18C9 18.5523 9.44772 19 10 19C10.5523 19 11 18.5523 11 18V16.9411C12.1389 16.8009 13.059 16.4132 13.7411 15.8135C14.6202 15.0405 15 14.0047 15 13C15 12.0588 14.722 11.007 13.8512 10.2029C13.1687 9.57251 12.2188 9.18454 11 9.05167V5.10673C11.5006 5.22046 11.8714 5.40776 12.1328 5.58205C12.3431 5.72223 12.5403 5.88371 12.6686 6.05562C13.0001 6.5 13.5993 6.63563 14.0547 6.33205C14.5142 6.0257 14.6384 5.40483 14.3321 4.9453C14.281 4.86873 14.1205 4.67298 14.0317 4.579C13.8544 4.39127 13.5944 4.15277 13.2422 3.91795C12.7032 3.55861 11.9622 3.21778 11 3.07298V2ZM9 5.10182C7.58485 5.41216 7 6.36373 7 7C7 7.63627 7.58485 8.58784 9 8.89818V5.10182ZM11 11.0673V14.9203C11.6838 14.8011 12.134 14.5634 12.4204 14.3115C12.8208 13.9595 13 13.4953 13 13C13 12.4412 12.8418 11.993 12.4943 11.6721C12.232 11.4299 11.7785 11.1828 11 11.0673Z"
      fill="currentColor"
    />
  </svg>
);
