import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Permissions, SalesDelayedPaymentPeriod } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import Alert, { AlertTypes } from 'components/Alert';
import { Input } from 'components/Inputs';
import { useAppSelector } from 'hooks';

import { getDelayedSales, getSalesDelayedPaymentPeriods } from '../selectors';

const DelayedSales = ({ register, setValue, showErrors }) => {
  const { t } = useTranslation('financial');

  const delayedSales = useAppSelector(getDelayedSales);
  const periods = useAppSelector(getSalesDelayedPaymentPeriods);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  useEffect(() => {
    if (periods.length) {
      periods.map((period, index) => {
        const delayedSale = delayedSales.find(
          (sales) =>
            sales.salesDelayedPaymentPeriodsId ===
            period.salesDelayedPaymentPeriodsId,
        );

        if (delayedSale) {
          setValue(
            `businessesFinancialsDelayedSales.${index}.businessesFinancialsDelayedSalesId`,
            delayedSale.businessesFinancialsDelayedSalesId,
          );
          setValue(
            `businessesFinancialsDelayedSales.${index}.percentageDelayed`,
            delayedSale.percentageDelayed,
          );
        }

        setValue(
          `businessesFinancialsDelayedSales.${index}.salesDelayedPaymentPeriodsId`,
          period.salesDelayedPaymentPeriodsId,
        );
      });
    }
  }, [delayedSales, periods, setValue]);

  return (
    <div className="pt-6">
      <p className="text-secondary">{t('financial:percentageIndicate')}</p>
      {showErrors &&
        !delayedSales.some(
          ({ percentageDelayed }) => Number(percentageDelayed) > 0,
        ) && (
          <Alert
            type={AlertTypes.warning}
            title={t('financial:percentageIndicateRequired')}
            className="mt-2"
            withBorder
          />
        )}
      <div className="flex flex-wrap items-start -mx-4.5">
        {periods.map((period: SalesDelayedPaymentPeriod, index: number) => (
          <div
            className="w-full max-w-1/5"
            key={period.salesDelayedPaymentPeriodsId}
          >
            {delayedSales.length > 0 && (
              <input
                type="hidden"
                {...register(
                  `businessesFinancialsDelayedSales.${index}.businessesFinancialsDelayedSalesId`,
                  { valueAsNumber: true },
                )}
              />
            )}
            <input
              type="hidden"
              {...register(
                `businessesFinancialsDelayedSales.${index}.salesDelayedPaymentPeriodsId`,
                { valueAsNumber: true },
              )}
            />
            <Input
              name={`businessesFinancialsDelayedSales.${index}.percentageDelayed`}
              readOnly={!financialPermissions.edit}
              label={period.description}
              register={register}
              type="number"
              className="mx-4.5 mt-6"
              inputClassName="pl-8"
              placeholder="0.00"
              prefix="%"
              prefixInline
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DelayedSales;
