import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';
import Popover from 'components/Popover';
import {
  CheckCircleIcon,
  DotsVerticalIcon,
  ProgressIcon,
} from 'components/icons';

import { deleteFXProvider } from '../thunks';
import {
  FXProviderModalTypes,
  setDetailsModalOpened,
  setProvider,
} from '../fxProviderSlice';

const bgColors = {
  Connected: 'bg-green-100',
  'In Progress': 'bg-gray-200',
};

const textColors = {
  Connected: 'text-green-800',
  'In Progress': 'text-gray-800',
};

const iconColors = {
  Connected: 'text-green-400',
  'In Progress': 'text-gray-400',
};

const ProviderStatus = ({ status, provider }) => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const fxPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.fxProvider),
  );

  const icons = {
    Connected: (
      <CheckCircleIcon
        className={`w-[14px] h-[14px] ${iconColors[status]} mr-[2px]`}
      />
    ),
    'In Progress': (
      <ProgressIcon
        className={`w-[14px] h-[14px] ${iconColors[status]} mr-[2px]`}
      />
    ),
  };

  const handleDetails = () => {
    dispatch(setDetailsModalOpened(FXProviderModalTypes.existed));
    dispatch(setProvider(provider));
    setIsOpened(false);
  };

  const handleCancel = () => {
    dispatch(deleteFXProvider(provider?.businessCrossBorderProviderId));
    setIsOpened(false);
  };

  return (
    <div className="flex items-center">
      <div
        className={`mr-2 flex items-center rounded-full pl-1 pr-2 py-0.5 text-xs font-medium ${bgColors[status]} ${textColors[status]}`}
      >
        {icons[status]}
        <span>{status}</span>
      </div>
      <Popover
        opened={isOpened}
        placement="bottom-end"
        button={
          <Button
            variant={ButtonVariant.linkSecondary}
            size={ButtonSize.small}
            heightClass="h-5"
            paddingClass="px-0.5"
            className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-500"
            onClick={() => setIsOpened(true)}
          >
            <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        }
        content={
          <div className="border rounded-lg shadow-sm mt-1 bg-white min-w-[230px]">
            <button
              className={
                'text-gray-700 hover:text-gray-900 block px-4 py-3 text-sm leading-5 hover:bg-gray-100 cursor-pointer w-full text-left'
              }
              onClick={handleDetails}
            >
              {t('fxProvider.viewDetails')}
            </button>
            <PermissionSection
              permission={Permissions.fxProvider}
              edit
              showPopover
            >
              <button
                disabled={!fxPermissions.edit}
                className={
                  'text-red-500 hover:text-red-800 block px-4 py-3 text-sm leading-5 hover:bg-gray-100 cursor-pointer w-full text-left'
                }
                onClick={handleCancel}
              >
                {t('common:cancel')}
              </button>
            </PermissionSection>
          </div>
        }
      />
    </div>
  );
};

export default ProviderStatus;
