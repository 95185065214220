import { getBusinessesId, setBusinessesId } from 'utils/authService';
import personsServices, { PersonSchema } from 'services/persons';
import {
  addTandCacceptLogs,
  TandCAcceptLogSchema,
  updateTandCacceptLogs,
} from 'services/tandc';
import businessesServices from 'services/businesses';
import phoneService from 'services/telephoneNumbers';
import { buildFormData } from 'utils/helpers';
import { UserProps } from 'entities/profile';
import addressesServices, {
  BUSINESS_ADDRESS_TYPE_CODE,
} from 'services/addresses';

import {
  getMappedNewPersonData,
  mappedCreateBusiness,
  mappedBusinessAddress,
  mappedUpdateBusiness,
  getMappedBusiness,
} from './utils';

export const getBusinessById = async (businessId: number) => {
  const businesses = await businessesServices.getBusinesses(businessId);
  return businesses?.[0] || null;
};

export const addBusinesses = async (data: any) => {
  const formData = buildFormData(data);
  const businessesId = await businessesServices.addBusinesses(formData);
  return businessesId || 0;
};

export const updateBusinesses = (fields: any) => {
  const formData = buildFormData(fields, true);
  return businessesServices.updateBusinesses(formData);
};

export const searchOrCreatePerson = async (
  user: Partial<UserProps>,
  tenantId: number,
) => {
  const persons: any = await personsServices.searchPersons({
    emailAddress: user.username,
    isActive: true,
    tenantId,
  });

  if (!persons.length) {
    const personPayload: Partial<PersonSchema> = getMappedNewPersonData(user);

    const personId: any = await personsServices.addPersons(personPayload);

    const newPerson: PersonSchema | null = await personsServices.getPersonById(
      personId,
    );

    return { personId, person: newPerson };
  }

  const person = persons[0];

  const result: any = { person };

  const personPhone = await phoneService.getPhoneNumberByPersonId(
    person.personsId,
  );

  if (personPhone) {
    result.personTelephoneNumber = personPhone.phoneNumber;
    result.personTelephoneNumberXref = personPhone.phoneNumberXref;
  }

  const businessId: number = getBusinessesId();

  if (businessId) {
    const business = await businessesServices.getBusinessDetailsById(
      businessId,
    );

    if (business) {
      const mappedBusiness = getMappedBusiness(business, person.emailAddress);

      return {
        ...result,
        ...mappedBusiness,
      };
    }

    result.personId = person.personsId;

    return result;
  }

  result.personId = person.personsId;

  return result;
};

const createBusinessAddress = async (fields, businessesId) => {
  const addressesId: any = await addressesServices.addAddresses(fields);

  const addressXrefs = {
    businessesId,
    addressesId,
    isActive: true,
    createdBy: fields.createdBy,
    addressesTypesTypeCode: BUSINESS_ADDRESS_TYPE_CODE,
  };

  await addressesServices.addBusinessesAddressesXrefs(addressXrefs);

  return addressesId;
};

export const createPersonForBusiness = async (data: any) => {
  let personId: any = data.personsId;

  if (data.isAuthenticated) {
    const existedPersons: any = await personsServices.searchPersons({
      emailAddress: data.emailAddress,
    });

    if (existedPersons.length) {
      personId = existedPersons[0].personsId;
    } else {
      personId = null;
    }
  }

  let personWasCreated = false;

  if (!personId) {
    personId = await personsServices.addPersons({
      createdBy: data.createdBy,
      isActive: true,
      givenName1: data.givenName1 || '',
      emailAddress: data.emailAddress,
      surnameFirst: data.surnameFirst || '',
      givenName2: '',
      surnameSecond: '',
      middleName: '',
      preferredName: '',
      prefix: '',
      suffix: '',
    });

    personWasCreated = true;
  }

  const businessesPersonsXrefid =
    await businessesServices.addBusinessesPersonsXrefs({
      createdBy: data.emailAddress,
      personsId: personId,
      businessesId: data.businessesId,
      isActive: true,
      businessesAdmin: true,
    });

  return { personId, businessesPersonsXrefid, personWasCreated };
};

export const createBusinessProfile = async ({ data, tenant, profile }) => {
  const { emailAddress } = data;

  const businessFields = mappedCreateBusiness(data, tenant.tenantsId);
  const addressFields = mappedBusinessAddress(data, profile.businessAddress);

  const businessesId: any = await addBusinesses(businessFields);

  setBusinessesId(businessesId);

  const result: any = {
    business: { businessesId, ...businessFields },
  };

  const personsId = profile.person?.personsId;

  const tAndCPayload: Partial<TandCAcceptLogSchema> = {
    businessesId,
  };

  if (personsId && emailAddress === profile.person?.emailAddress) {
    const businessesPersonsXrefid =
      await businessesServices.addBusinessesPersonsXrefs({
        createdBy: emailAddress,
        personsId: personsId,
        businessesId,
        isActive: true,
        businessesAdmin: true,
      });

    result.businessPerson = { businessesPersonsXrefid };

    tAndCPayload.modifiedBy = emailAddress;
  } else {
    const createBusinessForOtherPerson = personsId ? true : false;

    const { personId, businessesPersonsXrefid, personWasCreated } =
      await createPersonForBusiness({
        isAuthenticated: data.isAuthenticated,
        personsId,
        emailAddress,
        businessesId,
        givenName1: profile.person?.givenName1 || '',
        surnameFirst: profile.person?.surnameFirst || '',
        createdBy: profile.person?.emailAddress || emailAddress,
      });

    result.person = {
      personsId: personId,
      emailAddress,
      givenName1: createBusinessForOtherPerson
        ? ''
        : profile.person?.givenName1 || '',
      surnameFirst: createBusinessForOtherPerson
        ? ''
        : profile.person?.surnameFirst || '',
    };
    result.businessPerson = { businessesPersonsXrefid };
    result.personWasCreated = personWasCreated;

    if (createBusinessForOtherPerson) {
      tAndCPayload.modifiedBy = profile.person?.emailAddress;
    } else {
      tAndCPayload.createdBy = emailAddress;
      tAndCPayload.personsId = personId;
    }
  }

  const addressesId = await createBusinessAddress(addressFields, businessesId);

  result.businessAddress = { addressesId, ...addressFields };

  if (profile.termsCheckbox.id) {
    updateTandCacceptLogs({
      tandCacceptLogsId: profile.termsCheckbox.id,
      ...tAndCPayload,
    });
  } else {
    const tandCacceptLogsId = await addTandCacceptLogs({
      tandCversion: profile.termsCheckbox.version,
      ...tAndCPayload,
    });

    result.termsCheckboxId = tandCacceptLogsId;
  }

  if (profile.privacyCheckbox.id) {
    updateTandCacceptLogs({
      tandCacceptLogsId: profile.privacyCheckbox.id,
      ...tAndCPayload,
    });
  } else {
    const tandCacceptLogsId = addTandCacceptLogs({
      tandCversion: profile.privacyCheckbox.version,
      ...tAndCPayload,
    });

    result.privacyCheckboxId = tandCacceptLogsId;
  }

  return result;
};

export const updateBusinessProfile = async (data, profile) => {
  const businessFields = mappedUpdateBusiness(data, profile);
  const addressFields = mappedBusinessAddress(data, profile.businessAddress);

  const businessesId = await updateBusinesses(businessFields);

  let addressesId = addressFields.addressesId;

  if (addressesId) {
    await addressesServices.updateAddresses(addressFields);
  } else {
    addressesId = await createBusinessAddress(addressFields, businessesId);
  }

  return {
    business: { ...businessFields, businessesId },
    businessAddress: { ...addressFields, addressesId },
  };
};
