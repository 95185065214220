import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import image from 'assets/images/illustations.png';
import { ExclamationIcon } from 'components/icons/ExclamationIcon';
import Button from 'components/Button';

const InformationMissing = ({ showWarning, setShowWarning }) => {
  const { t } = useTranslation('dashboard');

  const handleClose = () => {
    setShowWarning(false);
  };

  return (
    <Modal isOpen={showWarning} closeModal={handleClose} maxWidth="512px">
      <div className="p-6 pt-14">
        <ExclamationIcon className="text-yellow-600 w-11 h-11 mx-auto" />
        <p className="pt-6 pb-2 text-lg font-semibold text-center text-gray-900">
          {t('dashboard:submitModal.missingInfoTitle')}
        </p>
        <p className="text-sm text-gray-500 text-center">
          {t('dashboard:submitModal.missingInfoText')}
        </p>
        <img alt="Guide" src={image} />
        <Button className="w-full mt-6" onClick={handleClose}>
          {t('dashboard:submitModal.missingInfoButton')}
        </Button>
      </div>
    </Modal>
  );
};

export default InformationMissing;
