import { createSlice } from '@reduxjs/toolkit';

import { clearDashboard } from 'pages/dashboard/thunks';
import { ProgressState } from 'entities/progress';
import { fetchBusinessProducts, updateBusinessProduct } from './thunks';
import { checkFinancialProgress } from 'pages/dashboard/components/Financial/thunks';
import { checkBusinessInfoProgress } from 'pages/dashboard/components/BusinessInformation/thunks';
import { checkOwnershipProgress } from 'pages/dashboard/components/Ownership/thunks';
import { checkTransactionalProgress } from 'pages/dashboard/components/Transactional/thunks';
import { checkBusinessPlanProgress } from 'pages/dashboard/components/BusinessPlan/thunks';

export const initialState: ProgressState = {
  businessProducts: [],
  progress: 0,
  newProductType: '', // using to check if switching the tab is needed
  procentByTab: {
    businessInfo: {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    },
    owner: {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    },
    financial: {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    },
    transactional: {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    },
    businessPlan: {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    },
  },
  showErrors: {
    businessInfo: false,
    owner: false,
    financial: false,
    transactional: false,
    businessPlan: false,
  },
};

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    clearNewProductType(state) {
      state.newProductType = '';
    },
    setBusinessProducts(state, action) {
      state.businessProducts = action.payload;
    },
    setProgress(state, action) {
      state.progress = action.payload;
    },
    showAllErrors(state) {
      state.showErrors = {
        businessInfo: true,
        owner: true,
        financial: true,
        transactional: true,
        businessPlan: true,
      };
    },
    setShowErrors(state, action) {
      state.showErrors = {
        ...state.showErrors,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchBusinessProducts.fulfilled, (state, action) => {
      state.businessProducts = action.payload;
    });
    builder.addCase(updateBusinessProduct.fulfilled, (state, action) => {
      state.businessProducts = action.payload.products;
      state.newProductType = action.payload.newProductType;
    });
    builder.addCase(checkFinancialProgress.fulfilled, (state, action) => {
      state.procentByTab = { ...state.procentByTab, financial: action.payload };
    });
    builder.addCase(checkOwnershipProgress.fulfilled, (state, action) => {
      state.procentByTab = { ...state.procentByTab, owner: action.payload };
    });
    builder.addCase(checkTransactionalProgress.fulfilled, (state, action) => {
      state.procentByTab = {
        ...state.procentByTab,
        transactional: action.payload,
      };
    });
    builder.addCase(checkBusinessPlanProgress.fulfilled, (state, action) => {
      state.procentByTab = {
        ...state.procentByTab,
        businessPlan: action.payload,
      };
    });
    builder.addCase(checkBusinessInfoProgress.fulfilled, (state, action) => {
      state.procentByTab = {
        ...state.procentByTab,
        businessInfo: action.payload,
      };
    });
  },
});

export const {
  setBusinessProducts,
  setProgress,
  showAllErrors,
  setShowErrors,
  clearNewProductType,
} = progressSlice.actions;

export default progressSlice.reducer;
