import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { Input, NumberInput, Select, Switch } from 'components/Inputs';
import { PencilIcon } from 'components/icons';
import { useAppDispatch } from 'hooks';

import { updateFormValues } from '../merchantSlice';

interface AcceptedValue {
  id: string;
  displayName: string;
}

const ProcessorField = ({
  data,
  control,
  register,
  watch,
  setValue,
  disabled,
  permission,
  platform,
  onEdit,
  showErrors,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const {
    type,
    name,
    description,
    example,
    acceptedValues,
    maxLimit,
    minLimit,
  } = data[1];

  const fieldKey = data[0];
  const fieldName = `fields.${fieldKey}`;

  const [dirty, setDirty] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  const value = watch(fieldName);

  const validateField = useCallback((val: string) => {
    if (val && minLimit && val?.length < minLimit) {
      setError({
        type: 'custom',
        message: t('validations.minLength', { value: minLimit }),
      });
      return;
    }

    if (maxLimit && val?.length > maxLimit) {
      setError({
        type: 'custom',
        message: t('validations.maxLength', { value: maxLimit }),
      });
      return;
    }

    setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultValue = useCallback(() => {
    if (name !== 'Platform') {
      setValue(fieldName, type === 'boolean' ? false : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (dirty) {
      validateField(value);
    }
  }, [value, dirty, validateField]);

  useEffect(() => {
    if (value === undefined) {
      setDefaultValue();
    } else {
      dispatch(
        updateFormValues({
          fields: {
            [fieldKey]: value,
          },
        }),
      );
    }
  }, [value, setDefaultValue, dispatch, fieldKey]);

  useEffect(() => {
    if (name === 'Platform') {
      setValue(fieldName, platform);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, platform]);

  const handleToggle = () => {
    setValue(fieldName, !value);
  };

  const handleBlur = () => {
    if (!dirty) {
      setDirty(true);
    }
  };

  if (acceptedValues) {
    const options = acceptedValues.map((val: AcceptedValue) => ({
      name: val.displayName,
      value: val.id,
    }));

    return (
      <Select
        name={fieldName}
        label={name}
        info={description}
        register={register}
        setValue={setValue}
        options={options}
        value={options.find((option) => option.value === value)}
        disabled={disabled}
        error={
          showErrors.financial &&
          !value && {
            message: t('common:validations.required', {
              name,
            }),
          }
        }
      />
    );
  }

  if (type === 'string') {
    if (name === 'Platform') {
      return (
        <Input
          label={t('dashboard:merchantServices.configuration.platform')}
          name={fieldName}
          placeholder={example}
          info={description}
          error={error}
          onBlur={handleBlur}
          register={register}
          readOnly={true}
          suffix={
            !disabled && (
              <button
                type="button"
                className="flex items-center px-3 h-full"
                onClick={onEdit}
              >
                <PencilIcon className="mr-2 text-gray-400 flex-shrink-0" />
                <span className="font-medium text-gray-700">{t('edit')}</span>
              </button>
            )
          }
        />
      );
    }

    return (
      <Input
        name={fieldName}
        label={name}
        placeholder={example}
        info={description}
        onBlur={handleBlur}
        register={register}
        readOnly={disabled}
        error={
          error ||
          (showErrors.financial &&
            !value && {
              message: t('common:validations.required', {
                name,
              }),
            })
        }
      />
    );
  }

  if (type === 'boolean') {
    return (
      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
        <div className="mr-6">
          <p className="text-sm font-medium">{name}</p>
          <p className="text-secondary font-medium">{description}</p>
        </div>
        <PermissionSection
          permission={disabled ? permission : undefined}
          showPopover
          edit
        >
          <Switch
            isOn={value}
            handleToggle={handleToggle}
            disabled={disabled}
          />
        </PermissionSection>
      </div>
    );
  }

  if (type === 'numeric' || type === 'int') {
    return (
      <NumberInput
        name={fieldName}
        label={name}
        placeholder={example}
        info={description}
        control={control}
        allowNegative={false}
        type={type === 'int' ? 'int' : 'float'}
        onBlur={handleBlur}
        readOnly={disabled}
        error={
          error ||
          (showErrors.financial &&
            !value && {
              message: t('common:validations.required', {
                name,
              }),
            })
        }
      />
    );
  }

  return <span>{type}</span>;
};

export default ProcessorField;
