import { useEffect, useState } from 'react';

import { statesByCountry, StateOptionType } from 'utils/address';
import { Input, Select } from 'components/Inputs';

interface AddressStateProps {
  countryISOCode: string;
  name: string;
  setValue: any;
  value: string | null;
  className?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  register: any;
  error?: any;
}

const AddressState: React.FC<AddressStateProps> = ({
  countryISOCode,
  name,
  setValue,
  value,
  className,
  label,
  placeholder = '',
  disabled,
  register,
  error,
}) => {
  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    if (statesByCountry[countryISOCode]) {
      setSelected(
        statesByCountry[countryISOCode].find(
          (s: StateOptionType) => s.value === value,
        ) || { value: '' },
      );
    }
  }, [value, countryISOCode]);

  const handleChange = (option) => {
    setSelected(option);
  };

  return statesByCountry[countryISOCode] ? (
    <Select
      label={label}
      placeholder={placeholder}
      name={name}
      value={selected}
      options={statesByCountry[countryISOCode]}
      onChange={handleChange}
      className={className}
      setValue={setValue}
      error={error}
      disabled={disabled}
    />
  ) : (
    <Input
      label={label}
      placeholder={placeholder}
      name={name}
      className={className}
      readOnly={disabled}
      register={register}
      error={error}
    />
  );
};

export default AddressState;
