import { useEffect } from 'react';

import { fetchPersonPermissions } from 'pages/Profile/thunks';
import { useAppDispatch } from 'hooks';

const PermissionRefresher = () => {
  const dispatch = useAppDispatch();

  const refreshPermissions = async () => {
    dispatch(fetchPersonPermissions());
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshPermissions();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default PermissionRefresher;
