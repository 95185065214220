import { createSlice } from '@reduxjs/toolkit';

import { BusinessInfoState } from 'entities/businessInfo';
import { clearDashboard } from 'pages/dashboard/thunks';

import {
  fetchBusinessAdditionalData,
  updateCentralIndexDocument,
  updateBusinessDocument,
  uploadBusinessDocument,
  deleteBusinessDocument,
  updateBusinessInfo,
  fetchBusinessDocumentTypes,
} from './thunks';

export const initialState: BusinessInfoState = {
  documents: [],
  applicant: {
    applicantName: '',
    applicantEmail: '',
    applicantPhone: '',
    applicantRole: '',
  },
  erpPreferences: null,
  isFormUpdate: false,
  showDoingBusinessAs: false,
  loadingProgress: 0,
  idTypes: [],
  idTypesCountryId: 0,
};

const businessInfoSlice = createSlice({
  name: 'businessInfo',
  initialState,
  reducers: {
    setFormUpdateState(state) {
      state.isFormUpdate = true;
    },
    setShowDoingBusinessAs(state, action) {
      state.showDoingBusinessAs = action.payload;
    },
    setLoadingProgress(state, action) {
      state.loadingProgress = action.payload;
    },
    setERPPreferences(state, action) {
      state.erpPreferences = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchBusinessAdditionalData.fulfilled, (state, action) => {
      if (action.payload.documents) {
        state.documents = action.payload.documents;
      }

      if (action.payload.applicant) {
        state.applicant = action.payload.applicant;
      }

      if (action.payload.erpPreferences) {
        state.erpPreferences = action.payload.erpPreferences;
      }
    });
    builder.addCase(updateCentralIndexDocument.fulfilled, (state, action) => {
      state.documents = action.payload;
    });
    builder.addCase(updateBusinessDocument.fulfilled, (state, action) => {
      state.documents = action.payload;
    });
    builder.addCase(uploadBusinessDocument.fulfilled, (state, action) => {
      state.documents = action.payload;
    });
    builder.addCase(deleteBusinessDocument.fulfilled, (state, action) => {
      state.documents = action.payload;
    });
    builder.addCase(updateBusinessInfo.fulfilled, (state, action) => {
      state.isFormUpdate = action.payload;
    });
    builder.addCase(fetchBusinessDocumentTypes.pending, (state) => {
      state.idTypes = [];
    });
    builder.addCase(fetchBusinessDocumentTypes.fulfilled, (state, action) => {
      state.idTypes = action.payload.idTypes;
      state.idTypesCountryId = action.payload.idTypesCountryId;
    });
  },
});

export const {
  setFormUpdateState,
  setShowDoingBusinessAs,
  setLoadingProgress,
  setERPPreferences,
} = businessInfoSlice.actions;

export default businessInfoSlice.reducer;
