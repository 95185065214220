export const prysymProcessor: ProcessorOption = {
  name: 'Prysym USA',
  value: 'prysym',
};

export interface ProcessorOption {
  name: string;
  value: string;
}

export type FileLoadingProps = {
  type: string;
  progress: number;
};
