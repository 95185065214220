import { useTranslation } from 'react-i18next';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { getDomain, getTenant } from 'theme/selectors';
import { DomainType } from 'utils/domainService';
import shield from 'assets/icons/shield.svg';
import { useAppSelector } from 'hooks';
import path from 'common/path';

const PoweredBy = () => {
  const { t, i18n } = useTranslation('common');

  const tenant = useAppSelector(getTenant);
  const domain = useAppSelector(getDomain);

  const platform =
    domain === DomainType.invoicecloud ? tenant.name : t('common:transcard');
  const iconSrc =
    domain === DomainType.invoicecloud
      ? tenant.faviconFileShareUri || shield
      : shield;

  return (
    <div className="text-center flex flex-wrap justify-center p-4">
      <div className="flex items-center justify-center w-full pb-2">
        <img src={iconSrc} alt={platform} className="max-w-[20px]" />
        <span className="ml-1 text-xs leading-4 font-medium text-gray-800">
          {t('poweredBy')}
          <span className="font-bold text-gray-900 text-sm ml-1">
            {platform}
          </span>
        </span>
      </div>
      <div className="flex items-center">
        <a
          href={`${path.terms}/${i18n.language.toUpperCase()}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant={ButtonVariant.linkSecondary}
            size={ButtonSize.small}
            heightClass="h-4"
          >
            {t('termOfUse')}
          </Button>
        </a>
        <div className="text-gray-500">&bull;</div>
        <a
          href={`${path.privacy}/${i18n.language.toUpperCase()}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant={ButtonVariant.linkSecondary}
            size={ButtonSize.small}
            heightClass="h-4"
          >
            {t('privacyPolicy')}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default PoweredBy;
