export const DoubleCheckIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      opacity="0.3"
      d="M17.2817 4.46012C17.5798 4.10944 18.1057 4.0668 18.4564 4.36487C18.807 4.66294 18.8497 5.18886 18.5516 5.53953L11.4683 13.8729C11.1655 14.229 10.6291 14.2666 10.2797 13.956L6.52968 10.6227C6.18569 10.3169 6.15471 9.79017 6.46047 9.44619C6.76624 9.1022 7.29297 9.07122 7.63695 9.37698L10.7502 12.1443L17.2817 4.46012Z"
      fill="currentColor"
    />
    <path
      d="M13.1137 5.29361C13.4118 4.94294 13.9377 4.9003 14.2884 5.19837C14.6391 5.49644 14.6817 6.02235 14.3836 6.37303L7.3003 14.7064C6.99755 15.0625 6.4611 15.1001 6.11171 14.7895L2.36171 11.4562C2.01772 11.1504 1.98674 10.6237 2.29251 10.2797C2.59827 9.9357 3.125 9.90471 3.46898 10.2105L6.58219 12.9778L13.1137 5.29361Z"
      fill="currentColor"
    />
  </svg>
);
