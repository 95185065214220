import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { EyeIcon, EyeOffIcon } from 'components/icons';
import { useAppSelector } from 'hooks';

import { getBankAccount, getIsCreatedByCurrentPerson } from '../selectors';
import HideComponent from 'components/HideComponent';

const BankAccountInfoNumbers = () => {
  const { t } = useTranslation('bank');

  const account = useAppSelector(getBankAccount);
  const isCreator = useAppSelector(getIsCreatedByCurrentPerson);

  const [showRouting, setShowRouting] = useState<boolean>(false);
  const [showAccount, setShowAccount] = useState<boolean>(false);
  const [showCompanyId, setShowCompanyId] = useState<boolean>(false);

  return (
    <HideComponent show={isCreator}>
      <div className="mt-6 pt-6 border-t border-gray-200 flex flex-wrap">
        <div className="w-1/2">
          <p className="text-sm font-medium text-gray-500 mb-1">
            {t('bank:fields.routingNumber.label')}
          </p>
          <p className="text-sm flex items-center">
            {showRouting ? (
              <>
                {account?.routingNumber}
                <button className="ml-2" onClick={() => setShowRouting(false)}>
                  <EyeOffIcon className="text-gray-500 w-4 h-4" />
                </button>
              </>
            ) : (
              <>
                {Array.from(
                  { length: (account?.routingNumber || '').length },
                  () => '*',
                )}
                <button className="ml-2" onClick={() => setShowRouting(true)}>
                  <EyeIcon className="text-gray-500 w-4 h-4" />
                </button>
              </>
            )}
          </p>
        </div>
        <div className="w-1/2">
          <p className="text-sm font-medium text-gray-500 mb-1">
            {t('bank:fields.accountNumber.label')}
          </p>
          <p className="text-sm flex items-center">
            {showAccount ? (
              <>
                {account?.accountNumber}
                <button className="ml-2" onClick={() => setShowAccount(false)}>
                  <EyeOffIcon className="text-gray-500 w-4 h-4" />
                </button>
              </>
            ) : (
              <>
                {Array.from(
                  { length: (account?.accountNumber || '').length },
                  () => '*',
                )}
                <button className="ml-2" onClick={() => setShowAccount(true)}>
                  <EyeIcon className="text-gray-500 w-4 h-4" />
                </button>
              </>
            )}
          </p>
        </div>
        {account?.achCompanyId && (
          <div className="w-1/2 mt-5">
            <p className="text-sm font-medium text-gray-500 mb-1">
              {t('bank:fields.achCompanyId.label')}
            </p>
            <p className="text-sm flex items-center">
              {showCompanyId ? (
                <>
                  {account.achCompanyId}
                  <button
                    className="ml-2"
                    onClick={() => setShowCompanyId(false)}
                  >
                    <EyeOffIcon className="text-gray-500 w-4 h-4" />
                  </button>
                </>
              ) : (
                <>
                  {Array.from(
                    { length: (account.achCompanyId || '').length },
                    () => '*',
                  )}
                  <button
                    className="ml-2"
                    onClick={() => setShowCompanyId(true)}
                  >
                    <EyeIcon className="text-gray-500 w-4 h-4" />
                  </button>
                </>
              )}
            </p>
          </div>
        )}
      </div>
    </HideComponent>
  );
};

export default BankAccountInfoNumbers;
