export const ArrowRightUpIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2929 5.57736C8.2929 5.02508 8.74062 4.57736 9.29291 4.57736L14.9498 4.57736C15.502 4.57736 15.9498 5.02508 15.9498 5.57736L15.9498 11.2342C15.9498 11.7865 15.502 12.2342 14.9498 12.2342C14.3975 12.2342 13.9498 11.7865 13.9498 11.2342L13.9498 7.99158L5.75737 16.184C5.36684 16.5745 4.73368 16.5745 4.34315 16.184C3.95263 15.7934 3.95263 15.1603 4.34315 14.7698L12.5355 6.57736L9.2929 6.57736C8.74062 6.57736 8.2929 6.12965 8.2929 5.57736Z"
      fill="currentColor"
    />
  </svg>
);
