import { createSlice } from '@reduxjs/toolkit';

import { fetchPersonPermissions } from 'pages/Profile/thunks';
import {
  DashboardState,
  DropdownsProps,
  OnboardingTabCodes,
} from 'entities/dashboard';

import {
  fetchCommonDropdowns,
  fetchDashboardInformation,
  clearDashboard,
} from './thunks';

export const emptyDropdowns: DropdownsProps = {
  applicationStatus: [],
  bankAccountStatuses: [],
  businessIdTypes: [],
  blobReferencesTypes: [],
  mappedBlobReferencesTypes: {},
  businessesLegalEntityFormTypes: [],
  currencyCodes: [],
  erpAccountStatuses: [],
  inputCurrencies: [],
  pciCompliant: [],
  merchantCategoryCodes: [],
  operatingStatus: [],
  businessStatuses: [],
  subscriptionsHowOften: [],
  salesDelayedPaymentPeriods: [],
  creditCardNetworks: [],
  businessesFinancialsGeographicSalesTypes: [],
  personsDocumentsTypes: [],
  countries: [],
  industryTypes: [],
  geogrphicTypes: [],
  demographicTypes: [],
};

export const initialState: DashboardState = {
  isLoading: true,
  onboardingTab: 0,
  onboardingTabCode: OnboardingTabCodes.businessInfo,
  permissions: [],
  dropdowns: emptyDropdowns,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOnboardingTab(state, action) {
      if (action.payload.index !== undefined) {
        state.onboardingTab = action.payload.index;
      }

      if (action.payload.key !== undefined) {
        state.onboardingTabCode = action.payload.key;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchDashboardInformation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDashboardInformation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchDashboardInformation.fulfilled, (state, action) => {
      if (action.payload.length) {
        state.isLoading = false;
      }
    });
    builder.addCase(fetchCommonDropdowns.fulfilled, (state, action) => {
      if (action.payload) {
        state.dropdowns = action.payload;
      }
    });
    builder.addCase(fetchPersonPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
  },
});

export const { setIsLoading, setOnboardingTab } = dashboardSlice.actions;

export default dashboardSlice.reducer;
