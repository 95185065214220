import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppDispatch, useCurrentUser, useAppSelector } from 'hooks';
import { getBusinessesId, getUserEmail } from 'utils/authService';
import LayoutApp from 'components/layouts/LayoutApp';
import Loading from 'components/Loading';
import path from 'common/path';

import BusinessAlert from './components/BusinessInformation/components/BusinessAlert';
import PermissionRefresher from './components/PermissionRefresher';
import IntegrationAlerts from './components/IntegrationAlerts';
import OnboardingTabs from './components/OnboardingTabs';
import Progress from './components/Progress/Progress';
import { fetchDashboardInformation } from './thunks';
import { getLoading } from './selectors';

const Dashboard = () => {
  useTranslation([
    'dashboard',
    'financial',
    'ownership',
    'bank',
    'integration',
    'configuration',
    'transactional',
  ]);
  const dispatch = useAppDispatch();
  const { user } = useCurrentUser();
  const navigate = useNavigate();

  const isLoading = useAppSelector(getLoading);

  const businessId = getBusinessesId();

  useEffect(() => {
    const email = user?.username || getUserEmail();

    if (!email) {
      navigate(path.login);
      return;
    }

    if (!businessId) {
      navigate(path.businesses);
      return;
    }

    dispatch(fetchDashboardInformation(email));
  }, [dispatch, user, navigate, businessId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LayoutApp>
      <Progress />
      <div className="mx-3 w-full">
        <IntegrationAlerts />
        <BusinessAlert />
        <OnboardingTabs />
      </div>
      <PermissionRefresher />
    </LayoutApp>
  );
};

export default Dashboard;
