import { useTranslation } from 'react-i18next';

import { AngleLeftIcon, AngleRightIcon } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import Button from 'components/Button';

import { getState, getBankAccountConnections } from '../selectors';
import {
  setStep,
  setBankAccountFields,
  setShowConnectionForm,
  selectConnection,
} from '../bankAccountsSlice';

const BankingModalActions = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const connections = useAppSelector(getBankAccountConnections);
  const { showConnectionForm, isLoading, isSubmiting, step, integrator } =
    useAppSelector(getState);

  const handleStep = (value: number) => {
    dispatch(setStep(value));

    if (step === 1) {
      dispatch(setBankAccountFields([]));
    }
  };

  const handleBack = () => {
    handleStep(step - 1);
  };

  const handleBackConnection = () => {
    if (showConnectionForm && connections.length > 0) {
      dispatch(setShowConnectionForm(false));
      return;
    }

    dispatch(setShowConnectionForm(false));
    handleBack();
  };

  const handleConnection = (e: any) => {
    if (!showConnectionForm) {
      e.preventDefault();
      dispatch(selectConnection());
    }
  };

  switch (step) {
    case 1:
      return (
        <div className="flex items-center justify-between gap-6">
          <Button
            type="button"
            variant="secondary-outline"
            className="w-1/2"
            onClick={onCancel}
            heightClass="h-11"
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="button"
            className="w-1/2"
            onClick={() => handleStep(2)}
            disabled={!(integrator?.banksIntegratorsId || 0)}
            heightClass="h-11"
          >
            {t('common:continue')}
          </Button>
        </div>
      );
    case 2:
      return (
        <div className="flex items-center justify-between gap-6">
          <Button
            type="button"
            variant="secondary-outline"
            className="w-1/2 max-w-[200px]"
            onClick={handleBack}
            heightClass="h-11"
          >
            <AngleLeftIcon className="mr-2" />
            {t('common:back')}
          </Button>
          <Button
            type="submit"
            className="w-1/2 max-w-[200px]"
            form="bankAccountForm"
            disabled={isLoading}
            loading={isSubmiting}
            heightClass="h-11"
          >
            {t('common:next')}
            <AngleRightIcon className="ml-2" />
          </Button>
        </div>
      );
    case 3:
      return (
        <div className="flex items-center justify-between gap-6">
          <Button
            type="button"
            variant="secondary-outline"
            className="w-1/2 max-w-[200px]"
            onClick={handleBackConnection}
            heightClass="h-11"
          >
            <AngleLeftIcon className="mr-3" />
            {t('common:back')}
          </Button>
          <Button
            onClick={handleConnection}
            type="submit"
            className="w-1/2 max-w-[200px]"
            form="bankAccountForm"
            disabled={isLoading}
            loading={isSubmiting}
            heightClass="h-11"
          >
            {t('common:next')}
            <AngleRightIcon className="ml-2" />
          </Button>
        </div>
      );
    default:
      return null;
  }
};

export default BankingModalActions;
