import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { getBusinessSignatory } from 'pages/Profile/selectors';
import { authorizeSignatory } from 'pages/Profile/thunks';
import { useAppDispatch, useAppSelector } from 'hooks';

import { getAuthorizing, getCheckboxes } from '../selectors';
import AuthorizedSignatory from './AuthorizedSignatory';
import TermsAgreement from './TermsAgreement';
import NMIAgreement from './NMIAgreement';

const AcceptTerms = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const signatory: any = useAppSelector(getBusinessSignatory);
  const authorizing = useAppSelector(getAuthorizing);
  const { termsCheckbox, privacyCheckbox } = useAppSelector(getCheckboxes);

  const handleAuthorize = () => {
    dispatch(authorizeSignatory());
  };

  useEffect(() => {
    if (termsCheckbox.checked && privacyCheckbox.checked) {
      const businessTab = document.getElementById('confirm-message');
      businessTab?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [termsCheckbox, privacyCheckbox]);

  return (
    <>
      <div className="p-6 flex-1">
        <p className="heading-2xl mb-1">
          {t('dashboard:submitModal.acceptTitle')}
        </p>
        <p className="text-secondary mb-9">
          {t('dashboard:submitModal.acceptSubTitle')}
        </p>

        <AuthorizedSignatory
          signatory={signatory}
          loading={authorizing}
          onClick={handleAuthorize}
        />
        <TermsAgreement />
        <NMIAgreement />
      </div>

      {termsCheckbox.checked && privacyCheckbox.checked && (
        <div id="confirm-message" className="p-6 text-secondary">
          {t('submitModal.confirmInfo')}
        </div>
      )}
    </>
  );
};

export default AcceptTerms;
