import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import OnboardingTabHeader from 'pages/dashboard/components/OnboardingTabHeader';
import { ProcessorOption, prysymProcessor } from 'entities/merchantServices';
import { PaymentProcessorIcon, ExclamationIcon } from 'components/icons';
import PermissionSection from 'components/permission/PermissionSection';
import { openDialog, closeDialog } from 'components/dialog/modalSlice';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';

import { changeBusinessMerchant, saveBusinessMerchant } from '../thunks';
import { calculateFinancialProgress } from '../../Financial/thunks';
import { setUpdateForm } from '../merchantSlice';
import ProcessorFields from './ProcessorFields';
import ProcessorFiles from './ProcessorFiles';
import PaymentDetails from './PaymentDetails';
import ContactInfo from './ContactInfo';
import {
  getCurrentProcessor,
  getFormValues,
  getUpdateForm,
} from '../selectors';

const ProcessorConfiguration = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const updateForm = useAppSelector(getUpdateForm);
  const formValues = useAppSelector(getFormValues);
  const currentProcessor: ProcessorOption | null =
    useAppSelector(getCurrentProcessor);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const { control, register, setValue, watch, reset } = useForm({
    defaultValues: formValues,
  });

  useEffect(() => {
    const saveOnUnmount = async () => {
      await dispatch(saveBusinessMerchant());
      dispatch(calculateFinancialProgress());
    };

    return () => {
      saveOnUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateForm) {
      reset(formValues);
      dispatch(setUpdateForm(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateForm, reset]);

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleChangeProcessor = (processor: ProcessorOption | null) => {
    const handleRemoveProcessor = () => {
      dispatch(changeBusinessMerchant(processor));
      handleCancel();
    };

    dispatch(
      openDialog({
        maxWidth: '512px',
        hideCross: false,
        content: (
          <div className="p-6 text-center">
            <div className="flex justify-center pt-8">
              <ExclamationIcon className="w-[44px] h-[44px] text-yellow-600" />
            </div>
            <h6 className="text-lg mb-2 mt-6 font-semibold">
              {t('merchantServices.configuration.modal.title')}
            </h6>
            <p className="text-secondary mb-6">
              {t('merchantServices.configuration.modal.description')}
            </p>
            <Button className="mb-4 w-full" onClick={handleRemoveProcessor}>
              {t('common:change')}
            </Button>
            <Button
              className="w-full"
              variant={ButtonVariant.secondaryOutline}
              onClick={handleCancel}
            >
              {t('common:cancel')}
            </Button>
          </div>
        ),
      }),
    );
  };

  return (
    <div className="p-6 relative">
      <div className="bg-gray-50 mb-6 rounded-lg p-4 flex items-center justify-between">
        <div className="flex">
          <PaymentProcessorIcon className="text-blue-600" />
          <div className="ml-4">
            <p className="text-secondary">
              {t('merchantServices.configuration.current')}
            </p>
            <p className="font-medium">{currentProcessor?.name}</p>
          </div>
        </div>
        <PermissionSection
          permission={Permissions.financialInfo}
          showPopover
          edit
        >
          <div>
            <Button
              variant={ButtonVariant.link}
              size={ButtonSize.medium}
              className="mr-3"
              onClick={() => handleChangeProcessor(prysymProcessor)}
              disabled={!financialPermissions.edit}
            >
              {t('merchantServices.configuration.tryPrysym')}
            </Button>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.medium}
              onClick={() => handleChangeProcessor(null)}
              disabled={!financialPermissions.edit}
            >
              {t('merchantServices.configuration.change')}
            </Button>
          </div>
        </PermissionSection>
      </div>
      <OnboardingTabHeader
        title={t('merchantServices.configuration.title')}
        subTitle={t('merchantServices.configuration.description')}
      />

      <ProcessorFields
        platform={currentProcessor?.value}
        control={control}
        register={register}
        watch={watch}
        setValue={setValue}
        onEdit={() => handleChangeProcessor(null)}
      />

      <div className="border-b border-gray-200 pb-5 mb-6 mt-16">
        <p className="heading-lg mb-1">
          {t('merchantServices.configuration.detailsTitle')}
        </p>
        <p className="text-secondary">
          {t('merchantServices.configuration.detailsDescription')}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-x-16 border-b border-gray-200 pb-12 mb-12">
        <div>
          <p className="heading-lg mb-1">
            {t('merchantServices.configuration.contactTitle')}
          </p>
          <p className="text-secondary">
            {t('merchantServices.configuration.contactDescription')}
          </p>
        </div>
        <ContactInfo register={register} control={control} watch={watch} />
      </div>

      <div className="grid grid-cols-2 gap-x-16 border-b border-gray-200 pb-12 mb-6">
        <div>
          <p className="heading-lg mb-1">
            {t('merchantServices.configuration.paymentTitle')}
          </p>
          <p className="text-secondary">
            {t('merchantServices.configuration.paymentDescription')}
          </p>
        </div>
        <PaymentDetails register={register} setValue={setValue} watch={watch} />
      </div>
      <ProcessorFiles />
    </div>
  );
};

export default ProcessorConfiguration;
