import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import Label from 'components/Inputs/components/Label';
import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import { ArrowLeftIcon } from 'components/icons';
import { NumberInput } from 'components/Inputs';
import { getTenant } from 'theme/selectors';

import { getCurrentTab, getLoginData } from '../selectors';
import { verifyCode, resendCode } from '../thunks';
import {
  setIsCodeSent,
  setCurrentTab,
  LOGIN_TAB_INDEX_EMAIL,
} from '../loginSlice';

const defaultValues = {
  one: '',
  two: '',
  three: '',
  four: '',
  five: '',
  six: '',
};

const CodeForm = () => {
  const { t } = useTranslation('login');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const data = useAppSelector(getLoginData);
  const currentTab = useAppSelector(getCurrentTab);
  const { supportEmailAddress } = useAppSelector(getTenant);

  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setFocus,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FieldValues>({
    defaultValues,
  });

  useEffect(() => {
    const listener = (e) => {
      e.preventDefault();

      const paste = (e.originalEvent || e).clipboardData.getData('text/plain');
      const code = paste.replace(new RegExp('[^0-9]', 'g'), '');

      reset({
        one: code[0],
        two: code[1],
        three: code[2],
        four: code[3],
        five: code[4],
        six: code[5],
      });
    };

    document.querySelector('.code-input')?.addEventListener('paste', listener);

    return () => {
      document
        .querySelector('.code-input')
        ?.removeEventListener('paste', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResend = async () => {
    setIsLoading(true);
    await dispatch(resendCode());
    setIsLoading(false);
  };

  const handleFormSubmit = (values) => {
    const verificationCode = Object.values(values).join('');

    if (verificationCode.length !== 6) {
      setError(true);
      return;
    }

    dispatch(verifyCode({ verificationCode, navigate }));
  };

  const handleChange = (value: number | string, key: string) => {
    if (value !== '') {
      const keys = Object.keys(defaultValues);
      const keyIndex = keys.indexOf(key);

      if (keyIndex + 2 <= keys.length) {
        setFocus(keys[keyIndex + 1]);
      }
    }

    if (error) {
      setError(false);
    }
  };

  const handleBack = (tab: number) => {
    if (tab !== currentTab) {
      dispatch(setCurrentTab(tab));
    }

    dispatch(setIsCodeSent(false));
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="w-full px-4 py-8 md:px-8"
    >
      <button
        className="cursor-pointer inline-block mb-4"
        onClick={() => handleBack(currentTab)}
        type="button"
      >
        <ArrowLeftIcon className="w-5 h-5" />
      </button>

      <div className="mb-6 text-gray-700">
        {t('login:code.info')}{' '}
        <span className="font-semibold whitespace-nowrap">
          {currentTab === LOGIN_TAB_INDEX_EMAIL
            ? data.email || ''
            : formatPhoneNumberIntl(data.phone || '')}
        </span>
      </div>

      <div className="mb-6">
        <Label
          info={
            <span>
              {t('login:code.title')}
              <span className="font-semibold ml-1">{supportEmailAddress}</span>
            </span>
          }
        >
          {t('login:code.label')}
        </Label>

        <div className="flex items-center">
          {Object.keys(defaultValues).map((key) => (
            <NumberInput
              key={key}
              name={key}
              error={errors?.[key]}
              control={control}
              maxLength={1}
              className="ml-[10px] first:ml-0 code-input"
              inputClassName={`text-center${error ? ' border-red-300' : ''}`}
              allowNegative={false}
              handleChange={(value) => handleChange(value, key)}
            />
          ))}
        </div>

        <HideComponent show={error}>
          <ErrorMessage
            error={{ message: t('login:code.validation.length') }}
          />
        </HideComponent>
      </div>

      <Button
        loading={isSubmitting}
        type="submit"
        className="mb-4 w-full"
        size={ButtonSize.mediumLarge}
        heightClass="h-11"
      >
        {t('common:submit')}
      </Button>

      <div className="flex justify-center">
        <Button
          onClick={handleResend}
          size={ButtonSize.mediumLarge}
          variant={ButtonVariant.mslLink}
          loading={isLoading}
          heightClass="h-11"
          className="w-full"
        >
          {t('login:code.resendCode')}
        </Button>
      </div>
    </form>
  );
};

export default CodeForm;
