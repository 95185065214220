import { useTranslation } from 'react-i18next';

import { PhoneIcon, MailIcon } from 'components/icons';
import logoRound from 'assets/images/logo-round.png';
import { formatPhoneNumber } from 'utils/phone';
import { getTenant } from 'theme/selectors';
import Button from 'components/Button';
import { useAppSelector } from 'hooks';

export default function ContactSupport() {
  const { t } = useTranslation();
  const { supportEmailAddress, telephoneNumber } = useAppSelector(getTenant);

  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-xl overflow-hidden">
      <div className="flex items-center p-6">
        <img src={logoRound} alt="Logo" className="flex-shrink-0" />
        <div className="ml-4">
          <p className="text-sm font-medium mb-0.5">
            {t('common:support.title')}
          </p>
          <div className="text-secondary flex flex-wrap items-center">
            <p className="mr-2">{supportEmailAddress}</p>
            <div className="w-px bg-gray-300 h-3 mr-2" />
            <p>{formatPhoneNumber(telephoneNumber)}</p>
          </div>
        </div>
      </div>
      <div className="divide-x divide-gray-200 flex items-center text-sm font-medium">
        <a href={`mailto:${supportEmailAddress}`} className="block w-full">
          <Button
            variant="link-secondary"
            className="w-full flex items-center justify-center rounded-none"
            rounded="none"
          >
            <MailIcon className="text-gray-400 mr-3" />
            <span>{t('common:support.email')}</span>
          </Button>
        </a>
        <a href={`tel:${telephoneNumber}`} className="block w-full">
          <Button
            variant="link-secondary"
            className="w-full flex items-center justify-center rounded-none"
            rounded="none"
          >
            <PhoneIcon className="text-gray-400 mr-3" />
            <span>{t('common:support.call')}</span>
          </Button>
        </a>
      </div>
    </div>
  );
}
