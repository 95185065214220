import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBusiness, getPerson, getOnboarding } from 'pages/Profile/selectors';
import { searchCrossBorderProviders } from 'services/crossborder';
import { getBusinessesId } from 'utils/authService';
import { RootState } from 'state/store';
import {
  CrossBorderSchema,
  addCrossBorder,
  searchCrossBorder,
  updateCrossBorder,
  searchBusinessCrossBorderProviders,
} from 'services/businesses';

import { calcTransactionalPercentage } from './utils';
import { getCrossBorder } from './selectors';

export const checkTransactionalProgress: any = createAsyncThunk(
  'transactional/checkTransactionalProgress',
  async (data: Partial<CrossBorderSchema> | null) => {
    try {
      return calcTransactionalPercentage(data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchCrossBorder: any = createAsyncThunk(
  'transactional/fetchCrossBorder',
  async (_: void, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();

      const crossBorders = await searchCrossBorder(businessesId, {
        isActive: true,
      });

      const crossBorderProviders = await searchCrossBorderProviders({
        isActive: true,
      });

      const businessCrossBorderProviders =
        await searchBusinessCrossBorderProviders(businessesId, {
          isActive: true,
        });

      const crossBorder = crossBorders.length ? crossBorders[0] : null;

      thunkAPI.dispatch(checkTransactionalProgress(crossBorder));

      return {
        crossBorder,
        crossBorderProviders,
        businessCrossBorderProviders,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveCrossBorder: any = createAsyncThunk(
  'transactional/saveCrossBorder',
  async (data: any, thunkAPI) => {
    try {
      const business = getBusiness(thunkAPI.getState() as RootState);
      const onboarding = getOnboarding(thunkAPI.getState() as RootState);

      if (!business?.businessesId || onboarding) {
        return null;
      }
      const businessesId: number = getBusinessesId();

      const { emailAddress } = getPerson(thunkAPI.getState() as RootState);
      const crossBorder = getCrossBorder(thunkAPI.getState() as RootState);

      let payload: Partial<CrossBorderSchema> = {};
      const fields: Partial<CrossBorderSchema> = {
        transactionEstimate12Month:
          data.transactionEstimate12Month === ''
            ? 0
            : parseInt(data.transactionEstimate12Month),
        transactionAverage:
          data.transactionAverage === ''
            ? 0
            : parseFloat(data.transactionAverage),
        transactionHigh:
          data.transactionHigh === '' ? 0 : parseFloat(data.transactionHigh),
        transactionPurpose:
          data.transactionPurpose === '' ? ' ' : data.transactionPurpose,
        regulatedActivities:
          data.regulatedActivities === '' ? false : data.regulatedActivities,
      };

      if (crossBorder?.id) {
        fields.modifiedBy = emailAddress;

        await updateCrossBorder(businessesId, crossBorder.id, fields);

        payload = {
          ...crossBorder,
          ...fields,
        };
      } else {
        fields.createdBy = emailAddress;

        const crossBorderId = await addCrossBorder(businessesId, fields);

        payload = {
          id: crossBorderId,
          businessesId,
          ...fields,
        };
      }

      thunkAPI.dispatch(checkTransactionalProgress(payload));

      return payload;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
