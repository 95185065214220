export const ProductIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V13C0 14.6569 1.34315 16 3 16C4.65685 16 6 14.6569 6 13V2C6 0.895431 5.10457 0 4 0H2ZM3 14C3.55228 14 4 13.5523 4 13C4 12.4477 3.55228 12 3 12C2.44772 12 2 12.4477 2 13C2 13.5523 2.44772 14 3 14Z"
      fill="#6B7280"
    />
    <path
      d="M8 12.2426L12.8995 7.34308C13.6805 6.56203 13.6805 5.2957 12.8995 4.51465L11.4853 3.10044C10.7042 2.31939 9.43789 2.31939 8.65684 3.10044L8 3.75728V12.2426Z"
      fill="#6B7280"
    />
    <path
      d="M14 16H7.07104L13.071 10H14C15.1046 10 16 10.8954 16 12V14C16 15.1046 15.1046 16 14 16Z"
      fill="#6B7280"
    />
  </svg>
);
