import { useState, useEffect } from 'react';
import classNames from 'classnames';

import PermissionSection from 'components/permission/PermissionSection';
import { CreditCardNetwork, Permissions } from 'entities/dashboard';
import { Checkbox } from 'components/Inputs';
import { useAppSelector } from 'hooks';

import { CardLogos } from './FinancialCardsAccepted';
import { getCardsAccepted } from '../selectors';

interface CardAcceptedButtonProps {
  cardNetwork: CreditCardNetwork;
  disabled: boolean;
  register: any;
  setValue: any;
  type: string;
  index: number;
}

const CardsAcceptedButton: React.FC<CardAcceptedButtonProps> = ({
  cardNetwork,
  disabled,
  register,
  setValue,
  type,
  index,
}) => {
  const cardsAccepted = useAppSelector(getCardsAccepted);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    const cardAccepted = cardsAccepted.find(
      (card) => card.creditCardNetworksId === cardNetwork.creditCardNetworksId,
    );
    const realType = type.charAt(0).toLowerCase() + type.slice(1);

    if (cardAccepted && cardAccepted[realType]) {
      setIsChecked(true);
    }
  }, [cardsAccepted, setIsChecked, cardNetwork.creditCardNetworksId, type]);

  const handleClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="w-full max-w-1/4 px-2">
      <PermissionSection
        permission={Permissions.financialInfo}
        showPopover
        edit
      >
        <button
          className={classNames(
            'rounded-lg border px-6 py-4 flex items-center justify-between w-full cursor-pointer',
            { 'opacity-50 cursor-default': disabled },
            isChecked
              ? 'bg-blue-50 border-blue-300 hover:border-blue-500'
              : 'bg-white border-gray-300 hover:border-gray-400',
          )}
          disabled={disabled}
          onClick={handleClick}
        >
          <div className="flex items-center">
            <img
              alt={cardNetwork.description}
              src={`/assets/payments/${CardLogos[cardNetwork.typeCode]}`}
            />
            <span className="text-sm text-gray-900 mx-2">
              {cardNetwork.description}
            </span>
          </div>

          <Checkbox
            name={`businessesFinancialsCardsAccepted.${index}.${type}`}
            checked={isChecked}
            setValue={setValue}
            register={register}
          />
        </button>
      </PermissionSection>
    </div>
  );
};

export default CardsAcceptedButton;
