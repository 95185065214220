import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/index.css';
import './styles/tailwind.css';
import App from './App';
import Loader from 'components/Loading';
import store from 'state/store';

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root'),
);
