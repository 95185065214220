import Steps from 'components/Steps';

interface StepsContainerProps {
  step: number;
  stepsLength: number;
}

const StepsContainer: React.FC<StepsContainerProps> = ({
  step,
  children,
  stepsLength,
}) => {
  if (!step || step > stepsLength) {
    return null;
  }
  return (
    <div className="p-6 w-full">
      <div className="mb-9">
        <Steps step={step} stepsLength={stepsLength} />
      </div>
      {children}
    </div>
  );
};

export default StepsContainer;
