export const BuildingIcon = ({ className }: { className?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.125 15.75V13.5C10.125 13.0858 9.78921 12.75 9.375 12.75H8.625C8.21079 12.75 7.875 13.0858 7.875 13.5V15.75H3.75V3C3.75 2.17157 4.42157 1.5 5.25 1.5H12.75C13.5784 1.5 14.25 2.17157 14.25 3V15.75H10.125ZM6.75 3C6.33579 3 6 3.33579 6 3.75V4.5C6 4.91421 6.33579 5.25 6.75 5.25H7.5C7.91421 5.25 8.25 4.91421 8.25 4.5V3.75C8.25 3.33579 7.91421 3 7.5 3H6.75ZM10.5 3C10.0858 3 9.75 3.33579 9.75 3.75V4.5C9.75 4.91421 10.0858 5.25 10.5 5.25H11.25C11.6642 5.25 12 4.91421 12 4.5V3.75C12 3.33579 11.6642 3 11.25 3H10.5ZM6.75 6C6.33579 6 6 6.33579 6 6.75V7.5C6 7.91421 6.33579 8.25 6.75 8.25H7.5C7.91421 8.25 8.25 7.91421 8.25 7.5V6.75C8.25 6.33579 7.91421 6 7.5 6H6.75ZM6.75 9C6.33579 9 6 9.33579 6 9.75V10.5C6 10.9142 6.33579 11.25 6.75 11.25H7.5C7.91421 11.25 8.25 10.9142 8.25 10.5V9.75C8.25 9.33579 7.91421 9 7.5 9H6.75ZM10.5 9C10.0858 9 9.75 9.33579 9.75 9.75V10.5C9.75 10.9142 10.0858 11.25 10.5 11.25H11.25C11.6642 11.25 12 10.9142 12 10.5V9.75C12 9.33579 11.6642 9 11.25 9H10.5Z"
      fill="#2563EB"
    />
    <path
      d="M11.25 6H10.5C10.0858 6 9.75 6.33579 9.75 6.75V7.5C9.75 7.91421 10.0858 8.25 10.5 8.25H11.25C11.6642 8.25 12 7.91421 12 7.5V6.75C12 6.33579 11.6642 6 11.25 6Z"
      fill="white"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 15.75H15C15.4142 15.75 15.75 16.0858 15.75 16.5V16.8C15.75 17.0485 15.5485 17.25 15.3 17.25H2.7C2.45147 17.25 2.25 17.0485 2.25 16.8V16.5C2.25 16.0858 2.58579 15.75 3 15.75Z"
      fill="#2563EB"
    />
  </svg>
);
