import { createSlice } from '@reduxjs/toolkit';

import { clearDashboard } from 'pages/dashboard/thunks';
import { FinancialState } from 'entities/financial';

import {
  fetchFinancialData,
  createBusinessFinancial,
  updateBusinessFinancial,
  createBlobReference,
  deleteBlobReference,
} from './thunks';

export const initialState: FinancialState = {
  businessFinancial: null,
  cardsAccepted: [],
  delayedSales: [],
  blobReferences: [],
  yearlyGrowth: [],
  geographicSales: [],
  currentCurrency: 'USD',
  authorizing: false,
  isFormUpdate: true,
  isLoading: false,
  fileLoading: '',
  loadingProgress: 0,
};

const financialSlice = createSlice({
  name: 'financial',
  initialState,
  reducers: {
    setFormUpdate(state) {
      state.isFormUpdate = true;
    },
    setCurrentCurrency(state, action) {
      state.currentCurrency = action.payload;
    },
    setLoadingProgress(state, action) {
      state.loadingProgress = action.payload;
    },
    startLoading(state, action) {
      state.fileLoading = action.payload;
      state.loadingProgress = 1;
    },
    stopLoading(state) {
      state.fileLoading = '';
      state.loadingProgress = 0;
    },
    setAuthorizing(state, action) {
      state.authorizing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchFinancialData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFinancialData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchFinancialData.fulfilled, (state, action) => {
      if (action.payload) {
        state.businessFinancial = action.payload.businessFinancial;
        state.currentCurrency = action.payload.currentCurrency;
        state.cardsAccepted = action.payload.cardsAccepted;
        state.delayedSales = action.payload.delayedSales;
        state.blobReferences = action.payload.blobReferences;
        state.yearlyGrowth = action.payload.yearlyGrowth;
        state.geographicSales = action.payload.geographicSales;
      }

      state.isLoading = false;
      state.isFormUpdate = false;
    });
    builder.addCase(createBusinessFinancial.fulfilled, (state, action) => {
      if (action.payload) {
        state.businessFinancial = action.payload.businessFinancial;
        state.cardsAccepted = action.payload.cardsAccepted;
        state.delayedSales = action.payload.delayedSales;
        state.blobReferences = action.payload.blobReferences;
        state.yearlyGrowth = action.payload.yearlyGrowth;
        state.geographicSales = action.payload.geographicSales;
      }
      state.isFormUpdate = false;
    });
    builder.addCase(updateBusinessFinancial.fulfilled, (state, action) => {
      if (action.payload) {
        state.businessFinancial = action.payload.businessFinancial;
        state.cardsAccepted = action.payload.cardsAccepted;
        state.delayedSales = action.payload.delayedSales;
        state.blobReferences = action.payload.blobReferences;
        state.yearlyGrowth = action.payload.yearlyGrowth;
        state.geographicSales = action.payload.geographicSales;
      }

      state.isFormUpdate = false;
    });
    builder.addCase(createBlobReference.fulfilled, (state, action) => {
      state.blobReferences = action.payload;
    });
    builder.addCase(deleteBlobReference.fulfilled, (state, action) => {
      state.blobReferences = action.payload;
    });
  },
});

export const {
  setFormUpdate,
  setCurrentCurrency,
  setLoadingProgress,
  startLoading,
  stopLoading,
  setAuthorizing,
} = financialSlice.actions;

export default financialSlice.reducer;
