import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Alert, { AlertTypes } from 'components/Alert';
import HideComponent from 'components/HideComponent';
import { CandidateProps } from 'entities/profile';
import Loading from 'components/Loading';
import Button from 'components/Button';
import {
  InfoCircleSolidIcon,
  ChevronRightIcon,
  AngleLeftIcon,
} from 'components/icons';

import { searchCandidatesByAddress, updateBusiness } from '../thunks';
import { setSelectedCandidate, setStep } from '../profileSlice';
import { onboardingSteps } from '../utils';
import BusinessCard from './BusinessCard';
import {
  getBusiness,
  getBusinessesAddress,
  getCandidates,
  getIsLoadinCandidates,
} from '../selectors';

const ChooseYourBusiness = () => {
  const { t } = useTranslation('profile');
  const dispatch = useAppDispatch();

  const business = useAppSelector(getBusiness);
  const businessAddress = useAppSelector(getBusinessesAddress);
  const candidates = useAppSelector(getCandidates);
  const isLoading = useAppSelector(getIsLoadinCandidates);

  const [loading, setLoading] = useState<string>('');

  function onSelect(id: number) {
    dispatch(setSelectedCandidate(id));
  }

  useEffect(() => {
    const selectedCandidate = candidates.data.find(
      (candidate: CandidateProps) =>
        candidate.organization.duns === business.dunsnumber,
    );
    if (selectedCandidate?.displaySequence) {
      dispatch(setSelectedCandidate(selectedCandidate.displaySequence));
    }
  }, [candidates.data, business.dunsnumber, dispatch]);

  useEffect(() => {
    if (business.businessLegalName && businessAddress.addressLine1) {
      dispatch(
        searchCandidatesByAddress({
          name: business.businessLegalName,
          address: businessAddress.addressLine1,
          countryISOAlpha2code: businessAddress.alpha2Code,
          addressLocality: businessAddress.city,
          addressRegion: businessAddress.stateOrProvince,
          postalCode: businessAddress.postalCode,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleNext = async () => {
    setLoading('next');

    const candidate = candidates.data.find(
      (item: CandidateProps) => item.displaySequence === candidates.selected,
    );

    if (candidate && candidate.organization.duns !== business.dunsnumber) {
      await dispatch(
        updateBusiness({
          dunsnumber: candidate.organization.duns,
          noDunslisted: false,
        }),
      );
    }
    setLoading('');
    dispatch(setStep(onboardingSteps.person));
  };

  const handleNotListed = useCallback(async () => {
    setLoading('clear');
    await dispatch(
      updateBusiness({
        noDunslisted: true,
        dunsnumber: '',
      }),
    );
    setLoading('');
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && candidates.data.length === 0 && !business.noDunslisted) {
      handleNotListed();
    }
  }, [isLoading, candidates.data, business.noDunslisted, handleNotListed]);

  return (
    <div className="divide-y divide-gray-200 h-full flex flex-col justify-between flex-1">
      <div>
        <h2 className="heading-2xl sm:heading-4xl">
          {t('profile:businessList.title')}
        </h2>
        <HideComponent
          show={!candidates.isLoading && candidates.data.length > 0}
        >
          <>
            <h6 className="sm:text-lg leading-6 text-gray-700 mt-8 mb-4 pb-4 border-b border-gray-200">
              {t('profile:businessList.subTitle')}
            </h6>
            <Alert
              type={AlertTypes.info}
              description={t('profile:businessList.outdatedInfo')}
              className="mb-4"
            />
          </>
        </HideComponent>

        <HideComponent
          show={!candidates.isLoading && candidates.data.length === 0}
        >
          <Alert
            type={AlertTypes.info}
            description={t('profile:businessList.emptyList')}
            className="mt-6"
          />
        </HideComponent>

        <Loading loading={isLoading} fullHeight={false} transparent />

        {candidates.data.map((candidate: CandidateProps) => (
          <BusinessCard
            business={candidate}
            key={candidate.displaySequence}
            onSelect={onSelect}
            selected={candidates.selected === candidate.displaySequence}
          />
        ))}

        <HideComponent
          show={!candidates.isLoading && candidates.data.length > 0}
        >
          <div className="flex flex-wrap justify-center sm:justify-end my-9.5">
            <div className="w-full sm:w-auto flex items-center mb-3 sm:mb-0 justify-center">
              <InfoCircleSolidIcon className="flex-shrink-0 text-gray-400" />
              <p className="ml-2 text-sm leading-5 font-medium text-gray-700">
                {t('profile:businessList.manualProcedure')}
              </p>
            </div>

            <Button
              type="button"
              className="sm:ml-6"
              size="medium"
              variant="secondary-outline"
              onClick={handleNotListed}
              loading={loading === 'clear'}
            >
              {t('profile:businessList.buttonNotListed')}
            </Button>
          </div>
        </HideComponent>
      </div>

      <div className="flex flex-wrap pt-6 items-center justify-between">
        <Button
          type="button"
          className="sm:ml-6 w-full sm:w-1/2 sm:max-w-[200px] mb-4 sm:mb-0"
          variant="secondary-outline"
          onClick={() => dispatch(setStep(onboardingSteps.business))}
          heightClass="h-11"
        >
          <AngleLeftIcon className="mr-3" />
          {t('common:back')}
        </Button>
        <Button
          type="button"
          className="sm:ml-6 w-full sm:w-1/2 sm:max-w-[200px]"
          onClick={handleNext}
          disabled={!candidates.selected && !business.noDunslisted}
          loading={loading === 'next'}
          heightClass="h-11"
        >
          {t('common:nextStep')}
          <ChevronRightIcon className="ml-3" />
        </Button>
      </div>
    </div>
  );
};

export default ChooseYourBusiness;
