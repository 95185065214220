export const CartIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    className={className}
  >
    <path
      d="M1.66663 0C1.11434 0 0.666626 0.447715 0.666626 1C0.666626 1.55228 1.11434 2 1.66663 2H2.88585L3.19141 3.22224C3.19462 3.23637 3.19812 3.25039 3.20192 3.26429L4.55915 8.69321L3.66658 9.58578C2.40665 10.8457 3.29898 13 5.08079 13H13.6666C14.2189 13 14.6666 12.5523 14.6666 12C14.6666 11.4477 14.2189 11 13.6666 11L5.08079 11L6.08079 10H12.6666C13.0454 10 13.3917 9.786 13.5611 9.44721L16.5611 3.44721C16.716 3.13723 16.6995 2.76909 16.5173 2.47427C16.3351 2.17945 16.0132 2 15.6666 2H4.9474L4.63677 0.757464C4.52548 0.312297 4.12549 0 3.66663 0H1.66663Z"
      fill="#6B7280"
    />
    <path
      d="M14.6666 15.5C14.6666 16.3284 13.9951 17 13.1666 17C12.3382 17 11.6666 16.3284 11.6666 15.5C11.6666 14.6716 12.3382 14 13.1666 14C13.9951 14 14.6666 14.6716 14.6666 15.5Z"
      fill="#6B7280"
    />
    <path
      d="M5.16663 17C5.99505 17 6.66663 16.3284 6.66663 15.5C6.66663 14.6716 5.99505 14 5.16663 14C4.3382 14 3.66663 14.6716 3.66663 15.5C3.66663 16.3284 4.3382 17 5.16663 17Z"
      fill="#6B7280"
    />
  </svg>
);
