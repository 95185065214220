import { useTranslation } from 'react-i18next';
import { useMemo, useEffect } from 'react';

import BankAccountOption from 'pages/dashboard/components/Configuration/components/BankAccountOption';
import { getBankAccounts } from 'pages/dashboard/components/BankAccounts/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { BankAccount } from 'entities/bankAccounts';
import { Permissions } from 'entities/dashboard';
import {
  RadioGroup,
  Switch,
  SelectIndicatorTypes,
  Select,
  Input,
} from 'components/Inputs';

import { updateFormValues } from '../merchantSlice';

interface MappedBankAccount extends BankAccount {
  name: string;
  value: number;
}

const BankAccountWidget = ({ setValue, watch, permissions }) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const bankAccounts: BankAccount[] = useAppSelector(getBankAccounts);

  const settlementBankAccountsId = watch('settlementBankAccountsId');

  useEffect(() => {
    dispatch(
      updateFormValues({
        settlementBankAccountsId: settlementBankAccountsId,
        settlementAccount: settlementBankAccountsId ? 'existing' : '',
      }),
    );
  }, [settlementBankAccountsId, dispatch]);

  const mappedBankAccounts: MappedBankAccount[] = useMemo(
    () =>
      bankAccounts
        .filter(
          (account: BankAccount) => account.isActive && account.accountName,
        )
        .map((account: BankAccount) => ({
          ...account,
          name: t('configuration:smart.bankAccount.selectedName', {
            name: account.accountName,
            number: account.accountNumberLast4,
          }),
          value: account.bankAccountsId,
        })),
    [bankAccounts, t],
  );

  const handleSelectAccount = (bankAccount: BankAccount) => {
    setValue('settlementBankAccountsId', bankAccount.bankAccountsId || 0);
  };

  return (
    <div className="pt-4">
      <PermissionSection permission={Permissions.bankIntegration} showMessage>
        <Select
          name="settlementBankAccountsId"
          label={t('merchantServices.configuration.payment.bankAccount.label')}
          placeholder={t(
            'merchantServices.configuration.payment.bankAccount.placeholder',
          )}
          indicator={SelectIndicatorTypes.selector}
          onChange={handleSelectAccount}
          optionComponent={BankAccountOption}
          classes={{ activeOption: 'bg-blue-50 cursor-pointer' }}
          options={mappedBankAccounts}
          value={mappedBankAccounts.find(
            (account: MappedBankAccount) =>
              account.value === settlementBankAccountsId,
          )}
          disabled={!permissions.edit}
        />
      </PermissionSection>
    </div>
  );
};

const RoutingNumberWidget = ({ register, permissions }) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="pt-4">
      <Input
        label={t('merchantServices.configuration.payment.routingNumber.label')}
        name="routingNumber"
        register={register}
        readOnly={!permissions.edit}
      />
    </div>
  );
};

const PaymentDetails = ({ register, setValue, watch }) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const capture = watch('captureMoreThanPreAuth');

  useEffect(() => {
    dispatch(
      updateFormValues({
        captureMoreThanPreAuth: capture,
      }),
    );
  }, [capture, dispatch]);

  return (
    <div>
      <p className="text-sm font-medium mb-4">
        {t('merchantServices.configuration.payment.title')}
      </p>
      <RadioGroup
        options={[
          {
            value: 'existing',
            name: t('merchantServices.configuration.payment.existingAccount'),
            content: (
              <BankAccountWidget
                setValue={setValue}
                watch={watch}
                permissions={financialPermissions}
              />
            ),
          },
          {
            value: 'new',
            name: t('merchantServices.configuration.payment.newAccount'),
            content: (
              <RoutingNumberWidget
                register={register}
                permissions={financialPermissions}
              />
            ),
          },
        ]}
        name="settlementAccount"
        className="mb-9"
        optionClassName="mb-3 p-4 bg-gray-50"
        disabled={!financialPermissions.edit}
        permission={Permissions.financialInfo}
        register={register}
        watch={watch}
        vertical
      />
      <div className="flex items-start mb-9">
        <PermissionSection
          permission={Permissions.financialInfo}
          showPopover
          edit
        >
          <Switch
            isOn={capture}
            handleToggle={() => setValue('captureMoreThanPreAuth', !capture)}
            disabled={!financialPermissions.edit}
          />
        </PermissionSection>
        <div className="ml-3">
          <p className="text-sm font-medium mb-0.5">
            {t('merchantServices.configuration.payment.capture.title')}
          </p>
          <p className="text-secondary text-xs">
            {t('merchantServices.configuration.payment.capture.description')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
