import { useTranslation } from 'react-i18next';

import logoRound from 'assets/images/group.png';
import Button from 'components/Button';
import { GreenFlagIcon, DownloadWhIteIcon } from 'components/icons';
import { Switch } from 'components/Inputs';

interface SuccessCreatedBusinessPlanProps {
  step: number;
  handleToggle: () => void;
  isOn: boolean;
  onDownload: () => void;
  isDownload: any;
}

const SuccessCreatedBusinessPlan: React.FC<SuccessCreatedBusinessPlanProps> = ({
  step,
  handleToggle,
  isOn,
  onDownload,
  isDownload,
}) => {
  const { t } = useTranslation('dashboard');
  if (step !== 6) {
    return null;
  }
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex bg-gray-50 p-[30px] rounded-lg">
        <div className="h-80 w-[370px] relative">
          <img
            src={logoRound}
            alt="label"
            className="h-80 w-82 absolute bottom-[-96px]"
          />
        </div>
        <div className="max-w-[630px]">
          <div className="flex mb-11 items-center">
            <GreenFlagIcon />
            <div className="ml-5">
              <h3 className="text-xl leading-7 font-semibold text-gray-900 mb-[2px]">
                {t('dashboard:businessPlan.modal.youDidIt')}
              </h3>
              <div className="bg-green-100 rounded-full py-[2px] px-[10px] flex">
                <span className="text-xs leading-4 font-medium text-green-500">
                  {t('dashboard:businessPlan.createdAbc')}
                </span>
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="">
              <h3 className="text-xl leading-7 font-semibold text-gray-900">
                {t('dashboard:businessPlan.leanBusinessPlan')}
              </h3>
              <span className="text-sm leading-5 font-normal text-gray-500">
                {t('dashboard:businessPlan.createdPlanDescription')}
              </span>
            </div>
          </div>
          <div className="flex gap-x-6">
            <Button
              className="w-full"
              onClick={onDownload}
              loading={isDownload}
            >
              <DownloadWhIteIcon />
              <span className="ml-4">
                {t('dashboard:businessPlan.buttons.downloadPlan')}
              </span>
            </Button>
            <div className="w-full">
              {/* <Button
                type="button"
                className="w-full bg-white"
                variant="secondary-outline"
              >
                {t('dashboard:businessPlan.buttons.reviewPlan')}
              </Button> */}
            </div>
          </div>
          <div className="flex mt-9">
            <Switch handleToggle={handleToggle} isOn={isOn} />
            <span className="text-sm ml-3 leading-5 font-medium text-gray-900">
              {t('dashboard:businessPlan.buttons.addBusinessToProfile')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessCreatedBusinessPlan;
