import { useTranslation } from 'react-i18next';

import OnboardingTabHeader from 'pages/dashboard/components/OnboardingTabHeader';
import { useAppSelector } from 'hooks';

import CurrentOptionTitle from './CurrentOptionTitle';
import { getIsLoading } from '../selectors';
import Loading from 'components/Loading';

const PrysymConfiguration = ({ children }) => {
  const { t } = useTranslation('dashboard');

  const isLoading: boolean = useAppSelector(getIsLoading);

  return (
    <div className="p-6 relative">
      <Loading loading={isLoading} transparent fullHeight />
      <CurrentOptionTitle />
      <OnboardingTabHeader
        title={t('merchantServices.configuration.title')}
        subTitle={t('merchantServices.configuration.description')}
      />
      {children}
    </div>
  );
};

export default PrysymConfiguration;
