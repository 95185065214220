import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { whereEq } from 'ramda';

import { NumberInput, Input, Select, PhoneInput } from 'components/Inputs';
import { getCountries, hasPermission } from 'pages/dashboard/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import { getBusinessesAddress } from 'pages/Profile/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { PhoneIcon, MailIcon } from 'components/icons';
import AddressState from 'components/AddressState';
import { CountryISOCodes } from 'common/countries';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';

import { getOwnersBusinessSchema } from '../utils';
import { getTotalShare } from '../selectors';
import {
  createOwnerBusiness,
  updateOwnerBusiness,
  deleteOwnerPerson,
  deleteOwnerBusiness,
} from '../thunks';

interface EntityFormProps {
  ownerData: any;
  isOpen: boolean;
  number: number;
}

enum IsLoadingButtons {
  empty,
  submit,
  delete,
}

const EntityForm: React.FC<EntityFormProps> = ({
  ownerData,
  isOpen,
  number,
}) => {
  const { t } = useTranslation('ownership');
  const dispatch = useAppDispatch();

  const countries = useAppSelector(getCountries);
  const totalShare = useAppSelector(getTotalShare);
  const { countryIsocode } = useAppSelector(getBusinessesAddress);
  const ownershipPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.ownership),
  );

  const [isLoading, setIsLoading] = useState<IsLoadingButtons>(
    IsLoadingButtons.empty,
  );

  const maxShare = totalShare - (ownerData.ownershipPercentage || 0);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    defaultValues: ownerData,
    resolver: yupResolver(getOwnersBusinessSchema(t, maxShare)),
  });

  const values = watch();
  const hideSubmitButton = whereEq(values, ownerData);

  useEffect(() => {
    reset(ownerData);
  }, [ownerData, reset]);

  const handleFormSubmit = async (data) => {
    setIsLoading(IsLoadingButtons.submit);

    if (ownerData.businessesOwnersPersonsId) {
      await dispatch(deleteOwnerPerson(ownerData.businessesOwnersPersonsId));
    }

    await dispatch(
      ownerData.businessesOwnersBusinessesId
        ? updateOwnerBusiness(data)
        : createOwnerBusiness(data),
    );

    setIsLoading(IsLoadingButtons.empty);
  };

  const onSubmit = () => {
    handleSubmit(handleFormSubmit)();
  };

  const closeConfirmationDialog = () => {
    dispatch(closeDialog());
  };

  const onDelete = () => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: closeConfirmationDialog,
        onCancel: closeConfirmationDialog,
        content: (
          <DangerDialog
            title={t('common:confirmationText')}
            buttonText={t('common:remove')}
            onCancel={closeConfirmationDialog}
            onConfirm={async () => {
              closeConfirmationDialog();

              setIsLoading(IsLoadingButtons.delete);

              await dispatch(
                deleteOwnerBusiness(ownerData.businessesOwnersBusinessesId),
              );

              setIsLoading(IsLoadingButtons.empty);
            }}
          />
        ),
      }),
    );
  };

  const defaultCountry = countryIsocode || CountryISOCodes.USA;

  const selectedCountry = countries.find(
    (country) => country.value === (ownerData.countryIsocode || defaultCountry),
  );

  const selectedBusinessCountry = countries.find(
    (country) =>
      country.value ===
      (ownerData.countryofIncorporationIsocode || defaultCountry),
  );

  return (
    <div className={isOpen ? '' : 'hidden'}>
      <div className="flex items-start -mx-4.5">
        <Input
          name="businessLegalName"
          label={t('common:fields.businessLegalName.label')}
          className="w-full mt-6 mx-4.5"
          register={register}
          error={errors?.businessLegalName}
          readOnly={!ownershipPermissions.edit}
        />
        <NumberInput
          name="ownershipPercentage"
          label={t('ownership:fields.ownershipPercentage.label')}
          placeholder={t('ownership:fields.ownershipPercentage.placeholder')}
          className="w-full mt-6 mx-4.5"
          inputClassName="pr-8"
          suffix="%"
          suffixInline
          control={control}
          error={errors?.ownershipPercentage}
          readOnly={!ownershipPermissions.edit}
        />
      </div>

      <div className="border-b border-gray-300 pb-5 mt-16">
        <p className="heading-lg">
          {t('ownership:ownerContactTitle', { number })}
        </p>
      </div>

      <div className="flex -mx-8 pb-5">
        <div className="w-1/2 mx-8">
          <Input
            name="addressLine1"
            label={t('common:fields.address.label')}
            className="w-full mt-6"
            register={register}
            error={errors?.addressLine1}
            readOnly={!ownershipPermissions.edit}
          />
          <div className="flex -mx-4.5">
            <Input
              name="postalCode"
              label={t('common:fields.postal.label')}
              className="w-full mx-4.5 mt-6"
              register={register}
              error={errors?.postalCode}
              readOnly={!ownershipPermissions.edit}
            />
            <Input
              name="city"
              label={t('common:fields.city.label')}
              className="w-full mx-4.5 mt-6"
              register={register}
              error={errors?.city}
              readOnly={!ownershipPermissions.edit}
            />
          </div>
          <div className="flex -mx-4.5">
            <Input
              name="addressLine2"
              label={t('ownership:fields.building.label')}
              className="w-full mx-4.5 mt-6"
              register={register}
              error={errors?.addressLine2}
              readOnly={!ownershipPermissions.edit}
              optional
            />
            <Input
              name="addressLine3"
              label={t('ownership:fields.apt.label')}
              className="w-full mx-4.5 mt-6"
              register={register}
              error={errors?.addressLine3}
              readOnly={!ownershipPermissions.edit}
              optional
            />
          </div>
          <AddressState
            label={t('common:fields.province.label')}
            name="stateOrProvince"
            className="w-full mt-6"
            countryISOCode={values.countryIsocode}
            value={ownerData.stateOrProvince}
            error={errors?.stateOrProvince}
            disabled={!ownershipPermissions.edit}
            register={register}
            setValue={setValue}
          />
          <Select
            label={t('common:fields.country.label')}
            name="countryIsocode"
            value={selectedCountry}
            className="w-full mt-6"
            options={countries}
            register={register}
            setValue={setValue}
            error={errors?.countryIsocode}
            disabled={!ownershipPermissions.edit}
            withIcon
          />
        </div>
        <div className="w-1/2 mx-8">
          <PhoneInput
            label={t('ownership:fields.businessPhone.label')}
            placeholder={t('ownership:fields.businessPhone.placeholder')}
            name="phone"
            className="w-full mt-6"
            iconEnd={<PhoneIcon className="text-gray-400" />}
            control={control}
            error={errors?.phone}
            readOnly={!ownershipPermissions.edit}
          />
          <Input
            label={t('ownership:fields.businessEmail.label')}
            placeholder={t('ownership:fields.businessEmail.placeholder')}
            name="emailAddress"
            className="w-full mt-6"
            iconEnd={<MailIcon className="text-gray-400" />}
            register={register}
            error={errors?.emailAddress}
            readOnly={!ownershipPermissions.edit}
            optional
          />
          <Select
            label={t('ownership:fields.incorporationCountry.label')}
            name="countryofIncorporationIsocode"
            value={selectedBusinessCountry}
            className="w-full mt-6"
            options={countries}
            register={register}
            setValue={setValue}
            error={errors?.countryofIncorporationIsocode}
            disabled={!ownershipPermissions.edit}
            withIcon
          />
        </div>
      </div>

      {!hideSubmitButton && (
        <PermissionSection permission={Permissions.ownership} showPopover edit>
          <Button
            onClick={onSubmit}
            className="mr-3 mt-6"
            disabled={!ownershipPermissions.edit}
            loading={isLoading === IsLoadingButtons.submit}
          >
            {t('common:submit')}
          </Button>
        </PermissionSection>
      )}

      {ownerData.businessesOwnersBusinessesId && (
        <PermissionSection permission={Permissions.ownership} showPopover edit>
          <Button
            onClick={onDelete}
            variant="red-pale"
            className="mt-6"
            disabled={!ownershipPermissions.edit}
            loading={isLoading === IsLoadingButtons.delete}
          >
            {t('common:remove')}
          </Button>
        </PermissionSection>
      )}
    </div>
  );
};

export default EntityForm;
