import { createSlice, current } from '@reduxjs/toolkit';

import { BankAccount, BankAccountsState } from 'entities/bankAccounts';
import { clearDashboard } from 'pages/dashboard/thunks';

import {
  fetchBankAccounts,
  fetchBankAccountData,
  fetchVendorCredentials,
  createBankAccount,
  updateBankAccount,
  disconnectBankAccount,
  fetchBankIntegrators,
  removeBankAccountFile,
  connectBankAccount,
  fetchCheckRanges,
  updateBankAccountRanges,
  createFundingAccount,
  fetchFundingAccount,
  saveVendorCredentials,
} from './thunks';

export const initialState: BankAccountsState = {
  bankAccounts: [],
  bankAccount: null,
  fundingAccount: null,
  vendorCredentials: null,
  bankIntegrators: [],
  bankAccountTypes: [],
  integrator: null,
  fields: [],
  directoryId: 0,
  step: 1,
  isFormUpdate: false,
  isLoading: false,
  isConnecting: false,
  isSuccess: false,
  isRefreshing: false,
  showBankName: false,
  isSubmiting: false,
  alerts: [],
  isBankAccountInfoOpen: false,
  isBankAccountOpen: false,
  isFundingAccountOpen: false,
  backToBankAccount: 0,
  showConnectionForm: false,
  selectedConnection: -1,
  checkRanges: [],
  checkOmitted: [],
};

const bankAccountSlice = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    setShowConnectionForm(state, action) {
      state.showConnectionForm = action.payload;
    },
    setSelectedConnection(state, action) {
      state.selectedConnection = action.payload;
      state.isFormUpdate = true;
    },
    selectConnection(state) {
      state.showConnectionForm = true;
      state.isFormUpdate = true;
    },
    viewBankAccount(state, action) {
      const { bankAccounts, bankIntegrators, backToBankAccount } =
        current(state);

      const bankAccount =
        bankAccounts
          .slice()
          .find((account) => account.bankAccountsId === action.payload) || null;

      const integrator =
        bankIntegrators
          .slice()
          .find(
            (integ) =>
              integ.banksIntegratorsId ===
              (bankAccount?.banksIntegratorsId ?? null),
          ) || null;

      state.bankAccount = bankAccount;
      state.integrator = integrator;
      state.directoryId = bankAccount?.integratorDirectoryId || 0;

      if (backToBankAccount) {
        state.backToBankAccount = 0;
        state.isFundingAccountOpen = false;
        state.isBankAccountOpen = true;
      } else {
        state.isBankAccountInfoOpen = true;
      }
    },
    setIntegrator(state, action) {
      state.integrator = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    setBankAccountFields(state, action) {
      state.fields = action.payload;
    },
    setShowBankName(state, action) {
      state.showBankName = action.payload;
    },
    setIsSuccess(state, action) {
      state.isSuccess = action.payload;
      state.showBankName = false;
    },
    setIsFormUpdate(state) {
      state.isFormUpdate = false;
    },
    setIsBankAccountInfoOpen(state, action) {
      state.isBankAccountInfoOpen = action.payload;
    },
    setIsBankAccountOpen(state, action) {
      state.isBankAccountOpen = action.payload;
    },
    setIsFundingAccountOpen(state, action) {
      state.isFundingAccountOpen = action.payload;
    },
    setBackToBankAccount(state, action) {
      state.backToBankAccount = action.payload;
    },
    showAlert(state, action) {
      state.alerts = [...state.alerts, action.payload];
    },
    hideAlert(state, action) {
      state.alerts = state.alerts.filter(
        (alert) => alert.code !== action.payload,
      );
    },
    resetBankAccountInfoModal(state) {
      state.isBankAccountInfoOpen = false;
      state.bankAccount = initialState.bankAccount;
      state.integrator = initialState.integrator;
    },
    resetFundingAccountModal(state) {
      state.fundingAccount = null;
      state.backToBankAccount = 0;
    },
    resetBankAccountModal(state) {
      state.step = 1;
      state.bankAccount = null;
      state.isSuccess = false;
      state.showBankName = false;
      state.isSubmiting = false;
      state.integrator = null;
      state.fields = [];
      state.checkRanges = [];
      state.checkOmitted = [];
      state.showConnectionForm = false;
      state.selectedConnection = -1;
      state.isConnecting = false;
      state.vendorCredentials = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchBankAccounts.pending, (state) => {
      state.isRefreshing = true;
    });
    builder.addCase(fetchBankAccounts.rejected, (state) => {
      state.isRefreshing = false;
    });
    builder.addCase(fetchBankAccounts.fulfilled, (state, action) => {
      state.isRefreshing = false;
      state.bankAccounts = action.payload;
    });
    builder.addCase(fetchBankAccountData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBankAccountData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchBankAccountData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bankAccountTypes = action.payload.bankAccountTypes;
      state.directoryId = action.payload.directoryId;
      state.selectedConnection = -1;
    });
    builder.addCase(fetchCheckRanges.fulfilled, (state, action) => {
      state.checkRanges = action.payload.ranges;
      state.checkOmitted = action.payload.omitted;
    });
    builder.addCase(fetchBankIntegrators.fulfilled, (state, action) => {
      state.bankIntegrators = action.payload;
    });
    builder.addCase(fetchVendorCredentials.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchVendorCredentials.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchVendorCredentials.fulfilled, (state, action) => {
      state.fields = action.payload.fields;
      state.vendorCredentials = action.payload.vendorCredentials;

      state.isLoading = false;
    });
    builder.addCase(createBankAccount.pending, (state) => {
      state.isSubmiting = true;
    });
    builder.addCase(createBankAccount.rejected, (state) => {
      state.isSubmiting = false;
    });
    builder.addCase(createBankAccount.fulfilled, (state, action) => {
      const { integrator } = current(state);

      state.isSubmiting = false;
      if (action.payload) {
        state.bankAccount = action.payload;
        state.bankAccounts.push(action.payload);

        if (integrator) {
          state.step = 3;
        }
      }
    });
    builder.addCase(createFundingAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.bankAccounts.push(action.payload);
      }
    });
    builder.addCase(fetchFundingAccount.pending, (state) => {
      state.isFundingAccountOpen = true;
      state.isLoading = true;
    });
    builder.addCase(fetchFundingAccount.rejected, (state) => {
      state.isFundingAccountOpen = false;
      state.isLoading = false;
    });
    builder.addCase(fetchFundingAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.fundingAccount = action.payload;
      } else {
        state.isFundingAccountOpen = false;
      }

      state.isLoading = false;
    });
    builder.addCase(updateBankAccountRanges.pending, (state) => {
      state.isSubmiting = true;
    });
    builder.addCase(updateBankAccountRanges.rejected, (state) => {
      state.isSubmiting = false;
    });
    builder.addCase(updateBankAccount.pending, (state) => {
      state.isSubmiting = true;
      if (state.showBankName) {
        state.isConnecting = true;
      }
    });
    builder.addCase(updateBankAccount.rejected, (state) => {
      state.isSubmiting = false;
      state.isConnecting = false;
    });
    builder.addCase(updateBankAccount.fulfilled, (state, action) => {
      const showBankName = state.showBankName;

      if (showBankName) {
        state.showBankName = false;
        state.isConnecting = false;
        state.isSuccess = true;
      }

      if (action.payload) {
        state.bankAccount = action.payload.bankAccount;
        state.bankAccounts = action.payload.bankAccounts;
      }

      if (state.step === 2) {
        state.step = 3;
      } else if (!showBankName) {
        state.showBankName = true;
      }

      state.isSubmiting = false;
    });
    builder.addCase(connectBankAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.bankAccounts = action.payload;

        const { bankAccount } = current(state);

        if (bankAccount) {
          const updatedBankAccount = action.payload.find(
            (bank: BankAccount) =>
              bank.bankAccountsId === bankAccount.bankAccountsId,
          );
          state.bankAccount = updatedBankAccount;
        }
      }
    });

    builder.addCase(disconnectBankAccount.pending, (state) => {
      state.isRefreshing = true;
    });
    builder.addCase(disconnectBankAccount.rejected, (state) => {
      state.isRefreshing = false;
    });
    builder.addCase(disconnectBankAccount.fulfilled, (state, action) => {
      state.isRefreshing = false;

      if (action.payload) {
        state.bankAccounts = action.payload;
        state.bankAccount = null;
        state.integrator = null;
        state.fields = [];
        state.directoryId = 0;
      }
    });

    builder.addCase(saveVendorCredentials.pending, (state) => {
      state.isSubmiting = true;
    });
    builder.addCase(saveVendorCredentials.rejected, (state) => {
      state.isSubmiting = false;
    });
    builder.addCase(saveVendorCredentials.fulfilled, (state, action) => {
      if (action.payload) {
        state.vendorCredentials = {
          ...state.vendorCredentials,
          ...action.payload,
        };
      }
      state.isSubmiting = false;
    });

    builder.addCase(removeBankAccountFile.fulfilled, (state) => {
      if (state.bankAccount) {
        state.bankAccount = {
          ...state.bankAccount,
          treasuryBlobFileExtension: '',
          treasuryBlobFileName: '',
          treasuryBlobFileSize: '',
          treasuryBlobdocumentId: '',
        };
      }
    });
  },
});

export const {
  setIntegrator,
  setShowConnectionForm,
  setSelectedConnection,
  selectConnection,
  setStep,
  setBankAccountFields,
  setIsSuccess,
  setIsFormUpdate,
  resetBankAccountInfoModal,
  resetBankAccountModal,
  resetFundingAccountModal,
  setShowBankName,
  showAlert,
  hideAlert,
  viewBankAccount,
  setIsBankAccountInfoOpen,
  setIsBankAccountOpen,
  setIsFundingAccountOpen,
  setBackToBankAccount,
} = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
