import { uniq } from 'ramda';

export const getFileSize = (size: number) => {
  const fSExt = ['bytes', 'kb', 'mb', 'gb'];

  let i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }

  return Math.round(size * 100) / 100 + ' ' + fSExt[i];
};

export const getUploadOptions = (callback) => ({
  onUploadProgress: (progressEvent: ProgressEvent) => {
    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);

    if (percent < 100) {
      callback(percent);
    } else {
      callback(99);
    }
  },
});

export const getImageDimensions = (file) =>
  new Promise((resolve) => {
    const fr = new FileReader();

    fr.onload = () => {
      const img = new Image();

      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };

      if (typeof fr.result === 'string') {
        img.src = fr.result;
      }
    };

    fr.readAsDataURL(file);
  });

export const validateFiles = async (files, rules) => {
  const errors: string[] = [];

  const maxSizeInBytes = (rules.maxSize || 0) * 1024 * 1024;

  for (let i = 0; i < files.length; i++) {
    if (maxSizeInBytes && files[i].size > maxSizeInBytes) {
      errors.push('maxSize');
    }

    if (rules.types && !rules.types.includes(files[i].type)) {
      errors.push('types');
    }

    if (!errors.length && rules.imageSize) {
      const [width, height] = rules.imageSize.split('x');

      const dimensions: any = await getImageDimensions(files[i]);

      if (
        (width && dimensions.width < width) ||
        (height && dimensions.height < height)
      ) {
        errors.push(
          width && height ? 'imageSize' : width ? 'imageWidth' : 'imageHeight',
        );
      }
    }
  }

  return uniq(errors);
};
