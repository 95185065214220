import moment from 'moment';

export const EMAIL_REGEX = /^([\w.\-]+)@([\w\-]+)((\.\w{2,3})+)$/;

export const getResponseErrorMessages = (response, firstError = false) => {
  const messages =
    response?.data?.errorMessages || response?.data?.ErrorMessages || [];
  return firstError ? (messages.length > 0 ? messages[0] : '') : messages;
};

export const isEmailValid = (email) => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email || '',
  );
};

export const blobToBase64 = (
  blob: Blob,
  callback: (base64: string) => void,
) => {
  const reader = new FileReader();

  reader.onload = function (event) {
    if (event.target) {
      const result = event.target.result as string;
      const base64String = result.split(',')[1];
      callback(base64String);
    }
  };

  reader.readAsDataURL(blob);
};

export const isTranscardUser = (email: string) => {
  return email && email.includes('@transcard.com') ? true : false;
};

export const getPersonLetters = (name: string) => {
  if (name) {
    const parts = name.split(' ');
    return parts[0][0] + (parts[1]?.[0] || parts[0][1] || '');
  }

  return '';
};

export const trimStr = (value) => {
  return typeof value === 'string' ? value.trim() : value;
};

export const round = (value, number = 2, int = true) => {
  if (typeof value !== 'number') {
    return value;
  }

  return int && Number.isInteger(value) ? value : value.toFixed(number);
};

export const isSubset = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
) => {
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (!obj2.hasOwnProperty(key) || obj2[key] !== obj1[key]) {
        return false;
      }
    }
  }

  return true;
};

export const getEnumKeyByValue = (obj: object, value: string) =>
  Object.keys(obj)[Object.values(obj).indexOf(value)];

export const enumHasValue = (obj: object, value: string) =>
  Object.values(obj).includes(value);

export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const lowercaseKeys = (arr) => {
  return arr.map((object) => {
    const result = {};

    for (const [key, value] of Object.entries(object)) {
      result[key[0].toLowerCase() + key.substring(1)] = value;
    }

    return result;
  });
};

export const parseJson = (json: any) => {
  try {
    const result = JSON.parse(json);

    if (result && typeof result === 'object') {
      return result;
    }
  } catch (e) {}

  return null;
};

const mapFormData = (formData, data, parentKey, useQuotes) => {
  if (data == null) {
    formData.append(parentKey, '');
    return;
  }

  if (data instanceof Date) {
    formData.append(parentKey, moment(data).format('YYYY-MM-DD'));
    return;
  }

  if (useQuotes && typeof data === 'string' && data.trim() === '') {
    formData.append(parentKey, '""');
    return;
  }

  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      mapFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
        useQuotes,
      );
    });
    return;
  }

  formData.append(parentKey, trimStr(data));
};

export const buildFormData = (data, useQuotes = false) => {
  const formData = new FormData();

  mapFormData(formData, data, '', useQuotes);

  return formData;
};
