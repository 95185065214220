import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddUserGray,
  PlusIcon,
  Others,
  TvIcon,
  PrintAdsIcon,
  SocialMediaIcon,
  PhoneIcon,
  MailIcon,
} from 'components/icons';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { BusinessPlan } from 'common/businessPlan';
import { useAppDispatch, useAppSelector } from 'hooks';
import { UploadFile, File } from 'components/uploadFile/index';
import { AcceptFormat } from 'common/files';

import HorizontalPipe from './HorizontalPipe';
import CheckboxWithIcon from './CheckboxWithIcon';
import { Tablet, TabletText } from './Tablet';
import TypesModalForm from './TypesModalForm';
import { setBusinessPlan } from '../businessPlanSlice';
import { getBusinessPlanItems } from '../selectors';
import {
  uploadLogo,
  findBusinessLogo,
  getFile,
  updateBusinessLogo,
  deleteBusinessLogo,
} from '../thunks';
import {
  getprogressPercent,
  getlogoFiles,
  getIsLogoUpload,
} from '../selectors';
import { setProgressPrecent } from '../businessPlanSlice';

interface AboutMarketingProps {
  step: number;
}

const AboutMarketing: React.FC<AboutMarketingProps> = ({ step }) => {
  const dispatch = useAppDispatch();
  const {
    emailAds,
    onlineAds,
    socialMediaAds,
    printedAds,
    phoneCalls,
    othersAds,
    marketingJSON,
  } = useAppSelector(getBusinessPlanItems);
  const isLoading = useAppSelector(getIsLogoUpload);
  const uploadFiles = useAppSelector(getlogoFiles);
  const progressPercent = useAppSelector(getprogressPercent);

  const [isOpenOwenerModal, setOwnerModalState] = useState(false);

  const [updateValues, setValues] = useState<any>(null);
  const [updatedIndex, setIndex] = useState<null | number>(null);

  const { t } = useTranslation('dashboard');

  const handleState = (key: string, value: boolean) => {
    dispatch(setBusinessPlan({ key, value }));
  };

  useEffect(() => {
    if (step === 5) {
      dispatch(findBusinessLogo());
    }
  }, [dispatch, step]);

  const handleOwnerModal = () => {
    if (isOpenOwenerModal) {
      setValues(null);
      setIndex(null);
    }

    setOwnerModalState(!isOpenOwenerModal);
  };

  const handleEditOwner = (value, index) => {
    setValues(value);
    setIndex(index);
    handleOwnerModal();
  };

  const dispatchNewOwner = (list) => {
    dispatch(
      setBusinessPlan({
        key: BusinessPlan.marketingJSON,
        value: list,
      }),
    );
  };

  const handleProgress = (value: number) => {
    dispatch(setProgressPrecent(value));
  };

  const handleOwnerDelete = (deleteByIndex) => {
    const filter = marketingJSON.filter(
      (item, index) => index !== deleteByIndex,
    );
    dispatchNewOwner(filter);
  };

  const addNewOwner = (value) => {
    handleOwnerModal();

    if (typeof updatedIndex === 'number') {
      const newArr = marketingJSON.map((item, index) => {
        if (index !== updatedIndex) {
          return item;
        }
      });

      newArr[updatedIndex] = value;
      dispatchNewOwner(newArr);

      setIndex(null);
      setValues(null);
      return;
    }

    dispatchNewOwner([...marketingJSON, value]);
  };

  const onDownload = (blobGUID, blobFileName) => {
    dispatch(getFile({ blobGUID, blobFileName }));
  };

  const handleUpload = (files) => {
    if (uploadFiles.length) {
      const businessesPlanBlobReferencesId =
        uploadFiles?.[0]?.businessesPlanBlobReferencesId;
      const blobGuid = uploadFiles?.[0]?.blobGuid;

      dispatch(
        updateBusinessLogo({ businessesPlanBlobReferencesId, files, blobGuid }),
      );
      return;
    }

    dispatch(uploadLogo(files));
  };

  const handleDeleteFile = (id) => {
    dispatch(deleteBusinessLogo(id));
  };

  if (step !== 5) {
    return null;
  }

  return (
    <div className="w-full">
      <h1 className="text-2xl leading-8 font-bold">
        {t('dashboard:businessPlan.modal.aboutMarketing')}
      </h1>
      <HorizontalPipe />
      <div>
        <div className="mt-9">
          <div className="mb-[10px]">
            <label className="text-sm leading-5 font-medium mb-[10px]">
              {t('dashboard:businessPlan.modal.whatMarketingTactics')}
            </label>
            <label className="text-sm leading-5 font-normal text-zinc-400">
              {t('dashboard:businessPlan.modal.seeAll')}
            </label>
          </div>
          <div className="flex items-center flex-wrap gap-x-12 gap-y-3">
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() => handleState(BusinessPlan.emailAds, !emailAds)}
                checked={emailAds}
                label={t('dashboard:businessPlan.modal.email')}
                icon={<MailIcon className="text-gray-500" />}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() => handleState(BusinessPlan.onlineAds, !onlineAds)}
                checked={onlineAds}
                label={t('dashboard:businessPlan.modal.onlineAds')}
                icon={<TvIcon />}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.socialMediaAds, !socialMediaAds)
                }
                checked={socialMediaAds}
                icon={<SocialMediaIcon />}
                label={t('dashboard:businessPlan.modal.socialMedia')}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.printedAds, !printedAds)
                }
                checked={printedAds}
                icon={<PrintAdsIcon />}
                label={t('dashboard:businessPlan.modal.printAds')}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() =>
                  handleState(BusinessPlan.phoneCalls, !phoneCalls)
                }
                checked={phoneCalls}
                icon={<PhoneIcon className="text-gray-500" />}
                label={t('dashboard:businessPlan.modal.phoneCall')}
              />
            </div>
            <div className="flex flex-1 w-full">
              <CheckboxWithIcon
                onChange={() => handleState(BusinessPlan.othersAds, !othersAds)}
                checked={othersAds}
                icon={<Others />}
                label={t('dashboard:businessPlan.modal.others')}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-9">
          <div className="mb-[10px]">
            <label className="text-sm leading-5 font-medium mb-[10px]">
              {t('dashboard:businessPlan.modal.wahtMarketingUse')}
            </label>
          </div>

          <div className="flex gap-x-4 flex-wrap gap-y-4">
            {marketingJSON.map(({ type }, index) => (
              <div key={index}>
                <Tablet
                  onEdit={() => handleEditOwner({ type }, index)}
                  onDelete={() => handleOwnerDelete(index)}
                >
                  <TabletText>{type}</TabletText>
                </Tablet>
              </div>
            ))}
            <Button variant="link" size="medium" onClick={handleOwnerModal}>
              <PlusIcon className="mr-2" />
              {t('dashboard:businessPlan.buttons.addAnotherType')}
            </Button>
          </div>
        </div>
        <div className="bg-gray-50 p-9 rounded-10 mt-5 max-w-[800px]">
          <UploadFile
            acceptFormat={[
              AcceptFormat.jpeg,
              AcceptFormat.jpg,
              AcceptFormat.png,
            ]}
            isLoading={isLoading}
            title={t('dashboard:businessPlan.fileUploadTitle')}
            text={t('dashboard:businessPlan.fileUploadText')}
            fileUploadText={t('dashboard:businessPlan.uploadText')}
            onUpload={handleUpload}
            uploadFiles={uploadFiles}
            handleProgress={handleProgress}
            progressPercent={progressPercent}
            optional
          >
            {({ handleSelect }) =>
              uploadFiles.map((file) => {
                return (
                  <div key={file.businessesPlanBlobReferencesId}>
                    <File
                      file={{
                        ...file,
                        description: t('dashboard:businessPlan.planLogoTitle'),
                      }}
                      onDelete={() =>
                        handleDeleteFile(file?.businessesPlanBlobReferencesId)
                      }
                      onDownload={(name) => onDownload(file.blobGuid, name)}
                      handleSelect={handleSelect}
                    />
                  </div>
                );
              })
            }
          </UploadFile>
        </div>
      </div>

      <Modal
        isOpen={isOpenOwenerModal}
        closeModal={handleOwnerModal}
        maxWidth="50rem"
        title={t(
          `dashboard:businessPlan.buttons.${
            updatedIndex !== null ? 'edit' : 'add'
          }AnotherType`,
        )}
        icon={<AddUserGray />}
        content={{
          maxWidth: '449px',
        }}
        closeTimeoutMS={0}
      >
        <TypesModalForm
          onClose={handleOwnerModal}
          onSave={addNewOwner}
          values={updateValues}
        />
      </Modal>
    </div>
  );
};

export default AboutMarketing;
