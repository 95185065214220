export const PictureIcon = ({ className }: { className?: string }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_10908_40235)">
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 26.5695L11.4545 11.2969L22.9089 26.5695H0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7275 26.5685L20.3639 16.3867L28.0002 26.5685H12.7275Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        d="M25.4546 10.0245C26.8604 10.0245 28.0001 8.88484 28.0001 7.47903C28.0001 6.07322 26.8604 4.93359 25.4546 4.93359C24.0488 4.93359 22.9092 6.07322 22.9092 7.47903C22.9092 8.88484 24.0488 10.0245 25.4546 10.0245Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_10908_40235">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
