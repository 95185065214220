export const TvIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333374 2C0.333374 0.895431 1.2288 0 2.33337 0H12.3334C13.4379 0 14.3334 0.895431 14.3334 2V10C14.3334 11.1046 13.4379 12 12.3334 12H10.1142L10.2364 12.4887L11.0405 13.2929C11.3265 13.5789 11.4121 14.009 11.2573 14.3827C11.1025 14.7563 10.7379 15 10.3334 15H4.3334C3.92894 15 3.5643 14.7563 3.40952 14.3827C3.25474 14.009 3.3403 13.5789 3.6263 13.2929L4.43044 12.4887L4.55262 12H2.33337C1.2288 12 0.333374 11.1046 0.333374 10V2ZM6.10467 9C6.08995 8.99967 6.07526 8.99967 6.06063 9H2.33337V2H12.3334V9H8.60618C8.59154 8.99967 8.57686 8.99967 8.56214 9H6.10467Z"
      fill="#6B7280"
    />
  </svg>
);
