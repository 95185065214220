import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import InfoDialog from 'components/dialog/templates/InfoDialog';
import { MailIcon, PhoneIcon } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Input, PhoneInput } from 'components/Inputs';
import HideComponent from 'components/HideComponent';
import icon from 'assets/icons/addCircle.svg';
import { isEmailValid } from 'utils/helpers';
import Modal from 'components/Modal';

import { createFundingAccount, saveFundingAccount } from '../thunks';
import { getApplicant } from '../../BusinessInformation/selectors';
import {
  getFundingAccount,
  getIsFundingAccountOpen,
  getBackToBankAccount,
  getBankAccountIsLoading,
} from '../selectors';
import {
  setIsFundingAccountOpen,
  resetFundingAccountModal,
  setIsBankAccountOpen,
  viewBankAccount,
} from '../bankAccountsSlice';
import { fetchBankAccounts } from '../thunks';
import Loading from 'components/Loading';

const defaultValues = {
  email: '',
  phone: '',
  note: '',
};

const FundingAccountModal = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const [isPrimarySelected, setIsPrimarySelected] = useState<boolean>(false);
  const [showNewAccount, setShowNewAccount] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const backToBankAccount = useAppSelector(getBackToBankAccount);
  const isOpen = useAppSelector(getIsFundingAccountOpen);
  const account = useAppSelector(getFundingAccount);
  const applicant = useAppSelector(getApplicant);
  const isModalLoading = useAppSelector(getBankAccountIsLoading);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    return () => {
      if (isOpen) {
        dispatch(resetFundingAccountModal());
        setIsPrimarySelected(false);
        setShowNewAccount(false);
        reset(defaultValues);
      }
    };
  }, [dispatch, isOpen, reset]);

  const handleRefresh = () => {
    dispatch(fetchBankAccounts());
  };

  const [note, phone, email] = watch(['note', 'phone', 'email']);

  const isUpdated =
    !account ||
    account.note !== note ||
    `${account.countryCallingCode}${account.telephoneNumber}`.replace(
      '-',
      '',
    ) !== phone.replace('-', '') ||
    account.emailAddress !== email;

  useEffect(() => {
    if (note.length > 1000) {
      setValue('note', note.slice(0, 1000));
    }
  }, [note, setValue]);

  useEffect(() => {
    if (account) {
      reset({
        email: account.emailAddress,
        phone: `${account.countryCallingCode || ''}${
          account.telephoneNumber || ''
        }`,
        note: account.note || '',
      });
      setShowNewAccount(true);
    }
  }, [account, reset]);

  const closeModal = () => {
    dispatch(setIsFundingAccountOpen(false));
  };

  const closeInfoDialog = () => {
    dispatch(closeDialog());
  };

  const handleSelectPrimary = () => {
    setIsPrimarySelected(true);
  };

  const handleBack = () => {
    if (showNewAccount) {
      setShowNewAccount(false);
    } else {
      if (backToBankAccount) {
        dispatch(viewBankAccount(backToBankAccount));
      } else {
        dispatch(setIsFundingAccountOpen(false));
        dispatch(setIsBankAccountOpen(true));
      }
    }
  };

  const handleRequestReceived = () => {
    closeModal();
    dispatch(
      openDialog({
        maxWidth: '512px',
        content: (
          <InfoDialog
            title={t('funding.requestReceivedTitle')}
            text={t('funding.requestReceivedText')}
            onClose={() => closeInfoDialog()}
            onConfirm={() => closeInfoDialog()}
          />
        ),
      }),
    );
  };

  const handleSendRequest = async () => {
    if (isPrimarySelected) {
      setIsLoading(true);

      const result = await dispatch(
        createFundingAccount({
          email: applicant.applicantEmail,
          phone: applicant.applicantPhone,
          note: '',
        }),
      );

      setIsLoading(false);

      if (!result.error) {
        handleRequestReceived();
      }
    }
  };

  const handleNewContact = () => {
    setIsPrimarySelected(false);
    setShowNewAccount(true);
  };

  const onSubmit = async (data) => {
    let result: any = null;

    setIsLoading(true);

    if (account?.id) {
      result = await dispatch(
        saveFundingAccount({
          referenceId: account.referenceId,
          id: account.id,
          ...data,
        }),
      );
    } else {
      result = await dispatch(createFundingAccount(data));
    }

    setIsLoading(false);

    if (result && !result.error) {
      handleRefresh();
      handleRequestReceived();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="32rem"
      actions={
        <div className="flex items-center justify-between gap-6">
          <HideComponent show={account === null}>
            <Button
              type="button"
              className="w-1/2"
              heightClass="h-11"
              variant={ButtonVariant.secondaryOutline}
              onClick={handleBack}
            >
              {t('common:back')}
            </Button>
          </HideComponent>
          <Button
            type="submit"
            className="w-1/2 ml-auto mr-0"
            heightClass="h-11"
            disabled={
              showNewAccount ? !isValid || !isUpdated : !isPrimarySelected
            }
            onClick={handleSendRequest}
            form="fundingForm"
            loading={isLoading}
          >
            {t(account ? 'common:save' : 'funding.sendRequest')}
          </Button>
        </div>
      }
      closeTimeoutMS={0}
    >
      <div className="px-6 pb-8 pt-14">
        <Loading loading={isModalLoading} />
        <img
          alt={t(`funding.title${account ? 'Edit' : ''}`)}
          src={icon}
          className="mx-auto w-12 h-12 mb-8"
        />
        <p className="text-lg font-semibold mb-2 text-center">
          {t(`funding.title${account ? 'Edit' : ''}`)}
        </p>
        <p className="text-center mb-6 text-secondary">
          {t('funding.description')}
        </p>
        {showNewAccount ? (
          <form id="fundingForm" onSubmit={handleSubmit(onSubmit)}>
            <Input
              name="email"
              label={t('funding.email.label')}
              placeholder={t('funding.email.placeholder')}
              register={register}
              className="mb-6"
              error={errors?.email}
              validation={{
                required: t('funding.email.validation.required'),
                validate: (value: string) =>
                  isEmailValid(value) || t('funding.email.validation.valid'),
              }}
            />
            <PhoneInput
              name="phone"
              label={t('funding.phone.label')}
              placeholder={t('funding.phone.placeholder')}
              control={control}
              className="mb-6"
              selectCountry={false}
              error={errors?.phone}
              validation={{
                required: t('funding.phone.validation.required'),
                validate: (value: string) =>
                  isPossiblePhoneNumber(value || '') ||
                  t('funding.phone.validation.valid'),
              }}
            />
            <Input
              type="textarea"
              name="note"
              label={t('funding.note.label')}
              register={register}
              rows={4}
              optional
            />
            <p className="text-secondary mt-1">{t('funding.note.info')}</p>
          </form>
        ) : (
          <>
            <p className="text-sm font-medium mb-1">
              {t('funding.primaryContact')}
            </p>
            <button
              type="button"
              className={`w-full px-6 py-4 border rounded-lg mb-3 shadow-sm cursor-pointer hover:bg-grey-50 ${
                isPrimarySelected ? 'border-blue-500' : 'border-gray-300'
              }`}
              onClick={handleSelectPrimary}
            >
              <p className="font-medium mb-1 text-left">
                {applicant.applicantName}
              </p>
              <div className="flex items-center gap-4">
                <div className="flex items-center">
                  <MailIcon className="mr-1.5 text-blue-600" />
                  <span className="text-secondary">
                    {applicant.applicantEmail}
                  </span>
                </div>
                <div className="flex items-center">
                  <PhoneIcon className="mr-1.5 text-blue-600" />
                  <span className="text-secondary">
                    {applicant.applicantPhone}
                  </span>
                </div>
              </div>
            </button>
            <div className="flex justify-center">
              <Button
                variant={ButtonVariant.secondaryOutline}
                size={ButtonSize.medium}
                heightClass="h-8"
                onClick={handleNewContact}
              >
                <span className="font-semibold">
                  {t('funding.otherContact')}
                </span>
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FundingAccountModal;
