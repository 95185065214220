import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import loginReducer, { signOut } from 'pages/login/loginSlice';
import profileReducer from 'pages/Profile/profileSlice';
import dashboardReducer from 'pages/dashboard/dashboardSlice';
import financialReducer from 'pages/dashboard/components/Financial/financialSlice';
import progressReducer from 'pages/dashboard/components/Progress/progressSlice';
import transactionalReducer from 'pages/dashboard/components/Transactional/transactionalSlice';
import businessInfoReducer from 'pages/dashboard/components/BusinessInformation/businessInfoSlice';
import ownershipReducer from 'pages/dashboard/components/Ownership/ownershipSlice';
import bankAccountsReducer from 'pages/dashboard/components/BankAccounts/bankAccountsSlice';
import invitePersonsReducer from 'components/InvitePersons/invitePersonsSlice';
import submitDashboardReducer from 'components/submitDashboard/submitDashboardSlice';
import accountingReducer from 'pages/dashboard/components/AccountingSoftware/accountingSlice';
import businessPlanReducer from 'pages/dashboard/components/BusinessPlan/businessPlanSlice';
import merchantReducer from 'pages/dashboard/components/MerchantServices/merchantSlice';
import complianceReducer from 'components/compliance/complianceSlice';
import modalReducer from 'components/dialog/modalSlice';
import themeReducer from 'theme/themeSlice';
import configurationReducer from 'pages/dashboard/components/Configuration/configurationSlice';
import fxProviderReducer from 'pages/dashboard/components/FXProvider/fxProviderSlice';
import notificationReducer from 'components/notification/notificationSlice';

const appReducer = combineReducers({
  login: loginReducer,
  invitePersons: invitePersonsReducer,
  submitDashboard: submitDashboardReducer,
  bankAccounts: bankAccountsReducer,
  profile: profileReducer,
  ownership: ownershipReducer,
  businessInfo: businessInfoReducer,
  progress: progressReducer,
  transactional: transactionalReducer,
  financial: financialReducer,
  dashboard: dashboardReducer,
  accounting: accountingReducer,
  businessPlan: businessPlanReducer,
  compliance: complianceReducer,
  modal: modalReducer,
  theme: themeReducer,
  configuration: configurationReducer,
  merchant: merchantReducer,
  fxProvider: fxProviderReducer,
  notification: notificationReducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === signOut.type) {
    return appReducer(undefined, { type: undefined });
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
