import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import ConnectedAccountFooter from './ConnectedAccountFooter';
import Account from './Account';

interface ConnectedProps {
  onUpdate: (value?: any) => void;
  accounts: any;
  image: React.ReactNode;
  isLoading?: boolean;
}

const Connected: React.FC<ConnectedProps> = ({
  onUpdate,
  accounts,
  image,
  isLoading,
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="mx-3 w-full">
      <p className="font-semibold text-gray-900 mb-2">
        {t('dashboard:connectors.accounting.title')}
      </p>
      <div className="flex flex-col justify-between w-full bg-gray-50 rounded-10 p-4 min-w-[342px] relative">
        <div className="overflow-y-auto">
          {isLoading && <Loading zIndex={10} transparent />}
          {accounts.map((account) => (
            <Account
              image={image}
              email={account.createdBy}
              onUpdate={() => onUpdate(account)}
              status={account.erpaccountStatus}
              key={account.erpaccountsId}
            />
          ))}
        </div>
        <ConnectedAccountFooter account={accounts[0]} onUpdate={onUpdate} />
      </div>
    </div>
  );
};

export default Connected;
