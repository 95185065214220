import { useTranslation } from 'react-i18next';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { ArrowRightUpIcon } from 'components/icons';
import { Logo } from 'components/Logos';

type PlatformCardProps = {
  platform: 'Enroll' | 'Hub';
  handleLink: () => void;
};

const PlatformCard: React.FC<PlatformCardProps> = ({
  platform,
  handleLink,
}) => {
  const { t } = useTranslation('business-list');

  return (
    <div className="w-full max-w-[380px] shadow rounded-2xl bg-white p-8 z-10">
      <Logo className="max-h-[72px] mb-8 mx-auto" />
      <p className="text-2xl mb-2">
        <span className="font-bold">SMART</span> {platform}
      </p>
      <p className="text-secondary mb-8">
        {t(`platform.${platform.toLowerCase()}-description`)}
      </p>
      <Button
        variant={ButtonVariant.link}
        size={ButtonSize.medium}
        className="w-full"
        paddingClass="p-0"
        heightClass="h-7"
        onClick={handleLink}
      >
        <p className="flex flex-1 items-center justify-between">
          <span>{t(`platform.${platform.toLowerCase()}-link`)}</span>
          <ArrowRightUpIcon className="text-blue-600" />
        </p>
      </Button>
    </div>
  );
};

export default PlatformCard;
