import { getDomain, getTenant } from 'theme/selectors';
import { DomainType } from 'utils/domainService';
import { useAppSelector } from 'hooks';

import SmartSuiteLogo from './SmartSuiteLogo';

interface LogoWithTextProps {
  className?: string;
  checkDefaultTenant?: boolean;
}

const LogoWithText: React.FC<LogoWithTextProps> = ({
  className,
  checkDefaultTenant = false,
}) => {
  const { logoSecondaryFileShareUri } = useAppSelector(getTenant);
  const domain = useAppSelector(getDomain);

  const logo = logoSecondaryFileShareUri || '';

  if (checkDefaultTenant && domain === DomainType.smartHub) {
    return <SmartSuiteLogo full className={className} />;
  }

  if (!logo) {
    return null;
  }

  return <img src={logo} className={className} alt="Logo" />;
};

export default LogoWithText;
