import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import { UserAddIcon } from 'components/icons';
import {
  setInviteIsOpen,
  setInvitedAndWasInviteState,
} from 'components/InvitePersons/invitePersonsSlice';

import BankingModalStepper from './BankingModalStepper';

const BankingModalTitle = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleInvite = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(true));
  };

  return (
    <div className="flex items-center justify-between">
      <div>{t('modalTitle')}</div>
      <div className="flex items-center">
        <HideComponent show={invitePermissions.view}>
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <div className="border-r border-gray-200 mr-6">
              <Button
                variant={ButtonVariant.link}
                paddingClass="px-4"
                heightClass="h-8"
                className="ml-3 mr-[14px]"
                size={ButtonSize.medium}
                onClick={handleInvite}
                disabled={!invitePermissions.edit}
              >
                {t('invite')}
                <UserAddIcon className="ml-2" />
              </Button>
            </div>
          </PermissionSection>
        </HideComponent>
        <BankingModalStepper />
      </div>
    </div>
  );
};

export default BankingModalTitle;
