import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { equals } from 'ramda';

import { useAppDispatch, useAppSelector, useCurrentUser } from 'hooks';
import { ChevronRightIcon, AngleLeftIcon } from 'components/icons';
import { Input, PhoneInput } from 'components/Inputs';
import { getOID, getUserEmail } from 'utils/authService';
import Button from 'components/Button';
import path from 'common/path';

import { getPersonInfo, getIsFormUpdate, getRedirectValue } from '../selectors';
import { getPersonInfoSchema, onboardingSteps } from '../utils';
import { setStep, setFormUpdateState } from '../profileSlice';
import { sendInvitation, updatePersonInfo } from '../thunks';

const PersonInformation = () => {
  const { t } = useTranslation('profile');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useCurrentUser();
  const isAuth = isAuthenticated || (getOID() && getUserEmail());

  const personInfo = useAppSelector(getPersonInfo);
  const isFormUpdate = useAppSelector(getIsFormUpdate);
  const redirect = useAppSelector(getRedirectValue);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: personInfo,
    resolver: yupResolver(getPersonInfoSchema(t)),
  });

  useEffect(() => {
    if (isFormUpdate) {
      reset(personInfo);
      dispatch(setFormUpdateState());
    }
  }, [personInfo, isFormUpdate, reset, dispatch]);

  useEffect(() => {
    if (redirect) {
      navigate(path.dashboard);
    }
  }, [redirect, navigate]);

  const handleUpdatePerson = async (data: any) => {
    if (isAuth && equals(personInfo, data)) {
      navigate(path.dashboard);
      return;
    }

    const { phone, ...fields } = data;

    const result = await dispatch(updatePersonInfo(isAuth ? data : fields));

    if (!result.error) {
      if (!isAuth) {
        const response = await dispatch(
          sendInvitation(t('profile:inviteMessage')),
        );

        if (!response.error) {
          navigate(path.invitation);
        }
        return;
      }

      if (!redirect) {
        navigate(path.dashboard);
        return;
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleUpdatePerson)}
      className="divide-y divide-gray-200 h-full flex flex-col justify-between flex-1"
    >
      <div>
        <h2 className="heading-2xl sm:heading-4xl mb-4">
          {t('profile:personInformation.title')}
        </h2>

        <h6 className="heading-lg mb-2">
          {t('profile:personInformation.subTitle')}
        </h6>
        <p className="text-secondary mb-5">
          {t('profile:personInformation.subText')}
        </p>
      </div>

      <div className="pt-4 flex-1">
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2 mb-4 sm:pr-3">
            <Input
              label={t('profile:personInformation.firstname.label')}
              name="givenName1"
              error={errors?.givenName1}
              register={register}
            />
          </div>

          <div className="w-full sm:w-1/2 mb-4 sm:pl-3">
            <Input
              label={t('profile:personInformation.lastname.label')}
              name="surnameFirst"
              error={errors?.surnameFirst}
              register={register}
            />
          </div>
        </div>

        <div className="w-full sm:w-1/2 mb-4 sm:pr-3">
          <Input
            label={t('profile:personInformation.role.label')}
            name="businessesPersonsRole"
            error={errors?.businessesPersonsRole}
            register={register}
          />
        </div>

        <div className="flex flex-wrap mb-8">
          <div className="w-full sm:w-1/2 mb-4 sm:pr-3">
            <Input
              label={t('profile:personInformation.email.label')}
              placeholder={t('profile:personInformation.email.placeholder')}
              name="emailAddress"
              register={register}
              readOnly
            />
          </div>

          <div className="w-full sm:w-1/2 mb-4 sm:pl-3">
            <PhoneInput
              label={t('profile:personInformation.phone.label')}
              placeholder={t('profile:personInformation.phone.placeholder')}
              name="phone"
              control={control}
              error={errors?.phone}
              readOnly={!isAuth}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap w-full sm:w-auto pt-6 justify-between">
        <Button
          type="button"
          className="w-full sm:max-w-[200px] mb-4 sm:mb-0"
          variant="secondary-outline"
          onClick={() => dispatch(setStep(onboardingSteps.dnb))}
          heightClass="h-11"
        >
          <AngleLeftIcon className="mr-3" />
          {t('common:back')}
        </Button>
        <Button
          type="submit"
          className="w-full sm:max-w-[200px] sm:ml-6"
          loading={isSubmitting}
          heightClass="h-11"
        >
          {t('common:nextStep')}
          <ChevronRightIcon className="ml-3" />
        </Button>
      </div>
    </form>
  );
};

export default PersonInformation;
