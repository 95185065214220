import api from 'api';

export interface TPASProductSchema {
  ProductId: number;
  ProductName: string;
  FundingPaymentAccountBalance: number;
  AutomaticallyTransferToCard: boolean;
  RequireCardOnPaymentAccountCreation: boolean;
  TransCardBinId: number;
  TransCardProductId: number;
  TransCardStoreName: string;
}

export const getProduct = (id: number) => {
  return api.get(`TPAS/Getproduct/${id}`);
};

export default {
  getProduct,
};
