import api from 'api';

type FilterType = {
  id?: string;
  name?: string;
};

export interface ProcessorSchema {
  id: string;
  name: string;
}

export interface SearchProcessorSchema {
  offset: number;
  limit: number;
  filters: FilterType;
}

export const searchProcessorsList = (data: Partial<SearchProcessorSchema>) => {
  return api.post('PrysymApi/SearchProcessorsList', data);
};

export const getProcessorByProcessorId = (processorId: string) => {
  return api.post(`PrysymApi/GetProcessorByProcessorId/${processorId}`);
};

export const prysymCreateMerchant = (businessId: number) => {
  return api.post(`PrysymApi/CreateMerchant/${businessId}`, {});
};

export default {
  searchProcessorsList,
  getProcessorByProcessorId,
  prysymCreateMerchant,
};
