import { createSelector } from '@reduxjs/toolkit';
import { sum } from 'ramda';

import { RootState } from 'state/store';

import { getMappedOwnerBlobReferences } from './utils';

export const getRoot = (state: RootState) => state.ownership;

export const getOwners = createSelector([getRoot], ({ owners }) => owners);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getLoadingProgress = createSelector(
  [getRoot],
  ({ loadingProgress }) => loadingProgress,
);

export const getBlobReferences = createSelector(
  [getRoot],
  ({ blobReferences }) => getMappedOwnerBlobReferences(blobReferences),
);

export const getTotalShare = createSelector([getRoot], ({ owners }) =>
  sum(owners.map((owner) => owner.ownershipPercentage || 0)),
);
