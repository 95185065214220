import { BankAccountStatusIds } from 'entities/bankAccounts';
import Badge from 'components/Badge';
import {
  ExclamationIcon,
  CheckCircleIcon,
  ProgressIcon,
  CloseCircleIcon,
} from 'components/icons';

interface AccountingStatusProps {
  status: string;
  statusId: number;
}

const statusIcons = {
  [BankAccountStatusIds.inProgress]: (
    <ProgressIcon className="w-[14px] h-[14px] text-blue-400" />
  ),
  [BankAccountStatusIds.actionRequired]: (
    <ExclamationIcon className="w-[14px] h-[14px] text-yellow-400" />
  ),
  [BankAccountStatusIds.connected]: (
    <CheckCircleIcon className="w-[14px] h-[14px] text-green-400" />
  ),
  [BankAccountStatusIds.connectionError]: (
    <CloseCircleIcon className="w-[14px] h-[14px] text-red-400" />
  ),
};

const statusColors = {
  [BankAccountStatusIds.inProgress]: 'blue',
  [BankAccountStatusIds.actionRequired]: 'yellow',
  [BankAccountStatusIds.connected]: 'green',
  [BankAccountStatusIds.connectionError]: 'red',
};

const BankAccountStatus: React.FC<AccountingStatusProps> = ({
  status,
  statusId,
}) => (
  <div className="flex">
    <Badge
      size="small"
      color={statusColors[statusId] || 'blue'}
      rounded="rounded-sm"
      className="pl-[3px]"
    >
      {statusIcons[statusId || BankAccountStatusIds.inProgress] || null}
      <span className="ml-[2px]">{status}</span>
    </Badge>
  </div>
);

export default BankAccountStatus;
