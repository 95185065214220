import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from 'react';

import LayoutSignUp from 'components/layouts/LayoutSignUp';
import PoweredBy from 'components/layouts/PoweredBy';
import { enumHasValue } from 'utils/helpers';
import Link from 'components/Link';
import { Languages } from 'config';
import path from 'common/path';

const Typography = ({ children, last = false }) => (
  <p
    className={`${
      last ? 'mb-6 sm:mb-12' : 'mb-3 sm:mb-6'
    } text-gray-700 text-sm sm:text-base`}
  >
    {children}
  </p>
);

const SignConsent = () => {
  const { t } = useTranslation('e-sign-consent');
  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && !enumHasValue(Languages, lang.toLowerCase())) {
      navigate(`${path.eSign}/${Languages.EN.toUpperCase()}`);
    }
  }, [lang, navigate]);

  return (
    <LayoutSignUp fullWidth>
      <div className="max-w-[1096px] m-auto">
        <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-6 sm:mb-12">
          <span className="text-blue-600">Transcard</span> {t('title')}
        </h2>

        <div className="mb-12">
          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="info"
              components={{ 1: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-1"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-2"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-3"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-4"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-5"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-6"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-7"
              components={{
                1: <b className="mr-4" />,
                2: <b />,
                3: (
                  <Link
                    href="mailto:support@transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-8"
              components={{
                1: <b className="mr-4" />,
                2: <b />,
                3: (
                  <Link
                    href="mailto:support@transcard.com"
                    className="font-medium"
                  />
                ),
              }}
            />
          </Typography>

          <Typography last>
            <Trans
              ns="e-sign-consent"
              i18nKey="text-9"
              components={{ 1: <b className="mr-4" />, 2: <b /> }}
            />
          </Typography>
        </div>
        <PoweredBy />
      </div>
    </LayoutSignUp>
  );
};

export default SignConsent;
