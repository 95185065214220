export const DownloadWhIteIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M3.33337 13.3327L3.33337 14.166C3.33337 15.5467 4.45266 16.666 5.83337 16.666L14.1667 16.666C15.5474 16.666 16.6667 15.5467 16.6667 14.166L16.6667 13.3327M13.3334 9.99935L10 13.3327M10 13.3327L6.66671 9.99935M10 13.3327L10 3.33268"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
