import { useTranslation } from 'react-i18next';

import { PaymentProcessorIcon, ExclamationIcon } from 'components/icons';
import PermissionSection from 'components/permission/PermissionSection';
import { openDialog, closeDialog } from 'components/dialog/modalSlice';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { ProcessorOption } from 'entities/merchantServices';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Permissions } from 'entities/dashboard';

import { changeBusinessMerchant } from '../thunks';
import { getCurrentProcessor } from '../selectors';

const CurrentOptionTitle = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const currentProcessor: ProcessorOption | null =
    useAppSelector(getCurrentProcessor);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const handleCancel = () => {
    dispatch(closeDialog());
  };

  const handleRemoveProcessor = () => {
    dispatch(changeBusinessMerchant(null));
    handleCancel();
  };

  const handleChangeProcessor = () => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        hideCross: false,
        content: (
          <div className="p-6 text-center">
            <div className="flex justify-center pt-8">
              <ExclamationIcon className="w-[44px] h-[44px] text-yellow-600" />
            </div>
            <h6 className="text-lg mb-2 mt-6 font-semibold">
              {t('merchantServices.configuration.modal.title')}
            </h6>
            <p className="text-secondary mb-6">
              {t('merchantServices.configuration.modal.description')}
            </p>
            <Button className="mb-4 w-full" onClick={handleRemoveProcessor}>
              {t('common:change')}
            </Button>
            <Button
              className="w-full"
              variant={ButtonVariant.secondaryOutline}
              onClick={handleCancel}
            >
              {t('common:cancel')}
            </Button>
          </div>
        ),
      }),
    );
  };

  return (
    <div className="bg-gray-50 mb-6 rounded-lg p-4 flex items-center justify-between">
      <div className="flex">
        <PaymentProcessorIcon className="text-blue-600" />
        <div className="ml-4">
          <p className="text-secondary">
            {t('merchantServices.configuration.current')}
          </p>
          <p className="font-medium">{currentProcessor?.name}</p>
        </div>
      </div>
      <PermissionSection
        permission={Permissions.financialInfo}
        showPopover
        edit
      >
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.medium}
          onClick={handleChangeProcessor}
          disabled={!financialPermissions.edit}
        >
          {t('merchantServices.configuration.change')}
        </Button>
      </PermissionSection>
    </div>
  );
};

export default CurrentOptionTitle;
