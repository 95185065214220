import { useTranslation } from 'react-i18next';

import { AngleLeftIcon, AngleDoubleRightIcon } from 'components/icons';
import Button from 'components/Button';

interface ButtonProps {
  step: number | null;
  handlBackStep: () => void;
  handleNextStep: () => void;
  loading?: any;
}

const Buttons: React.FC<ButtonProps> = ({
  step,
  handlBackStep,
  handleNextStep,
  loading,
}) => {
  const { t } = useTranslation('dashboard');

  const Back = (
    <>
      <AngleLeftIcon className="mr-3" />
      {t('common:back')}
    </>
  );

  const Next = (
    <>
      {t('common:next')}
      <AngleDoubleRightIcon className="ml-3" />
    </>
  );

  const Done = <> {t('dashboard:businessPlan.buttons.done')}</>;
  const BackToDashBoard = (
    <>{t('dashboard:businessPlan.buttons.backToDashboard')}</>
  );
  const CreateBusinessPlan = (
    <>{t('dashboard:businessPlan.buttons.createBusinessPlan')}</>
  );

  const backButtonSteps = {
    1: Back,
    2: Back,
    3: Back,
    4: Back,
    5: Back,
    6: BackToDashBoard,
  };

  const nextButtonSteps = {
    1: Next,
    2: Next,
    3: Next,
    4: Next,
    5: CreateBusinessPlan,
    6: Done,
  };

  if (!step) {
    return null;
  }

  return (
    <div className="border-y-[1px] border-gray-200 mt-5 h-[90px] w-full flex items-center justify-between px-6">
      <Button
        type="button"
        className="w-1/2 sm:w-48"
        variant="secondary-outline"
        onClick={handlBackStep}
      >
        {backButtonSteps[step]}
      </Button>
      <Button
        type="button"
        className="sm:w-52"
        onClick={handleNextStep}
        loading={loading}
      >
        {nextButtonSteps[step]}
      </Button>
    </div>
  );
};

export default Buttons;
