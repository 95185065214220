import api from 'api';

export interface TandCLogSchema {
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  businessesId: number;
  personsId: number;
  tandCversion: string;
}

export interface TandCAcceptLogSchema extends TandCLogSchema {
  tandCacceptLogsId: number;
}

export interface TandCViewLogSchema extends TandCLogSchema {
  tandCviewLogsId: number;
}

export const addTandCacceptLogs = async (
  data: Partial<TandCAcceptLogSchema>,
) => {
  const id: number = await api.post('TandCacceptLogs/AddTandCacceptLogs', data);
  return id;
};

export const updateTandCacceptLogs = async (
  data: Partial<TandCAcceptLogSchema>,
) => {
  const id: number = await api.put(
    'TandCacceptLogs/UpdateTandCacceptLogs',
    data,
  );
  return id;
};

export const addTandCviewLogs = async (data: Partial<TandCViewLogSchema>) => {
  const id: number = await api.post('TandCviewLogs/AddTandCviewLogs', data);
  return id;
};

export default {
  addTandCacceptLogs,
  addTandCviewLogs,
};
