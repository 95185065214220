import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import PopoverOnHover from 'components/PopoverOnHover';
import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Input, Checkbox } from 'components/Inputs';
import { Permissions } from 'entities/dashboard';
import { isEmailValid } from 'utils/helpers';

import { setErrors, setGrandAccess } from '../invitePersonsSlice';
import { getGrandAccess, getErrors } from '../selectors';
import SelectPermissions from './SelectPermissions';
import Autocomplete from './Autocomplete';
import AccessModal from './AccessModal';

type Member = {
  avatar: string;
  label: string;
  value: string;
};

interface InviteFormProps {
  selected: Member | null;
  onChange: (item: Member) => void;
  register: any;
}

const InviteForm: React.FC<InviteFormProps> = ({
  selected,
  onChange,
  register,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const [isAccessModalOpened, setIsAccessModalOpened] =
    useState<boolean>(false);

  const grandAccess = useAppSelector(getGrandAccess);
  const errors = useAppSelector(getErrors);
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleChange = (item: Member) => {
    if (item && !isEmailValid(item.value)) {
      dispatch(
        setErrors({
          ...errors,
          InvitedUserEmailAddress: t('invitePersons.emailError'),
        }),
      );
    } else if (errors.InvitedUserEmailAddress) {
      const { InvitedUserEmailAddress, ...other } = errors;

      dispatch(setErrors(other));
    }

    onChange(item);
  };

  const handleCheckbox = (value: boolean) => {
    dispatch(setGrandAccess(value));

    if (value) {
      setIsAccessModalOpened(true);
    }
  };

  const handleCancelAccess = () => {
    dispatch(setGrandAccess(false));
    setIsAccessModalOpened(false);
  };

  return (
    <div className="p-6">
      <p className="heading-lg text-gray-700 mb-6">
        {t('invitePersons.inviteWithEmail')}
      </p>

      <div className="mb-4">
        <div className="flex items-center">
          <Autocomplete
            onChange={handleChange}
            placeholder={t('invitePersons.invitePlaceholder')}
            defaultValue={selected}
            className="flex-1 max-w-full"
            hasError={errors?.InvitedUserEmailAddress}
            disabled={!invitePermissions.edit}
            isClearable
          />
          <SelectPermissions />
        </div>
        {errors?.InvitedUserEmailAddress && (
          <p className="text-sm leading-4 mt-2 text-red-600">
            {errors.InvitedUserEmailAddress}
          </p>
        )}
        {errors?.permissionsRequired && (
          <p className="text-sm leading-4 mt-2 text-red-600 text-right">
            {errors.permissionsRequired}
          </p>
        )}
      </div>

      <HideComponent hide={true}>
        <div className="flex items-center mb-6 p-4">
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <label className="flex items-center cursor-pointer">
              <Checkbox
                checked={grandAccess}
                onChange={handleCheckbox}
                disabled={!invitePermissions.edit}
              />
              <span className="mx-2 text-sm text-gray-800">
                {t('invitePersons.checkbox')}
              </span>
            </label>
          </PermissionSection>
          <PopoverOnHover
            info={t('invitePersons.checkboxInfo')}
            infoClassName="z-10 w-full max-w-[344px] text-sm text-gray-500 bg-white p-4 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md"
          />
        </div>
      </HideComponent>

      <Input
        label={t('invitePersons.messageLabel')}
        className="pt-6"
        name="message"
        type="textarea"
        register={register}
        rows={3}
        optional
        readOnly={!invitePermissions.edit}
      />
      {isAccessModalOpened && (
        <AccessModal
          onCancel={handleCancelAccess}
          onConfirm={() => setIsAccessModalOpened(false)}
        />
      )}
    </div>
  );
};

export default InviteForm;
