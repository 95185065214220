import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

interface Option {
  value: number;
  name: string;
  disabled?: boolean;
}

interface RadioButtonsProps {
  options: Array<Option>;
  checked?: Option | null;
  onChange: (option: Option) => void;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'normal';
}

const classes = {
  checked: 'bg-blue-50 text-blue-900 border-blue-300 hover:border-blue-400',
  disabled: 'bg-gray-100 cursor-not-allowed',
  default:
    'bg-white cursor-pointer focus:outline-none border-gray-300 text-gray-900 hover:bg-gray-50 hover:border-gray-400',
};

const RadioButtons: React.FC<RadioButtonsProps> = ({
  options,
  checked = null,
  onChange,
  disabled,
  className = '',
  size = 'normal',
}) => {
  return (
    <RadioGroup value={checked} onChange={onChange}>
      <div
        className={classNames(className, 'flex items-center', {
          '-mx-1': size === 'small',
          '-mx-2': size === 'normal',
        })}
      >
        {options.map((option) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={(props) =>
              classNames(
                props.checked
                  ? classes.checked
                  : option.disabled || disabled
                  ? classes.disabled
                  : classes.default,
                'border rounded-lg flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
                {
                  'mx-1 py-1.5 px-4': size === 'small',
                  'mx-2 py-2 px-4.5': size === 'normal',
                },
              )
            }
            disabled={disabled || option.disabled}
          >
            <RadioGroup.Label as="p">{option.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioButtons;
