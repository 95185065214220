import { ReactNode } from 'react';

import TabInvite from 'components/InvitePersons/TabInvite';

type OnboardingTabHeaderProps = {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
};

const OnboardingTabHeader: React.FC<OnboardingTabHeaderProps> = ({
  title,
  subTitle,
}) => (
  <div className="border-b border-gray-200 pb-5 flex items-center justify-between">
    <div>
      <p className="text-2xl font-semibold">{title}</p>
      {subTitle && <p className="text-secondary mt-1">{subTitle}</p>}
    </div>
    <TabInvite />
  </div>
);

export default OnboardingTabHeader;
