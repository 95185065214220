import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import PopoverOnHover from 'components/PopoverOnHover';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

type PermissionSectionProps = {
  permission: Permissions | undefined;
  showMessage?: boolean;
  showPopover?: boolean;
  fullWidth?: boolean;
  edit?: boolean;
  children: ReactElement;
};

const PermissionSection: React.FC<PermissionSectionProps> = ({
  permission,
  showMessage = false,
  showPopover = false,
  fullWidth = false,
  edit = false,
  children,
}) => {
  const { t } = useTranslation('common');

  const permissions = useAppSelector((state) =>
    hasPermission(state, permission),
  );

  const show = edit ? permissions.edit : permissions.view;

  return show || !permission ? (
    children
  ) : showPopover ? (
    <PopoverOnHover
      info={
        <div>
          {permission === Permissions.invite
            ? t('permissions.invitePermissionRequired')
            : t('permissions.editPermissionRequired', {
                section: t(`permissions.${permission}`),
              })}
        </div>
      }
      button={children}
      className={fullWidth ? 'w-full' : ''}
      wrapperClassName="flex-shrink-0"
      infoClassName="z-20 w-64 text-xs font-medium text-gray-700 bg-white px-2 py-1 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md"
    />
  ) : showMessage ? (
    <p className="text-secondary">
      {t('permissions.viewPermissionRequired', {
        section: t(`permissions.${permission}`),
      })}
    </p>
  ) : null;
};

export default PermissionSection;
