import { createSelector } from '@reduxjs/toolkit';

import { prysymProcessor } from 'entities/merchantServices';
import { ProductTypeCodes } from 'entities/progress';
import { RootState } from 'state/store';

import { getActiveProductsTypeCodes } from '../Progress/selectors';

const getRoot = (state: RootState) => state.merchant;

export const getCurrentProcessor = createSelector(
  [getRoot],
  ({ currentProcessor }) => currentProcessor,
);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getUpdateForm = createSelector(
  [getRoot],
  ({ updateForm }) => updateForm,
);

export const getFields = createSelector([getRoot], ({ fields }) => fields);

export const getMerchant = createSelector(
  [getRoot],
  ({ merchant }) => merchant,
);

export const getIsNMIMerchant = createSelector(
  [getRoot, getActiveProductsTypeCodes],
  ({ merchant }, products) =>
    products.includes(ProductTypeCodes.services) &&
    merchant?.merchantsId &&
    merchant.processor_MerchantsId !== prysymProcessor.value,
);

export const getFormValues = createSelector(
  [getRoot],
  ({ formValues }) => formValues,
);
