import { useState, ReactNode, useEffect } from 'react';
import classNames from 'classnames';

import RadioButtons from 'components/RadioButtons';
import { Permissions } from 'entities/dashboard';
import Collapse from 'components/Collapse';

import PermissionSection from './permission/PermissionSection';

const options = [
  {
    value: 1,
    name: 'Yes',
  },
  {
    value: 0,
    name: 'No',
  },
];

interface YesNoCollapseProps {
  title: string;
  value: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onChange?: (val: number) => void;
  permission?: Permissions;
}

const YesNoCollapse: React.FC<YesNoCollapseProps> = ({
  title,
  value = false,
  disabled,
  children,
  className = '',
  onChange,
  permission,
}) => {
  const [option, setOption] = useState(options[1]);

  useEffect(() => {
    setTimeout(() => setOption(options[value ? 0 : 1]), 100);
  }, [value, setOption]);

  const handleChange = (opt) => {
    setOption(opt);

    if (onChange) {
      onChange(opt.value);
    }
  };

  return (
    <Collapse
      className={classNames(className, 'rounded-lg px-4 py-3 mt-5')}
      opened={option.value === 1}
      header={
        <div className="flex items-center justify-between">
          <p className="text-sm leading-5 font-medium">{title}</p>

          <PermissionSection permission={permission} showPopover edit>
            <RadioButtons
              options={options}
              checked={option}
              onChange={handleChange}
              disabled={disabled}
            />
          </PermissionSection>
        </div>
      }
    >
      {children}
    </Collapse>
  );
};

export default YesNoCollapse;
