export const FilesGray = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      opacity="0.3"
      d="M5.85714 2H13.7364C14.0911 2 14.4343 2.12568 14.7051 2.35474L19.4687 6.38394C19.8057 6.66895 20 7.08788 20 7.5292V20.0833C20 21.8739 19.9796 22 18.1429 22H5.85714C4.02045 22 4 21.8739 4 20.0833V3.91667C4 2.12612 4.02045 2 5.85714 2Z"
      fill="#6B7280"
    />
    <path
      d="M14 11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11Z"
      fill="#6B7280"
    />
    <path
      d="M10 15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H10C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15Z"
      fill="#6B7280"
    />
  </svg>
);
