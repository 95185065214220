import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getIsOpened, getModalData } from './selectors';
import { closeDialog } from './modalSlice';

const DialogModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpened = useAppSelector(getIsOpened);
  const {
    title,
    text,
    onClose,
    onCancel,
    onContinue,
    content,
    maxWidth,
    isOverlayDisabled,
    hideCross,
  } = useAppSelector(getModalData);

  const closeModal = () => {
    dispatch(closeDialog());
  };

  const handleClose = () => {
    closeModal();

    if (onClose) {
      onClose();
    }
  };

  const handleCancel = () => {
    closeModal();

    if (onCancel) {
      onCancel();
    }
  };

  const handleContinue = () => {
    closeModal();

    if (onContinue) {
      onContinue();
    }
  };

  return (
    <Modal
      isOpen={isOpened}
      closeModal={handleClose}
      maxWidth={maxWidth}
      closeTimeoutMS={0}
      hideCross={hideCross}
      zIndex={50}
      isOverlayDisabled={isOverlayDisabled}
    >
      {content || (
        <div className="p-6">
          {title && (
            <div className="text-lg font-medium text-gray-900 mb-2">
              {title}
            </div>
          )}
          {text && <div className="text-sm text-gray-500">{text}</div>}
          <div className="flex items-center justify-end gap-3 mt-6">
            {onCancel && (
              <Button
                variant="secondary-outline"
                size="medium-large"
                onClick={handleCancel}
              >
                {t('common:cancel')}
              </Button>
            )}
            {onContinue && (
              <Button size="medium-large" onClick={handleContinue}>
                {t('common:continue')}
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DialogModal;
