import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppSelector, useCurrentUser } from 'hooks';
import { Logo, LogoWithText } from 'components/Logos';
import PoweredBy from 'components/layouts/PoweredBy';
import HideComponent from 'components/HideComponent';
import { getOID } from 'utils/authService';
import path from 'common/path';

import SessionExpired from './components/SessionExpired';
import Background from './components/Background';
import LoginForm from './components/LoginForm';
import CodeForm from './components/CodeForm';
import { getIsCodeSent } from './selectors';

const Login = () => {
  useTranslation('login');
  const navigate = useNavigate();
  const { isAuthenticated } = useCurrentUser();

  const isCodeSent = useAppSelector(getIsCodeSent);

  const oid = getOID();

  useEffect(() => {
    if (isAuthenticated || oid) {
      navigate(path.businesses);
    }
  }, [oid, isAuthenticated, navigate]);

  return (
    <div className="h-screen overflow-y-auto flex">
      <Background />
      <div className="absolute top-0 left-0">
        <LogoWithText className="mt-12 ml-16 max-h-[72px]" checkDefaultTenant />
      </div>
      <div className="flex-1">
        <div className="flex flex-col min-h-screen items-center justify-center pt-16 pb-4 px-4">
          <div className="w-full max-w-[428px] dropdown-shadow-effect rounded-3xl bg-white z-10 mb-6">
            <div className="p-8 border-b border-gray-200">
              <div className="flex justify-center mb-6">
                <Logo className="max-h-[72px]" checkDefaultTenant />
              </div>
              <h2 className="text-2xl font-medium text-center">
                <Trans i18nKey="title" ns="login">
                  text<span className="font-bold">name</span>
                </Trans>
              </h2>
            </div>

            <HideComponent hide={isCodeSent}>
              <LoginForm />
            </HideComponent>

            <HideComponent show={isCodeSent}>
              <CodeForm />
            </HideComponent>
          </div>

          <PoweredBy />
        </div>
      </div>

      <SessionExpired />
    </div>
  );
};

export default Login;
