import { Ref, FormEvent, ReactNode, useState, useEffect } from 'react';
import { default as PNInput } from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import classNames from 'classnames';
import { CountryCode } from 'libphonenumber-js/types';
import {
  UseFormRegister,
  FieldValues,
  Controller,
  Control,
} from 'react-hook-form';
import { useAppSelector } from 'hooks';

import ShowButton from './components/ShowButton';
import ErrorMessage from './components/ErrorMessage';
import ErrorIcon from './components/ErrorIcon';
import InputAddOn from './components/InputAddOn';
import Label from './components/Label';
import { getInputCurrencies } from 'pages/dashboard/selectors';
import { CurrencyCode } from 'entities/dashboard';

interface InputProps {
  name: string;
  register?: UseFormRegister<FieldValues>;
  label?: string;
  value?: string;
  type?: 'text' | 'password' | 'textarea' | 'number';
  info?: string | ReactNode;
  description?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  error?: any;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  ref?: Ref<HTMLInputElement & HTMLTextAreaElement>;
  pattern?: RegExp;
  optional?: boolean;
  iconEnd?: ReactNode;
  iconStart?: ReactNode;
  suffix?: string | ReactNode;
  prefix?: string | ReactNode;
  prefixInline?: boolean;
  suffixInline?: boolean;
  suffixClass?: string;
  rows?: number;
  validation?: any;
  maxLength?: number;
  readOnly?: boolean;
  hideValue?: boolean;
}

const IconStart = ({ icon }) => (
  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
    {icon}
  </div>
);

const IconEnd = ({ icon, error }) => (
  <div
    className={classNames(
      'absolute inset-y-0 right-0 flex items-center pointer-events-none',
      error ? 'pr-9' : 'pr-3',
    )}
  >
    {icon}
  </div>
);

export const Input: React.FC<InputProps> = ({
  name,
  register,
  label,
  type = 'text',
  info,
  description,
  className,
  inputClassName,
  error,
  pattern,
  optional,
  iconEnd,
  iconStart,
  prefix,
  prefixInline = false,
  suffix,
  suffixInline = false,
  suffixClass,
  rows = 2,
  validation = {},
  hideValue,
  ...otherProps
}) => {
  const [showValue, setShowValue] = useState<boolean>(false);

  const toggleShowValue = () => {
    setShowValue(!showValue);
  };

  return (
    <div className={className}>
      {label && (
        <Label info={info} description={description} optional={optional}>
          {label}
        </Label>
      )}

      <div
        className={classNames('relative flex', {
          'rounded-md': prefix && !prefixInline,
        })}
      >
        {prefix && (
          <InputAddOn value={prefix} inline={prefixInline} type="prefix" />
        )}
        {iconStart && <IconStart icon={iconStart} />}

        {type === 'textarea' ? (
          <textarea
            name={name}
            {...(register && register(name, validation))}
            className={classNames(
              inputClassName,
              'w-full shadow-sm border border-gray-300 py-[7px] px-3 placeholder-gray-400',
              prefix && !prefixInline && 'focus:ring-1',
              (!prefix || prefixInline) && 'rounded-l-md',
              suffix && !suffixInline && 'focus:ring-1',
              (!suffix || suffixInline) && 'rounded-r-md',
              {
                'pl-10': iconStart,
                'pr-10': iconEnd,
              },
              error
                ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 placeholder-red-300'
                : 'border-gray-300 focus:ring-0 focus:border-indigo-500',
              otherProps.readOnly &&
                'bg-gray-100 cursor-default focus:border-gray-300',
            )}
            rows={rows}
            {...otherProps}
          />
        ) : (
          <input
            name={name}
            {...(register && register(name, validation))}
            type={type === 'password' && showValue ? 'text' : type}
            className={classNames(
              inputClassName,
              'w-full shadow-sm border border-gray-300 py-[7px] px-3 placeholder-gray-400',
              prefix && !prefixInline && 'focus:ring-1',
              (!prefix || prefixInline) && 'rounded-l-md',
              suffix && !suffixInline && 'focus:ring-1',
              (!suffix || suffixInline) && 'rounded-r-md',
              {
                'pl-10': iconStart,
                'pr-10': iconEnd || (type === 'password' && !hideValue),
              },
              error
                ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 placeholder-red-300'
                : 'border-gray-300 focus:ring-0',
              otherProps.readOnly &&
                'bg-gray-100 cursor-default focus:border-gray-300',
            )}
            {...otherProps}
          />
        )}

        {error && <ErrorIcon />}

        {iconEnd && <IconEnd icon={iconEnd} error={error} />}

        {!hideValue && type === 'password' && (
          <ShowButton
            error={error}
            toggleShowValue={toggleShowValue}
            showValue={showValue}
          />
        )}

        {suffix && (
          <InputAddOn
            value={suffix}
            inline={suffixInline}
            type="suffix"
            error={error}
            className={suffixClass}
          />
        )}
      </div>

      <ErrorMessage error={error} />
    </div>
  );
};

interface NumberInputProps extends Omit<InputProps, 'onChange' | 'type'> {
  control?: Control<any>;
  allowNegative?: boolean;
  type?: 'float' | 'int';
  handleChange?: (value: any) => void;
  maxLength?: number;
  password?: boolean;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  name,
  control,
  label,
  handleChange,
  info,
  description,
  className,
  inputClassName,
  error,
  pattern,
  optional,
  iconEnd,
  iconStart,
  prefix,
  suffix,
  prefixInline = false,
  suffixInline = false,
  allowNegative = true,
  hideValue = false,
  password = false,
  type = 'float',
  maxLength,
  ...otherProps
}) => {
  const [showValue, setShowValue] = useState<boolean>(false);

  const toggleShowValue = () => {
    setShowValue(!showValue);
  };

  const regex = new RegExp(
    `[^0-9${type === 'float' ? '.' : ''}${allowNegative ? '-' : ''}]`,
    'g',
  );

  const handleOnChange = (e, onChange) => {
    const { value } = e.target;

    if (!value || (value === '-' && allowNegative)) {
      onChange(value);
      if (handleChange) {
        handleChange(value);
      }
      return;
    }

    if (!isNaN(value)) {
      if (!maxLength || value.length <= maxLength) {
        const newValue = value.replace(regex, '');
        onChange(newValue);
        if (handleChange) {
          handleChange(newValue);
        }
      }
    }
  };

  return (
    <div className={className}>
      {label && (
        <Label info={info} description={description} optional={optional}>
          {label}
        </Label>
      )}

      <div
        className={classNames('relative flex', {
          'rounded-md': prefix && !prefixInline,
        })}
      >
        {prefix && (
          <InputAddOn value={prefix} inline={prefixInline} type="prefix" />
        )}

        {iconStart && <IconStart icon={iconStart} />}

        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            return (
              <input
                name={name}
                value={value === undefined ? '' : value}
                onBlur={onBlur}
                onChange={(e) => handleOnChange(e, onChange)}
                type={password && !showValue ? 'password' : 'text'}
                ref={ref}
                className={classNames(
                  inputClassName,
                  'w-full shadow-sm border border-gray-300 py-[7px] px-3 placeholder-gray-400',
                  prefix && !prefixInline && 'focus:ring-1',
                  (!prefix || prefixInline) && 'rounded-l-md',
                  suffix && !suffixInline && 'focus:ring-1',
                  (!suffix || suffixInline) && 'rounded-r-md',
                  {
                    'pl-10': iconStart,
                    'pr-10': iconEnd || (password && !hideValue),
                  },
                  error
                    ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900'
                    : 'border-gray-300 focus:ring-0 focus:border-indigo-500',
                  otherProps.readOnly &&
                    'bg-gray-100 cursor-default focus:border-gray-300',
                )}
                {...otherProps}
              />
            );
          }}
        />

        {error && <ErrorIcon />}

        {iconEnd && <IconEnd icon={iconEnd} error={error} />}

        {!hideValue && password && (
          <ShowButton
            error={error}
            toggleShowValue={toggleShowValue}
            showValue={showValue}
          />
        )}

        {suffix && (
          <InputAddOn
            value={suffix}
            inline={suffixInline}
            type="suffix"
            error={error}
          />
        )}
      </div>

      <ErrorMessage error={error} />
    </div>
  );
};

const phoneValidation = {
  validate: (val: string) =>
    isPossiblePhoneNumber(val || '') || 'Please enter a valid phone number.',
  required: 'The phone is required.',
};

interface PhoneInputProps extends Omit<InputProps, 'onChange'> {
  control?: Control;
  country?: CountryCode;
}

const countries = [
  {
    id: 1,
    code: 'US',
    placeholder: '+1 (555) 987-6543',
  },
];

export const PhoneInput = ({
  name,
  control,
  label,
  info,
  className,
  error,
  optional,
  country = 'US',
  ...otherProps
}: PhoneInputProps) => {
  const [code, setCode] = useState<CountryCode>(country);

  const handleChangeCountry = (e: FormEvent<HTMLSelectElement>) => {
    setCode(e.currentTarget.value as CountryCode);
  };

  return (
    <div className={className}>
      {label && (
        <Label info={info} optional={optional}>
          {label}
        </Label>
      )}

      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <select
            name="country"
            autoComplete="country"
            className="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 text-sm leading-5 rounded-md focus:ring-0 focus:border-indigo-500"
            value={code}
            onChange={handleChangeCountry}
          >
            {countries.map((item) => (
              <option value={item.code} key={item.id}>
                {item.code}
              </option>
            ))}
          </select>
        </div>

        <Controller
          name={name}
          control={control}
          rules={phoneValidation}
          render={({ field: { value, onChange } }) => (
            <PNInput
              value={value}
              onChange={onChange}
              defaultCountry={code}
              className={classNames(
                'rounded-md w-full shadow-sm border border-gray-300 py-[7px] pl-16 placeholder-gray-400',
                error
                  ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900'
                  : 'pr-3 border-gray-300 focus:ring-0 focus:border-indigo-500',
              )}
              placeholder={countries.find((c) => c.code === code)?.placeholder}
              {...otherProps}
            />
          )}
        />

        {error && <ErrorIcon />}
      </div>

      <ErrorMessage error={error} />
    </div>
  );
};

enum CurrencySymbols {
  USD = '$',
  EUR = '€',
}

interface CurrencyInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: number) => void;
  onCurrencyChange?: (currency: CurrencyCode) => void;
  currencyValue?: string;
}

export const CurrencyInput = ({
  label,
  name,
  info,
  className,
  error,
  pattern,
  optional,
  register,
  onChange,
  onCurrencyChange,
  currencyValue,
  prefix,
  suffix,
  ...otherProps
}: CurrencyInputProps) => {
  const currencyOptions: CurrencyCode[] = useAppSelector(getInputCurrencies);
  const [currency, setCurrency] = useState<string>(currencyValue || 'USD');

  useEffect(() => {
    if (currencyValue) {
      setCurrency(currencyValue);

      const selectedCurrency = currencyOptions.find(
        (cur: CurrencyCode) => cur.alphaCode === currencyValue,
      );

      if (onCurrencyChange && selectedCurrency) {
        onCurrencyChange(selectedCurrency);
      }
    }
  }, [currencyValue, currencyOptions, onCurrencyChange]);

  const handleSelect = (e: FormEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const selectedCurrency = currencyOptions.find(
      (cur: CurrencyCode) => cur.alphaCode === value,
    );

    if (onCurrencyChange && selectedCurrency) {
      onCurrencyChange(selectedCurrency);
    }

    setCurrency(value);
  };

  const handleChange = (e) => {
    if (onChange) {
      const { value } = e.target;

      if (!value) {
        onChange(value);
        return;
      }

      if (!isNaN(value)) {
        onChange(value.replace(/[^0-9.]/g, ''));
      }
    }
  };

  return (
    <div className={className}>
      {label && (
        <Label info={info} optional={optional}>
          {label}
        </Label>
      )}

      <div className="relative">
        <InputAddOn
          value={CurrencySymbols[currency] || ''}
          inline
          type="prefix"
        />

        <input
          name={name}
          type="text"
          onChange={handleChange}
          {...(register && register(name))}
          className={classNames(
            'rounded-md w-full shadow-sm border border-gray-300 py-[7px] pr-16 pl-8 placeholder-gray-400',
            error
              ? 'pr-9 border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900'
              : 'pr-3 border-gray-300 focus:ring-0 focus:border-indigo-500',
          )}
          {...otherProps}
        />

        <div className="absolute inset-y-0 right-0 flex items-center">
          <select
            name="currency"
            autoComplete="currency"
            className="h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 text-sm leading-5 rounded-md focus:ring-0 focus:border-indigo-500"
            onChange={handleSelect}
            value={currency}
          >
            {currencyOptions.map((cur: CurrencyCode) => (
              <option key={cur.alphaCode} value={cur.alphaCode}>
                {cur.alphaCode}
              </option>
            ))}
          </select>
        </div>

        {error && <ErrorIcon />}
      </div>

      <ErrorMessage error={error} />
    </div>
  );
};
