import { ERPProviders } from 'entities/accounting';
import api from 'api';

export interface ErpAccountSchema {
  erpaccountsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  businessesId: number;
  connectorId: number;
  erpaccountCreatedOn: string | null;
  erplogReferenceId: string | null;
  erpaccountReferenceId: number | null;
  json: string | null;
  jsone: string | null;
  tokenOid: string | null;
  erpaccountStatusesId: number;
}

export const addErpAccount = (payload: Partial<ErpAccountSchema>) => {
  return api.post('Erpaccounts/AddErpaccounts', payload);
};

export const getErpAccounts = (id: number) => {
  return api.get(`Erpaccounts/GetErpaccounts/${id}`);
};

export const updateErpAccount = (payload: Partial<ErpAccountSchema>) => {
  return api.put('Erpaccounts/UpdateErpaccounts', payload);
};

export const updateIsActiveErpAccount = (
  payload: Partial<ErpAccountSchema>,
) => {
  return api.put('Erpaccounts/UpdateIsActiveErpaccounts', payload);
};

export const searchErpAccounts = (payload: Partial<ErpAccountSchema>) => {
  return api.post('Erpaccounts/SearchErpaccounts', payload);
};

export interface SageBankAccountsPayload {
  erpAccountId: number;
  connectorId: number;
  routingNumber: string;
  bankAccountNumber: string;
}

export const getSageBankAccounts = (data: SageBankAccountsPayload) => {
  const { erpAccountId, ...payload } = data;

  return api.post(`Erpaccounts/${erpAccountId}/Sage/Intacct/Search`, payload);
};

export interface ErpIntegratorSchema {
  createdBy: string;
  createdBySp: string;
  createdOn: string;
  erpIntegratorsId: number;
  erpdbIntegratorsId: number;
  guideFileShareUri: string | null;
  guideText: string | null;
  helpText: string | null;
  intergratorName: string;
  isActive: boolean;
  logoFileShareUri: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  modifiedOn: string | null;
}

export const searchERPIntegrators = async (
  payload: Partial<ErpIntegratorSchema>,
) => {
  const response: ErpIntegratorSchema[] = await api.post(
    'ErpIntegrators/SearchErpIntegrators',
    payload,
  );

  return response;
};

export interface SmartDisburseSchema {
  businessesId: number;
  createdBy: string;
  tokenOid: string | null;
}

export const addSmartDisburse = (data: Partial<SmartDisburseSchema>) => {
  return api.post(
    `ERPApi/AddSmartDisburse?businessId=${data.businessesId}`,
    data,
  );
};

export interface ERPCreationWrapperPayload {
  businessesId: number;
  createdBy: string;
}

export const erpCreationWrapper = (payload: ERPCreationWrapperPayload) => {
  return api.post('ERPApi/ERPCreationWrapper', payload);
};

export const erpUpdateBusiness = (payload: ERPCreationWrapperPayload) => {
  return api.put('ERPApi/UpdateBusiness', payload);
};

export interface ERPAuthFieldSchema {
  authId: number;
  authType: number;
  connector: string | null;
  connectorId: number;
  createdDate: string;
  derrivedFromTemplate: boolean;
  fieldType: string | null;
  isHidden: boolean;
  isPrivate: boolean;
  isRequired: boolean;
  key: string;
  keyType: number;
  password: boolean;
  remove: boolean;
  updatedDate: string;
  value: string;
}

export interface ERPConnectorSchema {
  auths: ERPAuthFieldSchema[];
  baseUrl: string;
  connectorId: number;
  connectorType: string;
  createdDate: string;
  dataConverter: string | null;
  description: string;
  isActive: boolean;
  logo: string;
  name: string;
  updatedDate: string | null;
}

export const getAllConnectors = async (filterType: string) => {
  const response: ERPConnectorSchema[] = await api.get(
    `ERPApi/GetAllConnectorsByfilter/${filterType}`,
  );

  return response;
};

export const erpValidateConnection = (id: number, emailAddress: string) => {
  return api.post(
    `ERPApi/ValidateConnection?erpaccountsId=${id}&validatedBy=${emailAddress}`,
  );
};

export interface ERPPreferencesDataSchema {
  businessesId: number;
  contactEmailAddress: string | null;
  countryCallingCode: string | null;
  createdBy: string;
  createdBySp: string;
  createdOn: string;
  dateSentToErp: string | null;
  documentMessage: string | null;
  documentsHeaderText: string | null;
  erppreferenceDataId: number;
  isActive: boolean;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  modifiedOn: string | null;
  paymentMessage: string | null;
  paymentMessageHeaderText: string | null;
  paymentTypeText: string | null;
  requiresDigitalSignature: boolean;
  signatureHeaderText: string | null;
  telephoneNumber: string | null;
  tokenOid: string | null;
}

export const searchERPPreferencesData = async (
  payload: Partial<ERPPreferencesDataSchema>,
) => {
  const result: ERPPreferencesDataSchema[] = await api.post(
    'ErppreferencesDatas/SearchErppreferencesDatas',
    payload,
  );
  return result;
};

export const updateERPPreferencesData = async (
  payload: Partial<ERPPreferencesDataSchema>,
) => {
  return api.put('ErppreferencesDatas/UpdateErppreferencesDatas', payload);
};

export const addERPPreferencesData = async (
  payload: Partial<ERPPreferencesDataSchema>,
) => {
  return api.post('ErppreferencesDatas/AddERPPreferencesDatas', payload);
};

export const getGuideByLink = (link: string) => {
  return api.get(link, { responseType: 'blob' });
};

export interface ERPAuthenticationPayload {
  erpaccountsId: number;
  createdBy: string;
  redirectUri?: string;
}

export const erpAuthentication = (
  provider: ERPProviders,
  payload: ERPAuthenticationPayload,
) => api.post(`${provider}/Authentication`, payload);

export const erpUpdateToken = (provider: ERPProviders, payload: any) =>
  api.post(`${provider}/UpdateAccessToken`, payload);

export const getERPBankAccounts = (
  provider: ERPProviders,
  businessId: number,
) => {
  return api.post(`/${provider}/GetERPBankAccounts/${businessId}`);
};

export const fetchERPLogoByURI = async (uri: string) => {
  try {
    const logo: Blob = await api.get(uri, { responseType: 'blob' });
    return logo;
  } catch (e) {
    return null;
  }
};
