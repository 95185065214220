import { useAppSelector } from 'hooks';
import Steps from 'components/Steps';

import BusinessProfileInformation from './BusinessProfileInformation';
import ChooseYourBusiness from './ChooseYourBusiness';
import PersonInformation from './PersonInformation';
import { getCurrentStep } from '../selectors';
import { onboardingSteps } from '../utils';

const ProfileContainer = () => {
  const step = useAppSelector(getCurrentStep);

  const StepComponent = {
    [onboardingSteps.business]: () => <BusinessProfileInformation />,
    [onboardingSteps.dnb]: () => <ChooseYourBusiness />,
    [onboardingSteps.person]: () => <PersonInformation />,
  };

  return (
    <>
      <div className="mb-12 sm:mb-4">
        <Steps step={step} stepsLength={3} />
      </div>
      {StepComponent[step]()}
    </>
  );
};

export default ProfileContainer;
