import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import Button, { ButtonVariant, ButtonSize } from 'components/Button';
import { ChevronDownIcon, QuestionIcon } from 'components/icons';
import ContactSupport from 'components/ContactSupport';
import PopoverOnHover from 'components/PopoverOnHover';
import Accordion from 'components/Accordion';
import Popover from 'components/Popover';
import Link from 'components/Link';

const HelpDropDown = () => {
  const { t } = useTranslation('dashboard');

  const [showLink, setShowLink] = useState<boolean>(false);

  const handleToggle = () => {
    setShowLink(!showLink);
  };

  return (
    <Accordion
      title={
        <div className="pointer-events-none">
          <Popover
            button={
              <Button
                variant={ButtonVariant.linkSecondary}
                size={ButtonSize.mediumLarge}
                paddingClass="px-3 pointer-events-auto"
              >
                <QuestionIcon className="mr-2 text-gray-400" />
                {t('common:helpTranscard')}
              </Button>
            }
            content={<ContactSupport />}
            overlay={true}
          />
        </div>
      }
      button={
        <Button
          variant={ButtonVariant.linkSecondary}
          size={ButtonSize.small}
          onClick={handleToggle}
        >
          <ChevronDownIcon
            className={`transition-transform duration-250${
              showLink ? ' transform rotate-180' : ''
            }`}
          />
        </Button>
      }
      className="p-[1px] mt-[54px]"
      opened={showLink}
    >
      <div className="px-4 flex items-center pt-1">
        <Link
          href="https://www.transcard.com/en/smart-knowledge-base"
          target="_blank"
          className="text-sm mr-2"
        >
          {t('progress.helpLink')}
        </Link>
        <PopoverOnHover
          infoClassName="z-10 text-gray-300 text-xs bg-gray-900 rounded-lg p-4 max-w-[300px] shadow-2xl"
          info={t('progress.helpLinkDescription')}
        />
      </div>
    </Accordion>
  );
};

export default HelpDropDown;
