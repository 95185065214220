import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import OnboardingTabHeader from 'pages/dashboard/components/OnboardingTabHeader';
import { getShowErrors } from 'pages/dashboard/components/Progress/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import { UploadFile, File } from 'components/uploadFile/index';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';
import { ChevronRightIcon } from 'components/icons';
import { Permissions } from 'entities/dashboard';
import { getTheme } from 'theme/selectors';
import Button from 'components/Button';

import { getprogressPercent, getFiles, getIsLoading } from './selectors';
import GetStartedModal from './components/GetStartedModal';
import { setStep } from './businessPlanSlice';
import {
  addDocument,
  deleteBusinessPlan,
  getFile,
  updateDocument,
} from './thunks';

const PlanTab = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const { backgroundColor } = useAppSelector(getTheme);
  const progressPercent = useAppSelector(getprogressPercent);
  const isLoading = useAppSelector(getIsLoading);
  const uploadFiles = useAppSelector(getFiles);
  const showErrors: any = useAppSelector(getShowErrors);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const [isOpen, setModalState] = useState(false);

  const handleUpload = (files) => {
    if (uploadFiles.length) {
      const businessesPlanBlobReferencesId =
        uploadFiles?.[0].businessesPlanBlobReferencesId;
      const blobGuid = uploadFiles?.[0].blobGuid;

      dispatch(
        updateDocument({ files, businessesPlanBlobReferencesId, blobGuid }),
      );
      return;
    }

    dispatch(addDocument(files));
  };

  const handleDeleteFile = (id) => {
    dispatch(deleteBusinessPlan(id));
  };

  const handleModalState = () => {
    setModalState(!isOpen);
  };

  const handleGetStarted = () => {
    dispatch(setStep(0));
    setModalState(!isOpen);
  };

  const onDownload = (blobGUID, blobFileName) => {
    dispatch(getFile({ blobGUID, blobFileName }));
  };

  return (
    <div className="p-6">
      <PermissionSection permission={Permissions.financialInfo} showMessage>
        <>
          <div className="mb-5">
            <OnboardingTabHeader
              title={t('businessPlan.title')}
              subTitle={t('businessPlan.description')}
            />
          </div>
          {showErrors.businessPlan &&
            uploadFiles.length === 0 &&
            financialPermissions.edit && (
              <Alert
                type={AlertTypes.warning}
                title={t('businessPlan.fileRequired')}
                className="mb-5"
                withBorder
              />
            )}

          <div className="bg-gray-50 p-9 rounded-10">
            <UploadFile
              isLoading={isLoading}
              title={t('businessPlan.fileTitle')}
              onUpload={handleUpload}
              uploadFiles={uploadFiles}
              progressPercent={progressPercent}
              disabled={!financialPermissions.edit}
              permission={Permissions.financialInfo}
            >
              {({ handleSelect }) =>
                uploadFiles.map((file) => {
                  return (
                    <div key={file.businessesPlanBlobReferencesId}>
                      <File
                        file={{
                          ...file,
                          description: t('businessPlan.planTitle'),
                        }}
                        onDelete={() =>
                          handleDeleteFile(file?.businessesPlanBlobReferencesId)
                        }
                        onDownload={(name) => onDownload(file.blobGuid, name)}
                        handleSelect={handleSelect}
                        readOnly={!financialPermissions.edit}
                        permission={Permissions.financialInfo}
                      />
                    </div>
                  );
                })
              }
            </UploadFile>
          </div>
          <div className="bg-gray-50 p-9 rounded-10 mt-6 flex items-center justify-between">
            <div className="mr-6">
              <p className="heading-lg mb-1">
                {t('businessPlan.needHelpTitle')}
              </p>
              <p className="text-secondary">{t('businessPlan.needHelpText')}</p>
            </div>

            <PermissionSection
              permission={Permissions.financialInfo}
              showPopover
              fullWidth
              edit
            >
              <Button
                styles={{
                  backgroundColor: backgroundColor.buton.secondery.base,
                  ':hover': {
                    backgroundColor: backgroundColor.buton.secondery.hover,
                  },
                }}
                className="rounded-[10px] w-[312px]"
                heightClass="h-20"
                onClick={handleGetStarted}
                disabled={!financialPermissions.edit}
              >
                <p className="w-full text-left text-lg leading-7 font-semibold">
                  {t('businessPlan.buttons.getStarted')}
                </p>
                <ChevronRightIcon />
              </Button>
            </PermissionSection>
          </div>
          <GetStartedModal isOpen={isOpen} closeModal={handleModalState} />
        </>
      </PermissionSection>
    </div>
  );
};

export default PlanTab;
