import { createSelector } from '@reduxjs/toolkit';

import { PAYMENT_TYPES } from 'entities/configuration';
import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.configuration;

export const getDefaultBankAccountId = createSelector(
  [getRoot],
  ({ defaultBankAccountId }) => defaultBankAccountId,
);

export const getCurrentBankAccountId = createSelector(
  [getRoot],
  ({ bpsTrack }) => bpsTrack?.bankAccountId || 0,
);

export const getBPSTrack = createSelector(
  [getRoot],
  ({ bpsTrack }) => bpsTrack,
);

export const getBPSTrackPayments = createSelector(
  [getRoot],
  ({ payments }) => payments,
);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getEarlyPaymentPreferences = createSelector(
  [getRoot],
  ({ bpsTrack }) => ({
    supplierMaxTenor: bpsTrack?.supplierMaxTenor
      ? String(bpsTrack.supplierMaxTenor)
      : '',
    supplierMinTenor: bpsTrack?.supplierMinTenor
      ? String(bpsTrack.supplierMinTenor)
      : '',
    supplierRate: bpsTrack?.supplierRate ? String(bpsTrack.supplierRate) : '',
  }),
);

export const getBPSTrackConfig = createSelector([getRoot], ({ payments }) => {
  const types = {};

  PAYMENT_TYPES.filter((type) => type.isActive).forEach((type) => {
    const payment = payments.find((p) => p.paymentMethod === type.title);

    types[type.title] = {
      min: payment?.minInvoiceAmount ? String(payment.minInvoiceAmount) : '',
      max: payment?.maxInvoiceAmount ? String(payment.maxInvoiceAmount) : '',
    };
  });

  return types;
});
