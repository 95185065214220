import { CountriesWithStates, CountryISOCodes } from 'common/countries';

export const isCountryWithStates = (isoCode: unknown) => {
  return Object.values(CountriesWithStates).includes(
    isoCode as CountriesWithStates,
  );
};

export type StateOptionType = {
  id: number;
  name: string;
  value: string;
};

export const statesOfUSA: StateOptionType[] = [
  {
    id: 1,
    name: 'Alabama',
    value: 'AL',
  },
  {
    id: 2,
    name: 'Alaska',
    value: 'AK',
  },
  {
    id: 3,
    name: 'Arizona',
    value: 'AZ',
  },
  {
    id: 4,
    name: 'Arkansas',
    value: 'AR',
  },
  {
    id: 5,
    name: 'California',
    value: 'CA',
  },
  {
    id: 6,
    name: 'Colorado',
    value: 'CO',
  },
  {
    id: 7,
    name: 'Connecticut',
    value: 'CT',
  },
  {
    id: 8,
    name: 'Delaware',
    value: 'DE',
  },
  {
    id: 9,
    name: 'Florida',
    value: 'FL',
  },
  {
    id: 10,
    name: 'Georgia',
    value: 'GA',
  },
  {
    id: 11,
    name: 'Hawaii',
    value: 'HI',
  },
  {
    id: 12,
    name: 'Idaho',
    value: 'ID',
  },
  {
    id: 13,
    name: 'Illinois',
    value: 'IL',
  },
  {
    id: 14,
    name: 'Indiana',
    value: 'IN',
  },
  {
    id: 15,
    name: 'Iowa',
    value: 'IA',
  },
  {
    id: 16,
    name: 'Kansas',
    value: 'KS',
  },
  {
    id: 17,
    name: 'Kentucky',
    value: 'KY',
  },
  {
    id: 18,
    name: 'Louisiana',
    value: 'LA',
  },
  {
    id: 19,
    name: 'Maine',
    value: 'ME',
  },
  {
    id: 20,
    name: 'Maryland',
    value: 'MD',
  },
  {
    id: 21,
    name: 'Massachusetts',
    value: 'MA',
  },
  {
    id: 22,
    name: 'Michigan',
    value: 'MI',
  },
  {
    id: 23,
    name: 'Minnesota',
    value: 'MN',
  },
  {
    id: 24,
    name: 'Mississippi',
    value: 'MS',
  },
  {
    id: 25,
    name: 'Missouri',
    value: 'MO',
  },
  {
    id: 26,
    name: 'Montana',
    value: 'MT',
  },
  {
    id: 27,
    name: 'Nebraska',
    value: 'NE',
  },
  {
    id: 28,
    name: 'Nevada',
    value: 'NV',
  },
  {
    id: 29,
    name: 'New Hampshire',
    value: 'NH',
  },
  {
    id: 30,
    name: 'New Jersey',
    value: 'NJ',
  },
  {
    id: 31,
    name: 'New Mexico',
    value: 'NM',
  },
  {
    id: 32,
    name: 'New York',
    value: 'NY',
  },
  {
    id: 33,
    name: 'North Carolina',
    value: 'NC',
  },
  {
    id: 34,
    name: 'North Dakota',
    value: 'ND',
  },
  {
    id: 35,
    name: 'Ohio',
    value: 'OH',
  },
  {
    id: 36,
    name: 'Oklahoma',
    value: 'OK',
  },
  {
    id: 37,
    name: 'Oregon',
    value: 'OR',
  },
  {
    id: 38,
    name: 'Pennsylvania',
    value: 'PA',
  },
  {
    id: 39,
    name: 'Rhode Island',
    value: 'RI',
  },
  {
    id: 40,
    name: 'South Carolina',
    value: 'SC',
  },
  {
    id: 41,
    name: 'South Dakota',
    value: 'SD',
  },
  {
    id: 42,
    name: 'Tennessee',
    value: 'TN',
  },
  {
    id: 43,
    name: 'Texas',
    value: 'TX',
  },
  {
    id: 44,
    name: 'Utah',
    value: 'UT',
  },
  {
    id: 45,
    name: 'Vermont',
    value: 'VT',
  },
  {
    id: 46,
    name: 'Virginia',
    value: 'VA',
  },
  {
    id: 47,
    name: 'Washington',
    value: 'WA',
  },
  {
    id: 48,
    name: 'West Virginia',
    value: 'WV',
  },
  {
    id: 49,
    name: 'Wisconsin',
    value: 'WI',
  },
  {
    id: 50,
    name: 'Wyoming',
    value: 'WY',
  },
];

export const canadianProvinces: StateOptionType[] = [
  {
    id: 1,
    name: 'Alberta',
    value: 'AB',
  },
  {
    id: 2,
    name: 'British Columbia',
    value: 'BC',
  },
  {
    id: 3,
    name: 'Manitoba',
    value: 'MB',
  },
  {
    id: 4,
    name: 'New Brunswick',
    value: 'NB',
  },
  {
    id: 5,
    name: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    id: 6,
    name: 'Nova Scotia',
    value: 'NS',
  },
  {
    id: 7,
    name: 'Ontario',
    value: 'ON',
  },
  {
    id: 8,
    name: 'Prince Edward Island',
    value: 'PE',
  },
  {
    id: 9,
    name: 'Quebec',
    value: 'QC',
  },
  {
    id: 10,
    name: 'Saskatchewan',
    value: 'SK',
  },
  {
    id: 11,
    name: 'Yukon',
    value: 'YT',
  },
  {
    id: 12,
    name: 'Nunavut',
    value: 'NU',
  },
  {
    id: 13,
    name: 'Northwest Territories',
    value: 'NT',
  },
];

export const statesByCountry = {
  [CountryISOCodes.USA]: statesOfUSA,
  [CountryISOCodes.Canada]: canadianProvinces,
};
