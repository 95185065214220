export const CommunicationIcon = ({
  className,
  styles,
}: {
  className?: string;
  styles?: any;
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    style={styles}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.3"
      d="M12 12C12 11.1 12.6 10.5 13.5 10.5H24V4.5C24 3.6 23.4 3 22.5 3H4.5C3.6 3 3 3.6 3 4.5V19.5C3 20.4 3.6 21 4.5 21H7.5V24.15C7.5 25.2 8.69999 25.65 9.44999 24.9L12 22.35V12Z"
      fill="currentColor"
    />
    <path
      d="M33 12.0015V27.0015C33 27.9015 32.4 28.5015 31.5 28.5015H28.5V31.6515C28.5 32.7015 27.3 33.1515 26.55 32.4015L22.5 28.3515H13.5C12.6 28.3515 12 27.7515 12 26.8515V11.8516C12 10.9516 12.6 10.3516 13.5 10.3516H31.5C32.4 10.5016 33 11.1015 33 12.0015ZM28.5 16.5015C28.5 15.6015 27.9 15.0015 27 15.0015H18C17.1 15.0015 16.5 15.6015 16.5 16.5015C16.5 17.4015 17.1 18.0015 18 18.0015H27C27.9 18.0015 28.5 17.4015 28.5 16.5015ZM25.5 22.5015C25.5 21.6015 24.9 21.0015 24 21.0015H18C17.1 21.0015 16.5 21.6015 16.5 22.5015C16.5 23.4015 17.1 24.0015 18 24.0015H24C24.9 24.0015 25.5 23.4015 25.5 22.5015Z"
      fill="currentColor"
    />
  </svg>
);
