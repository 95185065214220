import { createSlice } from '@reduxjs/toolkit';

import { fetchDashboardInformation } from 'pages/dashboard/thunks';
import { CountriesList, LoginFormValues } from 'entities/login';

import { fetchBusinessesByPerson, verifyPerson, verifyCode } from './thunks';

export const LOGIN_TAB_INDEX_EMAIL = 0;
export const LOGIN_TAB_INDEX_PHONE = 1;

export interface LoginState {
  data: LoginFormValues;
  countries: CountriesList[];
  currentTab: number;
  isCodeSent: boolean;
  isLoading: boolean;
  businesses: any;
  showBusinessList: boolean;
  sessionExpired: boolean;
}

export const initialState: LoginState = {
  data: {
    phone: '',
    email: '',
  },
  isCodeSent: false,
  countries: [
    {
      id: 1,
      code: 'US',
      placeholder: '+1 (000) 000-0000',
    },
  ],
  currentTab: LOGIN_TAB_INDEX_EMAIL,
  isLoading: false,
  businesses: [],
  showBusinessList: false,
  sessionExpired: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setIsCodeSent(state, action) {
      state.isCodeSent = action.payload;
    },
    setSessionExpired(state, action) {
      state.sessionExpired = action.payload;
    },
    setShowBusinessList(state, action) {
      state.showBusinessList = action.payload;
    },
    signOut() {}, // used in rootReducer to reset global store
  },
  extraReducers: (builder) => {
    builder.addCase(verifyCode.fulfilled, (state, action) => {
      if (action.payload === false) {
        state.isCodeSent = false;
      }
    });
    builder.addCase(verifyPerson.fulfilled, (state, action) => {
      if (action.payload === 'sendCode') {
        state.isCodeSent = true;
      }
    });

    builder.addCase(fetchBusinessesByPerson.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBusinessesByPerson.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchBusinessesByPerson.fulfilled, (state, action) => {
      if (action.payload) {
        state.businesses = action.payload;
        state.isLoading = false;
      }
    });

    builder.addCase(fetchDashboardInformation.fulfilled, (state, action) => {
      state.businesses = action.payload;
    });
  },
});

export const {
  setData,
  setCurrentTab,
  setIsCodeSent,
  setSessionExpired,
  signOut,
  setShowBusinessList,
} = loginSlice.actions;

export default loginSlice.reducer;
