import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { showAllErrors } from 'pages/dashboard/components/Progress/progressSlice';
import { getPermissions } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PermissionSchema } from 'services/security';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';

import InformationMissing from './components/InformationMissing';
import { setIsModalOpened } from './submitDashboardSlice';
import SubmitModal from './components/SubmitModal';

const SubmitDashboard = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const [showWarning, setShowWarning] = useState<boolean>(false);

  const permissions = useAppSelector(getPermissions);

  const handleOpen = () => {
    dispatch(setIsModalOpened(true));
  };

  const handleWarning = () => {
    dispatch(showAllErrors());
    setShowWarning(true);
  };

  if (
    !permissions.find(
      (p: PermissionSchema) => p.permissions_Code === Permissions.submitApp,
    )
  ) {
    return null;
  }

  return (
    <>
      <SubmitModal onWarning={handleWarning} />
      <InformationMissing
        showWarning={showWarning}
        setShowWarning={setShowWarning}
      />
      <Button
        onClick={handleOpen}
        type="button"
        className="flex-shrink-0 ml-6"
        heightClass="h-10.5"
        paddingClass="px-5"
      >
        {t('dashboard:nextStep')}
      </Button>
    </>
  );
};

export default SubmitDashboard;
