import { equals } from 'ramda';

import { BusinessMerchantSchema } from 'services/businesses';

export const emptyContact = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

export const defaulFormtValues = {
  settlementBankAccountsId: 0,
  settlementAccount: '',
  captureMoreThanPreAuth: false,
  contact: emptyContact,
  fields: {},
};

export const mapMerchantFields = (data: any, list: any) => {
  const { captureMoreThanPreAuth, contact, settlementBankAccountsId, fields } =
    data;

  let filteredFields = {};

  if (Object.keys(list).length > 0) {
    for (const prop in list) {
      if (list.hasOwnProperty(prop) && list[prop].required) {
        if (fields.hasOwnProperty(prop)) {
          filteredFields[prop] = fields[prop] === undefined ? '' : fields[prop];
        } else {
          filteredFields[prop] = list[prop].type === 'boolean' ? false : '';
        }
      }
    }
  } else {
    filteredFields = fields;
  }

  return {
    captureMoreThanPreAuth,
    contact: {
      firstName: contact?.firstName || null,
      lastName: contact?.lastName || null,
      phone: contact?.phone || null,
      email: contact?.email || null,
    },
    settlementBankAccountsId: settlementBankAccountsId || null,
    processingInfo: {
      json: JSON.stringify(filteredFields || {}),
    },
  };
};

export const setFormValues = (merchant: BusinessMerchantSchema) => ({
  settlementBankAccountsId: merchant.settlementBankAccountsId || 0,
  settlementAccount: merchant.settlementBankAccountsId ? 'existing' : '',
  captureMoreThanPreAuth: merchant.captureMoreThanPreAuth || false,
  contact: merchant.contact || emptyContact,
  fields: merchant.processingInfo?.json
    ? JSON.parse(merchant.processingInfo.json)
    : {},
});

export const isMerchantUpdated = (
  merchant: BusinessMerchantSchema,
  formValues: typeof defaulFormtValues,
) =>
  merchant.captureMoreThanPreAuth !== formValues.captureMoreThanPreAuth ||
  merchant.settlementBankAccountsId !== formValues.settlementBankAccountsId ||
  !equals(merchant.contact, formValues.contact) ||
  !equals(JSON.parse(merchant.processingInfo?.json || '{}'), formValues.fields);
