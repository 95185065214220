import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { getProcent } from 'pages/dashboard/components/Progress/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { FlagIcon } from 'components/icons/FlagIcon';
import Modal from 'components/Modal';
import Steps from 'components/Steps';

import { setIsModalOpened } from '../submitDashboardSlice';
import InformationReceived from './InformationReceived';
import { getIsModalOpened } from '../selectors';
import { submitApplication } from '../thunks';
import Confirmation from './Confirmation';
import ModalActions from './ModalActions';
import AcceptTerms from './AcceptTerms';
import Connectors from './Connectors';

const SubmitModal = ({ onWarning }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const isModalOpened = useAppSelector(getIsModalOpened);
  const appPercent: any = useAppSelector(getProcent);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    dispatch(setIsModalOpened(false));
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = async () => {
    if (appPercent < 100) {
      handleClose();
      onWarning();
      return;
    }

    setIsLoading(true);
    await dispatch(submitApplication());
    setCurrentStep(4);
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isModalOpened}
      closeModal={handleClose}
      title={t('dashboard:submitModal.title')}
      icon={<FlagIcon className="text-gray-600" />}
      actions={
        <ModalActions
          step={currentStep}
          handleSubmit={handleSubmit}
          handleBack={handleBack}
          handleNext={handleNext}
          isLoading={isLoading}
        />
      }
      variant="screen"
      zIndex={25}
    >
      <div className="h-full flex flex-col">
        <div className="pt-6 px-6">
          <Steps step={currentStep} stepsLength={4} />
        </div>
        {currentStep === 1 && <Connectors />}
        {currentStep === 2 && <Confirmation />}
        {currentStep === 3 && <AcceptTerms />}
        {currentStep === 4 && <InformationReceived />}
      </div>
    </Modal>
  );
};

export default SubmitModal;
