import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy, prop } from 'ramda';

import { openDialog, closeDialog } from 'components/dialog/modalSlice';
import SuccessDialog from 'components/dialog/templates/SuccessDialog';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import { AvatarIcon } from 'components/icons';
import { Checkbox } from 'components/Inputs';
import Person from 'components/Person';

import SelectPermissions from './SelectPermissions';
import { updateBusinessMember } from '../thunks';
import { getVisiblePersons } from '../selectors';
import { mappedPersonsAccess } from './utils';

const BusinessMembers = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const persons = useAppSelector(getVisiblePersons);

  const [personsAccess, setPersonsAccess] = useState<number[]>(
    mappedPersonsAccess(persons),
  );

  useEffect(() => {
    setPersonsAccess(mappedPersonsAccess(persons));
  }, [persons]);

  const handleCancel = (id: number, value: boolean) => {
    setPersonsAccess(
      value
        ? personsAccess.filter((i: number) => i !== id)
        : [...personsAccess, id],
    );
    dispatch(closeDialog());
  };

  const handleConfirm = (id: number, value: boolean) => {
    dispatch(
      updateBusinessMember({
        businessesPersonsXREFId: id,
        hubCreateUser: value,
      }),
    );
    dispatch(closeDialog());
  };

  const handleCheckbox = (id: number, value: boolean) => {
    setPersonsAccess(
      value
        ? [...personsAccess, id]
        : personsAccess.filter((i: number) => i !== id),
    );

    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: () => handleCancel(id, value),
        onCancel: () => handleCancel(id, value),
        content: value ? (
          <SuccessDialog
            title={t('invitePersons.member.grandAccessTitle')}
            text={t('invitePersons.member.grandAccessDescription')}
            onConfirm={() => handleConfirm(id, value)}
            onCancel={() => handleCancel(id, value)}
          />
        ) : (
          <DangerDialog
            title={t('invitePersons.member.removeAccessTitle')}
            text={t('invitePersons.member.removeAccessDescription')}
            buttonText={t('common:remove')}
            onConfirm={() => handleConfirm(id, value)}
            onCancel={() => handleCancel(id, value)}
          />
        ),
      }),
    );
  };

  const sortedMembers = useMemo(
    () => sortBy(prop('personsEmailAddress'))(persons),
    [persons],
  );

  return (
    <div className="p-6">
      {sortedMembers.map((person, index) => (
        <div
          key={person.businessesPersonsXREFId}
          className="py-3 flex items-center border-b border-gray-200"
        >
          <div className="flex-1 flex items-center py-2.5">
            {person.personsGivenName1 && person.personsSurnameFirst ? (
              <div className="mr-7">
                <Person name={person.avatarLetters} index={index} />
              </div>
            ) : (
              <AvatarIcon className="h-8 w-8 text-gray-300 mr-4" />
            )}
            <span className="text-sm font-medium">
              {person.personsEmailAddress}
            </span>
          </div>
          <HideComponent hide={true}>
            <div className="ml-4 mr-6 p-4 bg-gray-50 rounded-lg">
              <label className="flex items-center cursor-pointer">
                <Checkbox
                  checked={personsAccess.includes(
                    person.businessesPersonsXREFId,
                  )}
                  onChange={(value) =>
                    handleCheckbox(person.businessesPersonsXREFId, value)
                  }
                  disabled={person.businessesAdmin}
                />
                <span className="mx-2 text-sm text-gray-800">
                  {t('invitePersons.member.checkbox')}
                </span>
              </label>
            </div>
          </HideComponent>
          <SelectPermissions person={person} />
        </div>
      ))}
    </div>
  );
};

export default BusinessMembers;
