import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Others,
  WalletIcon,
  PlusIcon,
  CartIcon,
  HomeIcon,
} from 'components/icons';
import Modal from 'components/Modal';
import { CurrencyInput } from 'components/Inputs';
import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BusinessPlan } from 'common/businessPlan';

import HorizontalPipe from './HorizontalPipe';
import CheckboxWithIcon from './CheckboxWithIcon';
import { Tablet, TabletText } from './Tablet';

import { setBusinessPlan } from '../businessPlanSlice';
import {
  getBusinessPlanItems,
  getBusinessPlanSelectedCurrency,
} from '../selectors';
import ExpensesModalForm from './ExpensesModalForm';
import { CurrencyCode } from 'entities/dashboard';

interface AboutRevenueAndExpensesProps {
  step: number;
}

const AboutRevenueAndExpenses: React.FC<AboutRevenueAndExpensesProps> = ({
  step,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const { instore, online, others, storeSales, onlieSales, expensesJSON } =
    useAppSelector(getBusinessPlanItems);
  const selectedCurrency: string = useAppSelector(
    getBusinessPlanSelectedCurrency,
  );
  const [isOpenModal, setModalState] = useState(false);

  const handleModal = () => setModalState(!isOpenModal);

  const handleState = (key: string, value: boolean) => {
    dispatch(setBusinessPlan({ key, value }));
  };

  const handleChange = (key: string, value: number) => {
    dispatch(setBusinessPlan({ key, value }));
  };

  const dispatchNewExpense = (list) => {
    dispatch(
      setBusinessPlan({
        key: BusinessPlan.expensesJSON,
        value: list.map((expense) => ({
          expense,
        })),
      }),
    );
  };

  const handleDelete = (deleteByIndex) => {
    const filter = expensesJSON
      .filter((item, index) => index !== deleteByIndex)
      .map(({ expense }) => expense);

    dispatchNewExpense(filter);
  };

  const addNewExpense = (value) => {
    handleModal();
    dispatchNewExpense(value);
  };

  if (step !== 2) {
    return null;
  }

  return (
    <div className="w-full">
      <h1 className="text-2xl leading-8 font-bold">
        {t('dashboard:businessPlan.modal.aboutYourBusinessTitle')}
      </h1>
      <HorizontalPipe />
      <div>
        <div>
          <label className="text-sm leading-5 font-medium mb-[10px]">
            {t('dashboard:businessPlan.modal.yourRevenue')}
          </label>
          <label className="text-sm leading-5 font-normal text-zinc-400">
            {t('dashboard:businessPlan.modal.seeAll')}
          </label>
        </div>

        <div className="mt-[10px] flex gap-x-6 flex-wrap gap-y-6">
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.instore, !instore)}
            checked={instore}
            label={t('dashboard:businessPlan.modal.inStore')}
            icon={<HomeIcon />}
          />
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.online, !online)}
            checked={online}
            label={t('dashboard:businessPlan.modal.online')}
            icon={<CartIcon />}
          />
          <CheckboxWithIcon
            onChange={() => handleState(BusinessPlan.others, !others)}
            checked={others}
            label={t('dashboard:businessPlan.modal.others')}
            icon={<Others />}
          />
        </div>
        <div className="flex items-center mt-9 flex-wrap gap-x-12">
          <div className="flex flex-1 w-full">
            <CurrencyInput
              className="w-full"
              label={t('dashboard:businessPlan.modal.storeSales.label')}
              name={BusinessPlan.storeSales}
              onChange={(value: number) =>
                handleChange(BusinessPlan.storeSales, value)
              }
              onCurrencyChange={(currency: CurrencyCode) =>
                handleChange(
                  BusinessPlan.currencyCodesId,
                  currency.currencyCodeID,
                )
              }
              value={storeSales}
              currencyValue={selectedCurrency}
              placeholder={t(
                'dashboard:businessPlan.modal.storeSales.placeholder',
              )}
            />
          </div>
          <div className="flex flex-1 w-full">
            <CurrencyInput
              className="w-full"
              label={t('dashboard:businessPlan.modal.onlieSales.label')}
              onChange={(value: number) =>
                handleChange(BusinessPlan.onlieSales, value)
              }
              onCurrencyChange={(currency: CurrencyCode) =>
                handleChange(
                  BusinessPlan.currencyCodesId,
                  currency.currencyCodeID,
                )
              }
              name={BusinessPlan.onlieSales}
              value={onlieSales}
              currencyValue={selectedCurrency}
              placeholder={t(
                'dashboard:businessPlan.modal.onlieSales.placeholder',
              )}
            />
          </div>
        </div>
        <div className="mt-9 flex flex-col">
          <label className="text-lg leading-6 font-medium text-gray-900">
            {t('dashboard:businessPlan.modal.keyExpenses')}
          </label>
          <span className="text-sm leading-5 font-normal mt-1 text-gray-500">
            {t('dashboard:businessPlan.modal.ditailsAlreadyProvided')}
          </span>
        </div>
        <HorizontalPipe />
        <div className="flex flex-col">
          <div className="mb-[10px]">
            <label className="text-sm leading-5 font-medium mb-[10px]">
              {t('dashboard:businessPlan.modal.wahtExpenses')}
            </label>
            <label className="text-sm leading-5 font-normal text-zinc-400">
              {t('dashboard:businessPlan.modal.addExpenses')}
            </label>
          </div>

          <div className="flex gap-x-4 flex-wrap gap-y-4">
            {expensesJSON.map(({ expense }, index) => (
              <div key={index}>
                <Tablet
                  onEdit={handleModal}
                  onDelete={() => handleDelete(index)}
                >
                  <TabletText>{expense}</TabletText>
                </Tablet>
              </div>
            ))}
            {expensesJSON.length < 5 && (
              <Button variant="link" size="medium" onClick={handleModal}>
                <PlusIcon className="mr-2" />
                {t('dashboard:businessPlan.buttons.addAnotherExpense')}
              </Button>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenModal}
        closeModal={handleModal}
        maxWidth="50rem"
        title={t('dashboard:businessPlan.buttons.editAnotherExpense')}
        icon={<WalletIcon className="text-gray-500" />}
        content={{
          maxWidth: '600px',
        }}
        closeTimeoutMS={0}
        isOverlayDisabled={true}
      >
        <ExpensesModalForm
          onClose={handleModal}
          onSave={addNewExpense}
          values={expensesJSON}
        />
      </Modal>
    </div>
  );
};

export default AboutRevenueAndExpenses;
