export const SocialMediaIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    className={className}
  >
    <path
      d="M16.6666 1C16.6666 0.653423 16.4872 0.331557 16.1924 0.14935C15.8975 -0.0328564 15.5294 -0.0494204 15.2194 0.105574L7.43056 4H3.66663C2.00977 4 0.666626 5.34315 0.666626 7C0.666626 8.65685 2.00977 10 3.66663 10H3.94587L5.71795 15.3162C5.85406 15.7246 6.2362 16 6.66663 16H7.66663C8.21892 16 8.66663 15.5523 8.66663 15V10.618L15.2194 13.8944C15.5294 14.0494 15.8975 14.0329 16.1924 13.8507C16.4872 13.6684 16.6666 13.3466 16.6666 13V1Z"
      fill="#6B7280"
    />
  </svg>
);
