import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useEffect, useState } from 'react';

import { msalConfig, loginRequest } from 'config';
import IdleWrapper from 'components/IdleWrapper';
import { msalLogin } from 'utils/authService';
import Loading from 'components/Loading';
import { useCurrentUser } from 'hooks';

export const msalInstance = new PublicClientApplication(msalConfig);

const Authentication: React.FC = ({ children }) => {
  const { loading: userLoading, user, isAuthenticated } = useCurrentUser();

  const [tokenLoading, setTokenLoading] = useState(true);

  const getMsalUserData = async () => {
    try {
      await msalInstance.initialize();

      const data = await msalInstance.acquireTokenSilent({
        account: user,
        ...loginRequest,
      });

      msalLogin(data?.accessToken);
      setTokenLoading(false);
    } catch (e) {
      setTokenLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getMsalUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && !userLoading) {
      setTokenLoading(false);
    }
  }, [isAuthenticated, userLoading]);

  if (userLoading || tokenLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

const AuthWrapper: React.FC = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Authentication>
        <IdleWrapper>{children}</IdleWrapper>
      </Authentication>
    </MsalProvider>
  );
};

export default AuthWrapper;
