import { Listbox, Transition } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import { UseFormRegister, FieldValues, RegisterOptions } from 'react-hook-form';

import { ChevronDownIcon, CheckIcon, SelectIcon } from 'components/icons';

import ErrorMessage from './components/ErrorMessage';

export enum SelectIndicatorTypes {
  chevron = 'chevron',
  selector = 'selector',
}

interface SelectClasses {
  activeOption?: string;
  options?: string;
  option?: string;
}

const indicatorTypes = {
  [SelectIndicatorTypes.chevron]: (
    <ChevronDownIcon className="text-gray-400" aria-hidden="true" />
  ),
  [SelectIndicatorTypes.selector]: (
    <SelectIcon className="text-gray-400" aria-hidden="true" />
  ),
};

interface SelectProps {
  name: string;
  register?: UseFormRegister<FieldValues>;
  setValue?: any;
  validation?: RegisterOptions;
  label?: string;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  info?: string;
  className?: string;
  error?: any;
  onBlur?: (event: React.FormEvent<HTMLSelectElement>) => void;
  onChange?: any;
  onKeyPress?: React.KeyboardEventHandler;
  ref?: React.Ref<HTMLSelectElement>;
  pattern?: RegExp;
  optional?: boolean;
  iconEnd?: React.ReactNode;
  iconStart?: React.ReactNode;
  prefix?: string;
  options: any;
  withIcon?: boolean;
  optionalLabel?: string;
  optionValue?: string;
  selectedComponent?: any;
  optionComponent?: any;
  optionsFooter?: any;
  classes?: SelectClasses;
  indicator?: SelectIndicatorTypes;
}

export const Select: React.FC<SelectProps> = ({
  name,
  label,
  placeholder = ' ',
  onChange,
  value,
  className,
  options,
  withIcon,
  disabled,
  register,
  setValue,
  error,
  optionalLabel,
  optionValue,
  selectedComponent,
  optionComponent,
  optionsFooter,
  classes = {},
  indicator = SelectIndicatorTypes.chevron,
  validation = {},
}) => {
  const [selected, setSelected] = useState<any>({});

  const Selected = selectedComponent;
  const Option = optionComponent;
  const Footer = optionsFooter;

  useEffect(() => {
    if (!value && selected.value) {
      setSelected({});

      if (setValue) {
        setValue(name, '');
      }
    }

    if (value && value.value !== selected.value) {
      setSelected(value);

      if (setValue) {
        setValue(name, value.value || '', {
          shouldValidate: value.value !== '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, name, setValue]);

  const handleChange = (selectedValue: any) => {
    setSelected(selectedValue);

    if (setValue) {
      setValue(name, selectedValue.value, { shouldValidate: true });
    }

    if (onChange) {
      onChange(selectedValue);
    }
  };

  return (
    <Listbox
      as="div"
      value={selected}
      onChange={handleChange}
      className={className}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700 mb-1">
              {label}
            </Listbox.Label>
          )}
          <div className="relative">
            <input
              type="hidden"
              name={name}
              {...(register && register(name, validation))}
            />

            <Listbox.Button
              className={classNames(
                'flex items-center h-10 relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default',
                disabled ? 'bg-gray-100' : 'bg-white',
                error
                  ? 'border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900'
                  : 'border-gray-300 focus:outline-none focus:ring-0 focus:border-indigo-500',
              )}
            >
              {withIcon && selected?.icon && (
                <img
                  src={selected.icon}
                  alt={selected?.name}
                  className="mr-2 mt-0.5 h-6"
                />
              )}

              {selected.name ? (
                <>
                  {selectedComponent ? (
                    <Selected selected={selected} />
                  ) : (
                    <span className="block truncate">{selected?.name}</span>
                  )}
                </>
              ) : (
                <span className="block truncate text-gray-400 h-6">
                  {placeholder}
                </span>
              )}

              <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                {indicatorTypes[indicator]}
              </span>
            </Listbox.Button>

            <ErrorMessage error={error} />

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-10 mt-1 w-full bg-white shadow-lg ${
                  classes.options || 'max-h-60'
                } rounded-md text-base overflow-auto focus:outline-none custom-scrollbar`}
              >
                {options.map((option: any, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active
                          ? classes.activeOption
                            ? classes.activeOption
                            : 'text-white bg-indigo-600 cursor-default'
                          : 'text-gray-900 cursor-default',
                        classes.option,
                        'select-none relative py-2 pl-3 pr-9 flex items-center',
                      )
                    }
                    value={
                      optionalLabel
                        ? {
                            name: option?.[optionalLabel],
                            value: option?.[optionValue ?? ''],
                          }
                        : option
                    }
                  >
                    {({ selected: isSelected, active }) => {
                      return optionComponent ? (
                        <Option
                          option={option}
                          isSelected={isSelected}
                          active={active}
                        />
                      ) : (
                        <>
                          {option.icon && (
                            <LazyLoad height={20} once overflow>
                              <img
                                src={option.icon}
                                alt={option?.name}
                                className="mr-2 mt-0.5 h-6"
                              />
                            </LazyLoad>
                          )}

                          <span
                            className={classNames(
                              isSelected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {optionalLabel
                              ? option?.[optionalLabel]
                              : option.name}
                          </span>

                          {isSelected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Listbox.Option>
                ))}
                {optionsFooter && (
                  <Listbox.Option value={{}}>
                    <Footer />
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
