import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FileIcon, CheckCircleIcon } from 'components/icons';
import ProgressLine from 'components/ProgressLine';

interface ProgressProps {
  loading: number;
}

const Progress: React.FC<ProgressProps> = ({ loading }) => {
  const { t } = useTranslation();

  if (!loading) {
    return null;
  }

  return (
    <div className="flex items-center my-5 w-full">
      <FileIcon />
      <div className="ml-2.5 w-full">
        <div className="flex items-center justify-between mb-2.5">
          <p className="text-sm leading-5 font-medium text-gray-900">
            {t(
              loading === 100
                ? 'common:fileUploadDone'
                : 'common:fileUploadUploading',
            )}
          </p>
          <p
            className={classNames(
              'flex items-center text-sm leading-5 font-semibold',
              loading === 100 ? 'text-green-500' : 'text-gray-900',
            )}
          >
            <span className="mr-1.5">{loading}%</span>
            {loading === 100 && <CheckCircleIcon className="text-green-500" />}
          </p>
        </div>

        <ProgressLine progress={loading} height={6} />
      </div>
    </div>
  );
};

export default Progress;
