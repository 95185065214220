import { Checkbox } from 'components/Inputs/Checkbox';

interface CheckboxWithIconProps {
  label: string;
  icon?: any;
  onChange: () => void;
  checked: boolean;
}

const CheckboxWithIcon: React.FC<CheckboxWithIconProps> = ({
  label,
  icon,
  onChange,
  checked,
}) => {
  return (
    <button
      onClick={onChange}
      className="flex min-w-[186px] flex-row justify-between rounded-xl items-center flex-1 w-full pt-[17px] pb-[17px] pr-[25px] pl-[25px] border-[1px] border-gray-300"
    >
      <div className="flex justify-center items-center">
        {icon}
        <label className="decoration-gray-900 text-[14px] font-medium ml-[10px] cursor-pointer">
          {label}
        </label>
      </div>
      <Checkbox checked={checked} onChange={onChange} />
    </button>
  );
};

export default CheckboxWithIcon;
