import PermissionSection from 'components/permission/PermissionSection';
import { PermissionSchema } from 'services/security';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';

import {
  InvitePersonsProps,
  PermissionType,
  Person,
} from '../invitePersonsSlice';

export const mappedPersonsAccess = (persons: Person[]) =>
  persons.filter((p) => p.hubCreateUser).map((p) => p.businessesPersonsXREFId);

export const getModalActions = (
  currentTab,
  t,
  onClick,
  isLoading,
  permissions,
) => {
  if (currentTab === 0) {
    return (
      <div className="flex justify-end">
        <PermissionSection permission={Permissions.invite} showPopover edit>
          <Button
            onClick={onClick}
            loading={isLoading}
            disabled={!permissions.edit}
          >
            {t('dashboard:invitePersons.buttons.send')}
          </Button>
        </PermissionSection>
      </div>
    );
  }

  return null;
};

export const getPermissionCodeWithoutType = (permission: PermissionSchema) => {
  return permission.permissions_Code
    .toLowerCase()
    .replace(`_${PermissionType.view}`, '')
    .replace(`_${PermissionType.edit}`, '');
};

export const getMappedPermissions = (data: InvitePersonsProps) => {
  const { permissionType, personPermissions, permissions } = data;

  const viewPermissions = personPermissions.filter((p) =>
    p.permissions_Code.toLowerCase().endsWith(`_${PermissionType.view}`),
  );

  const editPermissions = personPermissions.filter((p) =>
    p.permissions_Code.toLowerCase().endsWith(`_${PermissionType.edit}`),
  );

  const selectedPermissions =
    permissionType === PermissionType.view
      ? viewPermissions
      : [
          ...permissions.view.filter((p) =>
            editPermissions.find(
              (e) =>
                getPermissionCodeWithoutType(e) ===
                getPermissionCodeWithoutType(p),
            ),
          ),
          ...editPermissions,
        ];

  return selectedPermissions.map((p) => p.security_PermissionsId);
};
