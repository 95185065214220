import { FieldValues, useForm, useFieldArray } from 'react-hook-form';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BanksWithPaymentPreferences, BankNames } from 'entities/bankAccounts';
import { Input, Checkbox, NumberInput, MultiSelect } from 'components/Inputs';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import { VendorCredentialProps } from 'entities/bankAccounts';
import checkPayment from 'assets/images/check-payment.png';
import ExternalLink from 'components/links/ExternalLink';
import { File, UploadFile } from 'components/uploadFile';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import Alert, { AlertTypes } from 'components/Alert';
import { Switch } from 'components/Inputs';
import Popover from 'components/Popover';
import Loading from 'components/Loading';
import Link from 'components/Link';
import {
  BankAccountCheckRangeType,
  BankAccountCheckType,
} from 'services/bankAccounts';
import {
  CloseIcon,
  PlusIcon,
  InfoCircleSolidIcon,
  PencilIcon,
} from 'components/icons';

import { mapAdditionalFields, getBankName } from '../utils';
import FundingPopoverLink from './FundingPopoverLink';
import BankConnection from './BankConnection';
import {
  setIsFormUpdate,
  setSelectedConnection,
  setShowConnectionForm,
} from '../bankAccountsSlice';
import {
  fetchVendorCredentials,
  fetchCheckRanges,
  removeBankAccountFile,
  updateBankAccountRanges,
  updateBankAccount,
  saveVendorCredentials,
  downloadFile,
  downloadGuide,
} from '../thunks';
import {
  getSelectedIntegrator,
  getBankAccountJson,
  getBankAccountFields,
  getIsFormUpdate,
  getBankAccountIsLoading,
  getFileData,
  getBankAccountConnections,
  getShowConnectionForm,
  getBankAccount,
  getBankAccountCheckRanges,
  getBankAccountChecks,
  getSelectedConnection,
} from '../selectors';

const emptyRange = {
  banksAccountsChecksRangesId: null,
  isActive: true,
  startRange: '',
  endRange: '',
};

const validateNumber = (value: string) => {
  return value.length === 4 && value !== '0000' && /^\d+$/.test(value);
};

const BankAccountSignIn = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const selectedIntegrator = useAppSelector(getSelectedIntegrator);
  const jsonData: any = useAppSelector(getBankAccountJson);
  const bankAccount = useAppSelector(getBankAccount);
  const fields = useAppSelector(getBankAccountFields);
  const isFormUpdate = useAppSelector(getIsFormUpdate);
  const isLoading = useAppSelector(getBankAccountIsLoading);
  const treasuryDoc = useAppSelector(getFileData);
  const connections = useAppSelector(getBankAccountConnections);
  const showConnectionForm = useAppSelector(getShowConnectionForm);
  const selectedConnection = useAppSelector(getSelectedConnection);
  const checkRanges: BankAccountCheckRangeType[] = useAppSelector(
    getBankAccountCheckRanges,
  );
  const checkOmitted: BankAccountCheckType[] =
    useAppSelector(getBankAccountChecks);

  const [editFields, setEditFields] = useState<number[]>([]);
  const [file, setFile] = useState<any>(null);
  const [fileProgress, setFileProgress] = useState<number>(0);
  const [checkSwitch, setCheckSwitch] = useState<boolean>(false);
  const [rangesError, setRangesError] = useState<string>('');

  const docs = file ? [file] : treasuryDoc.id ? [treasuryDoc] : [];

  const defaultOmitted = useMemo(() => {
    return checkOmitted.map((omitted: BankAccountCheckType) => ({
      ...omitted,
      value: omitted.checkNumber || '',
      label: omitted.checkNumber || '',
    }));
  }, [checkOmitted]);

  const hasPaymentPreferences = useMemo(
    () =>
      Object.values(BanksWithPaymentPreferences).includes(
        getBankName(selectedIntegrator?.bankName || ''),
      ),
    [selectedIntegrator?.bankName],
  );

  const hasTransactionFeed = useMemo(
    () =>
      getBankName(selectedIntegrator?.bankName || '') ===
      BankNames.GoldmanSachs,
    [selectedIntegrator?.bankName],
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      ...jsonData,
      ranges: checkRanges.length ? checkRanges : [emptyRange],
      omitted: checkOmitted,
      reportingFeedsEnabled: bankAccount?.reportingFeedsEnabled || false,
    },
  });

  const {
    fields: rangeFields,
    append,
    remove,
  } = useFieldArray({
    name: 'ranges',
    control,
  });

  useEffect(() => {
    if (isFormUpdate) {
      const data: any = {
        ranges: checkRanges.length ? checkRanges : [emptyRange],
        omitted: checkOmitted,
        reportingFeedsEnabled: bankAccount?.reportingFeedsEnabled || false,
      };

      fields.map(
        (field) =>
          (data[`additionalFields.${field.id}`] =
            jsonData[`additionalFields.${field.id}`] || ''),
      );

      reset(data);
      dispatch(setIsFormUpdate());
    }
  }, [
    fields,
    jsonData,
    checkRanges,
    checkOmitted,
    isFormUpdate,
    bankAccount?.reportingFeedsEnabled,
    reset,
    dispatch,
  ]);

  useEffect(() => {
    if (connections.length) {
      if (selectedConnection < 0) {
        dispatch(setSelectedConnection(0));
      }
    } else {
      dispatch(setShowConnectionForm(true));
    }
  }, [selectedConnection, connections, dispatch]);

  useEffect(() => {
    setCheckSwitch(bankAccount?.checkCustomerEnabled || false);
  }, [bankAccount?.checkCustomerEnabled]);

  useEffect(() => {
    dispatch(fetchVendorCredentials());

    if (hasPaymentPreferences) {
      dispatch(fetchCheckRanges());
    }
  }, [dispatch, hasPaymentPreferences]);

  const isValidRanges = (nextValue: boolean = checkSwitch) => {
    const { ranges } = getValues();
    setRangesError('');

    let valid = true;

    if (
      !nextValue &&
      ranges.length === 1 &&
      !ranges[0].startRange &&
      !ranges[0].endRange
    ) {
      clearErrors(['ranges.0.startRange', 'ranges.0.endRange']);
      return true;
    }

    if (ranges) {
      ranges.map((range: BankAccountCheckRangeType, index: number) => {
        if (!range.startRange && !range.endRange && ranges.length > 1) {
          clearErrors(`ranges.${index}.startRange`);
          remove(index);
          return;
        }

        if (
          !range.startRange ||
          range.startRange.length < 4 ||
          (range.endRange && +range.startRange > +range.endRange)
        ) {
          valid = false;
          setError(`ranges.${index}.startRange`, {
            type: 'custom',
            message: '',
          });
        } else {
          clearErrors(`ranges.${index}.startRange`);
        }

        if (
          !range.endRange ||
          range.endRange.length < 4 ||
          (range.startRange && +range.endRange < +range.startRange)
        ) {
          valid = false;
          setError(`ranges.${index}.endRange`, { type: 'custom', message: '' });
        } else {
          clearErrors(`ranges.${index}.endRange`);
        }
      });
    }

    return valid;
  };

  const handleCheckPaymentsSwitch = () => {
    setCheckSwitch(!checkSwitch);
  };

  const onSubmit = async (data: any) => {
    if (hasPaymentPreferences) {
      const isValid = isValidRanges();

      if (!isValid) {
        return;
      }

      const { ranges, omitted } = data;
      const result = await dispatch(
        updateBankAccountRanges({ ranges, omitted }),
      );

      if (result.error && result.payload) {
        setRangesError(result.payload);
        return;
      }
    }

    const { additionalFields, reportingFeedsEnabled } = data;
    const jsone = mapAdditionalFields({ fields, additionalFields, jsonData });

    const fileFields: any = {};

    if (!docs.length) {
      fileFields.treasuryBlobdocumentId = null;
      fileFields.treasuryBlobFileName = '';
      fileFields.treasuryBlobFileSize = '';
      fileFields.treasuryBlobFileExtension = '';
    } else if (file?.file) {
      fileFields.file = file.file;
      fileFields.treasuryBlobFileName = file.blobFileName;
      fileFields.treasuryBlobFileSize = file.blobFileSize;
      fileFields.treasuryBlobFileExtension = file.blobFileExtension;
    }

    const payload = {
      ...fileFields,
      allDataEntered: !jsone.find((field) => !field.value.trim()),
      reportingFeedsEnabled,
    };

    if (hasPaymentPreferences) {
      payload.checkCustomerEnabled = checkSwitch;
    }

    await dispatch(saveVendorCredentials(window.btoa(JSON.stringify(jsone))));
    dispatch(updateBankAccount(payload));
  };

  const handleFileDelete = () => {
    if (file) {
      setFile(null);
    }

    if (treasuryDoc.id) {
      dispatch(removeBankAccountFile());
    }
  };

  const handleFileDownload = () => {
    if (treasuryDoc.id) {
      dispatch(
        downloadFile({
          blobId: treasuryDoc.id,
          bankAccountId: bankAccount?.bankAccountsId,
          fileName: treasuryDoc.blobFileName,
        }),
      );
    }
  };

  const handleFileUpload = async (files: FileList) => {
    handleFileDelete();

    setFileProgress(100);
    setTimeout(() => {
      setFileProgress(0);
      setFile({
        file: files[0],
        id: 0,
        blobFileName: files[0].name,
        blobFileSize: files[0].size,
        blobFileExtension: files[0].name.split('.')[1],
      });
    }, 1000);
  };

  const handleGuide = () => {
    const filename = `${t('bank:filename', {
      name: selectedIntegrator?.bankName || '',
    })}.pdf`;
    dispatch(
      downloadGuide({
        guid: selectedIntegrator?.howToBlobGuid || '',
        filename,
      }),
    );
  };

  const handleEditField = (fieldId: number) => {
    setValue(`additionalFields.${fieldId}`, '');
    setEditFields([...editFields, fieldId]);
  };

  const handleCancelField = (fieldId: number) => {
    setValue(
      `additionalFields.${fieldId}`,
      jsonData[`additionalFields.${fieldId}`],
    );
    setEditFields(editFields.filter((id: number) => id !== fieldId));
  };

  return (
    <div className="p-6 flex-1 overflow-auto">
      {selectedIntegrator && (
        <div className="h-12 flex items-center justify-center mb-2.5">
          <img
            alt={selectedIntegrator.bankName}
            src={`/assets/images/banks/${getBankName(
              selectedIntegrator.bankName,
            )}.png`}
            className="mx-auto max-w-[200px]"
          />
        </div>
      )}

      <div className="max-w-[600px] mx-auto mb-10">
        <p className="heading-2xl mb-8 text-center">{t('bank:signIn')}</p>

        <div className="relative">
          <div className="mb-8 flex items-center justify-center">
            <p className="text-secondary mr-2">{t('funding.setUpTitle')}</p>
            <FundingPopoverLink bankAccountId={bankAccount?.bankAccountsId} />
          </div>

          <Loading loading={isLoading} transparent />

          <HideComponent
            show={connections && connections.length > 0 && !showConnectionForm}
          >
            <>
              <p className="text-center text-secondary py-2 mb-6">
                {t('bank:selectConnection')}
              </p>

              {connections.map((connection, index) => (
                <BankConnection
                  onClick={() => dispatch(setSelectedConnection(index))}
                  disabled={!!bankAccount?.tgpaccountReferenceId}
                  isSelected={selectedConnection === index}
                  connection={connection}
                  index={index}
                  key={index}
                />
              ))}
            </>
          </HideComponent>

          <HideComponent show={showConnectionForm}>
            <>
              {(selectedIntegrator?.helpText ||
                selectedIntegrator?.urltext) && (
                <Alert
                  type={AlertTypes.info}
                  title={selectedIntegrator.helpText}
                  description={
                    <ExternalLink
                      href={selectedIntegrator.url}
                      site={selectedIntegrator.bankName}
                      className="font-semibold"
                    >
                      {selectedIntegrator.urltext}
                      {' ->'}
                    </ExternalLink>
                  }
                />
              )}

              <p className="text-sm text-gray-900 mb-6 bg-gray-50 p-4 mt-6">
                {t('bank:credentialsText')}
              </p>

              {selectedIntegrator?.howToBlobGuid && (
                <div className="flex justify-center mb-6">
                  <div className="bg-gray-50 rounded-md px-4 py-2 text-sm">
                    {t('bank:helpText')}
                    <Link onClick={handleGuide} className="ml-1">
                      {t('bank:helpLink')}
                    </Link>
                  </div>
                </div>
              )}

              {!isLoading && (
                <form
                  id="bankAccountForm"
                  onSubmit={handleSubmit(onSubmit)}
                  className="relative"
                >
                  {/* <Input
                    register={register}
                    name="connectionName"
                    label={t('bank:connectionName')}
                    placeholder={t('bank:connectionNamePlaceholder')}
                    className="mb-6"
                    error={errors.connectionName}
                  /> */}

                  {fields.map((field: VendorCredentialProps) => (
                    <div className="relative" key={field.id}>
                      <Input
                        register={register}
                        name={`additionalFields.${field.id}`}
                        label={field.name.replace('_', ' ')}
                        className="mb-6 w-full"
                        inputClassName={
                          jsonData[`additionalFields.${field.id}`] ? 'pr-8' : ''
                        }
                        error={errors.additionalFields?.[field.id]}
                        readOnly={
                          !editFields.includes(field.id) &&
                          Boolean(jsonData[`additionalFields.${field.id}`])
                        }
                      />
                      {!editFields.includes(field.id) &&
                        jsonData[`additionalFields.${field.id}`] && (
                          <button
                            type="button"
                            className="cursor-pointer absolute right-0 top-[26px] p-2"
                            onClick={() => handleEditField(field.id)}
                          >
                            <PencilIcon className="text-gray-500" />
                          </button>
                        )}
                      {editFields.includes(field.id) && (
                        <button
                          type="button"
                          className="cursor-pointer absolute right-0 top-[26px] p-2"
                          onClick={() => handleCancelField(field.id)}
                        >
                          <CloseIcon className="text-gray-500" />
                        </button>
                      )}
                    </div>
                  ))}

                  <div className="justify-start hidden">
                    <label htmlFor="terms-checkbox" className="flex">
                      <Checkbox
                        id="terms-checkbox"
                        className="mt-[4px]"
                        checked={true}
                        disabled
                      />
                      <span className="ml-4 text-sm text-gray-900">
                        {t('bank:checkBoxTitle')}
                      </span>
                    </label>
                  </div>

                  <UploadFile
                    title={t('bank:fileTitle')}
                    className="mt-5"
                    onUpload={handleFileUpload}
                    progressPercent={fileProgress}
                    uploadFiles={docs}
                    optional
                  >
                    {({ handleSelect }) =>
                      docs.map((doc) => (
                        <div key={doc.id}>
                          <File
                            file={{ ...doc, description: t('bank:fileTitle') }}
                            handleSelect={handleSelect}
                            onDelete={handleFileDelete}
                            onDownload={handleFileDownload}
                            size="small"
                          />
                        </div>
                      ))
                    }
                  </UploadFile>

                  {hasTransactionFeed && (
                    <div className="my-6 p-3 bg-gray-50 rounded-lg">
                      <label
                        htmlFor="reporting-feeds"
                        className="flex cursor-pointer"
                      >
                        <Checkbox
                          id="reporting-feeds"
                          className="mt-[4px]"
                          name="reportingFeedsEnabled"
                          register={register}
                          setValue={setValue}
                          checked={bankAccount?.reportingFeedsEnabled || false}
                        />
                        <span className="block pl-3">
                          <span className="block text-sm font-medium">
                            {t('bank:feedsTitle')}
                          </span>
                          <span className="block text-secondary">
                            {t('bank:feedsText')}
                          </span>
                        </span>
                      </label>
                    </div>
                  )}

                  {hasPaymentPreferences && (
                    <div className="pt-6">
                      <h6 className="font-medium mb-4">
                        {t('bank:paymentPreferences.title')}
                      </h6>
                      <div className="flex items-start mb-4">
                        <Switch
                          isOn={checkSwitch}
                          handleToggle={handleCheckPaymentsSwitch}
                        />
                        <div className="ml-3">
                          <p className="text-sm font-medium mb-0.5">
                            {t('bank:paymentPreferences.checkTitle')}
                          </p>
                          <p className="text-secondary text-xs">
                            {t('bank:paymentPreferences.checkDescription')}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center mb-0.5 pl-14">
                          <p className="text-sm font-medium mr-1">
                            {t('bank:paymentPreferences.rangeTitle')}
                          </p>
                          <Popover
                            content={
                              <div className="py-2 ml-1 px-[10px] bg-gray-900 text-gray-50 text-sm max-w-[344px] rounded-lg">
                                {t('bank:paymentPreferences.rangeInfo')}
                              </div>
                            }
                            button={
                              <button
                                type="button"
                                className="border-[2px] border-transparent flex self-center cursor-pointer text-gray-400 flex-shrink-0 p-0 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:border-blue-600"
                              >
                                <InfoCircleSolidIcon className="h-[18px] w-[18px] text-gray-400" />
                              </button>
                            }
                            placement="right"
                          />
                        </div>
                        <p className="text-sm pl-14 mb-2">
                          {t('bank:paymentPreferences.checkValidation')}
                        </p>
                        <img
                          src={checkPayment}
                          alt="Check payment illustration"
                        />
                        <div className="pl-14">
                          <ErrorMessage error={{ message: rangesError }} />
                        </div>
                        <div className="pl-14 mt-3">
                          {rangeFields.map((field, index) => (
                            <div
                              className="flex items-center mb-3"
                              key={field.id}
                            >
                              <label className="text-sm font-medium text-gray-700">
                                {t('bank:paymentPreferences.from')}
                              </label>
                              <NumberInput
                                control={control}
                                name={`ranges.${index}.startRange`}
                                placeholder="0001"
                                className="mx-2 max-w-[128px]"
                                allowNegative={false}
                                maxLength={4}
                                type="int"
                                error={errors.ranges?.[index]?.startRange}
                                handleChange={isValidRanges}
                                readOnly={!checkSwitch}
                              />
                              <label className="text-sm font-medium text-gray-700">
                                {t('bank:paymentPreferences.to')}
                              </label>
                              <NumberInput
                                control={control}
                                name={`ranges.${index}.endRange`}
                                placeholder="0123"
                                className="mx-2 max-w-[128px]"
                                allowNegative={false}
                                maxLength={4}
                                type="int"
                                error={errors.ranges?.[index]?.endRange}
                                handleChange={isValidRanges}
                                readOnly={!checkSwitch}
                              />
                              {rangeFields.length > 1 && checkSwitch && (
                                <div
                                  aria-hidden="true"
                                  onClick={() => remove(index)}
                                >
                                  <CloseIcon className="text-gray-400 cursor-pointer" />
                                </div>
                              )}
                            </div>
                          ))}
                          {checkSwitch && (
                            <Button
                              variant={ButtonVariant.link}
                              size={ButtonSize.medium}
                              className="pl-0 pr-3"
                              onClick={() => append(emptyRange)}
                            >
                              <PlusIcon className="mr-1" />
                              {t('bank:paymentPreferences.rangeButton')}
                            </Button>
                          )}
                          <div className="mt-6">
                            <div className="flex items-center justify-between mb-1">
                              <div className="flex items-center mb-1">
                                <p className="text-sm font-medium mr-1">
                                  {t('bank:paymentPreferences.omittedTitle')}
                                </p>
                                <Popover
                                  content={
                                    <div className="py-2 ml-1 px-[10px] bg-gray-900 text-gray-50 text-sm max-w-[344px] rounded-lg">
                                      {t('bank:paymentPreferences.omittedInfo')}
                                    </div>
                                  }
                                  button={
                                    <button
                                      type="button"
                                      className="border-[2px] border-transparent flex self-center cursor-pointer text-gray-400 flex-shrink-0 p-0 rounded-md hover:bg-gray-100 hover:text-gray-500 focus:border-blue-600"
                                    >
                                      <InfoCircleSolidIcon className="h-[18px] w-[18px] text-gray-400" />
                                    </button>
                                  }
                                  placement="right"
                                />
                              </div>
                              <p className="font-medium text-secondary">
                                {t('common:labelOptional')}
                              </p>
                            </div>
                            <MultiSelect
                              placeholder={t(
                                'bank:paymentPreferences.omittedPlaceholder',
                              )}
                              createOption={(value: string) => ({
                                value: value,
                                label: value,
                                checkNumber: value,
                                banks_Accounts_ChecksId: null,
                                isActive: true,
                              })}
                              validation={validateNumber}
                              isDisabled={!checkSwitch}
                              setValue={setValue}
                              value={defaultOmitted}
                              name="omitted"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </>
          </HideComponent>
        </div>
      </div>
    </div>
  );
};

export default BankAccountSignIn;
