import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useAppSelector, useCurrentUser } from 'hooks';
import { Logo, LogoWithText } from 'components/Logos';
import PoweredBy from 'components/layouts/PoweredBy';
import arrowDraw from 'assets/icons/arrowDraw.svg';
import { CheckCircleIcon } from 'components/icons';
import { DomainType } from 'utils/domainService';
import { getDomain } from 'theme/selectors';
import { getOID } from 'utils/authService';
import path from 'common/path';

import Background from '../login/components/Background';
import EnrollForm from './components/EnrollForm';

const Enroll = () => {
  const { t } = useTranslation('enroll');
  const navigate = useNavigate();

  const { isAuthenticated } = useCurrentUser();

  const domain: string = useAppSelector(getDomain);

  useEffect(() => {
    if (isAuthenticated || getOID()) {
      navigate(path.businesses);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (domain === DomainType.invoicecloud) {
      navigate(path.login);
    }
  }, [domain, navigate]);

  return (
    <div className="h-screen overflow-y-auto flex">
      <Background />
      <div className="flex flex-1 flex-wrap px-4 pt-24 pb-12 justify-center">
        <div className="max-w-[580px]">
          <LogoWithText className="max-h-[72px]" checkDefaultTenant />
          <p className="heading-3xl mb-12 mt-24">{t('title')}</p>
          {[1, 2, 3, 4].map((i) => (
            <p className="mt-4 text-sm font-semibold flex items-center" key={i}>
              <CheckCircleIcon className="text-blue-600 mr-2" />
              <span>{t(`list-${i}`)}</span>
            </p>
          ))}
          <div className="flex items-center mt-12 pt-12 border-t border-gray-200 mb-12">
            <p className="text-2xl font-semibold text-gray-700 mr-2">
              {t('easy')}
            </p>
            <p className="text-2xl font-bold text-blue-600 mr-3">
              {t('getStarted')}
            </p>
            <div>
              <img src={arrowDraw} alt="Arrow" className="-mt-5" />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center px-4 lg:ml-20 max-h-[900px]">
          <div className="w-full max-w-[428px] dropdown-shadow-effect rounded-3xl bg-white z-10 mb-6">
            <div className="px-8 pb-8 pt-4 border-b border-gray-200">
              <div className="flex justify-center mb-6 mt-4">
                <Logo className="max-h-[72px]" />
              </div>
              <h2 className="text-2xl font-medium text-center">
                <Trans i18nKey="enroll:form.title">
                  text<span className="font-bold">name</span>
                </Trans>
              </h2>
            </div>

            <EnrollForm />
          </div>

          <PoweredBy />
        </div>
      </div>
    </div>
  );
};

export default Enroll;
