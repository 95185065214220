import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCircleSolidIcon } from 'components/icons/InfoCircleSolidIcon';
import PopoverOnHover from 'components/PopoverOnHover';

type LabelProps = {
  children: ReactNode | string;
  info?: string | ReactNode;
  description?: string;
  optional?: boolean;
};

const Label: React.FC<LabelProps> = ({
  children,
  info,
  description,
  optional,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center mb-1">
      <div className="flex items-center">
        <label className="text-sm leading-5 font-medium text-gray-700 whitespace-nowrap">
          {children}
        </label>
        {description && (
          <span className="text-gray-500 text-xs ml-3">{description}</span>
        )}
      </div>

      <div className="flex items-center">
        {info && (
          <PopoverOnHover
            info={info}
            button={<InfoCircleSolidIcon className="text-gray-400" />}
          />
        )}
        {optional && (
          <span className="text-sm leading-5 font-medium text-gray-400 pl-2">
            {t('common:labelOptional')}
          </span>
        )}
      </div>
    </div>
  );
};

export default Label;
