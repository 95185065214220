import { useAppSelector } from 'hooks';

import ConnectBankAccount from './ConnectBankAccount';
import BankAccountSignIn from './BankAccountSignIn';
import { getBankAccountStep } from '../selectors';
import BankAccountList from './BankAccountList';
import BankAccountInfo from './BankAccountInfo';

const BankingModalBody = () => {
  const step: number = useAppSelector(getBankAccountStep);

  return (
    <>
      {step === 1 && (
        <ConnectBankAccount>
          <BankAccountList />
        </ConnectBankAccount>
      )}
      {step === 2 && <BankAccountInfo />}
      {step === 3 && <BankAccountSignIn />}
    </>
  );
};

export default BankingModalBody;
