export const WalletIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.3"
      d="M20.5 14C21.3284 14 22 13.3284 22 12.5C22 11.6716 21.3284 11 20.5 11C19.6716 11 19 11.6716 19 12.5C19 13.3284 19.6716 14 20.5 14Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M18.8215 1.0493L3.36668 5.1904C2.83322 5.33334 2.51663 5.88168 2.65958 6.41514L3.95367 11.2448C4.09661 11.7782 4.64495 12.0948 5.17842 11.9519L20.6332 7.81078C21.1667 7.66783 21.4833 7.1195 21.3403 6.58603L20.0462 1.7564C19.9033 1.22294 19.355 0.906353 18.8215 1.0493Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 9.33682C21.5454 9.12085 21.0368 9 20.5 9C18.567 9 17 10.567 17 12.5C17 14.433 18.567 16 20.5 16C21.0368 16 21.5454 15.8792 22 15.6632V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V9.33682Z"
      fill="currentColor"
    />
  </svg>
);
