import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import Modal from 'components/Modal';

import { getBankAccountModalState, getBankAccountStep } from './selectors';
import BankingModalActions from './components/BankingModalActions';
import BankingModalTitle from './components/BankingModalTitle';
import BankingModalBody from './components/BankingModalBody';
import BankAccountName from './components/BankAccountName';
import SuccessfulConnection from '../SuccessfulConnection';
import { fetchBankAccounts } from './thunks';
import ModalLoading from '../ModalLoading';
import {
  resetBankAccountModal,
  setIsBankAccountOpen,
} from './bankAccountsSlice';

const BankAccountModal = () => {
  const dispatch = useAppDispatch();

  const { isConnecting, isSuccess, showBankName, isBankAccountOpen } =
    useAppSelector(getBankAccountModalState);
  const step: number = useAppSelector(getBankAccountStep);

  const onlyModalBody = isConnecting || isSuccess || showBankName;

  useEffect(() => {
    return () => {
      if (isBankAccountOpen) {
        dispatch(resetBankAccountModal());
        dispatch(fetchBankAccounts());
      }
    };
  }, [dispatch, isBankAccountOpen]);

  const closeModal = () => {
    dispatch(setIsBankAccountOpen(false));
  };

  return (
    <Modal
      isOpen={isBankAccountOpen}
      closeModal={closeModal}
      maxWidth={onlyModalBody || step === 1 ? '32rem' : '1000px'}
      hideCross={isConnecting}
      title={onlyModalBody || step === 1 ? '' : <BankingModalTitle />}
      actions={
        onlyModalBody ? null : <BankingModalActions onCancel={closeModal} />
      }
      height={onlyModalBody || step === 1 ? 'auto' : '100%'}
      closeTimeoutMS={0}
      zIndex={25}
      overflow={step === 1 ? 'visible' : 'auto'}
    >
      {isConnecting && <ModalLoading />}
      {isSuccess && <SuccessfulConnection type="bank" onClick={closeModal} />}
      {!isConnecting && showBankName && <BankAccountName />}
      {!onlyModalBody && <BankingModalBody />}
    </Modal>
  );
};

export default BankAccountModal;
