import api, { APIResponseIdSchema } from 'api';

export const PERSON_PHONE_TYPE_CODE = 'Mobile';
export const BUSINESS_PHONE_TYPE_CODE = 'Mobile';

export interface CreateTelephoneNumberPayload {
  createdBy: string;
  countryCallingCode: string;
  telephoneNumber: string;
}

export interface TelephoneNumberSchema {
  telephoneNumbersId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  countryCallingCode: string;
  telephoneNumber: string;
}

export interface PersonTelephoneNumberSchema {
  personsTelephoneNumbersXrefid: number;
  persons__TelephoneNumbers_XrefId?: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  personsId: number;
  telephoneNumbersId: number;
  telephoneNumbersTypeCode: string;
}

export interface BusinessTelephoneNumberSchema {
  businessesTelephoneNumbersXrefid: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  businessesId: number;
  telephoneNumbersId: number;
  telephoneNumbersTypeCode: string;
}

export const getTelephoneNumbers = async (id: number) => {
  const result: TelephoneNumberSchema | null = await api.get(
    `TelephoneNumbers/${id}`,
  );
  return result;
};

export const addTelephoneNumbers = async (
  data: CreateTelephoneNumberPayload,
) => {
  const response: APIResponseIdSchema = await api.post(
    'TelephoneNumbers',
    data,
  );
  return response.id;
};

export const addPersonsTelephoneNumbersXrefs = (
  data: Partial<PersonTelephoneNumberSchema>,
) => {
  return api.post(
    'PersonsTelephoneNumbersXrefs/AddPersonsTelephoneNumbersXrefs',
    data,
  );
};

export const updatePersonsTelephoneNumbersXrefs = (
  data: Partial<PersonTelephoneNumberSchema>,
) => {
  return api.put(
    'PersonsTelephoneNumbersXrefs/UpdatePersonsTelephoneNumbersXrefs',
    data,
  );
};

export const searchPersonsTelephoneNumbersXrefs = (
  data: Partial<PersonTelephoneNumberSchema>,
) => {
  return api.post(
    'PersonsTelephoneNumbersXrefs/SearchPersonsTelephoneNumbersXrefs',
    data,
  );
};

export const addBusinessesTelephoneNumbersXrefs = (
  data: Partial<BusinessTelephoneNumberSchema>,
) => {
  return api.post(
    'BusinessesTelephoneNumbersXrefs/AddBusinessesTelephoneNumbersXrefs',
    data,
  );
};

export const updateBusinessesTelephoneNumbersXrefs = (
  data: Partial<BusinessTelephoneNumberSchema>,
) => {
  return api.put(
    'BusinessesTelephoneNumbersXrefs/UpdateBusinessesTelephoneNumbersXrefs',
    data,
  );
};

export const getPhoneNumberByPersonId = async (
  personsId: number,
): Promise<any | null> => {
  try {
    const phoneNumberXrefs: any = await searchPersonsTelephoneNumbersXrefs({
      personsId,
      isActive: true,
      telephoneNumbersTypeCode: PERSON_PHONE_TYPE_CODE,
    });

    const phoneNumberXref: PersonTelephoneNumberSchema | null =
      phoneNumberXrefs && phoneNumberXrefs.length ? phoneNumberXrefs[0] : null;

    if (phoneNumberXref) {
      const phoneNumber = await getTelephoneNumbers(
        phoneNumberXref.telephoneNumbersId,
      );

      return {
        phoneNumber,
        phoneNumberXref,
      };
    }

    return null;
  } catch (error) {
    return null;
  }
};

export default {
  addTelephoneNumbers,
  addPersonsTelephoneNumbersXrefs,
  updatePersonsTelephoneNumbersXrefs,
  searchPersonsTelephoneNumbersXrefs,
  addBusinessesTelephoneNumbersXrefs,
  updateBusinessesTelephoneNumbersXrefs,
  getPhoneNumberByPersonId,
};
