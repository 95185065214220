import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export const getRoot = (state: RootState) => state.fxProvider;

export const getState = createSelector([getRoot], (state) => state);

export const getDetailsModalOpened = createSelector(
  [getRoot],
  ({ detailsModalOpened }) => detailsModalOpened,
);

export const getBusinessProviders = createSelector(
  [getRoot],
  ({ businessProviders }) => businessProviders,
);

export const getFXProviderLogos = createSelector(
  [getRoot],
  ({ logos }) => logos,
);

export const getProviders = createSelector(
  [getRoot],
  ({ providers, businessProviders }) =>
    providers.map((provider) => {
      const businessProvider = businessProviders.find(
        (p) => p.crossBorder_ProvidersId === provider.id,
      );

      return {
        ...provider,
        status: businessProvider ? 'In Progress' : null,
        crossBorder_ProvidersId: businessProvider?.crossBorder_ProvidersId || 0,
        businessCrossBorderProviderId: businessProvider?.id || 0,
      };
    }),
);

export const getSelectedProvider = createSelector(
  [getRoot],
  ({ provider }) => provider,
);
