import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { QuestionIcon, CloseCircleIcon, UserAddIcon } from 'components/icons';
import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import ContactSupport from 'components/ContactSupport';
import PopoverOnHover from 'components/PopoverOnHover';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import { getTheme } from 'theme/selectors';
import Popover from 'components/Popover';
import Button from 'components/Button';
import Badge from 'components/Badge';
import {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
} from 'components/InvitePersons/invitePersonsSlice';

interface NotConnectedProps {
  image: ReactNode;
  title: string;
  text: string;
  onConnect?: () => void;
  isConnected?: boolean;
}

const NotConnected: React.FC<NotConnectedProps> = ({
  image,
  title,
  text,
  onConnect,
  children,
  isConnected,
}) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const { textColor } = useAppSelector(getTheme);
  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleInvite = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(true));
  };

  if (isConnected) {
    return <>{children}</>;
  }

  return (
    <div className="w-full mx-3 bg-gray-50 rounded-10 p-4 self-start">
      <div className="flex justify-between items-center mb-1">
        <div className="flex items-center">
          {image}
          <div className="ml-4 flex">
            <Badge color="gray" rounded="rounded-sm" className="pr-2 pl-1">
              <CloseCircleIcon className="w-[14px] h-[14px] text-gray-400 mr-1" />
              {t('dashboard:connectors.notStarted')}
            </Badge>
          </div>
        </div>

        <Popover
          button={
            <Button
              type="button"
              variant="link-secondary"
              size="medium-large"
              paddingClass="px-3"
            >
              <QuestionIcon className="text-gray-400" />
              <span className="font-medium ml-3 text-gray-500">
                {t('dashboard:needHelp')}
              </span>
            </Button>
          }
          content={<ContactSupport />}
          overlay={true}
        />
      </div>

      <h6 className="font-semibold text-gray-900 mb-0.5">{title}</h6>
      <p className="text-secondary">{text}</p>

      <div className="mt-4 pb-0.5 flex items-center justify-between">
        <HideComponent show={erpPermissions.edit}>
          <Button
            className="rounded-full mr-3"
            type="button"
            size="medium"
            paddingClass="px-4"
            onClick={onConnect}
          >
            {t('dashboard:connectors.connect')}
          </Button>
        </HideComponent>

        <HideComponent show={invitePermissions.view}>
          <div className="flex items-center">
            <PermissionSection permission={Permissions.invite} showPopover edit>
              <Button
                type="button"
                variant="link"
                size="medium-large"
                paddingClass="px-3"
                onClick={handleInvite}
                className="mr-1"
                disabled={!invitePermissions.edit}
              >
                <span className="font-medium mr-3">
                  {t('dashboard:inviteHelp')}
                </span>
                <UserAddIcon styles={{ color: textColor.primary.base }} />
              </Button>
            </PermissionSection>
            <PopoverOnHover info={t('dashboard:inviteInfo')} />
          </div>
        </HideComponent>
      </div>
    </div>
  );
};

export default NotConnected;
