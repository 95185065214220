import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { SearchDuotoneIcon } from 'components/icons';
import icon from 'assets/icons/addCircle.svg';
import { useAppSelector } from 'hooks';

import FundingPopoverLink from './FundingPopoverLink';
import { getBankAccount } from '../selectors';

interface ConnectBankAccountProps {
  children: ReactNode;
}

const ConnectBankAccount: React.FC<ConnectBankAccountProps> = ({
  children,
}) => {
  const { t } = useTranslation('bank');

  const bankAccount: any = useAppSelector(getBankAccount);

  return (
    <div className="mt-14 px-6 pb-8 flex flex-wrap relative">
      <img alt={t('title')} src={icon} className="mx-auto w-12 h-12" />

      <div className="w-full my-8">
        <h6 className="heading-lg font-semibold text-center mb-2">
          {t('title')}
        </h6>

        <div className="text-sm text-gray-600 mb-6 text-center">
          {t('subTitle')}
        </div>

        {children}
      </div>

      <div className="flex w-full p-4 item-center justify-between rounded-lg bg-gray-50">
        <div className="text-sm font-semibold flex items-center">
          <SearchDuotoneIcon className="text-blue-600 mr-2" />
          <span>{t('notListed')}</span>
        </div>
        <FundingPopoverLink bankAccountId={bankAccount?.bankAccountsId} />
      </div>
    </div>
  );
};

export default ConnectBankAccount;
