import { ReactNode } from 'react';
import classNames from 'classnames';

type InputAddOnProps = {
  inline: boolean;
  value: string | ReactNode;
  type: 'prefix' | 'suffix';
  error?: any;
  className?: string;
};

const InputAddOn: React.FC<InputAddOnProps> = ({
  inline,
  value,
  type,
  error,
  className,
}) =>
  inline ? (
    <div
      className={classNames(
        className,
        'absolute flex items-center pointer-events-none inset-y-0',
        type === 'prefix'
          ? 'left-0 pl-3'
          : error
          ? 'right-0 pr-9'
          : 'right-0 pr-3',
      )}
    >
      <span className="text-gray-500">{value}</span>
    </div>
  ) : (
    <span
      className={classNames(
        className,
        'inline-flex items-center px-3 border border-gray-300 bg-gray-50 text-gray-500',
        type === 'prefix'
          ? 'border-r-0 rounded-l-md'
          : 'border-l-0 rounded-r-md',
      )}
    >
      {value}
    </span>
  );

export default InputAddOn;
