import { useTranslation } from 'react-i18next';

import { BankAccount as BankAccountProps } from 'entities/bankAccounts';
import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import { Permissions } from 'entities/dashboard';
import { PlusIcon } from 'components/icons';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { useAppSelector } from 'hooks';

import { getHasBankAccountRelation } from '../AccountingSoftware/selectors';
import { getIsRefreshing } from '../BankAccounts/selectors';
import BankAccount from './BankAccount';

interface ConnectedBankAccountProps {
  accounts: BankAccountProps[];
  setIsOpened: () => void;
  onConnect: () => void;
}

const ConnectedBankAccount: React.FC<ConnectedBankAccountProps> = ({
  accounts,
  setIsOpened,
  onConnect,
}) => {
  const { t } = useTranslation('dashboard');

  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );
  const isRefreshing: boolean = useAppSelector(getIsRefreshing);
  const erpBankNameRequired: boolean = useAppSelector(
    getHasBankAccountRelation,
  );

  const handleUpdate = () => {
    // dispatch(setAccountById(id));
    setIsOpened();
  };

  return (
    <div className="mx-3 w-full">
      <p className="font-semibold text-gray-900 mb-2">
        {accounts.length > 1
          ? t('dashboard:connectors.bank.multiTitle', { value: '' })
          : t('dashboard:connectors.bank.title')}
      </p>
      <div className="min-w-[342px] h-[222px] flex flex-col justify-between w-full bg-gray-50 rounded-10 px-2 pb-4 pt-2 relative">
        <Loading loading={isRefreshing} fullHeight transparent />

        <div className="overflow-y-auto	h-[145px] mb-4 custom-scrollbar">
          {accounts.map((account: BankAccountProps) => (
            <div key={account.bankAccountsId} className="w-full pr-1">
              <BankAccount
                account={account}
                erpBankNameRequired={erpBankNameRequired}
                onClick={() => handleUpdate()}
              />
            </div>
          ))}
        </div>

        <PermissionSection
          permission={Permissions.bankIntegration}
          showPopover
          edit
        >
          <div className="flex items-center">
            <Button
              variant="secondary-outline"
              size="medium"
              className="rounded-full bg-white"
              onClick={onConnect}
              disabled={!bankPermissions.edit}
            >
              <PlusIcon className="text-gray-500 mr-2" />
              {t('dashboard:connectors.addBankAccount')}
            </Button>
          </div>
        </PermissionSection>
      </div>
    </div>
  );
};

export default ConnectedBankAccount;
