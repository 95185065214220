import { createSelector } from '@reduxjs/toolkit';

import { CountryISOCodes } from 'common/countries';
import { RootState } from 'state/store';

const getRoot = (state: RootState) => state.submitDashboard;
const getProfile = (state: RootState) => state.profile;

export const getIsModalOpened = createSelector(
  [getRoot],
  ({ isModalOpened }) => isModalOpened,
);

export const getAuthorizing = createSelector(
  [getRoot],
  ({ authorizing }) => authorizing,
);

export const getIsDDAChecked = createSelector(
  [getRoot],
  ({ isDDAChecked }) => isDDAChecked,
);

export const getCheckboxes = createSelector(
  [getRoot],
  ({ termsCheckbox, privacyCheckbox }) => ({ termsCheckbox, privacyCheckbox }),
);

export const getIsConfirmEnabled = createSelector(
  [getRoot, getProfile],
  ({ termsCheckbox, privacyCheckbox, isDDAChecked }, { businessAddress }) =>
    termsCheckbox.checked &&
    privacyCheckbox.checked &&
    (isDDAChecked || businessAddress.countryIsocode !== CountryISOCodes.Canada),
);

export const getIsNMIAgreed = createSelector(
  [getProfile],
  ({ person, business }) =>
    person.personsId === business.nmiTermsAndConditionsPersonsId,
);

export const getIsNMIAccepted = createSelector(
  [getRoot],
  ({ isNMIAccepted }) => isNMIAccepted,
);
