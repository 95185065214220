export const PrintAdsIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2V5H2C0.895431 5 0 5.89543 0 7V10C0 11.1046 0.895431 12 2 12H3V14C3 15.1046 3.89543 16 5 16H11C12.1046 16 13 15.1046 13 14V12H14C15.1046 12 16 11.1046 16 10V7C16 5.89543 15.1046 5 14 5H13V2C13 0.895431 12.1046 0 11 0H5C3.89543 0 3 0.89543 3 2ZM11 2H5V5H11V2ZM11 10H5V14H11V10Z"
      fill="#6B7280"
    />
  </svg>
);
