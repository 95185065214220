export const AvatarIcon = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_4879_7918)">
      <path
        opacity="0.3"
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="currentColor"
      />
      <path
        d="M20 17.4943V20.0002H0V17.5035C1.16329 15.9489 2.67326 14.6871 4.40982 13.8186C6.14639 12.95 8.06166 12.4986 10.0033 12.5002C14.09 12.5002 17.72 14.4618 20 17.4943ZM13.335 7.49935C13.335 8.3834 12.9838 9.23125 12.3587 9.85637C11.7336 10.4815 10.8857 10.8327 10.0017 10.8327C9.11761 10.8327 8.26976 10.4815 7.64464 9.85637C7.01952 9.23125 6.66833 8.3834 6.66833 7.49935C6.66833 6.61529 7.01952 5.76745 7.64464 5.14233C8.26976 4.5172 9.11761 4.16602 10.0017 4.16602C10.8857 4.16602 11.7336 4.5172 12.3587 5.14233C12.9838 5.76745 13.335 6.61529 13.335 7.49935Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4879_7918">
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
