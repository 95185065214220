import { useTranslation } from 'react-i18next';

import { BlobFileTypes, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import { UploadFile, File } from 'components/uploadFile';
import { useAppDispatch, useAppSelector } from 'hooks';

import { getBlobReferences, getFileLoading } from '../../Financial/selectors';
import {
  createBlobReference,
  deleteBlobReference,
  downloadBlobReference,
} from '../../Financial/thunks';

const ProcessorFiles = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const blobReferences = useAppSelector(getBlobReferences);
  const fileLoading = useAppSelector(getFileLoading);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const docs = blobReferences.filter(
    (blob) => blob.typeCode === BlobFileTypes.merchantVarSheet,
  );

  const handleUpload = async (files: FileList) => {
    const type = BlobFileTypes.merchantVarSheet;
    dispatch(createBlobReference({ files, type }));
  };

  const handleDelete = async (id: number) => {
    await dispatch(deleteBlobReference(id));
  };

  const handleDownload = (guid: string, fileName: string) => {
    dispatch(downloadBlobReference({ guid, fileName }));
  };

  return (
    <div>
      <UploadFile
        title={t('merchantServices.configuration.varTitle')}
        text={t('merchantServices.configuration.varDescription')}
        onUpload={handleUpload}
        uploadFiles={docs}
        disabled={!financialPermissions.edit}
        progressPercent={
          fileLoading.type === BlobFileTypes.merchantVarSheet
            ? fileLoading.progress
            : 0
        }
        permission={Permissions.financialInfo}
        multiple
      >
        {() =>
          docs.map((file: any, index) => (
            <div key={index}>
              <File
                file={file}
                onDelete={() => handleDelete(file.id)}
                onDownload={(name) => handleDownload(file, name)}
                hideDate
                hideUploadButton
                size="small"
                readOnly={!financialPermissions.edit}
                permission={Permissions.financialInfo}
              />
            </div>
          ))
        }
      </UploadFile>
    </div>
  );
};

export default ProcessorFiles;
