import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import { hasPermission } from 'pages/dashboard/selectors';
import { Select, DatePicker } from 'components/Inputs';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

import { getMappedOperatingStatuses, getOperatingStatusId } from '../selectors';

const OperatingStatus = ({ setValue, control, showError }) => {
  const { t } = useTranslation('financial');

  const [hasValue, setHasValue] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);

  const operatingStatusId = useAppSelector(getOperatingStatusId);
  const statuses = useAppSelector(getMappedOperatingStatuses);
  const financialPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.financialInfo),
  );

  const operatingStatus = statuses.find(
    (status) => status.value === operatingStatusId,
  );

  useEffect(() => {
    if (operatingStatus) {
      setIsActive(operatingStatus.typeCode.toLowerCase() === 'active');
    }
  }, [operatingStatus]);

  const handleChange = ({ typeCode }) => {
    setIsActive(typeCode.toLowerCase() === 'active');
    setHasValue(true);
  };

  return (
    <div className="w-full mx-4.5">
      <Select
        label={t('financial:fields.status.label')}
        placeholder={t('financial:fields.status.placeholder')}
        name="operatingStatusId"
        className="mt-5 w-full"
        options={statuses}
        value={operatingStatus}
        setValue={setValue}
        onChange={handleChange}
        disabled={!financialPermissions.edit}
        error={
          showError &&
          !operatingStatusId &&
          !hasValue && {
            message: t('financial:fields.status.validation.required'),
          }
        }
      />

      {!isActive && (
        <DatePicker
          label={t('financial:fields.dateOfGoingLive.label')}
          name="dateofGoLive"
          className="mt-5 w-full"
          control={control}
          minDate={new Date()}
          disabled={!financialPermissions.edit}
        />
      )}
    </div>
  );
};

export default OperatingStatus;
