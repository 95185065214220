import { createSlice } from '@reduxjs/toolkit';

import { BusinessRequest } from 'common/compliance';
import {
  approveCompliance,
  declineCompliance,
  suspendCompliance,
  activateCompliance,
  sendMessage,
  fetchBusinessRequests,
} from './thunks';

interface CompianceProps {
  isModalOpened: boolean;
  loadingButton: string;
  requests: BusinessRequest[];
}

export const initialState: CompianceProps = {
  isModalOpened: false,
  loadingButton: '',
  requests: [] as BusinessRequest[],
};

const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    setIsModalOpened(state, action) {
      state.isModalOpened = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(approveCompliance.pending, (state) => {
      state.loadingButton = 'approve';
    });
    builder.addCase(approveCompliance.rejected, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(approveCompliance.fulfilled, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(declineCompliance.pending, (state) => {
      state.loadingButton = 'decline';
    });
    builder.addCase(declineCompliance.rejected, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(declineCompliance.fulfilled, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(suspendCompliance.pending, (state) => {
      state.loadingButton = 'suspend';
    });
    builder.addCase(suspendCompliance.rejected, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(suspendCompliance.fulfilled, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(activateCompliance.pending, (state) => {
      state.loadingButton = 'approve';
    });
    builder.addCase(activateCompliance.rejected, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(activateCompliance.fulfilled, (state) => {
      state.loadingButton = '';
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      if (action.payload) {
        state.requests = [...state.requests, action.payload];
      }
    });
    builder.addCase(fetchBusinessRequests.fulfilled, (state, action) => {
      state.requests = action.payload;
    });
  },
});

export const { setIsModalOpened } = complianceSlice.actions;

export default complianceSlice.reducer;
