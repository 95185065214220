type HideComponentProps = {
  hide?: boolean;
  show?: boolean;
  children: React.ReactElement;
};

const HideComponent: React.FC<HideComponentProps> = ({
  hide = false,
  show,
  children,
}) => {
  const showComponent = show !== undefined ? show : !hide;
  return showComponent ? children : null;
};

export default HideComponent;
