import { createAsyncThunk } from '@reduxjs/toolkit';

import { PermissionSchema, getPermissions } from 'services/security';
import { getResponseErrorMessages } from 'utils/helpers';
import { getUserInviteData } from 'pages/login/utils';
import { getBusinessesId } from 'utils/authService';
import { getTenant } from 'theme/selectors';
import { RootState } from 'state/store';
import {
  invitePersonsCreate,
  addPersonPermissions,
  deletePersonPermissions,
  getBusinessPermissions,
} from 'services/persons';
import businessesService, {
  BusinessPersonXrefSchema,
} from 'services/businesses';

import { getInvitePersonsData, getProfile, getPersons } from './selectors';
import { PermissionType, Person } from './invitePersonsSlice';
import { getMappedPermissions } from './components/utils';

export const fetchPermissions: any = createAsyncThunk(
  'invitePersons/fetchPermissions',
  async () => {
    try {
      const permissions = await getPermissions();
      return permissions;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchMemberPermissions: any = createAsyncThunk(
  'invitePersons/fetchMemberPermissions',
  async (personId: number) => {
    try {
      const businessId: number = getBusinessesId();

      const permissions = await getBusinessPermissions(personId, businessId);

      return permissions;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusinessMember: any = createAsyncThunk(
  'invitePersons/updateBusinessMember',
  async (data: Partial<BusinessPersonXrefSchema>, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { person } = getProfile(state);
      const persons = getPersons(state);

      const payload = {
        ...data,
        modifiedBy: person.emailAddress,
      };

      await businessesService.updateBusinessesPersonsXrefs(payload);

      return persons
        .slice()
        .map((p: Person) =>
          p.businessesPersonsXREFId === payload.businessesPersonsXREFId
            ? { ...p, ...payload }
            : p,
        );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateMemberPermissions: any = createAsyncThunk(
  'invitePersons/updateMemberPermissions',
  async (data: any, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();

      const inviteState = getInvitePersonsData(
        thunkAPI.getState() as RootState,
      );

      const oldPermissionIds = inviteState.personPermissions.map(
        (p: PermissionSchema) => p.security_PermissionsId,
      );

      if (oldPermissionIds.length) {
        await deletePersonPermissions({
          businessesId,
          personsId: data.personsId,
          permissions: oldPermissionIds,
        });
      }

      const mappedPermissions: number[] = getMappedPermissions({
        ...inviteState,
        ...data,
      });

      await addPersonPermissions({
        businessesId,
        personsId: data.personsId,
        permissions: mappedPermissions,
      });

      return inviteState.persons.slice().map((p: Person) =>
        p.personsId === data.personsId
          ? {
              ...p,
              hasOnlyReadonlyRights:
                data.permissionType === PermissionType.view,
            }
          : p,
      );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const searchBusinessesPersons: any = createAsyncThunk(
  'invitePersons/searchBusinessesPersonsXrefs',
  async (personsId: any) => {
    try {
      const businessesId = getBusinessesId();

      const businessPersonsXrefs: any =
        await businessesService.searchBusinessesPersonsXrefs({
          businessesId,
          personsId,
        });

      return businessPersonsXrefs.filter((person) => person.isActive);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBusinessMembers: any = createAsyncThunk(
  'invitePersons/fetchBusinessMembers',
  async () => {
    try {
      const businessesId = getBusinessesId();

      const businessPersonsXrefs: any =
        await businessesService.getBusinessesPersons(businessesId);

      return businessPersonsXrefs.filter((person) => person.isActive);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteBusinessPerson: any = createAsyncThunk(
  'invitePersons/deleteBusinessPerson',
  async (person: Person, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();

      const { payload } = await thunkAPI.dispatch(
        fetchMemberPermissions(person.personsId),
      );

      const permissionIds = payload
        ? payload.map((p: PermissionSchema) => p.security_PermissionsId)
        : [];

      if (permissionIds.length) {
        await deletePersonPermissions({
          businessesId,
          personsId: person.personsId,
          permissions: permissionIds,
        });
      }

      const state = thunkAPI.getState() as RootState;

      const profile = getProfile(state);
      const persons = getPersons(state);

      await businessesService.updateBusinessesPersonsXrefs({
        businessesId,
        businessesPersonsXREFId: person.businessesPersonsXREFId,
        modifiedBy: profile.person.emailAddress,
        isActive: false,
      });

      return persons.filter(
        (p: Person) =>
          p.businessesPersonsXREFId !== person.businessesPersonsXREFId,
      );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const searchPersonByEmail: any = createAsyncThunk(
  'invitePersons/searchPersonByEmail',
  async ({ userEmail, email, onClose, message }: any, thunkApi) => {
    const { dispatch, getState, rejectWithValue } = thunkApi;

    try {
      const businessesId = getBusinessesId();
      const inviteState = getInvitePersonsData(getState() as RootState);

      const tenant = getTenant(getState() as RootState);

      const inviteData = getUserInviteData({
        email,
        businessesId,
        message,
        tenant,
      });

      const personsId: any = await invitePersonsCreate(inviteData);

      const businessPersonsXrefs: any =
        await businessesService.searchBusinessesPersonsXrefs({
          businessesId,
          personsId,
        });

      if (!businessPersonsXrefs.length) {
        await businessesService.addBusinessesPersonsXrefs({
          createdBy: userEmail,
          isActive: true,
          businessesId,
          personsId,
          businessesPersonsRole: '',
          hubCreateUser: inviteState.grandAccess,
        });
      }

      if (businessPersonsXrefs.length && !businessPersonsXrefs[0].isActive) {
        await businessesService.updateBusinessesPersonsXrefs({
          businessesId,
          businessesPersonsXREFId:
            businessPersonsXrefs[0].businessesPersonsXREFId,
          modifiedBy: userEmail,
          isActive: true,
          hubCreateUser: inviteState.grandAccess,
        });
      }

      const mappedPermissions: number[] = getMappedPermissions(inviteState);

      await addPersonPermissions({
        personsId,
        businessesId,
        permissions: mappedPermissions,
      });

      dispatch(fetchBusinessMembers());

      if (onClose) {
        onClose();
      }
    } catch (e: any) {
      if (onClose) {
        onClose();
      }
      return rejectWithValue(getResponseErrorMessages(e.response));
    }
  },
);
