import moment from 'moment';

import { LibrarySolidIcon } from 'components/icons';

const fieldName = 'Connection Name';

const BankConnection = ({
  connection,
  onClick,
  isSelected,
  index,
  disabled,
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`px-6 py-4 border shadow-sm mb-4 rounded-lg ${
        isSelected
          ? 'border-blue-600 ring-blue-600 ring-1'
          : disabled
          ? 'bg-gray-100'
          : 'cursor-pointer border-gray-300'
      }`}
      aria-hidden="true"
    >
      <p className="text-gray-900 font-medium mb-1">
        {connection.vendorCredentials.find((field) => field.name === fieldName)
          ?.value || `Connection #${index + 1}`}
      </p>
      {connection.createdOn && (
        <div className="flex items-center">
          <LibrarySolidIcon className="text-blue-600 w-5 h-5" />

          <p className="ml-1 text-sm text-gray-500">
            {moment(connection.createdOn).format('MMM DD YYYY hh:mm:ss')}
          </p>
        </div>
      )}
    </div>
  );
};

export default BankConnection;
