import { CheckCircleIcon } from 'components/icons';
import { useTranslation } from 'react-i18next';

const LastUpdated = () => {
  const { t } = useTranslation('terms-of-use');

  return (
    <div className="flex mt-3 mb-5">
      <div className="flex items-center rounded-full bg-gray-50 text-xs sm:text-sm p-[5px]">
        <CheckCircleIcon className="mr-2 sm:mr-[14px] text-green-500" />
        <span className="text-gray-500">{t('lastUpdated')}</span>&nbsp;
        <span className="font-medium text-gray-900">{t('date')}</span>
      </div>
    </div>
  );
};

export default LastUpdated;
