import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector, useCurrentUser } from 'hooks';
import Background from 'pages/login/components/Background';
import invitationIcon from 'assets/icons/invitation.svg';
import { getOID, getUserEmail } from 'utils/authService';
import HideComponent from 'components/HideComponent';
import Loading from 'components/Loading';
import Link from 'components/Link';
import path from 'common/path';

import { sendInvitation } from '../Profile/thunks';
import { getPerson } from '../Profile/selectors';

const Invitation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile');
  const navigate = useNavigate();
  const { isAuthenticated } = useCurrentUser();

  const { emailAddress } = useAppSelector(getPerson);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isAuth = isAuthenticated || (getOID() && getUserEmail());

  useEffect(() => {
    if (!emailAddress || isAuth) {
      navigate(path.login);
    }
  }, [emailAddress, isAuth, navigate]);

  const handleClick = async () => {
    setIsLoading(true);
    await dispatch(sendInvitation(t('profile:inviteMessage')));
    setIsLoading(false);
  };

  return (
    <div className="h-screen overflow-y-auto flex">
      <Background />

      <div className="flex-1">
        <div className="flex flex-col min-h-screen items-center justify-center p-4">
          <div className="text-center dropdown-shadow-effect rounded-3xl bg-white z-10 p-12 max-w-[576px]">
            <HideComponent show={isLoading}>
              <Loading transparent />
            </HideComponent>

            <img src={invitationIcon} alt="Email" className="mx-auto mb-6" />

            <h3 className="heading-2xl mb-6">
              {t('profile:invitation.title')}
            </h3>

            <p className="text-sm text-gray-600 mx-auto">
              <Trans
                i18nKey="profile:invitation.description1"
                values={{ email: emailAddress || '' }}
              >
                text<b>email</b>text
              </Trans>
            </p>
            <p className="mb-12 text-sm text-gray-600 mx-auto">
              {t('profile:invitation.description2')}
            </p>

            <p className="text-sm text-gray-600">
              {t('profile:invitation.info')}
              <Link className="ml-1" onClick={handleClick}>
                {t('profile:invitation.link')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
