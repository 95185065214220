import { useRef, ReactNode, useState, useEffect, TransitionEvent } from 'react';
import { PlusIcon, MinusIcon } from 'components/icons';
import classNames from 'classnames';

interface AccordionProps {
  title: string | ReactNode;
  toggleOnTitleClick?: boolean;
  opened: boolean;
  className?: string;
  titleClassName?: string;
  children: ReactNode;
  button?: ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  toggleOnTitleClick = false,
  opened = false,
  className = 'p-6',
  titleClassName = '',
  children,
  button,
}) => {
  const [state, setState] = useState({
    isOpened: opened,
    height: opened ? 'auto' : '0',
    shouldOpenOnNextCycle: false,
    shouldSwitchAutoOnNextCycle: false,
  });

  const contentRef = useRef<HTMLDivElement>(null);

  const getRefHeight = () => {
    if (contentRef && contentRef.current) {
      return contentRef.current.scrollHeight.toString();
    }

    return '0';
  };

  useEffect(() => {
    if (state.shouldOpenOnNextCycle) {
      setState({
        ...state,
        height: getRefHeight(),
        isOpened: true,
        shouldOpenOnNextCycle: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldOpenOnNextCycle]);

  useEffect(() => {
    if (state.shouldSwitchAutoOnNextCycle) {
      setState({
        ...state,
        height: '0',
        isOpened: false,
        shouldSwitchAutoOnNextCycle: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldSwitchAutoOnNextCycle]);

  function handleTransitionEnd(e: TransitionEvent<HTMLDivElement>) {
    // Switch to height auto to make the container responsive
    if (e.target === contentRef.current && state.isOpened) {
      setState({
        ...state,
        height: 'auto',
      });
    }
  }

  function toggleAccordion() {
    if (state.isOpened) {
      setState({
        ...state,
        shouldSwitchAutoOnNextCycle: true,
        height: getRefHeight(),
      });
    } else {
      setState({
        ...state,
        shouldOpenOnNextCycle: true,
      });
    }
  }

  function handleTitleClick() {
    if (toggleOnTitleClick) {
      toggleAccordion();
    }
  }

  return (
    <div className={classNames(className, 'overflow-hidden')}>
      <div
        className={classNames(
          titleClassName,
          toggleOnTitleClick && 'hover:cursor-pointer',
          'flex items-center justify-between',
        )}
        onClick={handleTitleClick}
        aria-hidden
      >
        <div className="font-semibold w-full">{title}</div>
        {button ? (
          <div onClick={toggleAccordion} aria-hidden>
            {button}
          </div>
        ) : (
          <button
            type="button"
            onClick={toggleAccordion}
            className={classNames(
              toggleOnTitleClick && 'pointer-events-none',
              'text-gray-900 cursor-pointer',
            )}
            aria-expanded={state.isOpened}
          >
            {state.isOpened ? <MinusIcon /> : <PlusIcon />}
          </button>
        )}
      </div>

      <div
        ref={contentRef}
        style={{
          height: state.height === 'auto' ? 'auto' : `${state.height}px`,
        }}
        className="transition-height duration-300 ease-in-out"
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
