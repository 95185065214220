import { BusinessProductXrefSchema } from 'services/businessProducts';

export enum ProductTypeCodes {
  payments = 'AR/AP Payments',
  scf = 'Supply Chain Financing',
  services = 'Merchant Services',
  disbursments = 'Cross Border Disbursments',
  crossBorderPayments = 'Cross-Border Payments',
}

export interface TabProgressProps {
  totalFields: number;
  enteredFields: number;
  progress: number;
}

export interface ShowErrorsProps {
  businessInfo: boolean;
  owner: boolean;
  financial: boolean;
  transactional: boolean;
  businessPlan: boolean;
}

export interface ProcentByTabProps {
  businessInfo: TabProgressProps;
  owner: TabProgressProps;
  financial: TabProgressProps;
  transactional: TabProgressProps;
  businessPlan: TabProgressProps;
}

export interface ProgressState {
  progress: number;
  newProductType: string;
  procentByTab: ProcentByTabProps;
  businessProducts: BusinessProductXrefSchema[];
  showErrors: ShowErrorsProps;
}

export enum ApplicationStatuses {
  pendingEnrollment = 'Pending Enrollment Data',
  pendingApproval = 'Pending Approval',
  complianceApproved = 'Compliance Approved',
  approved = 'Approved',
  declined = 'Declined',
  withdrawn = 'Application Withdrawn',
  applicationSuspended = 'Application or Account Suspended',
  suspended = 'Suspended',
}

export enum ApplicationStatusColors {
  'Pending Enrollment Data' = 'yellow',
  'Pending Approval' = 'blue',
  'Compliance Approved' = 'green',
  'Approved' = 'green',
  'Declined' = 'red',
  'Application Withdrawn' = 'yellow',
  'Application or Account Suspended' = 'red',
  'Suspended' = 'red',
}
