export const FileDoneIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.3"
      d="M5.85714 2.5H13.7364C14.0911 2.5 14.4343 2.62568 14.7051 2.85474L19.4687 6.88394C19.8057 7.16895 20 7.58788 20 8.0292V20.5833C20 22.3739 19.9796 22.5 18.1429 22.5H5.85714C4.02045 22.5 4 22.3739 4 20.5833V4.41667C4 2.62612 4.02045 2.5 5.85714 2.5ZM10.875 16.25C11.1146 16.25 11.3542 16.1542 11.5458 15.9625L15.3792 12.1292C15.7625 11.7458 15.7625 11.1708 15.3792 10.7875C14.9958 10.4042 14.4208 10.4042 14.0375 10.7875L10.875 13.95L9.62917 12.7042C9.29375 12.3208 8.67083 12.3208 8.2875 12.7042C7.90417 13.0875 7.90417 13.6625 8.2875 14.0458L10.2042 15.9625C10.3958 16.1542 10.6354 16.25 10.875 16.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.875 16.25C10.6354 16.25 10.3958 16.1542 10.2042 15.9625L8.2875 14.0458C7.90417 13.6625 7.90417 13.0875 8.2875 12.7042C8.67083 12.3208 9.29375 12.3208 9.62917 12.7042L10.875 13.95L14.0375 10.7875C14.4208 10.4042 14.9958 10.4042 15.3792 10.7875C15.7625 11.1708 15.7625 11.7458 15.3792 12.1292L11.5458 15.9625C11.3542 16.1542 11.1146 16.25 10.875 16.25Z"
      fill="currentColor"
    />
  </svg>
);
