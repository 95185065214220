import Link from 'components/Link';
import path from 'common/path';

import LastUpdated from './LastUpdated';
import Typography from './Typography';
import SubTitle from './SubTitle';
import Title from './Title';

const UKSupplement = () => {
  return (
    <div>
      <LastUpdated />
      <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-3 sm:mb-6">
        UK Supplement
      </h2>
      <h2 className="text-2xl sm:text-5xl font-extrabold tracking-tight mb-6 sm:mb-12">
        <span className="text-blue-600">Transcard</span> SMART Suite Terms &amp;
        Conditions
      </h2>
      <div className="mb-12">
        {/* todo */}
        <Typography last>
          These Terms & Conditions (the <b>“Terms”</b>) form part of and are
          supplemental to the Transcard SMART Suite Terms & Conditions entered
          into by you (<b>“you”</b>, <b>“your”</b>) and Transcard Payments, LLC
          and are applicable when you are located in the United Kingdom and
          receive the Smart Disburse services from Transcard Payments Limited
          (company number: 14759455 and FRN:[  ] ). Transcard Payments Limited
          is a company duly incorporated in England with its registered office
          at 3rd Floor 1 Ashley Road, Altrincham, Cheshire, United Kingdom, WA14
          2DT (<b>“Company”</b>, <b>“Transcard”</b>, <b>“we”</b>, <b>“our”</b>,{' '}
          <b>“us”</b>) and with its head office at 4th Floor, 20 Balderton
          Street, London, W1K 6TL.
        </Typography>

        <Title>Introduction</Title>
        <Typography>
          Transcard is authorised by the Financial Conduct Authority (
          <b>“FCA”</b>) as a payment institution for the provision of payment
          services under the Payment Services Regulations 2017/572 (
          <b>“PSRs”</b>). Our regulatory permissions granted by the FCA enable
          us to act as an account information service provider (<b>“AISP”</b>)
          and a payment initiation service provider (<b>“PISP”</b>) in the
          United Kingdom.
        </Typography>
        <Typography>
          The FCA can be contacted at 12 Endeavour Square London E20 1JN and by
          phone and online:{' '}
          <Link
            href="https://www.fca.org.uk/contact"
            target="_blank"
            className="font-medium"
          >
            here
          </Link>
          .
        </Typography>
        <Typography>
          You and we agree that all of the provisions of Part VI of the PSRs do
          not apply to the provision of payment services by us to you.
        </Typography>
        <Typography>
          You have a right to request a copy of these Terms (as amended) at any
          time during the contractual relationship, and can also view the
          current Terms on Transcard’s website.
        </Typography>
        <Typography last>
          Except where defined in these Terms any capitalized terms shall have
          the meaning ascribed to them in the Framework Contract.
        </Typography>

        <Title>1. Use of the Smart Disburse Service</Title>
        <SubTitle>A. ELIGIBILITY</SubTitle>
        <Typography>
          You must read and agree to these terms before using the Smart Disburse
          service. If you do not agree, you may not use the Smart Disburse
          service. You may use the Smart Disburse service only if you have
          formed a binding contract with the Company, and only in compliance
          with the Framework Contract, these Terms and all applicable local,
          state, national, and international laws, rules and regulations. Any
          use of or access to the Smart Disburse service by anyone under the age
          of 18 is strictly prohibited and in violation of these Terms. The
          Smart Disburse service is not available to any users previously
          removed from the use of any services by the Company or its affiliates.
        </Typography>
        <SubTitle>
          B. ACCOUNT INFORMATION SERVICES AND PAYMENT INITIATION SERVICES
        </SubTitle>
        <Typography>
          <b className="mr-4">B.1</b>Your use of the SMART Disburse service, as
          described in this Clause B, constitutes our performance of regulated
          payment initiation services and account information services under the
          PSRs.
        </Typography>
        <SubTitle>Account Information Services</SubTitle>
        <Typography>
          <b className="mr-4">B.2</b>You can use SMART Disburse to provide us
          with your explicit consent to request consolidated information on one
          or more of your payment accounts held at a third party payment service
          provider, including your balances, account and transactional
          information (<b>“Account Information”</b>). If you expressly authorise
          us to provide your Account Information to a third party, we will do
          so. To authorise us to access your Account Information, you will need
          to redirect via our software to your payment account provider to
          verify your identity and authorise us to access your account.
        </Typography>
        <SubTitle>Payment Initiation Services</SubTitle>
        <Typography>
          <b className="mr-4">B.3</b>SMART Disburse also allows you to provide
          us with your consent to initiate a payment order from your payment
          account held by a third party payment service provider. This is
          subject to the limits in place with the third party and your internal
          banking limits and policies for the use of the SMART Disburse
          services.
        </Typography>
        <Typography>
          <b className="mr-4">B.4</b>To initiate a payment order, SMART Disburse
          will attempt to retrieve relevant existing payee banking information
          from your integrated Enterprise Resource Planning system or “ERP”. If
          this information is not available, SMART Disburse will provide a
          secure token via SMS or email to the proposed payee which presents a
          validation Q&A, document presentment and signature at your request,
          and provides the payee with options to present payment information
          back to you via SMART Disburse.
        </Typography>
        <Typography>
          <b className="mr-4">B.5</b>The SMART Hub will also show which
          disbursements are due and/or upcoming for payment. Any associated
          information relating to these bills will be available within your ERP.
          You can schedule payments for a period of time in the future, or pay
          immediately via the SMART Hub on the date of payment.
        </Typography>
        <Typography>
          <b className="mr-4">B.6</b>You will be able to choose the payment
          account which you want to disburse the payments from, and the SMART
          Hub will present core information from this account to you, including
          live balance availability, to ensure that the payments can be
          initiated. The payment will then be executed by you within the SMART
          Hub. If required, you will complete the relevant strong customer
          authentication journey via SMART Hub’s API connection to your payment
          account provider.
        </Typography>
        <Typography>
          <b className="mr-4">B.7</b>Subject to these Terms and you meeting any
          authentication requirements imposed by your payment account provider,
          you agree that we will initiate a payment on your behalf upon receipt
          of the information set out below:
        </Typography>
        <div className="pl-6">
          <Typography>
            <b>(a)</b> details of the beneficiary;
            <br />
            <b>(b)</b> the amount of the payment order;
            <br />
            <b>(c)</b> a description of the payment;
            <br />
            <b>(d)</b> all other mandatory information requested in the relevant
            payment or checkout flows;
            <br />
            <b>(e)</b> when you would like the payment to be made; and
            <br />
            <b>(f)</b> clear and express consent to the payment.
          </Typography>
        </div>
        <Typography>
          <b className="mr-4">B.8</b>Once in receipt of the payment order, we
          will instruct the payment to be initiated from your payment account.
        </Typography>
        <Typography>
          <b className="mr-4">B.9</b>Initiation of a payment is near
          instantaneous upon our receipt of a payment order and you may not
          therefore withdraw consent to the associated payment once the payment
          order has been submitted.
        </Typography>
        <Typography>
          <b className="mr-4">B.10</b>Where the payment is executed from your
          payment account, this will be carried out and credited to the payment
          service provider of the beneficiary in accordance with the terms you
          have with your payment account provider.
        </Typography>
        <Typography>
          <b className="mr-4">B.11</b>You may also use our Smart Disburse
          services to initiate the payment of fixed or variable amounts from
          your payment account to a beneficiary in the future, on a one-time or
          periodic basis, according to specified parameters (
          <b>“Pre-Authorised Payments”</b>).
        </Typography>
        <Typography>
          <b className="mr-4">B.12</b>When you make a Pre-Authorised Payment
          request, you will be asked to set up a mandate (a{' '}
          <b>“Pre-Authorised Payment Order”</b>). The Pre-Authorised Payment
          Order will specify:
        </Typography>
        <div className="pl-6">
          <Typography>
            <b>(a)</b> the details of the beneficiary;
            <br />
            <b>(b)</b> the maximum amount permitted per Pre-Authorised Payment;
            <br />
            <b>(c)</b> a description of the payment;
            <br />
            <b>(d)</b> the frequency of the payments and/or when you would like
            the payments to be made;
            <br />
            <b>(e)</b> the expiry date of the Pre-Authorised Payment Order;
            <br />
            <b>(f)</b> all other mandatory information requested in the relevant
            payment or checkout flows; and
            <br />
            <b>(g)</b> clear and express consent to the Pre-Authorised Payments.
          </Typography>
        </div>
        <Typography>
          <b className="mr-4">B.13</b>It is your responsibility to ensure that
          all of the details in the Pre-Authorised Payment Order are correct.
          You may not be able to recover a Pre-Authorised Payment to an
          incorrect account or recipient if the details you gave as part of the
          Pre-Authorised Payment Order were incorrect.
        </Typography>
        <Typography>
          <b className="mr-4">B.14</b>Once in receipt of a Pre-Authorised
          Payment Order, we will provide the details of the Pre-Authorised
          Payment Order to your payment account provider and may instruct
          payments to be initiated from your payment account in accordance with
          the Pre-Authorised Payment Order without your further consent.
        </Typography>
        <Typography>
          <b className="mr-4">B.15</b>You may cancel any future Pre-Authorised
          Payment under your Pre-Authorised Payment Order at any time through
          your payment account provider provided that you do so before the end
          of the business day before the next payment under the Pre-Authorised
          Payment Order is due to be made.
        </Typography>
        <Typography>
          <b className="mr-4">B.16</b>If you are unable to authenticate yourself
          to your payment account provider, this may mean that we are unable to
          provide the Smart Disburse services. Your account provider sets their
          own authentication requirements, and we have no control over these. We
          accept no responsibility for their authentication requirements.
        </Typography>
        <Typography>
          <b className="mr-4">B.17</b>We have the right to prevent your use of
          your SMART Disburse service on reasonable grounds relating to the
          security of the procedures or the suspected unauthorised or fraudulent
          use of the procedures. If we do this we will always inform you that we
          intend to stop your use of the procedures, and give our reasons for
          doing so beforehand (or immediately after, if this is not possible)
          unless doing so would compromise reasonable security measures or
          otherwise be unlawful. We will replace the procedures as soon as
          practicable after the reasons for preventing their use cease to exist.
        </Typography>
        <Typography>
          <b className="mr-4">B.18</b>We are responsible for correctly relaying
          your payment orders to your payment account provider. If you think
          that a payment initiated through us may have been incorrect,
          unauthorised, or not properly executed (perhaps due to delay or other
          error), then you need to contact us as soon as possible so that we can
          investigate. Contact us at{' '}
          <Link href="mailto:support@smart-enroll.com" className="font-medium">
            support@smart-enroll.com
          </Link>
          .
        </Typography>
        <Typography>
          <b className="mr-4">B.19</b>You should also contact your payment
          account provider so that they can investigate and correct any error
          for you. If a refund needs to be applied to the payment account from
          which the transfer was made, then your payment account provider will
          manage this for you.
        </Typography>
        <Typography>
          <b className="mr-4">B.20</b>You should contact both us and your
          payment account provider as soon as possible if you think there may be
          an issue with any payment. Please do this as soon as you become aware
          of this (and no later than 13 months after the debit date for the
          payment) as you can lose your right to have it corrected by your
          payment account provider after that time.
        </Typography>
        <Typography>
          <b className="mr-4">B.21</b>You may withdraw or vary your
          authorisation for us to access your financial information at any time.
          This may apply to one, some or all of the accounts you have already
          given us access to. You can submit your withdrawal/variation to us or
          directly with your payment account provider.
        </Typography>
        <Typography>
          <b className="mr-4">B.22</b>Once we receive your withdrawal/variation,
          we will implement it in line with the changes to your authorisation
          and, where appropriate, cease to access, display and process any
          relevant financial information which is the subject of the
          withdrawal/variation.
        </Typography>
        <Typography>
          <b className="mr-4">B.23</b>We do not review the third-party account
          information for accuracy, legality or non-infringement, we make no
          warranty or representation as to the accuracy of the information and
          we are not responsible for your third-party account information or
          products and services offered by or on third-party sites. You
          acknowledge that any third-party account information that is displayed
          through the Smart Disburse service will be the information we most
          recently accessed, and that this information may not reflect pending
          transactions or other recent activity.
        </Typography>
        <Typography>
          <b className="mr-4">B.24</b>Any product or service offered by a third
          party or your payment account provider to you will be governed by the
          terms between you and that party. We have no responsibility for such
          products and services and are not liable to you for any harm, damage
          or loss arising from your use of those products and services.
        </Typography>
        <Typography last>
          <b className="mr-4">B.25</b>We, and / or your payment account provider
          may require you to re-authenticate or reconfirm your authorisation and
          consents from time to time. If you do not re-authenticate or provide
          your consent, this may lead to us being unable to access your
          financial information and provide the Smart Disburse services.
        </Typography>

        <SubTitle>C. COMPLAINTS</SubTitle>
        {/* todo */}
        <Typography last>
          If you have any complaint about the Smart Disburse services, you
          should contact our dedicated team at{' '}
          <Link href="mailto:support@smart-enroll.com" className="font-medium">
            support@smart-enroll.com
          </Link>{' '}
          and we will try and resolve it as soon as possible. We handle
          complaints in accordance with our Complaints Handling process
          (available at https://[  ]).
        </Typography>

        <SubTitle>D. PRICING</SubTitle>
        <Typography last>
          Pricing terms for the SMART Suite products are available at{' '}
          <Link
            href="https://pricing.smart-hub.com/"
            target="_blank"
            className="font-medium"
          >
            Pricing.SMART-hub.com
          </Link>
          . We may change the pricing at any time and without prior notice. You
          are encouraged to review the latest pricing at{' '}
          <Link
            href="https://pricing.smart-hub.com/"
            target="_blank"
            className="font-medium"
          >
            Pricing.SMART-hub.com
          </Link>
          . If you do not agree to the price change, you must cancel and stop
          using the SMART Suite products before the price change takes effect.
          If there is a fixed term and price for your Service offer, that price
          will remain in force for the fixed term.
        </Typography>

        <SubTitle>E. PRIVACY</SubTitle>
        <Typography last>
          We care about the privacy of our Users. Transcard will process a
          user’s personal data in accordance with its privacy notice (available
          at{' '}
          <Link
            href={path.privacy}
            target="_blank"
            rel="noreferrer"
            className="font-medium"
          >
            SMART-Hub.com
          </Link>
          ), the terms of which are incorporated into these Terms by reference.
        </Typography>

        <SubTitle>F. GOVERNING LAW AND JURISDICTION</SubTitle>
        <Typography last>
          These Terms, their subject matter and their formation, are governed by
          English law. You and we both agree that the courts of England and
          Wales will have exclusive jurisdiction over any contractual or
          non-contractual disputes relating to these Terms.
        </Typography>

        <SubTitle>G. GENERAL</SubTitle>
        <Typography>
          <b className="mr-4">G.1</b>Assignment. These Terms, and any rights and
          licenses granted hereunder, may not be transferred or assigned by you,
          but may be assigned by Company without restriction. Any attempted
          transfer or assignment in violation hereof shall be null and void.
        </Typography>
        <Typography>
          <b className="mr-4">G.2</b>Notification Procedures and Changes to
          these Terms. Company may provide notifications, whether such
          notifications are required by law, including for the transmission of
          information or notifications under the PSRs, or which are for
          marketing or other business related purposes, to you via email notice,
          verbal notice via a phone call, written or hard copy notice, or
          through posting of such notice on our website, as determined by
          Company in our sole discretion. Please check your incoming messages on
          a regular and frequent basis, so you can report any apparent errors,
          unauthorised transactions and raise any queries. Company reserves the
          right to determine the form and means of providing notifications to
          our Users, provided that you may opt out of certain means of
          notification as described in these Terms. We may make available
          different languages for communication, but we reserve the right to
          communicate with you in English, and you will always accept
          communications made to you in English. Company is not responsible for
          any automatic filtering you or your network provider may apply to
          email notifications we send to the email address you provide us.
          Company may, in its sole discretion, modify or update these Terms from
          time to time, and so you should review this page periodically. We have
          the right to change or add to the terms of these Terms at any time,
          and to change, delete, discontinue, or impose conditions on any
          feature or aspect of the Smart Disburse service without notice, unless
          required by applicable law. In the event we are required to provide
          notice, we may post notice on our website or any other website
          maintained or owned by us and identified to you or email you at the
          email address associated with your Account. Any use of our Service
          after our publication of any such changes shall constitute your
          acceptance of future Terms of Use as modified. If you do not agree to
          any of these terms or any future Terms of Use, do not use or access
          (or continue to access) the Smart Disburse service. No modification or
          amendment to these Terms shall be binding upon Company unless in a
          written instrument signed by a duly authorized representative of
          Company.
        </Typography>
        <Typography>
          <b className="mr-4">G.3</b>Entire Agreement/Severability. These Terms,
          together with any amendments and any additional agreements you may
          enter into with Company in connection with the Smart Disburse service,
          shall constitute the entire agreement between you and Company
          concerning the Service. If any provision of these Terms is deemed
          invalid by a court of competent jurisdiction, the invalidity of such
          provision shall not affect the validity of the remaining provisions of
          these Terms, which shall remain in full force and effect.
        </Typography>
        <Typography>
          <b className="mr-4">G.4</b>Interpretation. Section headings have been
          added for convenience of reference and will not be deemed part of
          these Terms. For purposes of this Agreement, “will” and “shall” are
          intended to have equivalent meaning, and “including” will mean
          “including without limitation.” References to statutory references
          include such references as updated or amended from time to time.
        </Typography>
        <Typography>
          <b className="mr-4">G.5</b>Survival. Any provision that is reasonably
          necessary to accomplish or enforce the purpose of these Terms shall
          survive and remain in effect in accordance with its terms upon the
          termination of these Terms.
        </Typography>
        <Typography>
          <b className="mr-4">G.6</b>Additional Terms. By consenting to these
          Terms, you consent to allowing the location and identity information
          (from your mobile carrier) of your mobile phone to be used by us to
          locate and verify the device and that the location and identity
          information may be shared with third parties for anti-fraud purposes.
        </Typography>
        <Typography>
          <b className="mr-4">G.7</b>Consent to Be Contacted. By accepting these
          Terms, you expressly consent to be contacted by us for any and all
          purposes, including for the transmission of information or
          notification under the PSRs at any telephone number, or physical or
          electronic address you provide or at which you may be reached. You
          agree we may contact you in any way, including SMS messages (including
          text messages to your mobile device), calls using pre-recorded
          messages or artificial voice, and calls and messages delivered using
          an auto telephone dialling system or an automatic texting system, for
          any and all purposes. Automated messages may be played when the
          telephone is answered, whether by you or someone else. We may also
          leave a message on your answering machine, voice mail, or send a
          message via text.
        </Typography>
        <Typography>
          <b className="mr-4">G.8</b>You certify, warrant and represent that the
          telephone numbers that you have provided to us are your numbers and
          not someone else’s. You represent that you are permitted to receive
          calls at each of the telephone numbers you have provided to us. You
          agree to alert us whenever you stop using a particular telephone
          number. You certify that your provided mobile number is true and
          accurate and that you are authorized to enrol the designated mobile
          number to receive SMS messages.
        </Typography>
        <Typography>
          <b className="mr-4">G.9</b>To unsubscribe from text messages at any
          time, reply STOP to any text message you receive from us. You consent
          that, following such a request to unsubscribe, you may receive one
          final text message from us confirming your request. For help contact
          us at{' '}
          <Link href="mailto:support@smart-enroll.com" className="font-medium">
            support@smart-enroll.com
          </Link>
          .
        </Typography>
        <Typography>
          <b className="mr-4">G.10</b>Telemarketing Calls: You agree that: (i)
          we may call, email or SMS messages (including text messages) you at
          the numbers and addresses you have provided for purposes of describing
          goods and services that may be of interest to you, offered by us, our
          affiliates and/or third parties; and (ii) these calls, text and email
          messages may be made using an automatic dialling or email system
          technology and/or involve pre-recorded and/or artificial voice
          messaging.. This consent for telemarketing calls shall remain in
          effect until you revoke it. Your consent to telemarketing calls may be
          revoked in accordance with the procedures set forth herein. You also
          understand that your cellular or mobile telephone provider will charge
          you according to the type of plan you carry.
        </Typography>
        <Typography>
          <b className="mr-4">G.11</b>Opt-Out: You can revoke your consent by
          contacting us via email at{' '}
          <Link href="mailto:support@smart-enroll.com" className="font-medium">
            support@smart-enroll.com
          </Link>
          .
        </Typography>
        <Typography>
          <b className="mr-4">G.12</b>Call Recording and Monitoring: You consent
          to the recording and monitoring, for quality assurance, training, risk
          management and/or collection purposes, of any call that you place with
          us or that we place to you.
        </Typography>
        <Typography>
          <b className="mr-4">G.13</b>Force Majeure. In no event will Company be
          liable to User, or be deemed to have breached these Terms, for any
          failure or delay in performing its obligations under these Terms, if
          and to the extent such failure or delay is caused by any circumstances
          beyond Company’s reasonable control, including acts of God, flood,
          fire, pandemic, epidemic, earthquake, explosion, war, terrorism,
          invasion, riot or other civil unrest, strikes, labour stoppages or
          slowdowns or other industrial disturbances, or passage of law or any
          action taken by a governmental or public authority, including imposing
          an embargo.
        </Typography>
        <Typography>
          <b className="mr-4">G.14</b>No Waiver. No waiver of any term of these
          Terms shall be deemed a further or continuing waiver of such term or
          any other term, and Company’s failure to assert any right or provision
          under these Terms shall not constitute a waiver of such right or
          provision.
        </Typography>
        <Typography>
          <b className="mr-4">G.15</b>Contact. Please contact us at{' '}
          <Link href="mailto:support@smart-enroll.com" className="font-medium">
            support@smart-enroll.com
          </Link>{' '}
          with any questions regarding these Terms.
        </Typography>
        {/* todo */}
        <Typography>
          <b className="mr-4">G.16</b>Duration. These Terms will continue in
          force indefinitely unless they are terminated by us or you in
          accordance with clause [  ] below.
        </Typography>
        {/* todo */}
        <Typography>
          <b className="mr-4">G.17</b>Termination. You may terminate these Terms
          at any time subject to your provision of [one (1)] month’s notice in
          writing. We reserve the right to charge for termination of these Terms
          within six (6) months of you signing these Terms, provided that any
          charges will reasonably correspond to our actual costs of termination.
          We may terminate these Terms provided we give you two months’ notice,
          which we will notify you of in accordance with this clause [  ]. Any
          applicable charges will be apportioned until the time of the
          termination, and any charges which you have paid in advance will be
          reimbursed proportionally.
        </Typography>
        <Typography>
          <b className="mr-4">G.18</b>If the service provider undertakes an
          activity that is subject to value added tax, its VAT registration
          number. (Electronic Commerce (EC Directive) Regulations 2002 (SI
          2002/2013).
        </Typography>
        <Typography>
          <b className="mr-4">G.19</b>You may be able to take your complaint to
          the Financial Ombudsman Service. If you have not received a final
          response letter from us within 15 business days of raising your
          complaint, or where the final response letter has been received but is
          not satisfactory to you, you will need to bring your complaint to the
          Financial Ombudsman Service within six months of receipt of our final
          response letter. You can contact the Financial Ombudsman Service at
          The Financial Ombudsman Service, Exchange Tower, London, E14 9SR or by
          calling them on 0800 023 4567.
        </Typography>
        <Typography last>
          <b className="mr-4">G.20</b>Except for members of the Transcard group
          of companies, a person who is not a party to these Terms has no right
          under the Contracts (Rights of Third Parties) Act 1999 to enforce any
          of the Terms.
        </Typography>
      </div>
    </div>
  );
};

export default UKSupplement;
