import { createSlice } from '@reduxjs/toolkit';

import { DataSourceWidgets, ERPConnector, Provider } from 'entities/accounting';
import { clearDashboard } from 'pages/dashboard/thunks';

import { fetchBusinessAdditionalData } from '../BusinessInformation/thunks';
import {
  fetchAllConnectors,
  fetchErpAccounts,
  updateErpAccount,
  addErpAccount,
  fetchErpAccount,
  disconnectErpAccount,
  erpValidate,
  erpAuth,
} from './thunks';

export interface AccountingState {
  isLoading: boolean;
  step: number;
  providersList: ERPConnector[];
  selectedProvider: Provider;
  erpAccounts: any[];
  selectedAccount: any;
  updateIsLoading: boolean;
  showSuccess: boolean;
  showSteps: boolean;
  isAccountingOpen: boolean;
  sourceType: number;
  showWidgets: boolean;
  ERPLogos: any[];
}

export const initialState: AccountingState = {
  providersList: [],
  selectedProvider: {
    connectorId: 0,
    name: '',
    auths: [],
    logoFileShareUri: '',
    guideFileShareUri: '',
    guideText: '',
    helpText: '',
  },
  erpAccounts: [],
  selectedAccount: {},
  step: 1,
  isLoading: false,
  updateIsLoading: false,
  showSuccess: false,
  showSteps: false,
  isAccountingOpen: false,
  sourceType: 0,
  showWidgets: true,
  ERPLogos: [],
};

const accountingSlice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
    setShowSteps(state, action) {
      state.showSteps = action.payload;
    },
    setIsAccountingOpen(state, action) {
      state.isAccountingOpen = action.payload;
    },
    setSourceType(state, action) {
      state.sourceType = action.payload;
    },
    setShowWidgets(state, action) {
      state.showWidgets = action.payload;
    },
    addERPLogo(state, action) {
      state.ERPLogos = [...state.ERPLogos, action.payload];
    },
    setProvider(state, action) {
      const { jsone, ...rest } = action.payload;
      state.selectedProvider = rest;

      if (jsone) {
        state.selectedAccount.jsone = jsone;
        return;
      } else {
        state.selectedAccount.jsone = null;
      }
    },
    viewErpAccount(state, action) {
      state.step = 3;
      state.selectedAccount = action.payload;
      state.isAccountingOpen = true;
    },
    setErpAccount(state, action) {
      state.selectedAccount = action.payload;
    },
    resetAccountingModal(state) {
      state.step = initialState.step;
      state.showSteps = initialState.showSteps;
      state.selectedAccount = initialState.selectedAccount;
      state.selectedProvider = initialState.selectedProvider;
      state.isAccountingOpen = initialState.isAccountingOpen;
    },
    resetOnClose(state) {
      state.showSuccess = false;
      state.step = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearDashboard, () => initialState);
    builder.addCase(fetchAllConnectors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllConnectors.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllConnectors.fulfilled, (state, action) => {
      state.providersList = action.payload;
      const id = state.selectedAccount.connectorId;
      const connectedData = action.payload.find(
        ({ connectorId }) => connectorId === id,
      );
      if (connectedData) {
        state.selectedProvider = connectedData;
      }
      state.isLoading = false;
    });

    builder.addCase(fetchErpAccounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchErpAccounts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchErpAccounts.fulfilled, (state, action) => {
      state.erpAccounts = action.payload;
      state.selectedAccount = {};
      state.isLoading = false;
    });

    builder.addCase(fetchErpAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.erpAccounts = [action.payload];
        state.selectedAccount = action.payload;
      }
    });

    builder.addCase(addErpAccount.pending, (state) => {
      state.updateIsLoading = true;
    });
    builder.addCase(addErpAccount.rejected, (state) => {
      state.updateIsLoading = false;
    });
    builder.addCase(addErpAccount.fulfilled, (state) => {
      state.updateIsLoading = false;
      state.showSteps = true;
    });

    builder.addCase(updateErpAccount.pending, (state) => {
      state.updateIsLoading = true;
    });
    builder.addCase(updateErpAccount.rejected, (state) => {
      state.updateIsLoading = false;
    });
    builder.addCase(updateErpAccount.fulfilled, (state) => {
      state.updateIsLoading = false;
      state.showSteps = true;
    });

    builder.addCase(disconnectErpAccount.pending, (state) => {
      state.updateIsLoading = true;
    });
    builder.addCase(disconnectErpAccount.rejected, (state) => {
      state.updateIsLoading = false;
    });
    builder.addCase(disconnectErpAccount.fulfilled, (state) => {
      state.step = 1;
      state.updateIsLoading = false;
      state.showSuccess = false;
      state.selectedAccount = {};
      state.selectedProvider = initialState.selectedProvider;
    });

    builder.addCase(erpAuth.pending, (state) => {
      state.updateIsLoading = true;
    });
    builder.addCase(erpAuth.rejected, (state) => {
      state.showSteps = true;
      state.updateIsLoading = false;
    });
    builder.addCase(erpAuth.fulfilled, (state) => {
      state.updateIsLoading = false;
    });

    builder.addCase(erpValidate.rejected, (state) => {
      state.showSteps = true;
    });
    builder.addCase(erpValidate.fulfilled, () => {});

    builder.addCase(fetchBusinessAdditionalData.fulfilled, (state, action) => {
      if (action.payload.sourceTypes.length) {
        const selectedSourceType =
          action.payload.sourceTypes[0].systemsOfRecord_SourceTypesId;
        state.sourceType = selectedSourceType;

        if (selectedSourceType === DataSourceWidgets.erp) {
          state.showWidgets = false;
        }
      }
    });
  },
});

export const {
  setStep,
  setShowSteps,
  setProvider,
  setErpAccount,
  resetOnClose,
  setIsAccountingOpen,
  resetAccountingModal,
  viewErpAccount,
  setShowWidgets,
  setSourceType,
  addERPLogo,
} = accountingSlice.actions;

export default accountingSlice.reducer;
