import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import { notify } from 'components/notification/notificationSlice';
import { useAppSelector, useAppDispatch } from 'hooks';
import ContactSupport from 'components/ContactSupport';
import { Input, PhoneInput } from 'components/Inputs';
import Alert, { AlertTypes } from 'components/Alert';
import HideComponent from 'components/HideComponent';
import { DomainType } from 'utils/domainService';
import { LoginFormValues } from 'entities/login';
import { USER_WITH_OID } from 'services/persons';
import { QuestionIcon } from 'components/icons';
import { getDomain } from 'theme/selectors';
import { getUrlParams } from 'utils/url';
import Popover from 'components/Popover';
import { loginRequest } from 'config';
import path from 'common/path';

import { emailValidation, phoneValidation } from '../utils';
import { getLoginData, getCurrentTab } from '../selectors';
import { verifyPerson } from '../thunks';
import {
  LOGIN_TAB_INDEX_EMAIL,
  LOGIN_TAB_INDEX_PHONE,
  setData,
} from '../loginSlice';

const LoginForm = () => {
  const { t } = useTranslation('login');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const urlParams: any = getUrlParams();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const data = useAppSelector(getLoginData);
  const currentTab = useAppSelector(getCurrentTab);
  const domain: string = useAppSelector(getDomain);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: urlParams?.email || data.email,
      phone: urlParams?.email || data.phone,
    },
  });

  const email = watch('email');

  useEffect(() => {
    if (showAlert) {
      setShowAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleMsaLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleCreate = async () => {
    dispatch(
      setData({
        email,
      }),
    );
    navigate(path.enroll);
  };

  const handleFormSubmit = async (values: LoginFormValues) => {
    const loginData: LoginFormValues = {};

    if (currentTab === LOGIN_TAB_INDEX_EMAIL) {
      loginData.email = values.email;
      loginData.sendCode = true;
    }

    if (currentTab === LOGIN_TAB_INDEX_PHONE) {
      loginData.phone = values.phone;
      loginData.sendCode = true;
    }

    dispatch(setData(loginData));

    const result = await dispatch(verifyPerson(loginData));

    if (Array.isArray(result.payload)) {
      if (result.payload.length === 0) {
        // account is missing
        setShowAlert(true);
      } else {
        // account has been locked
        dispatch(
          notify({
            variant: 'error',
            message: 'login:emailLockedAlert',
          }),
        );
      }
    }

    if (result.payload === USER_WITH_OID) {
      handleMsaLogin();
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="py-8 px-4 md:px-8">
        <HideComponent show={currentTab === LOGIN_TAB_INDEX_EMAIL}>
          <Input
            name="email"
            placeholder={t('login:email.placeholder')}
            label={
              errors?.email?.type === 'required'
                ? t('login:email.labelRequired')
                : t('login:email.label')
            }
            register={register}
            error={errors?.email}
            validation={emailValidation(t)}
            readOnly={!!urlParams?.email}
          />
        </HideComponent>
        <HideComponent show={currentTab === LOGIN_TAB_INDEX_PHONE}>
          <PhoneInput
            label={t('login:phone.label')}
            name="phone"
            error={errors?.phone}
            control={control}
            validation={phoneValidation(t)}
          />
        </HideComponent>

        <HideComponent show={showAlert}>
          <Alert
            type={AlertTypes.warning}
            title={t(
              `login:${
                domain === DomainType.invoicecloud
                  ? 'emailMissingAlertShort'
                  : 'emailMissingAlert'
              }`,
            )}
            className="mt-4"
          />
        </HideComponent>

        <Button
          loading={isSubmitting}
          type="submit"
          className="my-6 w-full"
          heightClass="h-11"
          size={ButtonSize.mediumLarge}
        >
          {t('common:signIn')}
        </Button>

        <HideComponent hide={domain === DomainType.invoicecloud}>
          <div className="flex justify-center items-center">
            <p className="text-secondary font-medium px-2.5">
              {t('createAccount')}
            </p>
            <Button
              onClick={handleCreate}
              variant={ButtonVariant.link}
              size={ButtonSize.medium}
              heightClass="h-6"
              paddingClass="px-2.5"
            >
              {t('login:enroll')}
            </Button>
          </div>
        </HideComponent>

        <div className="flex justify-center">
          <Popover
            button={
              <Button
                variant={ButtonVariant.linkSecondary}
                className="mt-6"
                size={ButtonSize.medium}
              >
                <QuestionIcon className="mr-2 text-gray-400" />
                {t('common:helpTranscard')}
              </Button>
            }
            content={<ContactSupport />}
            overlay={true}
          />
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
