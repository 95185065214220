import { ReactNode, LinkHTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Radium from 'radium';

import { useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';

interface LinkProps extends LinkHTMLAttributes<HTMLLinkElement> {
  children: ReactNode | string;
  className?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  to?: string;
  styles?: any;
  state?: any;
  disabled?: boolean;
}

const Link: React.FC<LinkProps> = ({
  href,
  to,
  children,
  className,
  target = '_self',
  rel,
  onClick,
  styles,
  state,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const { textColor } = useAppSelector(getTheme);

  const defaultStyles = {
    color: textColor.primary.base,
    ':hover': {
      color: textColor.primary.hover,
    },
  };

  const handleClick = (e) => {
    if (disabled) {
      return;
    }

    if (to) {
      navigate(to, { state });
    }

    if (onClick) {
      onClick(e);
    }
  };

  return href ? (
    <a
      href={href}
      style={styles ?? defaultStyles}
      className={classNames(className, 'cursor-pointer')}
      onClick={handleClick}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  ) : (
    <button
      onClick={handleClick}
      type="button"
      style={styles ?? defaultStyles}
      className={classNames(className, 'cursor-pointer font-medium', {
        'opacity-40': disabled,
      })}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Radium(Link);
