import { useTranslation } from 'react-i18next';

import { ERPAcountStatuses, ErpAccount } from 'entities/accounting';
import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import Loading from 'components/Loading';

import { fetchErpAccounts, disconnectErpAccount } from './thunks';
import AccountingStatus from '../Connectors/AccountingStatus';
import { getErpAccounts, getIsLoading } from './selectors';
import DropdownMenu from '../Connectors/DropdownMenu';
import { viewErpAccount } from './accountingSlice';

const ERPSoftwareList = () => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector(getIsLoading);
  const erpAccounts: ErpAccount[] = useAppSelector(getErpAccounts);

  const handleRefresh = () => {
    dispatch(fetchErpAccounts());
  };

  const handleUpdate = (account: ErpAccount) => {
    dispatch(viewErpAccount(account));
  };

  const handleDisconnect = async (account: ErpAccount) => {
    await dispatch(disconnectErpAccount(account));
    handleRefresh();
  };

  return (
    <div className="table w-full mt-6">
      <div className="table-header-group">
        <div className="table-row uppercase font-medium text-xs text-gray-500">
          <div className="table-cell xl:w-[62%] px-4 py-3">
            {t('integration:erp.account')}
          </div>
          <div className="table-cell px-4 py-3">
            <div className="flex item-center">{t('integration:erp.erp')}</div>
          </div>
          <div className="table-cell px-4 py-3">
            {t('integration:erp.status')}
          </div>
          <div className="table-cell w-8 px-4 py-3"></div>
        </div>
      </div>
      <div className="table-row-group">
        <HideComponent show={isLoading}>
          <Loading zIndex={10} transparent />
        </HideComponent>
        {erpAccounts.map((account: ErpAccount) => (
          <div key={account.erpaccountsId} className="table-row">
            <div className="table-cell p-4 border-t border-gray-200 text-sm font-medium">
              {account.createdBy}
            </div>
            <div className="table-cell p-4 border-t border-gray-200 text-secondary">
              {account.connectorName}
            </div>
            <div className="table-cell px-4 border-t border-gray-200">
              <AccountingStatus status={account.erpaccountStatus} />
            </div>
            <div className="table-cell border-t border-gray-200">
              <DropdownMenu
                onRefresh={handleRefresh}
                onUpdate={() => handleUpdate(account)}
                permission={Permissions.erpIntegration}
                onDisconnect={
                  account.erpaccountStatus === ERPAcountStatuses.connected
                    ? () => handleDisconnect(account)
                    : null
                }
                onConnect={null}
                isActive={account.isActive}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ERPSoftwareList;
